// 渲染IMU故障
export const renderImuStatus = (value: number, placeholder?: string) => {
  const imuObj: any = {
    0: { text: '无异常', className: '' },
    1: { text: '通信异常', className: 'dangerText' },
    2: { text: '功能异常', className: 'dangerText' },
  }
  return (
    <span className={imuObj[value]?.className}>
      {imuObj[value]?.text || placeholder}
    </span>
  );
}