/**
 * 传感器方案相关列表的通用搜索表单项
 */
import React, { Fragment } from 'react';
import { Form, Col } from 'antd';
import UserSelect from '@/components/userSelect';
import DatePicker from '@/components/datePicker';

interface SensorCommonSearchProps {
  span?: number;
}
const { RangePicker } = DatePicker;
const SensorCommonSearch = (props: SensorCommonSearchProps) => {
  const { span = 6 } = props;
  return (
    <Fragment>
      <Col span={span}>
        <Form.Item label="创建人" name="userId">
          <UserSelect />
        </Form.Item>
      </Col>
      <Col span={span}>
        <Form.Item label="创建时间" name="time">
          <RangePicker allowClear style={{ width: '100%' }} showTime />
        </Form.Item>
      </Col>
    </Fragment>
  );
};

export default SensorCommonSearch;
