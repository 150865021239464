/**
 * 4G/GPS监控相关请求
 */

 import request from "@/utils/request";

 export interface SignalMonitorRequestParams {
   carId: number;
 }
 // 4G/GPS监控数据获取
 export const signalMonitorRequestPsth = '/api/v2/gps4g/stateMonitoring'
 export const signalMonitorRequest = (params: SignalMonitorRequestParams) => {
   return request.get(signalMonitorRequestPsth, {
     params,
   })
 }