/**
 * 驾驶行为分析
 */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Cascader,
  Input,
  Checkbox,
  message,
  Radio
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import gcoord from 'gcoord';
import {
  driveAnalysisRequest,
  driveAnalysisRequestPath,
  alarmResourceInfoRequest
} from '@/service/driveAnalysis';
import { findOneCarRequest } from '@/service/carManage';
import { jtDeviceDetailRequest } from '@/service/jtDevice';
import { importAliMap, initMap, destroyAliMap } from '@/utils/map';
import { ownRound } from '@/utils/math';
import { getInnerPermissionEnable } from '@/utils/permission';
import {
  renderEventType,
  renderGps,
  renderMarker,
  showInfoWindow
} from '@/utils/driveAnalysis';
import { useCallbackState } from '@/utils/ownHooks';
import { formatGpsSpeed } from '@/utils/gps';
import {
  driveBehaviorDataWithJtDevice,
  driveBehaviorValueWithJtDevice
} from '@/constants/driveBehavior';
import { driveVideoErrorMessage } from '@/constants/driveVideo';
import { formSearchGutter } from '@/constants/common';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import RecorderVideoPreviewModal from '@/components/recorderVideoPreviewModal';
import CommonTable from '@/components/commonTable';
import JtDeviceAlarmOperateColumn from '@/pages/jtDeviceAlarm/components/jtDeviceAlarmOperateColumn';
import DetailModal from './components/detailModal';
import AlarmDetailModal from './components/alarmDetailModal';
import VideoDownloadModal from './components/videoDownloadModal';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';

const { RangePicker } = DatePicker;
const DriveAnalysis = () => {
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 存储地图实例
  const alarmLabelsRef: any = useRef({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: []
  }); // 存储报警的label信息
  const pageNumRef: any = useRef(1); // 当前请求数据的页码数
  const mapDataRef: any = useRef({}); // 渲染地图所需数据
  const [showMap, setShowMap] = useCallbackState(false); // 是否显示地图
  const [alarmLabelsChecked, setAlarmLabelsChecked] = useState<any>({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  }); // 是否勾选报警的label
  const [data, setData] = useState<any>([]); // 表格数据(全量的数据，展示的数据由showDataCount控制)
  const [showDataCount, setShowDataCount] = useState(0); // 表格展示的数据数量
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储
  const [loading, setLoading] = useState(false); // 表格的loading
  const [scrollToTop, setScrollToTop] = useState(false); // 表格是否滚动到顶部
  const [mapLoading, setMapLoading] = useState(false); // 地图的loading
  const [alarmResourceInfo, setAlarmResourceInfo] = useState<any>({}); // 报警信息的记录仪视频信息
  const [carDetail, setCarDetail] = useState<any>({}); // 搜索的车辆详情(部标机、记录仪相关)
  const [mapForm] = Form.useForm(); // 地图的搜素表单
  const [tableForm] = Form.useForm(); // 表格的搜索表单
  const [sourceForm] = Form.useForm(); // 地图渲染数据来源表单

  // 监听跳转到驾驶行为分析页面携带默认搜索参数的形式
  useEffect(() => {
    if (activeKey === '/car-admin/drive-analysis' && tabParams) {
      const newTabParams = {
        plateNumber: tabParams.plateNumber,
        time: [
          dayjs(`${tabParams.date} 00:00:00`),
          dayjs(`${tabParams.date} 23:59:59`)
        ],
        type: driveBehaviorValueWithJtDevice
      };
      mapForm.setFieldsValue(newTabParams);
      tableForm.setFieldsValue(newTabParams);
      mapForm.submit();
    }
  }, [activeKey, tabParams]);

  // 销毁地图释放内存
  useEffect(() => {
    return () => {
      destroyAliMap(mapDomRef.current, mapRef.current);
    };
  }, []);

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    delete newParams.time;
    newParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
    newParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    newParams.plateNumber = (params.plateNumber || '').trim();
    // 将级联选择器的values处理成接口参数形式
    newParams.type = params.type.map((item: any) => item[item.length - 1]);
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  };

  // 搜索(地图上的表单搜索会作用于地图和表格)
  const onSearch = async (values: any) => {
    try {
      // 地图的搜索参数
      const mapSearchValues = operateSearchParams(values);
      const { beginDate, endDate, plateNumber } = mapSearchValues;
      const diffHour = dayjs(endDate).diff(beginDate, 'hour');
      if (diffHour > 24) {
        message.error('时间范围最大支持24小时！');
        return;
      }
      // 将地图表单的搜索条件赋值给表格表单并初始化地图
      const _setTableFormValues = () => {
        tableForm.setFieldsValue({
          time: values.time,
          type: values.type
        });
        tableForm.submit();
        getMapData(mapSearchValues);
      };
      if (showMap) {
        _setTableFormValues();
      } else {
        setShowMap(true, () => {
          _setTableFormValues();
        });
      }
      // 获取车辆详情
      const res = await findOneCarRequest({ plateNumber });
      const {
        terminalPhone,
        jt808DeviceId,
        jt808IsOnline,
        hasModem,
        sn,
        version
      } = res?.data?.data || {};
      const carInfo: any = {
        terminalPhone,
        jt808DeviceId,
        jt808IsOnline,
        hasModem,
        plateNumber,
        sn,
        version
      };
      if (jt808DeviceId) {
        // 有部标机时获取部标机设备通道数据
        const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
        carInfo.channelNoList = jtDeviceRes?.data?.data?.channelNoList || [];
      }
      setCarDetail(carInfo);
    } catch (error) {
      console.log(error);
      message.error('驾驶行为分析搜索错误');
    }
  };

  // 表格的局部搜索
  const onTableSearch = async (values: any) => {
    try {
      setLoading(true);
      // 地图的搜索参数
      const mapSearchValues: any = await mapForm.validateFields();
      // 表格的搜索参数
      const tableSearchValues = {
        ...mapSearchValues,
        ...values
      };
      const tableSearchParams = operateSearchParams(tableSearchValues);
      const res = await driveAnalysisRequest({
        page: 1,
        limit: 99999,
        ...tableSearchParams
      });
      const allTableData: any = [];
      const allResourceKeys: any = [];
      (res.data?.data || []).forEach((item: any) => {
        if (item.alarmResourceKey) {
          allResourceKeys.push(item.alarmResourceKey);
        }
        allTableData.push(item);
      });
      getAlarmResourceInfo(allResourceKeys);
      setData(allTableData);
      setShowDataCount(50);
      setScrollToTop(true);
      setLoading(false);
      pageNumRef.current = 1;
    } catch (error) {
      setLoading(false);
      message.error('驾驶行为分析表格搜索错误');
    }
  };

  // 获取驾驶行为数据是否有行车记录仪视频数据
  const getAlarmResourceInfo = async (allResourceKeys: any) => {
    if (allResourceKeys?.length) {
      const res = await alarmResourceInfoRequest({
        alarmResourceKeys: allResourceKeys
      });
      setAlarmResourceInfo(res.data?.data || {});
    }
  };

  // 表格加载更多的方法
  const getMoreTableData = () => {
    setLoading(true);
    setTimeout(() => {
      // 设置显示更多条数据
      pageNumRef.current += 1;
      setScrollToTop(false);
      setShowDataCount(50 * pageNumRef.current);
      setLoading(false);
    }, 500);
  };

  // 获取地图数据并渲染
  const getMapData = async (searchValues: any) => {
    try {
      setMapLoading(true);
      const res = await driveAnalysisRequest({
        page: 1,
        limit: 99999,
        ...searchValues
      });
      const resData = res.data.data || [];
      // const mapData = resData.filter(filterNotInChina).filter(filterAdjoinPoint);
      const gpsData: any = []; // gps数据(AEB设备)
      const jtGpsData: any = []; // gps数据(视频机设备)
      const alarmsData: any = []; // 报警数据(AEB设备)
      const jtAlarmData: any = []; // 报警数据(视频机设备)
      const signData: any = []; // 车身信息数据
      const modemData: any = []; // 记录仪数据
      resData.forEach((item: any) => {
        const { lat, lon, type } = item;
        if (lat && lon) {
          if (type === 'gps') {
            gpsData.push(item);
          }
          if (type === 'gps808') {
            jtGpsData.push(item);
          }
          if (type === 'alarm') {
            alarmsData.push(item);
          }
          if (type === 'alarm808') {
            jtAlarmData.push(item);
          }
          if (type === 'signInfo') {
            signData.push(item);
          }
          if (type === 'modem') {
            modemData.push(item);
          }
        }
      });
      mapDataRef.current = {
        gpsData,
        jtGpsData,
        alarmsData,
        jtAlarmData,
        signData,
        modemData
      };
      // 没有AEB设备的GPS信息但是有视频机设备的GPS信息时将来源修改为视频机
      if (!gpsData.length && jtGpsData.length) {
        sourceForm.setFieldValue('gpsSource', 'jtDevice');
      }
      importAliMap(() =>
        initMapCallback(
          gpsData,
          jtGpsData,
          alarmsData,
          jtAlarmData,
          signData,
          modemData
        )
      );
    } catch (error) {
      setMapLoading(false);
    }
  };

  // 地图初始化成功的回调
  const initMapCallback = async (
    gpsData: any,
    jtGpsData: any,
    alarmsData: any,
    jtAlarmData: any,
    signData: any,
    modemData: any
  ) => {
    try {
      initMap(mapDomRef, mapRef, 'top');
      mapRef.current?.clearMap();
      cancelAllAlarmChecked();
      gpsData.length && renderGps(gpsData, mapRef, false, true);
      // 默认渲染AEB设备的GPS信息，没有AEB设备的GPS信息时才渲染视频设备的GPS信息
      if (!gpsData.length) {
        jtGpsData.length && renderGps(jtGpsData, mapRef, false, true);
      }
      alarmsData.length && renderMarker(alarmsData, 0, mapRef, alarmLabelsRef);
      jtAlarmData.length &&
        renderMarker(jtAlarmData, 180, mapRef, alarmLabelsRef);
      signData.length && renderMarker(signData, 108, mapRef);
      modemData.length && renderMarker(modemData, 36, mapRef);
      // 确定地图中心点(根据选择的事件类型不同动态确定地图的中心点)
      const centerPositionData =
        gpsData[0] ||
        jtGpsData[0] ||
        alarmsData[0] ||
        jtAlarmData[0] ||
        signData[0] ||
        modemData[0] ||
        {};
      const { lat, lon } = centerPositionData;
      if (lat && lon) {
        const transformCenterPosition = gcoord.transform(
          [lat, lon],
          gcoord.WGS84,
          gcoord.AMap
        );
        mapRef.current.setCenter(
          new window.AMap.LngLat(
            transformCenterPosition[0],
            transformCenterPosition[1]
          )
        );
      }
      setMapLoading(false);
    } catch (error) {
      console.log(error);
      setMapLoading(false);
      message.error('地图初始化失败');
    }
  };

  // 切换报警类型的勾选
  const toggleAlarmChecked = (checked: boolean, label: number) => {
    setAlarmLabelsChecked({ ...alarmLabelsChecked, [label]: checked });
    const currentAlarmLabels = alarmLabelsRef.current[label];
    if (checked) {
      // 选中显示报警信息浮层
      currentAlarmLabels.forEach((item: any) => {
        mapRef.current.add(item);
      });
    } else {
      // 取消选中隐藏报警浮层
      currentAlarmLabels.forEach((item: any) => {
        mapRef.current.remove(item);
      });
    }
  };

  // 取消所有报警类型的勾选
  const cancelAllAlarmChecked = () => {
    alarmLabelsRef.current = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: []
    };
    setAlarmLabelsChecked({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false
    });
  };

  // 获取导出的参数
  const getExportParams = () => {
    const mapSearchValues = mapForm.getFieldsValue();
    const tableSearchValues = tableForm.getFieldsValue();
    tableSearchValues.plateNumber = mapSearchValues.plateNumber;
    return operateSearchParams(tableSearchValues, true);
  };

  // 点击位置按钮
  const showPosition = (data: any) => {
    // if (
    //   data.lat < 73 ||
    //   data.lat > 134 ||
    //   data.lon > 53.5 ||
    //   data.lon < 18.15
    // ) {
    //   return;
    // }
    window.AMap.plugin('AMap.Geocoder', function () {
      const geocoder = new window.AMap.Geocoder();
      geocoder.getAddress([data.lat, data.lon], (status: string, result: any) => {
        if (status === 'complete' && result.regeocode) {
          const transformGcoord = gcoord.transform(
            [data.lat, data.lon],
            gcoord.WGS84,
            gcoord.AMap
          );
          const lnglat = new window.AMap.LngLat(
            transformGcoord[0],
            transformGcoord[1]
          );
          const formattedAddress = result.regeocode.formattedAddress;
          const currentPoint = {
            lnglat,
            target: { originData: { ...data, transformGcoord } }
          };
          showInfoWindow(currentPoint, mapRef, true, formattedAddress);
        }
      });
    });
  };

  // 点击详细信息显示弹窗
  const showInfo = (data: any, index: number) => {
    if (data.type === 'alarm') {
      setOperateInfo({ type: 'alarmDetail', data, index });
    } else if (data.type === 'signInfo') {
      setOperateInfo({ type: 'info', data });
    }
  };

  // 视频转码成功(添加字幕)后修改源数据
  const transferCallback = (newUrl: string) => {
    const newData: any = [];
    data.forEach((item: any, index: number) => {
      if (index === operateInfo.index) {
        newData.push({ ...item, needTrans: false, attachUrl: newUrl });
      } else {
        newData.push(item);
      }
    });
    setData(newData);
  };

  // 渲染表格的footer内容
  const renderTableFooter = () => {
    const totalCount = data.length;
    if (Math.min(showDataCount, totalCount) === totalCount) {
      return `共${totalCount}条，已加载全部数据`;
    } else {
      return (
        <div className={ownStyles.tableFooter}>
          <span className={ownStyles.desc}>
            {`共${totalCount}条，已加载${showDataCount}条数据`}
          </span>
          <Button loading={loading} type="primary" onClick={getMoreTableData}>
            加载更多
          </Button>
        </div>
      );
    }
  };

  // 修改渲染地图的数据来源
  const onSourceSearch = (values: any) => {
    try {
      setMapLoading(true);
      const {
        gpsData,
        jtGpsData,
        alarmsData,
        jtAlarmData,
        signData,
        modemData
      } = mapDataRef.current;
      const { gpsSource, alarmSource } = values;
      mapRef.current?.clearMap();
      cancelAllAlarmChecked();
      if (gpsSource === 'aeb') {
        gpsData.length && renderGps(gpsData, mapRef, false, true);
      } else {
        jtGpsData.length && renderGps(jtGpsData, mapRef, false, true);
      }
      if (alarmSource.includes('aeb')) {
        alarmsData.length &&
          renderMarker(alarmsData, 0, mapRef, alarmLabelsRef);
        signData.length && renderMarker(signData, 108, mapRef);
        modemData.length && renderMarker(modemData, 36, mapRef);
      }
      if (alarmSource.includes('jtDevice')) {
        jtAlarmData.length &&
          renderMarker(jtAlarmData, 180, mapRef, alarmLabelsRef);
      }
      setMapLoading(false);
    } catch (error) {
      setMapLoading(false);
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '数据来源',
      dataIndex: 'source',
      width: 70,
      render: (value: any, item: any) => {
        const { type } = item;
        if (['gps808', 'alarm808'].includes(type)) {
          return '视频机设备';
        } else {
          return 'AEB设备';
        }
      }
    },
    {
      title: '时间',
      dataIndex: 'reportedTime',
      width: 110
    },
    {
      title: '事件类型',
      dataIndex: 'type',
      width: 80,
      render: (value: number | string, item: any) => renderEventType(item)
    },
    {
      title: '车身车速',
      dataIndex: 'vehicleSpeed',
      width: 50,
      render: (value: string) => ownRound(value, 1)
    },
    {
      title: 'GPS车速',
      dataIndex: 'gpsSpeed',
      width: 50,
      render: (value: string) => formatGpsSpeed(value)
    },
    {
      title: '描述',
      dataIndex: 'descText',
      width: 265,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 135,
      render: (value: any, item: any, index: number) => {
        return (
          <div
            className={styles.tableOperateColumn}
            style={{ display: 'flex' }}
          >
            {item.info && item.type !== 'alarm808' && (
              <Button type="link" onClick={() => showInfo(item, index)}>
                详细信息
              </Button>
            )}
            {/* 视频机报警查看附件 */}
            {item.type === 'alarm808' && (
              <JtDeviceAlarmOperateColumn
                alarmData={{ contentMap: item.info, ossKeyArr: item.ossKeyArr }}
              />
            )}
            {Boolean(item.lon) && Boolean(item.lat) && (
              <Button type="link" onClick={() => showPosition(item)}>
                位置
              </Button>
            )}
            {/* 报警信息且有部标机或者记录仪的才显示 */}
            {item.type === 'alarm' &&
              Boolean(carDetail.jt808DeviceId || carDetail.hasModem) && (
                <Button
                  type="link"
                  onClick={() => setOperateInfo({ type: 'videoDownload' })}
                >
                  历史视频下载
                </Button>
              )}
            {/* 显示记录仪视频 */}
            {Boolean(item.hasAttach && item.type === 'modem') && (
              <Button
                type="link"
                onClick={() =>
                  setOperateInfo({ type: 'video', data: item, index })
                }
              >
                播放
              </Button>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.pageContentBox}>
      <Form onFinish={onSearch} form={mapForm}>
        <Row gutter={formSearchGutter} wrap>
          <Col span={11}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
              rules={[{ required: true, message: '请输入车牌号' }]}
            >
              <Input placeholder="请输入车牌号" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs().subtract(1, 'day'), dayjs()]}
              rules={[{ required: true, message: '请选择日期范围' }]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
                showTime
              />
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item
              label="事件类型"
              name="type"
              initialValue={driveBehaviorValueWithJtDevice}
              rules={[{ required: true, message: '请指定事件类型' }]}
            >
              <Cascader
                multiple
                options={driveBehaviorDataWithJtDevice}
                maxTagCount="responsive"
                allowClear
                showCheckedStrategy={Cascader.SHOW_CHILD}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {showMap && (
        <LoadingWrapper spinning={mapLoading}>
          <div className={ownStyles.analysisMap}>
            <div ref={mapDomRef} className={ownStyles.map} />
            <div className={ownStyles.sourceForm}>
              <Form onFinish={onSourceSearch} form={sourceForm} size="small">
                <Form.Item label="GPS来源" name="gpsSource" initialValue="aeb">
                  <Radio.Group
                    options={[
                      { label: 'AEB设备', value: 'aeb' },
                      { label: '视频机设备', value: 'jtDevice' }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="报警来源"
                  name="alarmSource"
                  initialValue={['aeb', 'jtDevice']}
                >
                  <Checkbox.Group
                    options={[
                      { label: 'AEB设备', value: 'aeb' },
                      { label: '视频机设备', value: 'jtDevice' }
                    ]}
                  />
                </Form.Item>
                <Form.Item>
                  <div className={styles.searchBox}>
                    <Button type="primary" htmlType="submit">
                      确定
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div className={ownStyles.alarmCheckbox}>
              {[
                'FCW预警',
                'AEB报警',
                '起步阻止',
                'LDW预警',
                'HMW预警',
                'AEB设备其它报警',
                '视频机报警'
              ].map((item: string, index: number) => (
                <div key={item}>
                  <Checkbox
                    checked={alarmLabelsChecked[index + 1]}
                    onChange={(e: CheckboxChangeEvent) =>
                      toggleAlarmChecked(e.target.checked, index + 1)
                    }
                  >
                    {item}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        </LoadingWrapper>
      )}
      {showMap && (
        <Form
          onFinish={onTableSearch}
          form={tableForm}
          className={ownStyles.tableForm}
          scrollToFirstError
        >
          <Row gutter={formSearchGutter} wrap>
            <Col span={24}>
              <Form.Item
                label="事件类型"
                name="type"
                rules={[{ required: true, message: '请指定事件类型' }]}
              >
                <Cascader
                  multiple
                  options={driveBehaviorDataWithJtDevice}
                  maxTagCount="responsive"
                  allowClear
                  showCheckedStrategy={Cascader.SHOW_CHILD}
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="日期范围"
                name="time"
                rules={[{ required: true, message: '请选择日期范围' }]}
              >
                <RangePicker
                  allowClear={false}
                  style={{ width: '100%' }}
                  showTime
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                  <ExportButton
                    url={driveAnalysisRequestPath}
                    fileName={`${carDetail.plateNumber}驾驶行为分析`}
                    getParamsFunc={getExportParams}
                    requestMethod="GET"
                    permissionKey="/car-admin/drive-analysis/export"
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      <CommonTable
        columns={columns}
        dataSource={data.slice(0, showDataCount)}
        pagination={false}
        bordered
        scroll={{ y: 400 }}
        loading={loading}
        scrollToTop={scrollToTop}
        scrollLoadMore={data.length !== Math.min(showDataCount, data.length)}
        scrollLoadMoreFunc={getMoreTableData}
        footer={data.length ? renderTableFooter : undefined}
      />
      {/* 报警信息的详细信息弹窗 */}
      {operateInfo.type === 'alarmDetail' && (
        <AlarmDetailModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
          carDetail={carDetail}
          transferCallback={transferCallback}
          alarmResourceInfo={alarmResourceInfo}
        />
      )}
      {/* 车身信息的详细信息弹窗 */}
      {operateInfo.type === 'info' && (
        <DetailModal
          closeModal={() => setOperateInfo({})}
          data={operateInfo.data}
        />
      )}
      {/* 视频播放的弹窗 */}
      {operateInfo.type === 'video' && (
        <RecorderVideoPreviewModal
          url={
            operateInfo.data?.attachUrl ||
            alarmResourceInfo[operateInfo.data?.alarmResourceKey]
          }
          fileName={`${operateInfo.data?.reportedTime}  ${renderEventType(
            operateInfo.data
          )}`}
          closeModal={() => setOperateInfo({})}
          showTransferButton={operateInfo.data?.needTrans}
          resourceKey={
            operateInfo.data?.resourceKey || operateInfo.data?.alarmResourceKey
          }
          transferCallback={(url: string) => transferCallback(url)}
          showTag
          title={`${operateInfo.data?.reportedTime}  ${renderEventType(
            operateInfo.data
          )}`}
          showComment={
            Boolean(operateInfo.data.hasAttach) &&
            operateInfo.data.type === 'alarm' &&
            getInnerPermissionEnable('/car-admin/drive-analysis/video-comment')
          }
          errorMessage={driveVideoErrorMessage?.[operateInfo.data.eventCode]}
          needTrans={operateInfo.data?.needTrans}
          rawUrl={operateInfo.data?.rawUrl}
        />
      )}
      {/* 历史视频下载弹窗 */}
      {operateInfo.type === 'videoDownload' && (
        <VideoDownloadModal
          closeModal={() => setOperateInfo({})}
          data={carDetail}
        />
      )}
    </Card>
  );
};

export default DriveAnalysis;
