// 报警类型枚举
export enum AlarmTypeEnum {
  fcw = 1,
  aeb = 2,
  sts = 3,
  ldw = 4,
  hmw = 5,
  radar = 7,
  rtb = 8,
  rta = 9
}

// 报警类型
export const alarmTypeData = [
  { label: 'FCW预警', value: AlarmTypeEnum.fcw, key: 'fcw', typeList: [1] },
  { label: 'AEB制动', value: AlarmTypeEnum.aeb, key: 'aeb', typeList: [2] },
  { label: '起步阻止', value: AlarmTypeEnum.sts, key: 'sts', typeList: [3] },
  { label: 'LDW预警', value: AlarmTypeEnum.ldw, key: 'ldw', typeList: [4] },
  { label: 'HMW预警', value: AlarmTypeEnum.hmw, key: 'hmw', typeList: [5] },
  {
    label: '角雷达报警',
    value: AlarmTypeEnum.radar,
    key: 'radar',
    typeList: [7]
  },
  { label: '右转预警', value: AlarmTypeEnum.rta, key: 'rta', typeList: [9] },
  { label: '右转制动', value: AlarmTypeEnum.rtb, key: 'rtb', typeList: [8] }
];

// 报警类型的value
export const alarmTypeValue = alarmTypeData.map((item: any) => item.value);

// 数据统计相关的报警类型(部分报警类型不支持数据统计查询)
export const dataStatisticsAlarmTypeData = [
  { label: 'FCW预警', value: AlarmTypeEnum.fcw },
  { label: 'AEB制动', value: AlarmTypeEnum.aeb },
  { label: '起步阻止', value: AlarmTypeEnum.sts },
  { label: 'LDW预警', value: AlarmTypeEnum.ldw },
  { label: 'HMW预警', value: AlarmTypeEnum.hmw },
  { label: '右转制动', value: AlarmTypeEnum.rtb }
];

// 数据统计相关的报警类型的value
export const dataStatisticsAlarmTypeValue = dataStatisticsAlarmTypeData.map(
  (item: any) => item.value
);

// 数据统计相关的报警类型(修正后HMW)
export const correctDataStatisticsAlarmTypeData =
  dataStatisticsAlarmTypeData.map((item: any) => {
    const returnItem = { ...item };
    if (item.value === AlarmTypeEnum.hmw) {
      returnItem.label = '修正后HMW预警';
    }
    return returnItem;
  });

// 挡位状态
export const gearStatus: NumberStringObj = {
  0: '无效',
  1: 'N档',
  2: 'D档',
  3: 'R档',
  4: 'P档',
  5: '未知'
};

// 开关状态数据
const switchStatusData: NumberStringObj = { 0: '关闭', 1: '打开' };

// 车道线类型
const laneStyle: { [key: number | string]: string } = {
  0: '无',
  1: '预测',
  2: '虚线',
  3: '实线',
  4: '双虚线',
  5: '双实线',
  6: '三车道线',
  none: '无',
  predict: '预测',
  broken: '虚线',
  solid: '实线',
  double_broken: '双虚线',
  double_solid: '双实线',
  triple: '三车道线'
};

// AEB状态/行为
const aebStatus: NumberStringObj = {
  0: '正常',
  1: '转向灯打开',
  2: '刹车灯打开',
  3: 'AEB制动开关关闭',
  4: 'AEB发生制动',
  5: 'AEB发生预警'
};

// AEB制动类型
export const aebBreakTypeData: NumberStringObj = {
  0: '无制动',
  1: '双目制动',
  2: '毫米波制动',
  3: '超声波制动',
  4: '角雷达制动'
};

// 详细报警信息对应数据
export const alarmKeyData: any = {
  trigger: {
    name: '触发源',
    value: {}
  },
  alarmId: {
    name: '报警ID',
    value: {}
  },
  //20240723 删除报警起止行改为在表头中显示
  // isBegin: {
  //   name: '报警起、止',
  //   value: {
  //     0: '止',
  //     1: '起',
  //     2: '毫米波制动',
  //     3: '超声波制动'
  //   }
  // },
  mileage: {
    name: '里程数',
    value: {}
  },
  lon: {
    name: '经度',
    value: {}
  },
  lat: {
    name: '纬度',
    value: {}
  },
  orientation: {
    name: '方向',
    value: {}
  },
  carSpeed: {
    name: '车速',
    value: {}
  },
  vehicleSpeed: {
    name: '车身速度',
    value: {}
  },
  gpsSpeed: {
    name: 'gps车速',
    value: {}
  },
  gpsDateTime: {
    name: 'GPS时间',
    value: {}
  },
  timeMs: {
    name: 'AEB系统运行时间',
    value: {}
  },
  angleSpeed: {
    name: 'IMU角速度',
    value: {}
  },
  angleX: {
    name: 'IMU角速度-x',
    value: {}
  },
  angleY: {
    name: 'IMU角速度-y',
    value: {}
  },
  angleZ: {
    name: 'IMU角速度-z',
    value: {}
  },
  accSpeed: {
    name: 'IMU加速度',
    value: {}
  },
  accX: {
    name: 'IMU加速度-x',
    value: {}
  },
  accY: {
    name: 'IMU加速度-y',
    value: {}
  },
  accZ: {
    name: 'IMU加速度-z',
    value: {}
  },
  pitch: {
    name: '俯仰角',
    value: {}
  },
  roll: {
    name: '横滚角',
    value: {}
  },
  yaw: {
    name: '航偏角',
    value: {}
  },
  GearStatus: {
    name: '档位状态',
    value: gearStatus
  },
  gearStatus: {
    name: '档位状态',
    value: gearStatus
  },
  throttle: {
    name: '油门踏板开度',
    value: {}
  },
  brake: {
    name: '刹车踏板开度',
    value: {}
  },
  steeringWheel: {
    name: '方向盘角度',
    value: {}
  },
  steeringWheelStatus: {
    name: '方向盘状态',
    value: {
      0: '左打',
      1: '右打'
    }
  },
  SignalLeftOn: {
    name: '左转向灯',
    value: switchStatusData
  },
  SignalRightOn: {
    name: '右转向灯',
    value: switchStatusData
  },
  SignalBreakOn: {
    name: '刹车灯',
    value: switchStatusData
  },
  accVelocity: {
    name: 'ACC速率',
    value: {}
  },
  decelerationDemand: {
    name: '减速需求',
    value: {}
  },
  obstacle_type: {
    name: '障碍物类型',
    value: {
      0: '无',
      1: '车辆',
      2: '人',
      3: '其他',
      4: '骑行者',
      5: '其他',
      6: '其他',
      7: '其他',
      8: '其他',
      9: '其他',
      10: '其他',
      11: '其他'
    }
  },
  obstacleType: {
    name: '障碍物类型',
    value: {
      0: '无',
      1: '车辆',
      2: '人',
      3: '其他',
      4: '其他',
      5: '其他',
      6: '其他',
      7: '其他',
      8: '其他',
      9: '其他',
      10: '其他',
      11: '其他'
    }
  },
  verticalDistance: {
    name: '障碍物纵向相对距离（米）',
    value: {}
  },
  lengthways_distance: {
    name: '纵向相对距离(米)',
    value: {}
  },
  verticalSpeed: {
    name: '障碍物纵向相对速度（米/秒）',
    value: {}
  },
  horizontalDistance: {
    name: '障碍物横向相对距离（米）',
    value: {}
  },
  horizontalSpeed: {
    name: '障碍物横向相对速度（米/秒）',
    value: {}
  },
  warningType: {
    name: '报警类型',
    value: {
      1: 'fcw',
      2: 'hmw',
      3: 'ldw',
      4: 'aeb'
    }
  },
  time: {
    name: '预警时间',
    value: {}
  },
  warningLevel: {
    name: '预警等级',
    value: {
      0: '无预警',
      1: '一级预警',
      2: '二级预警',
      3: '三级预警'
    }
  },
  level: {
    name: '报警等级',
    value: {}
  },
  fcwLevel: {
    name: '前向碰撞预警等级',
    value: {
      0: '无预警',
      1: '一级预警',
      2: '二级预警'
    }
  },
  AEBStatus: {
    name: 'AEB状态',
    value: aebStatus
  },
  AEBAction: {
    name: 'AEB行为',
    value: aebStatus
  },
  AebsStatus: {
    name: 'AEB制动类型',
    value: aebBreakTypeData
  },
  AebsOn: {
    name: 'AEB制动开关',
    value: switchStatusData
  },
  type: {
    name: '触发事件类型',
    value: {
      0: '无事件',
      1: 'TTC时间触发',
      2: 'HMW时间触发'
    }
  },
  brakeStrength: {
    name: '制动力度',
    value: {}
  },
  ttc: {
    name: '前向碰撞时间（秒）',
    value: {}
  },
  TTC: {
    name: '碰撞时间(秒)',
    value: {}
  },
  hmw: {
    name: '车距过近时间（秒）',
    value: {}
  },
  HMW_time: {
    name: '车距时间(秒)',
    value: {}
  },
  speed: {
    name: '车距过近预警车速',
    value: {}
  },
  LDW: {
    name: '车辆偏离预警',
    value: {
      0: '未识别车道线',
      1: '左车道线偏离',
      2: '右车道线偏离',
      3: '无偏离'
    }
  },
  warningSide: {
    name: '预警边界',
    value: {
      none: '无预警',
      left: '左边界预警',
      right: '右边界预警',
      0: '无预警',
      1: '左边界预警',
      2: '右边界预警'
    }
  },
  laneStyle: {
    name: '车道线类型',
    value: laneStyle
  },
  LeftLaneStyle: {
    name: '左车道线类型',
    value: laneStyle
  },
  leftLaneStyle: {
    name: '左车道线类型',
    value: laneStyle
  },
  RightLaneStyle: {
    name: '右车道线类型',
    value: laneStyle
  },
  rightLaneStyle: {
    name: '右车道线类型',
    value: laneStyle
  },
  cameraId: {
    name: '触发源-相机ID',
    value: {}
  },
  cameraLocation: {
    name: '触发源-相机位置',
    value: {}
  },
  cameraFailureId: {
    name: '相机异常码',
    value: {}
  },
  mmwId: {
    name: '触发源-毫米波雷达ID',
    value: {}
  },
  mmwLocation: {
    name: '触发源-毫米波雷达位置',
    value: {}
  },
  bsdWarningDirection: {
    name: '角雷达报警方向',
    value: {
      0: '无效',
      1: '前方',
      2: '右侧',
      3: '后方',
      4: '左侧',
      5: '左前方',
      6: '右前方',
      7: '左后方',
      8: '右后方',
      9: '其他',
      10: '其他',
      11: '其他',
      12: '其他',
      13: '其他',
      14: '其他',
      15: '其他'
    }
  },
  ultraId: {
    name: '触发源-超声波雷达ID',
    value: {}
  },
  ultraLocation: {
    name: '触发源-超声波雷达位置',
    value: {}
  },
  ultrasonicDistance: {
    name: '超声波报警距离（分米）',
    value: {}
  },
  whichUltrasonicBrake: {
    name: '超声波制动雷达ID',
    value: {}
  }
};