/**
 * 设备详情
 */
import React, { useEffect, useState } from "react";
import { Descriptions, Drawer } from 'antd';
import { deviceDetailRequest } from '@/service/equipmentManage';
import LoadingWrapper from '@/components/loadingWrapper';

interface DeviceDetailProps {
  item: any; // 设备信息
  onClose: () => void; // 关闭设备详情弹层的方法
}
const deviceDetail = (props: DeviceDetailProps) => {
  const { item, onClose } = props;
  const [deviceDetail, setDeviceDetail] = useState<any>({}); // 设备详情
  const [loading, setLoading] = useState<boolean>(false) //  设备详情是否在请求中

  // 获取设备详情
  const getDeviceDetail = async() => {
    try {
      setLoading(true);
      const res = await deviceDetailRequest(item.id);
      const data = res.data?.data || {};
      setDeviceDetail(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDeviceDetail();
  }, []);

  return (
    <Drawer
      title='设备详情'
      visible
      size="large"
      onClose={onClose}
    >
      <LoadingWrapper spinning={loading}>
        <Descriptions bordered size="small" column={{ xs: 1, sm: 1 }}>
          <Descriptions.Item label="设备sn">{deviceDetail.sn}</Descriptions.Item>
          <Descriptions.Item label="设备关联sim卡编号">{deviceDetail.sim}</Descriptions.Item>
          <Descriptions.Item label="关联车辆">{deviceDetail.plateNumber}</Descriptions.Item>
          <Descriptions.Item label="相机sn">{deviceDetail.cameraSn}</Descriptions.Item>
        </Descriptions>
      </LoadingWrapper>
    </Drawer>
  )
}

export default deviceDetail;
