/**
 * 运维组管理的相关请求
 */
import request from "@/utils/request";

export interface UpsertOperationGroupParams {
  id?: number;
  title: string;
  cateIds: Array<string>;
}

// 获取运维组列表
export const operationGroupListRequest = () => {
  return request.get('/api/v2/cateOm/list')
}

// 添加、编辑运维组
export const upsertOperationGroupRequest = (data: UpsertOperationGroupParams) => {
  return request.post('/api/v2/cateOm/upsert', data)
}

// 删除运维组
export const deleteOperationGroupRequest = (id: number) => {
  return request.delete(`/api/v2/cateOm/${id}`)
}