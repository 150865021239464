/**
 * 报警次数筛选相关请求
 */
import request from "@/utils/request";

export interface AlarmCountRequestParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  cate_id?: number | Array<number>;
  min?: number | string;
  max?: number | string;
}

// 获取报警次数筛选数据
export const alarmCountRequestPath = '/api/v2/car/alarmCount';
export const alarmCountRequest = (data: AlarmCountRequestParams) => {
  return request.post(alarmCountRequestPath, data)
}