/**
 * 运维组管理
 */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Tree, Tag, Button, Input, Popconfirm, message } from 'antd';
import { debounce, uniq } from 'lodash';
import LoadingWrapper from '@/components/loadingWrapper';
import PermissionButton from '@/components/permissionButton';
import { operationGroupListRequest, deleteOperationGroupRequest, upsertOperationGroupRequest, UpsertOperationGroupParams } from '@/service/operationGroupManage';
import { groupCategoryRequest } from '@/service/groupManage';
import styles from './index.module.scss';
const { CheckableTag } = Tag;

const OperationGroupManage = () => {
  const expandRef = useRef<any>([]); // 存储展开的keys
  const [operationGroupList, setOperationGroupList] = useState<any>([]); // 运维组列表数据
  const [loading, setLoading] = useState<boolean>(false); // 运维组数据的loading
  const [allCategoryData, setAllCategoryData] = useState<any>([]); // 全量分组类目数据
  const [categoryData, setCategoryData] = useState([]); // 用于展示的分组类目数据
  const [selectGroup, setSelectGroup] = useState<any>(null); // 选择的运维组
  const [groupName, setGroupName] = useState<string>(''); // 运维组的名称
  const [operateType, setOperateType] = useState<any>('detail'); // 当前操作类型
  const [checkedKeys, setCheckedKeys] = useState<any>([]); // 选中的分组
  const [expandedKeys, setExpandedKeys] = useState<any>([]); // 展开的keys
  const [searchValue, setSearchValue] = useState<string>(''); // 搜索的内容
  const [serachLoading, setSearchLoading] = useState<boolean>(false); // 分组类目数据搜索时的loading

  useEffect(() => {
    getOperationGroupList();
    getGroupCategory();
  }, []);

  // 获取运维组列表
  const getOperationGroupList = async() => {
    try {
      setLoading(true);
      const res = await operationGroupListRequest();
      setOperationGroupList(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 获取分组数据
  const getGroupCategory = async() => {
    const res = await groupCategoryRequest();
    setCategoryData(res.data?.data || []);
    setAllCategoryData(res.data?.data || []);
  }

  // 运维组选择事件
  const onGroupChange = (checked: boolean, group: any) => {
    resetCategoryData();
    setOperateType('detail');
    if (checked) {
      setSelectGroup(group);
      setCheckedKeys(group?.cateId || []);
    } else {
      setSelectGroup(null);
      setCheckedKeys([]);
    }
  }

  // 重置分组树数据
  const resetCategoryData = () => {
    setCategoryData(allCategoryData);
    setExpandedKeys([]);
    setSearchValue('');
  }

  // 点击添加运维组
  const addGroupClick = () => {
    if (operateType === 'add') {
      return;
    }
    setSelectGroup(null);
    setOperateType('add');
    setCheckedKeys([]);
    resetCategoryData();
  }

  // 点击编辑运维组
  const editGroupClick = () => {
    setOperateType('edit');
    setGroupName(selectGroup?.title)
  }

  // 点击取消
  const cancelClick = () => {
    setCategoryData(allCategoryData);
    setSearchValue('');
    if (operateType === 'add') {
      setExpandedKeys([]);
    }
    setOperateType('detail');
    setCheckedKeys(selectGroup?.cateId || []);
    setGroupName('');
  }

  // 保存运维组
  const saveGroup = async() => {
    if (!groupName) {
      message.error('请填写运维组名称');
      return;
    }
    if (!checkedKeys.length) {
      message.error('请选择分组');
      return;
    }
    const params: UpsertOperationGroupParams = {
      cateIds: checkedKeys,
      title: groupName,
    }
    if (operateType === 'edit') {
      params.id = selectGroup.id;
    }
    const res = await upsertOperationGroupRequest(params);
    if (res.data?.code === 0) {
      message.success("保存成功");
      getOperationGroupList();
      setOperateType('detail');
      setGroupName('');
    } else {
      message.error("保存失败");
    }
  }

  // 删除运维组
  const deleteGroup = async(id: number) => {
    const res = await deleteOperationGroupRequest(id);
    if (res.data?.code === 0) {
      message.success("删除成功");
      getOperationGroupList();
      if (id === selectGroup?.id) {
        setSelectGroup(null);
        setGroupName('');
      }
    } else {
      message.error("删除失败");
    }
  }

  // 获取当前操作的所有节点id
  const getCheckIds = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      const allIds: any = [];
      const deep = (innerData: any) => {
        innerData.forEach((item: any) => {
          if (!item?.children?.length) {
            allIds.push(item.id);
          }
          if (Array.isArray(item.children) && item.children.length > 0) {
            deep(item.children);
          }
        });
      };
      deep(data);
      return allIds;
    } else {
      return [];
    }
  }

  // 树节点勾选
  const onTreeCheck = (newCheckedKeys: any, e: any) => {
    // 本次勾选或取消勾选的节点id
    const checkIds: any = getCheckIds([e.node]);
    if (e.checked) {
      // 勾选
      setCheckedKeys(uniq([...checkedKeys, ...checkIds]));
    } else {
      // 取消勾选
      const filterCheckedKeys = checkedKeys.filter((item: any) => !checkIds.includes(item));
      setCheckedKeys(filterCheckedKeys);
    }
  }

  // 过滤匹配搜索的数据
  const filterCategoryData = (data: any, value: string) => {
    const newData: any = [];
    data.forEach((item: any) => {
      let obj: any = {};
      if (item.title.includes(value)) {
        obj = {
          ...item,
        };
        newData.push(obj);
      } else if (Array.isArray(item.children) && item.children.length > 0) {
        const tempData = filterCategoryData(item.children, value);
        obj = {
          ...item,
          children: tempData,
        };
        // 当children不为空时才保留该数据
        if (obj.children.length > 0) {
          expandRef.current.push(item.id);
          newData.push(obj);
        }
      }
    });
    return newData;
  };

  // 搜索框搜索
  const handleSearch = async (value: string) => {
    if (value) {
      setSearchLoading(true);
      expandRef.current = [];
      setTimeout(() => {
        const filterData = filterCategoryData(allCategoryData, value);
        setExpandedKeys(expandRef.current);
        setCategoryData(filterData);
        setSearchLoading(false);
      }, 0)
    } else {
      expandRef.current = [];
      setExpandedKeys([]);
      setCategoryData(allCategoryData);
    }
  }

  // 防抖的搜索框搜索
  const debounceHandleSearch = useMemo(() => {
    return debounce(handleSearch, 600);
  }, [allCategoryData]);

  // 搜索框输入改变
  const onInputChange = (value: string) => {
    setSearchValue(value);
    debounceHandleSearch(value);
  }

  return (
    <div className={styles.operationGroupManage}>
      <div className={styles.leftPart}>
        <div className={styles.buttonBox}>
          <PermissionButton
            operateType='/car-admin/operation-group-manage/add'
            type="primary"
            onClick={addGroupClick}
          >
            添加运维组
          </PermissionButton>
        </div>
        <LoadingWrapper spinning={loading} autoHeight>
          <div className={styles.groupBox}>
            {operationGroupList.map((item: any) => (
              <div className={styles.groupItem} key={item.id}>
                <CheckableTag
                  key={item.id}
                  checked={selectGroup?.id === item.id}
                  onChange={(checked: boolean) => onGroupChange(checked, item)}
                >
                  {item.title}
                </CheckableTag>
                <Popconfirm
                  title="确定要删除该运维组吗？"
                  onConfirm={() => deleteGroup(item.id)}
                >
                  <PermissionButton operateType='/car-admin/operation-group-manage/delete' type="link">删除</PermissionButton>
                </Popconfirm>
              </div>
            ))}
          </div>
        </LoadingWrapper>
      </div>
      {/* 选择运维组或添加时显示树状分组 */}
      {(selectGroup || operateType === 'add') ? <div className={styles.rightPart}>
        <div className={styles.title}>
          {operateType === 'detail' ?
            <span>{selectGroup?.title}</span> :
            <Input
              placeholder="请输入运维组名称"
              style={{ width: 200 }}
              value={groupName}
              onChange={(e: any) => setGroupName(e.target.value)}
            />
          }
          {operateType === 'detail' ? 
            <PermissionButton
              operateType='/car-admin/operation-group-manage/edit'
              type="primary"
              onClick={editGroupClick}
            >
              编辑
            </PermissionButton> :
            <div>
              <Button onClick={cancelClick}>取消</Button>
              <Button type="primary" onClick={saveGroup} style={{ marginLeft: '16px' }}>保存</Button>
            </div>
          }
        </div>
        <div className={styles.treeBox}>
          <div className={styles.searchBox}>
            <Input
              placeholder='可输入分组名称进行搜索'
              onChange={(e: any) => onInputChange(e.target.value)}
              value={searchValue}
            />
          </div>
          <LoadingWrapper spinning={serachLoading} autoHeight>
            <div className={styles.tree}>
              <Tree
                checkable
                disabled={operateType === 'detail'}
                treeData={categoryData}
                fieldNames={{ key: 'id' }}
                checkedKeys={checkedKeys}
                expandedKeys={expandedKeys}
                onExpand = {(newExpandedKeys: any) => {
                  expandRef.current = newExpandedKeys;
                  setExpandedKeys(newExpandedKeys);
                }}
                onCheck={onTreeCheck}
              />
            </div>
          </LoadingWrapper>
        </div>
      </div> :
      <div className={styles.placeholderBox}>
        选择左侧运维组可以查看、编辑车队
      </div>}
    </div>
  );
}

export default OperationGroupManage;