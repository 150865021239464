/**
 * 使用WebSocket的hooks
 */
import { useEffect, useRef, useState } from 'react';
import { getWebSocketPath } from '@/utils/common';
import { useDebounce } from '@/utils/ownHooks';

export interface UseWebSocketParams {
  webSocketPath: string; // WebSocket地址
  webSocketFlag: string; // WebSocket的标识(用于console的提示信息区分)
  onmessage: (data: string) => void; // onmessage处理方法
  heartInterval?: number; // 维持WebSocket心跳的时间间隔
  reconnectTimes?: number; // WebSocket重连次数
  onOpenCallback?: Function; // onopen的回调方法
}

export const useWebSocket = ({
  webSocketPath,
  webSocketFlag,
  onmessage,
  heartInterval = 30000,
  reconnectTimes = 3,
  onOpenCallback
}: UseWebSocketParams) => {
  const onOpenCallbackRef = useRef(onOpenCallback); // onopen的回调方法
  const wsRef = useRef<any>(); // WebSocket实例
  const timerRef = useRef<any>(); // WebSocket维持心跳的定时器
  const reconnectTimeRef = useRef<number>(0); // WebSocket重连次数
  const lockReconnectRef = useRef<boolean>(false); // 避免断开/错误时重复连接
  const [wsConnected, setWsConnected] = useState(false); // WebSocket是否是连接状态

  // 监听onOpenCallback的变化
  useEffect(() => {
    onOpenCallbackRef.current = onOpenCallback;
  }, [onOpenCallback]);

  // WebSocket推送内容(维持心跳)
  const wsSend = () => {
    if (wsRef.current?.readyState === 1) {
      setWsConnected(true);
      // 如果连接是打开的，发送信息
      wsRef.current?.send(webSocketPath);
    }
    timerRef.current = setTimeout(() => {
      wsSend();
    }, heartInterval);
  };

  // WebSocket重连
  const wsReconnect = () => {
    if (lockReconnectRef.current) {
      return;
    }
    timerRef.current && clearTimeout(timerRef.current);
    if (reconnectTimeRef.current < reconnectTimes) {
      // 如果连接不是打开的且有网络的状态下尝试重连
      if (wsRef.current?.readyState !== 1 && navigator.onLine) {
        lockReconnectRef.current = true;
        setTimeout(() => {
          reconnectTimeRef.current += 1;
          openWs();
          lockReconnectRef.current = false;
        }, 3000);
      }
    } else {
      console.log(`${webSocketFlag}WebSocket重连${reconnectTimes}次失败`);
    }
  };

  // WebSocket重连(防抖)
  const debounceWsReconnect = useDebounce(wsReconnect);

  // 初始化WebSocket
  const openWs = () => {
    try {
      wsRef.current = new WebSocket(getWebSocketPath(webSocketPath, true));
      wsRef.current.onopen = () => {
        setWsConnected(true);
        reconnectTimeRef.current = 0;
        console.log(`${webSocketFlag}WebSocket连接打开`, wsRef.current);
        onOpenCallbackRef.current && onOpenCallbackRef.current();
        wsSend();
      };
      wsRef.current.onmessage = (event: any) => {
        setWsConnected(true);
        if (event.data) {
          onmessage(event.data);
        }
      };
      wsRef.current.onerror = (event: any) => {
        setWsConnected(false);
        console.log(`${webSocketFlag}WebSocket连接失败`, event, wsRef.current);
        debounceWsReconnect();
      };
      wsRef.current.onclose = (event: any) => {
        setWsConnected(false);
        console.log(`${webSocketFlag}WebSocket连接断开`, event, wsRef.current);
        if (event.code !== 1000) {
          // 正常关闭时不再重新连接
          debounceWsReconnect();
        }
      };
    } catch (error) {
      console.log(error);
    }
  };

  // 网络断开
  const offline = () => {
    console.log(`${webSocketFlag}网络断开`, wsRef.current);
  };

  useEffect(() => {
    openWs();
    const online = () => {
      console.log(`${webSocketFlag}网络连接`, wsRef.current);
      openWs();
    };
    // 监听网络重新上线
    window.addEventListener('online', online);
    window.addEventListener('offline', offline);
    return () => {
      if (wsRef.current) {
        wsRef.current.onerror = null;
        wsRef.current.onclose = null;
      }
      wsRef.current?.close();
      wsRef.current = null;
      timerRef.current && clearTimeout(timerRef.current);
      window.removeEventListener('online', online);
      window.removeEventListener('offline', offline);
    };
  }, []);

  return { wsConnected, openWs };
};
