/**
 * 在线记录相关请求
 */
import request from "@/utils/request";

export interface OnlineRecordListRequestParams {
  plateNumber: string;
  year: string;
}

// 获取在线记录数据
export const onlineRecordListRequest = (params: OnlineRecordListRequestParams) => {
  return request.get('/api/v2/car/onlineRecord', {
    params,
  })
}