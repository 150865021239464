/**
 * 行车视频分析的相关请求
 */
import request from "@/utils/request";

export interface DriveVideoParams {
  page: number,
  limit: number;
  beginDate?: string;
  endDate?: string;
  type?: Array<string>;
  cateIds?: Array<string>;
}

// 行车视频分析
export const driveVideoRequestPath = '/api/v2/car/drivingBehaviorAnalysisOnlyVideo';
export const driveVideoRequest = (data: DriveVideoParams) => {
  return request.post(driveVideoRequestPath, data)
}

// 评论行车记录仪视频
export const commentVideoRequest = (data: any) => {
  return request.post('/api/v2/tag/tagVideo', data)
}

// 获取行车记录仪视频评论信息
export const getVideoCommentRequest = (params: any) => {
  return request.get('/api/v2/car/drivingBehaviorAnalysisOnlyTag', {
    params
  })
}

// 行车视频分析的数据统计
export const driveVideoDataRequest = () => {
  return request.get('/api/v2/car/drivingBehaviorAnalysisPercent')
}

// 删除视频评价
export const deleteVideoCommentRequest = (data: any) => {
  return request.delete('/api/v2/tag/deleteVideoTag', { data })
}
