/**
 * 分组选择(TreeSelect)
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TreeSelect, TreeSelectProps } from 'antd';
import { groupCategoryRequest } from '@/service/groupManage';
import { filterMultipleCateData } from '@/utils/cate';

interface CategoryTreeSelectProps extends TreeSelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string) => void; // 选择时调用的方法
}
const CategoryTreeSelect = (props: CategoryTreeSelectProps) => {
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;
  const { value, onChange } = props;
  const [categoryData, setCategoryData] = useState([]);
  const [selectValue, setSelectValue] = useState<string | undefined>();

  useEffect(() => {
    getCategoryData();
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 获取分组树形数据
  const getCategoryData = async () => {
    const res = await groupCategoryRequest();
    const data = res.data?.data || [];
    // 针对有多个分组的账号特殊过滤分组数据
    if (userDetail.multiCate) {
      setCategoryData(filterMultipleCateData(data, JSON.parse(userDetail.multiCate)));
    } else {
      setCategoryData(data);
    }
  }

  // 选择树节点
  const onTreeChange = (value: string) => {
    setSelectValue(value);
    onChange && onChange(value)
  }

  return (
    <TreeSelect
      {...props}
      treeNodeFilterProp="title"
      fieldNames={{ value: 'id' }}
      allowClear
      value={selectValue}
      style={{ width: '100%' }}
      showSearch
      placeholder="请选择"
      treeData={categoryData}
      onChange={onTreeChange}
      dropdownMatchSelectWidth={false}
      showCheckedStrategy="SHOW_PARENT"
      maxTagCount="responsive"
    />
  )
}

export default CategoryTreeSelect;
  