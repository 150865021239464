/**
 * 工单列表
 */
import React, { useState, useEffect } from 'react';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { workOrderListColumns } from '@/constants/workOrder';
import { workOrderListRequest, workOrderListRequestPath } from '@/service/workOrder';

interface WorkOrderListProps {
  params: any
}
const WorkOrderList = (props: WorkOrderListProps) => {
  const { params } = props;
  const [searchParams, setSearchParams] = useState(params); // 工单的搜索参数
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: workOrderListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  useEffect(() => {
    setSearchParams(props.params);
  }, [props.params])

  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as 'left',
    },
    ...workOrderListColumns,
  ];

  return (
    <div>
      <div style={{ textAlign: 'right', marginBottom: '8px'}}>
        <ExportButton
          type="primary"
          url={workOrderListRequestPath}
          fileName='工单列表'
          getParamsFunc={() => ({...searchParams, export: true})}
          requestMethod="GET"
          permissionKey='/car-admin/work-order-operation/order-list-export'
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        scroll={{ y: '50vh' }}
      />
    </div>
  )
}

export default WorkOrderList;