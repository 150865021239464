/**
 * 数据大屏详情
 */
import React, { useEffect, useState } from 'react';
import PagesOne from './components/pagesOne';
import PagesTwo from './components/pagesTwo';
import PagesThree from './components/pagesThree';
import PagesFour from './components/pagesFour';
import PagesFive from './components/pagesFive';
import PagesSix from './components/pagesSix';
import PagesSeven from './components/pagesSeven';
import PagesEight from './components/pagesEight';
import PagesNine from './components/pagesNine';

const DataSize = () => {
  const [dispage, setDispage] = useState(<PagesOne/>)
  useEffect(() => {
    ceshi()
  }, [])
  
  const ceshi = () => {
    let index = 1;
    setInterval(function(){
      if (index == 0) {
        setDispage(<PagesOne/>)
      } else if (index == 1) {
        setDispage(<PagesTwo/>)
      } else if (index == 2) {
        setDispage(<PagesThree/>)
      } else if (index == 3) {
        setDispage(<PagesFour/>)
      } else if (index == 4) {
        setDispage(<PagesFive/>)
      } else if (index == 5) {
        setDispage(<PagesSix/>)
      } else if (index == 6) {
        setDispage(<PagesSeven/>)
      } else if (index == 7) {
        setDispage(<PagesEight/>)
      } else if (index == 8) {
        setDispage(<PagesNine/>)
        index = -1
      }
      index++;
    },10000);
  }

  return (
    <div>
        {dispage}
    </div>
  )
}

export default DataSize;
