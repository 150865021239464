/**
 * 行车视频分析
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, Select, Modal, message } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { isEmpty, groupBy } from 'lodash';
import { driveVideoRequest, driveVideoRequestPath, driveVideoDataRequest } from '@/service/driveVideo';
import { tagListRequest } from '@/service/tagManage';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import AlarmInfoModal from '@/components/alarmInfoModal';
import RecorderVideoPreviewModal from '@/components/recorderVideoPreviewModal';
import PermissionButton from '@/components/permissionButton';
import DetailModal from '@/pages/driveAnalysis/components/detailModal';
import StatisticsCard from '@/pages/home/components/statisticsCard';
import { useTable } from '@/utils/useTable';
import { operateTimeRange } from '@/utils/operateSearchParams';
import { ownRound } from '@/utils/math';
import { renderEventType } from '@/utils/driveAnalysis';
import { getGroupVideoTag } from '@/utils/driveVideo';
import { getInnerPermissionEnable } from '@/utils/permission';
import { driveVideoErrorMessage } from '@/constants/driveBehavior';
import { isReviewData, reviewRateData, driveVideoData } from '@/constants/driveVideo';
import CommentVideoModal from './components/commentVideoModal';
import CommentVideoDetail from './components/commentVideoDetail';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const DriveVideo = () => {
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')}`,
    endDate: `${dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')}`,
    type: ['3_1', '3_2', '3_3', '3_5'],
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储
  const [summaryData, setSummaryData] = useState<any>({}); // 视频统计数据
  const [videoTags, setVideoTags] = useState<any>([]); // 视频相关标签
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: driveVideoRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 获取视频统计数据
  const getDriveVideoData = async() => {
    const res = await driveVideoDataRequest();
    setSummaryData(res.data?.data || {});
  }

  // 获取视频评价相关标签
  const getVideoTagData = async() => {
    const res = await tagListRequest();
    const tagData = res.data?.data || [];
    setVideoTags(getGroupVideoTag(tagData, true));
  }

  useEffect(() => {
    getDriveVideoData();
    getVideoTagData();
  }, []);

  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams = operateTimeRange(params);
    if (params.reviewRate) {
      const reviewRateRange = params.reviewRate.split(',');
      newSearchParams.floorRate = reviewRateRange[0];
      newSearchParams.ceilingRate = reviewRateRange[1];
    }
    delete newSearchParams.reviewRate;
    newSearchParams.tagIds = getTagGroup(params.tagIds);
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    if (newSearchParams.type?.length) {
      setSearchParams(newSearchParams);
    } else {
      message.error('请选择事件类型')
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const newSearchParams = operateSearchParams(values, true);
    if (newSearchParams.type?.length) {
      return newSearchParams;
    } else {
      message.error('请选择事件类型')
    }
  }

  // 选择的标签处理成分组形式数据
  const getTagGroup = (tagIds: any) => {
    if (tagIds?.length) {
      const allTags: any = [];
      videoTags.forEach((item: any) => allTags.push(...item.children));
      const matchTags = allTags.filter((item: any) => tagIds.includes(item.value));
      const groupTag = groupBy(matchTags, 'tagSort');
      const groupTagValues: any = [];
      Object.values(groupTag).forEach((item: any) => groupTagValues.push(item.map((item: any) => item.value)));
      return groupTagValues;
    } else {
      return [];
    }
  }

  // 点击详细信息显示弹窗
  const showInfo = (data: any) => {
    if (data.type === "alarm") {
      setOperateInfo({ type: 'alarm', data });
    } else if (data.type === "signInfo") {
      setOperateInfo({ type: 'info', data });
    }
  }

  // 重新获取数据
  const reloadData = () => {
    getData();
    getDriveVideoData();
  }

  // 统计卡片点击
  const statisticsCardClick = (value: number) => {
    form.setFieldsValue({
      reviewCount: value,
      time: [dayjs('2022-07-04'), dayjs()],
      tagIds: [],
      reviewRate: '',
      type: ['3_1', '3_2', '3_3', '3_5'],
    });
    form.submit();
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌',
      dataIndex: 'plateNumber',
      width: 110,
    },
    {
      title: '时间',
      dataIndex: 'reportedTime',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '事件类型',
      dataIndex: 'type',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: number | string, item: any) => <RenderEllipsisColumn text={renderEventType(item)} /> 
    },
    {
      title: '车身车速',
      dataIndex: 'vehicleSpeed',
      width: 100,
      render: (value: string) => ownRound(value, 1)
    },
    {
      title: 'GPS车速',
      dataIndex: 'gpsSpeed',
      width: 100,
      render: (value: string) => ownRound(value, 1)
    },
    {
      title: '描述',
      dataIndex: 'descText',
      width: 280,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '评分',
      dataIndex: 'avgRate',
      width: 70,
      render: (value: string) => ownRound(value, 1)
    },
    {
      title: '评价状态',
      dataIndex: 'userId',
      width: 90,
      render: (value: any) => (value || []).includes(userDetail.id) ? '我已评价' : null,
    },
    {
      title: '视频状态',
      dataIndex: 'needTrans',
      width: 90,
      render: (value: boolean) => value ? '' : '已加字幕',
    },
    {
      title: '评价次数',
      dataIndex: 'commentJson',
      width: 90,
      render: (value: any) => (value || []).length,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 260,
      render: (value: any, item: any, index: number) => {
        return (
          <div className={styles.tableOperateColumn}>
            {item.info && <Button type="link" onClick={() => showInfo(item)}>详细信息</Button>}
            <Button type="link" onClick={() => setOperateInfo({ type: 'video', data: item })}>播放</Button>
            {item.type === 'alarm' && <PermissionButton
              operateType='/car-admin/drive-video/videoComment'
              type="link"
              onClick={() => setOperateInfo({ type: 'comment', data: item })}
            >
              新增评价
            </PermissionButton>}
            {isEmpty(item.tags) || <Button type="link" onClick={() => setOperateInfo({ type: 'commentDetail', data: item })}>评价详情</Button>}
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Row gutter={24}>
        <Col span={8}>
          <StatisticsCard
            style={{ border: '1px solid #eee '}}
            title="无任何评价的视频数量"
            count={summaryData.nullReviewCount}
            percent={summaryData.nullReviewCountPct && `${summaryData.nullReviewCountPct}%`}
            clickFunc={() => statisticsCardClick(0)}
          />
        </Col>
        <Col span={8}>
          <StatisticsCard
            style={{ border: '1px solid #eee '}}
            title="有评价的视频数量"
            count={summaryData.haveReviewCount}
            percent={summaryData.haveReviewCountPct && `${summaryData.haveReviewCountPct}%`}
            clickFunc={() => statisticsCardClick(1)}
          />
        </Col>
        <Col span={8}>
          <StatisticsCard
            style={{ border: '1px solid #eee '}}
            title="评价两次及以上的视频数量"
            count={summaryData.moreReviewCount}
            percent={summaryData.moreReviewCountPct && `${summaryData.moreReviewCountPct}%`}
            clickFunc={() => statisticsCardClick(2)}
          />
        </Col>
      </Row>
      <Form onFinish={onSearch} form={form} style={{ marginTop: '16px' }}>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker showTime allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="是否评价" name="reviewCount">
              <Select options={isReviewData} allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="评价分数" name="reviewRate">
              <Select options={reviewRateData} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item label="标签筛选" name="tagIds">
              <Select allowClear mode="multiple" maxTagCount="responsive">
                {videoTags.map((item: any) => (
                  <Select.OptGroup label={item.label} key={item.field}>
                    {item.children.map((item1: any) => (
                      <Select.Option value={item1.value} key={item1.value} disabled={item1.disabled}>{item1.label}</Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label="事件筛选" name="type" initialValue={searchParams.type}>
              <Select allowClear mode="multiple" options={driveVideoData} maxTagCount="responsive" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={driveVideoRequestPath}
                  fileName='行车视频分析'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/drive-video/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        columnFilter={false}
      />
      {operateInfo.type === 'alarm' && <AlarmInfoModal closeModal={() => setOperateInfo({})} data={operateInfo.data} infoKey="info" />}
      {operateInfo.type === 'info' && <DetailModal closeModal={() => setOperateInfo({})} data={operateInfo.data} />}
      {/* 视频播放的弹窗 */}
      {operateInfo.type === 'video' && <RecorderVideoPreviewModal
        url={operateInfo.data?.attachUrl}
        fileName={`${operateInfo.data?.reportedTime}  ${renderEventType(operateInfo.data)}`}
        closeModal={() => setOperateInfo({})}
        showTransferButton={operateInfo.data?.needTrans && operateInfo.data?.type === 'alarm'}
        resourceKey={operateInfo.data?.resourceKey}
        transferCallback={getData}
        showTag
        showComment={Boolean(operateInfo.data.hasAttach) && operateInfo.data.type === 'alarm' && getInnerPermissionEnable('/car-admin/drive-video/videoComment')}
        title={`${operateInfo.data?.reportedTime}  ${renderEventType(operateInfo.data)}`}
        errorMessage={driveVideoErrorMessage?.[operateInfo.data.eventCode]}
        commentCallback={reloadData}
        needTrans={operateInfo.data?.needTrans}
        rawUrl={operateInfo.data?.rawUrl}
      />}
      {/* 视频评价的弹窗 */}
      {operateInfo.type === 'comment' && <CommentVideoModal
        closeModal={() => setOperateInfo({})}
        resourceKey={operateInfo.data?.resourceKey}
        reloadData={reloadData}
      />}
      {/* 评价详情的弹窗 */}
      {operateInfo.type === 'commentDetail' && <Modal
        title="视频评价详情"
        visible
        onCancel={() => setOperateInfo({})}
        onOk={() => setOperateInfo({})}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
        centered
      >
        <CommentVideoDetail resourceKey={operateInfo.data?.resourceKey} reloadData={reloadData} />
      </Modal>}
      
    </Card>
  )
}

export default DriveVideo;
