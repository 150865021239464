// extracted by mini-css-extract-plugin
export default {"carDiagnosisTable":"carDiagnosisTable_carDiagnosisTable__RQraF"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carDiagnosisTable\":\"carDiagnosisTable_carDiagnosisTable__RQraF\"}";
        // 1726639069072
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  