/**
 * AEB制动关闭相关请求
 */
import request from '@/utils/request';

export interface AebCloseListRequestParams {
  page: number;
  limit: number;
  beginDate: string;
  endDate: string;
  idList: Array<number>;
}

// 获取AEB关闭列表
export const aebCloseListRequest = (data: AebCloseListRequestParams) => {
  return request.post('/api/v2/carAebClosed/list', data);
};

// 获取AEB关闭时间图表数据
export const aebCloseTimeChartRequest = (data: AebCloseListRequestParams) => {
  return request.post('/api/v2/carAebClosed/timeChart', data);
};

// 获取AEB关闭时间图表数据(每天)
export const aebCloseDailyTimeChartRequest = (params: {
  carId: number;
  day: string;
}) => {
  return request.get('/api/v2/carAebClosed/dailyAebClosedTimeSlots', {
    params
  });
};

// 获取AEB关闭车辆数图表数据
export const aebCloseCountChartRequest = (data: AebCloseListRequestParams) => {
  return request.post('/api/v2/carAebClosed/aebOffCount', data);
};

// AEB关闭列表导出路径
export const aebCloseListExportRequestPath = '/api/v2/carAebClosed/export';
