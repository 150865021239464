/**
 * 图片预览弹窗
 */
import React from 'react';
import { Modal, Image, Button } from 'antd';
import PermissionButton from '@/components/permissionButton';
import { downloadFile } from '@/utils/download';

interface ImagePreviewModalProps {
  url: string; // 图片url
  fileName: string; // 名称
  closeModal: () => void; // 关闭弹窗的方法
  showDownload?: boolean; // 是否显示下载按钮
  downloadPermissionKey?: string; // 是否能下载
  dataIndex?: number; // 当前图片下标
  dataArray?: any; // 当前图片数组
  pubIndex?: number; // 非安装图片下标
  pubArray?: any; // 非安装图片数组
}
const ImagePreviewModal = (props: ImagePreviewModalProps) => {
  const { url, closeModal, fileName = "图片", showDownload = true, downloadPermissionKey = '', dataIndex, dataArray, pubIndex,pubArray} = props;
  const [currentUrl, setCurrentUrl] = React.useState(url); //当前路径
  const [dataCurrentIndex, setDataCurrentIndex] = React.useState(Number(dataIndex));  //data当前图片的下标
  const [pubCurrentIndex, setPubCurrentIndex] = React.useState(Number(pubIndex));  //pub当前图片的下标
  const dataArrayCurrent: any = dataArray
  const pubArrayCurrent: any = pubArray

  //上一张图片
  const prevImage = () => {
    if(dataArrayCurrent == undefined){
      if (pubCurrentIndex !== 0) {
        setPubCurrentIndex(pubCurrentIndex - 1);
        setCurrentUrl(pubArrayCurrent[pubCurrentIndex - 1].url);
      }
    }else{
      if (dataCurrentIndex !== 0) {
        setDataCurrentIndex(dataCurrentIndex - 1);
        setCurrentUrl(dataArrayCurrent[dataCurrentIndex - 1].data.url);
      }
    }
  }
  //下一张图片
  const nextImage = () => {
    if(dataArrayCurrent == undefined){
      if (pubCurrentIndex < pubArrayCurrent.length - 1) {
        setPubCurrentIndex(pubCurrentIndex + 1);
        setCurrentUrl(pubArrayCurrent[pubCurrentIndex + 1].url);
      }
    }else{
      if (dataCurrentIndex < dataArrayCurrent.length - 1) {
        setDataCurrentIndex(dataCurrentIndex + 1);
        setCurrentUrl(dataArrayCurrent[dataCurrentIndex + 1].data.url)
      }
    }
  }

  return (
    <Modal
      title="图片预览"
      visible
      footer={null}
      onCancel={closeModal}
      centered
    >
      <Image src={currentUrl} alt="图片预览" width="100%" />
      <Button onClick={prevImage}>上一张</Button>
      <Button onClick={nextImage}>下一张</Button>

      {showDownload &&
        <PermissionButton
          type="primary"
          onClick={() => downloadFile(currentUrl, `${fileName}.png`)}
          operateType={downloadPermissionKey}
          style={{ marginTop: '12px' }}>
          下载
        </PermissionButton>
      }
    </Modal>
  )
}

export default ImagePreviewModal;
