/**
 * 用户登录信息相关数据存储
 */
import localStorageUtil from '@/utils/localStorageUtil';
import history from '@/utils/history';

interface PayloadDataType {
  access_token?: string;
  userDetail?: any;
  menuData: any;
  allPagePermission: any;
  allInnerPermission: any;
  allSubMenuKeys: any;
  loginSuccessJumpPath?: string;
}

interface ActionType {
  type: string;
  payload?: PayloadDataType
}
const initState = {
  access_token: localStorageUtil.getItem('access_token'), // token
  userDetail: localStorageUtil.getItem('userDetail') || {}, // 用户详细信息
  menuData: [], // 用户显示的菜单数据
  allPagePermission: [], // 用户所有页面级别权限
  allInnerPermission: [], // 用户所有页面内部权限点
  allSubMenuKeys: [], // 菜单所有展开的导航key
  loginSuccessJumpPath: '', // 登录成功之后跳转的路由(用户访问某页面时退出登录后，重新登录回到之前访问的页面)
}

export const userStore = (state = initState, action: ActionType) => {
  const { type, payload } = action
  switch (type) {
    case 'login':
      localStorageUtil.setItem('access_token', payload?.access_token);
      localStorageUtil.setItem('userDetail', payload?.userDetail);
      return {
        access_token: payload?.access_token,
        userDetail: payload?.userDetail,
        menuData: payload?.menuData,
        allPagePermission: payload?.allPagePermission,
        allInnerPermission: payload?.allInnerPermission,
        allSubMenuKeys: payload?.allSubMenuKeys,
      };
    case 'logout':
      localStorageUtil.removeItem('access_token');
      localStorageUtil.removeItem('userDetail');
      const groupKey = state?.userDetail?.groupKey;
      if (groupKey) {
        history.replace(`/${groupKey}/login`);
      } else {
        history.replace('/login');
      }
      return {
        access_token: '',
        userDetail: {},
        menuData: [],
        allPagePermission: [],
        allInnerPermission: [],
        allSubMenuKeys: [],
        loginSuccessJumpPath: payload?.loginSuccessJumpPath
      };
    case 'refreshUserInfo':
      localStorageUtil.setItem('userDetail', payload?.userDetail);
      return {
        ...state,
        userDetail: payload?.userDetail,
        menuData: payload?.menuData,
        allPagePermission: payload?.allPagePermission,
        allInnerPermission: payload?.allInnerPermission,
        allSubMenuKeys: payload?.allSubMenuKeys,
      };
    default:
      return state;
  }
}