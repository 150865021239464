/**
 * 昆仑山设备状态表格配置
 */
import React from 'react';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { controlerCanArr, controlerCanMap } from '@/constants/klsDeviceStatus';
import { renderDifferentTableCell } from '@/utils/common';
import { renderKlsDeviceStatusColumnText } from '@/utils/klsDeviceStatus';
import { formatTime } from '@/utils/formatTime';

// 生成控制器CAN相关的列配置
const getControlerCanColumns = () => {
  const controlerCanColums: any = [];
  controlerCanArr.forEach((item: number) => {
    const canMatch = controlerCanMap[item];
    const moreWidth = [1, 5].includes(item); // 表格列宽度大一些
    controlerCanColums.push({
      title: `CAN${item}(${canMatch})总线状态`,
      dataIndex: `controlerCan${item}Status`,
      width: moreWidth ? 120 : 100
    });
    controlerCanColums.push({
      title: `CAN${item}(${canMatch})通讯错误状态`,
      dataIndex: `controlerCan${item}CommErrorStatus`,
      width: moreWidth ? 130 : 110
    });
    controlerCanColums.push({
      title: `CAN${item}(${canMatch})通讯错误代码`,
      dataIndex: `controlerCan${item}CommErrorId`,
      width: moreWidth ? 130 : 110
    });
    controlerCanColums.push({
      title: `CAN${item}(${canMatch})通讯错误方向`,
      dataIndex: `controlerCan${item}CommErrorPoint`,
      width: moreWidth ? 130 : 110
    });
  });
  return controlerCanColums;
};

// 生成超声波探头故障列配置
const getAllUltrasonicFailureColumns = (isKlsV3?: boolean) => {
  // 昆仑山3.0才显示
  if (isKlsV3) {
    const controlerCanColums: any = [];
    new Array(12).fill(1).forEach((item: number, index: number) => {
      controlerCanColums.push({
        title: `超声波探头${index + 1}故障`,
        dataIndex: `ultra${index + 1}FailureId`,
        width: 80
      });
    });
    return controlerCanColums;
  } else {
    return [];
  }
};

// 表格列配置
export const getKlsDeviceStatusColumns = (allData: any, isKlsV3?: boolean) => {
  return [
    {
      title: '上报时间',
      dataIndex: 'reportedTime',
      width: 80,
      render: (value: any) => (
        <RenderEllipsisColumn text={formatTime(value)} ellipsis />
      ),
      fixed: 'left' as const,
      noCommonRender: true,
      noCommonCell: true
    },
    {
      title: '相机故障',
      dataIndex: 'cameraFailureId',
      width: 60
    },
    {
      title: '比例阀故障',
      dataIndex: 'proportionFailureId',
      width: 60
    },
    {
      title: '车速信息是否有效',
      dataIndex: 'vehicleSpeedIsValid',
      width: 80,
      hide: isKlsV3 // 昆仑山3.0不显示
    },
    {
      title: 'AEB开关状态',
      dataIndex: 'aebSwitchStatus',
      width: 70
    },
    {
      title: 'LDW开关状态',
      dataIndex: 'ldwSwitchStatus',
      width: 70
    },
    {
      title: 'AEB开关源',
      dataIndex: 'aebSwitchSource',
      width: 70
    },
    {
      title: 'LDW开关源',
      dataIndex: 'ldwSwitchSource',
      width: 70
    },
    {
      title: '相机在线状态',
      dataIndex: 'cameraOnlineStatus',
      width: 70
    },
    {
      title: '相机外部环境亮度',
      dataIndex: 'cameraExternalEnvBrightness',
      width: 80,
      noCommonCell: true
    },
    {
      title: '比例阀在线状态',
      dataIndex: 'proportionOnlineStatus',
      width: 80
    },
    {
      title: '超声波在线状态',
      dataIndex: 'ultrasonicOnlineStatus',
      width: 80
    },
    {
      title: '角雷达在线状态',
      dataIndex: 'angleRadarOnlineStatus',
      width: 80,
      hide: !isKlsV3 // 昆仑山3.0才显示
    },
    {
      title: '毫米波在线状态',
      dataIndex: 'milimeterWaveOnlineStatus',
      width: 100,
      render: (value: any) =>
        renderKlsDeviceStatusColumnText(
          value,
          'milimeterWaveOnlineStatus',
          isKlsV3
        ),
      noCommonRender: true
    },
    {
      title: '小屏幕在线状态',
      dataIndex: 'screenOnlineStatus',
      width: 80
    },
    {
      title: '4G在线状态',
      dataIndex: 't4gOnlineStatus',
      width: 70
    },
    {
      title: 'GPS在线状态',
      dataIndex: 'gpsOnlineStatus',
      width: 70
    },
    {
      title: '蓝牙在线状态',
      dataIndex: 'btOnlineStatus',
      width: 70
    },
    {
      title: '车身故障',
      dataIndex: 'vehicleBodyFailureId',
      width: 60,
      hide: isKlsV3 // 昆仑山3.0不显示
    },
    {
      title: '控制器故障',
      dataIndex: 'controlerFailureStatus',
      width: 60,
      hide: isKlsV3 // 昆仑山3.0不显示
    },
    {
      title: '超声波故障',
      dataIndex: 'ultrasonicFailureId',
      width: 60,
      hide: isKlsV3 // 昆仑山3.0不显示
    },
    {
      title: '毫米波故障',
      dataIndex: 'milimeterWaveFailureId',
      width: 60
    },
    {
      title: '小屏幕故障',
      dataIndex: 'screenFailureId',
      width: 60
    },
    {
      title: '4G通讯故障',
      dataIndex: 't4gFailureId',
      width: 60
    },
    {
      title: 'GPS通讯故障',
      dataIndex: 'gpsFailureId',
      width: 60
    },
    {
      title: '蓝牙故障',
      dataIndex: 'btFailureId',
      width: 60
    },
    {
      title: 'IMU故障',
      dataIndex: 'imuFailureId',
      width: 60
    },
    ...getAllUltrasonicFailureColumns(isKlsV3),
    ...getControlerCanColumns()
  ]
    .filter((item: any) => !item.hide)
    .map((item: any) => {
      const returnItem = { ...item };
      const { noCommonRender, noCommonCell, dataIndex } = item;
      if (!noCommonRender) {
        returnItem.render = (value: any) =>
          renderKlsDeviceStatusColumnText(value, dataIndex);
      }
      if (!noCommonCell) {
        returnItem.onCell = (record: any, rowIndex: number) =>
          renderDifferentTableCell(record, rowIndex, dataIndex, allData);
      }
      return returnItem;
    });
};
