/**
 * 车辆风险测评
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, Input, Descriptions } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';
import ExportButton from '@/components/exportButton';
import LoadingWrapper from '@/components/loadingWrapper';
import styles from './index.module.scss';
import { carScoreRequest,carScoreExportPath } from '@/service/carScore';
import { isEmpty } from 'lodash';
import { formatTime } from '@/utils/formatTime';

const { RangePicker } = DatePicker;
const CarScore = () => {
  const [data, setData] = useState<any>({}); // 报告相关数据
  const [loading, setLoading] = useState(false); // 是否在获取报告中
  const [form] = Form.useForm();

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams:any = {...values};
    if (values.time) {
      console.log('newSeaech',newSearchParams);
      newSearchParams.beginTime = dayjs(values.time[0]).format('YYYY-MM-DD'); 
      newSearchParams.endTime = dayjs(values.time[1]).format('YYYY-MM-DD');
      delete newSearchParams.time
    }
    if (newSearchParams) {
      getFleetRiskReport(newSearchParams);
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const params = {...values};
    params.beginTime = formatTime(values.time[0], 'YYYY-MM-DD');
    params.endTime = formatTime(values.time[1], 'YYYY-MM-DD');
    delete params.time;
    params.export = true;
    return params;
  }

  // 获取车辆评分数据
  const getFleetRiskReport = async(data: any) => {
    try {
      setLoading(true);
      const res = await carScoreRequest(data);
      const reportData = res.data?.data || {};
      console.log('reportData',reportData)
      setData(reportData);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Card bordered={false} className={styles.fleetRisk}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="时间"
              name="time"
              initialValue={[dayjs().subtract(5, 'day'), dayjs().subtract(1, 'day')]}
              rules={[{ required: true, message: '请选择时间' }]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }}   picker="month" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="车牌号" name="plateNumber" rules={[{ required: true, message: '请输入车牌号' }]}>
              <Input placeholder="请输入车牌号" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">搜索</Button>
                  <ExportButton
                    url={carScoreExportPath}
                    fileName={`车辆评分`}
                    getParamsFunc={getExportParams}
                    requestMethod="GET"
                    fileSuffix="docx"
                    permissionKey='/car-admin/car-score/export'
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
      <div style={{ visibility: isEmpty(data) ? 'hidden' : 'visible' }}>
          <h2>车辆测评结果</h2>
          <Descriptions bordered column={2}>
            <Descriptions.Item  label="所属车队">{data?.company}</Descriptions.Item>
            <Descriptions.Item  label="车辆状态">{data?.vehicleState}</Descriptions.Item>
            <Descriptions.Item  label="车牌号">{data?.plateNumber}</Descriptions.Item>
            <Descriptions.Item  label="车架号">{data?.frameNumber}</Descriptions.Item>
            <Descriptions.Item span={2} label="考评得分">{data?.score}（分）</Descriptions.Item>
            <Descriptions.Item  label="理赔次数">{data?.claimCount}</Descriptions.Item>
            <Descriptions.Item  label="理赔金额（元）">{data?.claimCost}（元）</Descriptions.Item>
            <Descriptions.Item  label="风险次数">{data?.riskCount}</Descriptions.Item>
            <Descriptions.Item label="HMW百公里次数">{data?.hwm}（次）</Descriptions.Item>
            <Descriptions.Item label="FCW百公里次数">{data?.fcw}（次）</Descriptions.Item>
            <Descriptions.Item label="LDW百公里次数">{data?.ldw}（次）</Descriptions.Item>
            <Descriptions.Item label="AEB百公里次数">{data?.aeb}（次）</Descriptions.Item>
            <Descriptions.Item label="正常工作天数比率">{data?.workingRate}（%）</Descriptions.Item>
            <Descriptions.Item label="因人为因素运维次数">{data?.operationCount}（次）</Descriptions.Item>
            <Descriptions.Item label="客户未配合次数">{data?.mismatchCount}</Descriptions.Item>
            <Descriptions.Item label="故障码出现天数比例">{data?.errorRate}（%）</Descriptions.Item>
            <Descriptions.Item label="周期内车辆运行状态">{data?.carState}</Descriptions.Item>            
          </Descriptions>
          <h2>车辆测评结果说明</h2>
          <Descriptions bordered column={2}>
            <Descriptions.Item span={2} label="">{data?.description}</Descriptions.Item>
          </Descriptions>
        </div>
      </LoadingWrapper>
    </Card>
  )
}

export default CarScore;
