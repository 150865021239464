/**
 * 承保记录相关请求
 */

//查询承保单支付记录
import request from "@/utils/request";
export const insurePayRecordListRequest = (params:any) => {
    return request.get("/api/v2/insure/pay/record/statistic/list", {
      params,
    });
  };