/**
 * 操作严重故障定义(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, message, Select, InputNumber } from 'antd';
import { DeviceTypeEnum } from "@/constants/deviceManage";
import { upsertFatalDefineRequest, fatalMapRequest } from '@/service/fatalDefine';

interface OperateFatalDefineProps {
  reloadData: () => void; // 重新获取定义列表数据的方法
  closeModal: () => void; // 关闭弹窗的方法
  type: string; // 操作类型
  editIndex?: number; // 编辑时的index
  fatalDefineList: any; // 严重故障定义数据
  deviceType: DeviceTypeEnum, // 设备类型
  groupId: number, // 组织id
  fatalDefineId?: number, // 故障定义的id
}
const OperateFatalDefine = (props: OperateFatalDefineProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, editIndex, fatalDefineList, deviceType, groupId, fatalDefineId } = props;
  const [fatalList, setFatalList] = useState([]); // 严重故障项数据
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 获取严重故障项数据
  const getFatalData = async() => {
    const res = await fatalMapRequest({ deviceType });
    const fatalData: any = [];
    const resData = res.data?.data || {};
    Object.keys(resData).forEach((item: any) => {
      fatalData.push({
        value: item,
        label: resData[item],
      })
    });
    setFatalList(fatalData);
  }

  // 编辑的时候赋值
  useEffect(() => {
    getFatalData();
    if (type === 'edit') {
      const params = {...fatalDefineList[editIndex as number]};
      form.setFieldsValue(params);
    }
  }, []);

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const newFatalDefineList = [...fatalDefineList];
      const fatalParams = {...values};
      const matchFatal: any = fatalList.find((item: any) => item.value === values.name) || {};
      fatalParams.ps = matchFatal?.label;
      // 全量更新所有定义
      if (type === 'add') {
        newFatalDefineList.push(fatalParams);
      } else {
        newFatalDefineList[editIndex as number] = fatalParams;
      }
      const params = {
        deviceType,
        groupId,
        id: fatalDefineId,
        content: JSON.stringify(newFatalDefineList),
      };
      const res = await upsertFatalDefineRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }
  
  return (
    <Modal
      title={type === 'add' ? '添加严重故障定义' : '编辑严重故障定义'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
      >
        <Form.Item
          label="故障项"
          name="name"
          rules={[{ required: true, message: '请选择故障项' }]}
        >
          <Select options={fatalList} placeholder="请选择故障项" showSearch optionFilterProp="label" />
        </Form.Item>
        <Form.Item
          label="严重故障阈值"
          name="value"
          rules={[{ required: true, message: '请输入严重故障阈值' }]}
        >
          <InputNumber placeholder='请输入严重故障阈值' style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateFatalDefine;
    