/**
 * 严重故障通知
 */
import React, { useRef, useEffect } from "react";
import { message, notification } from 'antd';
import { fatalInfoRequest, fatalCarSubscribeRequest } from '@/service/fatalNotification';
import PermissionButton from "@/components/permissionButton";

const FatalNotification = () => {
  let timerRef: any = useRef(null); // 存储获取严重故障通知的定时器

  useEffect(() => {
    getFatalNotification();
    return () => {
      notification.destroy();
      timerRef.current && clearTimeout(timerRef.current);
    }
  }, [])

  // 收藏故障车辆
  const fatalCarSubscribe = async(fatalData: any) => {
    const { id, plateNumber, name, ps } = fatalData;
    const res = await fatalCarSubscribeRequest({
      carId: id,
      plateNumber: plateNumber,
      groupTitle: `${name}-${ps}`
    });
    if (res.data?.data) {
      message.success('添加成功');
      notification.close(`${name}${id}`);
    }
  }
  
  // 获取严重故障通知
  const getFatalNotification = async() => {
    const res = await fatalInfoRequest();
    const fatalInfo = res.data?.data || [];
    notification.destroy();
    fatalInfo.forEach((item: any) => {
      notification.open({
        message: item.plateNumber,
        description: (
          <div>
            <div>故障类型：{item.ps}</div>
            <div>24小时故障次数：{item.value}</div>
            <div>最后故障时间：{item.lastReportTime}</div>
          </div>
        ),
        btn: <PermissionButton operateType="" type="primary" size="small" onClick={() => fatalCarSubscribe(item)}>添加到我的车辆</PermissionButton>,
        duration: 0,
        key: `${item.name}${item.id}`
      });
    })
    // 轮询获取严重故障通知(10分钟轮询一次)
    timerRef.current = setTimeout(() => {
      getFatalNotification();
    }, 600000);
  }

  return null;
}

export default FatalNotification;