/**
 * 版本信息弹窗(兼容展示昆仑山设备、黄河设备)
 */
import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import KlsVersionInfo from '@/components/klsVersionInfo';
import LoadingWrapper from '@/components/loadingWrapper';
import { getVersionInfoRequest } from '@/service/deviceManage';
import { getJtKlsWareInfoRequest } from '@/service/jtKlsParameterConfiguration';
import { isKlsAeb, isKlsV3Aeb } from '@/utils/device';

interface VersionModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const VersionModal = (props: VersionModalProps) => {
  const { data, closeModal } = props;
  const [loading, setLoading] = useState(false); // 获取昆仑山设备的版本信息loading
  const [klsVersion, setKlsVersion] = useState({}); // 昆仑山版本信息

  // 是否是昆仑山设备
  const deviceTypeCategory = data.deviceTypeCategory;
  const isKls = isKlsAeb(deviceTypeCategory);
  // 黄河设备的版本信息
  const versionInfo = data.version_info || data.versionInfo;

  // 获取昆仑山版本信息
  const getKlsVersionInfo = async() => {
    try {
      setLoading(true);
      const isKlsV3 = isKlsV3Aeb(deviceTypeCategory);
      let requestFunc = isKlsV3 ? getJtKlsWareInfoRequest : getVersionInfoRequest;
      const res = await requestFunc(data.sn);
      setLoading(false);
      if (res.data?.code === 0) {
        setKlsVersion(res.data?.data || {})
      }
    } catch (error) {
      setKlsVersion({});
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isKls) {
      // 是昆仑山设备时调用接口获取版本信息
      getKlsVersionInfo();
    } else {
      // 黄河设备没有版本信息时给提示
      if (!versionInfo) {
        message.info('暂无版本信息');
        closeModal();
      };
    }
  }, []);

  // 黄河设备没有版本信息时不显示弹窗
  if (!isKls && !versionInfo) {
    return null;
  }

  return (
    <Modal
      title="版本信息"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      width={isKls ? '80%' : '520px'}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      {/* 根据设备版本显示不同的内容 */}
      <LoadingWrapper spinning={loading}>
        {isKls ? <KlsVersionInfo data={klsVersion} /> : versionInfo}
      </LoadingWrapper>
    </Modal>
  )
}

export default VersionModal;
    