// 事故报告统计30天的指标数据描述
export const accidentIndexThirtyDayDataDesc = [
  { type: '百公里HMW值', normalValue: '', field: 'hmwPer100km',riskConclusion:'hmwPer100kmStr'},
  { type: '离线天数', normalValue: '连续7天不在线', field: 'offline',riskConclusion:'offlineStr'},
  { type: '故障码91', normalValue: '≥10&HMW=0&公里数≠0', field: 'e91',riskConclusion:'e91Str' },
  { type: '故障码31', normalValue: '≥30&HMW=0&公里数≠0', field: 'e31',riskConclusion:'e31Str'},
  { type: '故障码130', normalValue: '≥10&HMW=0&公里数≠0', field: 'e130',riskConclusion:'hmwPer100kmStr' },
  { type: '故障码02', normalValue: '>30&HMW=0&公里数≠0', field: 'e2',riskConclusion:'e130Str'},
  { type: '车速为0', normalValue: '>100&HMW=0&公里数≥10', field: 'vs0',riskConclusion:'vs0Str'}
]

//事故报告统计结论
export const accidentAccidentDataDesc = [
  { type: '设备状态', normalValue: '', field: 'state' },
  { type: '设备功能', normalValue: '', field: 'function' },
  { type: '时间', normalValue: '', field: 'time' },
  { type: '位置', normalValue: '', field: 'address' },
  { type: 'DMS设备', normalValue: '', field: 'dms' },
  { type: '其他', normalValue: '', field: 'other' },
]
