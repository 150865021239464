// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","carTree":"carTree_carTree__-JTmi","title":"carTree_title__8+Dgc","filterIcon":"carTree_filterIcon__HYeFC","tree":"carTree_tree__htDJD"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"carTree\":\"carTree_carTree__-JTmi\",\"title\":\"carTree_title__8+Dgc\",\"filterIcon\":\"carTree_filterIcon__HYeFC\",\"tree\":\"carTree_tree__htDJD\"}";
        // 1726639068985
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  