/**
 * 上传文件
 */
import React, { useState, useRef, useEffect } from "react";
import { Upload, message } from "antd";
import { PlusOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import localStorageUtil from '@/utils/localStorageUtil';
import styles from './index.module.scss';

interface UploadImageVideoProps {
  accept?: string; // 支持的文件类型
  uploadFilesChange: Function; // 上传文件改变之后的回调方法
  defaultUploadFiles?: Array<{ fileName:any, key: string, preview: string, contentType: string }>; // 默认已经上传成功的文件信息(编辑时使用)
  canUploadVideo?: boolean; // 是否支持上传视频
  limitSize?: number; // 上传内容大小限制(单位M)
  maxCount?: number; // 最多可以上传的个数
  multiple?: boolean; // 是否支持一次上传多张
}
const UploadFile = (props: UploadImageVideoProps) => {
  const { accept, uploadFilesChange, defaultUploadFiles, canUploadVideo, limitSize, maxCount, multiple } = props;
  const uploadCountRef = useRef(0);
  const [uploadSuccessList, setUploadSuccessList] = useState<any>(defaultUploadFiles || []); // 上传成功的资源信息
  const [uploadLoading, setUploadLoading] = useState<boolean>(false); // 是否上传中
  // 有初始值时调用uploadFilesChange方法传递给使用的组件
  useEffect(() => {
    if (defaultUploadFiles) {
      uploadFilesChange(defaultUploadFiles);
    }
  }, []);

  // 上传资源改变的方法
  const onUploadChange = (info: any) => {
    // 上传结束的计数+1
    const addUploadCount = () => {
      uploadCountRef.current += 1;
      if (uploadCountRef.current === (info.fileList || []).length) {
        setUploadLoading(false);
      }
    }
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
    }
    if (info.file.status === 'error') {
      addUploadCount();
      message.error('上传失败');
    }
    if (info.file.status === 'done') {
      addUploadCount();
      const code = info.file.response?.code;
      if (code === 0) {
        const newUploadSuccessList = [...uploadSuccessList, info.file.response.data];
        setUploadSuccessList(newUploadSuccessList);
        uploadFilesChange(newUploadSuccessList);
      } else {
        message.error(info.file.response?.msg || '上传失败');
      }
    }
  }

  // 删除上传的内容
  const deleteUpload = (index: number, e: any) => {
    e.stopPropagation();
    const newUploadSuccessList = [...uploadSuccessList];
    newUploadSuccessList.splice(index, 1);
    setUploadSuccessList(newUploadSuccessList);
    uploadFilesChange(newUploadSuccessList);
  }
  
  return (
    <div className={styles.uploadImageVideo}>
      <div className={styles.previewBox}>
        {uploadSuccessList.map((item: any, index: number) => (
          <div className={styles.previewItem} key={item.key}>
            {item.fileName}
            <DeleteOutlined className={styles.deleteIcon} onClick={(e: any) => deleteUpload(index, e)} />
          </div>
        ))}
      </div>
      {/* 没有限制上传数量或者上传数小于限制时显示上传 */}
      {(!maxCount || uploadSuccessList.length < maxCount) && <Upload
        accept={accept}
        action="/api/v2/resource/upload"
        headers={{ access_token: localStorageUtil.getItem('access_token') }}
        onChange={onUploadChange}
        multiple={multiple}
      >
        <div className={styles.uploadCard}>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div>上传文件</div>
        </div>
      </Upload>}
    </div>
  )
}

export default UploadFile;