/**
 * 上传excel文件(批量上传使用)
 */
import React, { useState } from "react";
import { Upload, message, ButtonProps } from "antd";
import PermissionButton from '@/components/permissionButton';
import localStorageUtil from '@/utils/localStorageUtil';

interface UploadExcelProps {
  uploadRequestPath: string; // 上传地址
  uploadExtraData?: any; // 上传额外的参数
  uploadPermissionKey?: string; // 上传按钮的权限key
  uploadButtonProps: ButtonProps; // 上传按钮的相关属性
  loadingChangeFunc?: Function; // loading改变调用的方法
  uploadSuccessFunc: Function; // 上传成功之后调用的方法
  uploadFailFunc?: Function; // 上传失败之后调用的方法
}
const UploadExcel = (props: UploadExcelProps) => {
  const { uploadRequestPath, uploadExtraData, uploadPermissionKey, uploadButtonProps, loadingChangeFunc, uploadSuccessFunc, uploadFailFunc } = props;
  const [uploadLoading, setUploadLoading] = useState<boolean>(false) // 上传的loading控制
  
  // 导入文件修改的方法
  const onUploadChange = (info: any) => {
    setUploadLoading(true);
    loadingChangeFunc && loadingChangeFunc(true);
    if (info.file.status === 'error') {
      message.error('导入失败');
      setUploadLoading(false);
      loadingChangeFunc && loadingChangeFunc(false);
    }
    if (info.file.status === 'done') {
      const res = info.file.response;
      const code = res?.code;
      const resData = res?.data;
      if (code === 0) {
        uploadSuccessFunc && uploadSuccessFunc(resData, code);
      } else {
        message.error(res?.msg || '导入失败');
        uploadFailFunc && uploadFailFunc(resData, code);
      }
      setUploadLoading(false);
      loadingChangeFunc && loadingChangeFunc(false);
    }
  }

  const accessToken = localStorageUtil.getItem('access_token');
  return (
    <Upload
      action={uploadRequestPath}
      accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      onChange={onUploadChange}
      headers={{ access_token: accessToken }}
      data={{ access_token: accessToken, ...uploadExtraData }}
      showUploadList={false}
    >
      <PermissionButton
        operateType={uploadPermissionKey}
        type="primary"
        loading={uploadLoading}
        {...uploadButtonProps}
      />
    </Upload>
  )
}

export default UploadExcel;