/**
 * 车辆在线状态相关请求
 */
import request from "@/utils/request";

export const carDayStatisticListRequestPath = '/api/v2/carDayStatistic/online/list';

// 获取数据
export const carDayStatisticListRequest = (data: any) => {
  return request.post(carDayStatisticListRequestPath, data)
}

