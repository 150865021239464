/**
 * 操作工单收费记录(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import UploadImageVideo from "@/components/uploadImageVideo";
import { costRecordAdd } from '@/service/workOrderCostRecord'
import CarSelect from '@/components/carSelect';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const costTypeData = [
    { label: '设备维修', value: 1 },
    { label: '设备拆除', value: 2 },
    { label: '设备迁移', value: 3 },
  ]
  const costItemsData = [
    { label: '维修费', value: '维修费' },
    { label: '雷达', value: '雷达' },
    { label: '双目相机', value: '双目相机' },
    { label: '主控器', value: '主控器' },
    { label: '雷达组', value: '雷达组' },
    { label: '盲区大屏', value: '盲区大屏' },
    { label: '盲区摄像头', value: '盲区摄像头' },
    { label: '预警显示器', value: '预警显示器' },
    { label: '线束组', value: '线束组' },
    { label: '双向单通阀套装', value: '双向单通阀套装' },
    { label: '减压阀套装', value: '减压阀套装' },
    { label: '电磁阀套装', value: '电磁阀套装' },
    { label: '4G模块套装', value: '4G模块套装' },
    { label: '装车', value: '装车' },
    { label: '其它', value: '其它' },
  ]

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
    if (type === 'add') {
      if (data !== '') {
        form.setFieldsValue(data)
      }
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      params.cost = Number(params.cost)
      params.type = params.type.toString()
      params.image = uploadSuccessList[0].key
      params.flag = false
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await costRecordAdd(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加工单收费记录' : '编辑工单收费记录'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item
          label="请选择车辆"
          name="plateNumber"
          rules={[
            { required: true, message: '请选择车辆' }
          ]}
        >
          <CarSelect valueField="plate_number" />
        </Form.Item>
        <Form.Item
          label="收费金额"
          name="cost"
          rules={[{ required: true, message: '请输入收费金额' }]}
        >
          <Input placeholder='请输入收费金额' />
        </Form.Item>
        <Form.Item
          label="收费类型"
          name="type"
          rules={[
            { required: true, message: '请选择收费类型' }
          ]}
        >
          <Select placeholder="请选择收费类型" options={costTypeData} allowClear showArrow showSearch optionFilterProp="label"  />
        </Form.Item>
        <Form.Item
          label="维保项"
          name="items"
        >
          <Select
            mode="multiple"
            showSearch
            placeholder="请选择工单标签"
            options={costItemsData}
            optionFilterProp="title"
          />
        </Form.Item>
        <Form.Item label="收费凭证">
          <UploadImageVideo
            businessName="收费凭证"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={type === 'add' ? [] : [{ contentType: "image/jpeg", key: new URL(data.imageUrl).pathname.slice(1), preview: data.imageUrl }]}
            multiple
          />
        </Form.Item>
        <Form.Item label="备注" name='remarks'>
          <Input />
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default OperateModal;
    