/**
 * 部标机设备报警相关请求
 */
import request from '@/utils/request';

export interface JtDeviceAlarmListRequestParams {
  page: number;
  limit: number;
  start?: string;
  end?: string;
  carIds?: Array<number>;
  alarmTypes?: Array<string>;
  plateNumber?: string;
}

export const jtDeviceAlarmListRequestPath = '/api/v2/jt808Device/video/list';
// 获取部标机设备报警数据
export const jtDeviceAlarmListRequest = (
  data: JtDeviceAlarmListRequestParams
) => {
  return request.post(jtDeviceAlarmListRequestPath, data);
};

// 获取部标机设备报警视频URL
export const jtDeviceAlarmVideoRequest = (fileName: string) => {
  return request.get(`/api/v2/jt808Device/video/${fileName}`);
};
