export interface KlsVersionFiledsInterface {
  title: string;
  field: string;
}
export interface KlsVersionInfoFiledsInterface {
  title: string;
  children: Array<KlsVersionFiledsInterface>
}
export const klsVersionInfoFileds: Array<KlsVersionInfoFiledsInterface> = [
  {
    title: '控制器版本',
    children: [
      { title: '软件版本号', field: 'aebsSwVersion' },
      { title: 'SN序列号', field: 'aebsSn' },
      { title: 'PN序列号', field: 'aebsPn' },
    ],
  },
  {
    title: '双目相机版本',
    children: [
      { title: '软件版本号', field: 'cameraSwVersion' },
      { title: '双目相机硬件版本号', field: 'cameraHwVersion' },
      { title: 'SN序列号', field: 'cameraSn' },
      { title: 'PN序列号', field: 'cameraPn' },
    ],
  },
  {
    title: '显示屏版本',
    children: [
      { title: '软件版本号', field: 'displayerSwVersion' },
      { title: 'SN序列号', field: 'displayerSn' },
      { title: 'PN序列号', field: 'displayerPn' },
    ],
  },
  {
    title: '雷达版本',
    children: [
      { title: '超声波雷达版本号', field: 'uradarVersion' },
      { title: '毫米波雷达版本号', field: 'mradarVersion' },
    ],
  },
  {
    title: '通信协议版本',
    children: [
      { title: '相机CAN协议版本号', field: 'cameraCanProtocoiVersion' },
      { title: 'PC端串口协议版本号', field: 'aebsAtPcProtocoiVersion' },
      { title: '蓝牙串口协议版本号', field: 'aebsAtBtProtocoiVersion' },
      { title: '控制器CAN协议版本号', field: 'aebsCanProtocoiVersion' },
      { title: '显示器CAN协议版本号', field: 'displayerCanProtocoiVersion' },
      { title: '云平台协议版本号', field: 'cloudPlatformProtocoiVersion' },
    ],
  },
  {
    title: '记录仪版本',
    children: [
      { title: '软件版本号', field: 'carDvrVersion' },
      { title: 'SN序列号', field: 'carDvrSn' },
    ],
  }
]