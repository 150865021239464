/**
 * 客户文件
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import styles from '@/styles/pageContent.module.scss';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import { useTable } from '@/utils/useTable';
import { clientFileListRequest, fileDeleteRequest } from '@/service/clientFile'
import OperateModal from './components/operateModal';

const ClientFile = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: clientFileListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    newSearchParams.nickName = (values.nickName || '').trim();
    newSearchParams.mobile = (values.mobile || '').trim();
    setSearchParams(newSearchParams);
  };
  
  // 预览
  const onPdfPreview = (fileSource:any) => {
    setPdfPreviewInfo({
      url:fileSource,
      fileName:"预览",
    }); 
  }

  // 删除
  const deleteUser = async(id: number) => {
    const res = await fileDeleteRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '文件名称',
      dataIndex: 'name',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '文件',
      dataIndex: 'fileSource',
      width: 110,
      ellipsis: { showTitle: false },
      render: (fileSource:any) => {
        return (
          <div className={styles.contractIcon}>
            <PermissionButton
              type="text"
              onClick={() => onPdfPreview(fileSource)}
            >
              <EyeOutlined />
            </PermissionButton>
          </div>
        )
      }
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/client-file/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
          
            <Popconfirm
              title="您确定要删除该文件吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/client-file/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete="off">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item label="文件名称" name="name">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="&nbsp;">
            <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/client-file/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Card>
  )
}

export default ClientFile;
