/**
 * 传感器方案-超声波硬件方案添加/编辑
 */
import React, { useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';
import SensorDeviceSelect from '@/components/sensorDeviceSelect';
import FormTable from '@/components/formTable';
import { upsertSensorUltrasonicHardwareRequest } from '@/service/sensorUltrasonicHardware';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();

  // 编辑时赋值
  useEffect(() => {
    if (['edit', 'detail'].includes(type)) {
      const { name, description, componentId, probeJsonDesc } = data;
      form.setFieldsValue({
        name,
        description,
        componentId,
        probeJson: probeJsonDesc
      });
    }
  }, []);

  // 校验探头位置和探头id不能重复
  const validatorProbeJsonRepeat = (value: any) => {
    const locationObj: any = {};
    const idObj: any = {};
    for (const i in value) {
      if (value[i]) {
        const { location, id } = value[i];
        if (locationObj[location] || idObj[id]) {
          return true;
        }
        locationObj[location] = true;
        idObj[id] = true;
      }
    }
    return false;
  };

  // 校验探头定义表单项
  const validatorProbeJson = (value: any) => {
    if (!value || value.length < 1) {
      return Promise.reject(new Error('请填写探头定义'));
    } else if (validatorProbeJsonRepeat(value)) {
      return Promise.reject(new Error('探头位置名称和探头ID不能重复'));
    } else {
      return Promise.resolve();
    }
  };

  // 保存传感器方案-超声波硬件方案
  const upsertSensorUltrasonicHardware = async (values: any) => {
    const params = { ...values };
    params.probeJson = JSON.stringify(values.probeJson);
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorUltrasonicHardwareRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  return (
    <Modal
      title={
        type === 'detail'
          ? '超声波硬件方案详情'
          : type === 'add'
          ? '新增超声波硬件方案'
          : '编辑超声波硬件方案'
      }
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      maskClosable={false}
      okButtonProps={{ style: type === 'detail' ? { display: 'none' } : {} }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={upsertSensorUltrasonicHardware}
        layout="vertical"
        disabled={type === 'detail'}
      >
        <Form.Item
          label="方案名称"
          name="name"
          rules={[{ required: true, message: '请输入方案名称' }]}
        >
          <Input placeholder="请输入方案名称" />
        </Form.Item>
        <Form.Item label="方案描述" name="description">
          <Input.TextArea placeholder="请输入方案描述" />
        </Form.Item>
        <Form.Item
          label="超声波设备"
          name="componentId"
          rules={[{ required: true, message: '请选择超声波设备' }]}
        >
          <SensorDeviceSelect requestParams={{ componentTypeId: 5 }} />
        </Form.Item>
        <FormTable
          form={form}
          field="probeJson"
          title="探头定义"
          initialValue={[{ location: '', id: '' }]}
          tableFormItems={[
            {
              title: '探头位置名称',
              width: 160,
              formItem: (name: number, formValues: any, restField: any) => (
                <Form.Item
                  {...restField}
                  name={[name, 'location']}
                  rules={[{ required: true, message: '请填写探头位置名称' }]}
                >
                  <Input placeholder="请填写探头位置名称" />
                </Form.Item>
              )
            },
            {
              title: '探头ID',
              width: 160,
              formItem: (name: number, formValues: any, restField: any) => (
                <Form.Item
                  {...restField}
                  name={[name, 'id']}
                  rules={[{ required: true, message: '请填写探头ID' }]}
                >
                  <Input placeholder="请填写探头ID" />
                </Form.Item>
              )
            }
          ]}
          validatorFunc={(value: any) => validatorProbeJson(value)}
        />
      </Form>
    </Modal>
  );
};

export default OperateModal;
