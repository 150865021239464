import { createElement } from 'react';
import store from '@/store';
import Icon from '@/components/icon';
import { MenuTypeEnum } from '@/constants/menuManage';

// 处理权限相关数据
export const operatePermissionData = (permissionData: any) => {
  const allPagePermission: any = []; // 所有页面的权限点
  const allInnerPermission: any = []; // 所有页面内部的权限点
  const allSubMenuKeys: any = []; // 所有展开的导航key
  const mapCategoryData = (innerData: any) => {
    const newData: any = [];
    innerData.forEach((item: any) => {
      const { permissions, leaf, children, key, label, title, id, icon } = item;
      // 存储所有页面内权限信息
      allInnerPermission.push(...permissions);
      // 存储所有页面权限信息
      if (leaf) {
        allPagePermission.push({ label, title, key, id });
      }
      const obj: any = { label, title, key, id, icon: icon ? createElement(Icon, { type: icon }) : null }
      if (Array.isArray(children) && children.length > 0) {
        // 存储所有菜单组的key
        allSubMenuKeys.push(key);
        const tempData = mapCategoryData(item.children);
        obj.children = tempData;
      }
      // 不是弹窗浮层的菜单才保留
      if (item.type !== MenuTypeEnum.overlay) {
        newData.push(obj);
      }
    });
    return newData;
  }
  const menuData = mapCategoryData(permissionData); // 左侧menu数据
  return { menuData, allPagePermission, allInnerPermission, allSubMenuKeys };
}

// 根据用户权限处理跳转的路径
export const getFirstJumpPath = (allPagePermission: any) => {
  let jumpPath = '';
  let jumpTitle = '';
  allPagePermission.forEach((item: any) => {
    if (!jumpPath) {
      jumpPath = item.key;
      jumpTitle = item.title;
    }
    if (item.key === '/car-admin/home') {
      jumpPath = '/car-admin/home';
      jumpTitle = item.title;
    }
  });
  store.dispatch({
    type: 'initTab',
    payload: { tab: jumpTitle, key: jumpPath || '/car-admin/home' } 
  });
}

// 获取用户是否有某个页面的权限
export const getPagePermissionEnable = (permissionKey: string) => {
  if (permissionKey) {
    const { userStore: { allPagePermission } } : { userStore: any } = store.getState();
    return Boolean(allPagePermission.find((item: any) => item.key === permissionKey));
  } else {
    // 没有permissionKey时默认表示用户有此权限
    return true;
  }
}

// 获取用户是否有页面内的某个权限
export const getInnerPermissionEnable = (permissionKey?: string) => {
  if (permissionKey) {
    const { userStore: { allInnerPermission } } : { userStore: any } = store.getState();
    return Boolean(allInnerPermission.find((item: any) => item.permissionKey === permissionKey));
  } else {
    // 没有permissionKey时默认表示用户有此权限
    return true;
  }
}
