/**
 * 部标机设备参数配置
 */
import React from 'react';
import { Modal } from 'antd';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import { jtDeviceConfigParams } from '@/constants/jtDevice';
import {
  jt808Command8106Request,
  jt808Command8103Request
} from '@/service/jtDevice';

interface ParamsConfigModalProps {
  phone: any; // 终端手机号
  closeModal: () => void; // 关闭弹窗的方法
}
const ParamsConfigModal = (props: ParamsConfigModalProps) => {
  const { phone, closeModal } = props;

  return (
    <ParameterConfigurationModal
      operateParameterValue={phone}
      operateParameterField="phone"
      formConfig={jtDeviceConfigParams}
      getParameterRequest={jt808Command8106Request}
      setParameterRequest={jt808Command8103Request}
      closeModal={closeModal}
      modalTitle="部标机参数配置"
      useCollapse
      defaultActiveKeys={['params']}
      setSuccessCallback={() => {
        Modal.success({
          content: '配置成功，设备重启后生效',
          centered: true
        });
      }}
    />
  );
};

export default ParamsConfigModal;
