/**
 * 路口标注的相关请求
 */
import request from '@/utils/request';

// 路口标注列表
export const crossMarkListRequest = () => {
  return request.get('/api/v2/road/list');
};

// 操作路口标注
export const upsertCrossMarkRequest = (data: any) => {
  return request.post('/api/v2/road/upsert', data);
};

// 路口标注详情
export const crossMarkDetailRequest = (id: number) => {
  return request.get(`/api/v2/road/detail/${id}`);
};

// 删除路口标注
export const deleteCrossMarkRequest = (id: number) => {
  return request.delete(`/api/v2/road/${id}`);
};
