/**
 * 客服记录维护相关请求
 */
import request from "@/utils/request";

export interface CallRecordRequestParams {
  page: number;
  limit: number;
  agentNickName?: Array<string>;
  isMaintained?: boolean;
}
// 获取客服记录维护列表
export const callRecordRequestPath = '/api/v2/callRecord/list'
export const callRecordRequest = (params: CallRecordRequestParams) => {
  return request.get(callRecordRequestPath, {
    params,
  })
}

export enum CallRecordStatusEnum {
  match = 1,
  maintained = 2, 
  todo = 3,
  ignore = 4,
  matchMultiple = 5,
}
export interface UpdateCallRecordRequestParams {
  id: number;
  status: CallRecordStatusEnum.maintained | CallRecordStatusEnum.ignore | CallRecordStatusEnum.match;
  carId?: number;
}
export const updateCallRecordRequest = (data: UpdateCallRecordRequestParams) => {
  return request.post('/api/v2/callRecord/update', data)
}

// 客服记录匹配多辆车时获取匹配的车辆数据
export const callRecordMatchRequest = (params: { callNumber: string }) => {
  return request.get('/api/v2/callRecord/matching', {
    params,
  })
}