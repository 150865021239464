/**
 * 车辆收藏分组列表
 */
import React, { useEffect, useState } from "react";
import { Drawer, Popconfirm, message } from 'antd';
import PermissionButton from '@/components/permissionButton';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { favoriteGroupListRequest, deleteFavoriteGroupRequest } from "@/service/myCar";
import OperateFavoriteGroupModal from '../operateFavoriteGroupModal';
import styles from '@/styles/pageContent.module.scss';

interface FavoriteGroupListModalProps {
  onClose: () => void; // 关闭车辆收藏分组列表的方法
  reloadData: () => void; // 重新获取收藏车辆的方法
}
const FavoriteGroupListModal = (props: FavoriteGroupListModalProps) => {
  const { onClose, reloadData } = props;
  const [loading, setLoading] = useState(false); // 数据是否请求中
  const [favoriteGroupList, setFavoriteGroupList] = useState([]); // 收藏分组数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息

  // 获取收藏分组数据
  const getFavoriteGroupList = async() => {
    try {
      setLoading(true);
      const res = await favoriteGroupListRequest();
      setFavoriteGroupList(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getFavoriteGroupList();
  }, []);

  // 删除分组
  const deleteFavoriteGroup = async (id: number) => {
    const res = await deleteFavoriteGroupRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getFavoriteGroupList();
      reloadData();
    }
  }

  const columns: any = [
    {
      title: '名称',
      dataIndex: 'title',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '备注',
      dataIndex: 'ps',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'id',
      width: 150,
      render: (value: number, record: any) => (
        <div className={styles.tableOperateColumn}>
          <PermissionButton
            operateType='/car-admin/my-car/edit-follow-group'
            type="link"
            onClick={() => setOperateInfo({ type: 'edit', data: record })}
          >
            编辑
          </PermissionButton>
          {/* 默认分组不可删除 */}
          {record.id === 0 || <Popconfirm
            title="删除分组后该分组内车辆将全部取消关注，确定删除吗？"
            onConfirm={() => deleteFavoriteGroup(value)}
          >
            <PermissionButton
              operateType='/car-admin/my-car/delete-follow-group'
              type="link"
            >
              删除
            </PermissionButton>
          </Popconfirm>}
        </div>
      )
    },
  ];

  return (
    <Drawer
      title='收藏分组管理'
      visible
      onClose={onClose}
      size="large"
      className={styles.drawerTableBox}
    >
      <div className={styles.operateBox}>
        <PermissionButton
          type="primary"
          operateType='/car-admin/my-car/add-follow-group'
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          新增分组
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columnFilter={false}
        columns={columns}
        dataSource={favoriteGroupList}
        pagination={false}
        bordered
        loading={loading}
      />
      {['add', 'edit'].includes(operateInfo.type) && <OperateFavoriteGroupModal
        data={operateInfo.data}
        type={operateInfo.type}
        closeModal={() => setOperateInfo({})}
        reloadData={() => {
          getFavoriteGroupList();
          reloadData();
        }}
      />}
    </Drawer>
  )
}

export default FavoriteGroupListModal;