/**
 * 设备管理
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, Input, Popconfirm, message } from 'antd';
import {
  deviceListRequest,
  deviceListRequestPath,
  deleteDeviceRequest,
  deviceImportRequestPath
} from '@/service/deviceManage';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import OperateModal from './components/operateModal';
import UploadExcel from '@/components/uploadExcel';
import CommonTableOperateColumn from '@/components/commonTableOperateColumn';
import { snColumn } from '@/columns/carDetail';
import { formSearchGutter } from '@/constants/common';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { displayDeviceTypeCategory } from '@/utils/device';
import styles from '@/styles/pageContent.module.scss';

const DeviceManage = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: deviceListRequest,
      initPageSize: 40,
      searchParams
    });

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.sn = (params.sn || '').trim();
    newParams.plateNumber = (params.plateNumber || '').trim();
    newParams.cameraSn = (params.cameraSn || '').trim();
    newParams.sim = (params.sim || '').trim();
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 删除设备
  const deleteDevice = async (id: number) => {
    const res = await deleteDeviceRequest(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  };

  // 表格列显示
  const columns = [
    snColumn,
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110
    },
    {
      title: '相机SN',
      dataIndex: 'cameraSn',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: 'SIM卡ICCID',
      dataIndex: 'sim',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '设备类型',
      dataIndex: 'deviceType',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: number) => displayDeviceTypeCategory(value)
    },
    {
      title: '是否在线',
      dataIndex: 'isOnline',
      width: 90,
      render: (value: boolean) => (value ? '是' : '否')
    },
    {
      title: '最近在线时间',
      dataIndex: 'onlineAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '最后更改时间',
      dataIndex: 'updatedAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 280,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div
            className={styles.tableOperateColumn}
            style={{ display: 'flex' }}
          >
            <PermissionButton
              operateType="/car-admin/device-manage/edit"
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该设备吗？请确保该设备没有绑定车辆"
              onConfirm={() => deleteDevice(item.id)}
            >
              <PermissionButton
                operateType="/car-admin/device-manage/delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
            <CommonTableOperateColumn
              operateInfoData={item}
              showPosition
              showContact={false}
              style={{ marginLeft: 0 }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} autoComplete="off" form={form}>
        <Row gutter={formSearchGutter}>
          <Col span={5}>
            <Form.Item label="设备SN" name="sn">
              <Input placeholder="请输入设备SN" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="相机SN" name="cameraSn">
              <Input placeholder="请输入相机SN" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="SIM" name="sim">
              <Input placeholder="请输入SIM" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <ExportButton
                  url={deviceListRequestPath}
                  fileName="设备列表"
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey="/car-admin/device-manage/export"
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/device-manage/add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加设备
        </PermissionButton>
        <Button href="/device_import_demo.xlsx">下载导入模板文件</Button>
        <UploadExcel
          uploadRequestPath={deviceImportRequestPath}
          uploadPermissionKey="/car-admin/device-manage/import"
          uploadButtonProps={{ children: '上传设备excel文件' }}
          uploadSuccessFunc={() => {
            message.success('上传成功');
            getData();
          }}
        />
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
      />
      {['edit', 'add'].includes(operateInfo.type) && (
        <OperateModal
          data={operateInfo.data}
          type={operateInfo.type}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
    </Card>
  );
};

export default DeviceManage;
