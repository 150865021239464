/**
 * 行车记录仪历史视频下载
 */
import React, { useState } from 'react';
import { Form, Row, Col, Button, message, InputNumber } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import VideoPreviewModal from '@/components/videoPreviewModal';
import {
  getShootByParamsRequest,
  getShootHistoryRequest
} from '@/service/parameterConfiguration';
import { formatTime } from '@/utils/formatTime';
import { useTable } from '@/utils/useTable';
import { changeUrlProtocol } from '@/utils/common';
import { downloadFile } from '@/utils/download';
import { renderDvrVideoStatus } from '@/utils/driveAnalysis';
import { formSearchGutter } from '@/constants/common';
import styles from '@/styles/pageContent.module.scss';

interface DvrDownloadProps {
  carDetail: any;
}
const DvrDownload = (props: DvrDownloadProps) => {
  const {
    carDetail: { sn }
  } = props;
  const [form] = Form.useForm();
  const [searchParams] = useState({}); // 获取已上传到服务器视频列表的搜索参数
  const [videoLoading, setVideoLoading] = useState(false); // 获取行车记录仪历史视频的loading
  const [downloadLoading, setDownloadLoading] = useState(false); // 下载视频的loading
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的信息存储

  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: getShootHistoryRequest,
      initRequest: true,
      initPageSize: 40,
      searchParams,
      extraParams: { sn, needUser: false }
    });

  // 获取行车记录仪历史视频
  const onSearch = async (values: any) => {
    try {
      setVideoLoading(true);
      const { time, duration } = values;
      const params = {
        time: formatTime(dayjs(time).add(Math.ceil(duration / 2), 'second')),
        duration,
        type: 1,
        interval: 0
      };
      const res = await getShootByParamsRequest(sn, params);
      if (res.data?.code === 0) {
        message.success('获取指令下发成功，稍后可刷新视频列表查看');
        setTimeout(() => {
          getData();
        }, 1000);
      }
      setVideoLoading(false);
    } catch (error) {
      setVideoLoading(false);
    }
  };

  // 下载视频
  const onDownload = async (data: any) => {
    try {
      setDownloadLoading(true);
      const { resourceUrl, targetTime, targetDuration } = data;
      // 处理视频开始时间和结束时间
      const startTime = formatTime(
        dayjs(targetTime).subtract(Math.ceil(targetDuration / 2), 'second')
      );
      const endTime = formatTime(
        dayjs(targetTime).add(Math.floor(targetDuration / 2), 'second')
      );
      await downloadFile(
        changeUrlProtocol(resourceUrl),
        `${startTime}-${endTime}.mp4`
      );
      setDownloadLoading(false);
    } catch (error) {
      setDownloadLoading(false);
    }
  };

  // 表格列配置
  const columns = [
    {
      title: '开始时间',
      dataIndex: 'startTime',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => (
        // 通过中间时间和时长计算视频开始时间
        <RenderEllipsisColumn
          text={formatTime(
            dayjs(item.targetTime).subtract(
              Math.ceil(item.targetDuration / 2),
              'second'
            )
          )}
        />
      )
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => (
        // 通过中间时间和时长计算视频结束时间
        <RenderEllipsisColumn
          text={formatTime(
            dayjs(item.targetTime).add(
              Math.floor(item.targetDuration / 2),
              'second'
            )
          )}
        />
      )
    },
    {
      title: '上传时间',
      dataIndex: 'createdAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '视频状态',
      dataIndex: 'status',
      width: 240,
      render: (value: number) => renderDvrVideoStatus(value)
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 90,
      render: (value: any, item: any) => {
        if (item.status === 0) {
          return (
            <div className={styles.tableOperateColumn}>
              <Button
                type="link"
                onClick={() => setOperateInfo({ type: 'play', data: item })}
              >
                播放
              </Button>
              <PermissionButton
                operateType="/car-admin/drive-analysis/history-video-download"
                type="link"
                onClick={() => onDownload(item)}
                loading={downloadLoading}
              >
                下载
              </PermissionButton>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  ];

  return (
    <div>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={formSearchGutter} wrap>
          <Col span={8}>
            <Form.Item
              label="开始时间"
              name="time"
              rules={[{ required: true, message: '请选择开始时间' }]}
            >
              <DatePicker style={{ width: '100%' }} showTime />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="视频时长"
              name="duration"
              rules={[
                { required: true, message: '请填写视频时长' },
                { type: 'number', max: 60, message: '视频最长60秒' },
                { type: 'number', min: 0, message: '视频最长0秒' }
              ]}
            >
              <InputNumber
                style={{ width: '100%' }}
                addonAfter="秒"
                placeholder="视频最长60秒"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={videoLoading}>
                获取
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '12px'
        }}
      >
        <div>已上传到服务器的视频</div>
        <Button onClick={() => getData()}>刷新列表</Button>
      </div>
      <CommonTable
        rowKey="id"
        columnFilter={false}
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
          showQuickJumper: false
        }}
        bordered
        scroll={{ y: 200 }}
        size="small"
        loading={loading}
      />
      {/* 视频播放 */}
      {operateInfo.type === 'play' && (
        <VideoPreviewModal
          url={changeUrlProtocol(operateInfo.data?.resourceUrl)}
          closeModal={() => setOperateInfo({})}
          showDownload={false}
        />
      )}
    </div>
  );
};

export default DvrDownload;
