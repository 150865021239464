/**
 * 绑定上传规则
 */
import React, { useState } from 'react';
import { message, Modal } from 'antd';
import RuleTable from '@/pages/jtDeviceUploadRule/components/ruleTable';
import { useTable } from '@/utils/useTable';
import {
  jtDeviceUploadRuleListRequest,
  bindUploadRuleRequest
} from '@/service/jtDeviceUploadRule';

interface OperateGroupProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData: () => void;
}
const BindRuleModal = (props: OperateGroupProps) => {
  const { data, closeModal, reloadData } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>(null); // 选择的规则key

  const {
    loading,
    data: tableData,
    getData
  } = useTable({
    requestFunc: jtDeviceUploadRuleListRequest,
    initRequest: true,
    initPageSize: 999
  });

  // 绑定规则
  const onBind = async () => {
    const { id, title, parentId } = data;
    const params = {
      id,
      parentId,
      title,
      ruleId: selectedRowKeys[0]
    };
    const res = await bindUploadRuleRequest(params);
    if (res.data?.data) {
      message.success('绑定成功');
      closeModal();
      reloadData();
    } else {
      message.error('绑定失败');
    }
  };

  return (
    <Modal
      title="绑定上传规则"
      open
      onCancel={closeModal}
      onOk={onBind}
      centered
      width="70%"
      okButtonProps={{
        disabled: !selectedRowKeys
      }}
    >
      <RuleTable
        loading={loading}
        data={tableData}
        getData={getData}
        hiddenOperate
        rowSelection={{
          fixed: true,
          type: 'radio',
          defaultSelectedRowKeys: data.ruleId ? [data.ruleId] : [],
          onChange: (selectedRowKeys: Array<number>) =>
            setSelectedRowKeys(selectedRowKeys)
        }}
      />
    </Modal>
  );
};

export default BindRuleModal;
