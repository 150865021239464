/**
 * 工单运维报告
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, Modal } from 'antd';
import dayjs from 'dayjs';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { workOrderReportRequest } from '@/service/workOrder';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import DatePicker from '@/components/datePicker';
import OperationGroupSelect from '@/components/operationGroupSelect';
import WorkOrderList from './components/workOrderList';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const WorkOrderOperation = () => {
  const [form] = Form.useForm();
  const cateIdArr = useUserCateIdArr();
  const [columns, setColumns] = useState<any>([]); // 表格要展示的列
  const [data, setData] = useState<any>([]); // 表格要显示的数据
  const [loading, setLoading] = useState(false); // 表格数据是否在请求中
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    cateId: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关内容

  useEffect(() => {
    getData(searchParams);
  }, [])

  // 获取数据
  const getData = async (params: any) => {
    try {
      setLoading(true);
      const res = await workOrderReportRequest(params);
      const reportData: any = res.data?.data || {};
      const { stats = {}, tags = {}, cell = [] } = reportData;
      const customColumns: any = [{ title: '派单原因', dataIndex: 'title' }];
      Object.keys(stats).forEach((item: string) => {
        customColumns.push({
          title: item,
          dataIndex: stats[item],
          render: (value: any) => (
            <div className={styles.tableOperateColumn}>
              <Button
                type={value.length ? "link": 'text'}
                onClick={() => value.length ? setOperateInfo({ type: 'orderList', data: { idArr: value } }) : null}
              >
                {value.length}
              </Button>
            </div>
          )
        })
      });
      // 处理每一行的数据
      const tableData: any = [];
      const rowObj: any = {};
      cell.forEach((item: any) => {
        const { tag, stat, idArr } = item;
        if (rowObj[tag]) {
          rowObj[tag][stat] = idArr;
        } else {
          rowObj[tag] = { [stat]: idArr };
        }
      });
      Object.keys(tags).forEach((item: string) => {
        const currentRowData: any = {...rowObj[tags[item]]};
        currentRowData.title = item;
        tableData.push(currentRowData);
      });
      setColumns(customColumns);
      setData(tableData)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    delete newSearchParams.day;
    setSearchParams(newSearchParams);
    getData(newSearchParams)
  };

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/work-order-operation/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columnFilter={false}
        rowKey="title"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'orderList' &&
        <Modal
          title="工单列表"
          onCancel={() => setOperateInfo({})}
          visible
          footer={null}
          width={'80%'}
          centered
        >
          <WorkOrderList params={operateInfo.data} />
        </Modal>
      }
    </Card>
  )
}

export default WorkOrderOperation;