/**
 * 相机参数配置弹窗
 */
import React from 'react';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import { getCameraConfigParams } from '@/constants/parameterConfiguration';
import {
  getCameraParamsRequest,
  setCameraParamsRequest
} from '@/service/parameterConfiguration';
import {
  getJtKlsCameraParamsRequest,
  setJtKlsCameraParamsRequest
} from '@/service/jtKlsParameterConfiguration';

interface CameraModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const CameraModal = (props: CameraModalProps) => {
  const {
    data: { isKlsV3, sn, deviceType },
    closeModal
  } = props;

  return (
    <ParameterConfigurationModal
      operateParameterValue={sn}
      operateParameterField="sn"
      getParameterRequest={
        isKlsV3 ? getJtKlsCameraParamsRequest : getCameraParamsRequest
      }
      setParameterRequest={
        isKlsV3 ? setJtKlsCameraParamsRequest : setCameraParamsRequest
      }
      formConfig={getCameraConfigParams(isKlsV3)}
      closeModal={closeModal}
      modalTitle="相机参数配置"
      modalWidth="auto"
      configKey="/car-admin/parameter-configuration/camera-configuration-config"
      approveKey="/car-admin/parameter-configuration/camera-configuration-config-approve"
      approveType="cameraConfig"
      deviceType={deviceType}
    />
  );
};

export default CameraModal;
