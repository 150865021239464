/**
 * 参数信息弹窗(兼容展示昆仑山设备、黄河设备)
 */
import React, { useEffect } from 'react';
import { Modal, message } from 'antd';
import { isKlsAeb, isKlsV3Aeb } from '@/utils/device';
import AebParamsModal from '@/pages/parameterConfiguration/components/aebParamsModal';
import JtKlsAebParamsModal from '@/pages/parameterConfiguration/components/jtKlsAebParamsModal';

interface ParamsModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const ParamsModal = (props: ParamsModalProps) => {
  const { data, closeModal } = props;

  // 是否是昆仑山设备
  const deviceTypeCategory = data.deviceTypeCategory;
  const isKls = isKlsAeb(deviceTypeCategory);
  const isKlsV3 = isKlsV3Aeb(deviceTypeCategory);
  // 黄河设备的参数信息
  const paramInfo = data.param_info || data.paramInfo;

  useEffect(() => {
    // 黄河设备没有参数信息时给提示
    if (!isKls && !paramInfo) {
      message.info('暂无参数信息');
      closeModal();
    };
  }, []);

  // 黄河设备没有参数信息时不显示弹窗
  if (!isKls && !paramInfo) {
    return null;
  }

  // 昆仑山设备和黄河设备区分显示
  if (isKls) {
    // 昆仑山设备区分显示3.0版本
    if (isKlsV3) {
      return (
        <JtKlsAebParamsModal data={data} closeModal={closeModal} isViewParam />
      );
    } else {
      return <AebParamsModal data={data} closeModal={closeModal} isViewParam />;
    }
  } else {
    return (
      <Modal
        title="参数信息"
        visible
        footer={null}
        onCancel={closeModal}
        centered
      >
        {paramInfo}
      </Modal>
    )
  }
}

export default ParamsModal;
    