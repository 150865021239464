/**
 * 传感器方案-供应商管理
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, Input } from 'antd';
import { sensorSupplierListRequest } from '@/service/sensorSupplierManage';
import { useTable } from '@/utils/useTable';
import { operateTimeRange } from '@/utils/operateSearchParams';
import { sensorSchemeCommonColumns } from '@/columns/sensorScheme';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import SensorDeviceTypeSelect from '@/components/sensorDeviceTypeSelect';
import SensorCommonSearch from '@/components/sensorCommonSearch';
import { formSearchGutter } from '@/constants/common';
import { actuatorName } from '@/constants/sensorScheme';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const SensorSupplierManage = () => {
  const [form] = Form.useForm();
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: sensorSupplierListRequest,
      searchParams
    });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateTimeRange(values, true);
    setSearchParams(newSearchParams);
  };

  // 表格列显示
  const columns = [
    // {
    //   title: '供应商ID',
    //   dataIndex: 'id',
    //   width: 110,
    //   ellipsis: { showTitle: false },
    //   render: (value: string) => <RenderEllipsisColumn text={value} />
    // },
    {
      title: '厂商ID',
      dataIndex: 'flag',
      width: 70,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '供应商名称',
      dataIndex: 'name',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '设备类型',
      dataIndex: 'componentTypeName',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => (
        <RenderEllipsisColumn
          text={value === actuatorName ? `${value}-${item.typeId}` : value}
        />
      )
    },
    ...sensorSchemeCommonColumns,
    {
      title: '备注',
      dataIndex: 'ps',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/sensorSupplier-manage/edit"
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <PermissionButton
              operateType="/car-admin/sensorSupplier-manage/viewDetail"
              type="link"
              onClick={() => setOperateInfo({ type: 'detail', data: item })}
            >
              查看
            </PermissionButton>
          </div>
        );
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off">
        <Row gutter={formSearchGutter}>
          <Col span={5}>
            <Form.Item label="供应商名称" name="name">
              <Input placeholder="请输入供应商名称" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="设备类型" name="componentTypeId">
              <SensorDeviceTypeSelect />
            </Form.Item>
          </Col>
          <SensorCommonSearch span={6} />
          <Col span={2}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/sensorSupplier-manage/add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          新增供应商
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
      />
      {['add', 'edit', 'detail'].includes(operateInfo.type) && (
        <OperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
    </Card>
  );
};

export default SensorSupplierManage;
