/**
 * 车队走访相关请求
 */
import request from "@/utils/request";

export interface CarInterviewListRequestParams {
    page: number; 
    limit: number;
}

export const carInterviewListRequestPath = '/api/v2/company/record/list';

// 获取数据
export const carInterviewListRequest = (params: CarInterviewListRequestParams) => {
    return request.get(carInterviewListRequestPath, {
        params,
    })
}

// 添加数据
export const upsertCarInterview = (data: any) => {
    return request.post('/api/v2/company/record/upsert', data)
}

// 删除用户
export const DeleteCarInterview = (id: number) => {
    return request.delete(`/api/v2/company/record/${id}`)
}