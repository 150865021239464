/**
 * 登录
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from "react-helmet";
import { Form, Input, Button, message } from 'antd';
import type { RouteComponentProps } from "react-router-dom";
import { loginRequest } from '@/service/login';
import { getOrganizationRequest } from '@/service/organizationManage';
import localStorageUtil from '@/utils/localStorageUtil';
import { getFirstJumpPath } from '@/utils/permission';
import { operateUserInfo } from '@/utils/user';
import cbsLogoImg from '@/assets/image/cbsLogo.jpg';
import { getCustomer } from '@/service/login';
import styles from './index.module.scss';

const Login = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history, match } = props;
  const [logo, setLogo] = useState(''); // logo
  const [pageTitle, setPageTitle] = useState(''); // 页面Title

  // 获取平台展示的logo
  const getPlateformLogo = async() => {
    const routeParams: any = match.params;
    if (routeParams?.organization) {
      const res = await getOrganizationRequest(routeParams?.organization);
      setLogo(res?.data?.data?.iconUrl || cbsLogoImg);
      setPageTitle(res?.data?.data?.pageTitle || '风险减量运维管理系统');
    } else {
      setLogo(cbsLogoImg);
      setPageTitle('风险减量运维管理系统')
    }
  }

  const getcustomerInfo = async() => {
    const routeParams: any = match.params;
    if (routeParams?.organization) {
      const res = await getCustomer();
      if (res.data.data == '') {
        localStorage.setItem("code",'000000')
      } else {
        localStorage.setItem("code",res.data.data.number)
      }
    } else {
      setLogo(cbsLogoImg);
    }
  }

  useEffect(() => {
    getPlateformLogo();
  }, []);
  
  // 登录
  const onLogin = async (values: any) => {
    const params = {...values};
    if (values.mobile) {
      params.mobile = values.mobile.trim();
    }
    const { data } = await loginRequest(params);
    if (data.code === 0) {
      const accessToken = data?.data?.accessToken || '';
      localStorageUtil.setItem('access_token', accessToken);
      const { 
        userDetail,
        menuData,
        allPagePermission, 
        allInnerPermission,
        allSubMenuKeys
      } = await operateUserInfo();
      // 存储登录的用户信息
      dispatch({
        type: 'login',
        payload: {
          access_token: accessToken,
          userDetail,
          menuData,
          allPagePermission, 
          allInnerPermission,
          allSubMenuKeys
        }
      })
      getcustomerInfo();
      message.success('登录成功');
      // 根据用户权限处理要显示的tab
      getFirstJumpPath(allPagePermission);
      history.replace('/vehicle-admin');
    }
  };

  return (
    <div className={styles.loginBg}>
      <Helmet
        link={[
          { "rel": "shortcut icon", "href": logo },
        ]}
        title={pageTitle}
      />
      <div className={styles.loginBox}>
        <div className={styles.logo}>
          <img src={logo} />
        </div>
        <div className={styles.loginForm}>
          <div className={styles.title}>欢迎回来</div>
          <div className={styles.desc}>请先登录</div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onLogin}
            autoComplete="off"
          >
            <Form.Item
              label="账号"
              name="mobile"
              rules={[{ required: true, message: '请输入账号' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="密码"
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                登入
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.icp}>
       <a href="https://beian.miit.gov.cn" > ICP备案号 : 京ICP备2022035052号-1;</a>
      </div>
    </div>
  

  );
};

export default Login;
