/**
 * 添加、编辑收藏车辆分组
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, message, Input } from 'antd';
import { favoriteGroupUpsertRequest } from "@/service/myCar";

interface OperateFavoriteGroupModalProps {
  data?: any;
  type: string;
  closeModal: () => void;
  reloadData: () => void;
}
const OperateFavoriteGroupModal = (props: OperateFavoriteGroupModalProps) => {
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑时赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await favoriteGroupUpsertRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '编辑成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={`${type === 'edit' ? '编辑' : '添加'}车辆关注分组`}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
      >
        <Form.Item
          label="收藏分组名称"
          name="title"
          rules={[
            { required: true, message: '请填写收藏分组名称' }
          ]}
        >
          <Input placeholder='请填写收藏分组名称' disabled={data?.id === 0} />
        </Form.Item>
        <Form.Item
          label="收藏分组备注"
          name="ps"
        >
          <Input.TextArea placeholder='请填写收藏分组备注' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateFavoriteGroupModal;
     