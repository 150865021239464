/**
 * 组织选择(TreeSelect)
 */
import React, { useEffect, useState } from 'react';
import { TreeSelect, TreeSelectProps } from 'antd';
import { organizationListRequest } from '@/service/organizationManage';

interface OrganizationTreeSelectProps extends TreeSelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string) => void; // 选择时调用的方法
}
const OrganizationTreeSelect = (props: OrganizationTreeSelectProps) => {
  const { value, onChange } = props;
  const [organizationData, setOrganizationData] = useState<any>([]);
  const [selectValue, setSelectValue] = useState<string | undefined>();

  useEffect(() => {
    getOrganizationList();
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 获取组织树形数据
  const getOrganizationList = async () => {
    const res = await organizationListRequest();
    const resData = res.data?.data;
    setOrganizationData(resData ? [resData] : []);
  }

  // 选择树节点
  const onTreeChange = (value: string) => {
    setSelectValue(value);
    onChange && onChange(value)
  }

  return (
    <TreeSelect
      {...props}
      treeNodeFilterProp="groupName"
      fieldNames={{ value: 'id', label: 'groupName' }}
      allowClear
      value={selectValue}
      style={{ width: '100%' }}
      showSearch
      placeholder="请选择"
      treeData={organizationData}
      onChange={onTreeChange}
      dropdownMatchSelectWidth={false}
      showCheckedStrategy="SHOW_PARENT"
      maxTagCount="responsive"
    />
  )
}

export default OrganizationTreeSelect;
  