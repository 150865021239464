/**
 * 诉讼记录
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, Input, message, Popconfirm, Select, Radio, Checkbox } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { carProceedListRequest, carProceedListRequestPath } from "@/service/contractManage";
import styles from '@/styles/pageContent.module.scss';
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';

const UnderwritingRecord = () => {
  const [searchParams, setSearchParams] = useState({ caseFlag: '', accidentFlag: '' }); // 搜索相关的参数
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息

  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: carProceedListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  useEffect(() => {
  }, []);


  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.plateNumber = (params.plateNumber || '').trim();
    if (!newParams.plateNumber) {
      delete newParams.plateNumber;
    }
    if (newParams.payDate) {
      newParams.payDate = dayjs(params.payDate).format('YYYY-MM-DD');
    }
    if (newParams.commercialBeginDate) {
      newParams.commercialBeginDate = dayjs(params.commercialBeginDate).format('YYYY-MM-DD');
    }
    if (newParams.commercialEndDate) {
      newParams.commercialEndDate = dayjs(params.commercialEndDate).format('YYYY-MM-DD');
    }
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  // 表格列显示
  const columns = [
    {
      title: '案件编号',
      dataIndex: 'caseNo',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '合同编号',
      dataIndex: 'contractNo',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '诉讼阶段',
      dataIndex: 'state',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '处理日期',
      dataIndex: 'createdAt',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value ? dayjs(value).format('YYYY-MM-DD') : ''} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '附件',
      dataIndex: 'attachments',
      width: 280,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        value?.map((item: any, index: any) => (
          <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
            <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
            </div>
            <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                type="text"
                onClick={() => onPdfPreview(item.url, item.fileName)}
              >
                <EyeOutlined />
              </Button>
              <Button
                type="link"
                onClick={() => downloadFile(item.url, item.fileName)}
                style={{ paddingLeft: 0 }}
              >
                <DownloadOutlined />
              </Button>
            </div>
          </div>
        ))
      ),
    },
    {
      title: '操作人',
      dataIndex: 'opt',
      width: 110,
      ellipsis: { showTitle: false },
    }
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="合同号" name="no">
              <Input placeholder='请输入合同号' allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder='请输入车架号' allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="案件号" name="caseNo">
              <Input placeholder='请输入案件号' allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton
                  url={carProceedListRequestPath}
                  fileName='诉讼记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/carproceed-recode/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Card>
  )
}

export default UnderwritingRecord;
