/**
 * 拉线电机配置
 */
import React from 'react';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import { cableMotorFormConfig } from '@/constants/parameterConfiguration';
import {
  getCableMotorParamsRequest,
  setCableMotorParamsRequest
} from '@/service/parameterConfiguration';
import {
  getJtKlsCableMotorParamsRequest,
  setJtKlsCableMotorParamsRequest
} from '@/service/jtKlsParameterConfiguration';

interface CableMotorModalProps {
  data: any;
  closeModal: () => void;
}
const CableMotorModal = (props: CableMotorModalProps) => {
  const {
    data: { isKlsV3, sn, deviceType },
    closeModal
  } = props;
  return (
    <ParameterConfigurationModal
      operateParameterValue={sn}
      operateParameterField="sn"
      getParameterRequest={
        isKlsV3 ? getJtKlsCableMotorParamsRequest : getCableMotorParamsRequest
      }
      setParameterRequest={
        isKlsV3 ? setJtKlsCableMotorParamsRequest : setCableMotorParamsRequest
      }
      formConfig={cableMotorFormConfig}
      closeModal={closeModal}
      modalTitle="拉线电机参数配置"
      configKey="/car-admin/parameter-configuration/cableMotor-configuration-config"
      approveKey="/car-admin/parameter-configuration/cableMotor-configuration-config-approve"
      approveType="cableMotorConfig"
      deviceType={deviceType}
    />
  );
};

export default CableMotorModal;
