/**
 * 评价总结弹窗(添加、编辑)
 */
import React from 'react';
import { Modal, Descriptions } from 'antd';

interface EvaluteModalProps {
  data?: any; // 评价总结详情
  onClose: () => void; // 关闭弹窗的方法
}
const EvaluteModal = (props: EvaluteModalProps) => {
  const { data, onClose } = props;
  const {
    firstNormal,
    firstError,
    firstLoss,
    secondNormal,
    secondError,
    secondLoss,
    firstCount,
    secondCount,
    matchCount,
    macthRate
  } = data;
  return (
    <Modal
      title="评价总结"
      open
      centered
      onCancel={onClose}
      onOk={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      width="auto"
    >
      <Descriptions
        layout="vertical"
        bordered
        size="small"
        contentStyle={{ fontSize: '20px' }}
        column={4}
      >
        <Descriptions.Item label="设备一数据总数">
          {firstCount}
        </Descriptions.Item>
        <Descriptions.Item label="设备二数据总数">
          {secondCount}
        </Descriptions.Item>
        <Descriptions.Item label="数据匹配次数">{matchCount}</Descriptions.Item>
        <Descriptions.Item label="匹配数据占比">{macthRate}</Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions
        layout="vertical"
        bordered
        size="small"
        contentStyle={{ fontSize: '20px' }}
      >
        <Descriptions.Item label="设备一">
          <div className="successText">正常：{firstNormal}</div>
          <div className="dangerText">误报：{firstError}</div>
          <div className="warningText">漏报：{firstLoss}</div>
        </Descriptions.Item>
        <Descriptions.Item label="设备二">
          <div className="successText">正常：{secondNormal}</div>
          <div className="dangerText">误报：{secondError}</div>
          <div className="warningText">漏报：{secondLoss}</div>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default EvaluteModal;
