/**
 * 渠道评价指标相关请求
 */
import request from "@/utils/request";

// 获取渠道评价指标
export const channelAssessmentRequestPath = '/api/dataAnalyze/assesmentOfDriving';
export const channelAssessmentRequest = (data: any) => {
  return request.post(channelAssessmentRequestPath, data)
}

