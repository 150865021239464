/**
 * 说明弹窗
 */
import React from 'react';
import { Modal } from 'antd';

interface DescriptionModalProps {
  closeModal: () => void; // 关闭弹窗的方法
}
const DescriptionModal = (props: DescriptionModalProps) => {
  const { closeModal } = props;
  return (
    <Modal
      title="说明"
      visible
      footer={null}
      onCancel={closeModal}
      centered
    >
      <div>
        <h3 className='boldText'>操作流程：</h3>
        <ol>
          <li>在车辆筛选区域输入车辆信息（3选一，填任何一个都可以）。</li>
          <li>点击搜索按钮，之后会在数据显示区域显示数据 （只有半个小时内在线的车辆，才会有数据显示出来）。</li>
          <li>点击搜索后，可以开启自动刷新，此后每隔30秒会自动获取一次最新数据。</li>
          <li>执行完前3步后，在筛选区域更换车辆信息，此时必须再次点击搜索，才会自动获取新车的数据。</li>
        </ol>
        <h3 className='boldText'>指标说明：</h3>
        <ol>
          <li>GPS卫星数量： 14以下为信号强度低，15到25中等信号强度，26以上为信号强度高。</li>
          <li>4G信号强度： 0代表设备在初始化， 4以下为信号强度低，5到14为中下等信号强度，15到25中等信号强度，26以上为信号强度高。</li>
        </ol>
      </div>
    </Modal>
  )
}

export default DescriptionModal;
    