/**
 * 操作退保
 */
import React, { useEffect, useState } from "react";
import { Input, message, Modal, Form, Button, Table, Select, DatePicker } from 'antd';
import UploadFile from '@/components/uploadFile';
import dayjs from "dayjs";
import PdfPreviewModal from '@/components/pdfPreviewModal';
import { surrenderUnderwritingRequest } from "@/service/underwritingRecord";

interface DoucumentCommentDetailProps {
    reloadData: () => void;
    closeModal: () => void;
    type: string;
    item?: any;
    name: string
}

const typeList = [
    {label: '退保', value: '退保'},
    {label: '转保', value: '转保'},
    {label: '过户', value: '过户'}
]

const UnderwritingSurrender = (props: DoucumentCommentDetailProps) => {
    const [form] = Form.useForm();
    const { type, item, closeModal, reloadData, } = props;
    const [isModalOpen, setIsModalOpen] = useState(false); // 记录弹框是否开启
    const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传文件
    const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
    const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
    const [initValue, setInitValue] = useState<{[key: string]: string}>({type: props.name})

    useEffect(() => {
        console.log('item', item);
        setInitValue({type: props.name})
    }, []);

    // 提交
    const submit = async (values: any) => {
        if (submitNoRepetition) {
            setSubmitNoRepetition(false)
            const params = { ...values };
            //退费
            params.date = dayjs(values.date).format("YYYY-MM-DD");
            // params.id = item.id
            params.insuranceCode = item.commercialNumber
            if (uploadSuccessList.length) {
                params.resources = uploadSuccessList;
            } else {
                params.resources = null;
            }
            const res = await surrenderUnderwritingRequest(params);
            if (res.data?.code === 0) {
                message.success('添加成功');
                closeModal();
                reloadData();
            }
            setIsModalOpen(false);
        }
    }
    
    return (
        <Modal title={`编辑${props.name}`}
            visible
            onCancel={closeModal}
            onOk={() => form.submit()}
            bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
            centered
            maskClosable={false}
            open={isModalOpen}
        >
            <Form
                form={form}
                autoComplete="off"
                onFinish={submit}
                layout="vertical"
                scrollToFirstError
                initialValues={initValue}
            >
                <Form.Item label="操作类型" name="type" rules={[{ required: true, message: '请选择操作类型' }]}>
                    <Select options={typeList} defaultValue={props.name} disabled></Select>
                </Form.Item>
                <Form.Item label={`${props.name}日期`} name="date" rules={[{ required: true, message: '请选择日期' }]}>
                    <DatePicker placeholder="请选择日期" style={{ width: '50%' }} />
                </Form.Item>
                <Form.Item label="退费金额" name="fee">
                    <Input placeholder="请输入支付金额" style={{ width: '50%' }} />
                </Form.Item>
                {props.name === '退保' && <Form.Item label="退费凭证" name="resources" >
                    <UploadFile
                        accept="application/pdf"
                        uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
                        defaultUploadFiles={[]}
                        canUploadVideo
                        limitSize={500}
                        multiple
                    />
                </Form.Item>}
                <Form.Item label="备注" name="remark">
                    <Input placeholder="请输入备注" style={{ width: '100%' }} />
                </Form.Item>
            </Form>
            {pdfPreviewInfo && <PdfPreviewModal
                title={pdfPreviewInfo?.fileName}
                pdfUrl={pdfPreviewInfo?.url}
                closeModal={() => setPdfPreviewInfo(null)}
            />}
        </Modal>
    )
}

export default UnderwritingSurrender;
