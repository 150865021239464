import dayjs from "dayjs";
/**
 * 格式化时间
 */
export const formatTime = (value: any, formatType: string = 'YYYY-MM-DD HH:mm:ss', placeholder?: any) => {
  if (value) {
    return dayjs(value).format(formatType);
  } else {
    return placeholder || '';
  }
}

/**
 * 将秒数转为中文可读的文案
 */
export const displayTime = (secondCount: any) => {
  const day = Math.floor(secondCount / (60 * 60 * 24));
  const hour = Math.floor((secondCount - day * 86400) / (60 * 60));
  const minute = Math.floor((secondCount - (day * 86400) - (hour * 3600)) / 60);
  const second = Math.floor((secondCount - (day * 86400) - (hour * 3600) - (minute * 60)));
  const dayString = day ? `${day}天` : '';
  const hourString = hour ? `${hour}小时` : '';
  const minuteString = minute ? `${minute}分钟` : '';
  let secondString = second ? `${second}秒` : '';
  // 如果天/小时/分钟都有就不显示秒
  if (dayString && hourString && minuteString) {
    secondString = '';
  }
  const timeString = `${dayString}${hourString}${minuteString}${secondString}` || '/';
  return {
    day,
    hour,
    minute,
    second,
    timeString,
  };
}

/**
 * 获取两个日期之间的所有日期
 * @param startDate 开始日期
 * @param endDate 结束日期
 */
export const getDateRange = (startDate: string, endDate: string) => {
  const dateRange = [];
  const startTime: any = new Date(startDate);
  const endTime: any = new Date(endDate);
  while (endTime - startTime >= 0) {
    const year = startTime.getFullYear();
    let month = startTime.getMonth();
    month = month < 9 ? `0${month + 1}` : month + 1;
    const dayString = startTime.getDate().toString();
    const day = dayString.length === 1 ? `0${dayString}` : dayString;
    dateRange.push(`${year}-${month}-${day}`);
    startTime.setDate(startTime.getDate() + 1);
  }
  return dateRange;
};