/**
 *  设备投保记录相关请求
 */
import request from "@/utils/request";

export interface ProductInsureRecordListRequestParams {
    page?: number; 
    limit?: number;
    aebSn?: any      //aeb设备sn
    carmaSn?: any    //相机sn  
    adasSN?: any     //adasSN
    frameNumber?:any //车架号
    plateNumber?:any //车牌号
}

export const productInsureRecordListRequestPath = '/api/v2/productInsureRecord/list';

// 获取数据
export const productInsureRecordListRequest = (params: ProductInsureRecordListRequestParams) => {
    return request.get(productInsureRecordListRequestPath, {
        params,
    })
}

// 添加数据
export const upsertProductInsureRecord = (data: any) => {
    return request.post('/api/v2/productInsureRecord/upsert', data)
}

// 删除数据
export const DeleteProductInsureRecord = (id: number) => {
    return request.delete(`/api/v2/productInsureRecord/${id}`)
}

// 查询最新需要投保的产品
export  const productInsureRecordProductRequestPath = '/api/v2/productInsureRecord/product/insure/query';
export const productInsureRecordProductRequest = () => {
    return request.get(productInsureRecordProductRequestPath, {
    })
}

//更新需要投保的产品   
export interface upsertProductInsureRecordProductProps {
    insureTime:any;
}
const upsertProductInsureRecordProductPath = '/api/v2/productInsureRecord/product/insure/update';
export const upsertProductInsureRecordProduct = (params: upsertProductInsureRecordProductProps) => {
    return request.get(upsertProductInsureRecordProductPath, {
        params,
    })
}

//批量投保记录导入 
export const importProductInsureRecord = '/api/v2/productInsureRecord/import';