/**
 * adas设备管理
 */
import request from "@/utils/request";

export interface adasRequestParams {
    page: number; 
    limit: number;
    // nickname?: string;
    // mobile?: string;
    // roleIds?: Array<number>;
}

export const adasListRequestPath = '/api/v2/adasDevice/list';

// 获取adas列表数据
export const adasListRequest = (params: adasRequestParams) => {
    return request.get(adasListRequestPath, {
        params,
    })
}

// 添加adas
export const addAdasRequest = (data: any) => {
    return request.post('/api/v2/adasDevice/upsert', data)
}

// 删除adas
export const adasDeleteRequest = (id: number) => {
    return request.delete(`/api/v2/adasDevice/${id}`)
}
