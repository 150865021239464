/**
 * 车辆CAN协议相关请求
 */
import request from '@/utils/request';

export interface CanInfoListRequestParams {
  page: number;
  limit: number;
  brand?: string;
  model?: string;
  hasFetch?: boolean;
}

// 获取车辆CAN协议数据
export const canInfoListRequestPath = '/api/v2/canInfo/list';
export const canInfoListRequest = (params: CanInfoListRequestParams) => {
  return request.get(canInfoListRequestPath, {
    params
  });
};

// 添加或编辑车辆CAN协议
export const upsertCanInfoRequest = (data: any) => {
  return request.post('/api/v2/canInfo/upsert', data);
};

// 获取车辆CAN协议详情
export const canInfoDetailRequest = (id: string) => {
  return request.get(`/api/v2/canInfo/detail/${id}`);
};

// 删除车辆CAN协议
export const deleteCanInfoRequest = (id: string) => {
  return request.delete(`/api/v2/canInfo/${id}`);
};

// 获取CAN协议统计
export const canDataRequest = () => {
  return request.get('/api/v2/car/cansData');
};

// dbc文件信号配置
export const dbcConfigRequest = (data: any) => {
  return request.post('/api/v2/canInfo/config/upsert', data);
};

// 获取dbc文件信号配置
export const getDbcConfigRequest = (id: number) => {
  return request.get(`/api/v2/canInfo/config/detail/${id}`);
};
