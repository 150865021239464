/**
 * json格式化展示弹窗
 */
import React from 'react';
import { Modal } from 'antd';

interface JsonModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const JsonModal = (props: JsonModalProps) => {
  const { data, closeModal } = props;

  // 判断data类型
  let jsonData = data;
  if (typeof data === 'string') {
    try {
      jsonData = JSON.parse(data);
    } catch (error) {
    }
  }
  return (
    <Modal
      title="详细信息"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <div style={{ whiteSpace: 'pre' }}>
        {JSON.stringify(jsonData, null, 4)}
      </div>
    </Modal>
  )
}

export default JsonModal;
    