import { round } from 'lodash';

export const ownRound = (value: any, precision: any) => {
  if (value) {
    return round(value, precision);
  } else {
    return value;
  }
}

// 十进制转二进制
export const decimalToBinary = (decimal: any) => {
  let res = "";
  let stack = []
  while (decimal > 0) {
    stack.push(decimal % 2);
    decimal = decimal >> 1;
  }
  while (stack.length) {
    res += stack.pop();
  }
  return res;
};

// 根据两个角获取中间角度
export const getMiddleAngle = (minAngle: number, maxAngle: number) => {
  if (maxAngle >= minAngle) {
    const angle = (maxAngle + minAngle) / 2;
    const angleRange = angle - minAngle;
    return { angle, angleRange };
  } else {
    // minAngle在0度左侧，maxAngle在0度右侧
    let angle = (minAngle + maxAngle + 360) / 2;
    if (angle >= 360) {
      // 中间角度在0度右侧
      angle = angle - 360;
      const angleRange = maxAngle - angle;
      return { angle, angleRange };
    } else {
      // 中间角度在0度左侧
      const angleRange = angle - minAngle;
      return { angle, angleRange };
    }
  }
};

// 根据角度和浮动区间获取最小值和最大值
export const getMinMaxAngle = (angle: number, angleRange: number) => {
  const minAngle = angle - angleRange;
  const maxAngle = angle + angleRange;
  return {
    headingAngleMin: minAngle > 0 ? minAngle : 360 + minAngle,
    headingAngleMax: maxAngle >= 360 ? maxAngle - 360 : maxAngle
  };
};

// 四舍五入保留有效小数点精度
export const ownEffectiveRound = (value: any, precision: any) => {
  if (value) {
    const matchValue = `${value}`.match(/^0.[0]*/);
    if (matchValue) {
      const macthPrefix = matchValue[0];
      if (
        macthPrefix.length > 3 &&
        !`${value}`.match(new RegExp(`^0.[0]{${precision}}[5-9]{1}`))
      ) {
        return `${value}`.slice(0, macthPrefix.length + precision);
      } else {
        return round(value, precision);
      }
    } else {
      return round(value, precision);
    }
  } else {
    return value;
  }
};
