/**
 * AT指令参数配置
 */
import React, { useState, useRef, useEffect } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Avatar,
  Row,
  Col,
  Popconfirm,
  message
} from 'antd';
import { UserOutlined, CarOutlined } from '@ant-design/icons';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import {
  atCommandJtKlsRequest,
  atListRequest,
  deleteAtRequest
} from '@/service/atCommand';
import { findOneCarRequest } from '@/service/carManage';
import { getJtKlsWareInfoRequest } from '@/service/jtKlsParameterConfiguration';
import { formSearchGutter } from '@/constants/common';
import { isKlsV3Aeb } from '@/utils/device';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';
import ownStyles from './index.module.scss';

const AtCommand = () => {
  const [form] = Form.useForm();
  const atChatBoxRef: any = useRef();
  const atSendFormRef: any = useRef();
  const [atChat, setAtChat] = useState<Array<string>>([]); // 平台设备AT指令记录
  const [loading, setLoading] = useState<boolean>(false); // 发送指令loading状态
  const [setallLoading, setSetallLoading] = useState<boolean>(false); // SETALLloading状态
  const [atList, setAtList] = useState<any>([]); // AT指令列表
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息存储

  // 获取AT指令列表
  const getAtList = async () => {
    const res = await atListRequest();
    setAtList(res.data?.data || []);
  };

  useEffect(() => {
    getAtList();
  }, []);

  // AT指令发送成功后显示区域滚动到最底部
  useEffect(() => {
    if (atChatBoxRef?.current) {
      atChatBoxRef.current.scrollTop = atChatBoxRef?.current.scrollHeight;
    }
  }, [atChat]);

  // 删除AT指令
  const deleteAt = async (id: number) => {
    const res = await deleteAtRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getAtList();
    }
  };

  // 处理AT指令参数格式
  const operateSearchParams = (params: any) => {
    const newParams = { ...params };
    newParams.sn = params.sn.trim();
    newParams.at = `NBR=1;${params.at.trim()}$;`;
    return newParams;
  };

  // 判断版本是否支持AT指令
  function judgeVersion(atVersion: string, deviceVersion: string) {
    if (!atVersion) {
      return true;
    }
    const deviceVStack = deviceVersion.replace(/^v/i, '').split('.');
    deviceVStack.length = 3;
    const atVStack = atVersion.replace(/^v/i, '').split('.');
    atVStack.length = 3;
    const maxLen = Math.max(deviceVStack.length, atVStack.length);
    for (let i = 0; i < maxLen; i++) {
      // 必须转整，否则按照字符顺序比较大小
      const prevVal = ~~atVStack[i];
      const currVal = ~~deviceVStack[i];
      if (prevVal > currVal) {
        return false;
      }
      if (prevVal < currVal) {
        return true;
      }
    }
    return true;
  }

  // AT指令发送成功后显示数据
  const sendSuccess = (at: string, res: any) => {
    setAtChat([
      ...atChat,
      at,
      (res.data?.data || '').includes('NBR=0')
        ? '请检查AT指令是否正常'
        : (res.data?.data || '').replace(/NBR=[0-9]{1};/, '').replace('$;', '')
    ]);
  };

  // 发送
  const onSend = async (values: any) => {
    try {
      setLoading(true);
      const params = operateSearchParams(values);
      // 车辆信息
      const carRes = await findOneCarRequest({ sn: params.sn });
      const carDeviceType = carRes.data?.data?.deviceTypeCategory; // 设备类型大类
      if (carRes.data?.code === 0) {
        // 昆仑山3.0才支持
        if (isKlsV3Aeb(carDeviceType)) {
          // 版本信息
          const wareRes = await getJtKlsWareInfoRequest(params.sn);
          if (wareRes.data?.code === 0) {
            const deviceVersion = wareRes.data?.data?.aebsSwVersion;
            const currentAt = values.at.replace('AT+', '').split('=')[0];
            const atVersion = atList.find(
              (item: any) => item.name === currentAt
            )?.version;
            // 判断当前控制器版本是否支持此AT指令
            if (judgeVersion(atVersion, deviceVersion)) {
              const res = await atCommandJtKlsRequest(params);
              if (res.data?.code === 0) {
                sendSuccess(values.at, res);
              }
            } else {
              message.warning('当前控制器版本不支持此AT指令');
            }
          }
        } else {
          message.warning('设备类型不支持AT指令');
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // SETALL
  const onSetall = async () => {
    try {
      setSetallLoading(true);
      const values = await form.validateFields();
      // 自动修改SETALL的AT指令
      values.at = 'AT+SETALL=1,1,0';
      const params = operateSearchParams(values);
      const res = await atCommandJtKlsRequest(params);
      if (res.data?.code === 0) {
        sendSuccess(values.at, res);
      }
      setSetallLoading(false);
    } catch (error) {
      setSetallLoading(false);
    }
  };

  // 点击AT指令名自动填写
  const atNameClick = (atData: any) => {
    const { name, type, param } = atData;
    const atCommand = `AT+${name}=1,${type ? `"${param}"` : param},0`;
    form.setFieldValue('at', atCommand);
  };

  // 列配置
  const columns = [
    {
      title: '指令名',
      dataIndex: 'name',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string, item: any) => (
        <RenderEllipsisColumn text={value}>
          <div
            onClick={() => atNameClick(item)}
            className={ownStyles.tableAtName}
          >
            {value}
          </div>
        </RenderEllipsisColumn>
      ),
      fixed: 'left' as const
    },
    {
      title: '默认参数',
      dataIndex: 'param',
      width: 80,
      // 区分数据类型
      render: (value: string, item: any) => (item.type ? `"${value}"` : value)
    },
    {
      title: '适用版本',
      dataIndex: 'version',
      width: 80
    },
    {
      title: '说明',
      dataIndex: 'ps',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 80,
      fixed: 'right' as const,
      render: (value: any, item: any) => (
        <div className={styles.tableOperateColumn}>
          <PermissionButton
            operateType="/car-admin/at-command/edit"
            type="link"
            onClick={() => setOperateInfo({ type: 'edit', data: item })}
          >
            编辑
          </PermissionButton>
          <Popconfirm
            title="您确定要删除此命令吗?"
            onConfirm={() => deleteAt(item.id)}
          >
            <PermissionButton
              operateType="/car-admin/at-command/delete"
              type="link"
            >
              删除
            </PermissionButton>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Row gutter={formSearchGutter}>
        <Col className={ownStyles.atShowBoxTitle} span={12}>
          AT指令发送接收显示
        </Col>
        <Col className={ownStyles.atShowBoxTitle} span={12}>
          <div>AT指令说明</div>

          <PermissionButton
            operateType="/car-admin/at-command/add"
            onClick={() => setOperateInfo({ type: 'add' })}
          >
            添加AT指令
          </PermissionButton>
        </Col>
      </Row>
      <Row className={ownStyles.atShowBox} gutter={formSearchGutter}>
        <Col className={ownStyles.chatBox} span={12} ref={atChatBoxRef}>
          {atChat.map((item: string, index: number) => (
            <div className={ownStyles.chatItem}>
              {index % 2 === 1 && (
                <Avatar
                  style={{ backgroundColor: '#52c41a' }}
                  icon={<CarOutlined />}
                />
              )}
              <div className={ownStyles.chat}>{item}</div>
              {index % 2 === 0 && (
                <Avatar
                  style={{ backgroundColor: '#40a9ff' }}
                  icon={<UserOutlined />}
                />
              )}
            </div>
          ))}
        </Col>
        <Col className={ownStyles.commandBox} span={12}>
          <CommonTable
            columns={columns}
            dataSource={atList}
            columnFilter={false}
            pagination={false}
          />
        </Col>
      </Row>
      <div ref={atSendFormRef}>
        <Form
          onFinish={onSend}
          form={form}
          autoComplete="off"
          className={ownStyles.atSendForm}
        >
          <Form.Item
            label="设备SN"
            name="sn"
            rules={[
              { required: true, message: '请输入设备SN' },
              { whitespace: true, message: '请输入设备SN' }
            ]}
          >
            <Input placeholder="请输入设备SN" />
          </Form.Item>
          <Form.Item
            label="AT指令"
            name="at"
            rules={[
              { required: true, message: '请输入AT指令' },
              { whitespace: true, message: '请输入AT指令' }
            ]}
          >
            <Input.TextArea placeholder="请输入AT指令" rows={3} />
          </Form.Item>
          <Form.Item className={ownStyles.buttomFormItem}>
            <div className={ownStyles.formContent}>
              <Button htmlType="submit" loading={loading}>
                发送AT指令
              </Button>
              <Button type="primary" loading={setallLoading} onClick={onSetall}>
                SETALL
              </Button>
              <div className={ownStyles.tip}>
                SETALL操作成功后AT指令操作才会生效，请在确保行车安全的前提下，进行AT指令操作！
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
      {/* 最近位置弹窗 */}
      {['add', 'edit'].includes(operateInfo.type) && (
        <OperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={getAtList}
        />
      )}
    </Card>
  );
};

export default AtCommand;
