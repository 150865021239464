/**
 * 报警信息实时状态推送
 */
import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons';
import { getWebSocketPath } from '@/utils/common';
import AlarmInfoModal from '@/components/alarmInfoModal';
import styles from './index.module.scss';

const AlarmPush = () => {
  const [visible, setVisible] = useState(false); // 面板是否显示
  const [alarmCount, setAlarmCount] = useState(0); // 推送的报警信息数量
  const [alarmData, setAlarmData] = useState<any>([]); // 推送的报警信息
  const [alarmDetail, setAlarmDetail] = useState<any>(null); // 报警信息详情

  // 处理推送数据
  const operateAlarmData = (data: any) => {
    const alarmTypeDispaly: any = {
      1: 'FCW预警',
      2: 'AEB制动',
      3: '起步阻止信息',
      4: 'LDW预警信息',
      5: 'HMW预警信息',
      6: 'AEB制动开关关闭',
      7: 'AEB制动开关打开',
    }
    const parseData = JSON.parse(data);
    parseData.alarm_type_name = alarmTypeDispaly[parseData.alarm_type];
    setAlarmCount((OldAlarmCount) => {
      return OldAlarmCount + 1;
    })
    setAlarmData((OldAlarmData: any) => {
      const newAlarmData = [parseData, ...OldAlarmData];
      return newAlarmData;
    })
  }

  // WebSocket 推送
  const openWs = () => {
    try {
      const webSocketPath = getWebSocketPath('/ws/aebClosed', true);
      const ws = new WebSocket(webSocketPath);
      ws.onopen = () => {
        console.log("WebSocket is open now");
      }
      ws.onmessage = (event) => {
        if (event.data) {
          operateAlarmData(event.data);
        }
      }
      ws.onerror = () => {
        console.log("WebSocket has error");
      }
      ws.onclose = () => {
        console.log('WebSocket close now');
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    // openWs();
  }, []);

  // 清空已有报警信息
  const clear = () => {
    setAlarmCount(0);
    setAlarmData([]);
  }

  return (
    <div>
      <div
        className={styles.toggleBox}
        onClick={() => setVisible(!visible)}
        style={{ right: visible ? '300px' : '0' }}
      >
        <div className={styles.count}>{alarmCount}</div>
        {visible ?
          <CaretRightOutlined className={styles.icon} /> :
          <CaretLeftOutlined className={styles.icon} />
        }
        <div className={styles.text}>实时状态</div>
      </div>
      <Drawer
        placement="right"
        visible={visible}
        className={styles.pushBox}
        onClose={() => setVisible(false)}
        mask={false}
        closable={false}
        zIndex={1001}
        width={300}
      >
        <div className={styles.alarmBox}>
          {alarmData.map((item: any, index: number) => (
            <div className={styles.alarmItem} key={index}>
              <div className={styles.title}>{item.car_plate_number}</div>
              <div>{item.alarm_type_name}</div>
              <div className={styles.time}>
                {item.reported_time}
                <Button
                  size='small'
                  type="primary"
                  onClick={() => setAlarmDetail(item)}
                >
                  查看详情
                </Button>
              </div>
            </div>
          ))}
          <div className={styles.clear}>
            <Button size='small' type="primary" onClick={clear}>清空</Button>
          </div>
        </div>
      </Drawer>
      {/* 报警信息弹窗 */}
      {alarmDetail &&
        <AlarmInfoModal closeModal={() => setAlarmDetail(null)} data={alarmDetail} />
      }
    </div>
  )
}

export default AlarmPush;
  