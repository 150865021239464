/* eslint-disable react/jsx-key */
/**
 * DBC自定义信号配置
 */
import React, { Fragment, useEffect, useState } from 'react';
import {
  Modal,
  Form,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  InputNumber
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import LoadingWrapper from '@/components/loadingWrapper';
import { dbcConfigRequest, getDbcConfigRequest } from '@/service/carCan';
import { customCANSignalData } from '@/constants/carCan';
import { canX, canBaudRates } from '@/constants/parameterConfiguration';
import { formSearchGutter } from '@/constants/common';
import styles from './index.module.scss';

interface SignalConfigModalProps {
  dbcId: number;
  onClose: () => void;
  reloadData: () => void;
}
const SignalConfigModal = (props: SignalConfigModalProps) => {
  const [form] = Form.useForm();
  const [signalParamsArr, setSignalParamsArr] = useState<any>([]); // 配置信号下对应的表单项
  const [loading, setLoading] = useState<boolean>(false); // 是否在获取配置中
  const [configLoading, setConfigLoading] = useState<boolean>(false); // 是否在设置配置中
  const { dbcId, onClose, reloadData } = props;

  useEffect(() => {
    getDbcConfig();
  }, []);

  // 获取dbc的配置
  const getDbcConfig = async () => {
    setLoading(true);
    const res = await getDbcConfigRequest(dbcId);
    const dbcConfig = res?.data?.data?.content;
    // 表单赋值
    if (dbcConfig) {
      const parseDbcConfig = JSON.parse(dbcConfig || '{}');
      const formValues: any = [];
      const matchSignalParamsArr: any = [];
      Object.keys(parseDbcConfig).forEach((item: string) => {
        formValues.push({
          signal: item,
          ...parseDbcConfig[item]
        });
        const matchCustomCANSignal = customCANSignalData.find(
          (customCANSignal: any) => customCANSignal.value === item
        );
        matchSignalParamsArr.push(matchCustomCANSignal?.childrenItems || []);
      });
      setSignalParamsArr(matchSignalParamsArr);
      form.setFieldsValue({ configs: formValues });
    } else {
      form.setFieldsValue({ configs: [{ signal: undefined }] });
    }
    setLoading(false);
  };

  // 保存车辆协议信息
  const onFinish = async (values: any) => {
    try {
      setConfigLoading(true);
      const configParams: any = {};
      (values.configs || []).forEach((item: any) => {
        const type = item?.signal;
        const currentConfig = { ...item };
        // 收发标志选择接收/输入时处理功能类型、报文之间时间间隔字段
        if (currentConfig.rsFlag === 0) {
          currentConfig.functionType = 0;
          currentConfig.timeInterval = 0;
        }
        delete currentConfig.signal;
        if (type) {
          configParams[type] = currentConfig;
        }
      });
      const params = {
        dbcId,
        content: JSON.stringify(configParams)
      };
      const res = await dbcConfigRequest(params);
      setConfigLoading(false);
      if (res?.data?.data) {
        message.success('配置成功');
        onClose();
        reloadData();
      }
    } catch (error) {
      setConfigLoading(false);
      console.log(error);
    }
  };

  // 选择信号后渲染对应信号的表单项
  const onSignalChange = (value: any, options: any, index: number) => {
    const newSignalArr = [...signalParamsArr];
    newSignalArr[index] = options.childrenItems;
    setSignalParamsArr(newSignalArr);
  };

  // 删除信号区块
  const removeSignalBox = (removeFunc: any, removeIndex: number) => {
    removeFunc(removeIndex);
    const newSignalArr = [...signalParamsArr];
    newSignalArr.splice(removeIndex, 1);
    setSignalParamsArr(newSignalArr);
  };

  // 校验信号不同的参数数值不能一致
  const validatorSignalParams = (
    rule: any,
    value: string,
    compareField: Array<string>,
    index: number
  ) => {
    const currentValues = form.getFieldsValue().configs[index];
    const compareFieldValues: any = [];
    compareField.forEach((item: string) => {
      compareFieldValues.push(currentValues[item]);
    });
    if (compareFieldValues.includes(value)) {
      return Promise.reject(new Error('信号的不同参数的值不能相同'));
    } else {
      compareField.forEach((item: string) => {
        const currentCompareField = ['configs', index, item];
        const compareValue = form.getFieldValue(currentCompareField);
        if (compareValue) {
          form.setFields([
            { name: currentCompareField, errors: [], value: compareValue }
          ]);
        }
      });
      return Promise.resolve();
    }
  };

  // 收发标志选择发送/输出时调整功能类型、报文之间时间间隔字段值
  const rsFlagChange = (e: any, index: number) => {
    if (e.target.value === 1) {
      form.setFieldValue(
        ['configs', index, 'functionType'],
        form.getFieldValue(['configs', index, 'functionType']) || undefined
      );
      form.setFieldValue(
        ['configs', index, 'timeInterval'],
        form.getFieldValue(['configs', index, 'timeInterval']) || undefined
      );
    }
  };

  return (
    <Modal
      width="80%"
      title="信号配置"
      open
      onCancel={onClose}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
      okButtonProps={{ loading: configLoading }}
    >
      <LoadingWrapper spinning={loading}>
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.List name="configs">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field: any, index: number) => {
                    // 配置信号显示未配置过的信号
                    const hiddenSignals: Array<string> = []; // 在当前项隐藏已经被选择过的信号
                    (form.getFieldsValue()?.configs || []).forEach(
                      (item: any, valueIndex: number) => {
                        if (item?.signal && valueIndex !== index) {
                          hiddenSignals.push(item?.signal);
                        }
                        // 危险灯和发动机转速信号选择互斥(昆仑山3.0设备使用危险灯信号，昆仑山3.0之前的设备使用发动机转速信号)
                        if (item?.signal === 'AEBS_EngineSpeed') {
                          hiddenSignals.push('AEBS_Hazard');
                        }
                        if (item?.signal === 'AEBS_Hazard') {
                          hiddenSignals.push('AEBS_EngineSpeed');
                        }
                      }
                    );
                    const showCustomCANSignalData = customCANSignalData.filter(
                      (item: any) => !hiddenSignals.includes(item.value)
                    );
                    return (
                      <div className={styles.signalItemBox}>
                        <div className={styles.signalItemForm}>
                          <Form.Item
                            label="配置信号"
                            name={[field.name, 'signal']}
                            rules={[
                              { required: true, message: '请选择配置信号' }
                            ]}
                          >
                            <Select
                              options={showCustomCANSignalData}
                              placeholder="请选择配置信号"
                              onChange={(value: any, options: any) =>
                                onSignalChange(value, options, index)
                              }
                            />
                          </Form.Item>
                          <Form.Item noStyle shouldUpdate>
                            {() => {
                              const formValues = form.getFieldsValue();
                              if (formValues.configs[index]?.signal) {
                                return (
                                  <Fragment>
                                    <Row gutter={formSearchGutter} wrap>
                                      <Col span={6}>
                                        <Form.Item
                                          label="是否使用"
                                          name={[field.name, 'enable']}
                                          rules={[
                                            {
                                              required: true,
                                              message: '请选择是否使用'
                                            }
                                          ]}
                                        >
                                          <Radio.Group
                                            options={[
                                              { label: '不使用', value: 0 },
                                              { label: '使用', value: 1 }
                                            ]}
                                          />
                                        </Form.Item>
                                      </Col>
                                      {/* 选择不使用时不显示当前信号独有配置项 */}
                                      <Form.Item noStyle shouldUpdate>
                                        {() => {
                                          if (
                                            formValues.configs[index]
                                              ?.enable === 1
                                          ) {
                                            return (
                                              signalParamsArr[index] || []
                                            ).map((item: any) => {
                                              const comObj: any = {
                                                radio: Radio.Group,
                                                inputNumber: InputNumber
                                              };
                                              const MatchCom =
                                                comObj[item.formType];
                                              return (
                                                <Col span={item.span}>
                                                  <Form.Item
                                                    label={item.title}
                                                    name={[
                                                      field.name,
                                                      item.field
                                                    ]}
                                                    rules={
                                                      item.compareField
                                                        ? [
                                                            ...item.rules,
                                                            {
                                                              validator: (
                                                                rule: any,
                                                                value: string
                                                              ) =>
                                                                validatorSignalParams(
                                                                  rule,
                                                                  value,
                                                                  item.compareField,
                                                                  index
                                                                )
                                                            }
                                                          ]
                                                        : item.rules
                                                    }
                                                  >
                                                    <MatchCom
                                                      {...item.props}
                                                      style={{ width: '100%' }}
                                                    />
                                                  </Form.Item>
                                                </Col>
                                              );
                                            });
                                          }
                                        }}
                                      </Form.Item>
                                    </Row>
                                    {/* 选择不使用时不显示收发标志相关配置项 */}
                                    <Form.Item noStyle shouldUpdate>
                                      {() => {
                                        if (
                                          formValues.configs[index]?.enable ===
                                          1
                                        ) {
                                          return (
                                            <Row gutter={formSearchGutter}>
                                              <Col span={6}>
                                                <Form.Item
                                                  label="收发标志"
                                                  name={[field.name, 'rsFlag']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: '请选择收发标志'
                                                    }
                                                  ]}
                                                >
                                                  <Radio.Group
                                                    options={[
                                                      {
                                                        label: '接收/输入',
                                                        value: 0
                                                      },
                                                      {
                                                        label: '发送/输出',
                                                        value: 1
                                                      }
                                                    ]}
                                                    onChange={(e: any) =>
                                                      rsFlagChange(e, index)
                                                    }
                                                  />
                                                </Form.Item>
                                              </Col>
                                              {/* 收发标志选择发送/输出时才显示相关配置项 */}
                                              <Form.Item noStyle shouldUpdate>
                                                {() => {
                                                  if (
                                                    formValues.configs[index]
                                                      ?.rsFlag === 1
                                                  ) {
                                                    return (
                                                      <Fragment>
                                                        <Col span={6}>
                                                          <Form.Item
                                                            label="功能类型"
                                                            name={[
                                                              field.name,
                                                              'functionType'
                                                            ]}
                                                          >
                                                            <Select
                                                              options={[
                                                                // {
                                                                //   label: '无控制类型',
                                                                //   value: 0
                                                                // },
                                                                {
                                                                  label:
                                                                    '控制动类型',
                                                                  value: 1
                                                                },
                                                                {
                                                                  label:
                                                                    '控转向类型',
                                                                  value: 2
                                                                }
                                                              ]}
                                                              placeholder="请选择功能类型"
                                                            />
                                                          </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                          <Form.Item
                                                            label="两帧报文之间的时间间隔"
                                                            name={[
                                                              field.name,
                                                              'timeInterval'
                                                            ]}
                                                          >
                                                            <InputNumber
                                                              addonAfter="ms"
                                                              min={0}
                                                              style={{
                                                                width: '100%'
                                                              }}
                                                              placeholder="请输入两帧报文之间的时间间隔"
                                                            />
                                                          </Form.Item>
                                                        </Col>
                                                      </Fragment>
                                                    );
                                                  }
                                                }}
                                              </Form.Item>
                                            </Row>
                                          );
                                        }
                                      }}
                                    </Form.Item>
                                    {/* 选择不使用时不显示CAN接口相关配置项 */}
                                    <Form.Item noStyle shouldUpdate>
                                      {() => {
                                        if (
                                          formValues.configs[index]?.enable ===
                                          1
                                        ) {
                                          return (
                                            <Row gutter={formSearchGutter}>
                                              <Col span={6}>
                                                <Form.Item
                                                  label="CAN接口"
                                                  name={[field.name, 'canX']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: '请选择CAN接口'
                                                    }
                                                  ]}
                                                >
                                                  <Select
                                                    options={canX}
                                                    placeholder="请选择CAN接口"
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={6}>
                                                <Form.Item
                                                  label="CAN波特率"
                                                  name={[field.name, 'canBaud']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: '请选择CAN波特率'
                                                    }
                                                  ]}
                                                >
                                                  <Select
                                                    options={canBaudRates}
                                                    placeholder="请选择CAN波特率"
                                                  />
                                                </Form.Item>
                                              </Col>
                                              <Col span={6}>
                                                <Form.Item
                                                  label="数据类型"
                                                  name={[
                                                    field.name,
                                                    'dataType'
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: '请选择数据类型'
                                                    }
                                                  ]}
                                                >
                                                  <Radio.Group
                                                    options={[
                                                      {
                                                        label: '无符号',
                                                        value: 0
                                                      },
                                                      {
                                                        label: '有符号float',
                                                        value: 1
                                                      }
                                                    ]}
                                                  />
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      }}
                                    </Form.Item>
                                    {/* 选择不使用时不显示无效值使能相关配置项 */}
                                    <Form.Item noStyle shouldUpdate>
                                      {() => {
                                        if (
                                          formValues.configs[index]?.enable ===
                                          1
                                        ) {
                                          return (
                                            <Row gutter={formSearchGutter}>
                                              <Col span={6}>
                                                <Form.Item
                                                  label="无效值使能"
                                                  name={[field.name, 'invalid']}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        '请选择无效值使能'
                                                    }
                                                  ]}
                                                >
                                                  <Radio.Group
                                                    options={[
                                                      {
                                                        label:
                                                          '无效值无效（不使用）',
                                                        value: 0
                                                      },
                                                      {
                                                        label:
                                                          '无效值有效（使用）',
                                                        value: 1
                                                      }
                                                    ]}
                                                  />
                                                </Form.Item>
                                              </Col>
                                              {/* 选择无效值有效（使用）时显示该表单项 */}
                                              <Col span={6}>
                                                <Form.Item noStyle shouldUpdate>
                                                  {() => {
                                                    if (
                                                      formValues.configs[index]
                                                        ?.invalid === 1
                                                    ) {
                                                      return (
                                                        <Form.Item
                                                          label="无效值数据值"
                                                          name={[
                                                            field.name,
                                                            'invalidValue'
                                                          ]}
                                                          rules={[
                                                            {
                                                              required: true,
                                                              message:
                                                                '请填写无效值数据值'
                                                            }
                                                          ]}
                                                        >
                                                          <InputNumber
                                                            style={{
                                                              width: '100%'
                                                            }}
                                                          />
                                                        </Form.Item>
                                                      );
                                                    }
                                                  }}
                                                </Form.Item>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      }}
                                    </Form.Item>
                                  </Fragment>
                                );
                              }
                            }}
                          </Form.Item>
                        </div>
                        <div
                          className={styles.deleteIcon}
                          onClick={() => removeSignalBox(remove, index)}
                        >
                          <DeleteOutlined />
                        </div>
                      </div>
                    );
                  })}
                  {/* 所有信号都配置了时无法再添加信号配置(减一是因为有两个信号是互斥信号) */}
                  {signalParamsArr.length < customCANSignalData.length - 1 && (
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      添加信号配置
                    </Button>
                  )}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default SignalConfigModal;
