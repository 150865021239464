/**
 * 部标机报警视频上传规则
 */
import React, { useState } from 'react';
import { Card } from 'antd';
import { useTable } from '@/utils/useTable';
import { jtDeviceUploadRuleListRequest } from '@/service/jtDeviceUploadRule';
import PermissionButton from '@/components/permissionButton';
import RuleTable from './components/ruleTable';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const JtDeviceUploadRule = () => {
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const { loading, data, getData } = useTable({
    requestFunc: jtDeviceUploadRuleListRequest,
    initRequest: true,
    initPageSize: 999
  });

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/dvr-upload-rule/add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <RuleTable loading={loading} data={data} getData={getData} />
      {operateInfo.type === 'add' && (
        <OperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
    </Card>
  );
};

export default JtDeviceUploadRule;
