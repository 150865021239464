/**
 * 指派/重新指派工单的弹窗
 */
import React, { useState, useEffect } from "react";
import { Modal, Form, Select, message } from "antd";
import dayjs from "dayjs";
import DatePicker from '@/components/datePicker';
import { userListRequest } from '@/service/userManage';
import { reassignWorkOrderRequest, assignWorkOrderRequest } from '@/service/workOrder';

interface AssignModalProps {
  data: any; // 工单信息
  type: string; // 操作类型(指派/重新指派)
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 指派成功后重新请求工单的方法
}

const AssignModal = (props: AssignModalProps) => {
  const { data, onClose, reloadData, type } = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [maintenancePerson, setMaintenancePerson] = useState([]); // 维保人员数据
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 获取维保人员数据
  const getMaintenancePerson = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setMaintenancePerson(res.data?.data || [])
  }

  useEffect(() => {
    getMaintenancePerson();
  }, []);

  // 指派工单
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        const params = {...values};
        // params.expire = dayjs(values.expire).format("YYYY-MM-DD") + ' 23:59:59';
        const matchOperator: any = maintenancePerson.find((item: any) => item.id === values.operatorId);
        params.operator = matchOperator?.nickname;
        params.id = data?.id;
        setLoading(true);
        // 根据操作类型区分调用的接口
        const requestMethod = type === 'assign' ?  assignWorkOrderRequest : reassignWorkOrderRequest;
        const res = await requestMethod(params);
        if (res.status == 200) {
          setSubmitNoRepetition(true)
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success(type === 'assign' ? '指派工单成功' : '重新指派工单成功');
          onClose();
          reloadData();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <Modal
      title={type === 'assign' ? "指派工单" : "重新指派工单"}
      visible
      centered
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="维保人员"
          name="operatorId"
          rules={[
            { required: true, message: '请选择维保人员' }
          ]}
        >
          <Select
            placeholder="请选择维保人员"
            options={maintenancePerson}
            allowClear
            showArrow
            showSearch
            fieldNames={{
              label: 'nickname',
              value: 'id'
            }}
            optionFilterProp="nickname"
          />
        </Form.Item>
        {/* <Form.Item
          label="期望完成时间"
          name="expire"
          rules={[
            { required: true, message: '请选择期望完成时间' }
          ]}
          initialValue={data.expire ? dayjs(data.expire) : null}
        >
          <DatePicker
            // showTime
            placeholder="请选择期望完成时间"
            style={{ width: '100%' }}
            disabledDate={(currentDate: any) => currentDate && currentDate < dayjs()}
          />
        </Form.Item> */}
      </Form>
    </Modal>
  )
}

export default AssignModal;