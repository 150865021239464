/**
 * 出险记录
 */
import React, { useState, useEffect } from 'react';
import { Card, Tag, message, Button, Popconfirm, Form, Row, Col, Input, Select } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import DatePicker from '@/components/datePicker';
import FleetSelect from '@/components/fleetSelect';
import UploadExcel from '@/components/uploadExcel';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { TagCategoryEnum } from '@/constants/tagManage';
import { accidentRecordRequest, accidentRecordRequestPath, importAccidentRecordRequestPath, deleteAccidentRecordRequest } from '@/service/accidentRecord';
import { tagListRequest } from '@/service/tagManage';
import OperateModal from './components/operateModal';
import RepeatModal from './components/repeatModal';
import ReportModal from './components/reportModal';
import styles from '@/styles/pageContent.module.scss';
import Thumbnail from '@/components/thumbnail';
import {insureListRequest} from '@/service/insurerManage';
import {companyListRequest} from '@/service/companyManage';



const { RangePicker } = DatePicker;
const AccidentRecord = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [accidentEnum, setAccidentEnum] = useState<any>({}); // 出险记录相关枚举
  const [organizations, setOrganizations] = useState<any>([]); // 承保机构
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: accidentRecordRequest,
    initPageSize: 40,
    searchParams,
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getAccidentTags();
    getOrganization();
  }, []);

  //自车车速标签
  const selfCarSpeedData = [
    { label: '≤10', value:'≤10' },
    { label: '10~30', value: '10~30' },
    { label: '30~50', value: '30~50' },
    { label: '50~70', value: '50~70' },
    { label: '70~90', value: '70~90' },
    { label: '≥90', value: '≥90' }
  ]
   //自车行为标签
   const selfCarBehaviorData = [
    { label: '直行', value:'直行' },
    { label: '左转', value: '左转' },
    { label: '右转', value: '右转' },
    { label: '倒车', value: '倒车' },
    { label: '起步', value: '起步' },
    { label: '其他', value: '其他' }
  ]
    //路段类型标签
    const  roadTypeData = [
      { label: '直道', value:'直道' },
      { label: '弯道', value: '弯道' },
      { label: '交叉路口', value: '交叉路口' },
      { label: '其他', value: '其他' }
    ]
      //障碍物类型标签
   const obstacleTypeData = [
    { label: '机动车', value:'机动车' },
    { label: '非机动车', value: '非机动车' },
    { label: '行人', value: '行人' },
    { label: '其他', value: '其他' },
    { label: '无', value: '无' }
  ]

  // 获取出险相关的标签数据
  const getAccidentTags = async() => {
    const res = await tagListRequest({
      tagSorts: [
        TagCategoryEnum.accidentType,
        TagCategoryEnum.accidentCollisionLocation,
        TagCategoryEnum.accidentClassification,
      ],
    });
    const enumData: any = {
      accidentType: [],
      accidentCollisionLocation: [],
      accidentClassification: [],
    };
    (res.data?.data || []).forEach((item: any) => {
      const { title, id, isDisabled } = item;
      const tag = { label: title, value: id, disabled: isDisabled };
      if (item.tagSort === TagCategoryEnum.accidentType) {
        enumData.accidentType.push(tag);
      }
      if (item.tagSort === TagCategoryEnum.accidentCollisionLocation) {
        enumData.accidentCollisionLocation.push(tag);
      }
      if (item.tagSort === TagCategoryEnum.accidentClassification) {
        enumData.accidentClassification.push(tag);
      }
    })
    setAccidentEnum(enumData);
  }

  // 删除出险记录
  const deleteAccident = async(id: number) => {
    const res = await deleteAccidentRecordRequest(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams = {...params};
    delete newSearchParams.time;
    newSearchParams.beginDate = formatTime(params.time?.[0], 'YYYY-MM-DD') || undefined;
    newSearchParams.endDate = formatTime(params.time?.[1], 'YYYY-MM-DD') || undefined;
    newSearchParams.caseNumber = (params.caseNumber || '').trim() || undefined;
    newSearchParams.plateNumber = (params.plateNumber || '').trim() || undefined;
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    setSearchParams(operateSearchParams(values));
  };

  // 获取导出的参数
  const getExportParams = () => {
    const params = form.getFieldsValue();
    return operateSearchParams(params, true);
  }

  // 上传成功的方法
  const uploadSuccess = (resData: any) => {
    if (typeof resData === 'boolean') {
      message.success('导入成功');
      getData();
    } else {
      setOperateInfo({ type: 'repeat', data: resData });
    }
  }

  // 获取承保机构
  // const getOrganization = async () => {
  //   const res = await tagListRequest({
  //     tagSorts: [
  //       TagCategoryEnum.organization,
  //     ],
  //   });
  //   const data: any = {
  //     organization: [],
  //   };
  //   (res.data?.data || []).forEach((item: any) => {
  //     const { title } = item;
  //     const organizations = { label: title, value: title };
  //     data.organization.push(organizations);
  //   })
  //   setOrganizations(data.organization);
  // }

  
  // 获取承保保司
  const getOrganization = async () => {
    const res = await insureListRequest('');
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {name, id} = item;
      tags.push({label: name, value: name});
    });
    setOrganizations(tags);
  }

   // 获取车队
   const getCateAllCompanyData = async () => {
    const res = await companyListRequest({page: 1, limit: 20000, name: ''});
    const data = res.data?.data || [];
    //循环遍历data 
    const aa: any = []
    data.forEach((item: any) => {
      aa.push({label: item.name, value: item.id})
    })
    setAllCompany(aa)
  }
  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 90,
      fixed: 'left' as 'left',
    },
    {
      title: '报案号',
      dataIndex: 'caseNumber',
      width: 60,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '被保险人',
      dataIndex: 'insurer',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '出险时间',
      dataIndex: 'insureDateTime',
      width: 85,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '承保机构',
      dataIndex: 'channel',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '所属车队',
      dataIndex: 'motorcade',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '出险金额',
      dataIndex: 'claimAmount',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '人伤金额',
      dataIndex: 'personAmount',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '事故类型',
      dataIndex: 'accidentTypeStr',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '碰撞方位',
      dataIndex: 'accidentCollisionLocationStr',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '事故分类',
      dataIndex: 'accidentClassificationStr',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '自车车速',
      dataIndex: 'selfCarSpeed',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '自车行为',
      dataIndex: 'selfCarBehavior',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '路段类型',
      dataIndex: 'roadType',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '障碍物类型',
      dataIndex: 'obstacleType',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '事故标签',
      dataIndex: 'tags',
      width: 75,
      ellipsis: { showTitle: false },
      render: (value: any) => <div className={styles.tableTagColumn}>
        {(Object.values(value || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag>))}
      </div>,
    },
    {
      title: '单证',
      dataIndex: 'documentResources',
      width: 110,
          render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="单证"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '标的车损',
      dataIndex: 'targetCarResources',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="标的车损"
          />共{value.length}张</span>);
        }
      }

    },
    {
      title: '标的人伤',
      dataIndex: 'targetPersonResources',
      width: 110,
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="标的人伤"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '三者车损',
      dataIndex: 'otherCarResources',
      width: 110,
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="三者车损"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '三者物损',
      dataIndex: 'otherObjectResources',
      width: 110,
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="三者物损"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '三者人伤',
      dataIndex: 'otherPersonResources',
      width: 110,
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="三者人伤"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '支付',
      dataIndex: 'payResources',
      width: 110,
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="支付"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '其他照片',
      dataIndex: 'otherResources',
      width: 110,
      render: (value: any) => {
        if (value && value.length > 0) {
          return (<span><Thumbnail
            dataSource={value.slice(0,1)}
            downloadImgName="其他照片"
          />共{value.length}张</span>);
        }
      }
    },
    {
      title: '备注',
      dataIndex: 'ps',
      width: 60,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 80,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton operateType="/car-admin/accident-record/edit" type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <Popconfirm
              title="您确定要删除此出险记录吗?"
              onConfirm={() => deleteAccident(item.id)}
            >
              <PermissionButton operateType='/car-admin/accident-record/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form form={form} onFinish={onSearch} autoComplete="off">
        <Row gutter={12} wrap>
          <Col span={8}>
            <Form.Item
              label="出险时间段"
              name="time"
            >
              <RangePicker allowClear style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="报案号"
              name="caseNumber"
            >
              <Input placeholder="请输入报案号" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
            >
              <Input placeholder="请输入车牌号" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="事故类型"
              name="accidentType"
            >
              <Select
                options={accidentEnum?.accidentType || []}
                placeholder="请选择事故类型"
                showSearch
                mode="multiple"
                showArrow
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="事故碰撞方位"
              name="accidentCollisionLocation"
            >
              <Select
                options={accidentEnum?.accidentCollisionLocation || []}
                placeholder="请选择事故碰撞方位"
                showSearch
                mode="multiple"
                showArrow
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="事故分类"
              name="accidentClassification"
            >
              <Select
                options={accidentEnum?.accidentClassification || []}
                placeholder="请选择事故分类"
                showSearch
                mode="multiple"
                showArrow
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="承保机构"
              name="channel"
            >
              <Select
                options={organizations}
                placeholder="请选择承保机构"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="所属车队"
              name="motorcade"
            >
              <FleetSelect valueField="label" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="自车车速"
              name="selfCarSpeed"
            >
              <Select
                options={selfCarSpeedData}
                placeholder="请选择自车车速"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="自车行为"
              name="selfCarBehavior"
            >
              <Select
                options={selfCarBehaviorData }
                placeholder="请选择自车行为"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="路段类型"
              name="roadType"
            >
              <Select
                options={roadTypeData}
                placeholder="请选择路段类型"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="障碍物类型"
              name="obstacleType"
            >
              <Select
                options={obstacleTypeData}
                placeholder="请选择障碍物类型"
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton
                  url={accidentRecordRequestPath}
                  fileName='出险记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/accident-record/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/accident-record/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
        <Button href="/accident_import_demo.xlsx">下载导入模板文件</Button>
        <UploadExcel
          uploadRequestPath={importAccidentRecordRequestPath}
          uploadPermissionKey="/car-admin/accident-record/import"
          uploadButtonProps={{ children: '批量导入' }}
          uploadSuccessFunc={uploadSuccess}
        /> 
        <PermissionButton
          type="primary"
          operateType="/car-admin/accident-record/exportReport"
          onClick={() => setOperateInfo({ type: 'report' })}
        >
          单车和车队报告
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        size="small"
      />
      {['edit', 'add'].includes(operateInfo.type) &&
        <OperateModal
          data={operateInfo.data}
          type={operateInfo.type}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      }
      {operateInfo.type === 'repeat' &&
        <RepeatModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      }
      {operateInfo.type === 'report' && <ReportModal closeModal={() => setOperateInfo({})} />}
    </Card>
  )
}

export default AccidentRecord;