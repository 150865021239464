/**
 * 雷达编号说明弹窗
 */
import React, { useState } from 'react';
import { Modal, Tree, Image } from 'antd';
import { radarNumberData } from '@/constants/radarNumber';
import ultrasonicImg from '@/assets/image/ultrasonic.jpg';
import styles from './index.module.scss';

interface RadarNumberModalProps {
  closeModal: () => void; // 关闭弹窗的方法
}

const RadarNumberModal = (props: RadarNumberModalProps) => {
  const { closeModal } = props;
  const [imgSrc, setImgSrc] = useState(ultrasonicImg); // 要展示的图片

  // 选择节点切换图片显示
  const onSelect = (selectedKeys: any, e: any) => {
    setImgSrc(e.node.img);
  }
  
  return (
    <Modal
      title="雷达编号说明"
      visible
      footer={null}
      onCancel={closeModal}
      centered
    >
      <div className={styles.radarNumberModal}>
        <div className={styles.treeBox}>
          <Tree
            treeData={radarNumberData}
            defaultExpandAll
            defaultSelectedKeys={['ultrasonicDefault']}
            onSelect={onSelect}
          />
        </div>
        <Image src={imgSrc} height={350} />
      </div>
    </Modal>
  )
}

export default RadarNumberModal;
    