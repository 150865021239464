/**
 * 固件详细信息
 */
import React from 'react';
import { Modal, Descriptions } from 'antd';
import { firmwareType, firmwareGroup } from '@/constants/firmwareManage';

interface FirmwareInfoModalProps {
  closeModal: () => void;
  data: any;
}
const FirmwareInfoModal = (props: FirmwareInfoModalProps) => {
  const { closeModal, data } = props;

  // 渲染固件类型
  const renderType = (value: number) => {
    const matchType = firmwareType.find((item: any) => item.value === value);
    return matchType?.label || value;
  };

  // 渲染固件分组
  const renderGroup = (value: number) => {
    const matchGroup = firmwareGroup.find((item: any) => item.value === value);
    return matchGroup?.label || value;
  };

  return (
    <Modal
      title="固件详细信息"
      open
      footer={null}
      centered
      onCancel={closeModal}
    >
      <Descriptions
        bordered
        size="small"
        column={2}
        labelStyle={{ whiteSpace: 'nowrap' }}
      >
        <Descriptions.Item label="固件类型">
          {renderType(data.type)}
        </Descriptions.Item>
        <Descriptions.Item label="固件分组">
          {renderGroup(data.packet)}
        </Descriptions.Item>
        <Descriptions.Item label="固件版本" span={2}>
          {data.version}
        </Descriptions.Item>
        <Descriptions.Item label="版本描述" span={2}>
          {data.ps}
        </Descriptions.Item>
        <Descriptions.Item label="文件地址" span={2}>
          {data.resourcePath}
        </Descriptions.Item>
        <Descriptions.Item label="文件大小">{data.size}</Descriptions.Item>
        <Descriptions.Item label="文件MD5">{data.md5}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default FirmwareInfoModal;
