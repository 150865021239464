/**
 * 保单收费记录
 */
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider } from 'antd';
import LoadingWrapper from '@/components/loadingWrapper';
import styles from './index.module.scss';
import { insurePayRecordListRequest } from "@/service/insurePayRecord";//保单收费记录请求
import InsurePayItem from '../insurePayItem';

interface CarProceedRecoedProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭保单收费记录弹层的方法
}
const InsurePayRecord = (props: CarProceedRecoedProps) => {
  const { carInfo, carInfo: { plate_number, frame_number}, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 保单收费记录数据
  const [loading, setLoading] = useState<boolean>(false) // 保单收费记录数据是否在请求中
  
  // 获取保单收费记录
  const getMaintenancesRecord = async(newPage: number) => {
    try {
      setLoading(true);
      const res = await insurePayRecordListRequest({});
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      console.log('recordData',recordData)
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMaintenancesRecord(1);
  }, []);

  // 加载更多
  const loadMore = () => {
    getMaintenancesRecord(page + 1);
  }

  function anyData(one: any, two: any): void {
    throw new Error('Function not implemented.');
  }

  return (
    <Drawer
      title={`${plate_number}保单收费记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any, index: any) => {
            return (
              <Timeline.Item key={index}>
                <div>
                  {item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}
                </div>
                <InsurePayItem column={{ xs: 1, sm: 3 }} data={item} type="maintenance" />
              </Timeline.Item>
              )
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无保单收费记录</Divider>
        }
      </LoadingWrapper>
    </Drawer>
  )
}

export default InsurePayRecord;