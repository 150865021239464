/**
 * 有快捷选项的DatePicker
 */
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';

interface QuickDateDayTomPickerProp {
    value?: any; // 选择的数据
    onChange?: (value: any) => void; // 选择时调用的方法
    style?: any; // 样式
}

const QuickDateDayTomPicker = (props: QuickDateDayTomPickerProp) => {
    const { value, onChange, style } = props;
    const [open, setOpen] = useState<boolean>(false); // 弹层是否展开
    const [selectValue, setSelectValue] = useState<any>(value); // 选择的日期

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    // 弹出日历和关闭日历的回调
    const onOpenChange = (value: boolean) => {
        setOpen(value);
    }

    // 快捷选择
    const quickSelect = (type: string) => {
        const dateObj: any = {
            'currentDay': dayjs().endOf('day'),
            'nextDay': dayjs().add(1, 'day').endOf('day'),
        }
        onDateChange(dateObj[type]);
        setOpen(false);
    }

    // 日期选择
    const onDateChange = (date: any) => {
        setSelectValue(date);
        onChange && onChange(date);
    }

    return (
        <DatePicker
            open={open}
            renderExtraFooter={() => <div>
                <Button type="link" size='small' onClick={() => quickSelect('currentDay')}>当天</Button>
                <Button type="link" size='small' onClick={() => quickSelect('nextDay')}>明天</Button>
            </div>}
            onOpenChange={onOpenChange}
            showToday={false}
            style={style}
            value={selectValue}
            onChange={onDateChange}
        />
    )
}


export default QuickDateDayTomPicker;
