/**
 * 严重故障定义
 */
import React from "react";
import { Card, Tabs } from 'antd';
import { DeviceTypeEnum } from "@/constants/deviceManage";
import FatalDefineList from "./components/fatalDefineList";
import styles from '@/styles/pageContent.module.scss';

const FatalDefine = () => {
  return (
    <Card bordered={false} className={styles.fixPageTabContentBox}>
      <Tabs>
        <Tabs.TabPane tab="昆仑山设备" key="kls">
          <FatalDefineList deviceType={DeviceTypeEnum.kunlunshan1} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="黄河设备" key="huanghe">
          <FatalDefineList deviceType={DeviceTypeEnum.huanghe1} />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  )
}

export default FatalDefine;