// 控制器CAN数量
export const controlerCanArr = [0, 1, 2, 3, 4, 5];

// 控制器CAN对应关系
export const controlerCanMap: NumberStringObj = {
  0: '相机',
  1: '毫米波雷达',
  2: '整车',
  3: '比例阀',
  4: '显示屏',
  5: '超声波雷达'
};

// 昆仑山设备信息
export const klsDeviceStatusData: any = {
  cameraFailureId: {
    name: '相机故障',
    values: {
      0: "无故障",
      2: "相机异常",
      4: "内部看门狗错误",
      5: "FPGA的内存出错",
      6: "没有remap数据",
      7: "ISP检查返回错误",
      11: "视差数据异常",
      31: "无法通过指定的 CAN 协议获取汽车信号",
      32: "无法获取雷达等的传感器信号",
      33: "通道 0 总线异常（仅表示发送异常）",
      34: "通道 1 总线异常（仅表示发送异常）",
      41: "IMU 信号异常",
      45: "GPS 信号异常",
      46: "4G 信号异常",
      81: "高温保护",
      91: "遮挡(此错误码非相机端发送)",
    }
  },
  proportionFailureId: {
    name: '比例阀故障',
    values: {
      0: "无故障",
      1: "内部错误",
      2: "电压过低",
      3: "电压过高",
      4: "压差过大",
      5: "没有接收到控制压力的命令",
      6: "压力控制指令丢失",
      7: "最后一段CAN数据丢失",
      8: "压力传感器开路",
      9: "压力传感器短路",
      10: "比例阀开路",
      11: "比例阀短路"
    }
  },
  vehicleSpeedIsValid: {
    name: '车速信息',
    values: { 0: '无效', 1: '有效' }
  },
  aebSwitchStatus: {
    name: 'AEB开关状态',
    values: { 0: '关闭', 1: '开启' },
  },
  ldwSwitchStatus: {
    name: 'LDW开关状态',
    values: { 0: '关闭', 1: '开启' },
  },
  aebSwitchSource: {
    name: 'AEB开关源',
    values: {
      0: '系统默认参数',
      1: '小屏幕按键',
      2: 'PC端AT指令',
      3: '蓝牙App端AT指令',
      4: 'GPIO开关',
      5: '云平台'
    }
  },
  ldwSwitchSource: {
    name: 'LDW开关源',
    values: { 
      0: '系统默认参数',
      1: '小屏幕按键',
      2: 'PC端AT指令',
      3: '蓝牙App端AT指令',
      4: 'GPIO开关',
      5: '云平台'
    }
  },
  cameraOnlineStatus: {
    name: '相机在线状态',
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  cameraExternalEnvBrightness: {
    name: '相机外部环境亮度',
    values: {
      0: '保留',
      1: '白天',
      2: '黄昏',
      3: '夜晚',
      4: '遮挡'
    }
  },
  proportionOnlineStatus: {
    name: '比例阀在线状态', 
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  ultrasonicOnlineStatus: {
    name: '超声波在线状态', 
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  milimeterWaveOnlineStatus: {
    name: '毫米波在线状态',
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  screenOnlineStatus: {
    name: '小屏幕在线状态', 
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  t4gOnlineStatus: {
    name: '4G在线状态',
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  gpsOnlineStatus: {
    name: 'GPS在线状态', 
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  btOnlineStatus: {
    name: '蓝牙在线状态', 
    values: {
      0: '不存在',
      1: '离线',
      2: '在线'
    }
  },
  vehicleBodyFailureId: {
    name: '车身故障', 
    values: {
      0: '无故障',
      1: '车身故障'
    }
  },
  controlerFailureStatus: {
    name: '控制器故障',
    values: {
      0: '无故障',
      1: '内部看门狗错误',
      2: 'CAN通讯错误'
    }
  },
  controlerCan0Status: {
    name: '控制器CAN0总线开关', 
    values: {
      0: '总线开启',
      1: '总线关闭'
    }
  },
  controlerCan0CommErrorStatus: {
    name: '控制器CAN0通讯错误状态', 
    values: {
      0: '无错误', 
      1: 'CAN通讯错误'
    }
  },
  controlerCan0CommErrorId: {
    name: '控制器CAN0通讯错误代码', 
    values: {
      0: '无效',
      1: '位错误',
      2: '格式错误',
      3: '填充错误',
      4: '其他错误'
    }
  },
  controlerCan0CommErrorPoint: {
    name: '控制器CAN0通讯错误方向', 
    values: {
      0: '无效',
      1: '发送时发生的错误',
      2: '接收时发生的错误'
    }
  },
  controlerCan1Status: {
    name: '控制器CAN1总线开关', 
    values: {
      0: '总线开启',
      1: '总线关闭'
    }
  },
  controlerCan1CommErrorStatus: {
    name: '控制器CAN1通讯错误状态', 
    values: {
      0: '无错误', 
      1: 'CAN通讯错误'
    }
  },
  controlerCan1CommErrorId: {
    name: '控制器CAN1通讯错误代码', 
    values: {
      0: '无效',
      1: '位错误',
      2: '格式错误',
      3: '填充错误',
      4: '其他错误'
    }
  },
  controlerCan1CommErrorPoint: {
    name: '控制器CAN1通讯错误方向', 
    values: {
      0: '无效',
      1: '发送时发生的错误',
      2: '接收时发生的错误'
    }
  },
  controlerCan2Status: {
    name: '控制器CAN2总线开关', 
    values: {
      0: '总线开启',
      1: '总线关闭'
    }
  },
  controlerCan2CommErrorStatus: {
    name: '控制器CAN2通讯错误状态', 
    values: {
      0: '无错误', 
      1: 'CAN通讯错误'
    }
  },
  controlerCan2CommErrorId: {
    name: '控制器CAN2通讯错误代码', 
    values: {
      0: '无效',
      1: '位错误',
      2: '格式错误',
      3: '填充错误',
      4: '其他错误'
    }
  },
  controlerCan2CommErrorPoint: {
    name: '控制器CAN2通讯错误方向', 
    values: {
      0: '无效',
      1: '发送时发生的错误',
      2: '接收时发生的错误'
    }
  },
  controlerCan3Status: {
    name: '控制器CAN3总线开关', 
    values: {
      0: '总线开启',
      1: '总线关闭'
    }
  },
  controlerCan3CommErrorStatus: {
    name: '控制器CAN3通讯错误状态', 
    values: {
      0: '无错误', 
      1: 'CAN通讯错误'
    }
  },
  controlerCan3CommErrorId: {
    name: '控制器CAN3通讯错误代码', 
    values: {
      0: '无效',
      1: '位错误',
      2: '格式错误',
      3: '填充错误',
      4: '其他错误'
    }
  },
  controlerCan3CommErrorPoint: {
    name: '控制器CAN3通讯错误方向', 
    values: {
      0: '无效',
      1: '发送时发生的错误',
      2: '接收时发生的错误'
    }
  },
  controlerCan4Status: {
    name: '控制器CAN4总线开关', 
    values: {
      0: '总线开启',
      1: '总线关闭'
    }
  },
  controlerCan4CommErrorStatus: {
    name: '控制器CAN4通讯错误状态', 
    values: {
      0: '无错误', 
      1: 'CAN通讯错误'
    }
  },
  controlerCan4CommErrorId: {
    name: '控制器CAN4通讯错误代码', 
    values: {
      0: '无效',
      1: '位错误',
      2: '格式错误',
      3: '填充错误',
      4: '其他错误'
    }
  },
  controlerCan4CommErrorPoint: {
    name: '控制器CAN4通讯错误方向', 
    values: {
      0: '无效',
      1: '发送时发生的错误',
      2: '接收时发生的错误'
    }
  },
  controlerCan5Status: {
    name: '控制器CAN5总线开关', 
    values: {
      0: '总线开启',
      1: '总线关闭'
    }
  },
  controlerCan5CommErrorStatus: {
    name: '控制器CAN5通讯错误状态', 
    values: {
      0: '无错误', 
      1: 'CAN通讯错误'
    }
  },
  controlerCan5CommErrorId: {
    name: '控制器CAN5通讯错误代码', 
    values: {
      0: '无效',
      1: '位错误',
      2: '格式错误',
      3: '填充错误',
      4: '其他错误'
    }
  },
  controlerCan5CommErrorPoint: {
    name: '控制器CAN5通讯错误方向', 
    values: {
      0: '无效',
      1: '发送时发生的错误',
      2: '接收时发生的错误'
    }
  },
  ultrasonicFailureId: {
    name: '超声波故障',
    values: {
      0: '未启用监测故障命令开关',
      1: '无故障',
      2: '超声波雷达故障'
    }
  },
  milimeterWaveFailureId: {
    name: '毫米波雷达故障', 
    values: {
      0: '无故障',
      1: '雷达失效',
      2: '性能降低',
      3: '通信错误',
    }
  },
  screenFailureId: {
    name: '小屏幕故障', 
    values: {
      0: '无故障',
      1: '小屏幕故障'
    }
  },
  t4gFailureId: {
    name: '4G通讯故障', 
    values: {
      0: '无故障', 
      1: '4G通讯故障'
    }
  },
  gpsFailureId: {
    name: 'GPS通讯故障', 
    values: {
      0: '无故障',
      1: 'GPS通讯故障'
    }
  },
  btFailureId: {
    name: '蓝牙故障', 
    values: {
      0: '不支持',
      1: '无故障',
      2: '内部BT模块故障',
      3: '外部BT模块故障'
    }
  },
  imuFailureId: {
    name: 'IMU故障', 
    values: {
      0: '无故障',
      1: '通信异常',
      2: '功能异常'
    }
  },
  carId: {
    name: '车辆ID', 
    values: {}
  },
  sn: { 
    name: 'SN', 
    values: {} 
  },
  reportedTime: {
    name: '上报时间',
    values: {} 
  }
}
