/**
 * 操作adas(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { addAdasRequest } from '@/service/adasEquipmentManage';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const manufacturerData = [
    { label: '博实结', value: 1 },
    { label: '锐驰曼', value: 2 },
    { label: '珠海骏驰', value: 3 },
  ]
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      params.channel = Number(params.channel)
      const res = await addAdasRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加adas设备' : '编辑adas设备'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item
          label="ADAS终端编号"
          name="sn"
          rules={[{ required: true, message: '请输入ADAS终端编号' }]}
        >
          <Input placeholder='请输入ADAS终端编号' />
        </Form.Item>
        <Form.Item
          label="ADAS流量卡号"
          name="sim"
          rules={[{ required: true, message: '请输入ADAS流量卡号' }]}
        >
          <Input placeholder='请输入ADAS流量卡号' />
        </Form.Item>
        <Form.Item
          label="厂家"
          name="manufacturer"
          rules={[
            { required: true, message: '请选择厂家' }
          ]}
        >
          <Select placeholder="请选择厂家" options={manufacturerData} allowClear showArrow showSearch optionFilterProp="label"  />
        </Form.Item>
        <Form.Item
          label="终端号"
          name="terminal"
          rules={[{ required: true, message: '请输入终端号' }]}
        >
          <Input placeholder='请输入终端号' />
        </Form.Item>
        <Form.Item
          label="摄像头路数"
          name="channel"
          rules={[{ required: true, message: '请输入摄像头路数' }]}
        >
          <Input placeholder='请输入摄像头路数' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
    