/**
 * 部标机设备相关常量
 */
// 808协议版本
export const jt808Version = [
  { label: '2011', value: '2011' },
  { label: '2013', value: '2013' },
  { label: '2019', value: '2019' }
];

// 码流类型(视频监控)
export const codeStreamData = [
  { label: '子码流(流畅)', value: 1 },
  { label: '主码流(清晰)', value: 0 }
];

// 码流类型(视频回放)
export const codeStreamPlaybackData = [
  { label: '所有码流', value: 0 },
  { label: '主码流(清晰)', value: 1 },
  { label: '子码流(流畅)', value: 2 }
];

// 编码模式(参数配置使用)
export const encodeTypes = [
  { label: 'CBR(固定码率)', value: 0 },
  { label: 'VBR(可变码率)', value: 1 },
  { label: 'ABR(平均码率)', value: 2 }
];

// 分辨率(参数配置使用)
export const resolutionTypes = [
  { label: 'QCIF', value: 0 },
  { label: 'CIF', value: 1 },
  { label: 'WCIF', value: 2 },
  { label: 'DI', value: 3 },
  { label: 'WDI', value: 4 },
  { label: '720P', value: 5 },
  { label: '1080P', value: 6 }
];

// OSD字幕叠加设置(参数配置使用)
export const captionTypes = [
  { label: '日期和时间', value: 0 },
  { label: '车牌号码', value: 1 },
  { label: '逻辑通道号', value: 2 },
  { label: '经纬度', value: 3 },
  { label: '行驶记录速度', value: 4 },
  { label: '卫星定位速度', value: 5 },
  { label: '连续驾驶时间', value: 6 }
];

// 部标机设备参数配置的表单项
export const jtDeviceConfigParams = [
  {
    panelTitle: '音视频参数',
    panelKey: 'params',
    paramId: 117,
    formItems: [
      // [
      //   {
      //     label: '是否启用音频输出',
      //     name: 'enableAudio',
      //     type: 'switch',
      //     getValueFromEvent: formatSwitchValue
      //   },
      //   {
      //     label: 'OSD字幕叠加设置',
      //     name: 'osd',
      //     type: 'select',
      //     optionsData: captionTypes,
      //     mode: 'multiple'
      //   },
      // ],
      [
        {
          label: '实时流编码模式',
          name: 'liveCodeMode',
          type: 'select',
          optionsData: encodeTypes
        },
        {
          label: '实时流分辨率',
          name: 'liveResolution',
          type: 'select',
          optionsData: resolutionTypes
        },
        {
          label: '实时流关键帧间隔',
          name: 'liveKeyframeInterval',
          type: 'inputNumber',
          min: 1,
          max: 1000,
          addonAfter: '帧'
        },
        {
          label: '实时流目标帧率',
          name: 'liveFrameRate',
          type: 'inputNumber',
          min: 1,
          max: 120,
          addonAfter: '帧/s'
        },
        {
          label: '实时流目标码率',
          name: 'liveBitrate',
          type: 'inputNumber',
          addonAfter: 'kbps'
        }
      ],
      [
        {
          label: '存储流编码模式',
          name: 'videoCodeMode',
          type: 'select',
          optionsData: encodeTypes
        },
        {
          label: '存储流分辨率',
          name: 'videoResolution',
          type: 'select',
          optionsData: resolutionTypes
        },
        {
          label: '存储流关键帧间隔',
          name: 'videoKeyframeInterval',
          type: 'inputNumber',
          min: 1,
          max: 1000,
          addonAfter: '帧'
        },
        {
          label: '存储流目标帧率',
          name: 'videoFrameRate',
          type: 'inputNumber',
          min: 1,
          max: 120,
          addonAfter: '帧/s'
        },
        {
          label: '存储流目标码率',
          name: 'videoBitrate',
          type: 'inputNumber',
          addonAfter: 'kbps'
        }
      ]
    ]
  }
  // {
  //   panelTitle: '特殊报警录像参数',
  //   panelKey: 'alarmConfig',
  //   formItems: [
  //     [
  //       {
  //         label: '存储阈值',
  //         name: 'a-1',
  //         type: 'inputNumber',
  //         min: 1,
  //         max: 99,
  //         addonAfter: '%'
  //       },
  //       {
  //         label: '持续时间',
  //         name: 'b-1',
  //         type: 'inputNumber',
  //         addonAfter: '分钟'
  //       },
  //       {
  //         label: '标识起始时间',
  //         name: 'c-1',
  //         type: 'inputNumber',
  //         addonAfter: '分钟'
  //       }
  //     ]
  //   ]
  // }
];
