/**
 * 表格(支持列伸缩，列过滤，表格展示在视口中)
 */
import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import { Table, TableProps, Dropdown, Checkbox, Button, Tooltip } from 'antd';
import { RedoOutlined, FilterOutlined } from '@ant-design/icons';
import { Resizable } from 'react-resizable';
import { omit } from 'lodash';
import { useThrottle } from '@/utils/ownHooks';
import styles from './index.module.scss';

interface CommonTableProps extends TableProps<any> {
  columnResize?: boolean; // 表格列是否可以伸缩
  columnFilter?: boolean; // 表格列是否可以过滤
  scrollLoadMore?: boolean; // 是否支持滚动加载更多
  scrollLoadMoreFunc?: Function | null; // 滚动加载更多的方法
  scrollToTop?: boolean; // 数据变化时滚动到最上方(用于滚动加载更多的形式)
  tableTitle?: any; // 表格标题
  reloadFunc?: Function; // 刷新的方法
  isNestTable?: boolean; // 是否为嵌套内的表格
  changeFilter?: Function;
  filterTitleButton?: any; // 过滤行文字的显示
  filterTitleButtonParams?: any;  // 过滤行的数据
  clickFilterTitleButton?: Function;  // 点击过滤行按钮的回调
}

// 表格的可伸缩列
const ResizableTableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className={styles.reactResizableHandle}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const CommonTable = (props: CommonTableProps) => {
  const tableRef = useRef(null);
  const [columnFilterData, setColumnFilterData] = useState([]); // 表格列筛选的数据
  const [checkColumn, setCheckColumn] = useState<any>([]); // 表格选中的列
  const [open, setOpen] = useState<boolean>(false); // 表格列筛选浮层的显示
  const [columnsData, setColumnsData] = useState<any>(props.columns); // 表格列的数据

  const {
    columnFilter = true,
    columnResize = true,
    scrollLoadMore = false,
    scrollLoadMoreFunc,
    scrollToTop = false,
    tableTitle,
    reloadFunc,
    isNestTable = false,
    changeFilter,
    filterTitleButton,
    filterTitleButtonParams,
    clickFilterTitleButton,
  } = props;
  // 处理表格的props
  let tableOwnProps = omit(props, [
    'columnResize',
    'columnFilter',
    'scrollLoadMore',
    'scrollLoadMoreFunc',
    'scrollToTop',
    'tableTitle',
    'reloadFunc',
    'isNestTable',
    'changeFilter',
    'filterTitleButton',
    'filterTitleButtonParams',
    'clickFilterTitleButton',
  ]);

  // 处理表格翻页器的参数
  if (tableOwnProps.pagination) {
    tableOwnProps = {
      ...tableOwnProps,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: {
          goButton: (
            <Button style={{ marginLeft: '12px' }} size="small">
              确定
            </Button>
          )
        },
        showTotal: total => `共 ${total} 条`,
        ...tableOwnProps.pagination
      }
    };
  }

  // 处理表格列是否选中显示(防止重新render时传递的表格列参数影响已经操作过的列显示)
  const operateColumnCheck = (currentColumn: any) => {
    // 获取当前已经取消勾选不显示的列
    const hasFilteredKeys: any = []; // 当前已经取消勾选不显示的列
    columnFilterData.forEach((item: any) => {
      if (!checkColumn.includes(item.value)) {
        hasFilteredKeys.push(item.value);
      }
    });
    if (currentColumn.defaultHide) {
      // 默认不显示时，如果已经被勾选为显示则显示否则不显示
      if (checkColumn.includes(currentColumn.dataIndex)) {
        return true;
      } else {
        return false;
      }
    } else if (hasFilteredKeys.includes(currentColumn.dataIndex)) {
      // 默认显示时，如果已经被取消勾选过则不显示
      return false;
    } else {
      return true;
    }
  };

  // 监听表格body的滚动条是否需要滚动到顶部
  useEffect(() => {
    if (scrollToTop) {
      const tableDom: any = tableRef.current;
      const tBody = tableDom.getElementsByClassName('ant-table-body')[0];
      tBody.scrollTo(0, 0);
    }
  }, [scrollToTop]);

  // 处理表格筛选列的数据
  useEffect(() => {
    const columnCheckBoxData: any = []; // 列筛选的数据
    const columnCheckedData: any = []; // 列筛选选中的key
    const showColumnData: any = []; // 要展示的列数据
    const columnsDataWidth: any = {}; // 当前各个列的宽度
    (columnsData || []).forEach((item: any) => {
      columnsDataWidth[item.dataIndex] = item.width;
    });
    (props.columns || []).forEach((item: any) => {
      columnCheckBoxData.push({
        label: item.title,
        value: item.dataIndex
      });
      // 只勾选/显示没有隐藏的列
      if (operateColumnCheck(item)) {
        columnCheckedData.push(item.dataIndex);
        showColumnData.push({
          ...item,
          width: columnsDataWidth[item.dataIndex] || item.width
        });
      }
    });
    setColumnFilterData(columnCheckBoxData);
    setCheckColumn(columnCheckedData);
    setColumnsData(showColumnData);
  }, [props.columns]);

  // 监听表格内部滚动加载更多
  useEffect(() => {
    if (scrollLoadMore) {
      const tableDom: any = tableRef.current;
      let tBody: any = null;
      if (tableDom) {
        tBody = tableDom.getElementsByClassName('ant-table-body')[0];
        if (tBody) {
          tBody.addEventListener('scroll', throttleOnTbodyScroll);
          return () => {
            tBody.removeEventListener('scroll', throttleOnTbodyScroll);
          };
        }
      }
    }
  }, [scrollLoadMore]);

  // 表格内容区域滚动
  const onTbodyScroll = (e: any) => {
    if (
      e.target?.scrollTop + e.target?.clientHeight >=
      e.target?.scrollHeight - 40
    ) {
      scrollLoadMoreFunc && scrollLoadMoreFunc();
    }
  };

  // 节流的表格内容区域滚动
  const throttleOnTbodyScroll = useThrottle(onTbodyScroll);

  // 表格列过滤
  const onColumnFilter = (checkedValues: any) => {
    setCheckColumn(checkedValues);
    const newColumns = (props.columns || []).filter((item: any) =>
      checkedValues.includes(item.dataIndex)
    );
    changeFilter && changeFilter(checkedValues);
    setColumnsData(newColumns);
  };

  // 表格列伸缩
  const handleResize =
    (index: number) =>
    (_: any, { size }: { size: any }) => {
      const newColumns = [...columnsData];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width
      };
      setColumnsData(newColumns);
    };

  // 显示的表格内容
  const showColumns = columnsData.map((col: any, index: number) => ({
    ...col,
    onHeaderCell: (column: any) => ({
      width: column.width,
      onResize: handleResize(index)
    })
  }));

  const scrollProps = props.scroll
    ? { ...props.scroll, x: '100%' }
    : { y: '100%', x: '100%' };
  
  // 点击过滤行按钮的方法
  const clickTitle = (value: any) => {
    if (value == 0) {
      clickFilterTitleButton ? clickFilterTitleButton([1,2,3,4,5,6,7]) : null
    }else if (value == 1) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    } else if (value == 2) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    } else if (value == 3) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    }else if (value == 4) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    } else if (value == 5) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    } else if (value == 6) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    } else if (value == 7) {
      clickFilterTitleButton ? clickFilterTitleButton(value) : null
    }
  }

  return (
    <div className={isNestTable ? undefined : styles.commonTableBox}>
      {(columnFilter || tableTitle || reloadFunc || filterTitleButton) && (
        <div className={styles.filterColumn}>
          {filterTitleButton && <div>
            <Button type="link" onClick={() => clickTitle(0)}>全部:{Number(filterTitleButtonParams.fcw) + Number(filterTitleButtonParams.aeb)+ Number(filterTitleButtonParams.sts)+ Number(filterTitleButtonParams.ldw)+Number(filterTitleButtonParams.hmw)+Number(filterTitleButtonParams.radar)+Number(filterTitleButtonParams.rtb)}</Button>
            <Button type="link" onClick={() => clickTitle(1)}>FCW报警:{filterTitleButtonParams.fcw == 0 ? 0 : filterTitleButtonParams.fcw}</Button>
            <Button type="link" onClick={() => clickTitle(2)}>AEB制动:{filterTitleButtonParams.aeb == 0 ? 0 : filterTitleButtonParams.aeb}</Button>
            <Button type="link" onClick={() => clickTitle(3)}>起步阻止:{filterTitleButtonParams.sts == 0 ? 0 : filterTitleButtonParams.sts}</Button>
            <Button type="link" onClick={() => clickTitle(4)}>LDW预警:{filterTitleButtonParams.ldw == 0 ? 0 : filterTitleButtonParams.ldw}</Button>
            <Button type="link" onClick={() => clickTitle(5)}>HMW预警:{filterTitleButtonParams.hmw == 0? 0 : filterTitleButtonParams.hmw}</Button>
            <Button type="link" onClick={() => clickTitle(6)}>角雷达报警:{filterTitleButtonParams.rander == 0 ? 0 : filterTitleButtonParams.radar}</Button>
            <Button type="link" onClick={() => clickTitle(7)}>右转制动:{filterTitleButtonParams.rtb == 0 ? 0 : filterTitleButtonParams.rtb}</Button>
          </div>}
          <div>{tableTitle || ''}</div>
          <div className={styles.rightAction}>
            {reloadFunc && (
              <Tooltip title="刷新">
                <RedoOutlined onClick={() => reloadFunc()} />
              </Tooltip>
            )}
            {columnFilter && (
              <Dropdown
                dropdownRender={() => (
                  <div className={styles.filterColumnCheckBox}>
                    <Checkbox.Group
                      options={columnFilterData}
                      value={checkColumn}
                      onChange={onColumnFilter}
                    />
                  </div>
                )}
                trigger={['click']}
                open={open}
                onOpenChange={(flag: boolean) => setOpen(flag)}
              >
                <Tooltip title="列设置">
                  <FilterOutlined />
                </Tooltip>
              </Dropdown>
            )}
          </div>
        </div>
      )}
      <Table
        {...tableOwnProps}
        columns={showColumns}
        components={
          columnResize ? { header: { cell: ResizableTableTitle } } : {}
        }
        scroll={scrollProps}
        ref={tableRef}
        className={isNestTable ? undefined : styles.tableWrapper}
      />
    </div>
  );
};

export default CommonTable;
