/**
 * 车辆评分相关请求
 */
import request from "@/utils/request";

export interface carScoreRequestParams {
    page: number; 
    limit: number;
}

export const carScoreRequestPath = '/api/v2/car/evaluate';

// 获取数据
export const carScoreRequest = (params: carScoreRequestParams) => {
    return request.get(carScoreRequestPath, {
        params,
    })
}

// 导出数据
export const carScoreExportPath = '/api/v2/car/evaluate/export';