/**
 * 保司相关请求
 */

//查询保司信息
import request from "@/utils/request";
export const insureListRequest = (params:any) => {
    return request.get("/api/v2/insurer/v1/list", {
      params,
    });
  };

// 添加数据
export const upsertInsurer = (data: any) => {
    return request.post('/api/v2/insurer/upsert', data)
}

// 删除
export const DeleteInsurer = (id: number) => {
    return request.delete(`/api/v2/insurer/${id}`)
}