
/**
 * 操作记录
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Select } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import JsonModal from '@/components/jsonModal';
import { useTable } from '@/utils/useTable';
import { operateLogRequest, operateLogRequestPath, operateLogEnumRequest } from '@/service/operateLog';
import { userListRequest, UserListRequestParams } from '@/service/userManage';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const OperateLog = () => {
  const [searchParams, setSearchParams] = useState({
    startTime: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')}`,
    endTime: `${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
  }); // 搜索相关的参数
  const [userData, setUserData] = useState([]); // 用户数据
  const [operateLogEnum, setOperateLogEnum] = useState([]); // 操作记录类型枚举
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: operateLogRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  // 获取用户数据
  const getUserData = async (params: UserListRequestParams) => {
    const res = await userListRequest(params); 
    let userList = res.data?.data || [];
    userList = userList.map((item: any) => ({
      label: `${item.nickname} (${item.mobile})`,
      value: item.id,
    }));
    setUserData(userList);
  }

  // 获取操作记录类型枚举
  const getOperateLogEnum = async () => {
    const res = await operateLogEnumRequest(); 
    const resData = res.data?.data || {};
    const enumData: any = [];
    Object.keys(resData).forEach((item: string) => {
      enumData.push({
        label: resData[item],
        value: item,
      })
    })
    setOperateLogEnum(enumData);
  }

  useEffect(() => {
    getUserData({ page: 1, limit: 500 });
    getOperateLogEnum();
  }, []);

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    delete newParams.time;
    newParams.startTime = dayjs(params.time[0]).format('YYYY-MM-DD HH:mm:ss');
    newParams.endTime = dayjs(params.time[1]).format('YYYY-MM-DD HH:mm:ss');
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 渲染操作类型列
  const renderOptTypeColumn = (value: number, data: any) => {
    return `${value} ${data.actionStr ? `(${data.actionStr})` : ''}`
  }

  // 渲染访问设备列
  const renderAccessDeviceColumn = (value: boolean, data: any) => {
    const deviceType = value ? 'Mobile' : 'PC';
    const devicePlatform = data.platform ? `（${data.platform}）` : '';
    return `${deviceType}${devicePlatform}`
  }

  // 表格列显示
  const columns = [
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      width: 130,
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '操作人',
      dataIndex: 'nickname',
      width: 130,
    },
    {
      title: '操作对象',
      dataIndex: 'optTypeStr',
      width: 130,
      render: (value: number, item: any) => renderOptTypeColumn(value, item),
    },
    {
      title: '接口url',
      dataIndex: 'url',
      width: 130,
    },
    {
      title: '访问设备',
      dataIndex: 'isMobile',
      width: 140,
      render: (value: boolean, item: any) => renderAccessDeviceColumn(value, item),
    },
    {
      title: '浏览器',
      dataIndex: 'brower',
      width: 130,
    },
    {
      title: '操作内容',
      dataIndex: 'optDetail',
      width: 120,
      render: (value: any, item: any) => value ? 
        <div className={styles.tableOperateColumn}>
          <Button type='link' onClick={() => setOperateInfo({ type: 'json', data: value })}>详细信息</Button>
        </div> :
        '-',
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.startTime), dayjs(searchParams.endTime)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} showTime />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="操作对象" name="optType">
              <Select placeholder='请选择操作对象' options={operateLogEnum} showSearch optionFilterProp="label" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="操作用户" name="optUserId">
              <Select placeholder='请选择操作用户' options={userData} showSearch optionFilterProp="label" allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={operateLogRequestPath}
                  fileName='操作记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/operate-log/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 操作内容的弹窗 */}
      {operateInfo.type === 'json' && <JsonModal
        data={operateInfo.data}
        closeModal={() => setOperateInfo({})} 
      />}
    </Card>
  )
}

export default OperateLog;
  