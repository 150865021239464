/**
 * 操作设备(添加或编辑)
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import { deviceAddRequest,} from '@/service/equipmentManage';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const deviceTypeData = [
    { label: '一代设备', value: 1 },
    { label: '二代设备', value: 2 },
  ]
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data)
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await deviceAddRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      } 
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加设备' : '编辑设备'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        <Form.Item
          label="设备sn"
          name="sn"
          rules={[{ required: true, message: '请输入设备sn'}]}
        >
          <Input placeholder='请输入设备sn' />
        </Form.Item>
        <Form.Item
          label="设备关联sim卡编号"
          name="sim"
          rules={[{ required: true, message: '请输入sim' }]}
        >
          <Input placeholder='请输入关联sim卡编号' />
        </Form.Item>
        <Form.Item
          label="设备类型"
          name="deviceType"
          rules={[
            { required: true, message: '请选择设备类型' }
          ]}
        >
          <Select placeholder="请选择设备类型" options={deviceTypeData} allowClear showArrow showSearch optionFilterProp="label"  />
        </Form.Item>
        <Form.Item
          label="相机sn"
          name="cameraSn"
          rules={[{ required: true, message: '请输入相机sn' }]}
        >
          <Input placeholder='请输入相机sn' />
        </Form.Item>

      </Form>
    </Modal>
  )
}

export default OperateModal;
    