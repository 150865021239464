/**
 * 传感器方案-供应商选择器
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { omit } from 'lodash';
import { sensorSupplierListRequest } from '@/service/sensorSupplierManage';
import { actuatorName } from '@/constants/sensorScheme';

interface SensorSupplierSelectProps extends SelectProps {
  value?: string; // 选择的供应商
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField?: string; // 作为选择器value的字段
  showDeviceType?: boolean; // 选择器的label是否显示设备类型
}
const SensorSupplierSelect = (props: SensorSupplierSelectProps) => {
  const { value, onChange, valueField, showDeviceType = true } = props;
  const [sensorSupplierList, setSensorSupplierList] = useState<any>([]); // 供应商列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>(); // 选择的供应商

  // 获取供应商列表数据
  const getSensorSupplierList = async () => {
    const res = await sensorSupplierListRequest({
      page: 1,
      limit: 999
    });
    const data = (res.data?.data || []).map((item: any) => ({
      label: showDeviceType
        ? `${item.componentTypeName}${
            item.componentTypeName === actuatorName ? `-${item.typeId}` : ''
          } (${item.name})`
        : item.name,
      value: valueField ? item[valueField] : item.id
    }));
    setSensorSupplierList(data);
  };

  useEffect(() => {
    getSensorSupplierList();
  }, []);

  // 监听选择的供应商进行赋值
  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const selectOwnProps = omit(props, ['valueField', 'showDeviceType']);
  return (
    <Select
      {...selectOwnProps}
      optionFilterProp="label"
      allowClear
      showSearch
      options={sensorSupplierList}
      onChange={onChange}
      value={selectValue}
      placeholder="请选择"
    />
  );
};

export default SensorSupplierSelect;
