/**
 * 车辆诊断-承保记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { underwritingListRequest ,deleteUnderwritingRequest} from '@/service/underwritingRecord'
import OperateModal from '../../../underwritingRecord/components/operateModal';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import UnderWritingItem from '../underWritingItem';
import styles from './index.module.scss';

interface UnderwritingRecordProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭承保记录弹层的方法
}
const UnderwritingRecoed = (props: UnderwritingRecordProps) => {
  const { carInfo, carInfo: { plate_number, id }, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 承保记录数据
  const [loading, setLoading] = useState<boolean>(false) // 承保记录数据是否在请求中
  const [addUnderwriting, setAddUnderwriting] = useState<any>({}); // 承保记录操作相关的信息

  // 获取承保记录
  const getUnderwritingRecord = async (newPage: number) => {
    try {
      setLoading(true);
      const res = await underwritingListRequest({ limit: 40, page: 1, plateNumber: plate_number });
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUnderwritingRecord(1);
  }, []);

  // 加载更多
  const loadMore = () => {
    getUnderwritingRecord(page + 1);
  }

  // 删除承保记录
  const deleteRecord = async (id: number) => {
    const res = await deleteUnderwritingRequest(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getUnderwritingRecord(1);
    }
  }

  // 车辆诊断新增承保记录
  const onUnderwritingAdd = async () => {
    setAddUnderwriting({ type: 'add', data: {plateNumber:carInfo.plate_number,frameNumber:carInfo.frame_number}});
  }

  const getData = () => {
    getUnderwritingRecord(1);
  }

  return (
    <Drawer
      title={`${plate_number}承保记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <PermissionButton
        type='primary'
        style={{ marginBottom: '16px' }}
        onClick={() => onUnderwritingAdd()}
        operateType='/car-admin/car-diagnose/underwriting-add'
      >
        新增承保记录
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any) => {
            return (
              <Timeline.Item>
                <div>
                  {item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}
                  <Popconfirm
                    title="此操作将删除该条承保记录, 是否继续?"
                    onConfirm={() => deleteRecord(item.id)}
                  >
                    <PermissionButton
                      operateType='/car-admin/car-diagnosis/underwriting-delete'
                      type="link"
                      style={{ padding: '0 0 0 6px', height: '26px' }}
                    >
                      <DeleteOutlined style={{ color: 'red' }} />
                    </PermissionButton>
                  </Popconfirm>
                </div>
                <UnderWritingItem column={{ xs: 1, sm: 2 }} data={item} type="Contract" />
              </Timeline.Item>
            )
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无承保记录</Divider>
        }
      </LoadingWrapper>
      {['add'].includes(addUnderwriting?.type) &&
        <OperateModal
          type={addUnderwriting.type}
          data={addUnderwriting.data}
          closeModal={() => setAddUnderwriting({})}
          reloadData={getData}
        />
      }
    </Drawer>
  )
}

export default UnderwritingRecoed;