/**
 * 离线天数筛选
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, InputNumber } from 'antd';
import { offlineDayRequest, offlineDayRequestPath } from '@/service/offlineDay';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import OperationGroupSelect from '@/components/operationGroupSelect';
import { useTable } from '@/utils/useTable';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { snColumnConfig, cateGroupColumnConfig, frameNumberColumnConfig, carBrandColumnConfig, carModelColumnConfig } from '@/constants/tableColumn';
import styles from '@/styles/pageContent.module.scss';
import dayjs from 'dayjs';
import PermissionButton from '@/components/permissionButton';

const OfflineDay = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    day: 1,
    cateId: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: offlineDayRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const params = form.getFieldsValue();
    params.export = true;
    return params;
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
    },
    snColumnConfig,
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
    },
    cateGroupColumnConfig,
    frameNumberColumnConfig,
    carBrandColumnConfig,
    carModelColumnConfig,
    {
      title: '是否在线',
      dataIndex: 'online',
      width: 100,
    },
    {
      title: '最后上报时间',
      dataIndex: 'online_at',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 260,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton 
              operateType='/car-admin/offline-day/contact'
              type="link"
              onClick={() => setOperateInfo({ type: 'contact', data: item })}
            >
              联系方式
            </PermissionButton>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="离线大于(最后一次上报gps数据距今的天数)"
              name="day"
              initialValue={searchParams.day}
              required
              rules={[{ required: true, message: '请指定离线天数' }]}
            >
              <InputNumber placeholder='离线天数大于x天' style={{ width: '100%' }} addonAfter="天" />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/offline-day/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={offlineDayRequestPath}
                  fileName={`离线天数大于${searchParams.day}`}
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/offline-day/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default OfflineDay;
  