/**
 * 客服处理统计
 */
import { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Select } from 'antd';
import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import styles from '@/styles/pageContent.module.scss';
import moment from 'moment';
import { workLoadRequest, workStateRequest, dealTimeRequest, totalTaskRequest, customerTakStateRequest } from '@/service/serviceCount'
echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, CanvasRenderer, UniversalTransition]);
const { RangePicker } = DatePicker;

//定义下拉框数据
const CustomerTaskStatistic = () => {
  const timeScope = [
    { label: '年', value: 'YEAR' },
    { label: '月', value: 'MONTH' },
    { label: '周', value: 'WEEK' },
    { label: '日', value: 'DAY' },
  ]
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // 数据是否在请求中
  const [showYear, setShowYear] = useState(false);
  const [showMonth, setShowMonth] = useState(false);
  const [showWeek, setShowWeek] = useState(false);
  const [showDay, setShowDay] = useState(true);
  const [searchParams] = useState({
    type: `DAY`,
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
  }); // 搜索相关的参数

  useEffect(() => {
    getWorkLoadData(searchParams);
    getWorkStateData(searchParams)
    getDealTimeData(searchParams)
    getCustomerTaskStaticData(searchParams)
    getCustomerTaskStatisticData(searchParams)
  }, []);

  // 获取客服任务统计--客服处理任务总量分布
  const getCustomerTaskStaticData = async (params: any) => {
    setLoading(true);
    const taskLoadData = await totalTaskRequest({
      ...params
    })
    const chartData = taskLoadData.data?.data || [];
    const xData: any = [];
    const yData: any = [];

    chartData.forEach((item: any, index: number) => {
      // console.log('item11', item, index);
      xData.push(item.name)
      yData.push(item.data)
    });
    const chartConfig = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: '切换为折线图', bar: '切换为柱状图' },
          },
          saveAsImage: {
            title: '保存为图'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: xData,
        name:'客服人员'
      },
      yAxis: { type: 'value', name: '数量' },
      series: [
        {
          type: 'line',
          data: yData
        }
      ]
    };
    const totalTaskChart: any = echarts.init(document.getElementById('totalTaskChart') as HTMLElement);
    totalTaskChart.setOption(chartConfig, true);
    setLoading(false);
  }

  // 获取客服任务统计--客服各状态任务数量
  const getCustomerTaskStatisticData = async (params: any) => {
    setLoading(true);
    const taskLoadData = await customerTakStateRequest({
      ...params
    })
    const chartData = taskLoadData.data?.data || [];
    const xData: any = [];
    const daichuli: any = [];
    const xiezhucheliang: any = [];
    const yichuangjiangongdan: any = [];
    const yipaigongdan: any = [];
    const yiwancheng: any = [];
    const chuliwancheng: any = [];
    const yituihui: any = [];
    const yiguanbi: any = [];


    chartData.forEach((item: any, index: number) => {
      // console.log('ITEM', item, index);
      xData.push(item.name)
      item.data.map((item: any) => {
        if (item.date == '处理中-待处理') {
          daichuli.push(item.num)
        }
        if (item.date == '协助车辆') {
          xiezhucheliang.push(item.num)
        }
        if (item.date == '处理中-已创建工单') {
          yichuangjiangongdan.push(item.num)
        }
        if (item.date == '处理中-已派工单') {
          yipaigongdan.push(item.num)
        }
        if (item.date == '处理中-已完成现场处理') {
          yiwancheng.push(item.num)
        }
        if (item.date == '处理完成') {
          chuliwancheng.push(item.num)
        }
        if (item.date == '已退回') {
          yituihui.push(item.num)
        }
        if (item.date == '已关闭') {
          yiguanbi.push(item.num)
        }
      })


    });
    const chartConfig = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: '切换为折线图', bar: '切换为柱状图' },
          },
          saveAsImage: {
            title: '保存为图'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: xData,
        name:'客服人员'

      },
      yAxis: { type: 'value', name: '数量' },
      series: [
        {
          name: '处理中-待处理',
          type: 'bar',
          data: daichuli
        },
        {
          name: '协助车辆',
          type: 'bar',
          data: xiezhucheliang
        },
        {
          name: '处理中-已创建工单',
          type: 'bar',
          data: yichuangjiangongdan
        },
        {
          name: '处理中-已派工单',
          type: 'bar',
          data: yipaigongdan
        },
        {
          name: '处理中-已完成现场处理',
          type: 'bar',
          data: yiwancheng
        },
        {
          name: '处理完成',
          type: 'bar',
          data: chuliwancheng
        },
        {
          name: '已退回',
          type: 'bar',
          data: yituihui
        },
        {
          name: '已关闭',
          type: 'bar',
          data: yiguanbi
        }
      ]
    };
    const customerTakStateChart: any = echarts.init(document.getElementById('customerTakStateChart') as HTMLElement);
    customerTakStateChart.setOption(chartConfig, true);
    setLoading(false);
  }

  // 获取客服任务统计--任务量
  const getWorkLoadData = async (params: any) => {
    setLoading(true);
    const taskLoadData = await workLoadRequest({
      ...params
    })
    const chartData = taskLoadData.data?.data || [];
    const xData: any = [];
    const lizhenzhu: any = [];
    const jinyao: any = [];
    const zhoujiatong: any = [];
    chartData.forEach((item: any, index: number) => {
      // console.log('item11', item, index);
      if (index === 0) {
        item.data.map((item: any) => {
          let date = item.date
          xData.push(date)
        })
      }
      if (item.name == '李珍珠') {
        item.data.map((item: any) => {
          lizhenzhu.push(item.num)
        })
      }
      if (item.name == '金垚') {
        item.data.map((item: any) => {
          jinyao.push(item.num)
        })
      }
      if (item.name == '周佳彤') {
        item.data.map((item: any) => {
          zhoujiatong.push(item.num)
        })
      }
    });
    const chartConfig = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: '切换为折线图', bar: '切换为柱状图' },
          },
          saveAsImage: {
            title: '保存为图'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: xData,
        name:'日期'

      },
      yAxis: { type: 'value', name: '数量' },
      series: [
        {
          name: '李珍珠',
          type: 'line',
          data: lizhenzhu
        },
        {
          name: '金垚',
          type: 'line',
          data: jinyao
        },
        {
          name: '周佳彤',
          type: 'line',
          data: zhoujiatong
        }
      ]
    };
    const orderStatisticsChart: any = echarts.init(document.getElementById('orderStatisticsChart') as HTMLElement);
    orderStatisticsChart.setOption(chartConfig, true);
    setLoading(false);
  }

  // 获取客服任务统计--业务状态
  const getWorkStateData = async (params: any) => {
    setLoading(true);
    const taskLoadData = await workStateRequest({
      ...params
    })
    const chartData = taskLoadData.data?.data || [];
    const xData: any = [];
    const weichuli: any = [];
    const daihujiao: any = [];
    const yihujiao: any = [];
    const daichuli: any = [];
    const xiezhucheliang: any = [];
    const yichuangjiangongdan: any = [];
    const yipaigongdan: any = [];
    const yiwancheng: any = [];
    const chuliwancheng: any = [];
    const yituihui: any = [];
    const yiguanbi: any = [];

    chartData.forEach((item: any, index: number) => {
      //console.log('item', item, index);
      if (index === 0) {
        item.data.map((item: any) => {
          xData.push(item.date)
        })
      }
      if (item.name == '未处理') {
        item.data.map((item: any) => {
          weichuli.push(item.num)
        })
      }
      if (item.name == '处理中-待呼叫') {
        item.data.map((item: any) => {
          daihujiao.push(item.num)
        })
      }
      if (item.name == '处理中-已呼叫') {
        item.data.map((item: any) => {
          yihujiao.push(item.num)
        })
      }
      if (item.name == '处理中-待处理') {
        item.data.map((item: any) => {
          daichuli.push(item.num)
        })
      }
      if (item.name == '协助车辆') {
        item.data.map((item: any) => {
          xiezhucheliang.push(item.num)
        })
      }
      if (item.name == '处理中-已创建工单') {
        item.data.map((item: any) => {
          yichuangjiangongdan.push(item.num)
        })
      }
      if (item.name == '处理中-已派工单') {
        item.data.map((item: any) => {
          yipaigongdan.push(item.num)
        })
      }
      if (item.name == '处理中-已完成现场处理') {
        item.data.map((item: any) => {
          yiwancheng.push(item.num)
        })
      }
      if (item.name == '处理完成') {
        item.data.map((item: any) => {
          chuliwancheng.push(item.num)
        })
      }
      if (item.name == '已退回') {
        item.data.map((item: any) => {
          yituihui.push(item.num)
        })
      }
      if (item.name == '已关闭') {
        item.data.map((item: any) => {
          yiguanbi.push(item.num)
        })
      }
    });
    const chartConfig = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: '切换为折线图', bar: '切换为柱状图' },
          },
          saveAsImage: {
            title: '保存为图'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: '25%',
      },
      legend: {},
      xAxis: {
        type: 'category',
        data: xData,
        name:'日期'
      },
      yAxis: { type: 'value', name: '数量' },
      series: [
        {
          name: '未处理',
          type: 'bar',
          data: weichuli
        },
        {
          name: '处理中-待呼叫',
          type: 'bar',
          data: daihujiao
        },
        {
          name: '处理中-已呼叫',
          type: 'bar',
          data: yihujiao
        },
        {
          name: '处理中-待处理',
          type: 'bar',
          data: daichuli
        },
        {
          name: '协助车辆',
          type: 'bar',
          data: xiezhucheliang
        },
        {
          name: '处理中-已创建工单',
          type: 'bar',
          data: yichuangjiangongdan
        },
        {
          name: '处理中-已派工单',
          type: 'bar',
          data: yipaigongdan
        },
        {
          name: '处理中-已完成现场处理',
          type: 'bar',
          data: yiwancheng
        },
        {
          name: '处理完成',
          type: 'bar',
          data: chuliwancheng
        },
        {
          name: '已退回',
          type: 'bar',
          data: yituihui
        },
        {
          name: '已关闭',
          type: 'bar',
          data: yiguanbi
        }
      ]
    };
    const serviceStatisticsChart: any = echarts.init(document.getElementById('serviceStatisticsChart') as HTMLElement);
    serviceStatisticsChart.setOption(chartConfig, true);
    setLoading(false);
  }

  // 获取客服任务统计--平均任务处理时长 
  const getDealTimeData = async (params: any) => {
    setLoading(true);
    const avTime = await dealTimeRequest({
      ...params
    })
    const chartData = avTime.data?.data || [];
    const xData: any = [];
    const lizhenzhu: any = [];
    const jinyao: any = [];
    const zhoujiatong: any = [];
    chartData.forEach((item: any, index: number) => {
      //console.log('item', item, index);
      if (index === 0) {
        item.data.map((item: any) => {
          xData.push(item.date)
        })
      }
      if (item.name == '李珍珠') {
        item.data.map((item: any) => {
          lizhenzhu.push(item.num)
        })
      }
      if (item.name == '金垚') {
        item.data.map((item: any) => {
          jinyao.push(item.num)
        })
      }
      if (item.name == '周佳彤') {
        item.data.map((item: any) => {
          zhoujiatong.push(item.num)
        })
      }
    });
    const chartConfig = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: '切换为折线图', bar: '切换为柱状图' },
          },
          saveAsImage: {
            title: '保存为图'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: xData,
        name:'日期'

      },
      yAxis: { type: 'value', name: '分钟' },
      series: [
        {
          name: '李珍珠',
          type: 'line',
          data: lizhenzhu
        },
        {
          name: '金垚',
          type: 'line',
          data: jinyao
        },
        {
          name: '周佳彤',
          type: 'line',
          data: zhoujiatong
        }
      ]
    };
    const serviceTimeChart: any = echarts.init(document.getElementById('serviceTimeChart') as HTMLElement);
    serviceTimeChart.setOption(chartConfig, true);
    setLoading(false);
  }

  // 搜索以及处理参数
  const onSearch = async (values: any) => {
    //console.log('values',values);
    const newSearchParams = { ...values };
    if(newSearchParams.type == 'MONTH'){
      newSearchParams.beginDate = dayjs(values.time[0]).startOf('month').format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(values.time[1]).endOf('month').format('YYYY-MM-DD');
    }else if(newSearchParams.type == 'YEAR'){
      newSearchParams.beginDate = dayjs(values.time[0]).startOf('year').format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(values.time[1]).endOf('year').format('YYYY-MM-DD');
    }else{
      newSearchParams.beginDate = dayjs(values.time[0]).format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(values.time[1]).format('YYYY-MM-DD');
    }
    
    delete newSearchParams.time
    getWorkLoadData(newSearchParams)
    getWorkStateData(newSearchParams)
    getDealTimeData(newSearchParams)
    getCustomerTaskStaticData(newSearchParams)
    getCustomerTaskStatisticData(newSearchParams)

  };

  // 周期
  const handleChange = (value: any) => {
    if (value === 'YEAR') {
      setShowYear(true);
      setShowMonth(false);
      setShowWeek(false)
      setShowDay(false);
    } else if (value === 'MONTH') {
      setShowYear(false);
      setShowMonth(true);
      setShowWeek(false)
      setShowDay(false);
    } else if (value === 'WEEK') {
      setShowYear(false);
      setShowMonth(false);
      setShowWeek(true)
      setShowDay(false)
    } else if (value === 'DAY') {
      setShowYear(false);
      setShowMonth(false);
      setShowWeek(false)
      setShowDay(true);
    } else {
      setShowYear(false);
      setShowMonth(false);
      setShowDay(false);
      setShowDay(false)
    }
  };

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item
              label="周期"
              name="type"
              initialValue={'DAY'}
              rules={[{ required: true, message: "请选择周期" }]}>
              <Select options={timeScope} maxTagCount="responsive" onChange={handleChange} />
            </Form.Item>
          </Col>
          <Col span={8} >
            {showYear && <Form.Item
              label="日期范围"
              name="time"
              rules={[{ required: true, message: "请选择日期范围" }]}>
              < RangePicker picker='year' style={{ width: '100%' }} disabledDate={(current) => current > moment()} />
            </Form.Item>}
            {showMonth && <Form.Item
              label="日期范围"
              name="time"
              rules={[{ required: true, message: "请选择日期范围" }]}>
              <RangePicker picker='month' style={{ width: '100%' }} disabledDate={(current) => current > moment()} />
            </Form.Item>}
            {showWeek && <Form.Item
              label="日期范围"
              name="time"
              rules={[{ required: true, message: "请选择日期范围" }]}>
              <RangePicker picker='week' style={{ width: '100%' }} disabledDate={(current) => current > moment()} />
            </Form.Item>}
            {showDay && <Form.Item
              label="日期范围"
              name="time"
              rules={[{ required: true, message: "请选择日期范围" }]}>
              <RangePicker style={{ width: '100%' }} disabledDate={(current) => current > moment()} />
            </Form.Item>}
          </Col>
          <Col span={8} style={{ margin: '30px' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" >
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.chartCard}>
            <div className={styles.title}>客服处理任务总量分布</div>
            <LoadingWrapper spinning={loading}>
              <div id="totalTaskChart" style={{ height: '400px', width: '100%', marginTop: '16px' }} />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.chartCard}>
            <div className={styles.title}>客服各状态任务数量</div>
            <LoadingWrapper spinning={loading}>
              <div id="customerTakStateChart" style={{ height: '400px', width: '100%', marginTop: '16px' }} />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.chartCard}>
            <div className={styles.title}>客服处理量统计</div>
            <LoadingWrapper spinning={loading}>
              <div id="orderStatisticsChart" style={{ height: '400px', width: '100%', marginTop: '16px' }} />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.chartCard}>
            <div className={styles.title}>任务状态统计</div>
            <LoadingWrapper spinning={loading}>
              <div id="serviceStatisticsChart" style={{ height: '400px', width: '100%', marginTop: '16px' }} />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.chartCard}>
            <div className={styles.title}>平均计时统计</div>
            <LoadingWrapper spinning={loading}>
              <div id="serviceTimeChart" style={{ height: '400px', width: '100%', marginTop: '16px' }} />
            </LoadingWrapper>
          </div>
        </Col>
      </Row>

    </Card>
  )
}

export default CustomerTaskStatistic;
