/**
 * 树形车辆的相关 
 */
import request from "@/utils/request";

// 获取车辆的树形数据
export const carTreeRequest = (params?: { search: string }) => {
  return request.get('/api/v2/car/busTree', {
    params
  })
}