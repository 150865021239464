// localStorage相关key
export enum LocalStorageKeyEnum {
  access_token = 'access_token', // token
  user_detail = 'userDetail', // 用户信息
  real_time_monitor_setting = 'realTimeMonitorSetting' // 实时监控的参数设置
}

// localStorage的操作处理
const localStorageUtil = {
  setItem: (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getItem: (key: string, defaultValue?: any) => {
    const value = localStorage.getItem(key) as string;
    if (value) {
      try {
        const parseValue = JSON.parse(value);
        return parseValue;
      } catch (error) {
        return value;
      }
    } else {
      return defaultValue;
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: () => {
    localStorage.clear();
  }
}

export default localStorageUtil;