/**
 * 车辆CAN协议
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Select } from 'antd';
import { round } from 'lodash';
import {
  canInfoListRequest,
  canInfoListRequestPath,
  CanInfoListRequestParams,
  canDataRequest
} from '@/service/carCan';
import { carBrandRequest, carModelRequest } from '@/service/carManage';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { getInnerPermissionEnable } from '@/utils/permission';
import { downloadFile } from '@/utils/download';
import {
  carCANSignal,
  carCANSignalValue,
  carCANSignalColor
} from '@/constants/carCan';
import { formSearchGutter } from '@/constants/common';
import StatisticsCard from '@/pages/home/components/statisticsCard';
import OperateModal from './components/operateModal';
import DbcFileList from './components/dbcFileList';
import OperateDbcModal from './components/operateDbcModal';
import styles from '@/styles/pageContent.module.scss';

const CarCan = () => {
  const [brandList, setBrandList] = useState([]); // 车辆品牌的数据
  const [modelList, setModelList] = useState([]); // 车辆车型的数据
  const [data, setData] = useState([]); // 数据
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [pageNum, setPageNum] = useState(1); // 页码
  const [pageSize, setPageSize] = useState(40); // 每一页显示的数量
  const [loading, setLoading] = useState(false);
  const [sortParams, setSortParams] = useState<any>({
    carCountDesc: true
  }); // 排序的参数
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [canData, setCanData] = useState<any>({}); // CAN统计数据
  const [modelCount, setModelCount] = useState(0); // 所有车型的总数
  const [form] = Form.useForm();

  useEffect(() => {
    getBrandList();
    getModelList();
    getData(
      {
        page: 1,
        limit: 40,
        ...sortParams
      },
      true
    );
    getCanData();
  }, []);

  // 获取车辆品牌的数据
  const getBrandList = async () => {
    const res = await carBrandRequest();
    let brandData = res.data?.data || [];
    brandData = brandData.map((item: string) => ({
      label: item,
      value: item
    }));
    setBrandList(brandData);
  };

  // 获取车型的数据
  const getModelList = async () => {
    const res = await carModelRequest();
    let modelData = res.data?.data || [];
    modelData = modelData.map((item: string) => ({
      label: item,
      value: item
    }));
    setModelList(modelData);
  };

  // 获取数据
  const getData = async (params: CanInfoListRequestParams, isAll?: boolean) => {
    try {
      setLoading(true);
      const res = await canInfoListRequest(params);
      setData(res.data?.data || []);
      setTotalCount(res.data?.count || 0);
      // 存储车型总数
      if (isAll) {
        setModelCount(res.data?.count || 0);
      }
      setLoading(false);
      setPageNum(params.page);
    } catch (error) {
      setLoading(false);
    }
  };

  // 获取CAN协议统计数据
  const getCanData = async () => {
    const res = await canDataRequest();
    setCanData(res.data?.data || []);
  };

  // 搜索
  const onSearch = async (values: any) => {
    const newValues = { ...values };
    setSearchParams(newValues);
    getData({
      page: 1,
      limit: pageSize,
      ...sortParams,
      ...newValues
    });
  };

  // 翻页器信息改变
  const onPageChange = (page: number, newPageSize: number) => {
    const newPageNum = newPageSize !== pageSize ? 1 : page;
    setPageNum(newPageNum);
    setPageSize(newPageSize);
    getData({
      page: newPageNum,
      limit: newPageSize,
      ...sortParams,
      ...searchParams
    });
  };

  // 表格的排序
  const onSort = (pagination: any, filters: any, sorter: any) => {
    const sortOrder: string = sorter.order;
    const params: any = {
      page: 1,
      limit: pageSize,
      ...searchParams
    };
    const carCountDesc = sortOrder === 'descend' ? true : false;
    if (sortOrder) {
      params.carCountDesc = carCountDesc;
      setSortParams({
        carCountDesc: carCountDesc
      });
    }
    // 排序有变化时才重新获取数据
    if (carCountDesc !== sortParams.carCountDesc) {
      getData(params);
    }
  };

  // 添加编辑之后重新获取列表数据
  const reloadData = (reloadBrandModel?: boolean) => {
    getData({
      page: pageNum,
      limit: pageSize,
      ...sortParams,
      ...searchParams
    });
    getCanData();
    // 新增车型后重新获取品牌和车型数据同时更新车型总数
    if (reloadBrandModel) {
      getBrandList();
      getModelList();
      setModelCount((prevCount: number) => prevCount + 1);
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const newValues = { ...values };
    newValues.export = true;
    newValues.carCountDesc = sortParams.carCountDesc;
    return newValues;
  };

  // 渲染CAN协议信号
  const renderCANSignalColumn = (value: number) => {
    return carCANSignalValue[value] || '未破解';
  };

  // 渲染文件按钮(根据是否有下载权限区分展示的样式)
  const renderFileButton = (file: any, permissionKey: string) => {
    const hasExportPermission = getInnerPermissionEnable(permissionKey);
    return (
      <Button
        type={hasExportPermission ? 'link' : 'text'}
        onClick={() =>
          hasExportPermission ? downloadFile(file.preview, file.fileName) : null
        }
        style={{ cursor: hasExportPermission ? 'pointer' : 'default' }}
        title={file.fileName}
      >
        {file.fileName}
      </Button>
    );
  };

  // 渲染DBC文件列
  const renderDbcFileColumn = (value: any, data: any) => {
    return (
      <div>
        {(data?.file || []).map((item: any) => {
          if (item?.dbcFiles?.key) {
            return (
              <div
                className={styles.tableOperateColumn}
                key={item.dbcFiles.key}
              >
                {renderFileButton(
                  item.dbcFiles,
                  '/car-admin/car-can/dbcDownload'
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  // 渲染CAN文件列
  const renderCanFileColumn = (value: any, data: any) => {
    return (
      <div>
        {(data?.file || []).map((item: any) =>
          (item.canFiles || []).map((item1: any) => (
            <div className={styles.tableOperateColumn} key={item1.key}>
              {renderFileButton(item1, '/car-admin/car-can/canDownload')}
            </div>
          ))
        )}
      </div>
    );
  };

  // 渲染录屏文件列
  const renderFileColumn = (value: any) => {
    return (
      <div>
        {(value || []).map((item: any) => (
          <div className={styles.tableOperateColumn} key={item.key}>
            {renderFileButton(item, '/car-admin/car-can/videoDownload')}
          </div>
        ))}
      </div>
    );
  };

  // 渲染信号单元格
  const renderSignalColumnCell = (record: any, field: string) => {
    if (record.hasFetch) {
      return {
        className: carCANSignalColor[record[field]] || 'warningTableCell'
      };
    } else {
      return {};
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '品牌',
      dataIndex: 'brand',
      width: 100,
      fixed: 'left' as const,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '车型',
      dataIndex: 'model',
      width: 100,
      fixed: 'left' as const,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '车辆数',
      dataIndex: 'carCount',
      width: 120
    },
    {
      title: '占比',
      dataIndex: 'prop',
      sorter: true,
      sortOrder: sortParams.carCountDesc ? 'descend' : ('ascend' as any),
      sortDirections: ['ascend', 'descend', 'ascend'] as any,
      width: 120,
      render: (value: any) => `${round(value * 100, 2)}%`
    },
    {
      title: '是否抓取',
      dataIndex: 'hasFetch',
      width: 90,
      render: (value: boolean) => (value ? 'Y' : 'N')
    },
    ...carCANSignal.map((item: any) => ({
      title: item.label,
      dataIndex: item.value,
      width: 110,
      render: renderCANSignalColumn,
      onCell: (record: any) => renderSignalColumnCell(record, item.value)
    })),
    {
      title: 'CAN报文',
      dataIndex: 'canFiles',
      width: 90,
      ellipsis: true,
      render: (value: any, record: any) => renderCanFileColumn(value, record)
    },
    {
      title: 'dbc文件',
      dataIndex: 'dbcFiles',
      width: 90,
      ellipsis: true,
      render: (value: any, record: any) => renderDbcFileColumn(value, record)
    },
    {
      title: '验证录屏文件',
      dataIndex: 'attachFiles',
      width: 120,
      ellipsis: true,
      render: (value: any) => renderFileColumn(value)
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              type="link"
              operateType="/car-admin/car-can/edit"
              onClick={() => setOperateInfo({ type: 'operate', data: item })}
            >
              编辑
            </PermissionButton>
            <Button
              type="link"
              onClick={() => setOperateInfo({ type: 'dbcConfig', data: item })}
            >
              DBC文件配置
            </Button>
          </div>
        );
      }
    }
  ];

  // 隐藏车辆数统计指标
  const hiddenCarTotal = getInnerPermissionEnable(
    '/car-admin/car-can/hiddenCarTotal'
  );
  const colSpan = hiddenCarTotal ? 6 : 4;
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Row gutter={formSearchGutter}>
        <Col span={colSpan}>
          <StatisticsCard
            title="已采集车型数"
            count={`${canData?.fetchModelCount || 0} / ${modelCount}`}
            bordered
          />
        </Col>
        {hiddenCarTotal || (
          <Col span={colSpan}>
            <StatisticsCard
              title="已采集车辆数"
              count={`${canData?.fetchCount || 0} / ${canData?.carCount || 0}`}
              bordered
            />
          </Col>
        )}
        <Col span={colSpan}>
          <StatisticsCard
            title="已采集车辆占比"
            count={`${round((canData?.fetchProp || 0) * 100, 2)}%`}
            bordered
          />
        </Col>
        <Col span={colSpan}>
          <StatisticsCard
            title="已验证车型数"
            count={`${canData?.validModelCount || 0} / ${modelCount}`}
            bordered
          />
        </Col>
        {hiddenCarTotal || (
          <Col span={colSpan}>
            <StatisticsCard
              title="已验证车辆数"
              count={`${canData?.validCount || 0} / ${canData?.carCount || 0}`}
              bordered
            />
          </Col>
        )}
        <Col span={colSpan}>
          <StatisticsCard
            title="已验证车辆占比"
            count={`${round((canData?.validProp || 0) * 100, 2)}%`}
            bordered
          />
        </Col>
      </Row>
      <Form
        onFinish={onSearch}
        form={form}
        autoComplete="off"
        style={{ marginTop: '16px' }}
      >
        <Row gutter={formSearchGutter}>
          <Col span={6}>
            <Form.Item label="车辆品牌" name="brand">
              <Select
                options={brandList}
                showSearch
                allowClear
                placeholder="请选择车辆品牌"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="车型" name="model">
              <Select
                options={modelList}
                showSearch
                allowClear
                placeholder="请选择车型"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="是否抓取" name="hasFetch">
              <Select
                allowClear
                placeholder="请选择"
                options={[
                  { label: '已抓取', value: true },
                  { label: '未抓取', value: false }
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
                <ExportButton
                  url={canInfoListRequestPath}
                  fileName="车辆CAN协议"
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey="/car-admin/car-can/export"
                />
                <PermissionButton
                  type="primary"
                  operateType="/car-admin/car-can/dbcAdd"
                  onClick={() => setOperateInfo({ type: 'addDbc' })}
                >
                  新增车型
                </PermissionButton>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: pageNum,
          pageSize,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        onChange={onSort}
      />
      {operateInfo.type === 'operate' && (
        <OperateModal
          closeModal={() => setOperateInfo({})}
          type={operateInfo.type}
          data={operateInfo.data}
          reloadData={reloadData}
        />
      )}
      {operateInfo.type === 'dbcConfig' && (
        <DbcFileList
          onClose={() => setOperateInfo({})}
          data={operateInfo.data}
          reloadData={reloadData}
        />
      )}
      {/* 新增车型 */}
      {'addDbc' === operateInfo.type && (
        <OperateDbcModal
          onClose={() => setOperateInfo({})}
          reloadData={() => reloadData(true)}
        />
      )}
    </Card>
  );
};

export default CarCan;
