import {Cascader, Col, DatePicker, Divider, Form, Input, Modal, Radio, Row, Select, TimePicker} from "antd";
import {ModalProps} from 'antd/lib/modal/Modal';
import {useEffect, useState} from "react";
import CarType from "@/pages/offerRecord/constant/carType"
import {tagListRequest} from "@/service/tagManage";
import {areaListRequest, carTypeListRequest} from "@/service/offerRecord";
import {fullTypeGen, getChildTypeByParentCode, rootNodeFilter} from "@/pages/offerRecord/utils/utils";
import useVehicleType from "@/pages/offerRecord/utils/vehicleType.hook";


interface QuotationModalProps extends ModalProps {
  open: boolean;
  onCancel: () => void;
}

function QuotationModal(props: QuotationModalProps) {
  const {open, onCancel} = props;
  const [form] = Form.useForm();
  const [fuelType, setFuelType] = useState<any>([]); // 燃料类型
  const {allVehicleType} = useVehicleType() // 车辆类型

  const [allCities, setAllCities] = useState<any>([]); // 城市

  //获取燃料类型
  const getFuelType = async () => {
    const res = await tagListRequest({tagSorts: [54]});
    const resData = res.data?.data || [];
    return resData.map((item: any) => {
      return {label: item.title, value: item.title}
    })
  }

  //获取城市
  const getAllCities = async () => {
    const res = await areaListRequest();
    const resData = res.data?.data || [];
    return resData
      .filter(rootNodeFilter)
      .map(fullTypeGen(resData))
  }

  useEffect(() => {

    //获取燃料类型
    getFuelType()
      .then(res => setFuelType(res))

    getAllCities()
      .then(res => setAllCities(res))

  }, []);


  return (
    <Modal open={open} title={"创建报价"} maskClosable={false} onCancel={onCancel} width={"50vw"}>
      <Form form={form}>
        <Divider orientation="left">基础信息</Divider>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item labelCol={{span: 8}} label={"报价所属公司"}>
              <Input placeholder={"请输入所属分公司"}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item labelCol={{span: 8}} label={"车牌号"}>
              <Input placeholder={"请输入车牌号"}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item labelCol={{span: 8}} label={"投保日期"}>
              <DatePicker placeholder={"请选择投保日期"}></DatePicker>
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">车辆信息</Divider>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"发动机号码"}>
              <Input placeholder={"请输入发动机号码"}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"车辆识别代号"}>
              <Input placeholder={"请输入车辆识别代号"}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"品牌型号"}>
              <Input placeholder={"请输入品牌型号"}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"车型"}>
              <Select options={CarType} placeholder={"请选择车型"} allowClear={true}></Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"核定载客量"}>
              <Input placeholder={"请输入核定载客量"}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"是否分期"}>
              <Radio.Group>
                <Radio value="yes">是</Radio>
                <Radio value="no">否</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"燃料类型"}>
              <Select options={fuelType} placeholder={"请选择燃料类型"}></Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"车辆类型"}>
              <Cascader options={allVehicleType} placeholder={"请选择车辆类型"}></Cascader>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"载重量(单位:kg)"}>
              <Input placeholder={"请输入载重量"}></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"注册日期"}>
              <DatePicker placeholder={"请选择投保日期"}></DatePicker>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{span: 8}} label={"选择城市"}>
              <Cascader options={allCities} placeholder={"请选择城市"}></Cascader>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default QuotationModal;
