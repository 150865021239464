/**
 * 设备添加和编辑
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select } from 'antd';
import {
  deviceDetailRequest,
  upsertDeviceRequest
} from '@/service/deviceManage';
import { DeviceCategoryEnum } from '@/constants/deviceManage';
import { isKlsAeb } from '@/utils/device';

interface OperateModalProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData: (params?:any) => void;
}
const OperateModal = (props: OperateModalProps) => {
  const [operateLoading, setOperateLoading] = useState(false); // 设备操作接口是否请求中
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData } = props;

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      getDeviceDetail(data.id);
    }
  }, []);

  // 编辑时获取设备详情赋值
  const getDeviceDetail = async (id: number) => {
    const res = await deviceDetailRequest(id);
    form.setFieldsValue(res.data?.data || {});
  };

  // 提交
  const submit = async () => {
    try {
      setOperateLoading(true);
      const values = await form.validateFields();
      const params = { ...values };
      const isEdit = type === 'edit';
      // 昆仑山设备SN转化字母为大写
      if (isKlsAeb(values.deviceType)) {
        params.sn = values.sn?.toUpperCase();
      }
      if (isEdit) {
        // 编辑时添加id
        params.id = data.id;
      }
      const res = await upsertDeviceRequest(params);
      if (res.data?.code === 0) {
        message.success(isEdit ? '修改成功' : '添加成功');
        setOperateLoading(false);
        closeModal();
        reloadData(params);
      } else {
        setOperateLoading(false);
      }
    } catch (error) {
      setOperateLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加设备' : '编辑设备'}
      open
      onCancel={closeModal}
      onOk={submit}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      okButtonProps={{ loading: operateLoading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="设备类型"
          name="deviceType"
          rules={[{ required: true, message: '请选择设备类型' }]}
        >
          <Select
            placeholder="请选择设备类型"
            options={[
              {label: '黄河设备', value: DeviceCategoryEnum.huanghe},
              {label: '昆仑山设备', value: DeviceCategoryEnum.kunlunshan},
              {label: '昆仑山3.0设备', value: DeviceCategoryEnum.kunlunshanV3},
            ]}
          />
        </Form.Item>
        {/* 编辑的时候不可以修改设备SN */}
        <Form.Item
          label="设备SN号"
          name="sn"
          rules={[{ required: true, message: '请输入设备SN号' }]}
        >
          <Input placeholder="请输入设备SN号" disabled={type === 'edit'} />
        </Form.Item>
        <Form.Item label="相机SN号" name="cameraSn">
          <Input placeholder="请输入相机SN号" />
        </Form.Item>
        <Form.Item label="SIM卡ICCID" name="sim">
          <Input placeholder="请输入SIM卡ICCID" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
