/**
 * 路口类型添加/编辑弹窗
 */
import React, { useEffect, useState } from 'react';
import { Form, message, Modal } from 'antd';
import { upsertFenceIntersectionRequest } from '@/service/electronFence';
import IntersectionOperateForm from '../intersectionOperateForm';

interface IntersectionOperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  fenceAreaId: number; // 围栏区域ID
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const IntersectionOperateModal = (props: IntersectionOperateModalProps) => {
  const { type, data, fenceAreaId, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false); // 保存按钮的loading

  // 编辑时赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存路口类型
  const saveIntersection = async (values: any) => {
    try {
      setSaveLoading(true);
      const params = {
        ...values,
        electronicFenceId: fenceAreaId
      };
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertFenceIntersectionRequest(params);
      if (res.data?.data) {
        message.success(type === 'edit' ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加路口类型' : '编辑路口类型'}
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{
        loading: saveLoading
      }}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={saveIntersection}
        layout="vertical"
      >
        <IntersectionOperateForm />
      </Form>
    </Modal>
  );
};

export default IntersectionOperateModal;
