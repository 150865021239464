// 报警类型
export const adasAlarmTypeData = [
  { label: 'FCW预警', value: 1 },
  { label: 'AEB制动', value: 2 },
  { label: '起步阻止', value: 3 },
  { label: 'LDW预警', value: 4 },
  { label: 'HMW预警', value: 5 },
  { label: '角雷达报警', value: 7 },
  { label: '右转制动', value: 8 },
  { label: '常规报警', value: 9 },
  { label: 'ADAS报警', value: 10 },
  { label: 'BSD报警', value: 11 },
  { label: 'DSM报警', value: 12 },
  { label: '设备故障报警', value: 13 },
]

// 报警类型的value
export const adasAlarmTypeValue = [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12, 13];

// // 报警类型(修正后HMW)
// export const correctAlarmTypeData = [
//   { label: 'FCW预警', value: 1 },
//   { label: 'AEB制动', value: 2 },
//   { label: '起步阻止', value: 3 },
//   { label: 'LDW预警', value: 4 },
//   { label: '修正后HMW预警', value: 5 },
//   { label: '角雷达报警', value: 7 },
//   { label: '右转制动', value: 8 },
// ]

// // 详细报警信息对应数据
// export const alarmKeyData: any = {
//   AEBStatus: {
//     name: 'AEB状态',
//     value: {
//       0: '正常', 
//       1: '转向灯打开', 
//       2: '刹车灯打开', 
//       3: 'AEB制动开关关闭', 
//       4: 'AEB发生制动', 
//       5: 'AEB发生预警'
//     }
//   },
//   decelerationDemand: {
//     name: '减速需求',
//     value: {}
//   },
//   AEBAction: {
//     name: 'AEB行为', 
//     value: {
//       0: '正常', 
//       1: '转向灯打开', 
//       2: '刹车灯打开', 
//       3: 'AEB制动开关关闭', 
//       4: 'AEB发生制动', 
//       5: 'AEB发生预警'
//     }
//   },
//   ttc: {
//     name: '前向碰撞时间（秒）', 
//     value: {}
//   },
//   hmw: {
//     name: '车距过近时间（秒）',
//     value: {}
//   },
//   warningSide: {
//     name: '预警边界',
//     value: {
//       'none': '无预警', 
//       'left': '左边界预警', 
//       'right': '右边界预警',
//       0: '无预警', 
//       1: '左边界预警', 
//       2: '右边界预警'
//     }
//   },
//   laneStyle: {
//     name: '车道线类型',
//     value: {
//       none: '无车道线', 
//       predict: '预测线', 
//       broken: '虚线', 
//       solid: '实线', 
//       double_broken: '双虚线', 
//       double_solid: '双实线', 
//       triple: '三车道线'
//     }
//   },
//   level: {
//     name: '报警等级',
//     value: {}
//   },
//   TTC: {
//     name: '碰撞时间(秒)',
//     value: {}
//   },
//   obstacle_type: {
//     name: '障碍物类型', 
//     value: {
//       0: '无', 
//       1: '车辆', 
//       2: '人', 
//       3: '其他', 
//       4: '骑行者', 
//       5: '其他', 
//       6: '其他', 
//       7: '其他', 
//       8: '其他', 
//       9: '其他', 
//       10: '其他', 
//       11: '其他'
//     }
//   },
//   lengthways_distance: {
//     name: '纵向相对距离(米)', 
//     value: {}
//   },
//   LDW: {
//     name: '车辆偏离预警', 
//     value: {
//       0: '未识别车道线', 
//       1: '左车道线偏离', 
//       2: '右车道线偏离', 
//       3: '无偏离'
//     }
//   },
//   HMW_time: {
//     name: '车距时间(秒)',
//     value: {}
//   },
//   verticalDistance: {
//     name: '障碍物纵向相对距离（米）',
//     value: {}
//   },
//   horizontalDistance: {
//     name: '障碍物横向相对距离（米）', 
//     value: {}
//   },
//   throttle: {
//     name: '油门踏板开度',
//     value: {}
//   },
//   brake: {
//     name: '刹车踏板开度', 
//     value: {}
//   },
//   steeringWheel: {
//     name: '方向盘角度', 
//     value: {}
//   },
//   steeringWheelStatus: {
//     name: '方向盘状态', 
//     value: {
//       0: '左打', 
//       1: '右打'
//     }
//   },
//   LeftLaneStyle: {
//     name: '左车道线类型',
//     value: {
//       0: '无', 
//       1: '预测', 
//       2: '虚线', 
//       3: '实线', 
//       4: '双虚线', 
//       5: '双实线', 
//       6: '三线'
//     }
//   },
//   RightLaneStyle: {
//     name: '右车道线类型',
//     value: {
//       0: '无', 
//       1: '预测', 
//       2: '虚线', 
//       3: '实线', 
//       4: '双虚线', 
//       5: '双实线', 
//       6: '三线'
//     }
//   },
//   fcwLevel: {
//     name: '前向碰撞预警等级', 
//     value: {
//       0: '无预警', 
//       1: '一级预警', 
//       2: '二级预警'
//     }
//   },
//   obstacleType: {
//     name: '障碍物类型',
//     value: {
//       0: '无', 
//       1: '车辆', 
//       2: '人', 
//       3: '其他', 
//       4: '其他', 
//       5: '其他', 
//       6: '其他', 
//       7: '其他', 
//       8: '其他', 
//       9: '其他', 
//       10: '其他', 
//       11: '其他'
//     }
//   },
//   AebsStatus: {
//     name: 'AEB制动类型',
//     value: {
//       0: '无制动', 
//       1: '双目制动', 
//       2: '毫米波制动', 
//       3: '超声波制动', 
//       4: '角雷达制动'
//     }
//   },
//   GearStatus: {
//     name: '档位状态', 
//     value: {
//       0: '无', 
//       1: 'N档', 
//       2: 'D档', 
//       3: 'R档'
//     }
//   },
//   isBegin: {
//     name: '报警起、止', 
//     value: {
//       0: '止', 
//       1: '起', 
//       2: '毫米波制动', 
//       3: '超声波制动'
//     }
//   },
//   warningType: {
//     name: '报警类型',
//     value:  {
//       1: 'fcw', 
//       2: 'hmw', 
//       3: 'ldw', 
//       4: 'aeb'
//     }
//   },
//   ultrasonicDistance: {
//     name: '超声波报警距离（分米）',
//     value: {}
//   },
//   verticalSpeed: {
//     name: '障碍物纵向相对速度（米/秒）',
//     value: {}
//   },
//   time: {
//     name: '预警时间',
//     value: {}
//   },
//   speed: {
//     name: '车距过近预警车速',
//     value: {}
//   },
//   vehicleSpeed: {
//     name: '车身速度',
//     value: {}
//   },
//   gpsSpeed: {
//     name: 'gps车速',
//     value: {}
//   },
//   SignalRightOn: {
//     name: '右转向灯',
//     value: {
//       0: '关闭', 
//       1: '打开'
//     }
//   },
//   AebsOn: {
//     name: 'AEB制动开关',
//     value: {
//       0: '关闭', 
//       1: '打开'
//     }
//   },
//   SignalLeftOn: {
//     name: '左转向灯',
//     value: {
//       0: '关闭', 
//       1: '打开'
//     }
//   },
//   SignalBreakOn: {
//     name: '刹车灯', 
//     value: {
//       0: '关闭', 
//       1: '打开'
//     }
//   },
//   warningLevel: {
//     name: '预警等级',
//     value: {
//       0: '无预警',
//       1: '一级', 
//       2: '二级'
//     }
//   },
//   accVelocity: {
//     name: 'ACC速率',
//     value: {}
//   },
//   alarmId: {
//     name: '报警ID',
//     value: {}
//   },
//   mileage: {
//     name: '里程数',
//     value: {}
//   },
//   lon: {
//     name: '经度',
//     value: {}
//   },
//   lat: {
//     name: '纬度',
//     value: {}
//   },
//   orientation: {
//     name: '方向',
//     value: {}
//   },
//   gpsDateTime: {
//     name: 'GPS时间',
//     value: {}
//   },
//   timeMs: {
//     name: 'AEB系统运行时间',
//     value: {}
//   },
//   cameraFailureId: {
//     name: '相机异常码',
//     value: {}
//   },
//   whichUltrasonicBrake: {
//     name: '超声波制动雷达ID',
//     value: {}
//   },
//   bsdWarningDirection: {
//     name: '角雷达报警方向', 
//     value: {
//       0: '无效', 
//       1: '前方', 
//       2: '右侧', 
//       3: '后方', 
//       4: '左侧'
//     }
//   },
//   angleSpeed: {
//     name: 'IMU角速度',
//     value: {}
//   },
//   accSpeed: {
//     name: 'IMU加速度', 
//     value: {}
//   },
//   brakeStrength: {
//     name: '制动力度', 
//     value: {}
//   },
// }