/**
 * 风险评估报告推送记录
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import DatePicker from '@/components/datePicker';
import PermissionButton from '@/components/permissionButton';
import { fleetRiskPushRecordRequest } from '@/service/fleetRisk';
import { operateDayRange } from '@/utils/operateSearchParams';
import { useTable } from '@/utils/useTable';
import PushConfigModal from './components/pushConfigModal';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const FleetRiskPush = () => {
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: fleetRiskPushRecordRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
    frontEndPageChange: true,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateDayRange(values);
    setSearchParams(newSearchParams);
  };

  // 表格列显示
  const columns = [
    {
      title: '推送文件名',
      dataIndex: 'fileName',
    },
    {
      title: '操作',
      dataIndex: 'url',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              type="link"
              href={value}
              download={item.fileName}
              operateType='/car-admin/fleet-risk-push/download'
            >
              下载
            </PermissionButton>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker
                allowClear={false}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="url"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
        columnFilter={false}
      />
      {operateInfo.type === 'configSetting' &&
        <PushConfigModal closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default FleetRiskPush;
  