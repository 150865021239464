/**
 * 4G/GPS监控
 */
import React, { useState, useRef, useEffect } from "react";
import { Card, Form, Row, Col, Button, Input, message, Switch } from 'antd';
import CarListModal from '@/components/carListModal';
import CommonTable from '@/components/commonTable';
import { signalMonitorRequest } from '@/service/signalMonitor';
import { carListRequest } from '@/service/carManage';
import { DeviceTypeEnum } from '@/constants/deviceManage';
import { renderImuStatus } from "@/utils/imu";
import { useUserCateIdArr } from '@/utils/ownHooks';
import DescriptionModal from './components/descriptionModal';
import styles from '@/styles/pageContent.module.scss';

const SignalMonitor = () => {
  const cateIdArr = useUserCateIdArr();
  const [form] = Form.useForm();
  let timerRef: any = useRef(null); // 存储获取信号数据的定时器
  let autoRefreshRef: any = useRef(false); // 是否自动刷新数据
  const [searchValues, setSearchValues] = useState<any>(null); // 搜索到多辆车时的搜索参数
  const [carInfo, setCarInfo] = useState<any>(null); // 选择的车辆信息
  const [requestParams, setRequestParams] = useState<any>(null); // 信号数据的请求参数
  const [loading, setLoading] = useState<boolean>(false); // 是否请求信号数据中
  const [data, setData] = useState<any>([]); // 信号数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关信息

  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    }
  }, [])
  
  // 获取信号数据
  const getSignalMonitor = async(params: any) => {
    try {
      setLoading(true);
      const res = await signalMonitorRequest(params);
      setData(res.data?.data || []);
      setLoading(false);
      if (autoRefreshRef.current) {
        // 轮询获取信号数据
        timerRef.current = setTimeout(() => {
          getSignalMonitor(params);
        }, 30000);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  // 获取匹配搜索的车辆
  const getMatchCar = async (params: any) => {
    const newParams = {
      page: 1,
      limit: 10,
      plate_number: params.plateNumber,
      sn: params.sn,
      frameNumber: params.frameNumber,
      cate_id: cateIdArr
     };
    const res = await carListRequest(newParams);
    const matchCar = res.data?.data || [];
    if (matchCar.length === 0) {
      message.warning("车辆不存在");
      timerRef.current && clearTimeout(timerRef.current);
    } else if (matchCar.length === 1) {
      // 只匹配到一辆车时获取该车信息
      plateNumberClick(matchCar[0]);
    } else {
      // 获取到多辆车时显示车辆列表进行选择
      setSearchValues(params);
    }
  }

  // 根据匹配车辆的车牌号进行搜索
  const plateNumberClick = (data: any) => {
    timerRef.current && clearTimeout(timerRef.current);
    const params = { carId: data.id };
    setCarInfo(data);
    form.setFieldsValue({ plateNumber: data.plate_number });
    setRequestParams(params);
    getSignalMonitor(params);
  }

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.plateNumber = (params.plateNumber || '').trim();
    newParams.sn = (params.sn || '').trim();
    newParams.frameNumber = (params.frameNumber || '').trim();
    if (isExport) {
      newParams.export = true;
    }
    if (newParams.plateNumber || newParams.sn || newParams.frameNumber) {
      return newParams;
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    if (newSearchParams) {
      getMatchCar(newSearchParams);
    } else {
      message.warning('请输入车牌号或设备SN或车架号进行搜索');
    }
  };

  // 是否自动刷新
  const onCheckChange = (checked: any) => {
    autoRefreshRef.current = checked;
    if (checked) {
      if (requestParams) {
        getSignalMonitor(requestParams);
      }
    } else {
      timerRef.current && clearTimeout(timerRef.current);
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '时间',
      dataIndex: 'time',
      width: 160,
      fixed: 'left' as 'left',
    },
    {
      title: '数据类型',
      dataIndex: 'type',
      width: 100,
      fixed: 'left' as 'left',
    },
    {
      title: 'GPS卫星数量',
      dataIndex: 'gnssCount',
      width: 130,
    },
    {
      title: 'GPS定位状态',
      dataIndex: 'gpsLocationStatus',
      width: 130,
      render: (value: string) => {
        if (value === 'A') {
          return '已定位';
        } else if (value === 'V') {
          return '未定位';
        } else {
          return value;
        }
      },
    },
    {
      title: 'GPS连接状态',
      dataIndex: 'gpsConnectStatus',
      width: 130,
    },
    {
      title: '4G信号强度',
      dataIndex: 'signal4g',
      width: 120,
    },
    {
      title: 'IMU故障',
      dataIndex: 'imuFailureId',
      width: 120,
      render: (value: number) => renderImuStatus(value)
    },
  ];

  // 是否是黄河设备(黄河设备不展示数据类型列)
  const deviceType = carInfo?.device_type || carInfo?.deviceType;
  const isHuanghe = Number(deviceType) === DeviceTypeEnum.huanghe1
  || Number(deviceType) === DeviceTypeEnum.huanghe2 
  || Number(deviceType) === DeviceTypeEnum.huanghe3;
  if (isHuanghe) {
    columns.splice(2, 1);
  }
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={5}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
            >
              <Input placeholder='请输入车牌号'  />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="设备SN" name="sn">
              <Input placeholder='请输入设备SN' />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder='请输入车架号' />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label=" ">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <Button onClick={() => setOperateInfo({ type: 'description' })}>说明</Button>
              </div>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="自动刷新">
              <Switch onChange={onCheckChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        loading={loading}
      />
      {searchValues && <CarListModal
        desc="当前搜索匹配到多辆车辆，点击车牌可进行精准搜索"
        closeModal={() => setSearchValues(null)}
        searchValues={searchValues}
        plateNumberClick={(data: any) => plateNumberClick(data)}
      />}
      {operateInfo.type === 'description' && <DescriptionModal closeModal={() => setOperateInfo({})} />}
    </Card>
  )
}

export default SignalMonitor;