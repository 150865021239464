/**
 * 车队风险评估报告的相关请求
 */
import request from "@/utils/request";

// 获取车队风险月报数据
export const fleetRiskMonthReportRequest = (data: any) => {
  return request.post('/api/v2/projectReport/month/info', data)
}

// 导出车队风险报告
export const fleetRiskMonthReportExportRequestPath = '/api/v2/projectReport/month/export';

// 获取车队风险报告数据
export const fleetRiskReportRequest = (data: any) => {
  return request.post('/api/v2/safeReport/v1/detail', data)
}

// 根据车队获取车队车辆
export const motorcadeCarList = (params?: { cateName?: string }) => {
  return request.get('/api/v2/car/carList', { params })
}

// 导出车队风险报告
export const fleetRiskReportExportRequestPath = '/api/v2/safeReport/v1/export';

interface FleetRiskPushRecordRequestParams {
  page: number,
  limit: number,
  beginDate: string;
  endDate: string;
}
// 获取风险报告的推送记录
export const fleetRiskPushRecordRequest = (params: FleetRiskPushRecordRequestParams) => {
  return request.get('/api/v2/safeReport/record', {
    params
  })
}

// 获取风险报告的推送配置
export const fleetRiskPushConfigRequest = () => {
  return request.get('/api/v2/safeReport/config')
}

// 设置风险报告的推送配置
export const fleetRiskPushConfigSettingRequest = (data: any) => {
  return request.post('/api/v2/safeReport/config', data)
}


//获取管辖范围内内的车队 
export const cateAllCompanyRequest = () => {
  return request.get('/api/v2/cate/allCompany')
}