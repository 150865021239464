/**
 * 车辆分组移动
 */
import React, { Fragment, useState, useRef } from 'react';
import { Form, message } from 'antd';
import { carGroupMoveRequest } from '@/service/carGroupMove';
import CarTree from '@/components/carTree';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import PermissionButton from '@/components/permissionButton';
import styles from './index.module.scss';

const CarGroupMove = () => {
  const carTreeRef: any = useRef(); // 车辆树组件ref
  const [selectCarInfos, setSelectCarInfos] = useState<Array<number>>([]); // 选择的车辆信息
  const [form] = Form.useForm();
  
  // 移动分组
  const onMove = async (values: any) => {
    if (selectCarInfos?.length) {
      const carIds = selectCarInfos.map((item: any) => item.metaId);
      const res = await carGroupMoveRequest({
        ...values,
        carIds,
      });
      if (res.data?.code === 0) {
        message.success('移动成功');
        setSelectCarInfos([]);
        // 重新请求车辆树数据
        carTreeRef.current?.reloadData?.();
      }
    } else {
      message.error('请选择要移动的车辆');
    }
  }

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>, carInfos: any) => {
    setSelectCarInfos(carInfos);
  }

  return (
    <div className={styles.carGroupMoveBox}>
      <div className={styles.carTree}>
        <CarTree checkable treeCheck={treeCheck} searchAlive ref={carTreeRef} />
      </div>
      <div className={styles.moveButtonBox}>
        <PermissionButton
          type="primary"
          operateType='/car-admin/car-group-move/move'
          onClick={() => form.submit()}
        >
          移动分组
        </PermissionButton>
      </div>
      <div className={styles.rightPart}>
        <Form layout='vertical' onFinish={onMove} form={form}>
          <Form.Item
            label="分组选择"
            name="cateId"
            rules={[
              { required: true, message: '请选择分组' }
            ]}
          >
            <CategoryTreeSelect />
          </Form.Item>
        </Form>
        {Boolean(selectCarInfos.length) && 
          <Fragment>
            <div className={styles.carBoxTitle}>{`选择的车辆（${selectCarInfos.length} 辆）`}</div>
            <div className={styles.carBox}>
              {selectCarInfos.map((item: any) => (
                <div key={item.metaId} className={styles.carItem}>{item.title}</div>
              ))}
            </div>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default CarGroupMove;
  