/**
 * DatePicker(使用dayjs)
 */
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/es/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
DatePicker.RangePicker.defaultProps = {
  ranges: {
    今天: [dayjs().startOf('day'), dayjs().endOf('day')],
    昨天: [
      dayjs().subtract(1, 'day').startOf('day'),
      dayjs().subtract(1, 'day').endOf('day')
    ]
  }
};
export default DatePicker;