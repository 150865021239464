import { operatePermissionData } from '@/utils/permission';
import { getUserInfoRequest } from '@/service/login';
import { getUserPermissionRequest } from '@/service/userManage';
import { MenuPurposeEnum } from '@/constants/menuManage';
// 不展示的菜单栏
// const hideMenu = ['状态管理','AEB制动关闭', '数据大屏s', 'adas设备管理', 'AEB设备管理', '实时监控', '监控回放', 'adas报警查询', '客户人脸', ]
const hideMenu: any[] = [];
// 获取用户信息
export const operateUserInfo = async() => {
  const res: any = await Promise.allSettled([
    getUserInfoRequest(),
    getUserPermissionRequest({
      menuPurpose: [MenuPurposeEnum.admin, MenuPurposeEnum.all]
    })
  ]);
  if (res[0]?.status === 'rejected' || res[1]?.status === 'rejected') {
    localStorage.setItem('userDetail-websoket', JSON.stringify(false));
  }
  const userDetail = res[0]?.value?.data?.data || {};
  //本地存储 当用户第一次登录websoket500
  userDetail.cateIdArr = Array.isArray(userDetail.cateId) ? userDetail.cateId : [userDetail.cateId];
  if (userDetail.multiCate) {
    userDetail.cateIdArr = JSON.parse(userDetail.multiCate);
  }
  const permissionData = res[1]?.value?.data?.data || [];
  let { menuData, allPagePermission, allInnerPermission, allSubMenuKeys } = operatePermissionData(permissionData);
  // menuData = menuData.filter((item: any) => {
  //   return item.label != '传感器方案'
  // })
  // 系统融合剔除状态管理和aeb制动关闭菜单
  menuData.forEach((item: {children: any[]}) => {
    item.children = item.children.filter((itemInner: { label: string; }) => {
      if(!hideMenu.find((item) => {return item == itemInner.label})) {
        return itemInner
      }
    })
  })
  return {
    userDetail,
    menuData,
    allPagePermission, 
    allInnerPermission,
    allSubMenuKeys
  }
}