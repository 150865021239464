/**
 * 传感器方案-超声波安装示意图
 */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  Fragment
} from 'react';
import { Select } from 'antd';
import carImage from '@/assets/image/car.jpg';
import styles from './index.module.scss';

interface SensorUltrasonicLocationProps {
  frontData?: any; // 前向选择器数据源
  behindData?: any; // 后向选择器数据源
  leftData?: any; // 左向选择器数据源
  rightData?: any; // 右向选择器数据源
  isView?: boolean; // 是否是查看模式
  initSelectedLocationData?: any; // 各个位置选择的位置关系数据(初始值)
}
const SensorUltrasonicLocation = (
  props: SensorUltrasonicLocationProps,
  ref: any
) => {
  // 不同位置对应的样式类
  const locationStyleData = [
    { className: styles.selectItemOne },
    { className: styles.selectItemTwo },
    { className: styles.selectItemThree },
    { className: styles.selectItemFour },
    { className: styles.selectItemFive },
    { className: styles.selectItemSix }
  ];

  // select通用的属性
  const commonSelectProps = {
    size: 'small' as const,
    style: { width: '80px' },
    showArrow: false,
    allowClear: true
  };
  const {
    frontData,
    behindData,
    leftData,
    rightData,
    isView,
    initSelectedLocationData = []
  } = props;
  const [selectedLocationData, setSelectedLocationData] = useState(
    initSelectedLocationData
  ); // 每一侧选择的位置关系数据(二维数组形式维护)

  // 监听选择的位置关系数据的变化
  useEffect(() => {
    setSelectedLocationData(initSelectedLocationData);
  }, [initSelectedLocationData]);

  // 暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    // 清空某一侧选择的位置关系数据
    resetSelectLocation: (outerIndex: number) => {
      const newSelectedLocationData: any = [...selectedLocationData];
      newSelectedLocationData[outerIndex] = [];
      setSelectedLocationData(newSelectedLocationData);
    },
    // 获取每一侧选择的位置关系数据
    getSelectLocation: () => {
      return selectedLocationData;
    }
  }));

  // 选择的位置关系数据改变
  const locationChange = (
    outerIndex: number,
    innerIndex: number,
    value: any
  ) => {
    const newSelectedLocationData: any = [...selectedLocationData];
    if (newSelectedLocationData[outerIndex]) {
      newSelectedLocationData[outerIndex][innerIndex] = value;
    } else {
      newSelectedLocationData[outerIndex] = [];
      newSelectedLocationData[outerIndex][innerIndex] = value;
    }
    setSelectedLocationData(newSelectedLocationData);
  };

  // 渲染每一侧的选择框
  const renderSelectItem = (
    data: any,
    outerIndex: number,
    className: any,
    reverseLine?: boolean
  ) => {
    // 选择框有数据或查看模式下有赋值时显示为红色
    const pointLineStyle =
      data?.length || selectedLocationData[outerIndex]?.length
        ? { background: '#ff4d4f' }
        : {};
    return locationStyleData.map((item: any, index: number) => (
      <div className={`${className} ${item.className}`} key={index}>
        {reverseLine && (
          <Fragment>
            <div className={styles.point} style={pointLineStyle} />
            <div className={styles.line} style={pointLineStyle} />
          </Fragment>
        )}
        {/* 查看模式下直接显示 */}
        {isView ? (
          <div className={styles.displaySelect}>
            {selectedLocationData[outerIndex]?.[index]}
          </div>
        ) : (
          <Select
            {...commonSelectProps}
            options={data || []}
            value={selectedLocationData[outerIndex]?.[index]}
            onChange={(value: any) => locationChange(outerIndex, index, value)}
          />
        )}
        {reverseLine || (
          <Fragment>
            <div className={styles.line} style={pointLineStyle} />
            <div className={styles.point} style={pointLineStyle} />
          </Fragment>
        )}
      </div>
    ));
  };

  return (
    <div className={styles.ultrasonicLocationBox}>
      {/* 左侧 */}
      <div className={styles.leftBox}>
        {renderSelectItem(leftData, 2, styles.selectItem)}
      </div>
      <div className={styles.middleBox}>
        {/* 前方 */}
        <div className={styles.middleTop}>
          {renderSelectItem(frontData, 0, styles.selectItemColumn)}
        </div>
        <div className={styles.middleImage}>
          <img src={carImage} alt="car" />
        </div>
        {/* 后方 */}
        <div className={styles.middleBottom}>
          {renderSelectItem(behindData, 1, styles.selectItemColumn, true)}
        </div>
        <div className={styles.middleText}>超声波安装示意图</div>
      </div>
      {/* 右侧 */}
      <div className={styles.rightBox}>
        {renderSelectItem(rightData, 3, styles.selectItem, true)}
      </div>
    </div>
  );
};

export default forwardRef(SensorUltrasonicLocation);
