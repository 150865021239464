/**
 * 通过数据配置生成表单
 */
import React from 'react';
import {
  Row,
  Col,
  Collapse,
  Switch,
  InputNumber,
  Select,
  Form,
  FormInstance,
  Radio
} from 'antd';
import { isNil } from 'lodash';
import { formSearchGutter } from '@/constants/common';

const { Panel } = Collapse;
interface ConfigFormProps {
  formConfig: any; // 表单配置项数据
  form: FormInstance; // form实例
  originData: any; // 表单的原始数据
  showCollapse?: boolean; // 是否使用Collapse渲染表单
  activeKeys?: any; // Collapse激活的keys
  onCollapseChange?: any; // 改变激活状态的方法
}
const ConfigForm = (props: ConfigFormProps) => {
  const {
    formConfig,
    form,
    originData,
    showCollapse,
    activeKeys,
    onCollapseChange
  } = props;

  // 渲染表单行
  const renderFormItemRow = (
    formItemRowData: any,
    formItemRowIndex: number,
    panelKey = ''
  ) => (
    <Row gutter={formSearchGutter} wrap key={`${panelKey}-${formItemRowIndex}`}>
      {formItemRowData.map((formItem: any) => {
        const {
          label,
          name,
          type,
          step,
          min,
          max,
          addonAfter,
          disabledField,
          visibleField,
          extra,
          at,
          getValueFromEvent,
          optionsData,
          judgeVisible,
          judgeRequired,
          mode,
          span,
          initialValue,
          disabled,
          precision,
          hide
        } = formItem;
        // 当没有读取到值时需要特殊判断是否显示的表单项不显示
        if (judgeVisible && originData && isNil(originData?.[name])) {
          return null;
        }
        // 表单项配置hide时不显示该表单项
        if (hide) {
          return null;
        }
        let isRequired = true;
        // 当没有读取到值时需要特殊判断该表单项不需要必填
        if (judgeRequired && originData && isNil(originData?.[name])) {
          isRequired = false;
        }
        const formItemCom: any = {
          switch: Switch,
          inputNumber: InputNumber,
          select: Select,
          radioGroup: Radio.Group
        };
        const MatchCom = formItemCom[type];
        const formItemProps: any = {};
        const matchComProps: any = { disabled };
        if (!isNil(initialValue)) {
          formItemProps.initialValue = initialValue;
        }
        // Swicth表单项对应配置
        if (type === 'switch') {
          formItemProps.valuePropName = 'checked';
          formItemProps.getValueFromEvent = getValueFromEvent;
          matchComProps.checkedChildren = '开';
          matchComProps.unCheckedChildren = '关';
        }
        // InputNumber表单项对应配置
        if (type === 'inputNumber') {
          formItemProps.rules = [
            { required: isRequired, message: `请输入${label}` },
            {
              type: 'number',
              min: min,
              max: max,
              message: `请输入指定区间的值(${min} ~ ${max})`
            }
          ];
          matchComProps.placeholder =
            !min && !max
              ? `请输入${label}`
              : `${isNil(min) ? '' : min} ~ ${max || ''}`;
          matchComProps.addonAfter = addonAfter;
          matchComProps.step = step;
          matchComProps.style = { width: '100%' };
          if (!isNil(precision)) {
            matchComProps.precision = precision;
          }
        }
        // Select表单项对应配置
        if (type === 'select') {
          formItemProps.rules = [
            { required: isRequired, message: `请选择${label}` }
          ];
          matchComProps.placeholder = `请选择${label}`;
          matchComProps.options = optionsData;
          matchComProps.mode = mode;
        }
        // Radio.Group表单项对应配置
        if (type === 'radioGroup') {
          formItemProps.rules = [
            { required: isRequired, message: `请选择${label}` }
          ];
          matchComProps.placeholder = `请选择${label}`;
          matchComProps.options = optionsData;
        }
        return (
          <Form.Item shouldUpdate noStyle key={name}>
            {() => {
              // 控制表单项是否显示
              let isShow = true;
              if (visibleField) {
                const visibleFieldArr = Array.isArray(visibleField)
                  ? visibleField
                  : [visibleField];
                const visibleFieldValue = form.getFieldsValue(visibleFieldArr);
                isShow = Object.values(visibleFieldValue).some((value: any) =>
                  Boolean(value)
                );
              }
              // 控制表单项是否disabled
              let isDisabled = false;
              if (disabledField) {
                const disabledFieldArr = Array.isArray(disabledField)
                  ? disabledField
                  : [disabledField];
                const disabledFieldValue =
                  form.getFieldsValue(disabledFieldArr);
                isDisabled = Object.values(disabledFieldValue).some(
                  (value: any) => Boolean(value)
                );
              }
              if (isShow) {
                return (
                  <Col span={span || 8}>
                    <Form.Item
                      tooltip={at ? `AT指令：${at}` : ''}
                      label={label}
                      name={name}
                      extra={extra}
                      {...formItemProps}
                    >
                      <MatchCom disabled={isDisabled} {...matchComProps} />
                    </Form.Item>
                  </Col>
                );
              } else {
                return null;
              }
            }}
          </Form.Item>
        );
      })}
    </Row>
  );

  if (showCollapse) {
    return (
      <Collapse activeKey={activeKeys} onChange={onCollapseChange}>
        {formConfig.map((panel: any) => {
          const { panelTitle, panelKey, formItems } = panel;
          return (
            <Panel header={panelTitle} key={panelKey} forceRender>
              {formItems.map((formItemRowData: any, formItemRowIndex: number) =>
                renderFormItemRow(formItemRowData, formItemRowIndex, panelKey)
              )}
            </Panel>
          );
        })}
      </Collapse>
    );
  } else {
    return formConfig.map((formItemRowData: any, formItemRowIndex: number) =>
      renderFormItemRow(formItemRowData, formItemRowIndex)
    );
  }
};

export default ConfigForm;
