/**
 * 昆仑山设备状态信息
 */
import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import CommonTable from '@/components/commonTable';
import { klsDeviceStatusData } from '@/constants/klsDeviceStatus';
import { deviceStateRequest } from '@/service/deviceManage';
import { formatTime } from '@/utils/formatTime';

interface AlarmInfoModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const KlsDeviceStatusModal = (props: AlarmInfoModalProps) => {
  const { data, closeModal } = props;
  const [tableData, setTableData] = useState<any>([]); // 设备状态表格信息
  const [loading, setLoading] = useState<boolean>(false); // 表格loading
  
  // 获取设备状态
  const getDeviceStatus = async () => {
    try {
      setLoading(true);
      const res = await deviceStateRequest(data.id);
      const statusData = res?.data.data || {};
      const statusArrayData: any = [];
      Object.keys(statusData).forEach((item: any) => {
        const value = statusData[item];
        if (klsDeviceStatusData[item]) {
          statusArrayData.push({ 
            title: klsDeviceStatusData[item].name || item,
            // key为reportedTime特殊处理时间格式
            value: item === 'reportedTime' ? formatTime(value) : klsDeviceStatusData[item].values[value] || value,
          })
        } else {
          statusArrayData.push({ title: item, value })
        }
      })
      setTableData(statusArrayData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDeviceStatus()
  }, [])

  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
    },
    {
      title: '',
      dataIndex: 'value',
    },
  ];

  return (
    <Modal
      title="设备状态信息"
      visible
      footer={null}
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
    >
      <CommonTable
        columnFilter={false}
        rowKey='title'
        size='small'
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        loading={loading}
        scroll={{}}
      />
    </Modal>
  )
}

export default KlsDeviceStatusModal;
     