/**
 * 车辆工时统计
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';
import { carTimeRequest, carTimeRequestPath } from '@/service/carTimeStatistics';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import OperationGroupSelect from '@/components/operationGroupSelect';
import { operateDayRange } from '@/utils/operateSearchParams';
import { useTable } from '@/utils/useTable';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { snColumnConfig, cameraSnColumnConfig, cateGroupColumnConfig } from '@/constants/tableColumn';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const CarTimeStatistics = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    cate_id: cateIdArr,
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carTimeRequest,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateDayRange(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateDayRange(values, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cate_id: options.cateId,
      })
    }
  }

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
      fixed: 'left' as 'left',
    },
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
      fixed: 'left' as 'left',
    },
    snColumnConfig,
    cameraSnColumnConfig,
    cateGroupColumnConfig,
    {
      title: '日期',
      dataIndex: 'date',
      width: 120,
    },
    {
      title: '有效运行时长(分)',
      dataIndex: 'duration',
      width: 150,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 220,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'contact', data: item })}>联系方式</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/time-statistics/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cate_id" initialValue={searchParams.cate_id}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={carTimeRequestPath}
                  fileName='车辆工时统计'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/time-statistics/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default CarTimeStatistics;
  