import { message } from "antd";
import NP from 'number-precision';
import { mapUrl, textIconOverlayUrl, markerClustererUrl } from '@/constants/map';
import importScript from "./importScript";

const importError = () => {
  message.error('地图资源加载失败，请刷新重试');
}

// 引入百度地图
export const importMap = async (callback: () => void) => {
  // 百度地图异步加载回调处理
  window.onBMapCallback = () => { callback() };
  const isSuccess = await importScript(mapUrl, { async: false }, window.onBMapCallback);
  if (!isSuccess) {
    importError();
  }
  return isSuccess;
}

// 引入百度地图及点聚合相关资源
export const importLocationMap = async (callback: () => void) => {
  // 百度地图异步加载回调处理
  window.onBMapCallback = async () => { 
    const importTextIcon = await importScript(textIconOverlayUrl, { async: false });
    const importMarkerClusterer = await importScript(markerClustererUrl, { async: false });
    const isSuccess = importTextIcon && importMarkerClusterer;
    if (isSuccess) {
      callback();
    } else {
      importError();
    }
  };
  await importScript(mapUrl, { async: false }, window.onBMapCallback);
}

// 引入高德地图
export const importAliMap = async (callback: () => void) => {
  // 高德地图异步加载回调处理
  window._AMapSecurityConfig = {
    securityJsCode:'f8800993ddca5af26477d06326a0d917',
}
  window.onAliMapCallback = async () => { callback() };

  const isSuccess = await importScript('https://webapi.amap.com/maps?v=2.0&key=222d3c6f3377ec36f4c118b9bc3cd5af&callback=onAliMapCallback', { async: false }, window.onAliMapCallback);
  if (!isSuccess) {
    importError();
  }
  return isSuccess;
}

// 销毁高德地图
export const destroyAliMap = (mapDom: any, map: any) => {
  mapDom
    ?.querySelector('canvas.amap-layer')
    ?.getContext('webgl')
    ?.getExtension('WEBGL_lose_context')
    ?.loseContext();
  map && map.destroy();
};

// 地图初始化
export const initMap = (
  mapDomRef: any,
  mapRef: any,
  mapTypePosition?: 'top' | 'bottom',
  callback?: any
) => {
  mapRef.current = new window.AMap.Map(mapDomRef.current, {
    center: [116.397428, 39.90923],
    zoom: 16,
    animateEnable: false,
    WebGLParams: {
      preserveDrawingBuffer: true
    }
  });
  // MapType的位置样式
  const mapTypeStyle: any = { right: '20px' };
  if (mapTypePosition === 'top') {
    mapTypeStyle.top = '20px';
  } else {
    mapTypeStyle.bottom = '126px';
  }
  // 加载插件
  window.AMap.plugin(
    ['AMap.ToolBar', 'AMap.Scale', 'AMap.MapType'],
    function () {
      mapRef.current.addControl(
        new window.AMap.ToolBar({
          position: {
            bottom: '50px',
            left: '15px'
          }
        })
      );
      mapRef.current.addControl(new window.AMap.Scale());
      mapRef.current.addControl(
        new window.AMap.MapType({
          position: mapTypeStyle
        })
      );
    }
  );
  callback && callback();
};

// 根据地图两个点经纬度获取角度
export const calcLatLngAngle = (map: any, start: any, end: any) => {
  const p_start = map.current.lngLatToContainer(start);
  const p_end = map.current.lngLatToContainer(end);
  const diff_x = p_end.x - p_start.x;
  const diff_y = p_end.y - p_start.y;
  const angle = Math.round(
    (360 * Math.atan2(diff_y, diff_x)) / (2 * Math.PI) + 90
  );
  if (angle < 0) {
    return 360 + angle;
  } else {
    return angle;
  }
};

// 根据一个经纬和距离和角度计算另一个经纬度
export const calcAnotherPoint = (
  lng: number,
  lat: number,
  distance: number,
  angle: number
) => {
  const meterDistance = NP.divide(distance, 1000);
  // 将距离转换成经度的计算公式
  const newLng =
    lng +
    (meterDistance * Math.sin((angle * Math.PI) / 180)) /
      (111 * Math.cos((lat * Math.PI) / 180));
  //将距离转换成纬度的计算公式
  const newLat =
    lat + (meterDistance * Math.cos((angle * Math.PI) / 180)) / 111;
  return [newLng, newLat];
};
