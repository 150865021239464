/**
 * 演示车辆管理
 */
import React, { useState } from 'react';
import { Card, Modal, Popconfirm, message } from 'antd';
import CarTree from '@/components/carTree';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import { getInnerPermissionEnable } from '@/utils/permission';
import {
  getGuestCarRequest,
  addGuestCarRequest,
  deleteGuestCarRequest
} from '@/service/guestCarManage';
import comonaStyles from '@/styles/pageContent.module.scss';
import styles from './index.module.scss';

const GuestCarManage = () => {
  const [selectCarIds, setSelectCarIds] = useState<Array<number>>([]); // 选择的车辆信息(用于添加)
  const [rowSelectCarIds, setRowSelectCarIds] = useState<Array<number>>([]); // 表格选择的车辆信息(用于批量删除)

  // 获取演示车辆数据
  const { data, getData, totalCount, loading } = useTable({
    requestFunc: getGuestCarRequest,
    initPageSize: 40,
    noPageChange: true
  });

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>) => {
    setSelectCarIds(carIds);
  };

  // 添加演示车辆
  const addGuestCar = async () => {
    const res = await addGuestCarRequest({ carIds: selectCarIds });
    if (res.data?.data) {
      message.success('添加成功');
      getData();
    }
  };

  // 删除演示车辆
  const deleteGuestCar = async (carIds: number[]) => {
    const res = await deleteGuestCarRequest({ carIds });
    if (res.data?.data) {
      message.success('删除成功');
      getData();
    }
  };

  // 添加演示车辆的确认框
  const addGuestCarConfirm = () => {
    Modal.confirm({
      title: `您确定要将选择的 ${selectCarIds.length} 辆车辆添加到演示车辆吗？`,
      centered: true,
      onOk: addGuestCar
    });
  };

  // 选择表格行
  const onTableRowSelect = (selectedRowKeys: any) => {
    setRowSelectCarIds(selectedRowKeys);
  };

  // 是否有添加演示车辆的权限
  const hasAddPermission = getInnerPermissionEnable('/car-admin/guest-car/add');
  // 是否有删除演示车辆的权限
  const hasDeletePermission = getInnerPermissionEnable(
    '/car-admin/guest-car/delete'
  );

  const columns = [
    { title: '车牌号', dataIndex: 'plate_number' },
    {
      title: '操作',
      dataIndex: 'id',
      render: (value: number) => (
        <div className={comonaStyles.tableOperateColumn}>
          <Popconfirm
            title="您确定要删除此演示车辆吗?"
            onConfirm={() => deleteGuestCar([value])}
          >
            <PermissionButton
              operateType="/car-admin/guest-car/delete"
              type="link"
            >
              删除
            </PermissionButton>
          </Popconfirm>
        </div>
      )
    }
  ];

  // 没有删除权限时不显示操作列
  if (!hasDeletePermission) {
    columns.pop();
  }

  return (
    <div className={styles.guestCarWrapper}>
      {/* 有添加演示车辆权限时才显示 */}
      {hasAddPermission && (
        <Card
          bordered
          className={comonaStyles.fixPageContentBox}
          style={{ flexShrink: 0, width: '40%' }}
        >
          <CarTree checkable treeCheck={treeCheck} searchAlive />
        </Card>
      )}
      <PermissionButton
        type="primary"
        disabled={!selectCarIds.length}
        onClick={addGuestCarConfirm}
        operateType="/car-admin/guest-car/add"
      >
        添加到演示车辆
      </PermissionButton>
      <Card bordered className={comonaStyles.fixPageContentBox}>
        <CommonTable
          tableTitle={
            <div>
              {Boolean(rowSelectCarIds?.length) && (
                <Popconfirm
                  title={`您确定要删除选中的 ${rowSelectCarIds?.length} 辆演示车辆吗?`}
                  onConfirm={() => deleteGuestCar(rowSelectCarIds)}
                >
                  <PermissionButton
                    operateType="/car-admin/guest-car/delete"
                    type="primary"
                    size="small"
                  >
                    删除选中车辆
                  </PermissionButton>
                </Popconfirm>
              )}
              {`共${totalCount}辆车`}
            </div>
          }
          dataSource={data}
          bordered
          columns={columns}
          columnFilter={false}
          pagination={false}
          loading={loading}
          rowSelection={
            hasDeletePermission
              ? {
                  onChange: onTableRowSelect,
                  selectedRowKeys: rowSelectCarIds
                }
              : undefined
          }
          rowKey="id"
        />
      </Card>
    </div>
  );
};

export default GuestCarManage;
