/**
 * 车辆档案
 */
import React, { useState, useRef } from 'react';
import { UploadOutlined, EyeOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Input,
  message,
  Image,
  Timeline,
  Descriptions,
  Upload,
  Tag,
  Empty,
  Popconfirm,
  Tooltip,
} from 'antd';
import ExportButton from '@/components/exportButton';
import LoadingWrapper from '@/components/loadingWrapper';
import CarListModal from '@/components/carListModal';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import PermissionButton from '@/components/permissionButton';
import OperateModal from '@/pages/accidentRecord/components/operateModal';
import OperateMaintenance from '@/pages/carDiagnosis/components/operateMaintenance';
import OperateUnderwriting from '@/pages/underwritingRecord/components/operateModal';
import localStorageUtil from '@/utils/localStorageUtil';
import { downloadFile } from '@/utils/download';
import { formatTime } from '@/utils/formatTime';
import { carListRequest, drivingLicenseContractRequest } from '@/service/carManage';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { carArchiveRequest, carArchiveRequestPath, carArchiveTagsRequest } from '@/service/carArchive';
import { upsertCarRequest, carDetailRequest } from '@/service/carManage';
import { ArchiveTypeEnum, archiveTypeTitleObj } from '@/constants/carArchive';
import MaintenanceItem from '@/pages/carDiagnosis/components/maintenanceItem';
import styles from './index.module.scss';
import { carType } from '@/constants/deviceManage';

const { CheckableTag } = Tag;
const CarArchive = () => {
  const cateIdArr = useUserCateIdArr();
  const uploadContractCountRef = useRef(0); // 批量上传合同完毕的数量
  const uploadContractSuccessListRef = useRef<any>([]); // 上传成功的合同信息
  const [carInfo, setCarInfo] = useState<any>({}); // 搜索的车辆信息
  const [loading, setLoading] = useState(false); // 接口请求loading
  const [hasSearch, setHasSearch] = useState(false); // 是否搜索过
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关信息
  const [tagData, setTagData] = useState<any>([]); // 标签数据
  const [archiveData, setArchiveData] = useState<any>([]); // 档案数据
  const [uploadLoading, setUploadLoading] = useState<boolean>(false); // 行驶证是否上传中
  const [contractUploadLoading, setContractUploadLoading] = useState<boolean>(false); // 合同是否上传中
  const [searchValues, setSearchValues] = useState<any>(null); // 搜索到多辆车时的搜索参数
  const [selectedTags, setSelectedTags] = useState<any>([]); // 选中的筛选tag
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [form] = Form.useForm();

  // 获取搜索匹配的车辆
  const getMatchCar = async (params: any) => {
    const newParams = {
      page: 1,
      limit: 10,
      plateNumber: params.plateNumber,
      sn: params.sn,
      frameNumber: params.frameNumber,
      cate_id: cateIdArr
     };
    const res = await carListRequest(newParams);
    const matchCar = res.data?.data || [];
    if (matchCar.length === 0) {
      message.warning("车辆不存在");
      setCarInfo({});
      uploadContractSuccessListRef.current = [];
    } else if (matchCar.length === 1) {
      // 只匹配到一辆车时获取该车信息
      carClick(matchCar[0]);
    } else{
      // 获取到多辆车时显示车辆列表进行选择
      setSearchValues(params);
    }
  }

  // 根据匹配车辆的车辆id进行搜索
  const carClick = (data: any) => {
    setHasSearch(true);
    setCarInfo(data);
    uploadContractSuccessListRef.current = data.contractPdfs || [];
    setSelectedTags([]);
    getArchiveData({ carId: data.carId });
    getArchiveTagsData({ carId: data.carId });
  }

  // 获取档案数据
  const getArchiveData = async (params: any) => {
    try {
      setLoading(true);
      const res = await carArchiveRequest(params);
      setArchiveData(res.data?.data || [])
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 获取档案标签
  const getArchiveTagsData = async (params: any) => {
    const res = await carArchiveTagsRequest(params);
    setTagData(res.data?.data || []);
  }

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.plateNumber = (params.plateNumber || '').trim();
    newParams.sn = (params.sn || '').trim();
    newParams.frameNumber = (params.frameNumber || '').trim();
    if (isExport) {
      newParams.export = true;
    }
    if (newParams.plateNumber || newParams.sn || newParams.frameNumber) {
      return newParams;
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    if (newSearchParams) {
      getMatchCar(newSearchParams);
    } else {
      message.warning('请输入车牌号或设备SN或车架号进行搜索');
    }
  };

  // 获取导出的参数
  const getExportParams = async () => {
    try {
      const values = await form.validateFields();
      const exportParams = operateSearchParams(values, true);
      if (exportParams) {
        return {
          carId: carInfo.id,
          queryTagId: selectedTags,
          export: true,
        };
      } else {
        message.warning('请输入车牌号或设备SN或车架号进行导出');
      }
    } catch (error) {
    }
  };

  // 展示车辆基本信息
  const renderCarInfo = (key: string) => {
    if (key == 'carType') {
      return (
        <Tooltip placement="topLeft" title={carType(carInfo[key])}>{carType(carInfo[key])}</Tooltip>
      )
    }
    const value = carInfo[key];
    if (!value) {
      return '-';
    }
    return (
      <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
    )
  }

  // 重新获取车辆信息
  const reloadCarInfo = async() => {
    const carDetail = await carDetailRequest(carInfo.id);
    const carDetailData = carDetail.data?.data || {};
    setCarInfo(carDetailData);
    uploadContractSuccessListRef.current = carDetailData.contractPdfs || [];
  }

  // 上传前限制图片大小
  const beforeUpload = (file: any) => {
    const isLt20M = file.size / 1024 / 1024 < 20;
    if (!isLt20M) {
      message.error('请上传20M以内图片');
    }
    const isLtType = file.type.startsWith('image');
    if (!isLtType) {
      message.error('请上传图片');
    }
    return isLt20M && isLtType;
  };

  // 合同上传前校验文件类型
  const beforeContractUpload = (file: any) => {
    const suffix = file.name.substr(file.name.lastIndexOf("."));
    const isLtType = suffix === '.pdf';
    if (!isLtType) {
      message.error('请上传PDF格式文件');
    }
    return isLtType;
  };

  // 上传行驶证
  const onUploadChange = async (info: any) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
    }
    if (info.file.status === 'error') {
      setUploadLoading(false);
      message.error('上传失败');
    }
    if (info.file.status === 'done') {
      // 上传成功之后更新车辆信息
      if (info?.file?.response?.code === 0) {
        const params: any = { id: carInfo.id };
        const resourceKey = info?.file?.response?.data?.key;
        params.drivingLicense = resourceKey;
        const res = await drivingLicenseContractRequest(params);
        if (res.data?.code === 0) {
          message.success('上传成功');
          setUploadLoading(false);
          reloadCarInfo();
        }
      }
    }
  }

  // 上传合同改变的方法
  const onContractUploadChange = async (info: any) => {
    if (info.file.status === 'uploading') {
      setContractUploadLoading(true);
    }
    if (info.file.status === 'error') {
      uploadContractCountRef.current += 1;
      if (uploadContractCountRef.current === (info.fileList || []).length) {
        setContractUploadLoading(false);
      }
      message.error('上传失败');
    }
    if (info.file.status === 'done') {
      uploadContractCountRef.current += 1;
      const code = info.file.response?.code;
      if (code === 0) {
        const resData = info.file.response.data;
        uploadContractSuccessListRef.current = [{ fileName: resData.fileName, key: resData.key }, ...uploadContractSuccessListRef.current];
        if (uploadContractCountRef.current === (info.fileList || []).length) {
          const params: any = { id: carInfo.id };
          params.contractPdf = JSON.stringify(uploadContractSuccessListRef.current);
          const res = await drivingLicenseContractRequest(params);
          if (res.data?.code === 0) {
            message.success('上传成功');
            setContractUploadLoading(false);
            reloadCarInfo();
          }
        }
      } else {
        message.error(info.file.response?.msg || '上传失败');
      }
    }
  }

  // 删除pdf
  const deletePdf = async (deleteIndex: number) => {
    const newUploadSuccessList = uploadContractSuccessListRef.current.filter((item: any, index: number) => index !== deleteIndex);
    uploadContractSuccessListRef.current = newUploadSuccessList;
    const params: any = { id: carInfo.id };
    params.contractPdf = JSON.stringify(newUploadSuccessList);
    const res = await drivingLicenseContractRequest(params);
    if (res.data?.code === 0) {
      message.success('删除成功');
      reloadCarInfo();
    }
  }

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    }); 
  }

  // 标签筛选
  const onTagFilter = (tagId: string, checked: boolean) => {
    let newSelectedTags = []
    if (checked) {
      newSelectedTags = [...selectedTags, tagId];
    } else {
      newSelectedTags = selectedTags.filter((item: any) => item !== tagId);
    }
    setSelectedTags(newSelectedTags);
    getArchiveData({
      carId: carInfo.id,
      queryTagId: newSelectedTags,
    })
  }

  // 操作之后重载数据
  const reloadData = () => {
    getArchiveData({ carId: carInfo.id, queryTagId: selectedTags });
    getArchiveTagsData({ carId: carInfo.id });
  }

  // 渲染时间轴的title(显示时间和记录类型)
  const renderTimelineTitle = (data: any) => {
    const carArchiveType = data.carArchiveType;
    let time = data.time;
    if (carArchiveType === ArchiveTypeEnum.install) {
      const carInfoRecord = (data.events || []).find((item: any) => item.type === 'carInfo');
      time = carInfoRecord?.time;
    }
    return `${formatTime(time)} (${archiveTypeTitleObj[carArchiveType]})`;
  }

  // 展示的车辆信息
  const showCarInfo = [
    { title: '车牌号', key: 'plate_number', span: 8 },
    { title: '车辆类型', key: 'carType', span: 8 },
    { title: '设备SN', key: 'sn', span: 8 },
    { title: '相机SN', key: 'camera_sn', span: 8 },
    { title: 'SIM卡号', key: 'sim', span: 8 },
    { title: '分组', key: 'cate_path_name', span: 16 },
    { title: '车辆品牌', key: 'car_brand', span: 8 },
    { title: '车辆型号', key: 'car_model', span: 8 },
    { title: '车架号', key: 'frame_number', span: 8 },
    { title: '安装日期', key: 'installTime', span: 8 },
    { title: '负责人', key: 'car_principal', span: 8 },
    { title: '负责人电话', key: 'car_principal_phone', span: 8 },
    { title: '司机', key: 'car_driver', span: 8 },
    { title: '司机电话', key: 'car_driver_phone', span: 8 },
    { title: '参数信息', key: 'paramInfo', span: 8 },
    { title: '版本信息', key: 'versionInfo', span: 8 },
  ];

  return (
    <Card bordered={false} className={styles.carArchive}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col lg={5} xs={14}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
            >
              <Input placeholder='请输入车牌号'  />
            </Form.Item>
          </Col>
          <Col lg={5} xs={14}>
            <Form.Item label="设备SN" name="sn">
              <Input placeholder='请输入设备SN' />
            </Form.Item>
          </Col>
          <Col lg={6} xs={14}>
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder='请输入车架号' />
            </Form.Item>
          </Col>
          <Col lg={8} xs={14}>
            <Form.Item label=" " shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">搜索</Button>
                  <ExportButton
                    disabled={!carInfo.id}
                    url={carArchiveRequestPath}
                    fileName={`${carInfo.plate_number || form.getFieldsValue().plateNumber}车辆档案`}
                    getParamsFunc={getExportParams}
                    requestMethod="GET"
                    permissionKey='/car-admin/car-archive/export'
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <div style={{ visibility: hasSearch ? 'visible' : 'hidden' }}>
          <Row>
            <Col span={12} className={styles.carInfoBox}>
              <Row wrap>
                {showCarInfo.map((item: any) => (
                  <Col span={item.span} key={item.title}>
                    <div className={styles.carInfoTitle}>{item.title}</div>
                    <div className={styles.carInfoContent}>{renderCarInfo(item.key)}</div>
                  </Col>
                ))}
              </Row>
            </Col>
            {/* 行驶证信息 */}
            <Col span={6} className={styles.uploadBox}>
              {carInfo?.drivingLicense ?
                <div className={styles.thingBox}>
                  <Image
                    width='100%'
                    height="100%"
                    src={carInfo?.drivingLicense}
                  />
                </div> :
                <div className={styles.emptyBox}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无行驶证信息" />
                </div>
              }
              <Upload
                accept="image/*"
                action="/api/v2/resource/upload"
                headers={{ access_token: localStorageUtil.getItem('access_token') }}
                beforeUpload={beforeUpload}
                onChange={onUploadChange}
                className={styles.uploadButton}
              >
                <PermissionButton
                  operateType='/car-admin/car-archive/drivingLicenseUpload'
                  className={styles.uploadButton}
                  loading={uploadLoading}
                  disabled={uploadLoading}
                  type="dashed"
                  icon={<UploadOutlined />}
                >
                  上传行驶证
                </PermissionButton>
              </Upload>
            </Col>
            {/* 合同信息 */}
            <Col span={6} className={styles.uploadBox}>
              {carInfo?.contractPdfs?.length ?
                  <div className={styles.thingBox}>
                  {carInfo?.contractPdfs.map((item: any, index: number) => (
                    <div className={styles.contractItem}>
                      <div className={styles.contractName}>{item.fileName}</div>
                      <div className={styles.contractIcon}>
                        <PermissionButton
                          operateType='/car-admin/car-archive/contractPreview'
                          type="text"
                          onClick={() => onPdfPreview(item.url, item.fileName)}
                        >
                          <EyeOutlined />
                        </PermissionButton>
                        <PermissionButton
                          operateType='/car-admin/car-archive/contractDownload'
                          type="link"
                          onClick={() => downloadFile(item.url, item.fileName)}
                          style={{ paddingLeft: 0 }}
                        >
                          <DownloadOutlined />
                        </PermissionButton>
                        <Popconfirm
                          title="此操作将删除该合同, 是否继续?"
                          onConfirm={() => deletePdf(index)}
                        >
                          <PermissionButton
                            operateType='/car-admin/car-archive/contractDelete'
                            type="link"
                            style={{ paddingLeft: 0 }}
                          >
                            <DeleteOutlined style={{ color: 'red' }} />
                          </PermissionButton>
                        </Popconfirm>
                      </div>
                    </div>
                  ))}
                </div> :
                <div className={styles.emptyBox}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无合同信息" />
                </div>
              }
              <Upload
                accept="application/pdf"
                action="/api/v2/resource/upload"
                headers={{ access_token: localStorageUtil.getItem('access_token') }}
                beforeUpload={beforeContractUpload}
                onChange={onContractUploadChange}
                className={styles.uploadButton}
                multiple
              >
                <PermissionButton
                  operateType='/car-admin/car-archive/contractUpload'
                  loading={contractUploadLoading}
                  disabled={contractUploadLoading}
                  type="dashed"
                  icon={<UploadOutlined />}
                >
                  上传合同
                </PermissionButton>
              </Upload>
            </Col>
          </Row>
          <Form style={{ marginTop: '20px' }}>
            <Form.Item label="新增记录">
              <div className={styles.searchBox}>
                {/* <Button>派单记录</Button>
                <Button>沟通记录</Button> */}
                <PermissionButton
                  operateType='/car-admin/car-archive/maintenanceAdd'
                  onClick={() => setOperateInfo({ type: 'maintenance' })}
                >
                  维保记录
                </PermissionButton>
                <PermissionButton
                  operateType='/car-admin/car-archive/accidentAdd'
                  onClick={() => setOperateInfo({ type: 'accident' })}
                >
                  出险记录
                </PermissionButton>
                <PermissionButton
                  operateType='/car-admin/car-archive/underwritingAdd'
                  onClick={() => setOperateInfo({ type: 'underwriting' })}
                >
                  承保记录
                </PermissionButton>
             </div>
            </Form.Item>
            {Boolean(tagData?.length) && <Form.Item label="标签筛选">
              {tagData.map((item: any) => (
                <CheckableTag
                  key={item.title}
                  checked={selectedTags.includes(item.tagId)}
                  onChange={checked => onTagFilter(item.tagId, checked)}
                >
                  {`${item.title}(${item.count})`}
                </CheckableTag>
              ))}
            </Form.Item>}
          </Form>
          <Row>
            <Timeline>
              {archiveData.map((item: any) => {
                const installInfo = JSON.parse((item.events || []).find((event: any) => event.type === 'carInfo')?.data || '{}');
                return (
                  <Timeline.Item>
                    <div>{renderTimelineTitle(item)}</div>
                    {/* 维保记录 */}
                    {item.carArchiveType === ArchiveTypeEnum.maintenance && item.mainId &&
                      <MaintenanceItem column={{ xs: 1, sm: 2, lg: 4 }} data={item} type="maintenance" />
                    }
                    {/* 维保记录(通话记录) */}
                    {item.carArchiveType === ArchiveTypeEnum.maintenance && !item.mainId &&
                      <MaintenanceItem column={{ xs: 1, sm: 2, lg: 4 }} data={item} type="callRecord" />
                    }
                    {/* 出险记录 */}
                    {item.carArchiveType === ArchiveTypeEnum.accident &&
                      <Descriptions bordered size="small" column={{ xs: 1, sm: 2, lg: 4 }}>
                        <Descriptions.Item label="报案号">{item.caseNumber}</Descriptions.Item>
                        <Descriptions.Item label="被保险人">{item.insurer}</Descriptions.Item>
                        <Descriptions.Item label="出险时间">{formatTime(item.insureDateTime)}</Descriptions.Item>
                        <Descriptions.Item label="出险金额">{item.claimAmount}</Descriptions.Item>
                        <Descriptions.Item label="事故类型">{item.accidentTypeStr}</Descriptions.Item>
                        <Descriptions.Item label="碰撞方位">{item.accidentCollisionLocationStr}</Descriptions.Item>
                        <Descriptions.Item label="事故分类">{item.accidentClassificationStr}</Descriptions.Item>
                        <Descriptions.Item label="事故标签">
                          {(Object.values(item.tags || {})[0] as any || []).map(((item1: any) => <Tag key={item1.id}>{item1.title}</Tag> ))}
                        </Descriptions.Item>
                        <Descriptions.Item label="承保机构">{item.channel}</Descriptions.Item>
                        <Descriptions.Item label="所属车队">{item.motorcade}</Descriptions.Item>
                        <Descriptions.Item label="备注">{item.ps}</Descriptions.Item>
                      </Descriptions>
                    }
                    {item.carArchiveType === ArchiveTypeEnum.underwriting &&
                      <Descriptions bordered size="small" column={{ xs: 1, sm: 2, lg: 4 }}>
                        <Descriptions.Item label="交强险保单号">{item.compulsoryNumber}</Descriptions.Item>
                        <Descriptions.Item label="交强险承保保险公司">{item.compulsoryCompany}</Descriptions.Item>
                        <Descriptions.Item label="交强险起始时间">{item.compulsoryBeginDate}</Descriptions.Item>
                        <Descriptions.Item label="交强险结束时间">{item.compulsoryEndDate}</Descriptions.Item>
                        <Descriptions.Item label="商业险保单号">{item.commercialNumber}</Descriptions.Item>
                        <Descriptions.Item label="商业险承保保险公司">{item.commercialCompany}</Descriptions.Item>
                        <Descriptions.Item label="商业险起始时间">{item.commercialBeginDate}</Descriptions.Item>
                        <Descriptions.Item label="商业险结束时间">{item.commercialEndDate}</Descriptions.Item>
                        <Descriptions.Item label="续保次数">{item.count}</Descriptions.Item>
                      </Descriptions>
                    }
                    {item.carArchiveType === ArchiveTypeEnum.install &&
                      <Descriptions bordered size="small" column={{ xs: 1, sm: 2, lg: 4 }}>
                        <Descriptions.Item label="车牌号">{installInfo.plateNumber}</Descriptions.Item>
                        <Descriptions.Item label="设备SN">{installInfo.sn}</Descriptions.Item>
                        <Descriptions.Item label="相机SN">{installInfo.cameraSn}</Descriptions.Item>
                        <Descriptions.Item label="SIM卡号">{installInfo.sim}</Descriptions.Item>
                        <Descriptions.Item label="负责人">{installInfo.carPrincipal}</Descriptions.Item>
                        <Descriptions.Item label="负责人电话">{installInfo.carPrincipalPhone}</Descriptions.Item>
                        <Descriptions.Item label="司机">{installInfo.carDriver}</Descriptions.Item>
                        <Descriptions.Item label="司机电话">{installInfo.carDriverPhone}</Descriptions.Item>
                        <Descriptions.Item label="安装队长">{installInfo.installCaptain}</Descriptions.Item>
                        <Descriptions.Item label="安装地址">{installInfo.installAddress}</Descriptions.Item>
                      </Descriptions>
                    }
                  </Timeline.Item>
                )
              })}
            </Timeline>
          </Row>
        </div>
      </LoadingWrapper>
      {/* 事故记录操作弹窗 */}
      {operateInfo.type === 'accident' &&
        <OperateModal
          data={carInfo}
          type={"add"}
          closeModal={() => setOperateInfo({})}
          reloadData={reloadData}
        />
      }
      {/* 维保记录操作弹窗 */}
      {operateInfo.type === 'maintenance' &&
        <OperateMaintenance
          carInfo={carInfo}
          onClose={() => setOperateInfo({})}
          reloadData={reloadData}
        />
      }
      {/* 承保记录操作弹窗 */}
      {operateInfo.type === 'underwriting' &&
        <OperateUnderwriting
          type="add"
          closeModal={() => setOperateInfo({})}
          reloadData={reloadData}
        />
      }
      {searchValues && <CarListModal
        desc="当前搜索匹配到多辆车辆，点击车牌可进行精准搜索"
        closeModal={() => setSearchValues(null)}
        searchValues={searchValues}
        plateNumberClick={(data: any) => carClick(data)}
      />}
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Card>
  )
}

export default CarArchive;