// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","atShowBoxTitle":"atCommand_atShowBoxTitle__jSlOv","atShowBox":"atCommand_atShowBox__KkFbg","chatBox":"atCommand_chatBox__EJjMv","chatItem":"atCommand_chatItem__N27iv","chat":"atCommand_chat__vjlHI","commandBox":"atCommand_commandBox__yYpS1","tableAtName":"atCommand_tableAtName__fAX8U","atSendForm":"atCommand_atSendForm__j9NEk","buttomFormItem":"atCommand_buttomFormItem__+P3RG","formContent":"atCommand_formContent__yCBZd","tip":"atCommand_tip__jony5"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"atShowBoxTitle\":\"atCommand_atShowBoxTitle__jSlOv\",\"atShowBox\":\"atCommand_atShowBox__KkFbg\",\"chatBox\":\"atCommand_chatBox__EJjMv\",\"chatItem\":\"atCommand_chatItem__N27iv\",\"chat\":\"atCommand_chat__vjlHI\",\"commandBox\":\"atCommand_commandBox__yYpS1\",\"tableAtName\":\"atCommand_tableAtName__fAX8U\",\"atSendForm\":\"atCommand_atSendForm__j9NEk\",\"buttomFormItem\":\"atCommand_buttomFormItem__+P3RG\",\"formContent\":\"atCommand_formContent__yCBZd\",\"tip\":\"atCommand_tip__jony5\"}";
        // 1726639067799
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  