/**
 * 部标机报警表格
 */
import React, { useMemo } from 'react';
import CommonTable from '@/components/commonTable';
import { jtDeviceAlarmColumns } from '@/columns/jtDeviceAlarm';

interface JtDeviceAlarmTableProps {
  dataSource: any;
}
const JtDeviceAlarmTable = (props: JtDeviceAlarmTableProps) => {
  const { dataSource } = props;

  // 表格列配置(不显示操作列)
  const columns = useMemo(() => {
    return jtDeviceAlarmColumns.slice(0, jtDeviceAlarmColumns.length - 1);
  }, []);

  return (
    <CommonTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      size="small"
      columnFilter={false}
    />
  );
};

export default JtDeviceAlarmTable;
