/**
 * 固件已绑定车辆列表弹窗
 */
import React, { useState } from 'react';
import { Modal, Tag, message } from 'antd';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { firmwareType } from '@/constants/firmwareManage';
import {
  firmwareBindListRequest,
  firmwareBindListRequestPath,
  firmwareBindDeleteRequest
} from '@/service/firmwareManage';
import styles from '@/styles/pageContent.module.scss';

const { confirm } = Modal;
interface FirmwareBindListModalProps {
  closeModal: () => void;
  id: string;
}
const FirmwareBindListModal = (props: FirmwareBindListModalProps) => {
  const [bindSelectKeys, setBindSelectKeys] = useState([]); // 固件绑定车辆选择的值
  const { closeModal, id } = props;
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: firmwareBindListRequest,
      initRequest: true,
      initPageSize: 30,
      extraParams: { id }
    });

  // 删除固件绑定的车辆
  const deleteFirmwareBind = async () => {
    const res = await firmwareBindDeleteRequest({ ids: bindSelectKeys });
    if (res.data?.code === 0 && res.data?.data) {
      message.success('删除成功');
      getData && getData();
      setBindSelectKeys([]);
    } else {
      message.error('删除失败');
    }
  };

  // 删除固件绑定车辆的弹窗
  const deleteFirmwareBindConfirm = () => {
    confirm({
      title: '确定删除吗？',
      onOk: deleteFirmwareBind,
      centered: true
    });
  };

  // 渲染固件类型列
  const renderTypeColumn = (value: string) => {
    const matchType = firmwareType.find((item: any) => item.value === value);
    return <Tag color="blue">{matchType?.label || value || '未知类型'}</Tag>;
  };

  // 渲染任务状态列
  const renderStatusColumn = (value: string) => {
    const statusObj: any = {
      0: '未下发',
      1: '已下发',
      2: '升级成功',
      3: '升级失败'
    };
    return <Tag color="blue">{statusObj[value] || '未知状态'}</Tag>;
  };

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70
    },
    {
      title: '设备SN号',
      dataIndex: 'sn',
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '固件类型',
      dataIndex: 'firmwareType',
      render: (value: string) => renderTypeColumn(value)
    },
    {
      title: '版本号',
      dataIndex: 'firmwareVersion',
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      render: (value: string) => renderStatusColumn(value)
    }
  ];

  return (
    <Modal
      open
      title="已绑定设备"
      footer={null}
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'hidden', display: 'flex' }}
      width="60%"
      centered
      maskClosable={false}
    >
      <div style={{ flex: 1 }}>
        <div className={styles.commonTableWrapper}>
          <div className={styles.operateBox}>
            <PermissionButton
              operateType="/car-admin/firmware-manage/delete-bind"
              type="primary"
              onClick={deleteFirmwareBindConfirm}
              disabled={bindSelectKeys.length === 0}
            >
              删除
            </PermissionButton>
            <ExportButton
              type="primary"
              url={firmwareBindListRequestPath}
              fileName="已绑定设备"
              getParamsFunc={() => ({
                id,
                export: true
              })}
              requestMethod="GET"
              permissionKey="/car-admin/firmware-manage/export-bind"
            />
          </div>
          <CommonTable
            rowKey="id"
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalCount,
              current: page,
              pageSize: limit,
              onChange: onPageChange
            }}
            bordered
            rowSelection={{
              selectedRowKeys: bindSelectKeys,
              onChange: (keys: any) => setBindSelectKeys(keys),
              fixed: true
            }}
            size="small"
            columnFilter={false}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FirmwareBindListModal;
