/**
 * 异常车辆汇总相关请求
 */
import request from "@/utils/request";

// 获取异常车辆汇总数据
export const abnormalSummaryRequest = (data: any) => {
  return request.post('/api/dataAnalyze/errCollect', data)
}

// 获取昆仑山设备异常车辆汇总数据
export const klsAbnormalSummaryRequest = (data: any) => {
  return request.post('/api/dataAnalyze/klsErrCollect', data)
}

// 获取adas设备异常车辆汇总数据
export const adasAbnormalSummaryRequest = (data: any) => {
  return request.post('/api/dataAnalyze/adasErrCollect', data)
}

// 获取车辆运行状况
export const deviceAggRequestPath = '/api/v2/car/deviceAgg';
export const deviceAggRequest = (data: any) => {
  return request.post(deviceAggRequestPath, data)
}

// 获取adas车辆运行状况
export const deviceAdasAggRequestPath = '/api/v2/adasDayStatistic/adasDeviceAgg';
export const deviceAdasAggRequest = (data: any) => {
  return request.post(deviceAdasAggRequestPath, data)
}

// 异常指标去重
export const errCollectGroupDayRequest = (data: any) => {
  return request.post('/api/dataAnalyze/errCollectGroupDay', data)
}

// 昆仑山设备异常指标去重
export const klsErrCollectGroupDayRequest = (data: any) => {
  return request.post('/api/dataAnalyze/klsErrCollectGroupDay', data)
}

// 车辆合计异常指标去重
export const errCollectDistinctRequest = (data: any) => {
  return request.post('/api/dataAnalyze/errorCollectDistinct', data)
}

// 昆仑山设备车辆合计异常指标去重
export const klsErrCollectDistinctRequest = (data: any) => {
  return request.post('/api/dataAnalyze/klsErrorCollectDistinct', data)
}