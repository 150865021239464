/**
 * 车辆详情的表格配置
 */
import React from 'react';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';

// 设备sn列
export const snColumn = {
  title: '设备SN',
  dataIndex: 'sn',
  width: 90,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />
};

// 相机sn列
export const cameraSnColumn = {
  title: '相机SN',
  dataIndex: 'camera_sn',
  width: 90,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />
};

// 分组列
export const cateGroupColumn = {
  title: '分组',
  dataIndex: 'cate_path_name',
  width: 130,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />
};

// 车架号列
export const frameNumberColumn = {
  title: '车架号',
  dataIndex: 'frame_number',
  width: 100,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />
};

// 车辆品牌列
export const carBrandColumn = {
  title: '车辆品牌',
  dataIndex: 'car_brand',
  width: 100,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />
};

// 车辆型号列
export const carModelColumn = {
  title: '车辆型号',
  dataIndex: 'car_model',
  width: 100,
  ellipsis: { showTitle: false },
  render: (value: string) => <RenderEllipsisColumn text={value} />
};
