/**
 * 客服记录匹配多辆车时手动选择匹配的车辆
 */
import React, { useEffect, useState } from 'react';
import { Modal, message } from 'antd';
import CommonTable from '@/components/commonTable';
import { updateCallRecordRequest, callRecordMatchRequest, CallRecordStatusEnum } from '@/service/callRecord';
import styles from '@/styles/pageContent.module.scss';

interface MaintainModalProps {
  successCallback: () => void;
  closeModal: () => void;
  data: any;
}
const MatchCarModal = (props: MaintainModalProps) => {
  const { successCallback, closeModal, data } = props;
  const [callRecordMatchCars, setCallRecordMatchCars] = useState([]); // 通话记录匹配的车辆数据
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // 表格的选择项
  const [loading, setLoading] = useState(false); // 是否在请求表格数据

  // 获取客服记录匹配的车辆
  const getCallRecordMatch = async() => {
    try {
      setLoading(true);
      const res = await callRecordMatchRequest({ callNumber: data?.callNumber });
      setLoading(false);
      setCallRecordMatchCars(res.data?.data || []);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCallRecordMatch();
  }, []);

  // 表格选择
  const onTableRowChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  // 更新客服记录状态
  const updateCallRecord = async() => {
    const res = await updateCallRecordRequest({
      id: data.id,
      status: CallRecordStatusEnum.match,
      carId: selectedRowKeys[0],
    });
     if(res?.data?.data) {
       message.success('维护成功');
       closeModal();
       successCallback();
     }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 120,
    },
    {
      title: '运维组',
      dataIndex: 'cateOm',
      width: 120,
    },
  ];

  return (
    <Modal
      title="客服记录维护"
      visible
      onCancel={closeModal}
      onOk={updateCallRecord}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      okButtonProps={{
        disabled: !selectedRowKeys.length,
      }}
      centered
    >
      <div className={styles.operateBox}>
        可勾选表格中车辆进行手动匹配
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={callRecordMatchCars}
        pagination={false}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: '50vh' }}
        size="small"
        rowSelection={{
          onChange: onTableRowChange,
          type: 'radio',
          selectedRowKeys,
        }}
      />
    </Modal>
  )
}

export default MatchCarModal;
    