/**
 * 单条问卷调查
 */
import { Descriptions } from "antd";

interface MaintenanceItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const SurveyItem = (props: MaintenanceItemProps) => {
  const { column, data } = props;

  // 是否
  const whether = (value:any) => {
    if (value == 0) {
      return '否'
    } else if (value == 1) {
      return '是'
    }
  }

  // 满意度
  const satisfaction = (value:any) => {
    if (value == 1) {
      return '非常不满意'
    } else if (value == 2) {
      return '不满意'
    } else if (value == 3) {
      return '一般'
    } else if (value == 4) {
      return '满意'
    } else if (value == 5) {
      return '非常满意'
    }
  }

  return (
    <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="所属公司">{data.company}</Descriptions.Item>
      <Descriptions.Item label="回访日期">{data.revisitDate}</Descriptions.Item>
      <Descriptions.Item label="安装人员">{data.maintainer}</Descriptions.Item>
      <Descriptions.Item label="是否电话联系">{whether(data.telFlag)}</Descriptions.Item>
      <Descriptions.Item label="是否按照要求到达现场">{whether(data.expressFlag)}</Descriptions.Item>
      <Descriptions.Item label="是否清晰流畅讲解设备">{whether(data.reachFlag)}</Descriptions.Item>
      <Descriptions.Item label="是否还有不清楚的地方">{whether(data.unclearFlag)}</Descriptions.Item>
      <Descriptions.Item label="服务评价">{satisfaction(data.total)}</Descriptions.Item>
      <Descriptions.Item label="建议或者意见" span={2}>{data.suggest}</Descriptions.Item>
    </Descriptions>
  )
  
}

export default SurveyItem;