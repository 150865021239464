/**
 * 工单相关方法
 */
import { workOrderTypeData, workOrderStatusData, WorkOrderStatusEnum } from '@/constants/workOrder';

// 渲染工单类型列
export const renderTypeColumns = (value: number) => {
  const matchType = workOrderTypeData.find((item: any) => item.value === value);
  return matchType?.label;
}

// 渲染工单状态列
export const renderStatusColumns = (value: number) => {
  const matchStatus = workOrderStatusData.find((item: any) => item.value === value);
  return <span className={matchStatus?.color}>{matchStatus?.label}</span>;
}

// 渲染是否逾期列
export const renderDelayColumns = (value: boolean, item: any) => {
  // 只有已分派/待处理/正在处理的工单才显示是否逾期
  if ([WorkOrderStatusEnum.assigned, WorkOrderStatusEnum.pending, WorkOrderStatusEnum.progress].includes(item.stat)) {
    return (
      value ? <span className='dangerText'>是</span> : <span>否</span>
    );
  } else {
    return '-';
  }
}