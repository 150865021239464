/**
 * 问卷调查记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { questionnaireSurveyListRequest, DeletequestionnaireSurvey } from '@/service/questionnaireSurvey';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import SurveyItem from '../surveyItem';
import styles from './index.module.scss';
import OperateModalTask from '../../../questionnaireSurvey/components/operateModal';

interface MaintenanceRecordProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭客服记录弹层的方法
}
const SurveyRecoed = (props: MaintenanceRecordProps) => {
  const { carInfo: { plate_number }, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 客服记录数据
  const [loading, setLoading] = useState<boolean>(false) // 客服记录数据是否在请求中
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息

  
  useEffect(() => {
    getMaintenancesRecord(1);
  }, []);

  // 获取客服记录
  const getMaintenancesRecord = async(newPage: number) => {
    try {
      setLoading(true);
      const res = await questionnaireSurveyListRequest({ plateNumber: plate_number, page: newPage, limit:10 });
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  // 加载更多
  const loadMore = () => {
    getMaintenancesRecord(page + 1);
  }

  // 删除客服记录
  const deleteRecord = async (id: number) => {
    const res = await DeletequestionnaireSurvey(id);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getMaintenancesRecord(1);
    }
  }

  return (
    <Drawer
      title={`${plate_number}问卷调查记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <PermissionButton
        operateType='/car-admin/car-diagnosis/survey-recoed-add'
        type="primary"
        style={{ marginBottom: '16px' }}
        onClick={() => setOperateInfo({ type: 'carDiagnosisAdd' })}
      >
        新增问卷调查
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any, index: any) => {
            return (
              <Timeline.Item key={index}>
                <div>
                  {item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}
                  <Popconfirm
                    title="此操作将删除该条问卷调查记录, 是否继续?"
                    onConfirm={() => deleteRecord(item.id)}
                  >
                    <PermissionButton
                      operateType='/car-admin/car-diagnosis/survey-recoed-delete'
                      type="link"
                      style={{ padding: '0 0 0 6px', height: '26px' }}
                    >
                      <DeleteOutlined style={{ color: 'red' }} />
                    </PermissionButton>
                  </Popconfirm>
                </div>
                <SurveyItem column={{ xs: 1, sm: 2 }} data={item} type="maintenance" />
              </Timeline.Item>
              )
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无客服记录</Divider>
        }
      </LoadingWrapper>
      {/* 添加、编辑用户 */}
      {['add', 'edit', 'carDiagnosisAdd'].includes(operateInfo.type) &&
        <OperateModalTask
          closeModal={() => setOperateInfo({})}
          reloadData={() => getMaintenancesRecord(1)}
          type={operateInfo.type}
          data={plate_number}
        />
      }
    </Drawer>
  )
}

export default SurveyRecoed;