/**
 * 数据大屏
 */
import request from "@/utils/request";

export interface params {
    cateId?: Array<number>
}

// 标题数据
export const getTitleDataNum = (params: params) => {
    return request.get('/api/dataV/carCount/current', {params})
}

// 车辆运行工时
export const getCarOperationTime = (params: params) => {
    return request.get('/api/dataV/effectiveOnlineMonth', {params})
}

// 当日超速车辆
export const getCarSpeed = (params: params) => {
    return request.get('/api/dataV/overSpeeds/current', {params})
}

// 报警制动总数
export const getCarAlarmBraking = (params: params) => {
    return request.get('/api/dataV/aebAlarmCountMonth', {params})
}

// 报警制动总数
export const getCarAlarmBrakingAverage = (params: params) => {
    return request.get('/api/dataV/aebAlarmCountAvgMonth', {params})
}

// 分组车辆份额
export const getCarPortion = (params: params) => {
    return request.get('/api/dataV/carCountSubCate', {params})
}

// 车辆在线情况
export const getCarOnline = (params: params) => {
    return request.get('/api/dataV/carOnlineMonth', {params})
}

// 车辆在线情况
export const getCarIncrease = (params: params) => {
    return request.get('/api/dataV/carAddonMonth', {params})
}

// 有效预警数量
export const getCarEarlyWarning = (params: params) => {
    return request.get('/api/dataV/effectiveAlarmCountMonth', {params})
}

// 有效预警单车平均数
export const getCarEarlyWarningAverage = (params: params) => {
    return request.get('/api/dataV/effectiveAlarmCountAvgMonth', {params})
}