/**
 * 观看记录界面相关请求
 */
import request from "@/utils/request";

export interface VideoRequestParams {
    userId: number;
    beginDate?: string;
    endDate?: string;
}

export const  videoRequestPath = '/api/v2/video/list';

// 获取查询观看记录
export const videoRequest = (params: VideoRequestParams) => {
    return request.get(videoRequestPath, {
        params,
    })
}


