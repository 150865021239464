/**
 * 菜单权限管理
 */
import React, { useEffect, useState } from "react";
import { Card, Button, Popconfirm, message } from "antd";
import CommonTable from "@/components/commonTable";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import PermissionButton from "@/components/permissionButton";
import { MenuPurposeEnum, MenuTypeEnum, menuTypeData, menuPurposeData } from '@/constants/menuManage';
import { menuTreeRequest, deleteMenuRequest } from '@/service/menuManage';
import MenuOperateModal from "./components/menuOperateModal";
import ButtonPermissionModal from "./components/buttonPermissionModal";
import styles from '@/styles/pageContent.module.scss';

const MenuManage = () => {
  const [loading, setLoading] = useState(false); // 是否loading
  const [menuData, setMenuData] = useState([]); // 菜单权限数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息

  // 获取菜单权限数据
  const getMenuData = async () => {
    try {
      setLoading(true);
      const res = await menuTreeRequest({ menuPurpose: [MenuPurposeEnum.admin] });
      setMenuData(res.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMenuData();
  }, []);

  // 删除权限配置
  const deleteMenu = async(id: number) => {
    const res = await deleteMenuRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getMenuData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: 120,
      render: (value: number) => menuTypeData.find((item: any) => item.value === value)?.label,
    },
    {
      title: '用途',
      dataIndex: 'menuPurpose',
      width: 120,
      render: (value: number) => menuPurposeData.find((item: any) => item.value === value)?.label,
    },
    {
      title: '排序编号',
      dataIndex: 'order',
      width: 120,
    },
    {
      title: '权限标识key',
      dataIndex: 'key',
      width: 160,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {/* 区分是菜单组还是页面 */}
            {item.leaf ?
              <Button
                type="link"
                onClick={() => setOperateInfo({ type: 'buttonConfig', data: item })}
              >
                页面内部权限配置
              </Button> :
              <PermissionButton
                operateType="/car-admin/menu-manage/add-page"
                type="link"
                onClick={() => setOperateInfo({ type: 'add', menuType: 'page', data: item })}
              >
                添加页面配置
              </PermissionButton>
            }
            <PermissionButton
              operateType="/car-admin/menu-manage/edit"
              type="link"
              onClick={() => setOperateInfo({
                type: 'edit',
                menuType: item.type === MenuTypeEnum.overlay ? 'overlay' : item.leaf ? 'page' : 'menuGroup',
                data: item
              })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此权限配置吗?"
              onConfirm={() => deleteMenu(item.id)}
            >
              <PermissionButton operateType="/car-admin/menu-manage/delete" type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
  
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/menu-manage/add-group"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add', menuType: 'menuGroup' })}
        >
          添加菜单组
        </PermissionButton>
        <PermissionButton
          operateType="/car-admin/menu-manage/add-overlay"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add', menuType: 'overlay' })}
        >
          添加浮层弹窗
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="key"
        columns={columns}
        dataSource={menuData}
        pagination={false}
        columnFilter={false}
        bordered
        loading={loading}
        size="small"
      />
      {/* 菜单的添加和编辑 */}
      {['add', 'edit'].includes(operateInfo.type) && <MenuOperateModal
        data={operateInfo.data}
        type={operateInfo.type}
        menuType={operateInfo.menuType}
        closeModal={() => setOperateInfo({})}
        reloadData={getMenuData}
      />}
      {/* 页面内部权限权限配置 */}
      {operateInfo.type === 'buttonConfig' && <ButtonPermissionModal
        data={operateInfo.data}
        closeModal={() => setOperateInfo({})}
      />}
    </Card>
  )
}

export default MenuManage;