/**
 * 传感器方案-超声波安装方案添加/编辑
 */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Switch, Select } from 'antd';
import SensorUltrasonicLocation from '@/components/sensorUltrasonicLocation';
import FormTable from '@/components/formTable';
import { controlNoData } from '@/constants/sensorScheme';
import { upsertSensorUltrasonicInstallRequest } from '@/service/sensorUltrasonicInstall';
import { sensorUltrasonicHardwareListRequest } from '@/service/sensorUltrasonicHardware';
import styles from './index.module.scss';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  let sensorUltrasonicLocationRef: any = null;
  const locationKey = [
    { label: '前方雷达', value: 'radarFront' },
    { label: '后方雷达', value: 'radarBehind' },
    { label: '左方雷达', value: 'radarLeft' },
    { label: '右方雷达', value: 'radarRight' }
  ];
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [hardwareList, setHardwareList] = useState<any>([]); // 硬件方案列表数据
  const [ultrasonicLocationData, setUltrasonicLocationData] = useState([
    { install: false, selectData: [], controllerNo: '' },
    { install: false, selectData: [], controllerNo: '' },
    { install: false, selectData: [], controllerNo: '' },
    { install: false, selectData: [], controllerNo: '' }
  ]); // 超声波位置相关的选择框数据
  const [initSelectedLocationData, setInitSelectedLocationData] = useState([]); // 默认的对应位置关系数据

  // 获取硬件方案列表数据
  const getSensorUltrasonicHardware = async () => {
    const res = await sensorUltrasonicHardwareListRequest({
      page: 1,
      limit: 100
    });
    const hardwareData = res.data?.data || [];
    setHardwareList(hardwareData);
    if (['edit', 'detail'].includes(type)) {
      setEditValues(hardwareData);
    }
  };

  // 编辑时处理赋值操作
  const setEditValues = (hardwareData: any) => {
    const { name, description } = data;
    const install: any = [];
    const newUltrasonicLocationData = [...ultrasonicLocationData];
    const newSelectedLocationData: any = [];
    locationKey.forEach((item: any, index: number) => {
      const matchValue = data[`${item.value}Desc`];
      if (matchValue) {
        const { ultraProbeId, controllerNo, nameArr } = matchValue;
        install.push({
          location: item.label,
          isInstall: true,
          ultraProbeId,
          controllerNo
        });
        newUltrasonicLocationData[index] = {
          install: true,
          selectData:
            hardwareData.find((item: any) => item.id === ultraProbeId)
              ?.probeJsonDesc || [],
          controllerNo
        };
        newSelectedLocationData[index] = nameArr.map((item1: string) =>
          item1 === '无' ? null : item1
        );
      } else {
        install.push({
          location: item.label,
          isInstall: false,
          ultraProbeId: null,
          controllerNo: null
        });
      }
    });
    form.setFieldsValue({
      name,
      description,
      install
    });
    setUltrasonicLocationData(newUltrasonicLocationData);
    setInitSelectedLocationData(newSelectedLocationData);
  };

  // 编辑时赋值
  useEffect(() => {
    getSensorUltrasonicHardware();
  }, []);

  // 表单操作时的处理
  const onFormChange = (changedValues: any) => {
    if (changedValues?.install) {
      const newUltrasonicLocationData: any = [...ultrasonicLocationData];
      const changeIndex = changedValues?.install.length - 1; // 当前改变的索引
      const { isInstall, ultraProbeId, controllerNo } =
        changedValues.install[changeIndex] || {};
      if (typeof isInstall === 'boolean') {
        if (isInstall) {
          newUltrasonicLocationData[changeIndex].install = true;
          form.setFields([
            {
              name: 'install',
              errors: [],
              value: form.getFieldValue('install')
            }
          ]);
        } else {
          newUltrasonicLocationData[changeIndex] = {
            install: false,
            selectData: [],
            controllerNo: ''
          };
          // 选择不安装时不校验对应的硬件方案和控制器编号
          form.setFields([
            {
              name: ['install', changeIndex, 'ultraProbeId'],
              errors: [],
              value: undefined
            },
            {
              name: ['install', changeIndex, 'controllerNo'],
              errors: [],
              value: undefined
            }
          ]);
        }
      }
      if (ultraProbeId) {
        newUltrasonicLocationData[changeIndex].selectData =
          hardwareList.find((item: any) => item.id === ultraProbeId)
            ?.probeJsonDesc || [];
      }
      if (controllerNo) {
        newUltrasonicLocationData[changeIndex].controllerNo = controllerNo;
      }
      sensorUltrasonicLocationRef &&
        sensorUltrasonicLocationRef.resetSelectLocation(changeIndex, []);
      setUltrasonicLocationData(newUltrasonicLocationData);
    }
  };

  // 校验安装位置表单项
  const validatorInstall = (value: any) => {
    if (value.some((item: any) => item.isInstall)) {
      const controllerNoObj: any = {};
      let flag = true;
      value.forEach((item: any) => {
        const { isInstall, ultraProbeId, controllerNo } = item;
        if (isInstall) {
          if (
            controllerNoObj[controllerNo] &&
            controllerNoObj[controllerNo] !== ultraProbeId
          ) {
            // 同一个控制器编号对应了不同的硬件方案
            flag = false;
          }
          controllerNoObj[controllerNo] = ultraProbeId;
        }
      });
      if (flag) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          new Error('同一个控制器编号应该选择相同的硬件方案')
        );
      }
    } else {
      return Promise.reject(new Error('请至少选择一个安装方向'));
    }
  };

  // 校验探头选择不能重复
  const validatorSelectLocation = (allSelectLocation: any) => {
    const locationObj: any = {};
    for (const item of allSelectLocation) {
      if (item) {
        if (locationObj[item]) {
          return true;
        }
        locationObj[item] = true;
      }
    }
    return false;
  };

  // 保存传感器方案-超声波安装方案
  const upsertSensorUltrasonicInstall = async (values: any) => {
    const selectLocationData = sensorUltrasonicLocationRef.getSelectLocation();
    const allSelectLocation = selectLocationData.flat(Infinity);
    if (validatorSelectLocation(allSelectLocation)) {
      message.error('同一控制器下同一探头不能重复选择');
      return;
    }
    const { name, description, install } = values;
    const radarLocation: any = {};
    let selectLocationVerifyFail = false; // 雷达对应关系的选择是否校验失败
    // 处理超声波安装对应关系数据
    install.forEach((item: any, index: number) => {
      const { isInstall, controllerNo, ultraProbeId } = item;
      if (isInstall) {
        const currentSelectLocation = selectLocationData[index];
        currentSelectLocation.length = 6;
        if (currentSelectLocation.every((item1: any) => !item1)) {
          selectLocationVerifyFail = true;
        }
        radarLocation[locationKey[index].value] = JSON.stringify({
          controllerNo,
          ultraProbeId,
          nameArr: Array.from(
            currentSelectLocation,
            (item1: any) => item1 || '无'
          )
        });
      } else {
        radarLocation[locationKey[index].value] = '';
      }
    });
    if (selectLocationVerifyFail) {
      message.error('请选择对应安装方向的雷达对应关系');
      return;
    }
    const params = {
      name,
      description,
      ...radarLocation
    };
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorUltrasonicInstallRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  // 处理传递给超声波位置示意图组件的数据
  const operateUltrasonicLocationData = (index: number) => {
    const matchData: any = ultrasonicLocationData[index];
    const { install, controllerNo, selectData } = matchData;
    if (install && controllerNo) {
      return selectData.map((item: any) => ({
        value: `${controllerNo}-${item.location}`,
        label: `${controllerNo}-${item.location}`
      }));
    }
  };

  return (
    <Modal
      title={
        type === 'detail'
          ? '超声波安装方案详情'
          : type === 'add'
          ? '新增超声波安装方案'
          : '编辑超声波安装方案'
      }
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      maskClosable={false}
      width="auto"
      okButtonProps={{ style: type === 'detail' ? { display: 'none' } : {} }}
    >
      <div className={styles.operateUltrasonicInstall}>
        <Form
          form={form}
          autoComplete="off"
          onFinish={upsertSensorUltrasonicInstall}
          layout="vertical"
          className={styles.formBox}
          onValuesChange={onFormChange}
          disabled={type === 'detail'}
        >
          <Form.Item
            label="安装方案名称"
            name="name"
            rules={[{ required: true, message: '请输入安装方案名称' }]}
          >
            <Input placeholder="请输入安装方案名称" />
          </Form.Item>
          <Form.Item label="安装方案描述" name="description">
            <Input.TextArea placeholder="请输入安装方案描述" />
          </Form.Item>
          <FormTable
            form={form}
            field="install"
            showAdd={false}
            showDelete={false}
            initialValue={[
              {
                location: '前方雷达',
                isInstall: false,
                ultraProbeId: null,
                controllerNo: null
              },
              {
                location: '后方雷达',
                isInstall: false,
                ultraProbeId: null,
                controllerNo: null
              },
              {
                location: '左侧雷达',
                isInstall: false,
                ultraProbeId: null,
                controllerNo: null
              },
              {
                location: '右侧雷达',
                isInstall: false,
                ultraProbeId: null,
                controllerNo: null
              }
            ]}
            tableFormItems={[
              {
                title: '位置',
                width: 80,
                formItem: (name: number, formValues: any, restField: any) => (
                  <Form.Item {...restField} name={[name, 'location']}>
                    <div>{formValues?.install?.[name]?.location}</div>
                  </Form.Item>
                )
              },
              {
                title: '是否安装',
                width: 80,
                formItem: (name: number, formValues: any, restField: any) => (
                  <Form.Item
                    {...restField}
                    name={[name, 'isInstall']}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                )
              },
              {
                title: '硬件方案',
                width: 200,
                formItem: (name: number, formValues: any, restField: any) => (
                  <Form.Item
                    {...restField}
                    name={[name, 'ultraProbeId']}
                    rules={[
                      {
                        required: Boolean(
                          formValues?.install?.[name]?.isInstall
                        ),
                        message: '请选择硬件方案'
                      }
                    ]}
                  >
                    <Select
                      placeholder="请选择硬件方案"
                      options={hardwareList}
                      fieldNames={{ label: 'name', value: 'id' }}
                    />
                  </Form.Item>
                )
              },
              {
                title: '控制器编号',
                width: 140,
                formItem: (name: number, formValues: any, restField: any) => (
                  <Form.Item
                    {...restField}
                    name={[name, 'controllerNo']}
                    rules={[
                      {
                        required: Boolean(
                          formValues?.install?.[name]?.isInstall
                        ),
                        message: '请选择控制器编号'
                      }
                    ]}
                  >
                    <Select
                      placeholder="请选择控制器编号"
                      options={controlNoData}
                    />
                  </Form.Item>
                )
              }
            ]}
            validatorFunc={(value: any) => validatorInstall(value)}
          />
        </Form>
        <div className={styles.imageBox}>
          <SensorUltrasonicLocation
            ref={(ref: any) => (sensorUltrasonicLocationRef = ref)}
            frontData={operateUltrasonicLocationData(0)}
            behindData={operateUltrasonicLocationData(1)}
            leftData={operateUltrasonicLocationData(2)}
            rightData={operateUltrasonicLocationData(3)}
            initSelectedLocationData={initSelectedLocationData}
            isView={type === 'detail'}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OperateModal;
