/**
 * 操作诉讼记录
 */
import React, { useEffect, useState } from "react";
import {Input, message, Modal, Form,Select } from 'antd';
import {upsertCarProceedRequest } from "@/service/contractManage";
import UploadFile from '@/components/uploadFile';
import { tagListRequest } from "@/service/tagManage";
import { TagCategoryEnum } from "@/constants/tagManage";


interface DoucumentCommentDetailProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  item?: any;
}

const OperateModal = (props: DoucumentCommentDetailProps) => {
  const [form] = Form.useForm();
  const { type, item, closeModal, reloadData, } = props;
  const [isModalOpen, setIsModalOpen] = useState(false); // 诉讼记录弹框是否开启
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传文件
  const [LawsuitState, setLawsuitState] = useState<any>([]); // 诉讼记录详情
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  useEffect(() => {
    getFuelType()
    if (type === 'edit') {
      if (item?.attachments) {
        setUploadSuccessList([item?.attachments])
      }
      const params = { ...item };
      form.setFieldsValue(params)
    }
  }, []);

  // 诉讼记录
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      //合同号
      params.contractNo = item.no
      if (uploadSuccessList.length) {
        params.attachments = uploadSuccessList;
      } else {
        params.attachments = null;
      }
      const res = await upsertCarProceedRequest(params);
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
      setIsModalOpen(false);
    }

  }
  //获取诉讼阶段
  const getFuelType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.lawsuitState],
    });
    const tags: any = [];
    (res.data?.data || []).map((item: any) => {
      const { title } = item;
      if(title === '已转交法务') {
        tags.unshift({ label: title, value: title })
      } else {
        tags.push({ label: title, value: title });
      }
      
    });
    setLawsuitState(tags);
  }

  return (
    <Modal title={type === 'add' ? '新增诉讼记录' : '编辑诉讼记录'}
    visible
    onCancel={closeModal}
    onOk={() => form.submit()}
    bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    centered
    maskClosable={false}
  >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item label="案件编号" name="caseNo" >
          <Input placeholder="请输入案件编号" />
        </Form.Item>
        <Form.Item label="诉讼阶段" name="state">
          <Select
            placeholder="请选择诉讼阶段"
            options={LawsuitState}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item label="备注" name="remark" >
          <Input placeholder="请输入备注" />
        </Form.Item>
        <Form.Item label="上传文件" name="attachments">
          <UploadFile
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={[]}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
