/**
 * 疲劳驾驶监控的相关请求
 */
import request from "@/utils/request";

export interface CurrentFatiguesRequestParams {
  cateId?: Array<string>;
  fatigueThreshold: number;
  restThreshold: number;
  export?: boolean;
}
// 获取当前疲劳驾驶车辆
export const currentFatiguesRequestPath = '/api/v2/car/currentFatigues';
export const currentFatiguesRequest = (data: CurrentFatiguesRequestParams) => {
  return request.post('/api/v2/car/currentFatigues', data)
}

export interface HistoryFatiguesRequestParams {
  cateId?: Array<string>;
  beginTime: string;
  endTime: string;
  fatigueThreshold: number;
  restThreshold: number;
  export?: boolean;
}
// 获取历史疲劳驾驶车辆
export const historyFatiguesRequestPath = '/api/v2/car/historyFatigues';
export const historyFatiguesRequest = (data: HistoryFatiguesRequestParams) => {
  return request.post('/api/v2/car/historyFatigues', data)
}

