/**
 * 退保记录
 */
import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import CommonTable from '@/components/commonTable';
import { surrenderRecordListRequest } from '@/service/underwritingRecord';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import dayjs from 'dayjs';
import LoadingWrapper from '@/components/loadingWrapper';
import UnderwritingSurrender from '@/pages/underwritingRecord/components/underwritingSurrender';
import PermissionButton from '@/components/permissionButton';
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';
import PdfPreviewModal from '@/components/pdfPreviewModal';

interface PayinfoModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void;
}
const PolicyCancellationModal = (props: PayinfoModalProps) => {
  const { data, closeModal, reloadData } = props;
  const [operateInfo, setOperateInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false) // 是否在请求中
  const [surrenderDetail, setSurrenderDetail] = useState<any>([]); // 退保记录详情
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息

  useEffect(() => {
    getSurrenderRecordList()
  }, [])

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  //获取退保记录
  const getSurrenderRecordList = async () => {
    try {
      setLoading(true);
      const res = await surrenderRecordListRequest({
        insuranceCode: data.commercialNumber,
      });
      const dataa = res.data?.data || {};
      setSurrenderDetail(dataa);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
    },
    {
      title: '操作类型',
      dataIndex: 'type',
    },
    {
      title: '日期',
      dataIndex: 'date',
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')} /> : '/'
    },
    {
      title: '退费金额(元)',
      dataIndex: 'fee',
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
    {
      title: '退费凭证',
      dataIndex: 'resources',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        value?.map((item: any, index: any) => (
          <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
            <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
            </div>
            <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                type="text"
                onClick={() => onPdfPreview(item.url, item.fileName)}
              >
                <EyeOutlined />
              </Button>
              <Button
                type="link"
                onClick={() => downloadFile(item.url, item.fileName)}
                style={{ paddingLeft: 0 }}
              >
                <DownloadOutlined />
              </Button>
            </div>
          </div>
        ))
      ),
    },
    {
      title: '操作人',
      dataIndex: 'opt',
    },
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} /> : '/'
    }
  ]

  return (
    <Drawer
      title='退保记录'
      visible
      size="large"
      onClose={closeModal}
      width={950}
    >
      <PermissionButton
        style={{marginRight: '10px'}}
        operateType="/car-admin/surrender-recode/add"
        type="primary"
        onClick={() => setOperateInfo({ type: 'add',name: '退保', data: data })}
      >
        退保
      </PermissionButton>
      <PermissionButton
      style={{marginRight: '10px'}}
        operateType="/car-admin/surrender-recode/add"
        type="primary"
        onClick={() => setOperateInfo({ type: 'add',name: '转保', data: data })}
      >
        转保
      </PermissionButton>
      <PermissionButton
        operateType="/car-admin/surrender-recode/add"
        type="primary"
        onClick={() => setOperateInfo({ type: 'add', name: '过户',data: data })}
      >
        过户
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <CommonTable
          rowKey="id"
          columns={columns}
          dataSource={surrenderDetail}
          bordered
          loading={loading}
        />
      </LoadingWrapper>
      {['add'].includes(operateInfo.type) &&
        <UnderwritingSurrender
          closeModal={() => setOperateInfo({})}
          reloadData={getSurrenderRecordList}
          type={operateInfo.type}
          item={operateInfo.data}
          name={operateInfo.name}
        />
      }
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Drawer>

  )
}

export default PolicyCancellationModal;
