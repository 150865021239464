/**
 * 服务费还款情况弹窗
 */
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Drawer, Input } from 'antd';
import CommonTable from '@/components/commonTable';
import { insureFeeListRequest ,upsertInsureFeeRequest, } from '@/service/underwritingRecord';
import QuickDatePicker from '@/components/quickDatePicker';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import dayjs from 'dayjs';
import LoadingWrapper from '@/components/loadingWrapper';
import PayRecord from '@/pages/underwritingRecord/components/payRecord';

interface ContactModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void;
}
const PayinfoModal = (props: ContactModalProps) => {
  const { data, closeModal, reloadData } = props;
  const [operateInfo, setOperateInfo] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false) // 是否在请求中
  const [orderDetail, setOrderDetail] = useState<any>([]); // 诉讼记录详情

  useEffect(() => {
    getInsureFeeList()
  }, [])

  //获取支付记录
  const getInsureFeeList = async () => {
    try {
      setLoading(true);
      const res = await insureFeeListRequest({
        insuranceCode: data.commercialNumber,
      });
      const dataa = res.data?.data || {};
      setOrderDetail(dataa);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  //应还款金额
  const changeFee = async (e: any, item: any) => {
    console.log(e, item);
    const params = { ...item }
    const aa ={
      fee : Number(e),
      id : params.id,
      insuranceCode: params.insuranceCode,
      date: params.date
    }

    const res = await upsertInsureFeeRequest(aa);
    reloadData()
}

const  changeDate = async (e: any, item: any) => {
  const params = { ...item }
  const aa ={
    fee : params.fee,
    id : params.id,
    insuranceCode: params.insuranceCode,
    date: e
  }

  const res = await upsertInsureFeeRequest(aa);
  reloadData()
}

  // 表格列显示
  const columns = [
    {
      title: '还款周期',
      dataIndex: 'name',
    },
    {
      title: '应还款金额(元)',
      dataIndex: 'fee',
      render: (value: any, item: any, index: any) => <Input defaultValue={value} onBlur={(e) => changeFee(e.target.value, item)} />

    },
    {
      title: '应还款日期',
      dataIndex: 'date',
      render:  (e: any, item: any, index: any)  => <Input defaultValue={e}  onBlur={(e) => changeDate(e.target.value, item)} />
    },
    {
      title: '实还款金额(元)',
      dataIndex: 'payFee', 
    },
    {
      title: '支付状态',
      dataIndex: 'state',
    },
    {
      title: '操作',
      dataIndex: 'operate',
      render: (value: any, item: any) => {
        return (
          <div >
            <Button onClick={() => setOperateInfo({ type: 'add' , data : item ,})}>支付记录</Button>
          </div>
        )
      },
    },
  ]
  
  return (
    <Drawer
      title='服务费还款情况'
      visible
      size="large"
      onClose={closeModal}
      width={750}
    >
      <LoadingWrapper spinning={loading}>
        <CommonTable
          rowKey="id"
          columns={columns}
          dataSource={orderDetail}
          bordered
          loading={loading}
          pagination={{
            current: 1,
            pageSize: 15,
          }}
        />
      </LoadingWrapper>
      {['add'].includes(operateInfo.type) &&
        <PayRecord
          closeModal={() => setOperateInfo({})}
          reloadData={getInsureFeeList}
          type={operateInfo.type}
          item={operateInfo.data}
        />
      }
    </Drawer>
  )
}

export default PayinfoModal;
