
/**
 * 登录用户相关请求
 */
import request from "@/utils/request";

interface LoginRequestData {
  mobile: string;
  password: string;
}

// 登录
export const loginRequest = (data: LoginRequestData) => {
  return request.post('/api/v2/system/login', data)
}

// 获取登录的用户信息
export const getUserInfoRequest = () => {
  return request.get('/api/v2/system/currentUser');
}

// 获取当前登录系统人员的客户信息
export const getCustomer = () => {
  return request.get('/api/v2/callRecord/customer/info')
}
