/**
 * 设备选择框
 */
import React, { useEffect, useState, useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { debounce, omit } from 'lodash';
import { deviceListRequest } from '@/service/deviceManage';

interface CarSelectProps extends SelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  labelShowPlateNumber?: boolean; // 选择器label显示车牌号
}
const DeviceSelect = (props: CarSelectProps) => {
  const { value, onChange, labelShowPlateNumber } = props;
  const [deviceList, setDeviceList] = useState<any>([]); // 车辆列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>();

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 设备的搜索
  const onDeviceSearch = async (value: string) => {
    if (value) {
      const res = await deviceListRequest({
        page: 1,
        limit: 30,
        sn: value,
      });
      const deviceData = (res.data?.data || []).map((item: any) => {
        let showLable = `（${item.deviceType === 2 ||item.deviceType === 6 || item.deviceType === 7 ||item.deviceType === 9 || item.deviceType === 12 || item.deviceType === 13? '昆仑山设备' : '黄河设备'}）`;
        // 显示车牌号
        if (labelShowPlateNumber) {
          showLable = item.plateNumber ? `（${item.plateNumber}）` : '';
        }
        return ({
          ...item,
          label: `${item.sn}${showLable}`,
          value: String(item.sn),
        });
      })
      setDeviceList(deviceData);
    } else {
      setDeviceList([]);
    }
  }

  // 防抖的设备的搜索
  const debounceOnDeviceSearch = useMemo(() => {
    return debounce(onDeviceSearch, 600);
  }, []);

  const selectOwnProps = omit(props, 'labelShowPlateNumber');

  return (
    <Select
      {...selectOwnProps}
      placeholder='可根据设备SN进行搜索'
      showSearch
      filterOption={false}
      onSearch={debounceOnDeviceSearch}
      options={deviceList}
      onChange={onChange}
      value={selectValue}
      notFoundContent={null}
    />
  )
}

export default DeviceSelect;
