/**
 * 操作保前查勘记录
 */
import React from 'react';
import { Modal } from 'antd';
import Thumbnail from '@/components/thumbnail';

interface ResourcesModalProps {
  data?: any;
  closeModal: () => void;
}
const ResourcesModal = (props: ResourcesModalProps) => {
  const { data, closeModal } = props;

  return (
    <Modal
      title="查看图片资源"
      open
      onCancel={closeModal}
      onOk={closeModal}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <div>
        {(JSON.parse(data.photos) || []).map((item: any, index: number) => (
          <div>
            <h3>{item.fileName}</h3>
            <Thumbnail
              dataSource={[
                {
                  url: data.resources[index].url,
                  contentType: 'image',
                  preview: data.resources[index].url
                }
              ]}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ResourcesModal;
