/**
 * 客服人员管理
 */
import request from "@/utils/request";
export interface CustomerListRequestParams {
    page: number; 
    limit: number;
}

export const customerListRequestPath = '/api/v2/customer/list';

// 获取客服人员
export const customerListRequest = (params: CustomerListRequestParams) => {
    return request.get(customerListRequestPath, {
        params,
    })
}

// 删除客服人员
export const DeleteCustomerTask = (id: number) => {
    return request.delete(`/api/v2/customer/${id}`)
}

// 添加/编辑客服人员
export const upsertCustomer = (data: any) => {
    return request.post('/api/v2/customer/upsert', data)
}