/**
 * 运营知识库
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Tag, Image, Select } from 'antd';
import { EyeOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import styles from '@/styles/pageContent.module.scss';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import { useTable } from '@/utils/useTable';
import { documentListRequest, DeleteDocument } from '@/service/documentData'
import OperateModal from './components/operateModal';
import dayjs from 'dayjs';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import TaskDetail from './components/taskDetail'; //评论框
import { downloadFile } from '@/utils/download';
import { TagCategoryEnum } from '@/constants/tagManage';
import { tagListRequest } from '@/service/tagManage';
import { useSelector } from 'react-redux';



const documentData = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF文件预览的信息
  const [classData, setClassData] = useState<any>([]); // 知识库类型的标签
  const { userDetail } = useSelector((state: any) => state.userStore);

  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: documentListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  useEffect(() => {
    getClassData();
  }, []);

  // 获取知识库类型标签
  const getClassData = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.documentData],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setClassData(tags);
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.creator = (values.creator || '').trim();
    setSearchParams(newSearchParams);
  };

  // 预览文件
  const onPdfPreview = (fileSource: any) => {
    if (fileSource?.contentType !== 'imagee') {
      const url = fileSource.url
      const fileName = fileSource.fileName
      setPdfPreviewInfo({
        url,
        fileName
      });
    }
  }

  // 删除
  const deleteDocument = async (id: number) => {
    const res = await DeleteDocument(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '类别',
      dataIndex: 'type',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '标题',
      dataIndex: 'title',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 160,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: '内容',
      dataIndex: 'content',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: any, item: any) => (<RenderEllipsisColumn text={value} />)
    },
    {
      title: '附件',
      dataIndex: 'resource',
      width: 140,
      ellipsis: { showTitle: false },
      render: (fileSource: any) => {
        return (
          <div className={styles.contractIcon}>
            {fileSource?.contentType == "imag" ?
              <div>
                <Image src={fileSource?.url} style={{ width: "100px" }} />
                <PermissionButton
                  onClick={() => downloadFile(fileSource.url, fileSource.fileName)}
                  type="link">
                  <VerticalAlignBottomOutlined />
                </PermissionButton>
              </div> : fileSource?.contentType == "application/pdf" ?
                <div>
                  <PermissionButton
                    type="text"
                    onClick={() => onPdfPreview(fileSource)} >
                    <EyeOutlined />
                  </PermissionButton>
                  <PermissionButton
                    onClick={() => downloadFile(fileSource.url, fileSource.fileName)}
                    type="link">
                    <VerticalAlignBottomOutlined />
                  </PermissionButton>
                </div> : '无附件'}
          </div>
        )
      }
    },
    {
      title: '关键字',
      dataIndex: 'keyWords',
      width: 160,
      ellipsis: { showTitle: false },
      render: (value: any , item :any) => <div className={styles.tableTagColumn}>
     {value?.map((item: any, index: number) => (
      <Tag key={index} >
        {item}
      </Tag>
    ))}
    </div>,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            {item.creatorId == userDetail.id ? <Button type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}> 编辑</Button> : userDetail.id == 1 ? <Button type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}> 编辑</Button> : null}
            <PermissionButton operateType='/car-admin/class-data/comment' type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>评论</PermissionButton>
            <PermissionButton operateType='/car-admin/class-data/delete' type="link" onClick={() => deleteDocument(item.id)} >删除</PermissionButton>
          </div>
        )
      },
    },
  ]

  // 关闭评论
  const taskCommentDetail = () => {
    setOperateInfo({})
    getData();
  }

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="标题" name="title">
              <Input placeholder='请输入标题' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="类别"
              name="type"
            >
              <Select
                options={classData}
                placeholder="请选择类别"
                showSearch
                showArrow
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/class-data/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />

      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
      {/* 评论 */}
      {operateInfo?.type === 'detail' &&
        <TaskDetail item={operateInfo.data} onClose={() => taskCommentDetail()} />
      }
    </Card>
  )
}

export default documentData;
