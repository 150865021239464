/**
 * 支付宝根目录
 */
import React, { Fragment } from 'react';
import ZhiFuDetails from '@/pages/zhiFuDetails'


const VehicleAdminRoute = () => {
 
  return (
    <Fragment>
      <ZhiFuDetails />
    </Fragment>
  )
}

export default VehicleAdminRoute;
