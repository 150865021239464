/**
 * 工单收费记录
 */
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Image, Tag, Select } from 'antd';
import { useSelector } from 'react-redux';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import ExportButton from '@/components/exportButton';
import { operateTimeRange } from '@/utils/operateSearchParams';
import { getInnerPermissionEnable } from '@/utils/permission';
import DatePicker from '@/components/datePicker';
import { useTable } from '@/utils/useTable';
import { costRecordListRequest, costRecordAffirm, costRecordDelete, costRecordListRequestPath } from '@/service/workOrderCostRecord'
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;

const WorkOrderCostRecord = () => {
  const { userDetail } = useSelector((state: any) => state.userStore);

  // 获取接口请求的issuId参数值
  const getSearchIssuId = () => {
    if (getInnerPermissionEnable('/car-admin/work-order/work-order-super')) {
      return undefined;
    } else {
      return userDetail.id;
    }
  }

  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    issuId: getSearchIssuId()
  }); // 搜索相关的参数

  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: costRecordListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  const costFlagData = [
    { label: '已确认', value: true },
    { label: '未确认', value: false },
  ]
  const plate_number = ''

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams = operateTimeRange(params, true, isExport);
    if (params.plateNumber) {
      newSearchParams.plateNumber = params.plateNumber.trim();
    } else {
      newSearchParams.plateNumber = undefined;
    }
    if (params.no) {
      newSearchParams.no = params.no.trim();
    } else {
      newSearchParams.no = undefined;
    }
    newSearchParams.issuId = params?.issuId || getSearchIssuId();
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
    newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
    delete newSearchParams.day;
    setSearchParams(newSearchParams);
    getData(newSearchParams)
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const newSearchParams = operateSearchParams(values, true);
    return newSearchParams;
  }

  // 收费状态改变
  const costFlag = async (values:any) => {
    const params = {...values};
    params.id = values.toString()
    const res = await costRecordAffirm(params)
    if (res.data?.code === 0) {
      message.success('确认收费成功');
      getData();
    }
  }

  // 删除
  const deleteUser = async(id: number) => {
    const res = await costRecordDelete(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 收费类型
  const renderType = (value:any) => {
    if (value == 1) {
      return '设备维修'
    } else if (value == 2) {
      return '设备拆除'
    } else if (value == 3) {
      return '设备迁移'
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: '车辆',
      dataIndex: 'plateNumber',
      width: 110,
    },
    {
      title: '收费时间',
      dataIndex: 'createdAt',
      width: 110,
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '操作人员',
      dataIndex: 'userName',
      width: 110,
    },
    {
      title: '收费金额(元)',
      dataIndex: 'cost',
      width: 110,
    },
    {
      title: '收费凭证',
      dataIndex: 'imageUrl',
      width: 110,
      render: (imageUrl:any) => <Image src={imageUrl} style={{height:"100px"}} />
    },
    {
      title: '收费类型',
      dataIndex: 'type',
      width: 110,
      render: (value: number) => renderType(value),
    },
    {
      title: '维保项',
      dataIndex: 'items',
      width: 110,
      render: (value: any) => {
        return (
          value !== null ? <div className={styles.tableTagColumn}>
            {eval(value).map(((item: any) => <Tag>{item}</Tag>))}
          </div> : <div></div>
        )
      },
    },
    {
      title: '收费状态',
      dataIndex: 'flag',
      width: 110,
      render: (value: any) => {
        return (
          value == true ? <Tag color="success">已确认</Tag> : <Tag color="error">未确认</Tag>
        )
      }
    },
    {
      title: '备注',
      dataIndex: 'remarks',
      width: 110,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/work-order-cost-record/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您要确认收费吗?"
              onConfirm={() => costFlag(item.id)}
            >
              <PermissionButton operateType='/car-admin/work-order-cost-record/cost-flag' type="link">确认收费</PermissionButton>
            </Popconfirm>
            <Popconfirm
              title="您确定要删除该用户吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/work-order-cost-record/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="日期"
            name="day"
            initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
          >
            <RangePicker allowClear={false} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="收费状态" name="flag">
            <Select 
              placeholder="请选择收费状态" 
              options={costFlagData} 
              allowClear 
              showArrow 
              optionFilterProp="label"  
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="&nbsp;">
            <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
              <ExportButton 
                url={costRecordListRequestPath}
                fileName='工单收费记录'
                getParamsFunc={getExportParams}
                requestMethod="GET"
                permissionKey='/car-admin/work-order/export'
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/work-order-cost-record/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add', data: {plateNumber:plate_number} })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      
      {/* 添加、编辑记录 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default WorkOrderCostRecord;
