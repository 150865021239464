/**
 * 组织管理
 */
import React, { useState, useEffect } from 'react';
import { Card, message, Popconfirm } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { formatTime } from '@/utils/formatTime';
import { organizationListRequest, deleteOrganizationRequest } from '@/service/organizationManage';
import OperateOrganizationModal from './components/operateOrganizationModal';
import styles from '@/styles/pageContent.module.scss';

const OrganizationManage = () => {
  const [loading, setLoading] = useState(false); // 组织数据请求中
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [organizationList, setOrganizationList] = useState<any>([]); // 组织列表

  // 获取组织列表
  const getOrganizationList = async() => {
    try {
      setLoading(true);
      const res = await organizationListRequest();
      const resData = res.data?.data;
      setOrganizationList(resData ? [resData] : []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrganizationList();
  }, []);

  // 删除组织
  const deleteOrganization = async(id: number) => {
    const res = await deleteOrganizationRequest(id)
    if (res.data?.data) {
      message.success('删除成功');
      getOrganizationList();
    }
  }
 
  // 表格列显示
  const columns = [
    {
      title: '组织名称',
      dataIndex: 'groupName',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '组织唯一key',
      dataIndex: 'groupKey',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '页面Title',
      dataIndex: 'pageTitle',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: 'logo图标url',
      dataIndex: 'iconUrl',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '数据大屏url',
      dataIndex: 'datavUrl',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={formatTime(value)} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 210,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/organization-manage/add'
              type="link"
              onClick={() => setOperateInfo({ type: 'add', data: item })}
            >
              添加子分组
            </PermissionButton>
            <PermissionButton
              operateType='/car-admin/organization-manage/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该组织吗?"
              onConfirm={() => deleteOrganization(item.id)}
            >
              <PermissionButton operateType='/car-admin/organization-manage/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={organizationList}
        columnFilter={false}
        pagination={false}
        bordered
        loading={loading}
      />
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateOrganizationModal
          type={operateInfo.type}
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
          reloadData={getOrganizationList}
        />
      }
    </Card>
  )
}

export default OrganizationManage;
  