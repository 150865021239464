/**
 * 标签分组的添加和编辑
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, TreeSelect } from 'antd';
import { upsertTagSortRequest, tagSortTreeRequest } from '@/service/tagManage';

interface OperateTagSortModalProps {
  data?: any;
  type?: string;
  closeModal: () => void;
  reloadData: () => void;
}
const OperateTagSortModal = (props: OperateTagSortModalProps) => {
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData } = props;
  const [tagSortTree, setTagSortTree] = useState<any>([]); // 标签分组数据
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    getTagSortTree();
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, [])

  // 获取标签分组数据
  const getTagSortTree = async() => {
    const res = await tagSortTreeRequest();
    const resData = res.data?.data;
    setTagSortTree(resData ? [resData] : []);
  }

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      const isEdit = type === 'edit';
      if (isEdit) {
        params.id = data.id;
      }
      const res = await upsertTagSortRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(isEdit ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加标签分组' : '编辑标签分组'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        layout='vertical'
      >
        <Form.Item
          label="分组名称"
          name="title"
          rules={[{ required: true, message: '请输入标签分组名称' }]}
        >
          <Input placeholder='请输入标签分组名称' />
        </Form.Item>
        <Form.Item
          label="分组归属父节点"
          name="parentId"
          rules={[{ required: true, message: '请选择分组归属父节点' }]}
        >
          <TreeSelect
            treeData={tagSortTree}
            placeholder='请选择分组归属父节点'
            fieldNames={{ value: 'id' }}
            treeNodeFilterProp="title"
            showSearch
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateTagSortModal;
    