// extracted by mini-css-extract-plugin
export default {"carGroupMoveBox":"carGroupMove_carGroupMoveBox__kIeEk","carTree":"carGroupMove_carTree__HxscX","moveButtonBox":"carGroupMove_moveButtonBox__a+9ib","rightPart":"carGroupMove_rightPart__2cutn","carBoxTitle":"carGroupMove_carBoxTitle__bsflz","carBox":"carGroupMove_carBox__KQJJA","carItem":"carGroupMove_carItem__HXy-T"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"carGroupMoveBox\":\"carGroupMove_carGroupMoveBox__kIeEk\",\"carTree\":\"carGroupMove_carTree__HxscX\",\"moveButtonBox\":\"carGroupMove_moveButtonBox__a+9ib\",\"rightPart\":\"carGroupMove_rightPart__2cutn\",\"carBoxTitle\":\"carGroupMove_carBoxTitle__bsflz\",\"carBox\":\"carGroupMove_carBox__KQJJA\",\"carItem\":\"carGroupMove_carItem__HXy-T\"}";
        // 1726639067508
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  