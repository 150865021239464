/**
 * 运维评级记录相关请求
 */
import request from "@/utils/request";

//查询运维评级记录
export const carEvaluationRequestPath = '/api/v2/car/evaluation/list';
export const carEvaluationRequest = (params: any) => {
  return request.get(carEvaluationRequestPath, {
    params
  })
}

// 添加数据
export const upsertCarEvaluation = (data: any) => {
    return request.post('/api/v2/car/evaluation/upsert', data)
}

// 删除数据
export const DeleteInsurer = (id: number) => {
  return request.delete(`/api/v2/car/evaluation/${id}`)
}