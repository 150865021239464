/**
 * 部标机设备报警相关方法
 */
import { jtDeviceAlarmTypeData } from '@/constants/jtDeviceAlarm';
import { formatTime } from '@/utils/formatTime';

// 显示部标机设备报警类型
export const displayJtDeviceAlarm = (alarmData: any) => {
  let alarmGroup = '';
  let warningType = '';
  if (alarmData.adas) {
    alarmGroup = 'adas';
    warningType = `${alarmGroup}_${alarmData.adas.warningType}`;
  } else if (alarmData.blind) {
    alarmGroup = 'blind';
    warningType = `${alarmGroup}_${alarmData.blind.warningType}`;
  } else if (alarmData.driver) {
    alarmGroup = 'driver';
    warningType = `${alarmGroup}_${alarmData.driver.warningType}`;
  }
  if (alarmGroup && warningType) {
    const matchAlarmGroup =
      jtDeviceAlarmTypeData.find((item: any) => item.value === alarmGroup)
        ?.children || [];
    const matchAlarmLabel = matchAlarmGroup.find(
      (item: any) => item.value === warningType
    )?.label;
    return matchAlarmLabel || '';
  } else {
    return '';
  }
};

/**
 * 显示部标机设备报警时间或速度（兼容没有报警详细信息时显示外层数据）
 * @param alarmData 报警数据
 * @param innerKey 有报警详细信息时使用的字段key
 * @param outerKey 没有报警详细信息时使用的字段key
 * @param format 是否需要格式化显示时间
 */
export const displayJtDeviceInfo = (
  alarmData: any,
  innerKey: string,
  outerKey: string,
  format?: boolean
) => {
  let alarmGroup = '';
  if (alarmData.contentMap?.adas) {
    alarmGroup = 'adas';
  } else if (alarmData.contentMap?.blind) {
    alarmGroup = 'blind';
  } else if (alarmData.contentMap?.driver) {
    alarmGroup = 'driver';
  }
  if (alarmGroup) {
    const matchValue = alarmData.contentMap?.[alarmGroup]?.[innerKey];
    return format ? formatTime(matchValue) : matchValue;
  } else {
    const matchValue = alarmData[outerKey];
    return format ? formatTime(matchValue) : matchValue;
  }
};
