/**
 * 虚拟设备
 */
import React, { useState } from 'react';
import { message, Popconfirm, Card } from 'antd';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import {
  virtualDeviceListRequest,
  virtualDeviceDeleteRequest
} from '@/service/jt808VirtualDevice';
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';

const Jt808VirtualDevice = () => {
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息

  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: virtualDeviceListRequest,
      initRequest: true,
      initPageSize: 40
    });

  // 删除虚拟设备
  const deleteVirtualDevice = async (id: number) => {
    const res = await virtualDeviceDeleteRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getData();
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '设备SN',
      dataIndex: 'sn',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '终端手机号',
      dataIndex: 'phone',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '省城ID',
      dataIndex: 'provinceId',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '市县城ID',
      dataIndex: 'cityId',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '制造商ID',
      dataIndex: 'makerId',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '终端型号',
      dataIndex: 'deviceModel',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '车牌颜色',
      dataIndex: 'plateColor',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: 'token',
      dataIndex: 'token',
      width: 100,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/808-virtual-device/edit"
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除该虚拟设备吗?"
              onConfirm={() => deleteVirtualDevice(item.id)}
            >
              <PermissionButton
                operateType="/car-admin/808-virtual-device/delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        );
      }
    }
  ];
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/808-virtual-device/add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        reloadFunc={getData}
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
      />
      {['add', 'edit'].includes(operateInfo.type) && (
        <OperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          onClose={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
    </Card>
  );
};

export default Jt808VirtualDevice;
