/**
 * 传感器方案-传感器设备选择器
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { omit } from 'lodash';
import { sensorDeviceListRequest } from '@/service/sensorDeviceManage';

interface SensorDeviceSelectProps extends SelectProps {
  value?: string; // 选择的设备
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField?: string; // 作为选择器value的字段
  requestParams?: any; // 请求参数
}
const SensorDeviceSelect = (props: SensorDeviceSelectProps) => {
  const { value, onChange, valueField, requestParams = {} } = props;
  const [sensorDeviceList, setSensorDeviceList] = useState<any>([]); // 设备类型列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>(); // 选择的设备类型

  // 获取传感器设备列表数据
  const getSensorDeviceList = async () => {
    const res = await sensorDeviceListRequest({
      page: 1,
      limit: 999,
      ...requestParams
    });
    const data = (res.data?.data || []).map((item: any) => {
      const { name, model, version, supplierName, id } = item;
      return {
        label: `${name}-${model}-${version}(${supplierName})`,
        value: valueField ? item[valueField] : id
      };
    });
    setSensorDeviceList(data);
  };

  useEffect(() => {
    getSensorDeviceList();
  }, []);

  // 监听选择的设备进行赋值
  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const selectOwnProps = omit(props, ['valueField', 'requestParams']);
  return (
    <Select
      {...selectOwnProps}
      optionFilterProp="label"
      allowClear
      showSearch
      options={sensorDeviceList}
      onChange={onChange}
      value={selectValue}
      placeholder="请选择"
    />
  );
};

export default SensorDeviceSelect;
