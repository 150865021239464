/**
 * 修改密码
 */
import React, { useState } from "react";
import { Modal, Form, Input, message } from 'antd';
import { modifyPasswordRequest } from '@/service/userManage';

interface ModifyPasswordModalProps {
  closeModal: () => void;
}
const ModifyPasswordModal = (props: ModifyPasswordModalProps) => {
  const [form] = Form.useForm();
  const { closeModal } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      delete params.password;
      const res = await modifyPasswordRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success('修改成功');
        closeModal();
      }
    }
  }

  // 密码改变
  const changePassword = (e: any) => {
    if (e?.target.value === form.getFieldValue('newPassword')) {
      form.setFields([{ name: 'newPassword', errors: [], value: form.getFieldValue('newPassword') }]);
    }
  }

  // 校验两次密码一致
  const validatorPassword = (rule: any, value: string) => {
    const { getFieldValue } = form;
    const password = getFieldValue('password');
    if (password && value && value === password) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('请保证两次密码输入一致'));
    }
  }
  
  return (
    <Modal
      title='修改密码'
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="旧密码"
          name="oldPassword"
          rules={[{ required: true, message: '请输入旧密码' }]}
        >
          <Input.Password placeholder='请输入旧密码' />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="password"
          rules={[{ required: true, message: '请输入新密码' }]}
        >
          <Input.Password placeholder='请输入新密码' onChange={changePassword} />
        </Form.Item>
        <Form.Item
          label="确认新密码"
          name="newPassword"
          required
          rules={[
            { validator: (rule: any, value: string) => validatorPassword(rule, value) }
          ]}
        >
          <Input.Password placeholder='请确认新密码' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModifyPasswordModal;
    