/**
 * 操作用户(添加或编辑)
 */
import React, { useEffect, useState, Fragment } from 'react';
import { Modal, Form, Input, message, Switch, Select } from 'antd';
import dayjs from 'dayjs';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import DatePicker from '@/components/datePicker';
import { upsertUserRequest } from '@/service/userManage';
import { roleListRequest } from '@/service/roleManage';
import { groupCategoryRequest } from '@/service/groupManage';
import { formatTime } from '@/utils/formatTime';
import { getInnerPermissionEnable } from '@/utils/permission';
import { guestCateId } from '@/constants/common';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const noExpiryDate = '2029-12-31T23:59:59'; // 不设置过期时间时使用的时间
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [roleList, setRoleList] = useState([]); // 角色列表
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [submitLoading, setSubmitLoading] = useState(false); // 提交的loading

  // 获取角色列表
  const getRoleList = async() => {
    const res = await roleListRequest({page:1,limit:1000});
    const roleData: any = [];
    (res.data?.data || []).forEach((item: any) => {
      roleData.push({
        ...item,
        label: `${item.name}（${item.groupName}）`
      })
    });
    setRoleList(roleData);
  }

  // 编辑的时候赋值
  useEffect(() => {
    getRoleList();
    if (type === 'edit') {
      const params = {...data};
      // 兼容处理用户角色参数
      if (data.roleNew) {
        params.roles = [Number(data.roleNew)];
      }
      if (data.roles?.length) {
        params.roles = data.roles.map((item: any) => item.id);
      }
      if (params.multiCate) {
        params.cateId = JSON.parse(params.multiCate);
        params.multigroup = true;
      }
      // 处理过期时间字段
      if (data.expiryDate && data.expiryDate !== noExpiryDate) {
        params.expiryDate = dayjs(data.expiryDate);
      } else {
        delete params.expiryDate;
      }
      form.setFieldsValue(params)
    }
  }, []);

  // 获取选中节点最近的公共父节点
  const getTreeNodeCommonParent = (data: any, selectKeys: any) => {
    const selectKeysArr = Array.isArray(selectKeys) ? selectKeys : [selectKeys];
    const allPaths: any = [];
    let minLength: number = 0;
    const addTreePath = (data: any, parentKey?: any) => {
      data.forEach((item: any) => {
        const paths = parentKey ? [...parentKey, item.id] : [item.id];
        if (selectKeysArr.includes(item.id)) {
          allPaths.push(paths);
          minLength = minLength ? Math.min(minLength, paths.length) : paths.length;
        }
        if (Array.isArray(item.children) && item.children.length > 0) {
          addTreePath(item.children, paths);
        }
      })
    }
    addTreePath(data);
    const commonArr = allPaths.reduce((prev: any, current: any) => {
      let matchLength = 0;
      prev.forEach((item: any, index: number) => {
        if (item === current[index]) {
          matchLength += 1;
        }
      });
      const returnArr = [...prev];
      returnArr.length = matchLength;
      return returnArr;
    })
    return commonArr[commonArr.length - 1];
  }

  // 提交
  const submit = async(values: any) => {
    try {
      setSubmitLoading(true);
      if (submitNoRepetition) {
        setSubmitNoRepetition(false)
        const { cateId, expiryDate } = values;
        const params = {...values};
        params.disabled = Boolean(values.disabled);
        params.roles = roleList.filter((item: any) => values.roles.includes(item.id));
        if (type === 'edit') {
          params.id = data.id;
        }
        delete params.multigroup;
        // 选择多分组时处理分组相关的参数
        if (values.multigroup) {
          const res = await groupCategoryRequest();
          const commonParent = getTreeNodeCommonParent(res.data?.data || [], cateId);
          params.multiCate = JSON.stringify(Array.isArray(cateId) ? cateId : [cateId]);
          params.cateId = commonParent;
        } else {
          params.multiCate = '';
        }
        if (expiryDate) {
          params.expiryDate = formatTime(
            dayjs(expiryDate).endOf('day'),
            'YYYY-MM-DDTHH:mm:ss'
          );
        } else if (data?.expiryDate) {
          // 编辑时将过期时间删除时设置过期时间
          params.expiryDate = noExpiryDate;
        }
        const res = await upsertUserRequest(params);
        if (res.status == 200) {
          setSubmitNoRepetition(true)
        }
        setSubmitLoading(false);
        if (res.data?.code === 0) {
          message.success(type === 'add' ? '添加成功' : '修改成功');
          closeModal();
          reloadData();
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  }
  
  // 是否显示创建演示账号表单项
  const showCreateGuest = getInnerPermissionEnable(
    '/car-admin/user-manage/show-guest'
  );
  return (
    <Modal
      title={type === 'add' ? '添加用户' : '编辑用户'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
      okButtonProps={{ loading: submitLoading }}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        {showCreateGuest && type === 'add' && (
          <Form.Item
            label="创建演示账号"
            name="createGuest"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        )}
        <Form.Item noStyle shouldUpdate>
          {/* 不是创建演示账号时才显示分组选择 */}
          {() => {
            const createGuest = form.getFieldValue('createGuest');
            if (!createGuest) {
              return (
                <Fragment>
                  <Form.Item
                    label="支持用户查看多分组"
                    name="multigroup"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  {/* 根据是否支持查看多分组处理分类是否可以多选 */}
                  <Form.Item noStyle shouldUpdate>
                    {() => (
                      <Form.Item
                        label="分类选择"
                        name="cateId"
                        rules={[{ required: true, message: '请选择分类' }]}
                      >
                        <CategoryTreeSelect treeCheckable={form.getFieldsValue()?.multigroup} />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Fragment>
              )
            }
          }}
        </Form.Item>
        <Form.Item
          label="用户角色"
          name="roles"
          rules={[{ required: true, message: '请选择用户角色' }]}
        >
          <Select
            fieldNames={{
              value: 'id',
            }}
            options={roleList}
            mode="multiple"
            optionFilterProp="name"
            placeholder="请选择用户角色"
          />
        </Form.Item>
        <Form.Item
          label="用户昵称"
          name="nickname"
          rules={[{ required: true, message: '请输入用户昵称' }]}
        >
          <Input placeholder='请输入用户昵称' />
        </Form.Item>
        <Form.Item
          label="部门"
          name="department"
          rules={[{ required: true, message: '请输入部门' }]}
        >
          <Input placeholder='请输入部门' />
        </Form.Item>
        <Form.Item
          label="岗位"
          name="position"
          rules={[{ required: true, message: '请输入岗位' }]}
        >
          <Input placeholder='请输入岗位' />
        </Form.Item>
        <Form.Item
          label="负责人"
          name="header"
          rules={[{ required: true, message: '请输入负责人' }]}
        >
          <Input placeholder='请输入负责人' />
        </Form.Item>
        <Form.Item
          label="账号"
          name="mobile"
          rules={[
            { required: true, message: '请输入账号' },
          ]}
        >
          <Input placeholder='请输入手机号' />
        </Form.Item>
        {/* 添加时显示 */}
        {type === 'add' &&
          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input type="password"  placeholder='请输入密码' />
          </Form.Item>
        }
        <Form.Item
          label="电子邮箱"
          name="email"
        >
          <Input placeholder='请输入电子邮箱' />
        </Form.Item>
        <Form.Item
          label="视频剩余时长"
          name="videoDuration">
          <Input placeholder='请输入视频剩余时长' />
        </Form.Item>
        <Form.Item label="过期时间" name="expiryDate">
          <DatePicker
            placeholder="请选择账号过期时间"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="状态"
          name="disabled"
          valuePropName="checked"
        >
          <Switch checkedChildren="禁用" unCheckedChildren="启用" className="disabledSwitch" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
    