import { AlarmTypeEnum, alarmTypeData } from '@/constants/alarm';

// 获取详细的报警类型
export const getDetailAlarmType = (data: any) => {
  if (data.alarm_type === 1) {
    return 'FCW预警';
  } else if (data.alarm_type === 2) {
    if (data.alarm_info) {
      if (data.alarm_info.AebsStatus) {
        let key = data.alarm_info.AebsStatus;
        if (typeof(data.alarm_info.AebsStatus) === "object") {
          key = data.alarm_info.AebsStatus[0];
        }
        const alarmTypeObj: any = {
          0: 'AEB制动 (无制动)',
          1: 'AEB制动 (双目制动)',
          2: 'AEB制动 (毫米波制动)',
          3: 'AEB制动 (超声波制动)',
          4: 'AEB制动 (角雷达制动)'
        }
        // 针对崇明公交做临时的报警过滤展示
        if (data.cate_path && /^#0#1#3438#3439#1712#1713.*/.test(data.cate_path)) {
          if (Number(key) === 3) {
            return 'AEB制动';
          }
        }
        return alarmTypeObj[key] || 'AEB制动';
      } else {
        return 'AEB制动';
      }
    } else {
      return 'AEB制动';
    }
  } else if (data.alarm_type === 3) {
    return '起步阻止信息';
  } else if (data.alarm_type === 4) {
    return 'LDW预警信息';
  } else if (data.alarm_type === 5) {
    return 'HMW预警信息';
  } else if (data.alarm_type === 7) {
    return '角雷达报警';
  }
}

// 获取报警类型的展示
export const getAlarmTypeDisplay = (data: any) => {
  const { alarmType, aebStatus, catePath } = data;
  const macthAlarmTypeLabel =
    alarmTypeData.find(
      (item: { label: string; value: number }) => item.value === alarmType
    )?.label || alarmType;
  if (alarmType === AlarmTypeEnum.aeb) {
    if (aebStatus) {
      // 针对崇明公交的超声波制动做临时的报警过滤展示
      if (
        catePath &&
        /^#0#1#3438#3439#1712#1713.*/.test(catePath) &&
        aebStatus.includes('超声波')
      ) {
        return macthAlarmTypeLabel;
      }
      // 显示AEB制动的触发源
      return `${macthAlarmTypeLabel} (${aebStatus})`;
    } else {
      return macthAlarmTypeLabel;
    }
  }
  return macthAlarmTypeLabel;
};