/**
 * 添加DBC文件
 */
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, message, Button, Upload, Input, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { isNil } from 'lodash';
import { upsertCanInfoRequest } from '@/service/carCan';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';
import { downloadFile } from '@/utils/download';

interface OperateDbcModalProps {
  data?: any;
  editIndex?: number;
  onClose: () => void;
  reloadData?: (id: string) => void;
}
const OperateDbcModal = (props: OperateDbcModalProps) => {
  const [form] = Form.useForm();
  const hasUploadingRef = useRef(false);
  const [saveLoading, setSaveLoading] = useState(false); // 是否保存中
  const { data, onClose, reloadData, editIndex } = props;

  useEffect(() => {
    setFormValues();
  }, []);

  // 编辑时处理文件相关内容
  const operateFileDetail = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item: any) => ({
        name: item.fileName,
        url: item.preview,
        status: 'done',
        response: { data: { key: item.key, preview: item.preview } }
      }));
    } else {
      return [];
    }
  };

  // 表单赋值
  const setFormValues = () => {
    const setValues: any = {
      brand: data?.brand,
      model: data?.model
    };
    // 编辑的时候处理上传文件的赋值
    if (!isNil(editIndex)) {
      const currentFile = (data.file || [])[editIndex];
      setValues.canFileKey = operateFileDetail(currentFile?.canFiles);
      setValues.dbcFileKey = operateFileDetail(
        currentFile?.dbcFiles ? [currentFile?.dbcFiles] : []
      );
      setValues.ps = currentFile?.ps || '';
    }
    form.setFieldsValue(setValues);
  };

  // 处理上传组件的value
  const formatUploadValue = (e: any) => {
    if (Array.isArray(e)) {
      return e[0]?.fileList;
    }
    return e?.fileList;
  };

  // 保存时处理上传的文件数据
  const operateFile = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      return data.map((item: any) => {
        if (item?.status === 'uploading') {
          hasUploadingRef.current = true;
        }
        return item?.response?.data?.key;
      });
    } else {
      return [];
    }
  };

  // 调用接口保存协议
  const onSave = async (params: any) => {
    if (data?.id) {
      params.id = data.id;
    }
    try {
      setSaveLoading(true);
      const res = await upsertCanInfoRequest(params);
      setSaveLoading(false);
      if (res.data?.code === 0) {
        message.success('保存成功');
        onClose();
        reloadData && reloadData(res?.data?.data);
      }
    } catch (error) {
      setSaveLoading(false);
    }
  };

  // 保存车辆协议信息
  const onFinish = async (values: any) => {
    if (!values.dbcFileKey?.length && !values.canFileKey?.length) {
      message.error('请上传DBC文件或CAN报文文件');
      return;
    }
    try {
      const params = { ...values };
      // 处理文件内容
      params.dbcFileKey = operateFile(values.dbcFileKey);
      params.canFileKey = operateFile(values.canFileKey);
      const canDbc: any = [];
      (data?.file || []).forEach((item: any) => {
        if (item?.dbcFiles?.key || item?.canFiles?.length) {
          const canFileKey = (item?.canFiles || []).map(
            (canFile: any) => canFile.key
          );
          canDbc.push({
            dbcFileKey: item?.dbcFiles?.key || '',
            canFileKey: canFileKey?.length ? canFileKey : [],
            dbcId: item?.dbcId,
            ps: item.ps || ''
          });
        }
      });
      // 区分编辑和添加时对文件字段的处理
      if (isNil(editIndex)) {
        canDbc.push({
          dbcFileKey: params.dbcFileKey[0] || '',
          canFileKey: params.canFileKey?.length ? params.canFileKey : [],
          ps: params.ps || ''
        });
      } else {
        canDbc[editIndex] = {
          dbcFileKey: params.dbcFileKey[0] || '',
          canFileKey: params.canFileKey?.length ? params.canFileKey : [],
          dbcId: canDbc[editIndex]?.dbcId,
          ps: params.ps || ''
        };
      }
      params.canDbc = canDbc;
      delete params.canFileKey;
      delete params.dbcFileKey;
      delete params.ps;
      if (hasUploadingRef.current) {
        message.info('当前有正在上传的文件，请等待上传完毕再保存。');
        hasUploadingRef.current = false;
      } else {
        onSave(params);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 下载已经上传成功的文件
  const uploadFileClick = (file: any) => {
    downloadFile(file?.response?.data?.preview, file.name);
  };

  return (
    <Modal
      title="添加DBC文件"
      open
      onCancel={onClose}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
      okButtonProps={{ loading: saveLoading }}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="车辆品牌"
          name="brand"
          rules={[{ required: true, message: '请填写车辆品牌' }]}
        >
          {/* 传入参数有车辆品牌时禁用 */}
          <Input disabled={Boolean(data)} />
        </Form.Item>
        <Form.Item
          label="车型"
          name="model"
          rules={[{ required: true, message: '请填写车型' }]}
        >
          {/* 传入参数有车型时禁用 */}
          <Input disabled={Boolean(data)} />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item
              label="DBC文件上传"
              name="dbcFileKey"
              valuePropName="fileList"
              getValueFromEvent={formatUploadValue}
            >
              <Upload
                action="/api/v2/resource/upload/canFile"
                headers={{
                  access_token: localStorageUtil.getItem(
                    LocalStorageKeyEnum.access_token
                  )
                }}
                onPreview={uploadFileClick}
                maxCount={1}
              >
                <Tooltip
                  title={
                    form.getFieldValue('dbcFileKey')?.length
                      ? '只允许上传一个DBC文件'
                      : ''
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={Boolean(form.getFieldValue('dbcFileKey')?.length)}
                  >
                    DBC文件上传
                  </Button>
                </Tooltip>
              </Upload>
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="DBC文件对应CAN报文上传"
          name="canFileKey"
          valuePropName="fileList"
          getValueFromEvent={formatUploadValue}
        >
          <Upload
            action="/api/v2/resource/upload/canFile"
            headers={{
              access_token: localStorageUtil.getItem(
                LocalStorageKeyEnum.access_token
              )
            }}
            multiple
            onPreview={uploadFileClick}
          >
            <Button icon={<UploadOutlined />}>CAN报文上传</Button>
          </Upload>
        </Form.Item>
        <Form.Item label="描述" name="ps">
          <Input.TextArea placeholder="请填写描述" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateDbcModal;
