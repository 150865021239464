/**
 * 其它数据表格
 */
import React from 'react';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { formatTime } from '@/utils/formatTime';

interface OtherDataTableProps {
  dataSource: any;
}
const OtherDataTable = (props: OtherDataTableProps) => {
  const { dataSource } = props;

  // 表格列配置
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '上报时间',
      dataIndex: 'reportedTime',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '事件类型',
      dataIndex: 'eventCode',
      width: 120,
      render: () => <div>震动</div>
    }
  ];

  return (
    <CommonTable
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      size="small"
      columnFilter={false}
    />
  );
};

export default OtherDataTable;
