/**
 * 实时监控
 */
import request from "@/utils/request";

// 记录视频播放和关闭
export const recordSwitchRequest = (data: any) => {
  return request.post('/api/v2/video/log', data)
}

//获取登录人的剩余观看时长
export const getVideoDurationRequest = () => {
  return request.get('/api/v2/videoInvest/my/duration')
}
