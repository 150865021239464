/**
 * 表格形式可删减的form表单
 */
import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { isFunction } from 'lodash';
import styles from './index.module.scss';

interface FormTableProps {
  form?: any; // 表单实例
  field: string; // 整体表单项的field
  title?: string; // 标题
  showAdd?: boolean; // 是否显示添加按钮
  showDelete?: boolean; // 是否显示删除按钮
  tableFormItems: any; // 表格内表单项配置
  validatorFunc?: (value: any) => void; // 整体表单项的校验方法
  initialValue?: any; // 表单项的初始值
}

const FormTable = (props: FormTableProps) => {
  const {
    form,
    field,
    title,
    showAdd = true,
    showDelete = true,
    tableFormItems,
    validatorFunc,
    initialValue
  } = props;

  // 校验整体表单项
  const validatorFormList = async (rule: any, value: any) => {
    if (validatorFunc) {
      return validatorFunc(value);
    } else {
      return Promise.resolve();
    }
  };

  const tableTitles = tableFormItems.map((item: any) => ({
    title: item.title,
    width: item.width
  }));
  if (showDelete) {
    tableTitles.push({ title: '操作', width: 120 });
  }
  return (
    <Form.List
      name={field}
      rules={[
        { validator: (rule: any, value: any) => validatorFormList(rule, value) }
      ]}
      initialValue={initialValue}
    >
      {(fields, { add, remove }, { errors }) => (
        <div className={styles.formTableBox}>
          <div className={styles.titleBox}>
            <div>{title}</div>
            {showAdd && (
              <Button onClick={() => add()} icon={<PlusOutlined />}>
                添加
              </Button>
            )}
          </div>
          <Row className={styles.itemTitleBox}>
            {tableTitles.map((item: any) => (
              <Col style={{ width: `${item.width}px` }} key={item.title}>
                {item.title}
              </Col>
            ))}
          </Row>
          {fields.map(({ key, name, ...restField }) => {
            return (
              <Row className={styles.itemContentBox} key={key}>
                {tableFormItems.map((item: any, index: number) => {
                  const { formItem, width } = item;
                  return (
                    <Form.Item noStyle shouldUpdate key={index}>
                      {() => {
                        const formValues = form.getFieldsValue();
                        return (
                          <Col style={{ width: `${width}px` }}>
                            {isFunction(formItem)
                              ? formItem(name, formValues, restField)
                              : formItem}
                          </Col>
                        );
                      }}
                    </Form.Item>
                  );
                })}
                {showDelete && (
                  <Col style={{ width: '120px' }}>
                    <Form.Item>
                      <Button type="text" onClick={() => remove(name)}>
                        删除
                      </Button>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            );
          })}
          <Form.ErrorList errors={errors} />
        </div>
      )}
    </Form.List>
  );
};

export default FormTable;
