/**
 * 传感器方案-超声波安装方案相关请求
 */
import request from '@/utils/request';

export interface SensorUltrasonicInstallListParams {
  page: number;
  limit: number;
  name?: string;
  id?: string;
  userId?: number;
  beginTime?: string;
  endTime?: string;
}

// 获取传感器-超声波安装方案列表
export const sensorUltrasonicInstallListRequestPath =
  '/api/v2/ultraInstall/list';
export const sensorUltrasonicInstallListRequest = (
  params: SensorUltrasonicInstallListParams
) => {
  return request.get(sensorUltrasonicInstallListRequestPath, {
    params
  });
};

export interface UpsertSensorUltrasonicInstallParams {
  name: string;
  description?: string;
  radarFront?: string;
  radarBehind?: string;
  radarLeft?: string;
  radarRight?: string;
  id?: number;
}
// 添加/编辑传感器-超声波安装方案
export const upsertSensorUltrasonicInstallRequest = (
  data: UpsertSensorUltrasonicInstallParams
) => {
  return request.post('/api/v2/ultraInstall/upsert', data);
};

// 删除传感器-超声波安装方案
export const deleteSensorUltrasonicInstallRequest = (id: number) => {
  return request.delete(`/api/v2/ultraInstall/${id}`);
};
