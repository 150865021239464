/**
 * 监控回放
 */
import React, { useEffect, useState } from 'react';
import { Button, Form, Select, Row, Col, Input, message } from 'antd';
import byskplayer from './byskplayer';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';
import CarTree from '@/components/carTree';
import axios from 'axios';
import flvjs from './flv';

const { RangePicker } = DatePicker;

const PlaybackMonitoring = () => {
  const [monitoring, setMonitoring] = useState({device:'',channel:'',title:''}); // 行程数据
  const [realPlayer, setRealPlayer] = useState({
    allocate(one:any){},
    destroy(){},
    record:{
      query(one:any,two:any){},
      open(one:any,two:any,three:any,four:any){},
      close(one:any,two:any,three:any){}
    },
    gaDownload:{
      downloadFile(one:any,two:any){}
    },
    ftp:{
      downloadFile(one:any,two:any){}
    }
  }); // 储存player实例
  const [download, setDownload] = useState(true); //文字展示与隐藏
  const [timeList, setTimeList] = useState<any>([]); // 时间段列表
  const [timeQuantum, setTimeQuantum] = useState<any>([]); // 时间段
  const [channel, setChannel] = useState<number>(); // 通道号
  const [getToken, setGetToken] = useState(''); // 获取token
  const [textDisplay, settextDisplay] = useState('block'); //文字展示与隐藏
  const [flyPlayerDisplay, setFlyPlayerDisplay] = useState('block'); //锐齿慢展示与隐藏
  const [playerDisplay, setPlayerDisplay] = useState('none'); //珀仕杰展示与隐藏
  const [videoDisplay, setvideoDisplay] = useState('none'); //video展示与隐藏
  const [manufacturerType, setManufacturerType] = useState<any>(); //储存厂家
  const [realFlvPlayer, setRealFlvPlayer] = useState<any>();

  let playList:any = [];
  let player:any = null

  useEffect(() => {
    axios({
      method: 'post',
      url: 'https://api.sdv2x.com/vehicle-openapi/sys/login',
      data: {
        "appCid": "6cd4420d49834001aba45d298a1b39e5",
        "appSecret": "73478508997ede8d2759a4c3a9806a9e"
      }
    }).then((res) => {
      setGetToken(res.data.data.Authorization)
    })
  }, []);

  const treeSelect = (selectCarInfo: any) => {
    //@ts-ignore
    document.querySelector('.playbackcontainer_box').innerHTML = '<div id="playbackcontainer"></div>';
    const { channel, terminal, manufacturer, title } = selectCarInfo;
    const ccc = channel
    const aaa = []
    for (var i = 1; i <= ccc; i++) {
      aaa.push(i)
    }
    const bbb = aaa.toString()
    const carList = { device: terminal, channel: bbb, title:title };
    setMonitoring(carList)
    setDownload(true)

    if ( manufacturer == '1') {
      setDownload(false)
      setPlayerDisplay('block')
      setFlyPlayerDisplay('none')
      player = new byskplayer({
        id: 'playbackcontainer',
        isReal: false,
        isRecord: true, 
        playerNum:1,
        userId: 'apitest',
        userkey: 'dbd78671-f01c-47db-98c6-06ce1cd400f6',
        baseURL:'wss://videostream.car900.com',
        isSwitchCodetypeOnFullscreen: false, //全屏是否切换高清，默认false
      });
      setRealPlayer(player)
      setManufacturerType(1)
    } else if ( manufacturer == '2' ) {
      settextDisplay('none')
      setFlyPlayerDisplay('block')
      setPlayerDisplay('none')
      setvideoDisplay('block')
      setManufacturerType(2)
    } else {
      settextDisplay('block')
      setFlyPlayerDisplay('block')
      setPlayerDisplay('none')
      setvideoDisplay('none')
      message.info("该车辆暂无监控");
    }

  }

  // 查询表单
  const onSearch = (values: any) => {
    if (monitoring.device === '') {
      message.info("请选择车辆");
      return;
    }
    if (values.num === undefined) {
      message.info("请输入通道号");
      return;
    }
    const num = Number(values.num)
    setChannel(num)
    if ( manufacturerType == 1 ) {
      let begintime = Math.round(new Date(dayjs(values.time[0]).format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000);
      let endtime = Math.round(new Date(dayjs(values.time[1]).format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000);
      let params = {
        device: monitoring.device, //终端号
        channel: num, //通道号
        protocolType: 1, //协议类型，1：GF-1078，2：GA系列
        begintime: begintime, //开始时间（时间戳，精确至秒）
        endtime: endtime, //结束时间（时间戳，精确至秒）
        alarmSign: 0, //报警类型（目前只支持：0）
      };
      realPlayer.record.query(params, (res: any) => {
        if (res.status == 1) {
          message.success("时间段查询成功");
          const data: any = {
            time: [],
          };
          (res.recordfiles).forEach((item: any) => {
            let stime = new Date(item.begintime);
            let etime = new Date(item.endtime);
            let value = new Date(stime.setHours(stime.getHours() + 8)).toJSON().substring(0, 19).replace('T', ' ') + ' 至 ' + new Date(etime.setHours(etime.getHours() + 8)).toJSON().substring(0, 19).replace('T', ' ');
            const timeLists = { label: value, value: value };
            data.time.push(timeLists);
          })
          setTimeList(data.time)
        }
      })
    } else if ( manufacturerType == 2 ) {
      let begintime = dayjs(values.time[0]).format('YYYY-MM-DD HH:mm:ss');;
      let endtime = dayjs(values.time[1]).format('YYYY-MM-DD HH:mm:ss');
      axios({
        method: 'post',
        url: 'https://api.sdv2x.com/vehicle-openapi/historyreplay/playback/video/list',
        headers: {
          Authorization: getToken
        },
        data: {
          licenseNum: monitoring.title,
          startTime: begintime,
          endTime: endtime,
          location: 0,
          resourceType: 0,
          streamType: 0,
          storageType: 0,
          channelNum: num
        }
      }).then((res) => {
        if (res.data.code == 500) {
          message.error("服务器异常")
        }
        if (res.data.data !== null) {
          message.success("时间段查询成功");
          const data: any = {
            time: [],
          };
          (res.data.data).forEach((item: any) => {
            let value = item.startTime + '至' + item.endTime;
            const timeLists = { label: value, value: value };
            data.time.push(timeLists);
          })
          setTimeList(data.time)
        }
      })
    }
  }

  // 时间段选择
  const timeSelect = (value:any) => {
    setTimeQuantum(value)
  }

  // 播放回放
  const openPlayback = () => {
    if (timeQuantum.length === 0) {
      message.info("请选择时间段");
      return;
    }
    if ( manufacturerType == 1) {
      const begintime = Math.round(new Date(dayjs(timeQuantum.slice(0,20)).format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000)
      const endtime = Math.round(new Date(dayjs(timeQuantum.slice(22)).format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000)
      const protocolType = 1;
      const SpecialSign = 0;
      const playerType = 0;
      const datatype = 0;
      const codetype = 1;
      let params = {
        device: monitoring.device,
        channel: channel,
        protocolType: protocolType,
        SpecialSign,
        playerType,
        datatype,
        codetype,
        begintime: begintime,
        endtime: endtime,
        fileName: null,
        plate: '测试车辆',
        vehicleId: '4321',
        groupId: '1'
      };
      const T = 'GF-1078';
      //GB-1078音视频分离
      if (['GB-1078'].includes(T)) { // todo: 感觉逻辑有问题
        params.datatype = 0;
      }
      let obj = playList.find((p:any) => p.device == monitoring.device && p.channel == monitoring.channel);
      if (obj) return;
      let tidObj:any = {flag:null,ids:[]}
      tidObj = realPlayer.allocate(1);
      if (tidObj.flag == 1) {
        let tid = tidObj.ids[0];
        realPlayer.record.open(tid, params, (res:any) => {
          console.log(res);
        }, {
          hasAudio: !['GB-1078'].includes(T), //GB-1078音视频分开，默认不请求音频
        });
        playList.push({
          ...params
        });
      } else {
        alert(tidObj.msg);
      }
    } else if ( manufacturerType == 2 ) {
      axios({
        method: 'post',
        url: 'https://api.sdv2x.com/vehicle-openapi/historyreplay/playback/video/play',
        headers: {
          Authorization: getToken
        },
        data: {
          licenseNum: monitoring.title,
          startTime: timeQuantum.slice(0,19),
          endTime: timeQuantum.slice(20),
          resourceType: 0,
          streamType: 0,
          storageType: 0,
          channelNum: channel
        }
      }).then((res) => {
        console.log('res',res)
        //@ts-ignore
        if (flvjs.isSupported()) {
          var videoElement = document.getElementById('_video');
          //@ts-ignore
          var flvPlayer = flvjs.createPlayer({
              type: 'flv',
              url: res.data.data, // 你的url地址
              isLive: true,
              enableStashBuffer: true,
              stashInitialSize: 64,
              autoCleanupSourceBuffer: true,
              autoCleanupMaxBackwardDuration: 5,
              autoCleanupMinBackwardDuration: 1,
              lazyLoadMaxDuration: 5
          });
          flvPlayer.attachMediaElement(videoElement);
          flvPlayer.load();
          flvPlayer.play();
        } else {
            console.error('当前浏览器不支持当前视频播放模式！');
        }
        setRealFlvPlayer(flvPlayer)
      })
    }
  }

  // 暂停回放
  const closePlayback = () => {
    if ( manufacturerType == 1) {
      let index = playList.findIndex((p:any) => p.device == monitoring.device && p.channel == channel);
      let obj = playList[index];
      if (obj) realPlayer.record.close(obj.tid, {
        device: obj.device,
        channel: obj.channel,
        protocolType: obj.protocolType
      }, (res:any) => {
        console.log(res);
      });
      playList.splice(index, 1);
    } else if ( manufacturerType == 2 ) {
      realFlvPlayer.detachMediaElement()
      realFlvPlayer.destroy()
    }

  }

  // 下载回放
  const downloadPlayback = () => {
    if ( manufacturerType == 1) {
      const begintime = Math.round(new Date(dayjs(timeQuantum.slice(0,20)).format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000)
      const endtime = Math.round(new Date(dayjs(timeQuantum.slice(22)).format('YYYY-MM-DD HH:mm:ss')).getTime() / 1000)
      const protocolType = 1;
      const SpecialSign = 0;
      const datatype = 0;
      const codetype = 1;
      let params = {
        device: monitoring.device,
        channel: channel,
        protocolType: protocolType,
        begintime: begintime,
        endtime: endtime,
        datatype,
        codetype,
        SpecialSign,
        fileName: null,
        plate: '测试车辆',
        vehicleId: '4321',
        groupId: '1'
      };
      realPlayer.ftp.downloadFile(params, (res:any) => {
        console.log(res);
        if (res.status && res.media_address) {
          saveAsFile(res.media_address.video_address,res);
        }
      })
    } else if ( manufacturerType == 2 ) {
    }
  }

  function saveAsFile(src:any, filename:any) {
    let a = document.createElement('a');
    a.href = src;
    a.target = '_self';
    a.download = filename || src.substr(src.lastIndexOf('/'));
    a.click();
  }

  return (
    <div className={styles.trackPlaybackBox}>
      {/* 左边栏车辆列表（公共组件） */}
      <div className={styles.carTree}>
        <CarTree
          treeSelect={treeSelect}
          selectableTypes={['car']}
        />
      </div>
      <div className={styles.monitoring}>
        <div className={styles.monitoringTitle}>
          监控回放
        </div>
        <Form onFinish={onSearch}>
          <Row className={styles.rows}>
            <Col span={15}>
              <Form.Item label="时间范围" name="time"  initialValue={[dayjs(`${dayjs().format('YYYY-MM-DD')} 00:00:00`), dayjs(`${dayjs().format('YYYY-MM-DD')} 23:59:59`)]}>
                <RangePicker style={{ width: 400 }} showTime allowClear={false}/>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="输入通道号" name="num">
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item>
                <Button type="primary" htmlType="submit">查询</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row className={styles.rows}>
          <Col span={15}>
            <Form.Item label="选时间段" name="time">
              <Select style={{ width: 400 }} placeholder="请选择时间段" options={timeList} onSelect={timeSelect} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={openPlayback}>播放</Button>
          </Col>
          <Col span={3}>
            <Button type="ghost" onClick={closePlayback}>停止</Button>
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={downloadPlayback} disabled={download}>下载</Button>
          </Col>
        </Row>
        <div className={'playbackcontainer_box'} style={{display:playerDisplay,height: '100%'}}>
          <div id='playbackcontainer'></div>
        </div>
        <div className={styles.monitoringContent} style={{display:flyPlayerDisplay}}>
          <video id="_video" autoPlay controls={true} style={{display:videoDisplay}}></video>
          <div className={styles.text} style={{display:textDisplay}}>请从左侧选择需要播放监控回放的车辆</div>
        </div>
      </div>
    </div>
  )
}

export default PlaybackMonitoring;