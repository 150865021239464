/**
 * 操作客户文档(添加或编辑)
 */
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, message, Switch, Select, Upload, Button } from 'antd';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import UploadImageVideo from "@/components/uploadImageVideo";
import localStorageUtil from '@/utils/localStorageUtil';
import { upsertFileRequest } from '@/service/clientFile';
import { groupCategoryRequest } from '@/service/groupManage';
import { UploadOutlined } from '@ant-design/icons';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [uploadFileList, setUploadFileList] = useState<any>([]); // 上传成功的文件信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      const params = {...data};
      if (params.cateIds) {
        params.cateId = JSON.parse(params.cateIds);
        params.multigroup = true;
      }
      form.setFieldsValue(params)
    }
  }, []);

  // 获取选中节点最近的公共父节点
  const getTreeNodeCommonParent = (data: any, selectKeys: any) => {
    const selectKeysArr = Array.isArray(selectKeys) ? selectKeys : [selectKeys];
    const allPaths: any = [];
    let minLength: number = 0;
    const addTreePath = (data: any, parentKey?: any) => {
      data.forEach((item: any) => {
        const paths = parentKey ? [...parentKey, item.id] : [item.id];
        if (selectKeysArr.includes(item.id)) {
          allPaths.push(paths);
          minLength = minLength ? Math.min(minLength, paths.length) : paths.length;
        }
        if (Array.isArray(item.children) && item.children.length > 0) {
          addTreePath(item.children, paths);
        }
      })
    }
    addTreePath(data);
    const commonArr = allPaths.reduce((prev: any, current: any) => {
      let matchLength = 0;
      prev.forEach((item: any, index: number) => {
        if (item === current[index]) {
          matchLength += 1;
        }
      });
      const returnArr = [...prev];
      returnArr.length = matchLength;
      return returnArr;
    })
    return commonArr[commonArr.length - 1];
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const { cateId } = values;
      const params = {...values};
      if (type === 'edit') {
        params.id = data.id;
      }
      // 选择多分组时处理分组相关的参数
      const resss = await groupCategoryRequest();
      const commonParent = getTreeNodeCommonParent(resss.data?.data || [], cateId);
      params.cateId = commonParent;
      params.cateIds = JSON.stringify(Array.isArray(cateId) ? cateId : [cateId]);
  
      params.fileSource = uploadFileList.key
      params.content = JSON.stringify(uploadSuccessList.map((item: any) => (item.key)))
      const res = await upsertFileRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }


  // 合同上传前校验文件类型
  const beforeFileUpload = (file: any) => {
    const suffix = file.name.substr(file.name.lastIndexOf("."));
    const isLtType = suffix === '.pdf';
    if (!isLtType) {
      message.error('请上传PDF格式文件');
    }
    return isLtType;
  };

  // 上传文件
  const onFileUploadChange = (info:any) => {
    if (info.file.status === 'done') {
      const code = info.file.response?.code;
      if (code === 0) {
        setUploadFileList(info.file.response.data)
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加文件' : '编辑文件'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
      >
        {/* <Form.Item
          label="支持用户查看多分组"
          name="multigroup"
          valuePropName="checked"
        >
          <Switch /> */
        /* </Form.Item> */}
        {/* 根据是否支持查看多分组处理分类是否可以多选 */}
        <Form.Item noStyle shouldUpdate>
          {() => (
            <Form.Item
              label="分类选择"
              name="cateId"
              rules={[{ required: true, message: '请选择分类' }]}
            >
              <CategoryTreeSelect treeCheckable={true} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          label="文件名称"
          name="name"
          rules={[{ required: true, message: '请输入文件名称' }]}
        >
          <Input placeholder='请输入文件名称' />
        </Form.Item>

        <Form.Item
          name="fileSource"
          label="文件上传"
        >
          <Upload 
            name="file" 
            accept="application/pdf"
            action="/api/v2/resource/upload"
            headers={{ access_token: localStorageUtil.getItem('access_token') }}
            beforeUpload={beforeFileUpload}
            onChange={onFileUploadChange}
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>

        <Form.Item label="图片上传">
          <UploadImageVideo
            businessName="文件图片"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.contentList.map((item: any) => ({
              contentType: "image/jpeg",
              key: new URL(item).pathname.slice(1),
              preview: item
            }))}
            multiple
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
    