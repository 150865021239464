/**
 * 驾驶行为分析相关请求
 */
import request from "@/utils/request";

export interface DriveAnalysisRequestParams {
  page: number,
  limit: number;
  beginDate: string;
  endDate: string;
  plateNumber: string;
  type: Array<string>;
}

// 获取驾驶行为分析数据
export const driveAnalysisRequestPath = '/api/v2/car/drivingBehaviorAnalysis';
export const driveAnalysisRequest = (params: DriveAnalysisRequestParams) => {
  return request.get(driveAnalysisRequestPath, {
    params,
  })
}

// 行车记录仪视频添加字幕
export const implantSubtitlesRequest = (key: string) => {
  return request.get(`/api/v2/car/implantSubtitles/${key}`)
}

// 判断是否有视频数据
export const alarmResourceInfoRequest = (data: any) => {
  return request.post('/api/v2/car/alarmResourceInfo', data)
}

// 事故报告
export const driveAnalysisRequestReportPath = '/api/v2/car/drivingBehaviorAnalysisReport';
export const driveAnalysisRequestReport = (params: DriveAnalysisRequestParams) => {
  return request.get(driveAnalysisRequestReportPath, {
    params,
  })
}
