/**
 * 问卷调查记录相关请求
 */
import request from "@/utils/request";

export interface questionnaireSurveyListRequestParams {
    page: number; 
    limit: number;
    plateNumber?: any;
}

export const questionnaireSurveyListRequestPath = '/api/v2/questionnaire/list';

// 获取数据
export const questionnaireSurveyListRequest = (params: questionnaireSurveyListRequestParams) => {
    return request.get(questionnaireSurveyListRequestPath, {
        params,
    })
}

// 添加数据
export const upsertquestionnaireSurvey = (data: any) => {
    return request.post('/api/v2/questionnaire/upsert', data)
}

// 删除用户
export const DeletequestionnaireSurvey = (id: number) => {
    return request.delete(`/api/v2/questionnaire/${id}`)
}