/**
 * 收藏车辆
 */
import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Form, message, Select, Radio } from 'antd';
import CarSelect from '@/components/carSelect';
import DeviceSelect from '@/components/deviceSelect';
import { favoriteGroupListRequest, favoriteUpsertCarRequest } from "@/service/myCar";

interface FavoriteCarModalProps {
  data?: any;
  operateType: string;
  closeModal: () => void;
  reloadData: () => void;
}
const FavoriteCarModal = (props: FavoriteCarModalProps) => {
  const [form] = Form.useForm();
  const { data, operateType, closeModal, reloadData } = props;
  const [favoriteGroup, setFavoriteGroup] = useState<any>([]); // 收藏分组数据
  const [favoriteCarData, setFavoriteCarData] = useState<any>(data || {}); // 收藏车辆的信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  
  // 获取收藏分组数据
  const getFavoriteGroupList = async() => {
    const res = await favoriteGroupListRequest();
    const favoriteGroupData = res.data?.data || [];
    setFavoriteGroup(favoriteGroupData);
    form.setFieldsValue({
      groupId: operateType === 'edit' ? favoriteCarData.groupId : favoriteGroupData?.[0].id,
    })
  }

  useEffect(() => {
    getFavoriteGroupList();
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const { groupId } = values;
      const groupTitle = favoriteGroup.find((item: any) => item.id === groupId)?.title;
      const params: any = {
        groupId,
        groupTitle,
        plateNumber: favoriteCarData?.plateNumber,
        carId: favoriteCarData?.carId,
      };
      if (operateType === 'edit') {
        params.id = favoriteCarData.id;
      }
      const res = await favoriteUpsertCarRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success('收藏成功');
        closeModal();
        reloadData();
      }
    }
  }

  // 车辆选择存储选择的车辆信息
  const onCarSelect = (value: any, options: any) => {
    setFavoriteCarData({
      plateNumber: options.plate_number,
      carId: options.id,
    });
  }

  // 设备选择存储选择的设备对应的车辆信息
  const onDeviceSelect = (value: any, options: any) => {
    setFavoriteCarData(options);
  }

  return (
    <Modal
      title='关注车辆'
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
      >
        <Form.Item
          label="收藏分组"
          name="groupId"
          rules={[
            { required: true, message: '请选择收藏分组' }
          ]}
        >
          <Select
            placeholder='请选择收藏分组'
            options={favoriteGroup}
            fieldNames={{
              label: 'title',
              value: 'id',
            }}
          />
        </Form.Item>
        {/* 从车辆管理页面直接添加关注时不显示车辆和设备选择器 */}
        {Boolean(data) ||
          <Fragment>
            <Form.Item
              label="车辆选择方式"
              name="favoriteType"
              initialValue='plateNumber'
            >
              <Radio.Group
                options={[
                  { label: '车牌号', value: 'plateNumber' },
                  { label: 'SN号', value: 'sn' },
                ]}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {() => {
                const { favoriteType } = form.getFieldsValue();
                if (favoriteType === 'plateNumber') {
                  return (
                    <Form.Item
                      label="车牌号"
                      name="plateNumber"
                      rules={[
                        { required: true, message: '请选择收藏车辆' }
                      ]}
                    >
                      <CarSelect valueField='plate_number' onChange={onCarSelect} />
                    </Form.Item>
                  )
                } else {
                  return (
                    <Form.Item
                      label="设备SN"
                      name="sn"
                      rules={[{ required: true, message: '请选择设备SN' }]}
                    >
                      <DeviceSelect onChange={onDeviceSelect} labelShowPlateNumber />
                    </Form.Item>
                  )
                }
              }}
            </Form.Item>
          </Fragment>
        }
      </Form>
    </Modal>
  )
}

export default FavoriteCarModal;
     