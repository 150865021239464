// 是否评价的数据
export const isReviewData = [
  { label: '全部', value: null },
  { label: '未评价', value: 0 },
  { label: '已评价', value: 1 },
  { label: '评价2次以上', value: 2 },
  { label: '我已评价', value: 3 },
  { label: '我未评价', value: 4 },
]

// 评价分数的数据
export const reviewRateData = [
  { label: '0-3', value: '0,3' },
  { label: '3-4', value: '3,4' },
  { label: '4-5', value: '4,5' },
]

// 目标位置的标签显示顺序
export const targetPositionTagSort = [
  6868, 6869, 6870, 6891, 6871, 6872, 6873
]

// 驾驶视频所有的分类
export const videoTagGroup = [
  { label: '预警结果', field: 'resultTag' },
  { label: '预警效果', field: 'effectTag' },
  { label: '预警评分', field: 'rateTag' },
  { label: '时间', field: 'timeTag' },
  { label: '天气', field: 'weatherTag' },
  { label: '路况', field: 'roadTag' },
  { label: '自车行为', field: 'carBehaviorTag' },
  { label: '逆光', field: 'lightTag' },
  { label: '及时性', field: 'timelyTag' },
  { label: '目标种类', field: 'targetTypeTag' },
  { label: '目标位置', field: 'targetPositionTag' },
  { label: '目标行为', field: 'targetBehaviorTag' },
]

// 驾驶视频的数据类型
export const driveVideoData = [
  { label: 'FCW预警', value: '3_1' },
  { label: 'AEB制动', value: '3_2' },
  { label: '起步阻止', value: '3_3' },
  { label: 'HMW预警', value: '3_5' },
]

// 驾驶视频播放失败的原因
export const driveVideoErrorMessage: any = {
  255: '未知失败',
  254: '网络原因失败',
  253: '资源找不到(指定时间范围数据没有)',
  252: 'oss服务响应失败'
};