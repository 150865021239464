/**
 * 操作车队档案
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, Radio } from 'antd';
import { cateAllCompanyRequest } from '@/service/fleetRisk';
import { upsertCompany } from '@/service/safeReport';

interface OperateModalProps {
  closeModal: () => void;
  onOk: () => void;
  data?: any;
}
const OperateTeamModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { closeModal, data, onOk } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  useEffect(() => {
    carInterviewEdit()
  }, []);



  // 编辑的时候赋值
  const carInterviewEdit = async () => {
    const newData:{[key:string]: any} = {}
		newData.header = data.ooe
		newData.tel = data.ooePhone
    form.setFieldsValue(newData)
  }

  //车队评级
  const safeGrade = [
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
  ]

  //车队状态
  const safeState = [
    { label: '启用', value: '启用' },
    { label: '停用', value: '停用' },
  ]

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      params.id = data.companyId;
      const res = await upsertCompany(params);
      if (res.data?.code === 0) {
        message.success('修改成功');
        closeModal();
        onOk()
      }
    }
  }


  return (
    <Modal
      title={'编辑车队'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError>
        <Form.Item
          name="head"
          label="负责人"
          rules={[{ required: true, message: '请输入负责人' }]}
        >
          <Input placeholder="请输入负责人" />
        </Form.Item>
        <Form.Item
          name="tel"
          label="联系方式"
          rules={[{ required: true, message: '请输入联系方式' }]}
        >
          <Input placeholder="请输入联系方式" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateTeamModal;
