// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","crossMark":"crossMark_crossMark__eQmE-","leftPart":"crossMark_leftPart__1LBie","buttonBox":"crossMark_buttonBox__SIJmB","roadBox":"crossMark_roadBox__-T9DX","roadItem":"crossMark_roadItem__BK2Ub","rightPart":"crossMark_rightPart__ejZ8p","title":"crossMark_title__gJs4o","mapBox":"crossMark_mapBox__yhnrQ"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"crossMark\":\"crossMark_crossMark__eQmE-\",\"leftPart\":\"crossMark_leftPart__1LBie\",\"buttonBox\":\"crossMark_buttonBox__SIJmB\",\"roadBox\":\"crossMark_roadBox__-T9DX\",\"roadItem\":\"crossMark_roadItem__BK2Ub\",\"rightPart\":\"crossMark_rightPart__ejZ8p\",\"title\":\"crossMark_title__gJs4o\",\"mapBox\":\"crossMark_mapBox__yhnrQ\"}";
        // 1726639067797
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  