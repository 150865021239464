// 所有的渠道数据
export const channelData: any = [
  { label: '中华庞大', value: 1740 },
  { label: '中华飞宏', value: 1739 },
  { label: '京东庞大', value: 1916 },
  { label: '京东元亨', value: 1917 },
  { label: '商车云', value: 2050 },
  { label: '京东通联', value: 3298 },
];

// 通过用户的cateId匹配需要显示的渠道数据
export const userCateIdMatchChannelData: {[key: string]: Array<number>} = {
  1740: [1740, 1916],
  1916: [1740, 1916],
  1739: [1739],
  1917: [1917],
  2050: [2050],
  3298: [3298],
}