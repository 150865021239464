/**
 * 操作车队走访
 */
import React, {useEffect, useState} from 'react';
import {
  Modal,
  Form,
  Input,
  message,
  Select,
  Button,
  Space,
  Switch,
  Upload,
  Radio,
  Checkbox,
  Cascader,
  Row,
  Col
} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import UploadImageVideo from "@/components/uploadImageVideo";
import FleetSelect from '@/components/fleetSelect';
import DatePicker from '@/components/datePicker';
import {tagListRequest} from '@/service/tagManage';
import {TagCategoryEnum} from '@/constants/tagManage';
import dayjs from "dayjs";
import {cateAllCompanyRequest, motorcadeCarList} from '@/service/fleetRisk';
import {upsertCarInterview} from '@/service/carInterview';
import {userListRequest} from '@/service/userManage';
import {chinaData} from "@/constants/chinaData";

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}

const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const {reloadData, closeModal, type, data} = props;
  const [carOption, setcarOption] = useState<any>([]); // 车辆选择
  const [interviewType, setInterviewType] = useState<any>([]); // 走访类别
  const [interviewCoordinate, setInterviewCoordinate] = useState<any>([]); // 负责人配合度
  const [otherAdaptability, setOtherAdaptability] = useState<any>([]); // 涉及人员配合度
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [tagData, setTagData] = useState<any>([]); // 走访人员
  const [carListOver, setCarListOver] = useState<any>([]); // 车辆
  const [provinceData] = useState<any>(chinaData['86']); // 维保地点的省份选择数据源
  const [cityData, setCityData] = useState<any>(chinaData['110000']); // 维保地点的城市选择数据源
  const [districtData, setDistrictData] = useState<any>(chinaData['110100']); // 维保地点的区域选择数据源
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择

  useEffect(() => {
    getInterviewType()
    getInterviewCoordinate()
    getMaintenancePerson()
    carInterviewEdit()
    getOtherAdaptability()
    getCateAllCompanyData()
  }, []);

  // 编辑的时候赋值
  const carInterviewEdit = async () => {
    if (type === 'edit') {
      const params = {cateName: data.name}
      const res = await motorcadeCarList(params);
      const carOptions: { value: any; label: any; }[] = []
      res.data.data.map((item: any) => {
        const aaa = {value: item.id, label: item.plate_number}
        carOptions.push(aaa)
      })
      setcarOption(carOptions)
      data.photo = null
      data.day = dayjs(data.day)
      data.accidentTime = dayjs(data.accidentTime)
      // 返回数据格式 []
      if (data.trackResultList == null) {
        data.trackResultList = []
      }
      if (data.objContactList == null) {
        data.objContactList = []
      }
      if (data.trackResultList[0]) {
        data.trackingTime = data.trackResultList[0].name
        data.trackingResult = data.trackResultList[0].value
      }
      if (data.objContactList[0]) {
        data.visitor = data.objContactList[0].name
        data.visitorPho = data.objContactList[0].value
      }
      //处理联系人信息
      if (data.contactList?.length) {
        const firstContact = data.contactList.shift();

        data.first = firstContact.name;
        data.last = firstContact.value;
      }
      form.setFieldsValue(data)
    }
  }

  // 车队选择
  const motorcadeSelect = async (value: any) => {
    const data = {cateName: value}
    const res = await motorcadeCarList(data);
    const carOptions: { value: any; label: any; }[] = []
    const carIdList: any[] = []
    res.data.data.map((item: any) => {
      const aaa = {value: item.id, label: item.plate_number, disabled: false}
      carOptions.push(aaa)
      carIdList.push(item.id)
    })
    setcarOption(carOptions)
    setCarListOver(carIdList)
  }

  // 获取维保人员数据
  const getMaintenancePerson = async () => {
    const res = await userListRequest({page: 1, limit: 999});
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {nickname, isDisabled} = item;
      tags.push({label: nickname, value: nickname, disabled: isDisabled});
    });
    setTagData(tags);
  }

  // 获取走访类别
  const getInterviewType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.interviewType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title, id} = item;
      tags.push({label: title, value: id});
    });
    setInterviewType(tags);
  }

  // 获取负责人配合度
  const getInterviewCoordinate = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.managerAdaptability],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title, id} = item;
      tags.push({label: title, value: id});
    });
    setInterviewCoordinate(tags);
  }


  // 获取涉及人员配合度
  const getOtherAdaptability = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.otherAdaptability],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const {title, id} = item;
      tags.push({label: title, value: id});
    });
    setOtherAdaptability(tags);
  }

  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data 
    const aa: any = []
    data.forEach((item: any) => {
      aa.push({label: item.title, value: item.title})
    })
    setAllCompany(aa)
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      params.province = provinceData[params.province]
      params.city = cityData[params.city]
      params.district = districtData[params.district]
      params.resources = uploadSuccessList;
      const aaa = {name: params.first, value: params.last}
      const bbb = {name: params.visitor, value: params.visitorPho}
      const ccc = {name: params.trackingTime, value: params.trackingResult}
      if (params.contactList == undefined) {
        params.contactList = []
        params.contactList.push(aaa)
      } else {
        params.contactList.push(aaa)
      }

      if (params.objContactList == undefined) {
        params.objContactList = []
        params.objContactList.push(bbb)
      } else {
        params.objContactList.push(bbb)
      }
      if (params.trackResultList == undefined) {
        params.trackResultList = []
        params.trackResultList.push(ccc)
      } else {
        params.trackResultList.push(ccc)
      }

      params.day = dayjs(values.day).format("YYYY-MM-DD") + ' 00:00:00';
      params.accidentTime = dayjs(values.accidentTime).format("YYYY-MM-DD HH:mm:ss");
      delete params.photo
      delete params.first
      delete params.last
      delete params.visitor
      delete params.visitorPho
      delete params.trackingTime
      delete params.trackingResult
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertCarInterview(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }

  const onChangeOff = (e: any) => {
    if (e.target.value == '全选') {
      form.setFieldsValue({
        carIdList: carListOver,
      })
    } else if (e.target.value == '全不选') {
      form.setFieldsValue({
        carIdList: undefined,
      })
    }
  }

  const provinceKeysData = Object.keys(provinceData);
  const cityKeysData = Object.keys(cityData);
  const districtKeysData = Object.keys(districtData);

  // 表单操作时的处理
  const onFormChange = (changedValues: any) => {
    // 选择省份(重新设置城市数据)
    if (changedValues.hasOwnProperty('province')) {
      const newCityData = chinaData[changedValues.province];
      setCityData(newCityData);
      const firstCity = Object.keys(newCityData)[0];
      const newDistrictData = chinaData[firstCity];
      setDistrictData(newDistrictData);
      form.setFieldsValue({
        city: firstCity,
        district: Object.keys(newDistrictData)[0],
      });
    }

    // 选择城市(重新设置区域数据)
    if (changedValues.hasOwnProperty('city')) {
      const newDistrictData = chinaData[changedValues.city];
      setDistrictData(newDistrictData);
      form.setFieldsValue({
        district: Object.keys(newDistrictData)[0],
      });
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加走访记录' : '编辑走访记录'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{maxHeight: '70vh', overflow: 'auto'}}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
        onValuesChange={onFormChange}
      >
        <Form.Item
          label="走访类别"
          name="type"
          rules={[{required: true, message: '请选择走访类别'}]}
        >
          <Select
            placeholder="请选择"
            options={interviewType}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="车队选择"
          name="name"
          rules={[{required: true, message: '请选择车队'}]}
        >
          <Select
            showSearch
            placeholder="请选择"
            style={{width: '100%'}}
            options={allCompany}
            onChange={motorcadeSelect}
            allowClear
          />
          {/* <FleetSelect valueField="label" onChange={motorcadeSelect} /> */}
        </Form.Item>
        <Form.Item
          label="车辆选择"
          name="carIdList"
          rules={[{required: true, message: '请选择车辆'}]}

        >
          <Select
            placeholder="请选择车辆"
            style={{width: '100%'}}
            options={carOption}
            allowClear
            showSearch
            mode="multiple"
          />
        </Form.Item>
        <Form.Item
          name="mode"
          label="合作模式"
          rules={[{required: true, message: '请输入合作模式'}]}
        >
          <Input placeholder="请输入合作模式"/>
        </Form.Item>
        <Form.Item
          name="total"
          label="车辆总数"
          rules={[{required: true, message: '请输入车辆总数'}]}
        >
          <Input placeholder="请输入车辆总数"/>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            if (form.getFieldValue('type') === 7070) {
              return (
                <>
                  <Form.Item
                    label="事故类型"
                    name="accidentType"
                    rules={[{required: true, message: '请输入事故类型'}]}

                  >
                    <Input placeholder="请输入事故类型"/>
                  </Form.Item>
                  <Form.Item
                    label="事故例数"
                    name="riskCount"
                    rules={[{required: true, message: '请输入事故例数'}]}
                  >
                    <Input placeholder="请输入事故例数"/>
                  </Form.Item>
                  <Form.Item

                    label="事故时间"
                    name="accidentTime"
                    rules={[{required: true, message: '请选择事故时间'}]}
                  >
                    <DatePicker
                      showTime
                      placeholder="请选择事故时间"
                      style={{width: '100%'}}
                    />
                  </Form.Item>
                  <Form.Item
                    label="培训内容"
                    name="content"
                    rules={[{required: true, message: '请选择培训内容'}]}

                  >
                    <Input.TextArea showCount placeholder="请输入培训内容" style={{height: 100}}/>
                  </Form.Item>
                  <Form.Item
                    label="事故原因(客户端)"
                    name="reasonClient"
                    rules={[{required: true, message: '请选择事故原因'}]}

                  >
                    <Input.TextArea showCount placeholder="请输入事故原因" style={{height: 100}}/>
                  </Form.Item>
                  <Form.Item name="result" label="走访结果">
                    <Radio.Group>
                      <Radio value="有效走访">有效走访</Radio>
                      <Radio value="无效走访">无效走访</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )
            } else if (form.getFieldValue('type') === 7071) {
              return (
                <>
                  <Form.Item
                    label="作业环境"
                    name="reasonClient"
                    rules={[{required: true, message: '请输入作业环境'}]}
                  >
                    <Input.TextArea showCount placeholder="请输入作业环境" style={{height: 100}}/>
                  </Form.Item>
                  <Form.Item
                    name="content"
                    label="处理方案"
                    rules={[{required: true, message: '请输入处理方案'}]}
                  >
                    <Input.TextArea showCount placeholder="请输入处理方案" style={{height: 120}}/>
                  </Form.Item>
                  <Form.Item name="result" label="走访结果">
                    <Radio.Group>
                      <Radio value="有效走访">有效走访</Radio>
                      <Radio value="无效走访">无效走访</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="追踪时间" name="trackingTime">
                    <Select allowClear>
                      <Select.Option value="三天">三天</Select.Option>
                      <Select.Option value="五天">五天</Select.Option>
                      <Select.Option value="十天">十天</Select.Option>
                      <Select.Option value="十五天">十五天</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="trackingResult" label="追踪结果">
                    <Radio.Group>
                      <Radio value="恢复">恢复</Radio>
                      <Radio value="未恢复">未恢复</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )
            } else if (form.getFieldValue('type') === 7072) {
              return (
                <>
                  <Form.Item
                    label="问题原因(客户端)"
                    name="reasonClient"
                    rules={[{required: true, message: '请输入问题原因(客户端)'}]}
                  >
                    <Input.TextArea showCount placeholder="请输入事故原因" style={{height: 100}}/>
                  </Form.Item>
                  <Form.Item
                    label="问题原因(技术端)"
                    name="reasonOur"
                    rules={[{required: true, message: '请输入问题原因(技术端)'}]}
                  >
                    <Input.TextArea showCount placeholder="请输入事故原因" style={{height: 100}}/>
                  </Form.Item>
                  <Form.Item
                    name="content"
                    label="处理方案"
                    rules={[{required: true, message: '请输入处理方案'}]}
                  >
                    <Input.TextArea showCount placeholder="请输入处理方案" style={{height: 120}}/>
                  </Form.Item>
                  <Form.Item name="result" label="走访结果">
                    <Radio.Group>
                      <Radio value="已处理/已恢复">已处理/已恢复</Radio>
                      <Radio value="未处理/未恢复">未处理/未恢复</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )
            }
          }}
        </Form.Item>
        <Form.Item
          label="人员选择"
          name="userNameList"
          rules={[
            {required: true, message: '请选择人员'}
          ]}
        >
          <Select
            mode="multiple"
            placeholder="请选择人员"
            options={tagData}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item label="走访地点" required>
          <Row wrap>
            <Col sm={8} xs={8}>
              <Form.Item name="province" noStyle initialValue={provinceKeysData[0]}>
                <Select>
                  {provinceKeysData.map((item: string) => (
                    <Select.Option value={item} key={item}>{provinceData[item]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={8} xs={8}>
              <Form.Item name="city" noStyle initialValue={cityKeysData[0]}>
                <Select>
                  {cityKeysData.map((item: string) => (
                    <Select.Option value={item} key={item}>{cityData[item]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={8} xs={8}>
              <Form.Item name="district" noStyle initialValue={districtKeysData[0]}>
                <Select>
                  {districtKeysData.map((item: string) => (
                    <Select.Option value={item} key={item}>{districtData[item]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="走访日期"
                   name="day"
                   rules={[{required: true, message: '请选择走访日期'}]}
        >
          <DatePicker
            placeholder="请选择走访日期"
            style={{width: '100%'}}
          />
        </Form.Item>
        <Form.Item
          label="走访问题"
          name="problem"
          rules={[{required: true, message: '请输入走访问题'}]}
        >
          <Input placeholder="请输入走访问题"/>
        </Form.Item>
        <Form.Item name="summarize"
                   label="走访总结"
                   rules={[{required: true, message: '请输入走访总结'}]}
        >
          <Input.TextArea showCount placeholder="请输入走访总结" style={{height: 120}}/>
        </Form.Item>

        <Form.Item
          name="suggest"
          label="客户建议"
          rules={[{required: true, message: '请输入客户建议'}]}
        >
          <Input.TextArea showCount placeholder="请输入客户建议" style={{height: 100}}/>
        </Form.Item>
        <Form.Item
          name="manageMode"
          label="车队管理模式"
          rules={[{required: true, message: '请输入车队管理模式'}]}
        >
          <Input placeholder="请输入车队管理模式"/>
        </Form.Item>
        <Form.Item
          name="operateMode"
          label="车队运营模式"
          rules={[{required: true, message: '请输入车队运营模式'}]}
        >
          <Input placeholder="请输入车队运营模式"/>
        </Form.Item>
        <Form.Item
          name="first"
          label="联系人"
          rules={[{required: true, message: '请输入联系人'}]}
          style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
        >
          <Input placeholder="请输入联系人"/>
        </Form.Item>
        <Form.Item
          name="last"
          label="联系人电话"
          rules={[{required: true, message: '请输入联系人电话'}]}
          style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}
        >
          <Input placeholder="请输入联系人电话"/>
        </Form.Item>
        <Form.List name="contactList">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({key, name, ...restField}) => (
                <Space key={key} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[{required: true, message: '请输入联系人'}]}
                    style={{width: 'calc(100% + 30px)'}}
                  >
                    <Input placeholder="请输入联系人"/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{required: true, message: '请输入联系人电话'}]}
                    style={{margin: '0 0 0 30px'}}
                  >
                    <Input placeholder="请输入联系人电话"/>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)}/>
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>添加联系方式</Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          name="visitor"
          label="拜访人"
          rules={[{required: true, message: '请输入拜访人'}]}
          style={{display: 'inline-block', width: 'calc(50% - 8px)'}}
        >
          <Input placeholder="请输入拜访人"/>
        </Form.Item>
        <Form.Item
          name="visitorPho"
          label="拜访人电话"
          rules={[{required: true, message: '请输入拜访人电话'}]}
          style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}
        >
          <Input placeholder="请输入拜访人电话"/>
        </Form.Item>
        <Form.Item label="负责人配合度"
                   name="managerAdaptability"
                   rules={[{required: true, message: '请选择负责人配合度'}]}
        >
          <Select
            placeholder="请选择负责人配合度"
            options={interviewCoordinate}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item label="涉及人员配合度"
                   name="otherAdaptability"
                   rules={[{required: true, message: '请选择涉及人员配合度'}]}
        >
          <Select
            placeholder="请选择涉及人员配合度"
            options={otherAdaptability}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item label="现场照片" name="photo"
                   rules={[{required: uploadSuccessList.length == 0 ? true : false, message: '0000000000000'}]}>
          <UploadImageVideo
            businessName="现场照片"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.viewResources == null ? [] : data.viewResources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>
        <Form.Item name="buildHome" label="是否建群">
          <Radio.Group>
            <Radio value="是">是</Radio>
            <Radio value="否">否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
        >
          <Input.TextArea showCount placeholder="请输入备注" style={{height: 120}}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
