/**
 * DBC文件列表
 */
import React, { useEffect, useState } from 'react';
import { Drawer, message, Popconfirm, Button, Descriptions } from 'antd';
import { canInfoDetailRequest, upsertCanInfoRequest } from '@/service/carCan';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import OperateDbcModal from '../operateDbcModal';
import SignalConfigModal from '../signalConfigModal';
import { getInnerPermissionEnable } from '@/utils/permission';
import { downloadFile } from '@/utils/download';
import styles from '@/styles/pageContent.module.scss';

interface DbcFileListProps {
  data: any; // 相关信息
  onClose: () => void; // 关闭面板的方法
  reloadData: () => void; // 重新获取信息的方法
}
const DbcFileList = (props: DbcFileListProps) => {
  const { data, onClose, reloadData } = props;
  const [detail, setDetail] = useState<any>(data); // 详情
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息

  // 获取详情信息
  const getDetail = async (id: string) => {
    const res = await canInfoDetailRequest(id);
    setDetail(res?.data?.data || {});
  };

  useEffect(() => {
    if (data?.id) {
      getDetail(data.id);
    }
  }, []);

  // 添加成功之后回调
  const addSuccessCallback = (id?: string) => {
    getDetail(id || detail.id);
    reloadData && reloadData();
  };

  // 删除DBC文件
  const deleteDbc = async (deleteIndex: number) => {
    const dbcFile: any = [];
    (detail?.file || []).forEach((item: any, index: number) => {
      if (deleteIndex !== index) {
        const dbcFileKey = item?.dbcFiles?.key || '';
        let canFileKey = (item?.canFiles || []).map((item: any) => item.key);
        canFileKey = canFileKey.length ? canFileKey : [];
        dbcFile.push({
          canFileKey,
          dbcFileKey,
          dbcId: item?.dbcId || null,
          ps: item?.ps
        });
      }
    });
    const params = {
      model: detail.model,
      brand: detail.brand,
      id: detail.id,
      canDbc: dbcFile
    };
    const res = await upsertCanInfoRequest(params);
    if (res?.data?.code === 0) {
      message.success('删除成功');
      addSuccessCallback();
    }
  };

  // 额外的展开行的渲染
  const expandedRowRender = (record: any) => {
    const columns = [
      {
        title: 'CAN文件',
        dataIndex: 'fileName',
        render: (value: string, item: any) => (
          <div className={styles.tableOperateColumn}>
            {renderFileButton(item, '/car-admin/car-can/canDownload')}
          </div>
        )
      }
    ];
    const canData: any = record.canFiles;
    return (
      <CommonTable
        columnFilter={false}
        columns={columns}
        dataSource={canData}
        pagination={false}
        bordered
        size="small"
        isNestTable
      />
    );
  };

  // 渲染文件按钮(根据是否有下载权限区分展示的样式)
  const renderFileButton = (file: any, permissionKey: string) => {
    const hasExportPermission = getInnerPermissionEnable(permissionKey);
    return (
      <Button
        type={hasExportPermission ? 'link' : 'text'}
        onClick={() =>
          hasExportPermission ? downloadFile(file.preview, file.fileName) : null
        }
        style={{ cursor: hasExportPermission ? 'pointer' : 'default' }}
      >
        {file.fileName}
      </Button>
    );
  };

  // 表格列显示
  const columns = [
    {
      title: 'DBC文件',
      dataIndex: 'fileName',
      render: (value: string, item: any) => (
        <div className={styles.tableOperateColumn}>
          {renderFileButton(item, '/car-admin/car-can/dbcDownload')}
        </div>
      )
    },
    {
      title: '描述',
      dataIndex: 'ps'
    },
    {
      title: '操作',
      dataIndex: 'operate',
      render: (value: any, item: any, index: number) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/car-can/dbcEdit"
              type="link"
              onClick={() =>
                setOperateInfo({
                  type: 'editDbc',
                  data: detail,
                  editIndex: index
                })
              }
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="此操作将删除该DBC文件及关联的CAN报文文件"
              onConfirm={() => deleteDbc(index)}
            >
              <PermissionButton
                operateType="/car-admin/car-can/dbcDelete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
            {Boolean(item.fileName) && (
              <PermissionButton
                operateType="/car-admin/car-can/dbcConfig"
                type="link"
                onClick={() =>
                  setOperateInfo({ type: 'signalConfig', dbcId: item.id })
                }
              >
                信号配置
              </PermissionButton>
            )}
          </div>
        );
      }
    }
  ];

  const tableData: any = [];
  (detail?.file || []).forEach((item: any) => {
    if (item?.dbcId) {
      tableData.push({
        fileName: item.dbcFiles?.fileName,
        preview: item.dbcFiles?.preview,
        key: item.dbcId,
        id: item.dbcId,
        canFiles: item.canFiles || [],
        ps: item.ps
      });
    }
  });

  return (
    <Drawer
      title="DBC文件配置"
      open
      onClose={onClose}
      size="large"
      className={styles.drawerTableBox}
    >
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/car-can/dbcAdd"
          type="primary"
          onClick={() => setOperateInfo({ type: 'addDbc', data: detail })}
        >
          添加DBC文件
        </PermissionButton>
      </div>
      <Descriptions bordered style={{ marginBottom: '12px' }}>
        <Descriptions.Item label="车辆品牌" span={3}>
          {detail.brand}
        </Descriptions.Item>
        <Descriptions.Item label="车辆型号" span={3}>
          {detail.model}
        </Descriptions.Item>
      </Descriptions>
      <CommonTable
        columnFilter={false}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        size="small"
        expandable={{
          showExpandColumn: false,
          expandedRowRender,
          rowExpandable: record => Boolean(record.canFiles.length),
          expandedRowKeys: tableData.map((item: any) => item.key)
        }}
      />
      {['addDbc', 'editDbc'].includes(operateInfo.type) && (
        <OperateDbcModal
          onClose={() => setOperateInfo({})}
          data={operateInfo.data}
          editIndex={operateInfo.editIndex}
          reloadData={(id: string) => addSuccessCallback(id)}
        />
      )}
      {operateInfo.type === 'signalConfig' && (
        <SignalConfigModal
          onClose={() => setOperateInfo({})}
          dbcId={operateInfo.dbcId}
          reloadData={addSuccessCallback}
        />
      )}
    </Drawer>
  );
};

export default DbcFileList;
