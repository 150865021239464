/**
 * 支付记录
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, Input } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { payRecordListRequest, upsertInsurePayRequestPath } from "@/service/underwritingRecord";
import styles from '@/styles/pageContent.module.scss';
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import { downloadFile } from '@/utils/download';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import DatePicker from '@/components/datePicker';

const PayRecords = () => {
  const [searchParams, setSearchParams] = useState({
    startDay: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
    endDay: `${dayjs().format('YYYY-MM-DD')}`,
  }); // 搜索相关的参数
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: payRecordListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });
  const { RangePicker } = DatePicker;

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.startDay = dayjs(params.day[0]).format('YYYY-MM-DD');
    newParams.endDay = dayjs(params.day[1]).format('YYYY-MM-DD');
    delete newParams.day;
    setSearchParams(newParams)
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  // 表格列显示
  const columns = [
    {
      title: '服务单号',
      dataIndex: 'insuranceCode',
      width: 110,
    },
    {
      title: '支付金额',
      dataIndex: 'fee',
      width: 110,
    },
    {
      title: '支付日期',
      dataIndex: 'date',
      width: 100,
      ellipsis: { showTitle: false },
    },
    {
      title: '附件',
      dataIndex: 'resources',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: any) => (
        value?.map((item: any, index: any) => (
          <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
            <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
            </div>
            <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button
                type="text"
                onClick={() => onPdfPreview(item.url, item.fileName)}
              >
                <EyeOutlined />
              </Button>
              <Button
                type="link"
                onClick={() => downloadFile(item.url, item.fileName)}
                style={{ paddingLeft: 0 }}
              >
                <DownloadOutlined />
              </Button>
            </div>
          </div>
        ))
      ),
    },
    {
      title: '操作人',
      dataIndex: 'opt',
      width: 110,
    }
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item label="服务单号" name="insuranceCode">
              <Input placeholder='请输入服务单号' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="时间范围"
              name="day"
              initialValue={[dayjs(searchParams.startDay), dayjs(searchParams.endDay)]}>
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="&nbsp;">
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton
                  url={upsertInsurePayRequestPath}
                  fileName='支付记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/pay-recodes/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {pdfPreviewInfo && <PdfPreviewModal
        title={pdfPreviewInfo?.fileName}
        pdfUrl={pdfPreviewInfo?.url}
        closeModal={() => setPdfPreviewInfo(null)}
      />}
    </Card>
  )
}

export default PayRecords;
