/**
 * 问卷调查记录
 */
import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, message, Popconfirm, Input, Select } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import PermissionButton from '@/components/permissionButton';
import OperateModalTask from './components/operateModal';
import { questionnaireSurveyListRequestPath, questionnaireSurveyListRequest, DeletequestionnaireSurvey } from '@/service/questionnaireSurvey';
import { useDispatch } from 'react-redux';
import { userListRequest } from '@/service/userManage';
import FleetSelect from '@/components/fleetSelect';

const { RangePicker } = DatePicker;
const QuestionnaireSurvey = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [maintenancePerson, setMaintenancePerson] = useState([]); // 维保人员数据
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: questionnaireSurveyListRequest,
    initPageSize: 40,
    searchParams,
  });

  useEffect(() => {
    getMaintenancePerson();
  }, []);

  // 获取维保人员数据
  const getMaintenancePerson = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setMaintenancePerson(res.data?.data || [])
  }

  // 处理搜索参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newSearchParams:any = {...params}
    if (params.time) {
      newSearchParams.beginDate = dayjs(params.time[0]).format('YYYY-MM-DD');
      newSearchParams.endDate = dayjs(params.time[1]).format('YYYY-MM-DD');
      delete newSearchParams.time
    }
    if (isExport) {
      newSearchParams.export = true;
    }
    return newSearchParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 任务状态
  const totalData = [
    { label: '非常满意', value: 5 },
    { label: '满意', value: 4 },
    { label: '一般', value: 3 },
    { label: '不满意', value: 2 },
    { label: '非常不满意', value: 1 },
  ];

  // 点击车牌跳转车辆诊断
  const skipCarDiagnosis = (value:any) => {
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber:value,beginDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD') }
      }
    })
  }

  // 删除单条
  const deleteItem = async(id: number) => {
    const res = await DeletequestionnaireSurvey(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <div style={{color:'#1890ff', cursor:'pointer'}} onClick={() => skipCarDiagnosis(value)}>{value}</div>
      ),
    },
    {
      title: '所属公司',
      dataIndex: 'company',
      width: 175,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '回访日期',
      dataIndex: 'revisitDate',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD') : value,
    },
    {
      title: '安装人员',
      dataIndex: 'maintainer',
      width: 110,
      ellipsis: { showTitle: false },
    },
    {
      title: '是否电话联系',
      dataIndex: 'telFlag',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: any) => value == 1 ? '是' : value == 0 ? '否' : value
    },
    {
      title: '是否按照要求到达现场',
      dataIndex: 'reachFlag',
      width: 175,
      ellipsis: { showTitle: false },
      render: (value: any) => value == 1 ? '是' : value == 0 ? '否' : value
    },
    {
      title: '是否清晰流畅讲解设备',
      dataIndex: 'expressFlag',
      width: 175,
      ellipsis: { showTitle: false },
      render: (value: any) => value == 1 ? '是' : value == 0 ? '否' : value
    },
    {
      title: '是否还有不清楚的地方',
      dataIndex: 'unclearFlag',
      width: 175,
      ellipsis: { showTitle: false },
      render: (value: any) => value == 1 ? '是' : value == 0 ? '否' : value
    },
    {
      title: '服务评价',
      dataIndex: 'total',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: any) => value == 1 ? '非常不满意' : value == 2 ? '不满意' : value == 3 ? '一般' : value == 4 ? '满意' : value == 5 ? '非常满意' : value
    },
    {
      title: '建议或者意见',
      dataIndex: 'suggest',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 170,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton operateType='/car-admin/questionnaire-survey/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <Popconfirm
              title="您确定要删除该问卷调查记录吗?"
              onConfirm={() => deleteItem(item.id)}
            >
              <PermissionButton operateType='/car-admin/questionnaire-survey/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={4}>
            <Form.Item
              label="回访日期"
              name="time"
            >
              <RangePicker allowClear={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="车队选择"
              name="company"
            >
              <FleetSelect valueField="label" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="安装人员" name="maintainer">
            <Select
              placeholder="请选择安装人员"
              options={maintenancePerson}
              allowClear
              showArrow
              showSearch
              fieldNames={{
                label: 'nickname',
                value: 'nickname'
              }}
              optionFilterProp="nickname"
            />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="服务评价"
              name="total"
            >
              <Select
                options={totalData} 
                placeholder="请选择服务评价"
                showSearch
                mode="multiple"
                showArrow
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <div className={styles.searchBox} style={{marginTop:'32px'}}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={questionnaireSurveyListRequestPath}
                  fileName='问卷调查记录'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/questionnaire-survey/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/questionnaire-survey/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModalTask
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default QuestionnaireSurvey;