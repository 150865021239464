/**
 * 标签管理
 */
import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { formatTime } from '@/utils/formatTime';
import { tagSortTreeRequest, tagListRequest } from '@/service/tagManage';
import commonStyles from '@/styles/pageContent.module.scss';
import OperateTagSortModal from './components/operateTagSortModal';
import OperateTagModal from './components/operateTagModal';
import styles from './index.module.scss';

const TagManage = () => {
  const [tagSortTree, setTagSortTree] = useState<any>([]); // 标签分组数据
  const [tagSortLoading, setTagSortLoading] = useState(false); // 标签分组loading
  const [tagList, setTagList] = useState<any>([]); // 标签数据
  const [tagLoading, setTagLoading] = useState(false); // 标签数据loading
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的信息
  const [selectTagSorts, setSelectTagSorts] = useState<any>([]); // 选择的标签分组

  useEffect(() => {
    getTagSortTree();
  }, []);

  // 获取标签分组数据
  const getTagSortTree = async() => {
    try {
      setTagSortLoading(true);
      const res = await tagSortTreeRequest();
      const resData = res.data?.data;
      setTagSortTree(resData ? [resData] : []);
      setTagSortLoading(false);
    } catch (error) {
      setTagSortLoading(false);
    }
  }

  // 获取标签数据
  const getTagList = async(params: any) => {
    try {
      setTagLoading(true);
      const res = await tagListRequest({
        ...params,
        needDisabled: true,
      });
      setTagList(res.data?.data || []);
      setTagLoading(false);
    } catch (error) {
      setTagLoading(false);
    }
  }

  // 选择标签分组时获取所有最后一级的id
  const getLastLevelData = (data: any) => {
    if (Array.isArray(data) && data.length > 0) {
      const newData: Array<number> = [];
      const deep = (innerData: any) => {
        innerData.forEach((item: any) => {
          if (Array.isArray(item.children) && item.children.length > 0) {
            deep(item.children);
          } else {
            newData.push(item.id);
          }
        });
      };
      deep(data);
      return newData;
    } else {
      return [];
    }
  }

  // 选择标签分组显示对应的标签列表
  const onTagSortSelect = (selectedKeys: any, e: any) => {
    if (e.selected) {
      const allSelectKeys = getLastLevelData([e.node]);
      setSelectTagSorts(allSelectKeys);
      getTagList({ tagSorts: allSelectKeys });
    } else {
      setSelectTagSorts([]);
      setTagList([]);
    }
  }

  // 渲染标签组tree的title
  const renderTagSortTitle = (node: any) => {
    return (
      <div className={styles.treeTitle}>
        <span>{node.title}</span>
        <span className={commonStyles.tableOperateColumn}>
          <PermissionButton
            operateType="/car-admin/tag-manage/edit-tag-sort"
            type="link"
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              setOperateInfo({ type: 'tagSort', operateType: 'edit', data: node });
            }}
          >
            编辑
          </PermissionButton>
        </span>
      </div>
    )
  }

  // 表格列显示
  const columns = [
    {
      title: '名称',
      dataIndex: 'title',
      width: 120,
      fixed: 'left' as 'left',
    },
    {
      title: '状态',
      dataIndex: 'isDisabled',
      width: 70,
      render: (value: boolean) => value ? <span className='dangerText'>禁用</span> : <span className='successText'>启用</span>,
    },
    {
      title: '标签组',
      dataIndex: 'sortTitle',
      width: 110,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 160,
      render: (value: string) => formatTime(value)
    },
    {
      title: '创建人',
      dataIndex: 'nickname',
      width: 110,
    },
    {
      title: '说明',
      dataIndex: 'ps',
      width: 130,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 70,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={commonStyles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/tag-manage/edit-tag"
              type="link"
              onClick={() => setOperateInfo({ type: 'tag', operateType: 'edit', data: item })}
            >
              编辑
            </PermissionButton>
          </div>
        )
      },
    },
  ]

  return (
    <div className={styles.tagManageBox}>
      <div className={styles.operateBox}>
        <div>
          <PermissionButton
            operateType='/car-admin/tag-manage/add-tag-sort'
            type='primary'
            onClick={() => setOperateInfo({ type: 'tagSort', operateType: 'add' })}
          >
            添加标签组
          </PermissionButton>
          <PermissionButton
            operateType='/car-admin/tag-manage/add-tag'
            type='primary'
            onClick={() => setOperateInfo({ type: 'tag', operateType: 'add' })}
          >
            创建新标签
          </PermissionButton>
        </div>
      </div>
      <div className={styles.tagBox}>
        <div className={styles.sortTree}>
          <LoadingWrapper autoHeight style={{ height: '100% '}} spinning={tagSortLoading}>
            <Tree
              treeData={tagSortTree}
              fieldNames={{ key: 'id' }}
              onSelect={onTagSortSelect}
              titleRender={renderTagSortTitle}
              blockNode
            />
          </LoadingWrapper>
        </div>
        <div className={styles.tagList}>
          <CommonTable
            pagination={false}
            columnFilter={false}
            columns={columns}
            dataSource={tagList}
            bordered
            loading={tagLoading}
            rowKey="id"
          />
        </div>
      </div>
      {/* 标签分组的操作 */}
      {operateInfo.type === 'tagSort' &&
        <OperateTagSortModal
          data={operateInfo.data}
          type={operateInfo.operateType}
          closeModal={() => setOperateInfo({})}
          reloadData={getTagSortTree}
        />
      }
      {/* 标签的操作 */}
      {operateInfo.type === 'tag' &&
        <OperateTagModal
          data={operateInfo.data}
          type={operateInfo.operateType}
          closeModal={() => setOperateInfo({})}
          reloadData={() => getTagList({ tagSorts: selectTagSorts })}
        />
      }
    </div>
  )
}

export default TagManage;
  