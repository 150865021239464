/**
 * 可伸缩区块
 */
import React, {
  useState,
  cloneElement,
  useImperativeHandle,
  forwardRef
} from 'react';
import { Resizable } from 'react-resizable';
import styles from './index.module.scss';

interface ResizableWrapperProps {
  resizeHandleDirection: 'top' | 'right' | 'bottom' | 'left'; // 可伸缩操作的位置方向
  defaultSizeStyle: any; // 初始的宽高样式
  children: any; // 可伸缩的内容
  minConstraints?: any; // 元素最小尺寸
  maxConstraints?: any; // 元素最大尺寸
  onResizeStop?: (size: any) => void; // 拖拽结束回传样式
}
const ResizableWrapper = (props: ResizableWrapperProps, ref: any) => {
  const {
    resizeHandleDirection,
    defaultSizeStyle,
    children,
    minConstraints,
    maxConstraints,
    onResizeStop
  } = props;
  const [sizeStyle, setSizeStyle] = useState(defaultSizeStyle || {}); // 尺寸样式(拖拽过程实时改变)

  // 暴露给父组件调用的方法
  useImperativeHandle(ref, () => ({
    changeSizeStyle: (size: any) => setSizeStyle(size), // 修改尺寸样式
    getSizeStyle: () => sizeStyle // 获取尺寸样式
  }));

  // 渲染可伸缩操作
  const renderResizeHandle = () => (
    <span
      className={styles[`${resizeHandleDirection}Handle`]}
      onClick={e => {
        e.stopPropagation();
      }}
    />
  );

  // 调整大小
  const onResize = (e: any, { size }: { size: any }, isStop?: boolean) => {
    const newSize = { ...size };
    if (Number.isNaN(size.width)) {
      newSize.width = defaultSizeStyle.width;
    }
    if (Number.isNaN(size.height)) {
      newSize.height = defaultSizeStyle.height;
    }
    if (isStop) {
      onResizeStop && onResizeStop(newSize);
    } else {
      setSizeStyle(newSize);
    }
  };

  return (
    <Resizable
      {...sizeStyle}
      handle={renderResizeHandle()}
      onResize={onResize}
      onResizeStop={(e: any, sizeData: any) => onResize(e, sizeData, true)}
      resizeHandles={
        ['right', 'bottom'].includes(resizeHandleDirection) ? ['se'] : ['nw']
      }
      minConstraints={minConstraints}
      maxConstraints={maxConstraints}
    >
      {cloneElement(children, {
        style: { ...sizeStyle, position: 'relative' }
      })}
    </Resizable>
  );
};

export default forwardRef(ResizableWrapper);
