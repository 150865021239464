/**
 * 历史联系结果
 */
import React, { useEffect, useState } from 'react';
import { Descriptions, Modal, Tag, Timeline } from 'antd';
import LoadingWrapper from '@/components/loadingWrapper';
import { serviceRecoedRequest } from '@/service/carDiagnosis';
import Thumbnail from "@/components/thumbnail";
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

interface VersionModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const AllServiceRecord = (props: VersionModalProps) => {
  const dispatch = useDispatch();
  const { data, closeModal } = props;
  const [loading, setLoading] = useState(false); 
  const [result, setResult] = useState<any>([]); // 工单详情

  console.log('data',data);
  
  useEffect(() => {
    getHistoryResult()
  }, []);

  const getHistoryResult = async() => {
    try {
      setLoading(true);
      const res = await serviceRecoedRequest({ tel: data.tel });
      setResult(res.data.data)
      setLoading(false);
    } catch (error) {
    }
  }

  // 点击车牌跳转车辆诊断
  const skipCarDiagnosis = (value:any) => {
    closeModal()
    dispatch({
      type: 'addTab',
      payload: {
        tab: '车辆诊断',
        key: '/car-admin/car-diagnosis',
        params: { plateNumber:value,beginDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD') }
      }
    })
  }

  return (
    <Modal
      title={`${data.plateNumber}客服记录`}
      visible
      footer={null}
      onCancel={closeModal}
      centered
      width={'520px'}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      {/* 根据设备版本显示不同的内容 */}
      <LoadingWrapper spinning={loading}>
        <Timeline>
        {(result || []).map((item: any, index: any) => (
          <Timeline.Item key={index}>
            <div>{item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}</div>
            <Descriptions style={{ marginBottom: '20px' }} bordered size="small" column={{ xs: 1, sm: 2 }}>
              <Descriptions.Item label="车牌号"><div style={{color:'#1890ff', cursor:'pointer'}} onClick={() => skipCarDiagnosis(item.plateNumber)}>{item.plateNumber}</div></Descriptions.Item>
              <Descriptions.Item label="客服">{item.nickName}</Descriptions.Item>
              <Descriptions.Item label="问题来源">{item.category}</Descriptions.Item>
              <Descriptions.Item label="离线原因">{item.offlineReason}</Descriptions.Item>
              <Descriptions.Item label="联系结果" span={2}>{item.result}</Descriptions.Item>
              <Descriptions.Item label="问题原因" span={2}>
                {<div>{(Object.values(item.tags || {})[0] as any || []).map(((item1: any) => <Tag key={item1.tagId}>{item1.title}</Tag>))}</div>}
              </Descriptions.Item>
              <Descriptions.Item label="记录内容" span={2}>{item.content}</Descriptions.Item>
              <Descriptions.Item label="客服记录图片/视频" span={2}>
                  <Thumbnail
                    dataSource={item.viewResources || []}
                    downloadImgName="客服记录图片"
                    downloadVideoName="客服记录视频"
                  />
              </Descriptions.Item>
            </Descriptions>
          </Timeline.Item>
        ))}
        </Timeline>
      </LoadingWrapper>
    </Modal>
  )
}

export default AllServiceRecord;