/**
 * 客户文件相关请求
 */
import request from "@/utils/request";

export interface ClientListRequestParams {
    page: number; 
    limit: number;
    // nickname?: string;
    // mobile?: string;
    // roleIds?: Array<number>;
}
export const clientListRequestPath = '/api/v2/fileRecord/list';

// 获取文件列表数据
export const clientLearnListRequest = (params: ClientListRequestParams) => {
    return request.get(clientListRequestPath, {
        params,
    })
}




