// 菜单类型枚举
export enum MenuTypeEnum {
  page = 0, // 页面形式
  overlay = 1, // 浮层形式页面
}

// 菜单类型数据
export const menuTypeData = [
  { label: '页面', value: MenuTypeEnum.page },
  { label: '浮层弹窗', value: MenuTypeEnum.overlay },
]

// 菜单用途枚举
export enum MenuPurposeEnum {
  all = 0, // 全部
  admin = 1, // 云平台
  wechat = 2, // 小程序
}

// 菜单用途数据
export const menuPurposeData = [
  { label: '云平台及小程序', value: MenuPurposeEnum.all },
  { label: '云平台', value: MenuPurposeEnum.admin },
  { label: '小程序', value: MenuPurposeEnum.wechat },
]