/**
 * 围栏区域添加/编辑
 */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal, InputNumber } from 'antd';
import {
  upsertFenceAreaRequest,
  UpsertFenceRequestParams
} from '@/service/electronFence';

interface OperateModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false); // 保存按钮的loading

  // 编辑时赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存围栏区域
  const saveFenceArea = async (values: any) => {
    try {
      setSaveLoading(true);
      const params: UpsertFenceRequestParams = { ...values };
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertFenceAreaRequest(params);
      if (res.data?.data) {
        message.success(type === 'edit' ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
      setSaveLoading(false);
    } catch (error) {
      setSaveLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加围栏区域' : '编辑围栏区域'}
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      okButtonProps={{
        loading: saveLoading
      }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={saveFenceArea}
        layout="vertical"
        labelWrap
      >
        <Form.Item
          label="围栏区域名称"
          name="name"
          rules={[{ required: true, message: '请输入围栏区域名称' }]}
        >
          <Input placeholder="请输入围栏区域名称" />
        </Form.Item>
        <Form.Item
          label="航向角默认浮动区间(如地图绘制航向角线为90度，浮动区间设置为10度时表示航向角的角度区间为80度 - 100度)"
          name="headingAngleHalfRange"
          rules={[
            { required: true, message: '请输入航向角默认浮动区间' },
            {
              type: 'number',
              min: 0,
              max: 180,
              message: `请输入指定区间的值(0 ~ 180)`
            }
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="请输入航向角默认浮动区间"
            addonAfter="度"
          />
        </Form.Item>
        <Form.Item
          label="电子围栏最小半径"
          name="radius"
          rules={[
            { required: true, message: '请输入电子围栏最小半径' },
            {
              type: 'number',
              min: 1,
              message: `请输入大于0的值`
            }
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="请输入电子围栏最小半径"
            precision={0}
            addonAfter="米"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
