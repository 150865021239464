/**
 * 退回原因
 */
import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";
import { upsertServiceTask, upsertTaskDetail } from "@/service/serviceTask";

interface CommentModalProps {
  id: any; 
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 添加成功后重新请求工单的方法
}

const CommentModal = (props: CommentModalProps) => {
  const { id, onClose, reloadData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [form] = Form.useForm();

  // 退回原因
  const submit = async (values: any) => {
    try {
      const params = {...values};
      params.taskId = id.id;
      setLoading(true);
      const res = await upsertTaskDetail(params);
      if (res.status == 200) {
      }
      setLoading(false);
      if (res.data?.code === 0) {
        const paramsss = {...id}
        paramsss.taskState = 7
        const resss = await upsertServiceTask(paramsss);
        if (resss.data?.code === 0) {
          message.success('退回成功');
          onClose();
          reloadData();
        } 
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      title="拒绝任务"
      visible
      centered
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="退回原因"
          name="content"
          rules={[
            { required: true, message: '请输入退回原因' }
          ]}
        >
          <Input.TextArea placeholder="请输入退回原因" allowClear />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CommentModal;