/**
 * 离线天数筛选相关请求
 */
import request from "@/utils/request";

export interface OfflineDayParams {
  page: number,
  limit: number;
  cate_id?: number;
  day?: number;
}

// 获取超速车辆
export const offlineDayRequestPath = '/api/v2/car/offList';
export const offlineDayRequest = (data: OfflineDayParams) => {
  return request.post(offlineDayRequestPath, data)
}