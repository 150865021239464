/**
 * 在线未报警车辆筛选相关请求
 */
import request from "@/utils/request";

export interface OnlineNoAlarmParams {
  day: string;
  page: number,
  limit: number;
  cate_id?: number | Array<number>;
  mileage?: number;
  alarmTypes: Array<number>;
}

// 在线未报警车辆筛选
export const onlineNoAlarmRequestPath = '/api/v2/car/onaList';
export const onlineNoAlarmRequest = (data: OnlineNoAlarmParams) => {
  return request.post(onlineNoAlarmRequestPath, data)
}