/**
 * 报告生成规则配置
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, message, InputNumber, Radio, Slider } from 'antd';
import { fleetRiskPushConfigRequest, fleetRiskPushConfigSettingRequest } from '@/service/fleetRisk';
import { weekDays } from '@/constants/common';

interface PushConfigModalProps {
  closeModal: () => void;
}
const PushConfigModal = (props: PushConfigModalProps) => {
  const [form] = Form.useForm();
  const { closeModal } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  useEffect(() => {
    getConfig();
  }, [])

  // 获取当前配置并赋值
  const getConfig = async() => {
    const res = await fleetRiskPushConfigRequest();
    form.setFieldsValue(res.data?.data || {});
  }

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      const res = await fleetRiskPushConfigSettingRequest(values);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success('设置成功');
        closeModal();
      }
    }
  }
  
  return (
    <Modal
      title='报告生成规则配置'
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>新的配置仅对之后生成的报告有效</div>
      <Form
        form={form}
        autoComplete="off"
        layout='vertical'
        onFinish={submit}
      >
        <Form.Item
          label="生成报告的时间"
          name="day"
          rules={[{ required: true, message: '请选择生成报告的时间' }]}
        >
          <Radio.Group buttonStyle="solid" options={weekDays} />
        </Form.Item>
        <Form.Item
          label="报告周期"
          name="range"
          rules={[{ required: true, message: '请选择报告周期' }]}
        >
          <Slider
            step={null}
            range={{ draggableTrack: true }}
            marks={{ 0: '周一', 17: '周二', 34: '周三', 50: '周四', 67: '周五', 83: '周六', 100: '周日' }}
          />
        </Form.Item>
        <Form.Item
          label="hmw频次阈值"
          name="hmw"
          rules={[{ required: true, message: '请指定hmw频次阈值' }]}
        >
          <InputNumber placeholder='请指定hmw频次阈值' style={{ width: '100%' }} addonAfter="次/百公里" />
        </Form.Item>
        <Form.Item
          label="风险评价指标（低风险阈值）"
          name="lowRisk"
          rules={[{ required: true, message: '请指定低风险阈值' }]}
        >
          <InputNumber min={1} max={100} placeholder='请指定低风险阈值' style={{ width: '100%' }} addonAfter="%" />
        </Form.Item>
        <Form.Item
          label="风险评价指标（中风险阈值）"
          name="middleRisk"
          rules={[{ required: true, message: '请指定中风险阈值' }]}
        >
          <InputNumber min={1} max={100} placeholder='请指定中风险阈值' style={{ width: '100%' }} addonAfter="%" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PushConfigModal;
    