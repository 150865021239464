/**
 * 没有权限时显示的内容
 */
import React, { useEffect } from 'react';
import type { RouteComponentProps } from "react-router-dom";
import styles from './index.module.scss';

const Nopermssion = (props: RouteComponentProps) => {
  const { history } = props;

  useEffect(() => {
    setTimeout(() => { history.replace('/vehicle-admin') }, 3000);
  }, []);
  
  return (
    <div className={styles.noPermssion}>没有此页面权限，3秒后将跳转到您可以访问的页面</div>
  )
}

export default Nopermssion;