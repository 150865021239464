/**
 * 实时监控页面整体相关设置
 */
import React from 'react';
import {
  Modal,
  Tabs,
  Form,
  Radio,
  InputNumber,
  Select,
  Button,
  message
} from 'antd';
import { codeStreamData } from '@/constants/jtDevice';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';

interface OverallSettingProps {
  closeModal: () => void;
}
const OverallSetting = (props: OverallSettingProps) => {
  const { closeModal } = props;
  const [videoForm] = Form.useForm();
  const [mapForm] = Form.useForm();
  // 参数设置
  const realTimeMonitorSetting = localStorageUtil.getItem(
    LocalStorageKeyEnum.real_time_monitor_setting,
    {}
  );
  const {
    codeStream = 1,
    autocCloseVideoTime = 3,
    positionInterval = 30,
    showDriverInfo = false
  } = realTimeMonitorSetting;

  // 保存参数设置
  const formSave = (values: any) => {
    const newSetting = {
      ...localStorageUtil.getItem(
        LocalStorageKeyEnum.real_time_monitor_setting,
        {}
      ),
      ...values
    };
    localStorageUtil.setItem(
      LocalStorageKeyEnum.real_time_monitor_setting,
      newSetting
    );
    message.success('保存成功');
  };

  return (
    <Modal
      title="参数设置"
      open
      onCancel={closeModal}
      footer={null}
      centered
      maskClosable={false}
    >
      <Tabs defaultActiveKey="video" size="small" type="card">
        <Tabs.TabPane tab="视频参数" key="video">
          <Form onFinish={formSave} form={videoForm}>
            <Form.Item
              label="码流类型"
              name="codeStream"
              initialValue={codeStream}
            >
              <Radio.Group options={codeStreamData} />
            </Form.Item>
            <Form.Item
              label="自动关闭视频"
              name="autocCloseVideoTime"
              initialValue={autocCloseVideoTime}
            >
              <InputNumber
                step={1}
                min={0}
                precision={0}
                addonAfter="分钟后关闭"
              />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="地图参数" key="map">
          <Form onFinish={formSave} form={mapForm}>
            <Form.Item
              label="位置刷新间隔"
              name="positionInterval"
              initialValue={positionInterval}
            >
              <Select
                options={[
                  { label: '2秒', value: 2 },
                  { label: '5秒', value: 5 },
                  { label: '10秒', value: 10 },
                  { label: '15秒', value: 15 },
                  { label: '30秒', value: 30 },
                  { label: '60秒', value: 60 },
                  { label: '90秒', value: 90 }
                ]}
              />
            </Form.Item>
            <Form.Item
              label="是否显示完整的司机信息"
              name="showDriverInfo"
              initialValue={showDriverInfo}
            >
              <Radio.Group
                options={[
                  { label: '是', value: true },
                  { label: '否', value: false }
                ]}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default OverallSetting;
