/**
 * AEB参数弹窗(昆仑山3.0设备)
 */
import React from 'react';
import { Row, Col, InputNumber, Form, FormInstance } from 'antd';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import {
  getJtKlsAebParamsRequest,
  setJtKlsAebParamsRequest
} from '@/service/jtKlsParameterConfiguration';
import { getAebConfigParams } from '@/constants/parameterConfiguration';
import { formSearchGutter } from '@/constants/common';

interface JtKlsAebParamsModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  isViewParam?: boolean; // 是否只查看参数(用于参数信息的查看)
}
const JtKlsAebParamsModal = (props: JtKlsAebParamsModalProps) => {
  const { data, closeModal, isViewParam = false } = props;

  // 表单操作时的处理
  const onValuesChange = (
    changedValues: any,
    allValues: any,
    form: FormInstance
  ) => {
    // AEB演示场景V2版本开关开启时AEB演示场景V1版本开关强制关闭
    if (changedValues.aebTtcDemoSw) {
      form.setFieldsValue({
        aebTtcMagicSw: 0
      });
    }
  };

  return (
    <ParameterConfigurationModal
      operateParameterValue={data.sn}
      operateParameterField="sn"
      getParameterRequest={getJtKlsAebParamsRequest}
      setParameterRequest={setJtKlsAebParamsRequest}
      formConfig={getAebConfigParams(true)}
      closeModal={closeModal}
      modalTitle={isViewParam ? '参数信息' : 'AEB参数配置'}
      configKey="/car-admin/parameter-configuration/aeb-params-config"
      approveKey="/car-admin/parameter-configuration/aeb-params-config-approve"
      approveType="aebConfig"
      isViewParam={isViewParam}
      onValuesChange={onValuesChange}
      useCollapse
      extraFormContent={
        <Row gutter={formSearchGutter} wrap>
          <Col span={8}>
            <Form.Item
              label="参数版本"
              name="paramsVersion"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              rules={[
                { required: true, message: '参数版本读取失败' },
                {
                  type: 'number',
                  min: 1,
                  max: 4294967295,
                  message: '参数版本读取值不合理(合理区间1-4294967295)'
                }
              ]}
            >
              <InputNumber
                placeholder="参数版本"
                style={{ width: '100%' }}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      }
      deviceType={data.deviceType}
    />
  );
};

export default JtKlsAebParamsModal;
