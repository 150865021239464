/**
 * 下发TTS语音
 */
import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { jtDeviceDetailRequest } from '@/service/jtDevice';
import { jt808Command8300Request } from '@/service/jtDeviceMonitor';

interface TtsModalProps {
  jtDeviceId: any; // 设备id
  closeModal: () => void; // 关闭弹窗方法
}
const TtsModal = (props: TtsModalProps) => {
  const { jtDeviceId, closeModal } = props;
  const [form] = Form.useForm();

  // 下发TTS语音
  const distributeTts = async (values: any) => {
    const detailRes = await jtDeviceDetailRequest(jtDeviceId);
    const deviceDetail = detailRes.data?.data || {};
    const params: any = {
      phone: deviceDetail.phone,
      version: deviceDetail.version,
      flag: 8,
      text: values.text
    };
    // 2019版本添加type字段
    if (deviceDetail.version === '2019') {
      params.type = 1;
    }
    const res = await jt808Command8300Request(params);
    if (res?.data?.data) {
      message.success('下发成功');
      closeModal();
    }
  };

  return (
    <Modal
      title="下发TTS语音"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={distributeTts}
      >
        <Form.Item
          label="内容"
          name="text"
          rules={[{ required: true, message: '请输入内容' }]}
        >
          <Input.TextArea placeholder="请输入内容" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TtsModal;
