/**
 * 分组列表
 */
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react';
import { useSelector } from 'react-redux';
import { Card, Form, Row, Col, Button, Input } from 'antd';
import { groupCategoryRequest } from '@/service/groupManage';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { formSearchGutter } from '@/constants/common';
import { filterMultipleCateData } from '@/utils/cate';
import { filterTreeData } from '@/utils/common';
import styles from '@/styles/pageContent.module.scss';

interface GroupListProps {
  columns: any; // 额外传入的表格列配置
}
const GroupList = (props: GroupListProps, ref: any) => {
  const { columns } = props;
  const { userDetail } = useSelector((state: any) => state.userStore);
  const [allGroupData, setAllGroupData] = useState<any>([]); // 全量的分组数据
  const [groupData, setGroupData] = useState<any>([]); // 表格显示的分组数据
  const [loading, setLoading] = useState<boolean>(false); // 表格数据是否加载中
  const [searchValue, setSearchValue] = useState<string>(''); // 搜索的内容
  const [expandedKeys, setExpandedKeys] = useState<any>([]); // 展开的keys

  // 暴露给父组件调用的方法
  useImperativeHandle(ref, () => ({
    getGroupData // 获取分组数据
  }));

  // 过滤分组
  const filterGroup = (data: any, value: string) => {
    const allExpandKeys: any = [];
    const filterGroupData = filterTreeData(data, value, 'title', (item: any) =>
      allExpandKeys.push(item.id)
    );
    setGroupData(filterGroupData);
    setExpandedKeys(allExpandKeys);
  };

  // 获取分组数据
  const getGroupData = async () => {
    try {
      setLoading(true);
      const res = await groupCategoryRequest();
      let finalGroupData = res.data?.data || [];
      // 针对有多个分组的账号特殊过滤分组数据
      if (userDetail.multiCate) {
        finalGroupData = filterMultipleCateData(
          finalGroupData,
          JSON.parse(userDetail.multiCate)
        );
      }
      setAllGroupData(finalGroupData);
      if (searchValue) {
        // 有搜索项时显示搜索过滤后的数据
        filterGroup(finalGroupData, searchValue);
      } else {
        setGroupData(finalGroupData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGroupData();
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    newSearchParams.name = (values.name || '').trim();
    setSearchValue(newSearchParams.name);
    if (newSearchParams.name) {
      setLoading(true);
      setTimeout(() => {
        filterGroup(allGroupData, newSearchParams.name);
        setLoading(false);
      }, 0);
    } else {
      setExpandedKeys([]);
      setGroupData(allGroupData);
    }
  };

  // 表格列显示
  const allColumns = [
    {
      title: '名称',
      dataIndex: 'title',
      width: 220,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '分组编号',
      dataIndex: 'id',
      width: 90
    },
    ...columns
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} autoComplete="off">
        <Row gutter={formSearchGutter}>
          <Col span={9}>
            <Form.Item label="分组名称" name="name">
              <Input placeholder="请输入分组名称" allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                搜索
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        columnFilter={false}
        rowKey="id"
        columns={allColumns}
        dataSource={groupData}
        pagination={false}
        bordered
        loading={loading}
        expandedRowKeys={expandedKeys}
        onExpandedRowsChange={expandedRows => setExpandedKeys(expandedRows)}
      />
    </Card>
  );
};

export default forwardRef(GroupList);
