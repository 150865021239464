/**
 * 工单备注
 */
import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";
import { commentWorkOrderRequest } from '@/service/workOrder';

interface CommentModalProps {
  id: number; // 工单id
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 添加成功后重新请求工单的方法
}

const CommentModal = (props: CommentModalProps) => {
  const { id, onClose, reloadData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 工单备注
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        const params = {...values};
        params.id = id;
        setLoading(true);
        const res = await commentWorkOrderRequest(params);
        if (res.status == 200) {
          setSubmitNoRepetition(true)
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success('备注成功');
          onClose();
          reloadData();
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  return (
    <Modal
      title="工单备注"
      visible
      centered
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="备注"
          name="reason"
          rules={[
            { required: true, message: '请输入备注' }
          ]}
        >
          <Input.TextArea placeholder="请输入备注" allowClear />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CommentModal;