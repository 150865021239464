/**
 * 实时监控
 */
import React, { useEffect, useState } from 'react';
import { Button, Form, message } from 'antd';
import byskplayer from './byskplayer';
import styles from './index.module.scss';
import CarTree from '@/components/carTree';
import axios from 'axios';
import flvjs from './flv';
import { recordSwitchRequest, getVideoDurationRequest } from '@/service/realMonitoring';
import { log } from 'console';
import { useSelector } from 'react-redux';

const RealMonitoring = () => {
  const [monitoring, setMonitoring] = useState({ device: '', channel: '' }); // 行程数据
  const [premonitoring, setPremonitoring] = useState({ device: '', channel: '' }); // 之前行程数据

  const [realPlayer, setRealPlayer] = useState({
    allocate(one: any) { },
    real: {
      open(one: any, two: any) { },
      close(one: any, two: any, three: any) { },
      openSpeak(one: any, two: any) { },
      closeSpeak(one: any, two: any) { }
    }
  }); // 储存player实例
  const [talkback, setTalkback] = useState(true); //对讲展示与隐藏
  const [getToken, setGetToken] = useState(''); // 获取token
  const [manufacturerType, setManufacturerType] = useState<any>(); //储存厂家
  const [playerDisplay, setPlayerDisplay] = useState('none'); //珀仕杰展示与隐藏
  const [flyPlayerDisplay, setFlyPlayerDisplay] = useState('block'); //锐齿慢展示与隐藏
  const [videoDisplay, setvideoDisplay] = useState('none'); //video展示与隐藏
  const [videoTwoDisplay, setvideoTwoDisplay] = useState({ dis: 'none', wid: 50, hei: 50, dislast: 'none' }); //video超过四个展示与隐藏
  const [textDisplay, settextDisplay] = useState('block'); //文字展示与隐藏
  const [realFlvPlayer, setRealFlvPlayer] = useState<any>();
  const [previousRealFlvPlayer, setPreviousRealFlvPlayer] = useState('');
  const [playList, setPlayList] = useState<any>();
  const [prePlayList, setPrePlayList] = useState<any>();
  const [carId, setCarId] = useState<any>(); //carId

  // 页面跳转
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  const [form] = Form.useForm();

    // 监听跳转时携带参数的形式
    useEffect(() => {
    }, [activeKey, tabParams]);

  
  let realFlvPlayerTwo = true
  //把rearealFlvPlayerTwo 存入本地
  localStorage.setItem('realFlvPlayerTwo', JSON.stringify(true));

  let pl: any = [];
  let player: any = null;
  useEffect(() => {
      axios({
        method: 'post',
        url: 'https://api.sdv2x.com/vehicle-openapi/sys/login',
        data: {
          "appCid": "6cd4420d49834001aba45d298a1b39e5",
          "appSecret": "73478508997ede8d2759a4c3a9806a9e"
        }
      }).then((res) => {
        setGetToken(res.data.data.Authorization)
      })
    setPremonitoring(monitoring)
    setPreviousRealFlvPlayer(realFlvPlayer)
    setPrePlayList(playList)
    return componentWillUnmount;
  }, [monitoring, realFlvPlayer, playList]

  );

  const treeSelect = (selectCarInfo: any) => {
    console.log('sele', selectCarInfo);
    // 切换车辆的时候， 关闭已有的监控
    if (realFlvPlayerTwo) {
      closeMonitoring(false)
    }

    //@ts-ignore
    document.querySelector('.realcontainer_box').innerHTML = '<div id="realcontainer"></div>';
    const { channel, terminal, manufacturer, metaId } = selectCarInfo;
    setCarId(metaId)

    const ccc = channel
    const aaa = []
    for (var i = 1; i <= ccc; i++) {
      aaa.push(i)
    }
    const bbb = aaa.toString()
    const carList = { device: terminal, channel: bbb };
    setMonitoring(carList)
    setTalkback(true)
    if (manufacturer == '1') {
      setTalkback(false)
      setPlayerDisplay('block')
      setFlyPlayerDisplay('none')
      player = new byskplayer({
        id: 'realcontainer',
        isReal: true,
        isRecord: false,
        userId: 'apitest',
        userkey: 'dbd78671-f01c-47db-98c6-06ce1cd400f6',
        baseURL: 'wss://videostream.car900.com',
        isSwitchCodetypeOnFullscreen: false, //全屏是否切换高清，默认false
      });
      player.setPlayerNum(4); //设置视频画面
      setRealPlayer(player)
      setManufacturerType(1)
    } else if (manufacturer == '2') {
      setPlayerDisplay('none')
      setFlyPlayerDisplay('block')
      setvideoDisplay('block')
      settextDisplay('none')
      setManufacturerType(2)
    } else {
      setPlayerDisplay('none')
      setFlyPlayerDisplay('block')
      setvideoDisplay('none')
      settextDisplay('block')
      message.info("该车辆暂无监控");
    }
  }

  //播放前进行筛选
  const selectCarTime = async () => {
    const res = await getVideoDurationRequest()
    let data = res.data?.data
    if(data > 0){
      openMonitoring()
    }else {
      message.info("该账号观看视频剩余时长不足,请联系:400-990-9171");
    }
  }


  // 播放
  const openMonitoring = async () => {
    console.log(carId);
    // 记录点击开始的时间
    const params = {
      type: 1,
      carId: carId
    }
    const res = await recordSwitchRequest(params);
    // 每十分钟关闭一次监控画面
    const close = setInterval(function () {
      closeMonitoring(true)
      clearInterval(close)
    }, 600000);

    if (manufacturerType == 1) {
      const channelArr = monitoring.channel.split(',').map(Number);
      let tidObj: any = { flag: null, ids: [] }
      tidObj = realPlayer.allocate(channelArr.length); //根据通道数申请
      if (tidObj.flag == 1) {
        let params = {
          device: monitoring.device,//终端号
          channel: monitoring.channel, //通道号 （1~8）
          protocolType: 1, //协议类型，1：GF-1078，GB-1078; 2：GA系列
          codeTypeCtrlBtnEnabled: false, //是否显示高标清切换按钮(GF才有高清)
          SpecialSign: 0,
          playerType: 0,
          codetype: 1, // 0: 高清; 1: 标清
        };
        for (let i = 0; i < channelArr.length; i++) {
          const tid = tidObj.ids[i];
          const param = {
            ...params,
            channel: channelArr[i],
          };
          realPlayer.real.open(tid, param);
          pl.push({
            ...param
          });
        }
        setPlayList(pl)
      }
    } else if (manufacturerType == 2) {
      const channelQuantity = Number(monitoring.channel.slice(-1))
      if (channelQuantity > 4 && channelQuantity < 7) {
        setvideoTwoDisplay({ dis: 'block', wid: 33.333, hei: 50, dislast: 'none' })
      }
      if (channelQuantity > 6 && channelQuantity < 10) {
        setvideoTwoDisplay({ dis: 'block', wid: 33.333, hei: 33.333, dislast: 'block' })
      }
      const chars = monitoring.channel.split(',')
      const flvPlayerList: any = []
      chars.map((item) => {
        const textChannelNum = Number(item)
        axios({
          method: 'get',
          url: 'https://api.sdv2x.com/vehicle-openapi/video/monitor/realtimeUrl',
          headers: {
            Authorization: getToken
          },
          params: {
            mediaType: 0,
            channelNum: textChannelNum,
            streamType: 1,
            deviceNum: monitoring.device
          }
        }).then((res) => {
          const flvjsUrl = res.data.data
          //@ts-ignore
          if (flvjs.isSupported()) {
            var videoElement = document.getElementById(`${item}_video`);
            //@ts-ignore
            var flvPlayer = flvjs.createPlayer({
              type: 'flv',
              url: flvjsUrl, // 你的url地址
              isLive: true,
              enableStashBuffer: true,
              stashInitialSize: 64,
              autoCleanupSourceBuffer: true,
              autoCleanupMaxBackwardDuration: 5,
              autoCleanupMinBackwardDuration: 1,
              lazyLoadMaxDuration: 5
            });
            flvPlayer.attachMediaElement(videoElement);
            flvPlayer.load();
            flvPlayer.play();
          } else {
            console.error('当前浏览器不支持当前视频播放模式！');
          }
          flvPlayerList.push(flvPlayer)
          setRealFlvPlayer(flvPlayerList)
        })
      })
    } else if (manufacturerType == undefined) {
      message.info("请先在左侧选择车辆");
    }
    console.log('监控对象播放结束', monitoring);

  }

  // 切换页面和车辆时，销毁已播放的视频  
  const destroyMonitoring = () => {
    console.log('监控摧毁  ', monitoring);
    console.log('监控摧毁before   ', premonitoring);
    console.log('监控摧毁playList   ', playList);
    console.log('监控摧毁prePlayList   ', prePlayList);
    const channelArr = monitoring.channel?.split(',').map(Number);
    for (let i = 0; i < channelArr.length; i++) {
      if (playList) {
        let index = playList.findIndex((p: { device: any; channel: number; }) => p.device == monitoring.device && p.channel == channelArr[i]);
        let obj = playList[index];
        if (obj) realPlayer.real.close(obj.tid, {
          device: obj.device,
          channel: obj.channel,
          protocolType: obj.protocolType
        }, (res: any) => {
          console.log(res);
        });
        playList.splice(index, 1);
      }

    }
    realFlvPlayer?.map((item: any) => {
      console.log(item)
      item.detachMediaElement()
      item.destroy()
    })

  }

  // 停止
  const closeMonitoring = async (ifParams: any) => {
    if (ifParams) {
      // 记录点击结束的时间
      const params = {
        type: 2,
        carId: carId
      }
      const res = await recordSwitchRequest(params);
    }

    if (manufacturerType == 1) {
      const channelArr = monitoring.channel.split(',').map(Number);
      for (let i = 0; i < channelArr.length; i++) {
        if (playList) {
          let index = playList.findIndex((p: { device: any; channel: number; }) => p.device == monitoring.device && p.channel == channelArr[i]);
          let obj = playList[index];
          if (obj) realPlayer.real.close(obj.tid, {
            device: obj.device,
            channel: obj.channel,
            protocolType: obj.protocolType
          }, (res: any) => {
            console.log(res);
          });
          playList.splice(index, 1);
        }

      }
    } else if (manufacturerType == 2) {
      realFlvPlayer?.map((item: any) => {
        item.detachMediaElement()
        item.destroy()
      })
    }
  }
  // 开启对讲
  const openSpeak = () => {
    if (manufacturerType == 1) {
      const SpecialSign = 0
      const params = {
        device: monitoring.device, //终端号
        protocolType: 1, //协议类型，1：GF-1078，2：GA系列，目前支持GF-1078
        SpecialSign,
      };
      realPlayer.real.openSpeak(params, (res: any) => {
        console.log(res);
      });
    }

  }

  // 关闭对讲
  const closeSpeak = () => {
    if (manufacturerType == 1) {
      const params = {
        device: monitoring.device,
        protocolType: 1
      };
      realPlayer.real.closeSpeak(params, (res: any) => {
        console.log(res);
      });
    }
  }

  //测试监控是否停止
  function componentWillUnmount() {
    // 组件销毁时你要执行的代码
    closeMonitoring(false)
  }

  return (
    <div className={styles.trackPlaybackBox}>
      {/* 左边栏车辆列表（公共组件） */}
      <div className={styles.carTree}>
        <CarTree
          treeSelect={treeSelect}
          selectableTypes={['car']}
          searchPlateNumber={tabParams?.plateNumber}
        />
      </div>
      <div></div>
      <div className={styles.monitoring}>
        <div className={styles.monitoringTitle}>
          监控画面
          <Button className={styles.monitoringButton} type="primary" onClick={selectCarTime}>播放</Button>
          <Button className={styles.monitoringButton} type="ghost" onClick={closeMonitoring}>停止</Button>
          <Button className={styles.monitoringButton} type="primary" onClick={openSpeak} disabled={talkback}>开启对讲</Button>
          <Button className={styles.monitoringButton} type="ghost" onClick={closeSpeak} disabled={talkback}>关闭对讲</Button>
        </div>
        <div className={'realcontainer_box'} style={{ display: playerDisplay, height: '100%' }}>
          <div id='realcontainer'></div>
        </div>

        <div id='realcontainer' className={styles.monitoringContent} style={{ display: flyPlayerDisplay }}>
          <video id="1_video" autoPlay controls={true} style={{ display: videoDisplay, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="2_video" autoPlay controls={true} style={{ display: videoDisplay, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="3_video" autoPlay controls={true} style={{ display: videoDisplay, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="4_video" autoPlay controls={true} style={{ display: videoDisplay, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="5_video" autoPlay controls={true} style={{ display: videoTwoDisplay.dis, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="6_video" autoPlay controls={true} style={{ display: videoTwoDisplay.dis, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="7_video" autoPlay controls={true} style={{ display: videoTwoDisplay.dislast, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="8_video" autoPlay controls={true} style={{ display: videoTwoDisplay.dislast, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <video id="9_video" autoPlay controls={true} style={{ display: videoTwoDisplay.dislast, width: `calc(${videoTwoDisplay.wid}%)`, height: `calc(${videoTwoDisplay.hei}%)` }}></video>
          <div className={styles.text} style={{ display: textDisplay }}>请从左侧选择需要播放实时监控的车辆</div>
        </div>
      </div>
    </div>
  )
}

export default RealMonitoring;