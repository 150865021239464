/**
 * 审批查询
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Select,
  Popconfirm,
  message
} from 'antd';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import JsonDiffModal from '@/components/jsonDiffModal';
import {
  auditListRequest,
  rejectAuditRequest,
  approveAuditRequest,
  approveJtKlsAuditRequest
} from '@/service/approveSearch';
import { organizationListRequest } from '@/service/organizationManage';
import { userListRequest } from '@/service/userManage';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { getInnerPermissionEnable } from '@/utils/permission';
import { isKlsV3Aeb } from '@/utils/device';
import {
  approveStatusData,
  ApproveStatusEnum,
  approveTypeData
} from '@/constants/approveSearch';
import { formSearchGutter } from '@/constants/common';
import styles from '@/styles/pageContent.module.scss';

const ApproveSearch = () => {
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;

  // 查看所有审批申请(默认看到自己发起的申请)
  const viewAll = getInnerPermissionEnable(
    '/car-admin/approve-search/view-all'
  );
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [user, setUser] = useState([]); // 申请人数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作信息存储

  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: auditListRequest,
      initPageSize: 40,
      searchParams,
      initRequest: false
    });
  const [form] = Form.useForm();

  // 获取申请人数据
  const getUser = async () => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setUser(res.data?.data || []);
  };

  // 获取当前用户所属组织下的所有组织
  const getCurrentUserGroupIds = async () => {
    if (viewAll) {
      const res = await organizationListRequest();
      let findGroupData = '';
      const findGroup = (innerData: any) => {
        for (let index = 0; index < innerData.length; index++) {
          const item = innerData[index];
          if (item.id === userDetail.groupId) {
            findGroupData = item;
            break;
          }
          if (item.children?.length) {
            findGroup(item.children);
          }
        }
      };
      findGroup([res.data?.data]);
      const allGroupIds: any = [];
      const deep = (data: any) => {
        data.forEach((item: any) => {
          allGroupIds.push(item?.id);
          if (item.children?.length) {
            deep(item.children);
          }
        });
      };
      deep([findGroupData]);
      setSearchParams({
        groupIds: allGroupIds
      });
    } else {
      setSearchParams({
        groupIds: []
      });
    }
  };

  useEffect(() => {
    getUser();
    getCurrentUserGroupIds();
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    setSearchParams({
      ...searchParams,
      ...values
    });
  };

  // 拒绝审批
  const rejectAudit = async (id: number) => {
    const res = await rejectAuditRequest(id);
    if (res?.data?.data) {
      message.success('审批已拒绝');
      getData();
    } else {
      message.error('审批拒绝失败');
    }
  };

  // 通过审批
  const approveAudit = async (record: any) => {
    const { id, deviceTypeCategory } = record;
    // 昆仑山3.0设备使用不同的接口
    const requestFunc = isKlsV3Aeb(deviceTypeCategory)
      ? approveJtKlsAuditRequest
      : approveAuditRequest;
    const res = await requestFunc(id);
    if (res.data?.code === 0) {
      message.success('审批通过');
      getData();
    } else {
      message.error('审批通过失败');
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '审批编号',
      dataIndex: 'id',
      width: 110
    },
    {
      title: '申请人',
      dataIndex: 'nickname',
      width: 120
    },
    {
      title: '操作类型',
      dataIndex: 'type',
      width: 140,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn
          text={
            approveTypeData.find((item: any) => item.value === value)?.label ||
            ''
          }
        />
      )
    },
    {
      title: '原因',
      dataIndex: 'reason',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '审批状态',
      dataIndex: 'statDesc',
      width: 110
    },
    {
      title: '参数下发状态',
      dataIndex: 'paramStatDesc',
      width: 120
    },
    {
      title: '修改内容',
      dataIndex: 'targetDesc',
      width: 110,
      render: (value: any, item: any) => (
        <div className={styles.tableOperateColumn}>
          <Button
            type="link"
            onClick={() =>
              setOperateInfo({
                type: 'diff',
                data: {
                  oldValue: item.originDesc,
                  newValue: value
                }
              })
            }
          >
            查看
          </Button>
        </div>
      )
    },
    {
      title: '申请日期',
      dataIndex: 'createdAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '审批日期',
      dataIndex: 'updatedAt',
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      )
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 120,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        if (item.stat === ApproveStatusEnum.pending) {
          return (
            <div className={styles.tableOperateColumn}>
              <Popconfirm
                title="您确定要通过该审批申请?"
                onConfirm={() => approveAudit(item)}
              >
                <PermissionButton
                  type="link"
                  operateType="/car-admin/approve-search/approve"
                >
                  通过
                </PermissionButton>
              </Popconfirm>
              <Popconfirm
                title="您确定要拒绝该审批申请?"
                onConfirm={() => rejectAudit(item.id)}
              >
                <PermissionButton
                  type="link"
                  operateType="/car-admin/approve-search/reject"
                >
                  拒绝
                </PermissionButton>
              </Popconfirm>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      {viewAll && (
        <Form onFinish={onSearch} form={form}>
          <Row gutter={formSearchGutter}>
            <Col span={8}>
              <Form.Item label="审批状态" name="stats">
                <Select
                  options={approveStatusData}
                  mode="multiple"
                  showArrow
                  showSearch
                  allowClear
                  optionFilterProp="label"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="申请人" name="userIds">
                <Select
                  options={user}
                  fieldNames={{
                    label: 'nickname',
                    value: 'id'
                  }}
                  mode="multiple"
                  showArrow
                  showSearch
                  allowClear
                  optionFilterProp="nickname"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <div>
                  <Button type="primary" htmlType="submit">
                    搜索
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        reloadFunc={getData}
      />
      {/* JSON Diff */}
      {operateInfo.type === 'diff' && (
        <JsonDiffModal
          {...operateInfo.data}
          closeModal={() => setOperateInfo({})}
          title="修改内容"
          leftTitle="原始值"
          rightTitle="修改值"
        />
      )}
    </Card>
  );
};

export default ApproveSearch;
