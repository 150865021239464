/**
 * 行驶轨迹查询
 */
import React, { useEffect, useRef, useState } from 'react';
import { Card, Form, Input, Button, Row, Col, Descriptions } from 'antd';
import gcoord from 'gcoord';
import { isEmpty } from 'lodash';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import { formSearchGutter } from '@/constants/common';
import { trackByPlateNumberRequest } from '@/service/driveTrack';
import { operateTimeRange } from '@/utils/operateSearchParams';
import { importAliMap, initMap } from '@/utils/map';
import { renderGps } from '@/utils/driveAnalysis';
import commonStyles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const DriveTrack = () => {
  const [form] = Form.useForm();
  const mapDomRef = useRef(null); // 渲染地图的DOM
  const mapRef: any = useRef(null); // 存储地图实例
  const [loading, setLoading] = useState(false); // 请求loading状态
  const [trackData, setTrackData] = useState<any>({}); // 行程数据

  useEffect(() => {
    importAliMap(() => initMap(mapDomRef, mapRef, 'top'));
  }, []);

  // 查询
  const onSearch = async (values: any) => {
    try {
      setLoading(true);
      const params = operateTimeRange(values, true);
      mapRef.current?.clearMap();
      const res = await trackByPlateNumberRequest(params);
      const { carGpsList, effectiveTime, mileage } = res.data?.data || {};
      if (carGpsList?.length) {
        const firstPoint = carGpsList[0];
        const transformGcoord = gcoord.transform(
          [firstPoint.lat, firstPoint.lon],
          gcoord.WGS84,
          gcoord.AMap
        );
        const firstLngLat = new window.AMap.LngLat(
          transformGcoord[0],
          transformGcoord[1]
        );
        mapRef.current.setCenter(firstLngLat);
        // 渲染gps点位
        renderGps(carGpsList, mapRef, true, true, true);
        setTrackData({ effectiveTime, mileage });
      } else {
        setTrackData({});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Card className={commonStyles.fixPageContentBox}>
      <Form form={form} onFinish={onSearch} autoComplete="off">
        <Row gutter={formSearchGutter}>
          <Col span={6}>
            <Form.Item
              label="车牌号"
              name="plateNumber"
              rules={[{ required: true, message: '请输入车牌号' }]}
            >
              <Input placeholder="请输入车牌号" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="时间范围"
              name="time"
              rules={[{ required: true, message: '请选择时间范围' }]}
            >
              <RangePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {isEmpty(trackData) || (
        <Descriptions bordered>
          <Descriptions.Item label="行驶里程">{`${trackData.mileage}（公里）`}</Descriptions.Item>
          <Descriptions.Item label="行驶时长">{`${trackData.effectiveTime}（分钟）`}</Descriptions.Item>
        </Descriptions>
      )}
      <LoadingWrapper spinning={loading} autoHeight>
        <div ref={mapDomRef} style={{ height: '100%' }} />
      </LoadingWrapper>
    </Card>
  );
};

export default DriveTrack;
