/**
 * 车队档案
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, message, Popconfirm, Select } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { useTable } from '@/utils/useTable';
import styles from '@/styles/pageContent.module.scss';
import PermissionButton from '@/components/permissionButton';
import OperateModal from './components/operateModal';
import { companyListRequestPath, companyListRequest, DeleteCompany } from '@/service/safeReport';
import { cateAllCompanyRequest } from '@/service/fleetRisk';

const SafeReport = () => {
  const [searchParams, setSearchParams] = useState({
  }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: companyListRequest,
    initPageSize: 40,
    searchParams,
  });

  useEffect(() => {
    getCateAllCompanyData()
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = { ...values };
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    values.export = true;
    return values
  }

  // 删除单条
  const deleteCompany = async (id: number) => {
    const res = await DeleteCompany(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }

  // 获取车队
  const getCateAllCompanyData = async () => {
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data 
    const aa: any = []
    data.forEach((item: any) => {
      aa.push({ label: item.title, value: item.title })
    })
    setAllCompany(aa)
  }


  // 表格列显示
  const columns = [
    {
      title: '编号',
      dataIndex: 'id',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '车队名称',
      dataIndex: 'name',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '负责人',
      dataIndex: 'head',
      width: 110,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '联系方式',
      dataIndex: 'tel',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '最新评级',
      dataIndex: 'grade',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '累积服务车辆总数',
      dataIndex: 'total',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '服务中车辆总数',
      dataIndex: 'serviceCount',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '状态',
      dataIndex: 'state',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '是否建群',
      dataIndex: 'buildHome',
      width: 90,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 170,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 100,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton operateType='/car-admin/safe-report/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <Popconfirm
              title="您确定要删除吗?"
              onConfirm={() => deleteCompany(item.id)}
            >
              <PermissionButton operateType='/car-admin/safe-report/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="车队选择"
              name="name"
            >
              <Select
                showSearch
                placeholder="请选择"
                style={{ width: '100%' }}
                options={allCompany}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox} style={{ marginTop: '32px' }}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton
                  url={companyListRequestPath}
                  fileName='车队档案'
                  getParamsFunc={getExportParams}
                  requestMethod="GET"
                  permissionKey='/car-admin/safe-report/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/safe-report/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default SafeReport;
