/**
 * 车辆信息更新记录
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, InputNumber, Select, Input } from 'antd';
import dayjs from 'dayjs';
import { carDeviceChargeListRequest, carDeviceChargeListRequestPath } from '@/service/carDeviceCharge';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import { useTable } from '@/utils/useTable';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { formatTime } from '@/utils/formatTime';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const  CarDeviceCharge = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginTime: `${dayjs().subtract(8, 'day').format('YYYY-MM-DD')}`,
    endTime: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    min: 80,
    duration: 1,
    cate_id: cateIdArr,
  }); // 搜索相关的参数
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carDeviceChargeListRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams:any = {...values}
    if (values.time) {
      newSearchParams.beginTime = dayjs(values.time[0]).format('YYYY-MM-DD');
      newSearchParams.endTime = dayjs(values.time[1]).format('YYYY-MM-DD');
      delete newSearchParams.time
    }
    setSearchParams(newSearchParams)
    return newSearchParams;
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    const params = {...values};
    delete params.time;
    params.beginTime = formatTime(values.time[0], 'YYYY-MM-DD');
    params.endTime = formatTime(values.time[1], 'YYYY-MM-DD');
    params.export = true;
    return params;
  }


  // 表格列显示
  const columns = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left' as 'left',
    },
    {
      title: '车架号',
      dataIndex: 'frameNumber',
      width: 70,
      fixed: 'left' as 'left',
    },
    // snColumnConfig,
    // cateGroupColumnConfig,
    {
      title: 'aeb设备sn',
      dataIndex: 'sn',
      width: 150,
      ellipsis: { showTitle: false },
    }, {
      title: 'aeb相机sn',
      dataIndex: 'carmaSn',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: 'adas设备sn',
      dataIndex: 'adasSn',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: '操作类型',
      dataIndex: 'flag',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: '操作人',
      dataIndex: 'creator',
      width: 130,
    },
    {
      title: '操作时间',
      dataIndex: 'createdAt',
      width: 130,
      render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
    },
    {
      title: '分组',
      dataIndex: 'catePathName',
      width: 130,
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cate_id}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col lg={6} >
            <Form.Item label="aeb设备SN" name="sn">
              <Input placeholder="请输入aeb设备SN" />
            </Form.Item>
          </Col>
          <Col lg={6} >
            <Form.Item label="aeb相机SN" name="carmaSn">
              <Input placeholder="请输入aeb设备SN" />
            </Form.Item>
          </Col>
          <Col lg={6} >
            <Form.Item label="adas设备SN" name="adasSn">
              <Input placeholder="请输入adas设备SN" />
            </Form.Item>
          </Col>

        </Row>
        <Row gutter={24} wrap>
          <Col lg={6} >
            <Form.Item label="车架号" name="frameNumber">
              <Input placeholder="请输入车架号" />
            </Form.Item>
          </Col>
          <Col lg={6} >
            <Form.Item label="车牌号" name="plateNumber">
              <Input placeholder="请输入车牌号" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="是否只查询更新设备记录" name="deviceCharge">
              <Select
                placeholder='请选择是否只查询更新设备记录'
                options={[
                  { label: '是', value: 1 },
                  { label: '否', value: 2 },
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginTime), dayjs(searchParams.endTime)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className={styles.searchBox}>
            <Button type="primary" htmlType="submit">搜索</Button>
            <ExportButton
              url={carDeviceChargeListRequestPath}
              fileName='车辆信息更新记录'
              getParamsFunc={getExportParams}
              requestMethod="POST"
              permissionKey='/car-admin/car-device-charge/export'
            />
          </div>
        </Form.Item>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
    </Card>
  )
}

export default  CarDeviceCharge;
