/**
 * 数据大屏
 */
import React, { Fragment } from 'react';
import DataSizeDetails from '@/pages/dataSizeDetails'

const VehicleAdminRoute = () => {
 
  return (
    <Fragment>
      <DataSizeDetails />
    </Fragment>
  )
}

export default VehicleAdminRoute;
