/**
 * adas轨迹回放相关请求
 */
import request from "@/utils/request";

// 获取车辆坐标
export const adasCurrentLocationRequest = (id: string) => {
  return request.get(`/api/v2/trackPlayback/currentLocation/adas/${id}`);
}

export interface DayInfoRequestParams {
  trickDate: string;
  plateNumber: string;
}

// 获取GPS点位数据(某一天)
export const adasDayGpsRequest = (params: DayInfoRequestParams) => {
  return request.get('/api/v2/trackPlayback/dayGpsStatistic/adas', {
    params
  });
}

// // 获取GPS点位数据(时间区间)
// export const listGpsRequestPath = '/api/v2/trackPlayback/listGps';
// export const listGpsRequest = (params: any) => {
//   return request.get(listGpsRequestPath, {
//     params
//   });
// }

// 获取报警数据
export const adasDayAlarmRequest = (params: DayInfoRequestParams) => {
  return request.get('/api/v2/trackPlayback/dayAlarm/adas', {
    params
  });
}

// export const gpsListExportPath = '/api/v2/trackPlayback/gpsListExport';
