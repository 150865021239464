/**
 * 参数配置相关请求(昆仑山3.0设备)
 */
import request from '@/utils/request';

// 处理请求参数
const operateRequestParams = (params: any) => {
  const reuqestParams = { ...params };
  delete reuqestParams.sn;
  return {
    reuqestParams,
    sn: params.sn
  };
};

// 获取车身类型数据
export const getJtKlsCarTypeRequest = (sn: string) => {
  return request.get(`/808/aebs/vehicleType/${sn}`);
};

// 设置车身类型
export const setJtKlsCarTypeRequest = (data: any) => {
  const { sn, type } = data;
  return request.put(`/808/aebs/vehicleType/${sn}`, { carType: type });
};

// 获取软硬件信息数据
export const getJtKlsWareInfoRequest = (sn: string) => {
  return request.get(`/808/aebs/deviceInfo/${sn}`);
};

// 获取AEB参数配置
export const getJtKlsAebParamsRequest = (sn: string) => {
  return request.get(`/808/aebs/aebConfig/${sn}`);
};

// 设置AEB参数配置
export const setJtKlsAebParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/808/aebs/aebConfig/${sn}`, reuqestParams);
};

// 获取传感器配置信息
export const getJtKlsSensorParamsRequest = (sn: string) => {
  return request.get(`/808/aebs/sensorConfig/${sn}`);
};

// 设置传感器配置
export const setJtKlsSensorParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/808/aebs/sensorConfig/${sn}`, reuqestParams);
};

// 获取相机参数
export const getJtKlsCameraParamsRequest = (sn: string) => {
  return request.get(`/808/aebs/cameraConfig/${sn}`);
};

// 设置相机参数
export const setJtKlsCameraParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/808/aebs/cameraConfig/${sn}`, reuqestParams);
};

// 获取拉线电机参数
export const getJtKlsCableMotorParamsRequest = (sn: string) => {
  return request.get(`/808/aebs/pullingMotorConfig/${sn}`);
};

// 设置拉线电机参数
export const setJtKlsCableMotorParamsRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/808/aebs/pullingMotorConfig/${sn}`, reuqestParams);
};

// 设置CAN dbc配置
export const setJtKlsCanDbcRequest = (data: any) => {
  const { reuqestParams, sn } = operateRequestParams(data);
  return request.put(`/808/aebs/canDbcConfig/${sn}`, reuqestParams);
};
