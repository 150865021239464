/**
 * 设备全检
 */
import React, { useState } from 'react';
import { Card, Button, Modal, Tabs, message, Popconfirm } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import PermissionButton from '@/components/permissionButton';
import ExportButton from '@/components/exportButton';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { isKlsAeb, displayDeviceTypeCategory, isHhAeb } from '@/utils/device';
import {
  deviceInspectStatusData,
  DeviceInspectStatusEnum
} from '@/constants/deviceInspect';
import { DeviceCategoryEnum } from '@/constants/deviceManage';
import {
  fullInspectionListRequest,
  upsertFullInspectionRequest,
  fullInspectionExportRequestPath
} from '@/service/deviceInspect';
import OperationModal from './components/operateModal';
import AlarmTable from './components/alarmTable';
import CarInfoTable from './components/carInfoTable';
import GPSTable from './components/gpsTable';
import DeviceStatusTable from './components/deviceStatusTable';
import styles from '@/styles/pageContent.module.scss';

const DeviceInspect = () => {
  const [searchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const { page, limit, loading, data, totalCount, onPageChange, getData } =
    useTable({
      requestFunc: fullInspectionListRequest,
      initPageSize: 40,
      searchParams
    });

  // 结束检测
  const endInspect = async (item: any) => {
    const params = {
      id: item.id,
      sn: item.sn,
      beginTime: item.beginTime,
      endTime: dayjs().format('YYYY-MM-DD HH:mm:ss')
    };
    const res = await upsertFullInspectionRequest(params);
    if (res.data?.code === 0) {
      message.success('检测已结束');
      getData();
    }
  };

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
      fixed: 'left' as const
    },
    {
      title: '设备SN',
      dataIndex: 'sn',
      width: 140,
      fixed: 'left' as const
    },
    {
      title: '执行人',
      dataIndex: 'optUser',
      width: 100
    },
    {
      title: '开始时间',
      dataIndex: 'beginTime',
      width: 160,
      render: (value: string) => formatTime(value)
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      width: 160,
      render: (value: string) => formatTime(value)
    },
    {
      title: '检测状态',
      dataIndex: 'stat',
      width: 120,
      render: (value: string) => (
        <span className={deviceInspectStatusData[value]?.class}>
          {deviceInspectStatusData[value]?.text}
        </span>
      )
    },
    {
      title: '控制盒类型',
      dataIndex: 'mcuType',
      width: 120,
      render: (value: number) => displayDeviceTypeCategory(value)
    },
    {
      title: '控制盒版本',
      dataIndex: 'mcuVersion',
      width: 130
    },
    {
      title: '双目相机版本',
      dataIndex: 'cameraVersion',
      width: 130
    },
    {
      title: '小屏幕版本',
      dataIndex: 'screenVersion',
      width: 130
    },
    {
      title: '硬件版本信息',
      dataIndex: 'info',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '备注',
      dataIndex: 'ps',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 160,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item.id })}
            >
              编辑
            </Button>
            <Button
              type="link"
              onClick={() => setOperateInfo({ type: 'detail', data: item })}
            >
              详情
            </Button>
            <Popconfirm
              title="您确定要结束此检测任务吗?"
              onConfirm={() => endInspect(item)}
            >
              {item.stat === DeviceInspectStatusEnum.process && (
                <Button type="link">结束检测</Button>
              )}
            </Popconfirm>
          </div>
        );
      }
    }
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <div className={`${styles.operateBox} ${styles.spaceBetweenFlex}`}>
        <div>
          <PermissionButton
            operateType="/car-admin/device-inspect/add"
            type="primary"
            onClick={() => setOperateInfo({ type: DeviceCategoryEnum.kunlunshan })}
          >
            创建昆仑山全检任务
          </PermissionButton>
          <PermissionButton
            operateType="/car-admin/device-inspect/add"
            type="primary"
            onClick={() =>
              setOperateInfo({ type: DeviceCategoryEnum.kunlunshanV3 })
            }
          >
            创建昆仑山3.0全检任务
          </PermissionButton>
          <PermissionButton
            operateType="/car-admin/device-inspect/add"
            type="primary"
            onClick={() => setOperateInfo({ type: DeviceCategoryEnum.huanghe })}
          >
            创建黄河全检任务
          </PermissionButton>
        </div>
        <Button icon={<RedoOutlined />} onClick={() => getData()}>
          刷新
        </Button>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
      />
      {[
        DeviceCategoryEnum.kunlunshan,
        DeviceCategoryEnum.kunlunshanV3,
        DeviceCategoryEnum.huanghe,
        'edit'
      ].includes(operateInfo.type) && (
        <OperationModal
          type={operateInfo.type}
          id={operateInfo.data}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      )}
      {operateInfo.type === 'detail' && (
        <Modal
          title="检测信息"
          open
          onCancel={() => setOperateInfo({})}
          footer={null}
          bodyStyle={{ height: '70vh', overflow: 'auto' }}
          centered
          maskClosable={false}
          width="70%"
        >
          <Tabs
            tabBarExtraContent={{
              right: (
                <ExportButton
                  type="primary"
                  url={fullInspectionExportRequestPath}
                  fileName="全检信息"
                  getParamsFunc={() => {
                    const { beginTime, endTime, virtualCarId, mcuType, sn } =
                      operateInfo.data || {};
                    return {
                      beginTime,
                      endTime: endTime || dayjs().format('YYYY-MM-DD HH:mm:ss'),
                      carId: virtualCarId,
                      isKls: isKlsAeb(mcuType),
                      sn,
                      deviceType: mcuType
                    };
                  }}
                  permissionKey="/car-admin/device-inspect/export"
                >
                  导出所有数据
                </ExportButton>
              )
            }}
            className={styles.fixModalTab}
          >
            <Tabs.TabPane tab="报警信息" key="alarm">
              <AlarmTable {...operateInfo.data} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                isHhAeb(operateInfo.data?.mcuType) ? '设备基本信息' : '车身信息'
              }
              key="carInfo"
            >
              <CarInfoTable {...operateInfo.data} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="GPS信息" key="gps">
              <GPSTable {...operateInfo.data} />
            </Tabs.TabPane>
            {/* 昆仑山设备显示此Tab */}
            {isKlsAeb(operateInfo.data?.mcuType) && (
              <Tabs.TabPane tab="设备状态信息" key="device">
                <DeviceStatusTable {...operateInfo.data} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </Modal>
      )}
    </Card>
  );
};

export default DeviceInspect;
