/**
 *车队档案相关请求
 */
import request from "@/utils/request";

export interface CompanyListRequestParams {
    page: number;
    limit: number;
    name: string;
}

export const companyListRequestPath = '/api/v2/company/list';


// 获取数据
export const companyListRequest = (params: CompanyListRequestParams) => {
    return request.get(companyListRequestPath,{
        params
    })
}

// 添加数据
export const upsertCompany = (data: any) => {
    return request.post('/api/v2/company/upsert', data)
}

// 删除
export const DeleteCompany = (id: number) => {
    return request.delete(`/api/v2/company/${id}`)
}