/**
 * 车辆诊断相关请求
 */
import request from "@/utils/request";

export interface CarDiagnosisParams {
  beginDate: string;
  endDate: string;
  plateNumber?: string;
  sn?: string;
  frameNumber?: string;
}
// 车辆诊断单车信息(adas设备)
export const carAdasRequestPath = '/api/v2/adasDayStatistic/list';
export const carAdasRequest = (params: CarDiagnosisParams) => {
  return request.get(carAdasRequestPath, {
    params,
  })
}

// 车辆诊断单车信息(黄河设备)
export const carDiagnosisRequestPath = '/api/v2/car/diagnosis';
export const carDiagnosisRequest = (params: CarDiagnosisParams) => {
  return request.get(carDiagnosisRequestPath, {
    params,
  })
}

// 车辆诊断单车设备状态变更信息(昆仑山设备)
export const carDiagnosisDeviceRequestPath = '/api/v2/deviceInfo/list';
export const carDiagnosisDeviceRequest = (params: any) => {
  return request.get(carDiagnosisDeviceRequestPath, {
    params,
  })
}

// 车辆诊断单车设备状态变更信息(时间区间)(昆仑山设备)
export const carDiagnosisDeviceTimeRangeRequestPath = '/api/v2/deviceInfo/listByTimeRange';
export const carDiagnosisDeviceTimeRangeRequest = (params: any) => {
  return request.get(carDiagnosisDeviceTimeRangeRequestPath, {
    params,
  })
}

// 车辆诊断单车设备版本变更信息(昆仑山设备)
export const carDiagnosisVersionRequestPath = '/api/v2/deviceInfo/versionInfoList';
export const carDiagnosisVersionRequest = (params: any) => {
  return request.get(carDiagnosisVersionRequestPath, {
    params,
  })
}

// 车辆诊断单车设备参数变更信息(昆仑山设备)
export const carDiagnosisParamsRequestPath = '/api/v2/deviceInfo/aebConfigList';
export const carDiagnosisParamsRequest = (params: any) => {
  return request.get(carDiagnosisParamsRequestPath, {
    params,
  })
}

// 车辆诊断单车记录仪异常数据(昆仑山设备)
export const carDiagnosisModemErrorRequestPath = '/api/v2/deviceInfo/modemErrorList';
export const carDiagnosisModemErrorRequest = (params: any) => {
  return request.get(carDiagnosisModemErrorRequestPath, {
    params,
  })
}

// 车辆诊断单车车身类型变更数据(昆仑山设备)
export const carDiagnosisVehicleTypeRequestPath = '/api/v2/deviceInfo/carTypeList';
export const carDiagnosisVehicleTypeRequest = (params: any) => {
  return request.get(carDiagnosisVehicleTypeRequestPath, {
    params,
  })
}

// 车辆诊断部标机设备故障信息数据(昆仑山设备)
export const carDiagnosisJtDeviceErrorRequestPath =
  '/api/v2/jt808Device/errorInfo';
export const carDiagnosisJtDeviceErrorRequest = (params: any) => {
  return request.get(carDiagnosisJtDeviceErrorRequestPath, {
    params
  });
};

// 获取车辆详情
export const carDetailV2Request = (id: string) => {
  return request.get(`/api/v2/car/detail/${id}`)
}

export interface MaintenancesRecordParams {
  carId?: string,
  page?: number,
  limit?: number;
  beginDate?: string;
  endDate?: string;
  cateId?: string | Array<string>;
  taskId?: any;
  plateNumber?: any;
  tel?:any
  frameNumber?: any;
}

// 获取维保记录(有标签的形式)
export const maintenancesRecordRequestPath = '/api/v2/car/diagnosis/maintenancesAndTag';
export const maintenancesRecordRequest = (params: MaintenancesRecordParams) => {
  return request.get(maintenancesRecordRequestPath, {
    params
  })
}

// 删除维保记录
export const deleteMaintenancesRecord = (data: { id: number; isForceDel?: boolean }) => {
  return request.delete('/api/v2/car/diagnosis/maintenances/delete', { 
    data
  })
}

// 添加维保记录
export const addMaintenancesRecord = (data: any) => {
  return request.post('/api/v2/car/diagnosis/addMaintenancesAndTag', data)
}

// 获取客服记录
export const serviceRecoedRequestPath = '/api/v2/customerRecord/list';
export const serviceRecoedRequest = (params: MaintenancesRecordParams) => {
  return request.get(serviceRecoedRequestPath, {
    params
  })
}

// 添加客服记录
export const addServiceRecoed = (data: any) => {
  return request.post('/api/v2/customerRecord/upsert', data)
}

// 删除客服记录
export const deleteServiceRecoed = (id: number) => {
  return request.delete(`/api/v2/customerRecord/${id}`)
}

// 插入呼叫车辆id
export const calloutCarRecoed = (data: any) => {
  return request.post('/api/v2/callRecord/upsert', data)
}

// 插入客服记录和工单的关系
export const serviceAndWorkOrder = (data: any) => {
  return request.post('/api/v2/customerRecord/insert/relation', data)
}

// 撤销工单
export const deleteServiceAndWorkOrder = (data: any) => {
  return request.post('/api/v2/customerRecord/delete/relation', data)
}

// 结束处理
export const closeServiceAndWorkOrder = (data: any) => {
  return request.post('/api/v2/customerRecord/close/relation', data)
}

// 批量添加客服记录
export const addServiceRecoedBatch = (data: any) => {
  return request.post('api/v2/customerRecord/add/multi', data)
}


// 获取客服记录次数
export const customerRecordCountRequestPath = '/api/v2/customerRecord/count';
export const customerRecordCountRequest = (params: any) => {
  return request.get(customerRecordCountRequestPath, {
    params
  })
}

// 获取维保记录统计
export const diagnosisCountRequestPath = '/api/v2/car/diagnosis/count';
export const diagnosisCountRequest = (params: any) => {
  return request.get(diagnosisCountRequestPath, {
    params
  })
}

//查询车辆的走访次数
export const companyCountRequestPath = '/api/v2/company/record/count';
export const companyCountRequest = (params: any) => {
  return request.get(companyCountRequestPath, {
    params
  })
}

interface UpsertColorDefineParams {
  id?: number;
  groupId: number;
  setting: string;
}
// 车辆诊断着色配置
export const upsertColorDefineRequest = (data: UpsertColorDefineParams) => {
  return request.post('/api/v2/color/upsert', data);
};

// 获取车辆诊断着色配置
export const getColorDefineRequest = (groupId: number) => {
  return request.get(`/api/v2/color/detail/${groupId}`);
};