/**
 * 部标机设备报警
 */
import React, { useState } from 'react';
import { Form, Cascader, Button, message } from 'antd';
import dayjs from 'dayjs';
import { jtDeviceAlarmListRequest } from '@/service/jtDeviceAlarm';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { jtDeviceAlarmTypeData } from '@/constants/jtDeviceAlarm';
import { jtDeviceAlarmColumns } from '@/columns/jtDeviceAlarm';
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const JtDeviceAlarm = () => {
  const [selectCarIds, setSelectCarIds] = useState<Array<number>>([]); // 选择的车辆id
  const [hasSearched, setHasSearched] = useState<boolean>(false); // 是否搜索过
  const [searchParams, setSearchParams] = useState({
    start: `${dayjs().format('YYYY-MM-DD')} 00:00:00`,
    end: `${dayjs().format('YYYY-MM-DD')} 23:59:59`,
    alarmTypes: jtDeviceAlarmTypeData
      .map((item: any) =>
        item.children.map((item1: any) => [item.value, item1.value])
      )
      .flat(1)
  }); // 搜索相关的参数
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: jtDeviceAlarmListRequest,
    initRequest: false,
    initPageSize: 90,
    searchParams
  });

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    if (selectCarIds.length === 0) {
      message.warning('请选择车辆');
      return;
    }
    const newParams = { ...params };
    delete newParams.time;
    // 将级联选择器的values处理成接口参数形式
    newParams.alarmTypes = params.alarmTypes.map(
      (item: any) => item[item.length - 1]
    );
    newParams.start = formatTime(params.time[0]);
    newParams.end = formatTime(params.time[1]);
    newParams.carIds = selectCarIds;
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  };

  // 搜索
  const onSearch = (values: any) => {
    const newSearchParams: any = operateSearchParams(values);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      setHasSearched(true);
    }
  };

  // 左侧车辆选择
  const treeCheck = (carIds: Array<number>) => {
    setSelectCarIds(carIds);
  };

  return (
    <div className={styles.spaceBetweenPageContentBox}>
      <div className={styles.carTree}>
        <CarTree
          checkable
          treeCheck={treeCheck}
          defaultFilterKey="hasJtDevice"
        />
      </div>
      <div className={styles.contentBox}>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSearch}
          form={form}
        >
          <Form.Item
            label="时间范围"
            name="time"
            initialValue={[dayjs(searchParams.start), dayjs(searchParams.end)]}
            rules={[{ required: true, message: '请选择时间范围' }]}
          >
            <RangePicker
              showTime
              allowClear={false}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            label="报警类型"
            name="alarmTypes"
            initialValue={searchParams.alarmTypes}
            // rules={[{ required: true, message: '请选择报警类型' }]}
          >
            <Cascader
              multiple
              options={jtDeviceAlarmTypeData}
              maxTagCount="responsive"
              allowClear
              showCheckedStrategy={Cascader.SHOW_CHILD}
              showSearch
            />
          </Form.Item>
          <Form.Item>
            <div className={styles.searchButtonBox}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </div>
          </Form.Item>
        </Form>
        {hasSearched ? (
          <div className={styles.tableBox}>
            <CommonTable
              columnFilter={false}
              rowKey="id"
              columns={jtDeviceAlarmColumns}
              dataSource={data}
              pagination={{
                total: totalCount,
                current: page,
                pageSize: limit,
                onChange: onPageChange
              }}
              bordered
              loading={loading}
            />
          </div>
        ) : (
          <div className={styles.tipBox}>请在左侧选择车辆后点击“查询”按钮</div>
        )}
      </div>
    </div>
  );
};

export default JtDeviceAlarm;
