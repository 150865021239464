/**
 * 全检任务(添加或编辑)
 */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Input, message, Select } from 'antd';
import dayjs from 'dayjs';
import DatePicker from '@/components/datePicker';
import { deviceCategoryData, DeviceCategoryEnum } from '@/constants/deviceManage';
import { DeviceInspectStatusEnum } from '@/constants/deviceInspect';
import { formatTime } from '@/utils/formatTime';
import { isHhAeb, isKlsAeb } from '@/utils/device';
import {
  upsertFullInspectionRequest,
  fullInspectionDetailRequest
} from '@/service/deviceInspect';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string | DeviceCategoryEnum;
  id?: number;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;
  const { reloadData, closeModal, type, id } = props;
  const [detail, setDetail] = useState<any>({}); // 编辑时的详情

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      setEditValues();
    }
  }, []);

  // 编辑时获取详情进行表单赋值
  const setEditValues = async () => {
    const res = await fullInspectionDetailRequest(id as number);
    const { code, data = {} } = res.data || {};
    if (code === 0) {
      setDetail(data);
      const params: any = { ...data };
      params.beginTime = dayjs(data?.beginTime);
      if (data?.endTime) {
        params.endTime = dayjs(data?.endTime);
      }
      form.setFieldsValue(params);
    }
  };

  // 提交
  const submit = async (values: any) => {
    const params = { ...values };
    params.beginTime = formatTime(values.beginTime);
    params.endTime = formatTime(values.endTime);
    if (type === 'edit') {
      params.id = id;
    }
    params.optUser = userDetail.nickname;
    const res = await upsertFullInspectionRequest(params);
    if (res.data?.code === 0) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  // 校验开始时间
  const validatorBeginDate = (rule: any, value: any) => {
    if (value && dayjs(value).isAfter(dayjs().subtract(30, 'minutes'))) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('请选择晚于现在的开始时间'));
    }
  };

  // 校验结束时间
  const validatorEndDate = (rule: any, value: any) => {
    const { getFieldValue } = form;
    const beginTime = getFieldValue('beginTime');
    if (value) {
      if (beginTime) {
        if (dayjs(value).isAfter(dayjs(beginTime))) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('结束时间需要晚于开始时间'));
        }
      } else {
        if (dayjs(value).isAfter(dayjs().subtract(30, 'minutes'))) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('请选择晚于现在的结束时间'));
        }
      }
    } else {
      return Promise.resolve();
    }
  };

  // 根据设备类型/任务状态显示版本输入框的placeholder
  const getVersionPlaceholder = (str: string) => {
    // 编辑昆仑山检测任务
    if (type === 'edit' && isKlsAeb(detail?.mcuType)) {
      const { stat } = detail;
      if (stat === DeviceInspectStatusEnum.notStarted) {
        return '任务开始后自动获取';
      }
      if (stat === DeviceInspectStatusEnum.process) {
        return '正在自动获取版本信息';
      }
      if (stat === DeviceInspectStatusEnum.closed) {
        return '未获取到版本信息';
      }
    } else {
      return `请输入${str}`;
    }
  };

  // 是否禁用开始时间选择器
  const disabledBeginDate =
    type === 'edit' &&
    [DeviceInspectStatusEnum.process, DeviceInspectStatusEnum.closed].includes(
      detail?.stat
    );
  // 是否禁用结束时间选择器
  const disabledEndDate =
    type === 'edit' && [DeviceInspectStatusEnum.closed].includes(detail?.stat);
  // 编辑昆仑山检测任务时禁用版本输入框
  const disabledVersion = type === 'edit' && isKlsAeb(detail?.mcuType);
  // 添加黄河检测任务或者编辑黄河检测任务时版本信息必填
  const requiredVersion =
    isHhAeb(DeviceCategoryEnum.huanghe) || isHhAeb(detail?.mcuType);

  return (
    <Modal
      title={type === 'edit' ? '编辑全检任务' : '创建全检任务'}
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={submit}
      >
        <Form.Item
          label="设备SN"
          name="sn"
          rules={[{ required: true, message: '请输入设备SN' }]}
        >
          <Input placeholder="请输入设备SN" />
        </Form.Item>
        <Form.Item
          label="控制盒类型"
          name="mcuType"
          rules={[{ required: true, message: '请选择控制盒类型' }]}
          initialValue={type}
        >
          <Select
            placeholder="请选择控制盒类型"
            options={deviceCategoryData}
            disabled
          />
        </Form.Item>
        {/* 添加昆仑山检测任务时不显示版本输入框 */}
        {isKlsAeb(type) || (
          <Fragment>
            <Form.Item
              label="控制盒版本"
              name="mcuVersion"
              // 黄河设备的控制盒版本必填
              rules={[
                { required: requiredVersion, message: '请输入控制盒版本' }
              ]}
            >
              {/* 昆仑山设备编辑时不可修改 */}
              <Input
                placeholder={getVersionPlaceholder('控制盒版本')}
                disabled={disabledVersion}
              />
            </Form.Item>
            <Form.Item
              label="双目相机版本"
              name="cameraVersion"
              rules={[
                { required: requiredVersion, message: '请输入双目相机版本' }
              ]}
            >
              <Input
                placeholder={getVersionPlaceholder('双目相机版本')}
                disabled={disabledVersion}
              />
            </Form.Item>
            <Form.Item
              label="小屏幕版本"
              name="screenVersion"
              rules={[
                { required: requiredVersion, message: '请输入小屏幕版本' }
              ]}
            >
              <Input
                placeholder={getVersionPlaceholder('小屏幕版本')}
                disabled={disabledVersion}
              />
            </Form.Item>
          </Fragment>
        )}
        {/* 进行中、已完成的任务无法修改开始时间 */}
        <Form.Item
          label="监测开始时间"
          name="beginTime"
          rules={[
            { required: true, message: '请选择监测开始时间' },
            {
              validator: disabledBeginDate
                ? () => Promise.resolve()
                : (rule: any, value: any) => validatorBeginDate(rule, value)
            }
          ]}
          validateFirst
        >
          <DatePicker
            showTime
            placeholder="请选择监测开始时间"
            style={{ width: '100%' }}
            disabled={disabledBeginDate}
          />
        </Form.Item>
        {/* 已完成的任务无法修改结束时间 */}
        <Form.Item
          label="监测结束时间"
          name="endTime"
          rules={[
            {
              validator: disabledEndDate
                ? () => Promise.resolve()
                : (rule: any, value: any) => validatorEndDate(rule, value)
            }
          ]}
        >
          <DatePicker
            showTime
            placeholder="请选择监测结束时间"
            style={{ width: '100%' }}
            disabled={disabledEndDate}
          />
        </Form.Item>
        <Form.Item label="硬件版本信息" name="info">
          <Input.TextArea placeholder="请输入硬件版本信息" />
        </Form.Item>
        <Form.Item label="备注" name="ps">
          <Input.TextArea placeholder="请输入备注" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
