/**
 * 接口请求业务错误相关code码
 */

export enum RequestErrorCode {
  deviceOffline = 6001, // 指定的设备未登录或不在线
  simRepeatDevice = 6501, // 车辆操作时SIM卡存在重复设备且设备未绑定车辆
  simRepeatCar = 6502, // 车辆操作时SIM卡存在重复设备且设备已绑定车辆
}

// 不需要通用请求方法提示信息的错误code
export const noMsgErrorCode = [
  RequestErrorCode.simRepeatDevice,
  RequestErrorCode.simRepeatCar,
]