/**
 * 设备管理
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Input, Button, message, Popconfirm, Image, Select } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { useTable } from '@/utils/useTable';
import { deviceListRequest, deviceDeleteRequest} from '@/service/equipmentManage'
import OperateModal from './components/operateModal';
import styles from '@/styles/pageContent.module.scss';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import dayjs from 'dayjs';
import DeviceDetail from './components/deviceDetail';

const AdasEquipmentManage = () => {
  const [searchParams, setSearchParams] = useState({}); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作相关的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: deviceListRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
  });

  console.log('data设备管理',data);
  
  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    setSearchParams(newSearchParams);
  };
  
  //删除
  const deleteUser = async(id: number) => {
    const res = await deviceDeleteRequest(id)
    if (res.data?.code === 0) {
      message.success('删除成功');
      getData();
    }
  }
  
  // 表格列显示
  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 60,
      ellipsis: { showTitle: false },
    },
    {
      title: '设备sn',
      dataIndex: 'sn',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: '设备关联sim卡编号',
      dataIndex: 'sim',
      width: 80,
      ellipsis: { showTitle: false },
    },
    {
      title: '相机sn',
      dataIndex: 'cameraSn',
      width: 150,
      ellipsis: { showTitle: false },
    },  
    // {
    //   title: '类型',
    //   dataIndex: 'deviceType',
    //   width: 150,
    //   ellipsis: { showTitle: false },
    // },
    {
      title: '关联车辆',
      dataIndex: 'plateNumber',
      width: 80,
      ellipsis: { showTitle: false },
    },

    {
      title: '参数信息',
      dataIndex: 'paramInfo',
      width: 150,
      ellipsis: { showTitle: false },
    },
    {
      title: '在线时间',
      dataIndex: 'onlineAt',
      width: 150,
      ellipsis: { showTitle: false },
      render: (value: string) => value ? <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD')} /> : ''
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 150,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType='/car-admin/equipment-manage/edit'
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item })}
            >
              编辑
            </PermissionButton>  
            <Button type="link" onClick={() => setOperateInfo({ type: 'detail', data: item })}>详情</Button>
            <Popconfirm
              title="您确定要删除该数据吗?"
              onConfirm={() => deleteUser(item.id)}
            >
              <PermissionButton operateType='/car-admin/equipment-manage/delete' type="link">删除</PermissionButton>
            </Popconfirm> 
          </div>
        )
      },
    },
  ]
  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item label="设备sn" name="sn">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="设备关联sim卡编号" name="sim">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="关联车辆" name="plateNumber">
            <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item label="相机sn" name="cameraSn">
        <Input placeholder='请输入' allowClear />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="&nbsp;">
            <div className={styles.searchBox}>
              <Button type="primary" htmlType="submit">搜索</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton
          operateType='/car-admin/equipment-manage/add'
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo?.type === 'detail' &&
        <DeviceDetail item={operateInfo.data} onClose={() => setOperateInfo({})} />
      }
      {/* 添加、编辑用户 */}
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateModal
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
          type={operateInfo.type}
          data={operateInfo.data}
        />
      }
    </Card>
  )
}

export default AdasEquipmentManage;
