/**
 * 报价配置
 */
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  Button,
  Card,
  Cascader,
  Collapse,
  Form,
  Input,
  Select,
  Table,
  message,
} from "antd";
import {
  offerPremiumListRequest,
  offerPremiumAdd,
  offerPremiumDelete,
  areaInsuranceListRequest,
} from "@/service/offerManage";
import styles from "./index.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import LoadingWrapper from "@/components/loadingWrapper";

const { Panel } = Collapse;
const offerManage = () => {
  const [allData, setAllData] = useState<any>(); //所有报价配置数据
  const [activeKey, setActiveKey] = useState<any>(["column"]); // 展开的面板
  const [area, setArea] = useState([]); // 区域
  const [itemColumnsValue, setItemColumnsValue] = useState<any>([]); //初始保司评价数据
  const [scoreColumnsValue, setScoreColumnsValue] = useState<any>([]); //初始商业分析数据
  const [riskColumnsValue, setRiskColumnsValue] = useState<any>([]); //初始出险次数数据
  const [deviceColumnsValue, setDeviceColumnsValue] = useState<any>([]); //初始出险次数数据
  const [taxColumnsValue, setTaxColumnsValue] = useState<any>([]); //初始基础税费数据
  const [newenergyColumnsValue, setNewenergyColumnsValue] = useState<any>([]); //初始新能源车分期支付配置数据
  const [unnewenergyColumnsValue, setUnnewenergyColumnsValue] = useState<any>(
    []
  ); //初始非新能源车分期支付配置数据
  const [vehicletypeColumnsValue, setVehicletypeColumnsValue] = useState<any>(
    []
  ); //初始车型配置
  const [loading, setLoading] = useState<boolean>(false); // 运维组数据的loading
  const [grades, setGrades] = useState<any>(); // 保司
  const [gradesFactors, setGradesFactors] = useState<any>(); // 折算系数
  const [scores, setScores] = useState<any>(); // 商业分析
  const [scoresFactor, setScoresFactors] = useState<any>(); // 商业分析折算系数
  const [riskFactors, setRiskFactors] = useState<any>(); // NCD系数折算系数
  const [deviceFactors, setDeviceFactors] = useState<any>(); // 设备保费配置保费
  const [deviceAreas, setDeviceAreas] = useState<any>(); // 设备保费配置区域
  const [taxFactors, setTaxFactors] = useState<any>(); // 基础税费配置保费
  const [taxAreas, setTaxAreas] = useState<any>(); // 基础税费配置区域
  const [newenergyItem, setNewenergyItem] = useState<any>(); // 新能源车分期支付配置指标
  const [newenergyFactors, setNewenergyFactors] = useState<any>(); // 新能源车分期支付配置折算系数
  const [newenergyAreas, setNewenergyAreas] = useState<any>(); // 新能源车分期支付配置配置区域
  const [unNewenergyItem, setUnNewenergyItem] = useState<any>(); // 非新能源车分期支付配置指标
  const [unNewenergyFactors, setUnNewenergyFactors] = useState<any>(); // 非新能源车分期支付配置折算系数
  const [unNewenergyAreas, setUnNewenergyAreas] = useState<any>(); // 非新能源车分期支付配置配置区域
  const [vehicletypeFactors, setVehicletypeFactors] = useState<any>(); // 非新能源车不分期支付配置折算系数(机动车)
  const [vehicletypeFactorsOne, setVehicletypeFactorsOne] = useState<any>(); // 非新能源车分期支付配置折算系数(机动车)
  const [vehicletypeFactorsTwo, setVehicletypeFactorsTwo] = useState<any>(); // 非新能源车分期支付配置折算系数(特征车)
  const [vehicletypeFactorsThree, setVehicletypeFactorsThree] = useState<any>(); // 非新能源车不分期支付配置折算系数(特征车)
  const [vehicletypeFactorsFour, setVehicletypeFactorsFour] = useState<any>(); // 非新能源车分期支付配置折算系数(新能源车)
  const [vehicletypeFactorsFive, setVehicletypeFactorsFive] = useState<any>(); // 非新能源车不分期支付配置折算系数(新能源车)
  const [vehicletypeAreas, setVehicletypeAreas] = useState<any>(); // 非新能源车分期支付配置配置区域
  const [vehicleTypeActiveKey, setVehicleTypeActiveKey] = useState<any[]>([]);
  const [risks, setRisks] = useState('')

  const [form] = Form.useForm();
  useEffect(() => {
    getOfferPremium();
    getareaData();
  }, []);

  // 获取区域数据
  const getareaData = async () => {
    const tree: any = [];
    const areaData = await areaInsuranceListRequest();
    const area = areaData.data.data;
    for (let i = 0; i < area.length; i++) {
      const item = area[i];
      if (item.parentCode === null) {
        tree.push({ ...item, children: [] });
      } else {
        const parent = tree.find((node: any) => node.code === item.parentCode);
        if (parent) {
          parent.children.push({ ...item });
        }
      }
      if (i === area.length - 1) {
        setArea(tree);
      }
    }
  };
  // 获取报价配置
  const getOfferPremium = async () => {
    setLoading(true);
    //车型配置先为空
    // setVehicletypeColumnsValue([]);
    try {
      const res = await offerPremiumListRequest();
      const resData = res.data?.data || {};
      setAllData(resData);
      //循环遍历resData
      const result = {};
      let GRADEData: any = []; //保司评级
      let SCOREData: any = []; //保司评分
      let RISKData: any = []; //出险次数
      let DEVICEData: any = []; //设备保费
      let TAXData: any = []; //基础税费
      let NEWENERGYEData: any = []; //新能源车分期支付
      let UNNEWENERGYData: any = []; //非新能源车分期支付
      let VEHICLETYPEData: any = []; //车型配置
      resData.map((item: any, index: any) => {
        if (item.type === "GRADE") {
          GRADEData.push(item);
        } else if (item.type === "SCORE") {
          SCOREData.push(item);
        } else if (item.type === "RISK" ) {
          if(!item.item) {
            RISKData.unshift(item)
          } else {
            RISKData.push(item);
          }
          
        } else if (item.type === "DEVICE") {
          DEVICEData.push(item);
        } else if (item.type === "TAX") {
          TAXData.push(item);
        } else if (item.type === "NEWENERGY") {
          NEWENERGYEData.push(item);
        } else if (item.type === "UNNEWENERGY") {
          UNNEWENERGYData.push(item);
        } else if (item.type === "VEHICLETYPE") {
          VEHICLETYPEData.push(item);
        }

        if (index == resData.length - 1) {
          setItemColumnsValue(GRADEData);
          setScoreColumnsValue(SCOREData);
          setRiskColumnsValue(RISKData);
          setDeviceColumnsValue(DEVICEData);
          setTaxColumnsValue(TAXData);
          setNewenergyColumnsValue(NEWENERGYEData);
          setUnnewenergyColumnsValue(UNNEWENERGYData);
          //将数据按照车型分单独数组
          const aa = VEHICLETYPEData.reduce((acc: any, cur: any) => {
            const key = cur.item;
            if (!acc[key]) {
              acc[key] = [];
            }
            if (cur.area) {
              acc[key].push(cur);
            } else {
              acc[key].unshift(cur)
            }
            
            return acc;
          }, {});
          if (Object.values(aa).length > 0) {
            setVehicletypeColumnsValue(Object.values(aa));
          }
        }
      });
      setLoading(false);
    } catch (error) {}
  };

  //删除数据
  const deleteDataColumns = async (id: any) => {
    if (id) {
      const res = await offerPremiumDelete(id);
      if (res.data?.code === 0) {
        message.success("删除成功");
        getOfferPremium();
      }
    } else {
      //刷新页面
      getOfferPremium();
    }
  };

  //编辑折算系数
  const changeFactor = (e: any, key: any) => {
    if (key == "grade") {
      setGradesFactors(e);
    } else if (key == "score") {
      setScoresFactors(e);
    } else if (key == "risk") {
      setRiskFactors(e);
    } else if (key == "device") {
      setDeviceFactors(e);
    } else if (key == "tax") {
      setTaxFactors(e);
    } else if (key == "newenergy") {
      setNewenergyFactors(e);
    } else if (key == "unnewenergy") {
      setUnNewenergyFactors(e);
    } else if (key == "vehicletype") {
      setVehicletypeFactors(e);
    } else if (key == "vehicletypeOne") {
      setVehicletypeFactorsOne(e);
    } else if (key == "vehicletypeTwo") {
      setVehicletypeFactorsTwo(e);
    } else if (key == "vehicletypeThree") {
      setVehicletypeFactorsThree(e);
    } else if (key == "vehicletypeFour") {
      setVehicletypeFactorsFour(e);
    } else if (key == "vehicletypeFive") {
      setVehicletypeFactorsFive(e);
    }
  };
  //编辑区域
  const changeAreas = (e: any, key: any) => {
    if (key == "device") {
      const a = e;
      const b = a.join(",");
      setDeviceAreas(b);
    } else if (key == "tax") {
      const a = e;
      const b = a.join(",");
      setTaxAreas(b);
    } else if (key == "newenergy") {
      const a = e;
      const b = a.join(",");
      setNewenergyAreas(b);
    } else if (key == "unnewenergy") {
      const a = e;
      const b = a.join(",");
      setUnNewenergyAreas(b);
    } else if (key == "vehicletype") {
      const a = e;
      const b = a.join(",");
      setVehicletypeAreas(b);
    }
  };
  //保存按钮
  const saveData = async (item: any, key: any) => {
    if (key == "grade") {
      if (item?.type == null) {
        const params = {
          item: grades, //指标名称
          type: "GRADE", //指标类型
          factor: gradesFactors, //折算系数
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
        const params = { ...item };
        params.item = grades;
        params.factor = gradesFactors;
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      }
    } else if (key == "score") {
      if (item?.type == null) {
        const params = {
          item: scores, //指标名称
          type: "SCORE", //指标类型
          factor: scoresFactor, //折算系数
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
        const params = { ...item };
        params.item = scores;
        params.factor = scoresFactor;
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      }
    } else if (key == "risk") {
      if (item?.type == null) {
        const params = {
          item: risks, //指标名称
          type: "RISK", //指标类型
          factor: riskFactors, //折算系数
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
      const params = { ...item };
      params.factor = riskFactors;
      params.item = risks
      const res = await offerPremiumAdd(params);
      message.success("保存成功");
      getOfferPremium();
    }
    } else if (key == "device") {
      if (item?.factor == null) {
        const params = {
          factor: deviceFactors, //指标名称
          type: "DEVICE", //指标类型
          item: "设备保费", //指标名称
          area: deviceAreas, //区域
          areas: null,
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
        const params = { ...item };
        params.factor = deviceFactors;
        params.area = deviceAreas;
        params.areas = null;
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      }
    } else if (key == "tax") {
      if (item?.factor == null) {
        const params = {
          factor: taxFactors, //指标名称
          type: "TAX", //指标类型
          item: "税点",
          area: taxAreas, //区域
          areas: null,
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
        const params = { ...item };
        params.factor = taxFactors;
        params.area = taxAreas;
        params.areas = null;
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      }
    } else if (key == "newenergy") {
      if (item?.item == null) {
        const params = {
          item: newenergyItem, //指标名称
          area: newenergyAreas, //区域
          areas: null,
          factor: newenergyFactors, //指标名称
          type: "NEWENERGY", //指标类型
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
        const params = { ...item };
        params.item = newenergyItem;
        params.factor = newenergyFactors;
        params.area = newenergyAreas;
        params.areas = null;
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      }
    } else if (key == "unnewenergy") {
      if (item?.item == null) {
        const params = {
          item: unNewenergyItem, //指标名称
          area: unNewenergyAreas, //区域
          areas: null,
          factor: unNewenergyFactors, //指标名称
          type: "UNNEWENERGY", //指标类型
        };
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      } else {
        const params = { ...item };
        params.item = unNewenergyItem;
        params.factor = unNewenergyFactors;
        params.area = unNewenergyAreas;
        params.areas = null;
        const res = await offerPremiumAdd(params);
        message.success("保存成功");
        getOfferPremium();
      }
    } else if (key == "vehicletype") {
      const params = { ...item };
      params.factor1 = vehicletypeFactorsOne;
      params.factor2 = vehicletypeFactorsTwo;
      params.factor3 = vehicletypeFactorsThree;
      params.factor4 = vehicletypeFactorsFour;
      params.factor5 = vehicletypeFactorsFive;
      params.factor = vehicletypeFactors;
      params.area = vehicletypeAreas;
      params.areas = null;
      const res = await offerPremiumAdd(params);
      message.success("保存成功");
      getOfferPremium();
    }
  };

  //保司评价
  const changeItem = async (e: any, item: any) => {
    setGrades(e);
  };
  //新增保司评价
  const addItemColumns = () => {
    const aaa = itemColumnsValue;
    setItemColumnsValue([ {}, ...aaa,]);
  };
  //保司评价折算配置表格列
  const itemColumns = [
    {
      title: "指标",
      dataIndex: "item",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeItem(e.target.value, item)}
        />
      ),
    },
    {
      title: "折算系数",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "grade")}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "grade")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  //商业分析
  const changeScore = async (e: any, item: any) => {
    setScores(e);
  };
  //新增商业分析
  const addScoreColumns = () => {
    const aaa = scoreColumnsValue;
    setScoreColumnsValue([ {}, ...aaa,]);
  };
  //商业分析配置表格列
  const scoreColumns = [
    {
      title: "商业分析",
      dataIndex: "item",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeScore(e.target.value, item)}
        />
      ),
    },
    {
      title: "折算系数",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "score")}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "score")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  const changeRisk = (e:any, item:any) => {
    setRisks(e)
  }

  //新增NCD系数折算配置
  const addRiskColumns = async () => {
    const params = {
      item: '', //指标名称
      type: "RISK", //指标类型
    };
    const res = await offerPremiumAdd(params);
    getOfferPremium();
  };
  //NCD系数折算配置表格列
  const riskColumns = [
    {
      title: "NCD系数",
      dataIndex: "item",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeRisk(e.target.value, item)}
        />
      ),
    },
    {
      title: "折算系数",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "risk")}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "risk")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  //新增设备保费指标
  const addDeviceColumns = () => {
    const aaa = deviceColumnsValue;
    setDeviceColumnsValue([{}, ...aaa, ]);
  };
  //设备保费折算配置表格列
  const deviceColumns = [
    {
      title: "保费(元)",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "device")}
        />
      ),
    },
    {
      title: "区域",
      dataIndex: "areas",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Cascader
          fieldNames={{ label: "name", value: "code" }}
          placeholder="请选择区域"
          defaultValue={value ? value : ""}
          onChange={(e) => changeAreas(e, "device")}
          // multiple
          allowClear
          showArrow
          showSearch
          options={area}
          changeOnSelect
          // showCheckedStrategy={SHOW_CHILD}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "device")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  //新增基础税费指标
  const addTaxColumns = (index: any) => {
    const aaa = taxColumnsValue;
    setTaxColumnsValue([ {}, ...aaa,]);
  };
  //基础税费配置表格列
  const taxColumns = [
    {
      title: "税点",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "tax")}
        />
      ),
    },
    {
      title: "区域",
      dataIndex: "areas",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Cascader
          fieldNames={{ label: "name", value: "code" }}
          placeholder="请选择区域"
          defaultValue={value ? value : ""}
          onChange={(e) => changeAreas(e, "tax")}
          // multiple
          allowClear
          showArrow
          showSearch
          options={area}
          changeOnSelect
          // showCheckedStrategy={SHOW_CHILD}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "tax")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  //新能源车分期支付
  const changeNewenergy = async (e: any, item: any) => {
    setNewenergyItem(e);
  };
  //编辑区域
  const changeNewenergyArea = async (e: any, item: any) => {
    //循环遍历e
    const aa = e;
    const params = { ...item };
    params.area = aa.join(",");
    params.areas = null;
    const res = await offerPremiumAdd(params);
  };
  //新增新能源车分期支付
  const addNewenergyColumns = (index: any) => {
    const aaa = newenergyColumnsValue;
    setNewenergyColumnsValue([{}, ...aaa, ]);
  };
  //新能源车配置表格列
  const newenergyColumns = [
    {
      title: "指标",
      dataIndex: "item",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeNewenergy(e.target.value, item)}
        />
      ),
    },
    {
      title: "区域",
      dataIndex: "areas",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Cascader
          fieldNames={{ label: "name", value: "code" }}
          placeholder="请选择区域"
          defaultValue={value ? value : ""}
          onChange={(e) => changeAreas(e, "newenergy")}
          // multiple
          allowClear
          showArrow
          showSearch
          options={area}
          changeOnSelect
        />
      ),
    },
    {
      title: "折算系数",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "newenergy")}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "newenergy")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  //非新能源车分期支付
  const changeUnnewenergy = async (e: any, item: any) => {
    setUnNewenergyItem(e);
  };
  //编辑非新能源车分期支付折算系数
  const changeUnnewenergyFactor = async (e: any, item: any) => {
    const params = { ...item };
    params.factor = e;
    const res = await offerPremiumAdd(params);
  };
  //新增非新能源车分期支付
  const addUnnewenergyColumns = (index: any) => {
    const aaa = unnewenergyColumnsValue;
    setUnnewenergyColumnsValue([{}, ...aaa, ]);
  };
  //非新能源车配置表格列
  const unnewenergyColumns = [
    {
      title: "指标",
      dataIndex: "item",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeUnnewenergy(e.target.value, item)}
        />
      ),
    },
    {
      title: "区域",
      dataIndex: "areas",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Cascader
          fieldNames={{ label: "name", value: "code" }}
          placeholder="请选择区域"
          defaultValue={value ? value : ""}
          onChange={(e) => changeAreas(e, "unnewenergy")}
          // multiple
          allowClear
          showArrow
          showSearch
          options={area}
          changeOnSelect
        />
      ),
    },
    {
      title: "折算系数",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "unnewenergy")}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "unnewenergy")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  //车型配置
  const changeVehicletype = (e: any, item: any) => {
    item.map(async (item: any) => {
      // 新增
      if (item.item == null) {
        const params = {
          item: e, //指标名称
          type: "VEHICLETYPE", //指标类型
        };
        setVehicleTypeActiveKey([...vehicleTypeActiveKey, e]);
        const res = await offerPremiumAdd(params);
        // getOfferPremium();
        //编辑
      } else {
        const params = { ...item };
        params.item = e;
        setVehicleTypeActiveKey([...vehicleTypeActiveKey, e]);
        const res = await offerPremiumAdd(params);
      }
    });
  };
  //新增车型
  const addVehicletypeColumns = () => {
    const aaa = vehicletypeColumnsValue;
    //给定一个[{item:null 方便下面遍历出来数据}]
    setVehicletypeColumnsValue([...aaa, [{ item: null }]]);
    setVehicleTypeActiveKey([...vehicleTypeActiveKey, "null"]);
    setTimeout(() => {
      $(".ant-tabs-tabpane.ant-tabs-tabpane-active")[0].scrollTop = $(
        ".ant-tabs-tabpane.ant-tabs-tabpane-active"
      )[0].scrollHeight - $(".ant-tabs-tabpane.ant-tabs-tabpane-active")[0].offsetHeight;
    }, 10);
  };

  useLayoutEffect(() => {}, []);
  //新增车型区域配置
  const addVehicletypeColumnsItem = async (item: any) => {
    const params = {
      item: item[0]?.item, //指标名称
      type: "VEHICLETYPE", //指标类型
    };
    const res = await offerPremiumAdd(params);
    getOfferPremium();
  };
  //车型配置配置表格列
  const vehicletypeColumns = [
    {
      title: "区域",
      dataIndex: "areas",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Cascader
          fieldNames={{ label: "name", value: "code" }}
          placeholder="请选择区域"
          defaultValue={value ? value : ""}
          onChange={(e) => changeAreas(e, "vehicletype")}
          // multiple
          allowClear
          showArrow
          showSearch
          options={area}
          changeOnSelect
          // showCheckedStrategy={SHOW_CHILD}
        />
      ),
    },
    {
      title: "机动车（不含车损）",
      dataIndex: "factor",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "vehicletype")}
        />
      ),
    },
    {
      title: "机动车（含车损）",
      dataIndex: "factor1",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "vehicletypeOne")}
        />
      ),
    },
    {
      title: "特种车（不含车损）",
      dataIndex: "factor2",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "vehicletypeTwo")}
        />
      ),
    },
    {
      title: "特种车（含车损）",
      dataIndex: "factor3",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "vehicletypeThree")}
        />
      ),
    },
    {
      title: "新能源车（不含车损）",
      dataIndex: "factor4",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "vehicletypeFour")}
        />
      ),
    },
    {
      title: "新能源车（含车损）",
      dataIndex: "factor5",
      width: 120,
      render: (value: any, item: any, index: any) => (
        <Input
          defaultValue={value}
          onBlur={(e) => changeFactor(e.target.value, "vehicletypeFive")}
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 120,
      render: (value: any, item: any, index: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button
              type="primary"
              danger
              onClick={() => deleteDataColumns(item.id)}
            >
              删除
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10 }}
              onClick={() => saveData(item, "vehicletype")}
            >
              保存
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.reportBox}>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        scrollToFirstError
        initialValues={{ managementCode: [{}], adviseColumnsCode: [{}] }}
      >
        <Collapse
          activeKey={activeKey}
          onChange={(keys: any) => setActiveKey(keys)}
        >
          {/* <LoadingWrapper spinning={loading} autoHeight> */}
          <Panel header="通用配置" key="column">
            <LoadingWrapper spinning={loading} autoHeight>
              <Collapse>
                <Panel key="item" header="保司评价折算配置">
                  <Form.Item label="保司评价折算配置" name="item">
                    <Button type="primary" onClick={addItemColumns}>
                      新增
                    </Button>
                    <Table
                      columns={itemColumns}
                      dataSource={itemColumnsValue}
                      rowKey="item"
                    />
                  </Form.Item>
                </Panel>
                <Panel key="score" header="商业分析折算配置">
                  <Form.Item label="商业分析折算配置" name="score">
                    <Button type="primary" onClick={addScoreColumns}>
                      新增
                    </Button>
                    <Table
                      columns={scoreColumns}
                      dataSource={scoreColumnsValue}
                      rowKey="id"
                    />
                  </Form.Item>
                </Panel>
                <Panel key="risk" header="NCD系数折算配置">
                  <Form.Item label="NCD系数折算配置" name="risk">
                    <Button type="primary" onClick={addRiskColumns}>
                      新增
                    </Button>
                    <Table
                      columns={riskColumns}
                      dataSource={riskColumnsValue}
                      rowKey="id"
                    />
                  </Form.Item>
                </Panel>
                <Panel key="device" header="设备保费配置">
                  <Form.Item label="设备保费配置" name="device">
                    <Button type="primary" onClick={addDeviceColumns}>
                      新增
                    </Button>
                    <Table
                      columns={deviceColumns}
                      dataSource={deviceColumnsValue}
                      rowKey="id"
                    />
                  </Form.Item>
                </Panel>
                <Panel key="tax" header="基础税费配置">
                  <Form.Item label="基础税费配置" name="tax">
                    <Button type="primary" onClick={addTaxColumns}>
                      新增
                    </Button>
                    <Table
                      columns={taxColumns}
                      dataSource={taxColumnsValue}
                      rowKey="id"
                    />
                  </Form.Item>
                </Panel>
                <Panel key="newenergy" header="新能源车分期支付配置">
                  <Form.Item label="新能源车分期支付配置" name="newenergy">
                    <Button type="primary" onClick={addNewenergyColumns}>
                      新增
                    </Button>
                    <Table
                      columns={newenergyColumns}
                      dataSource={newenergyColumnsValue}
                      rowKey="id"
                    />
                  </Form.Item>
                </Panel>
                <Panel key="unnewenergy" header="非新能源车分期支付配置">
                  <Form.Item label="非新能源车分期支付配置" name="unnewenergy">
                    <Button type="primary" onClick={addUnnewenergyColumns}>
                      新增
                    </Button>
                    <Table
                      columns={unnewenergyColumns}
                      dataSource={unnewenergyColumnsValue}
                      rowKey="id"
                    />
                  </Form.Item>
                </Panel>
              </Collapse>
            </LoadingWrapper>
          </Panel>
          <Panel header="车型配置" key={"vehicletype"}>
            <LoadingWrapper spinning={loading} autoHeight>
              <Form.Item label="" name="vehicletype">
                <Button type="primary" onClick={addVehicletypeColumns}>
                  新增
                </Button>
                <Collapse
                  activeKey={vehicleTypeActiveKey}
                  onChange={(keys: any) => setVehicleTypeActiveKey(keys)}
                >
                  {vehicletypeColumnsValue?.map((item: any, index: any) => (
                    <Panel key={item[0]?.item} header={item[0]?.item}>
                      <span style={{ fontWeight: 800 }}>指标:</span>
                      <Input
                        style={{ width: 300, margin: "20px" }}
                        defaultValue={item[0]?.item}
                        onBlur={(e) => changeVehicletype(e.target.value, item)}
                      />
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        onClick={() => addVehicletypeColumnsItem(item)}
                      ></Button>
                      <Table
                        columns={vehicletypeColumns}
                        dataSource={item}
                        pagination={false}
                        rowKey="id"
                      />
                    </Panel>
                  ))}
                </Collapse>
                {/* <Table  columns={vehicletypeColumns} dataSource={vehicletypeColumnsValue}  pagination={false} /> */}
              </Form.Item>
            </LoadingWrapper>
          </Panel>
          {/* </LoadingWrapper> */}
        </Collapse>
      </Form>
    </Card>
  );
};

export default offerManage;
