/**
 * 支付记录界面相关请求
 */
import request from "@/utils/request";

export interface VideoInvestParams {
    userId?: number;
    duration?:number;
    payment?:any  //1 支付宝   2 微信
    orderNo?:any
}

export const  videoInvestPath = '/api/v2/videoInvest/order/product';

// 用户视频预下单
export const videoRequest = (params: VideoInvestParams) => {
    return request.post(videoInvestPath, params)
}


// 用户发起请求支付
export const videoInvestRequest = (params: VideoInvestParams) => {
    return request.post('/api/v2/videoInvest/order/invest', params)
}

// 用户支付状态查询
export const videoInvestQueryexportRequest = (params: VideoInvestParams) => {
    return request.post('/api/v2/videoInvest/order/query', params)
}
