/**
 * 编辑车辆诊断 设备状态（aeb \adas）
 */
import React, {useState, useEffect} from "react";
import {Modal, Form, message, Input, Radio} from "antd";
import {updateServiceStateRequest} from "@/service/carManage";

interface AssignModalProps {
  data: any; // 工单信息

  type: any; // 操作类型(1 : aeb/2：adas)
  onClose: () => void; // 关闭弹窗的方法
  onOk: () => void; // 确认弹窗后触发的方法
}

const EditServiceState = (props: AssignModalProps) => {
  const {data, onClose, type, onOk} = props;
  const [loading, setLoading] = useState<boolean>(false); // 提交接口是否请求中
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [changeServiceState, setChangeServiceState] = useState<any>();

  useEffect(() => {
    const oldValue = {
      serviceStates: type === 1 ? data.deviceState : data.adasDeviceState,
    };
    form.setFieldsValue(oldValue);
  }, []);

  // 编辑请求
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false);
      try {
        const params: any = {};
        params.id = data.carId;
        params.updateDeviceType = [type];
        params.deviceState = changeServiceState;
        const res = await updateServiceStateRequest(params);
        setLoading(true);
        if (res.status == 200) {
          setSubmitNoRepetition(true);
        }
        setLoading(false);
        if (res.data?.code === 0) {
          message.success("修改成功");
          onClose();
        }
      } catch (error) {
        setSubmitNoRepetition(true);
        setLoading(false);
      }
    }
  };

  const onChange = (e: any) => {
    setChangeServiceState(e.target.value);
  }

  return (
    <Modal
      title={"编辑设备状态"}
      visible
      centered
      onCancel={onClose}
      onOk={() => {
        form.submit()
        onOk && onOk()
      }}
      okButtonProps={{loading}}
      maskClosable={false}
      width={580}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item name="serviceStates" label="设备状态">
          <Radio.Group style={{width: '100%'}} onChange={onChange}>
            <Radio value={'正常'}>正常</Radio>
            <Radio value={'停用'}>停用</Radio>
            <Radio value={'故障'}>故障</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditServiceState;
