/**
 * 设备管理
 */
import request from "@/utils/request";

export interface adasRequestParams {
    page: number; 
    limit: number;
    sn?:string;
    sim?:string;
    plateNumber?:string;
    cameraSn?:string
}

export const ListRequestPath = '/api/v2/device/list';

// 获取设备列表数据
export const deviceListRequest = (params: adasRequestParams) => {
    return request.get(ListRequestPath, {
        params,
    })
}

// 设备添加
export const deviceAddRequest = (data: any) => {
    return request.post('/api/v2/device/upsert', data)
}

//设备删除
export const deviceDeleteRequest = (id: number) => {
    return request.delete(`/api/v2/device/${id}`)
}

//设备详情查询
export const deviceDetailRequest = (id: number) => {
    return request.get(`/api/v2/device/detail/${id}`)
}
