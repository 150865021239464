/**
 * 操作支付记录
 */

import React, { useEffect, useState } from "react";
import { Input, message, Modal, Form, Button, Popconfirm,Table, Select, DatePicker } from 'antd';
import UploadFile from '@/components/uploadFile';
import CommonTable from "@/components/commonTable";
import { useTable } from "@/utils/useTable";
import { payRecordListRequest, upsertInsurePayRequest,deleteInsurePayRequest } from '@/service/underwritingRecord';
import dayjs from "dayjs";
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import { downloadFile } from '@/utils/download';
import PermissionButton from '@/components/permissionButton';
import styles from '@/styles/pageContent.module.scss';

interface DoucumentCommentDetailProps {
    reloadData: () => void;
    closeModal: () => void;
    type: string;
    item?: any;
}

const PayRecord = (props: DoucumentCommentDetailProps) => {
    const [form] = Form.useForm();
    const { type, item, closeModal, reloadData } = props;
    const [isModalOpen, setIsModalOpen] = useState(false); // 记录弹框是否开启
    const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传文件
    const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
    const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
    const [searchParams, setSearchParams] = useState({ feeId: item.id }); // 搜索相关的参数
    const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
        requestFunc: payRecordListRequest,
        initRequest: true,
        initPageSize: 40,
        searchParams
    });

    // 提交
    const submit = async (values: any) => {
        if (submitNoRepetition) {
            setSubmitNoRepetition(false)
            const params = { ...values };
            params.date = dayjs(values.date).format('YYYY-MM-DD');
            //分期Id
            params.feeId = item.id
            if (uploadSuccessList.length) {
                params.resources = uploadSuccessList;
            } else {
                params.resources = null;
            }
            const res = await upsertInsurePayRequest(params);
            if (res.data?.code === 0) {
                message.success(type === 'add' ? '添加成功' : '修改成功');
                closeModal();
                reloadData();
            }
            setIsModalOpen(false);
        }

    }

    // 删除支付记录
    const deletePayRecord = async (id: number) => {

            const res = await deleteInsurePayRequest(id);
            if (res.data?.code === 0) {
                message.success("删除成功");
                closeModal();
                reloadData();
            }

    }


    // PDF合同预览
    const onPdfPreview = (url: string, fileName: string) => {
        setPdfPreviewInfo({
            url,
            fileName,
        });
    }

    const columns = [
        {
            title: '服务单号',
            dataIndex: 'insuranceCode',
            width: 110,
        },
        {
            title: '支付金额',
            dataIndex: 'fee',
            width: 110,
        },
        {
            title: '支付日期',
            dataIndex: 'date',
            width: 100,
            ellipsis: { showTitle: false },
        },
        {
            title: '备注',
            dataIndex: 'remark',
            width: 100,
            ellipsis: { showTitle: false },
        },
        {
            title: '附件',
            dataIndex: 'resources',
            width: 200,
            ellipsis: { showTitle: false },
            render: (value: any) => (
                value?.map((item: any, index: any) => (
                    <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
                        <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
                        </div>
                        <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                type="text"
                                onClick={() => onPdfPreview(item.url, item.fileName)}
                            >
                                <EyeOutlined />
                            </Button>
                            <Button
                                type="link"
                                onClick={() => downloadFile(item.url, item.fileName)}
                                style={{ paddingLeft: 0 }}
                            >
                                <DownloadOutlined />
                            </Button>
                        </div>
                    </div>
                ))
            ),
        },
        {
            title: '操作人',
            dataIndex: 'opt',
            width: 110,
        },
        {
            title: '操作',
            dataIndex: 'operate',
            width: 200,
            fixed: 'right' as 'right',
            render: (value: any, item: any) => {
              return (
                <div className={styles.tableOperateColumn}>
                    <Popconfirm
                    title="您确定要删除该数据吗?"
                    onConfirm={() => deletePayRecord(item.id)}
                    >
                    <PermissionButton operateType='/car-admin/data-department/delete-item' type="link">删除</PermissionButton>

                    </Popconfirm>
                </div>
              )
            },
          },
    ]

    return (
        <Modal title={'编辑支付'}
            visible
            onCancel={closeModal}
            onOk={() => form.submit()}
            bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
            centered
            maskClosable={false}
            width={800}
            open={isModalOpen}
        >
            <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: totalCount,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                }}
                bordered
                loading={loading}
            />
            <Form
                form={form}
                autoComplete="off"
                onFinish={submit}
                layout="vertical"
                scrollToFirstError
            >
                <Form.Item label="支付日期" name="date" rules={[{ required: true, message: '请选择支付日期' }]}>
                    <DatePicker placeholder="请选择支付日期" style={{ width: '50%' }} />
                </Form.Item>
                <Form.Item label="支付金额" name="fee" rules={[{ required: true, message: '请输入支付金额' }]}>
                    <Input placeholder="请输入支付金额" style={{ width: '50%' }} />
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="备注"
                >
                    <Input.TextArea showCount placeholder="请输入备注" style={{ height: 100 , width: '50%'}} />
                </Form.Item>
                <Form.Item label="支付凭证" name="resources">
                    <UploadFile
                        accept="application/pdf"
                        uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
                        defaultUploadFiles={[]}
                        canUploadVideo
                        limitSize={500}
                        multiple
                    />
                </Form.Item>
            </Form>
            {pdfPreviewInfo && <PdfPreviewModal
                title={pdfPreviewInfo?.fileName}
                pdfUrl={pdfPreviewInfo?.url}
                closeModal={() => setPdfPreviewInfo(null)}
            />}
        </Modal>
    )
}

export default PayRecord;
