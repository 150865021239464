// extracted by mini-css-extract-plugin
export default {"onLineRecordBox":"onlineRecord_onLineRecordBox__cBFmQ","carTree":"onlineRecord_carTree__2PpR8","recordBox":"onlineRecord_recordBox__eidhs","tips":"onlineRecord_tips__tSnGC","month":"onlineRecord_month__y2uo8","monthItemBox":"onlineRecord_monthItemBox__skTtC","monthItem":"onlineRecord_monthItem__RRb07","title":"onlineRecord_title__B40W-","week":"onlineRecord_week__2GFCF","weekItem":"onlineRecord_weekItem__Q678s","day":"onlineRecord_day__9sA-K","dayItem":"onlineRecord_dayItem__5d5jP","dayItemOnline":"onlineRecord_dayItemOnline__JdVKN"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"onLineRecordBox\":\"onlineRecord_onLineRecordBox__cBFmQ\",\"carTree\":\"onlineRecord_carTree__2PpR8\",\"recordBox\":\"onlineRecord_recordBox__eidhs\",\"tips\":\"onlineRecord_tips__tSnGC\",\"month\":\"onlineRecord_month__y2uo8\",\"monthItemBox\":\"onlineRecord_monthItemBox__skTtC\",\"monthItem\":\"onlineRecord_monthItem__RRb07\",\"title\":\"onlineRecord_title__B40W-\",\"week\":\"onlineRecord_week__2GFCF\",\"weekItem\":\"onlineRecord_weekItem__Q678s\",\"day\":\"onlineRecord_day__9sA-K\",\"dayItem\":\"onlineRecord_dayItem__5d5jP\",\"dayItemOnline\":\"onlineRecord_dayItemOnline__JdVKN\"}";
        // 1726639067515
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  