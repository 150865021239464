/**
 * 软硬件信息弹窗
 */
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import LoadingWrapper from '@/components/loadingWrapper';
import KlsVersionInfo from '@/components/klsVersionInfo';
import { formatTime } from '@/utils/formatTime';
import { getWareInfoRequest } from '@/service/parameterConfiguration';
import { getJtKlsWareInfoRequest } from '@/service/jtKlsParameterConfiguration';

interface WareInfoModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}

const WareInfoModal = (props: WareInfoModalProps) => {
  const { data, closeModal } = props;
  const [initLoading, setInitLoading] = useState(false); // 获取配置接口的loding
  const [wareInfoData, setWareInfoData] = useState({}); // 软硬件版本信息
  const [offlineInfo, setOfflineInfo] = useState<any>(null); // 离线设备获取到设备参数时的相关信息

  useEffect(() => {
    getWareInfo();
  }, []);

  // 获取软硬件信息
  const getWareInfo = async () => {
    try {
      setInitLoading(true);
      // 根据是否为昆仑山3.0设备区分接口
      const { sn, isKlsV3 } = data;
      const requestFunc = isKlsV3
        ? getJtKlsWareInfoRequest
        : getWareInfoRequest;
      const res = await requestFunc(sn);
      setInitLoading(false);
      if (res.data?.code === 0) {
        const wareInfoData = res.data?.data || {};
        setWareInfoData(wareInfoData);
        // 设备不在线获取到设备信息时存储相关信息
        if (wareInfoData?.offline) {
          setOfflineInfo({ updatedAt: wareInfoData.updatedAt });
        } else {
          setOfflineInfo(null);
        }
      }
    } catch (error) {
      setWareInfoData({});
      setInitLoading(false);
    }
  };

  // 渲染弹窗的title
  const renderModalTitle = () => {
    const offlineStr = offlineInfo ? (
      <span className="dangerText">
        （离线数据，数据时间：
        {formatTime(offlineInfo?.updatedAt, 'YYYY-MM-DD HH:mm:ss')}）
      </span>
    ) : (
      ''
    );
    return <span>软硬件信息{offlineStr}</span>;
  };

  return (
    <Modal
      title={renderModalTitle()}
      open
      onCancel={closeModal}
      centered
      width="80%"
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          取消
        </Button>,
        <Button
          type="primary"
          key="read"
          onClick={getWareInfo}
          loading={initLoading}
        >
          读取
        </Button>
      ]}
      maskClosable={false}
    >
      <LoadingWrapper spinning={initLoading}>
        <KlsVersionInfo data={wareInfoData} />
      </LoadingWrapper>
    </Modal>
  );
};

export default WareInfoModal;
