/**
 * 操作工单
 */
import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Select, Input, message } from "antd";
import CarSelect from '@/components/carSelect';
import MapLocationModal from '@/components/mapLocationModal';
import Icon from "@/components/icon";
import { upsertWorkOrderRequest } from '@/service/workOrder';
import { tagListRequest } from '@/service/tagManage';
import { importAliMap } from '@/utils/map';
import { workOrderTypeData } from '@/constants/workOrder';
import { TagCategoryEnum } from '@/constants/tagManage';
import dayjs from "dayjs";
import { carListRequest } from '@/service/carManage';
import { deviceTypeData } from '@/constants/deviceManage';
import UploadImageVideo from "@/components/uploadImageVideo";
import { upsertOperationAssignOrder } from "@/service/dataDepartment";
import { upsertServiceTask } from "@/service/serviceTask";
import TextArea from "antd/lib/input/TextArea";
import QuickDateDayTomPicker from "@/components/quickDateDayTomPicker";

interface OperateModalProps {
  type: string; // 操作类型
  workOrderInfo?: any; // 工单信息
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 添加成功后重新请求工单的方法
  workId: (orderId:any,customerId:any,anyData:any) => void;
}

const { Search } = Input;
const OperateModal = (props: OperateModalProps) => {
  const { type, workOrderInfo, onClose, reloadData, workId } = props;
  const geocoderRef = useRef<any>(); // 地理编码工具实例
  const [loading, setLoading] = useState<boolean>(false); // 接口是否请求中
  const [tagData, setTagData] = useState<any>([]); // 工单相关的标签
  const [serviceProblemType, setServiceProblemType] = useState<any>([]); // 问题来源的标签
  const [operateInfo, setOperateInfo] = useState<any>({}); // 弹窗的信息
  const [lnglat, setLnglat] = useState<any>(null); // 选择地址的经纬度信息
  const [form] = Form.useForm();
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息
  const [carBusinessUnit, setCarBusinessUnit] = useState([]); // 获取业务渠道

  useEffect(() => {
    getBusinessUnitType()
    getServiceProblemType()
    getWorkOrderTags();
    importAliMap(initMap);
    if (type === 'edit') {
      setWorkOrderDetail();
    }
    if (type === 'servicrAdd') {
      setServicrAddWorkOrder()
    }
    if (type === 'servicrTaskAdd') {
      setServicrTaskAddWorkOrder()
    }
    if (type === 'carDiagnosisAdd') {
      setCarDiagnosisAddWorkOrder()
    }
    if (type === 'dataManageAdd') {
      setDataManageAddWorkOrder()
    }
    if (type === 'servicBatchDispatchOrder') {
      SetervicBatchDispatchOrder()
    }
  }, []);

   // 创建工单
   const getWorkOrderId =  (orderId: any, customerId: any, anyData: any) => {
    const params = anyData
    orderId.forEach(async (item:any) => {
      params.orderId= item.orderId
      params.taskState = 3
      const res = await upsertServiceTask(params);
    });
    reloadData();
  };

  // 初始化地图
  const initMap = () => {
    try {
      window.AMap.plugin(['AMap.Geocoder'], function() {
        geocoderRef.current = new window.AMap.Geocoder();
      });
    } catch (error) {
      message.error('地图初始化失败');
    }
  }

  // 获取问题来源
  const getServiceProblemType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.serviceProblemType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setServiceProblemType(tags);
  }

  // 获取工单标签
  const getWorkOrderTags = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.maintainType],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title, id, isDisabled } = item;
      tags.push({ label: title, value: id, disabled: isDisabled });
    });
    setTagData(tags);
  }

    // 批量派单创建工单赋值
  const SetervicBatchDispatchOrder = async() => {
    console.log(workOrderInfo,'workOrderInfo111');
     const res = await carListRequest({
      page: 1,
      limit: 100,
      plate_number: workOrderInfo[0]?.plateNumber,
    });
    const params:any = {};
    params.businessUnit = res.data.data[0]?.businessUnit

    params.tel = workOrderInfo[0]?.tel
    form.setFieldsValue(params);
  }

  // 编辑时赋值
  const setWorkOrderDetail = () => {
    const params = {...workOrderInfo};
    console.log(params);
    params.tag = (Object.values(workOrderInfo.tags || {})[0] as any || []).map(((item: any) => Number(item.tagId)));
    params.expire = dayjs(params.expire)
    form.setFieldsValue(params);
  }

  // 客服记录创建工单赋值
  const setServicrAddWorkOrder = () => {
    const params:any = {};
    params.tag = workOrderInfo.types
    params.plateNumber = workOrderInfo.plateNumber
    params.category = workOrderInfo.category
    form.setFieldsValue(params);
  }

  // 客服任务创建工单赋值
  const setServicrTaskAddWorkOrder = async () => {
    const res = await carListRequest({
      page: 1,
      limit: 100,
      plate_number: workOrderInfo.plateNumber,
    });
    const params:any = {};
    params.deviceType = res.data.data[0]?.device_type,
    params.businessUnit = res.data.data[0]?.businessUnit
    params.tel = workOrderInfo?.tel
    // params.district = workOrderInfo.district
    // params.expire = dayjs(workOrderInfo.deadWorkTime)
    if(workOrderInfo.type == '故障车辆' || workOrderInfo.type == '监测车辆' || workOrderInfo.type == '离线车辆' ){
     params.type = 1
    }
    if(workOrderInfo.type == '专项回访'){
      if(workOrderInfo.masterProblem == '事故车辆'){
        params.type = 3
      } else if (workOrderInfo.masterProblem == '新安装车辆'){
        params.type = 2
      }
    }
    params.category = workOrderInfo?.category
    params.tag = workOrderInfo?.types
    params.plateNumber = workOrderInfo?.plateNumber
    params.channel = workOrderInfo?.channel
    params.company = workOrderInfo?.company
    params.reason = workOrderInfo?.reason
    form.setFieldsValue(params);
  }

  // 车辆诊断创建工单
  const setCarDiagnosisAddWorkOrder = () => {
    const params = {
      deviceType:workOrderInfo.device_type,
      plateNumber:workOrderInfo.plate_number,
      channel: workOrderInfo.cate_path_name.split('/')[workOrderInfo.cate_path_name.split('/').length - 2],
      company: workOrderInfo.cate_path_name.split('/').pop(),
      businessUnit:workOrderInfo.businessUnit
    };
    form.setFieldsValue(params);
  }

  // 数据管理创建工单赋值
  const setDataManageAddWorkOrder = async () => {
    const res = await carListRequest({
      page: 1,
      limit: 100,
      plate_number: workOrderInfo.plateNumber,
    });
    const params:any = {};
    params.deviceType = res.data.data[0]?.device_type, //设备类型
    params.businessUnit = res.data.data[0]?.businessUnit//业务渠道
    params.plateNumber = workOrderInfo?.plateNumber
    params.channel = workOrderInfo?.channel//渠道
    params.company = workOrderInfo?.company
    params.tel = workOrderInfo?.tel1
    form.setFieldsValue(params);
  }

  // 确认地址的方法
  const confirmLocation = (lnglat: any, address: string) => {
    setLnglat(lnglat);
    form.setFieldsValue({
      district: address,
    });
  }

  // 提交工单
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      try {
        setLoading(true);
        if (type === 'servicBatchDispatchOrder') {
          let  num = -1
          const piliang = setInterval(async function(){
            num++
            if (num == workOrderInfo.length) {
              console.log(1111111111)
              reloadData();
              clearInterval(piliang)
            } else {
              const params = {...values};
              const ccc = num
              const resss = await carListRequest({
                page: 1,
                limit: 100,
                plate_number: workOrderInfo[num]?.plateNumber,
              });
              params.plateNumber = workOrderInfo[ccc]?.plateNumber;
              params.company = workOrderInfo[ccc]?.company,
              params.channel = workOrderInfo[ccc]?.channel,
              // params.businessUnit = workOrderInfo[ccc]?.businessUnit,
              // params.tel = workOrderInfo[ccc]?.tel,
              params.expire = dayjs(values.expire).format("YYYY-MM-DD") + ' 23:59:59';
              if (uploadSuccessList.length) {
                params.resources = uploadSuccessList;
              }
              if (lnglat) {
                params.lat = lnglat.lng;
                params.lon = lnglat.lat;
                const res = await upsertWorkOrderRequest(params);
                if (res.status == 200) {
                  setSubmitNoRepetition(true)
                }
                setLoading(false);
                if (res.data?.code === 0) {
                  message.success('添加成功');
                  onClose();
                }
              } else {
                geocoderRef.current.getLocation(values.district, async (status: any, result: any) => {
                  if (status === 'complete' && result.geocodes.length) {
                    const districtlnglat = result.geocodes[0].location;
                    params.lat = districtlnglat.lng;
                    params.lon = districtlnglat.lat;
                    console.log('2params',params)
                    const res = await upsertWorkOrderRequest(params);
                    if (res.status == 200) {
                      setSubmitNoRepetition(true)
                    }
                    setLoading(false);
                    if (res.data?.code === 0) {
                      message.success('添加成功');
                      onClose();
                    }
                  } else {
                    message.error('根据输入地址查询地理位置失败，可完善地址信息重新查询');
                    setLoading(false);
                  }
                });
              }
              getWorkOrderId(resss.data.data,workOrderInfo[ccc].id,workOrderInfo[ccc])
            }
          },500);
        } else {
          const params = {...values};
          params.expire = dayjs(values.expire).format("YYYY-MM-DD") + ' 23:59:59';
          if (uploadSuccessList.length) {
            params.resources = uploadSuccessList;
          }
          if (type === 'edit') {
            params.id = workOrderInfo.id;
          }
          if (lnglat) {
            params.lat = lnglat.lng;
            params.lon = lnglat.lat;
            const res = await upsertWorkOrderRequest(params);
            if (res.status == 200) {
              setSubmitNoRepetition(true)
            }
            setLoading(false);
            if (res.data?.code === 0) {
              message.success(type === 'edit' ? '编辑成功' : '添加成功');
              if(type === 'dataManageAdd'){
                const params:any = {};
                params.oc = res.data?.data
                params.id = workOrderInfo.id;
                const ress = await upsertOperationAssignOrder(params);
              }
              onClose();
              reloadData();
              workId(res.data.data,workOrderInfo.id,workOrderInfo)
            }
          } else {
            geocoderRef.current.getLocation(values.district, async (status: any, result: any) => {
              if (status === 'complete' && result.geocodes.length) {
                const districtlnglat = result.geocodes[0].location;
                params.lat = districtlnglat.lng;
                params.lon = districtlnglat.lat;
                const res = await upsertWorkOrderRequest(params);
                if (res.status == 200) {
                  setSubmitNoRepetition(true)
                }
                setLoading(false);
                if (res.data?.code === 0) {
                  message.success(type === 'edit' ? '编辑成功' : '添加成功');
                  onClose();
                  reloadData();
                  workId(res.data.data,workOrderInfo.id,workOrderInfo)
                }
              } else {
                message.error('根据输入地址查询地理位置失败，可完善地址信息重新查询');
                setLoading(false);
              }
            });
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  }

  // 选择车辆
  const selectplateNumber = async (value:any) => {
    const res = await carListRequest({
      page: 1,
      limit: 100,
      plate_number: value,
    });
    console.log('选择车辆res',res);
    form.setFieldsValue({
      deviceType: res.data.data[0].device_type,
      channel: res.data.data[0].cate_path_name.split('/')[res.data.data[0].cate_path_name.split('/').length - 2],
      company: res.data.data[0].cate_path_name.split('/').pop(),
      businessUnit:res.data.data[0].businessUnit

    })
  }

  // 获取业务渠道
  const getBusinessUnitType = async () => {
    const res = await tagListRequest({
      tagSorts: [TagCategoryEnum.businessUnit],
    });
    const tags: any = [];
    (res.data?.data || []).forEach((item: any) => {
      const { title } = item;
      tags.push({ label: title, value: title });
    });
    setCarBusinessUnit(tags);
  };

  return (
    <Modal
      title={type === 'add' ? '创建工单' : type === 'edit' ? '编辑工单': type === 'dataManageAdd' ? '创建工单' : '客服创建工单'}
      visible
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="工单类型"
          name="type"
          rules={[
            { required: true, message: '请选择工单类型' }
          ]}
        >
          <Select placeholder="请选择工单类型" options={workOrderTypeData} allowClear showArrow showSearch optionFilterProp="label"  />
        </Form.Item>
        <Form.Item
          label="问题来源"
          name="category"
          rules={[
            { required: true, message: '请选择问题来源' }
          ]}
        >
          <Select placeholder="请选择问题来源" options={serviceProblemType} allowClear showArrow showSearch optionFilterProp="label"  />
        </Form.Item>
        <Form.Item
          label="问题原因"
          name="tag"
          rules={[
            { required: true, message: '请选择问题原因' }
          ]}
        >
          <Select
            mode="multiple"
            placeholder="请选择问题原因"
            options={tagData}
            allowClear 
            showArrow 
            showSearch 
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="问题原因(自定义)"
          name="reason"
        >
          <TextArea rows={2} />
        </Form.Item>
        {/* 装车工单时车牌号非必填 */}
        {type == 'servicBatchDispatchOrder' ? null :
        <Form.Item shouldUpdate noStyle>
          {() => {
            if (form.getFieldValue('type') === 2) {
              return (
                <Form.Item
                  label="请输入车牌号"
                  name="plateNumber"
                >
                  <Input placeholder="请输入车牌号" />
                </Form.Item>
              )
            } else {
              return (
                <Form.Item label="请选择车辆"  name="plateNumber"  rules={[ { required: true, message: '请选择车辆' }  ]} >
                  <CarSelect onChange={selectplateNumber} valueField="plate_number" />
                </Form.Item>
              )
            }
          }}
        </Form.Item>}
        {/* {type == 'servicBatchDispatchOrder' ? null : */}
        <Form.Item
        label="客户电话"
        name="tel" >
        <Input placeholder="请输入客户电话" allowClear />
       </Form.Item>
       {/* } */}
        <Form.Item
          label="设备类型"
          name="deviceType"
          rules={[
            { required: true, message: '请选择设备类型' }
          ]}>
          <Select placeholder="请选择设备类型" options={deviceTypeData} allowClear showArrow showSearch optionFilterProp="label"  />
        </Form.Item>
        {type == 'servicBatchDispatchOrder' ? null :
        <Form.Item
          label="渠道"
          name="channel"
          rules={[{ required: true, message: '请输入渠道' }]}>
          <Input placeholder="请输入渠道" />
        </Form.Item>}
        {type == 'servicBatchDispatchOrder' ? null :
        <Form.Item
          label="所属公司"
          name="company"
          rules={[{ required: true, message: '请输入所属公司' }]}
        >
          <Input placeholder="请输入所属公司" />
        </Form.Item>}
        {/* {type == 'servicBatchDispatchOrder' ? null : */}
        <Form.Item label="所属业务渠道" name="businessUnit">
          <Select
            placeholder="请选择所属业务渠道"
            options={carBusinessUnit}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        {/* } */}
        <Form.Item
          label="工单描述"
          name="description"
          rules={[
            { required: true, message: '请输入工单描述' }
          ]}
        >
          <Input.TextArea placeholder="请输入工单描述" allowClear />
        </Form.Item>
        <Form.Item
          label="车辆地址"
          name="district"
          rules={[
            { required: true, message: '请输入车辆地址' }
          ]}
        >
          <Search
            placeholder='点击右侧按钮可进行地址选择'
            enterButton={<Icon type="icon-location" />}
            onSearch={() => setOperateInfo({ type: 'mapLocation' })}
          />
        </Form.Item>
        <Form.Item
          label="期望完成时间"
          name="expire"
          rules={[
            { required: true, message: '请选择期望完成时间' }
          ]}
          // initialValue={workOrderInfo.expire ? dayjs(workOrderInfo.expire) : null}
        >
         <QuickDateDayTomPicker style={{ width: '100%' }} />
        </Form.Item>
        {type == 'servicBatchDispatchOrder' ? null :
        <Form.Item label="上传影像">
          <UploadImageVideo
            businessName="客服任务影像"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            canUploadVideo
            defaultUploadFiles={
              type === 'servicrTaskAdd' ? workOrderInfo.resourceViewDtos == null ? [] : workOrderInfo.resourceViewDtos.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            })) 
            // : type === 'servicBatchDispatchOrder' ?  workOrderInfo.resourcess == null ? [] : workOrderInfo.resourcess.map((item: any) => ({
            //   contentType: item.contentType,
            //   key: new URL(item.url).pathname.slice(1),
            //   preview: item.url
            // }))
            : type === 'add' ? [] : workOrderInfo.resources == null ? [] : workOrderInfo.resources.map((item: any) => ({
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            limitSize={500}
            multiple
          />
        </Form.Item>}
      </Form>
      {operateInfo.type === 'mapLocation' && 
        <MapLocationModal
          closeModal={() => setOperateInfo({})}
          confirmLocationFunc={confirmLocation}
        />
      }
    </Modal>
  )
}

export default OperateModal;