/**
 * 添加固件
 */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Upload,
  Button
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { firmwareUpsertRequest } from '@/service/firmwareManage';
import { firmwareType, firmwareGroup } from '@/constants/firmwareManage';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';

interface AddModalProps {
  closeModal: () => void;
  getFirmwareData?: () => void;
  fileData?: any; // 上传文件相关数据
}
const AddModal = (props: AddModalProps) => {
  const [form] = Form.useForm();
  const { getFirmwareData, closeModal, fileData } = props;
  const [uploading, setUploadLoading] = useState(false); // 是否上传中
  const [isUploadSuccess, setIsUploadSuccess] = useState(Boolean(fileData)); // 是否上传文件成功

  // 有上传文件相关数据时进行表单赋值
  useEffect(() => {
    fileData && form.setFieldsValue(fileData);
  }, []);

  // 提交
  const submit = async (values: any) => {
    if (isUploadSuccess) {
      const res = await firmwareUpsertRequest(values);
      if (res.data?.code === 0) {
        message.success('添加成功');
        closeModal();
        getFirmwareData && getFirmwareData();
      }
    } else {
      message.error('请上传固件');
    }
  };

  // 上传文件改变的方法
  const onUploadChange = (info: any) => {
    // 上传中
    if (info.file.status === 'uploading') {
      setUploadLoading(true);
    } else {
      setUploadLoading(false);
    }
    // 上传失败
    if (info.file.status === 'error') {
      message.error('上传失败');
    }
    // 上传完毕
    if (info.file.status === 'done') {
      const code = info.file.response?.code;
      if (code === 0) {
        const uploadData = info.file.response?.data || {};
        setIsUploadSuccess(true);
        form.setFieldsValue({
          resourcePath: uploadData.preview,
          size: uploadData.fileSize,
          md5: uploadData.key
        });
      } else {
        message.error(info.file.response?.msg || '上传失败');
      }
    }
    // 删除已上传文件
    if (info.file.status === 'removed') {
      setIsUploadSuccess(false);
      form.setFieldsValue({
        resourcePath: '',
        size: '',
        md5: ''
      });
    }
  };

  return (
    <Modal
      title="添加固件"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      okButtonProps={{ disabled: uploading }}
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="固件类型"
          name="type"
          rules={[{ required: true, message: '请选择固件类型' }]}
        >
          <Select
            placeholder="请选择固件类型"
            options={firmwareType}
            disabled={Boolean(fileData)}
          />
        </Form.Item>
        <Form.Item
          label="固件分组"
          name="packet"
          rules={[{ required: true, message: '请选择固件分组' }]}
        >
          <Select placeholder="请选择固件分组" options={firmwareGroup} />
        </Form.Item>
        <Form.Item
          label="固件版本"
          name="version"
          rules={[{ required: true, message: '请输入固件版本' }]}
        >
          <Input placeholder="请输入固件版本" />
        </Form.Item>
        <Form.Item label="版本描述" name="ps">
          <Input.TextArea placeholder="请输入版本描述" />
        </Form.Item>
        {/* 有文件相关信息时不显示文件上传表单项 */}
        {Boolean(fileData) || (
          <Form.Item label="文件上传" required>
            <Upload
              action="/api/v2/resource/upload/firmware"
              headers={{
                access_token: localStorageUtil.getItem(
                  LocalStorageKeyEnum.access_token
                )
              }}
              maxCount={1}
              onChange={onUploadChange}
            >
              <Button
                icon={<UploadOutlined />}
                loading={uploading}
                disabled={isUploadSuccess}
              >
                文件上传
              </Button>
              {isUploadSuccess && (
                <span style={{ marginLeft: '4px' }}>
                  删除已上传文件可以重新上传
                </span>
              )}
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          label="文件地址"
          name="resourcePath"
          rules={[{ required: true, message: '请输入文件地址' }]}
        >
          <Input placeholder="请输入文件地址" disabled />
        </Form.Item>
        <Form.Item
          label="文件大小"
          name="size"
          rules={[
            { required: true, message: '请输入文件大小' },
            { type: 'number', message: '请输入数字' }
          ]}
        >
          <InputNumber
            placeholder="请输入文件大小"
            style={{ width: '100%' }}
            disabled
          />
        </Form.Item>
        <Form.Item
          label="文件MD5值"
          name="md5"
          rules={[{ required: true, message: '请输入文件MD5值' }]}
        >
          <Input placeholder="请输入文件MD5值" disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddModal;
