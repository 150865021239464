/**
 * 角色管理
 */
import React, { useState, useEffect } from 'react';
import { Card,Form, Row, Col,Input,  Button, Popconfirm, message, Select } from 'antd';
import { formatTime } from '@/utils/formatTime';
import { useTable } from '@/utils/useTable';
import { getTreeAllNode } from '@/utils/common';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { roleListRequest, deleteRoleRequest } from '@/service/roleManage';
import { organizationListRequest } from '@/service/organizationManage';
import OperateRoleModal from './components/operateRoleModal';
import PermissionModal from './components/permissionModal';
import PermissionConfigModal from './components/permissionConfigModal';
import styles from '@/styles/pageContent.module.scss';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";

const RoleManage = () => {
  const [form] = Form.useForm();
  const [searchParams,setSearchParams] = useState({}); // 搜索相关参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息
  const [organizationList, setOrganizationList] = useState<any>([]); // 组织数据
  const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
    requestFunc: roleListRequest,
    initPageSize: 40,
    searchParams,
  });
  const tabeBarshowsList = [
    { label: '工作台', value: 1},
    { label: '文件学习', value: 2 },
    { label: '车辆', value: 3 },
    { label: '工具箱', value: 4}
  ];

  // 获取组织数据
  const getOrganizationList = async () => {
    const res = await organizationListRequest();
    const resData = res.data?.data ? [res.data?.data] : [];
    setOrganizationList(
      getTreeAllNode(resData, [
        ['label', 'groupName'],
        ['value', 'id']
      ])
    );
  };

  useEffect(() => {
    getOrganizationList();
  }, []);

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = {...values};
    newSearchParams.name = (values.name || '').trim();
    setSearchParams(newSearchParams);
  };

  // 删除角色
  const deleteRole = async(id: number) => {
    const res = await deleteRoleRequest(id);
    if (res.data?.data) {
      message.success('删除成功');
      getData();
    }
  }

  const tabeBarshowsData = (value: any) => {
    if(value){
      const bbb:any = []
      value.map((item:any) => {
        const aaa:any = tabeBarshowsList.find((itemm: any) => itemm.value == item);
        bbb.push(aaa?.label)
      })
      return <RenderEllipsisColumn text={bbb.join('、')} />;
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '角色名称',
      dataIndex: 'name',
      width: 220,
    },
    {
      title: '所属组织',
      dataIndex: 'groupName',
      width: 120,
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      width: 160,
      render: (value: string) => formatTime(value),
    },
    {
      title: '更新时间',
      dataIndex: 'updatedAt',
      width: 160,
      render: (value: string) => formatTime(value),
    },
    {
      title: '小程序TabBar权限',
      dataIndex: 'tabeBarShows',
      width: 160,
      render: (value: any) => tabeBarshowsData(value),
    },
    {
      title: '操作',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <Button type="link" onClick={() => setOperateInfo({ type: 'permission', data: item })}>角色已有权限</Button>
            <PermissionButton operateType='/car-admin/role-manage/permission-config' type="link" onClick={() => setOperateInfo({ type: 'config', data: item })}>权限配置</PermissionButton>
            <PermissionButton operateType='/car-admin/role-manage/edit' type="link" onClick={() => setOperateInfo({ type: 'edit', data: item })}>编辑</PermissionButton>
            <PermissionButton
              operateType="/car-admin/role-manage/copy"
              type="link"
              onClick={() => setOperateInfo({ type: 'copy', data: item })}
            >
              复制
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此角色吗?"
              onConfirm={() => deleteRole(item.id)}>
              <PermissionButton operateType='/car-admin/role-manage/delete' type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
      width: 280,
    },
  ]

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} autoComplete='off' form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="所属组织" name="groupIds">
              <Select
                mode="multiple"
                options={organizationList}
                maxTagCount="responsive"
                allowClear
                showArrow
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="角色名称" name="name">
              <Input placeholder='请输入角色名称' allowClear />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={styles.operateBox}>
        <PermissionButton operateType='/car-admin/role-manage/add' type="primary" onClick={() => setOperateInfo({ type: 'add' })}>添加角色</PermissionButton>
      </div>
      <CommonTable
        columnFilter={false}
        rowKey='id'
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {/* 角色添加、编辑、复制 */}
      {['add', 'edit', 'copy'].includes(operateInfo.type) &&
        <OperateRoleModal
          type={operateInfo.type}
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
          reloadData={getData}
        />
      }
      {/* 查看角色权限 */}
      {operateInfo.type === 'permission' &&
        <PermissionModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      }
      {/* 配置角色权限 */}
      {operateInfo.type === 'config' &&
        <PermissionConfigModal
          data={operateInfo.data}
          closeModal={() => setOperateInfo({})}
        />
      }
    </Card>
  );
}

export default RoleManage; 