// 渲染开关的状态
export const renderOffOnColumn = (value: boolean) => {
  if (value) {
    return '开';
  } else {
    return '关';
  }
}

// 渲染档位列
export const renderGearColumn = (value: number) => {
  if (value === 0) {
    return '无效';
  } else if (value === 1) {
    return 'N档';
  } else if (value === 2) {
    return 'D档';
  } else if (value === 3) {
    return 'R档';
  } else {
    return value;
  }
}

// 渲染方向盘状态列
export const renderWheelStatusColumn = (value: number) => {
  if (value === 0) {
    return '左打';
  } else if (value === 1) {
    return '右打';
  } else {
    return value;
  }
}