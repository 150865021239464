/**
 * 车辆分布相关请求
 */
import request from "@/utils/request";

// 获取车辆位置
export const carLocationRequest = (data: { cate_id: Array<number | string> }) => {
  return request.post('/api/v2/car/locations', data)
}

// 批量车辆分布查询上传路径
export const carLocationQueryRequestPath = "/api/v2/car/extractLocations";