/**
 * 在线未报警车辆
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import { onlineNoAlarmRequest, onlineNoAlarmRequestPath } from '@/service/onlineNoAlarm';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import ContactModal from '@/components/contactModal';
import ParamsModal from '@/components/paramsModal';
import VersionModal from '@/components/versionModal';
import OperationGroupSelect from '@/components/operationGroupSelect';
import {
  dataStatisticsAlarmTypeData,
  dataStatisticsAlarmTypeValue
} from '@/constants/alarm';
import { ownRound } from '@/utils/math';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { useTable } from '@/utils/useTable';
import { snColumnConfig, cateGroupColumnConfig, frameNumberColumnConfig, carBrandColumnConfig, carModelColumnConfig } from '@/constants/tableColumn';
import styles from '@/styles/pageContent.module.scss';
import PermissionButton from '@/components/permissionButton';

const OnlineNoAlarm = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState<any>({ 
    day: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    mileage: 0,
    cate_id: cateIdArr,
    alarmTypes: dataStatisticsAlarmTypeValue,
   }); // 搜索相关的参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: onlineNoAlarmRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });

  // 处理搜索的参数
  const operateSearchParams = (params: any, isExport?: boolean) => {
    const newParams = { ...params };
    newParams.day = dayjs(params.day).format('YYYY-MM-DD');
    if (isExport) {
      newParams.export = true;
    }
    return newParams;
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateSearchParams(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateSearchParams(values, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cate_id: options.cateId,
      })
    }
  }

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
    },
    {
      title: '行驶里程',
      dataIndex: 'diff_mileage',
      width: 110,
      render: (value: string) => ownRound(value, 1),
    },
    snColumnConfig,
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
    },
    cateGroupColumnConfig,
    frameNumberColumnConfig,
    carBrandColumnConfig,
    carModelColumnConfig,
    {
      title: '操作',
      dataIndex: 'operate',
      width: 260,
      fixed: 'right' as 'right',
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton 
              operateType='/car-admin/online-no-alarm/contact'
              type="link"
              onClick={() => setOperateInfo({ type: 'contact', data: item })}
            >
              联系方式
            </PermissionButton>
            <Button type="link" onClick={() => setOperateInfo({ type: 'params', data: item })}>参数信息</Button>
            <Button type="link" onClick={() => setOperateInfo({ type: 'version', data: item })}>版本信息</Button>
          </div>
        )
      },
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="day"
              initialValue={dayjs(searchParams.day)}
            >
              <DatePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/online-no-alarm/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cate_id" initialValue={searchParams.cate_id}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="行驶里程"
              name="mileage"
              initialValue={searchParams.mileage}
            >
              <InputNumber placeholder='当天累计行驶里程' style={{ width: '100%' }} addonAfter="里程" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="报警类型"
              name="alarmTypes"
              initialValue={searchParams.alarmTypes}
              rules={[{ required: true, message: "请指定报警类型" }]}
            >
              <Select mode="multiple" options={dataStatisticsAlarmTypeData} maxTagCount="responsive" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={onlineNoAlarmRequestPath}
                  fileName='在线未报警'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/online-no-alarm/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
      {operateInfo.type === 'contact' &&
        <ContactModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'params' &&
        <ParamsModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
      {operateInfo.type === 'version' &&
        <VersionModal data={operateInfo.data} closeModal={() => setOperateInfo({})} />
      }
    </Card>
  )
}

export default OnlineNoAlarm;
  