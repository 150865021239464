/**
 * 操作问卷调查
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, Radio } from 'antd';
import DatePicker from '@/components/datePicker';
import { userListRequest } from '@/service/userManage';
import { upsertquestionnaireSurvey } from '@/service/questionnaireSurvey';
import CarSelect from '@/components/carSelect';
import dayjs from 'dayjs';

interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModalTask = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  const [maintenancePerson, setMaintenancePerson] = useState([]); // 维保人员数据

  // 编辑的时候赋值
  useEffect(() => {
    getMaintenancePerson();
    if (type == 'edit') {
      data.revisitDate = dayjs(data.revisitDate)
      form.setFieldsValue(data)
    }
    if (type == 'carDiagnosisAdd') {
      form.setFieldsValue({plateNumber:data})
    }
  }, []);

  // 获取维保人员数据
  const getMaintenancePerson = async() => {
    const res = await userListRequest({ page: 1, limit: 999 });
    setMaintenancePerson(res.data?.data || [])
  }

  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = {...values};
      params.revisitDate = dayjs(values.revisitDate).format("YYYY-MM-DD");
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertquestionnaireSurvey(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'edit' ? '编辑问卷调查' : '问卷调查'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={submit}
        layout="vertical"
        scrollToFirstError
      >
        <Form.Item
          label="车牌号"
          name="plateNumber"
          rules={[
            { required: true, message: '请选择车牌号' }
          ]}
        >
          <CarSelect valueField="plate_number" />
        </Form.Item>
        <Form.Item
          label="回访日期"
          name="revisitDate"
          rules={[
            { required: true, message: '请选择回访日期' }
          ]}
        >
          <DatePicker
            placeholder="请选择回访日期"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          label="安装人员"
          name="maintainer"
          rules={[
            { required: true, message: '请选择安装人员' }
          ]}
        >
          <Select
            placeholder="请选择安装人员"
            options={maintenancePerson}
            allowClear
            showArrow
            showSearch
            fieldNames={{
              label: 'nickname',
              value: 'nickname'
            }}
            optionFilterProp="nickname"
          />
        </Form.Item>
        <Form.Item 
          label="安装人员去之前是否有电话联系？" 
          name="telFlag"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          label="安装人员是否按照要求到达现场？" 
          name="reachFlag"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          label="安装人员现场讲解设备使用规则是否清晰流畅？" 
          name="expressFlag"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          label="给您讲解设备使用方法后是否还有不清楚的地方？" 
          name="unclearFlag"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          label="您对安装人员本次的服务评价" 
          name="total"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Radio.Group>
            <Radio value={5}>非常满意</Radio>
            <Radio value={4}>满意</Radio>
            <Radio value={3}>一般</Radio>
            <Radio value={2}>不满意</Radio>
            <Radio value={1}>非常不满意</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="suggest"
          label="最后请问您对我们安装人员及设备有其他建议或者意见吗？"
        >
          <Input.TextArea showCount style={{ height: 120 }}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModalTask;