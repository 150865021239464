/**
 * 部标机报警视频上传规则相关常量
 */
// 预警等级
export const warningLevelData = [
  { label: '全部等级', value: 0 },
  { label: '一级预警', value: 1 },
  { label: '二级预警', value: 2 },
  { label: '三级预警', value: 3 }
];

// 报警上传规则
export const alarmRuleData = [
  { title: 'FCW预警', key: 1 },
  { title: 'HMW预警', key: 5 },
  { title: 'LDW预警', key: 4 },
  { title: 'AEB制动', key: 2 },
  { title: '角雷达报警', key: 6 },
  { title: '右转制动', key: 8 }
];
