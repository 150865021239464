/**
 * 操作记录的相关请求
 */
import request from "@/utils/request";

export interface OperateLogParams {
  page: number,
  limit: number;
  startTime: string;
  endTime: string;
  optUserId?: number;
  optType?: number;
}

// 获取操作记录类型
export const operateLogRequestPath = '/api/v2/optLog/queryOptLog';
export const operateLogRequest = (params: OperateLogParams) => {
  return request.get(operateLogRequestPath, {
    params,
  })
}

// 获取操作记录类型枚举
export const operateLogEnumRequest = () => {
  return request.get('/api/v2/optLog/enum')
}