/**
 * 分组管理相关请求
 */
import request from "@/utils/request";

// 获取分组的树形数据
export const groupCategoryRequest = () => {
  return request.get('/api/v2/car/cateList')
}

// 添加、编辑分组
export const upsertGroupRequest = (data: any) => {
  return request.post('/api/v2/cate/upsert', data)
}

// 删除分组
export const deleteGroupRequest = (id : number) => {
  return request.delete(`/api/v2/cate/${id}`)
}