/**
 * 报警对比相关常量
 */
export interface JtDeviceCompareAlarmGroupType {
  label: string;
  key: string;
  options: Array<JtDeviceCompareAlarmType>;
}
export interface JtDeviceCompareAlarmType {
  label: string;
  value: string;
}
// 报警对比部标机设备报警类型数据
export const jtDeviceCompareAlarmTypeData = [
  {
    label: '高级驾驶辅助系统报警',
    key: 'adas',
    options: [
      { label: '前向碰撞报警', value: 'adas_1' },
      { label: '车道偏离报警', value: 'adas_2' },
      { label: '车距过近报警', value: 'adas_3' },
      { label: '行人碰撞报警', value: 'adas_4' }
    ]
  },
  {
    label: '盲区监测系统报警',
    key: 'blind',
    options: [
      { label: '后方接近报警', value: 'blind_1' },
      { label: '左侧后方接近报警', value: 'blind_2' },
      { label: '右侧后方接近报警', value: 'blind_3' }
    ]
  }
];

// 报警对比部标机设备报警方向数据
export const jtDeviceAlarmDirectionData = [
  {
    title: '全部',
    value: jtDeviceCompareAlarmTypeData
      .map((item: JtDeviceCompareAlarmGroupType) =>
        item.options.map((item1: JtDeviceCompareAlarmType) => item1.value)
      )
      .flat(Infinity)
      .join(','),
    children: [
      {
        title: '前向',
        value: jtDeviceCompareAlarmTypeData
          .find((item: JtDeviceCompareAlarmGroupType) => item.key === 'adas')
          ?.options.map((item1: JtDeviceCompareAlarmType) => item1.value)
          .join(','),
        children: jtDeviceCompareAlarmTypeData
          .find((item: JtDeviceCompareAlarmGroupType) => item.key === 'adas')
          ?.options.map((item1: JtDeviceCompareAlarmType) => ({
            title: item1.label,
            value: item1.value
          }))
      },
      {
        title: '右侧',
        value: jtDeviceCompareAlarmTypeData
          .find((item: JtDeviceCompareAlarmGroupType) => item.key === 'blind')
          ?.options.map((item1: JtDeviceCompareAlarmType) => item1.value)
          .join(','),
        children: jtDeviceCompareAlarmTypeData
          .find((item: JtDeviceCompareAlarmGroupType) => item.key === 'blind')
          ?.options.map((item1: JtDeviceCompareAlarmType) => ({
            title: item1.label,
            value: item1.value
          }))
      }
    ]
  }
];

// 报警对比对齐间隔
export const alarmCompareIntervalData = [
  { label: '1s', value: 1 },
  { label: '2s', value: 2 },
  { label: '3s', value: 3 },
  { label: '4s', value: 4 },
  { label: '5s', value: 5 }
];

// 报警对比设备类型枚举
export enum compareDeviceTypeEnum {
  'jtDevice' = 1,
  'aeb' = 2
}

// 报警对比设备类型
export const alarmCompareDeviceType = [
  { label: '部标机', value: compareDeviceTypeEnum.jtDevice },
  { label: 'AEB', value: compareDeviceTypeEnum.aeb }
];

// 报警对比设备数量
export const alarmCompareDeviceCount = [
  { label: '设备一', value: 1 },
  { label: '设备二', value: 2 }
];

// 报警对比表格title
export const alarmCompareTableTitle = (correctTime: number) => [
  {
    className: 'leftHeader',
    title: '设备一',
    children: [
      { className: 'type', title: '类型' },
      { className: 'time', title: '时间' },
      { className: 'speed', title: '速度' },
      { className: 'operate', title: '操作' },
      { className: 'evalute', title: '评价' }
    ]
  },
  {
    className: 'rightHeader',
    title: '设备二',
    children: [
      { className: 'type', title: '类型' },
      { className: 'time', title: `时间(修正)(+${correctTime}s)` },
      { className: 'speed', title: '速度' },
      { className: 'operate', title: '操作' },
      { className: 'evalute', title: '评价' }
    ]
  }
];

// 前向报警
const frontAlarmString = [
  'FCW预警',
  'AEB制动 (双目制动)',
  'AEB制动 (毫米波制动)',
  'AEB制动 (超声波制动)',
  '起步阻止',
  'LDW预警',
  'HMW预警'
];

// 右侧报警
const rightAlarmString = ['AEB制动 (角雷达制动)', '角雷达报警', '右转制动'];

// 驾驶行为的报警数据类型(区分方向)
export const driveBehaviorDirectionData = [
  {
    title: '全部',
    value: [...frontAlarmString, ...rightAlarmString].join(','),
    children: [
      {
        title: '前向',
        value: frontAlarmString.join(','),
        children: frontAlarmString.map((item: string) => ({
          title: item,
          value: item
        }))
      },
      {
        title: '右侧',
        value: rightAlarmString.join(','),
        children: rightAlarmString.map((item: string) => ({
          title: item,
          value: item
        }))
      }
    ]
  }
];
