/**
 * ftp服务器资源列表
 */
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Button } from "antd";
import CommonTable from "@/components/commonTable";
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import PermissionButton from "@/components/permissionButton";
import { ftpResourceRequest } from "@/service/jtDevicePlayback";
import { formatTime } from "@/utils/formatTime";
import { deviceOperateFtpResource, operateFtpResource } from "@/utils/jtDevice";
import { downloadFile, downloadUrl } from "@/utils/download";
import styles from "@/styles/pageContent.module.scss";
import { jtDeviceDetailRequest } from "@/service/jtDevice";

interface FtpResourceTableProps {
  plateNumber: string; // 车牌号
  downloadPermissionKey?: string; // 下载按钮的权限key
  reportedTime?: string; // 上报时间
  playFtpVideo?: (url: string) => void; // 播放视频的处理逻辑
  jt808DeviceId?: any; // 设备id
}
const FtpResourceTable = (props: FtpResourceTableProps, ref: any) => {
  const {
    plateNumber,
    downloadPermissionKey = "",
    reportedTime,
    playFtpVideo,
    jt808DeviceId,
  } = props;
  const [resourceList, setResourceList] = useState([]); // ftp服务器资源列表
  const [loading, setLoading] = useState<boolean>(false); // 请求ftp服务器资源列表的loading
  const [ftpPlayLoading, setFtpPlayLoading] = useState<any>({}); // 播放按钮loading控制
  const [ftpDownloadLoading, setFtpDownloadLoading] = useState<any>({}); // 下载按钮loading控制

  // 获取ftp服务器资源列表
  const getFtpResource = async () => {
    try {
      setLoading(true);
      const params: any = { plateNumber };
      if (reportedTime) {
        params.reportedTime = reportedTime;
      }
      const res = await ftpResourceRequest(params);
      setResourceList(res.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getFtpResource();
    }, 100);
  }, []);

  // reload是暴露给父组件的方法
  useImperativeHandle(ref, () => ({
    reload: () => {
      getFtpResource();
    },
  }));

  // 播放
  const onPlay = async (data: any) => {
    // try {
    //   const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
    //   setFtpPlayLoading({ ...ftpPlayLoading, [data.id]: true });
    //   if (jtDeviceRes.data?.data?.filePath) {
    //     const params = { ...data };
    //     params.jtDeviceRes = jtDeviceRes.data?.data?.filePath;
    //     const url = await deviceOperateFtpResource(params);
    //     playFtpVideo && playFtpVideo(url);
    //   } else {
    //     const url = await operateFtpResource(data);
    //     playFtpVideo && playFtpVideo(url);
    //   }
    //   setFtpPlayLoading({ ...ftpPlayLoading, [data.id]: false });
    // } catch (error) {
    //   setFtpPlayLoading({ ...ftpPlayLoading, [data.id]: false });
    // }
    playFtpVideo && playFtpVideo(data.url);
  };

  // 下载
  const onDownload = async (data: any) => {
    const { id, beginTime, endTime } = data;
    // try {
    //   const jtDeviceRes = await jtDeviceDetailRequest(jt808DeviceId);
    //   setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: true });
    //   if (jtDeviceRes.data?.data?.filePath) {
    //     const params = { ...data };
    //     params.jtDeviceRes = jtDeviceRes.data?.data?.filePath;
    //     const url = await deviceOperateFtpResource(params);
    //     downloadUrl(url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`);
    //   } else {
    //     const url = await operateFtpResource(data);
    //     downloadUrl(url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`);
    //   }
    //   setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    // } catch (error) {
    //   setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    // }
    // downloadUrl(data.url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`);
    setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: true });
    try{
      await downloadFile(data.url, `${formatTime(beginTime)}-${formatTime(endTime)}.mp4`, false)
      setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    }catch(e){
      setFtpDownloadLoading({ ...ftpDownloadLoading, [id]: false });
    }
  };

  // 资源表格列显示
  const resourceColumns = [
    {
      title: "开始时间",
      dataIndex: "beginTime",
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      ),
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      ),
    },
    {
      title: "上传时间",
      dataIndex: "createdAt",
      width: 120,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn text={formatTime(value)} />
      ),
    },
    {
      title: "通道号",
      dataIndex: "channelNo",
      width: 100,
    },
    {
      title: "操作",
      dataIndex: "operate",
      width: 150,
      fixed: "right" as const,
      render: (value: any, item: any) => (
        <div className={styles.tableOperateColumn}>
          {item.url&&(
            <>
              <Button
                type="link"
                onClick={() => onPlay(item)}
                loading={Boolean(ftpPlayLoading[item.id])}
              >
                播放
              </Button>
              <PermissionButton
                type="link"
                operateType={downloadPermissionKey}
                onClick={() => onDownload(item)}
                loading={Boolean(ftpDownloadLoading[item.id])}
              >
                下载
              </PermissionButton>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <CommonTable
      dataSource={resourceList}
      columns={resourceColumns}
      columnFilter={false}
      size="small"
      scroll={{ y: 200 }}
      loading={loading}
      pagination={false}
      bordered
    />
  );
};

export default forwardRef(FtpResourceTable);
