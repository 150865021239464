/**
 * 工单收费记录相关请求
 */
import request from "@/utils/request";

export interface CostRecordRequestParams {
    page: number; 
    limit: number;
    // nickname?: string;
    // mobile?: string;
    // roleIds?: Array<number>;
    carId: number;
}

export const costRecordListRequestPath = '/api/v2/chargingRecord/list';

// 获取收费列表数据
export const costRecordListRequest = (params: CostRecordRequestParams) => {
    return request.get(costRecordListRequestPath, {
        params,
    })
}

// 确认收费记录
export const costRecordAffirm = (params:any) => {
    return request.get('/api/v2/chargingRecord/confirm',{
        params,
    })
}

// 添加收费列表数据
export const costRecordAdd = (data: any) => {
    return request.post('/api/v2/chargingRecord/upsert', data)
}

// 删除收费记录
export const costRecordDelete = (id: number) => {
    return request.delete(`/api/v2/chargingRecord/${id}`)
}