/**
 * 在线记录
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Row, Col, Input, message } from 'antd';
import dayjs from 'dayjs';
import { onlineRecordListRequest } from '@/service/onlineRecord';
import CarTree from '@/components/carTree';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import styles from './index.module.scss';

const OnlineRecord = () => {
  const [form] = Form.useForm();
  const tabPagesStore = useSelector((state: any) => state.tabPagesStore);
  const { activeKey, tabParams } = tabPagesStore;
  const [currentYear, setCurrentYear] = useState<number | string>(new Date().getFullYear()); // 当前年份
  const [hasSearch, setHasSearch] = useState(false); // 是否搜索过
  const [onlineData, setOnlineData] = useState<Array<string>>([]); // 在线数据
  const [loading, setLoading] = useState<boolean>(false); // 数据是否请求中
  const allMonth = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']; 
  const allWeek = ['日', '一', '二', '三', '四', '五', '六']; 

  // 监听跳转时携带参数的形式
  useEffect(() => {
    if (activeKey === '/car-admin/online-record' && tabParams) {
      const newTabParams = {
        plateNumber: tabParams.plateNumber,
        year: dayjs(tabParams.year),
      };
      form.setFieldsValue(newTabParams);
      form.submit();
    }
  }, [activeKey, tabParams]);

  // 搜索
  const onSearch = async (values: any) => {
    if (!values.plateNumber) {
      message.info('请选择车辆');
    } else {
      try {
        const params = {...values};
        params.plateNumber = (values.plateNumber || '').trim();
        const selectYear = dayjs(values.year).format('YYYY');
        params.year = selectYear;
        setLoading(true);
        const res = await onlineRecordListRequest(params);
        const data = res.data?.data || [];
        setCurrentYear(selectYear);
        setOnlineData(data);
        setHasSearch(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  // 选择车辆
  const treeSelect = (selectCarInfo: any) => {
    const { selected, title } = selectCarInfo;
    if (!selected) {
      form.setFieldsValue({ plateNumber: title })
    } else {
      form.setFieldsValue({ plateNumber: '' })
    }
  }

  return (
    <div className={styles.onLineRecordBox}>
      <div className={styles.carTree}>
        <CarTree
          treeSelect={treeSelect}
          selectableTypes={['car']}
        />
      </div>
      <div className={styles.recordBox}>
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onSearch}
          form={form}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="车牌号" name="plateNumber">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="时间" name="year" initialValue={dayjs(`${currentYear}-01-01`)}>
                <DatePicker picker='year' allowClear={false} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button type="primary" htmlType="submit">查询</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {hasSearch ? 
          <LoadingWrapper spinning={loading} autoHeight>
            <div className={styles.month}>
              {allMonth.map((item: string, index: number) => {
                // 当前月的总天数
                const allDays = dayjs(`${currentYear}-${item}`).daysInMonth();
                // 当前月第一天是星期几
                const firstDay = new Date(`${currentYear}-${item}-01`).getDay();
                // 当前月的所有天
                const dayArray = new Array(allDays).fill(true).map((item1: any, index1: number) => index1 + 1);
                if (firstDay) {
                  dayArray.unshift(...new Array(firstDay).fill(false));
                }
                return (
                  <div className={styles.monthItemBox} key={item}>
                    <div className={styles.monthItem} key={item}>
                      <div className={styles.title}>{`${currentYear}年${index + 1}月`}</div>
                      <div className={styles.week}>
                        {allWeek.map((item2: string) => {
                          return (
                            <div className={styles.weekItem} key={item2}>{item2}</div>
                          )
                        })}
                      </div>
                      <div className={styles.day}>
                        {dayArray.map((item3: any, index: number) => {
                          const fullDay = `${currentYear}-${item}-${item3 < 10 ? `0${item3}` : item3} 00:00:00`;
                          return (
                            <div
                              className={onlineData.includes(fullDay) ?  styles.dayItemOnline : styles.dayItem}
                              key={index}
                            >
                              {item3}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </LoadingWrapper> : 
          <div className={styles.tips}>
            请在左侧选择车辆后点击“查询”按钮
          </div>
        }
      </div>
    </div>
  )
}

export default OnlineRecord;