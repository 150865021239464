/**
 * 操作合同管理(添加或编辑)
 */
import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, message, Switch, Select, Upload, Button } from 'antd';
import { upsertContract } from '@/service/contractManage';
import QuickDatePicker from '@/components/quickDatePicker';
import { modeData, stateData } from '@/constants/underwritingRecord';
import dayjs from 'dayjs';
import UploadFile from '@/components/uploadFile';


interface OperateModalProps {
  reloadData: () => void;
  closeModal: () => void;
  type: string;
  data?: any;
}
const OperateModal = (props: OperateModalProps) => {
  const [form] = Form.useForm();
  const { reloadData, closeModal, type, data} = props;
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传文件
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      const params = { ...data };
      if (params.beginTime !== null) {
        params.beginTime = dayjs(data.beginTime)
      }
      if (params.endTime !== null) {
        params.endTime = dayjs(data.endTime)
      }
      if (params.installTime !== null) {
        params.installTime = dayjs(data.installTime)
      }

      if (params.uninstallTime !== null) {
        params.uninstallTime = dayjs(data.uninstallTime)
      }
      if (uploadSuccessList.length) {
        params.attachments = uploadSuccessList;
      } else {
        params.attachments = '';
      }

      form.setFieldsValue(params)
    }
    if (type === 'copy') {
      const params = { ...data };
      if (params.beginTime !== null) {
        params.beginTime = dayjs(data.beginTime)
      }
      if (params.endTime !== null) {
        params.endTime = dayjs(data.endTime)
      }
      if (params.installTime !== null) {
        params.installTime = dayjs(data.installTime)
      }

      if (params.uninstallTime !== null) {
        params.uninstallTime = dayjs(data.uninstallTime)
      }
      if (uploadSuccessList.length) {
        params.attachments = uploadSuccessList;
      } else {
        params.attachments = '';
      }
      delete params.id;
      form.setFieldsValue(params)
    }
    if(type == 'add'){
      if (data !== '') {
        form.setFieldsValue(data)
      }
    }
  }, []);


  // 提交
  const submit = async (values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params = { ...values };
      console.log('params',params)
      if(params.beginTime){
        params.beginTime = dayjs(values.beginTime).format('YYYY-MM-DD')
      }
      if(params.endTime){
        params.endTime = dayjs(values.endTime).format('YYYY-MM-DD')
      }
      if(params.installTime){
      params.installTime = dayjs(values.installTime).format('YYYY-MM-DD')
      }
      if(params.uninstallTime){
      params.uninstallTime = dayjs(values.uninstallTime).format('YYYY-MM-DD')
      }
      if (type === 'edit') {
        params.id = data.id;
      }
      if (uploadSuccessList.length) {
        params.attachments = uploadSuccessList;
      } else {
        params.attachments = null;
      }
      const res = await upsertContract(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'add' || type === 'copy'  ? '添加成功' : '修改成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加合同' : '编辑合同'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
        onFinish={submit}
        initialValues={{
          state: '正常'
        }}
      >
        <Form.Item label="合作模式" name="mode" rules={[{ required: true, message: '请选择合作模式' }]}>
          <Select
            placeholder="请选择合作模式"
            options={modeData}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label="合同号"
          name="no"
          rules={[{ required: true, message: '请输入合同号' }]}
        >
          <Input placeholder='请输入合同号' />
        </Form.Item>
        <Form.Item
          label="车牌号"
          name="plateNumber"
          rules={[{ required: true, message: '请输入车牌号' }]}
        >
          <Input placeholder='请输入车牌号' />
        </Form.Item>
        <Form.Item
          label="车架号"
          name="frameNumber"
          rules={[{ required: true, message: '请输入车架号' }]}
        >
          <Input placeholder='请输入车架号' />
        </Form.Item>
        <Form.Item
          label="甲方"
          name="mainA"
          rules={[{ required: true, message: '请输入甲方' }]}
        >
          <Input placeholder='请输入甲方' />
        </Form.Item>
        <Form.Item
          label="乙方"
          name="mainB"
          rules={[{ required: true, message: '请输入乙方' }]}
        >
          <Input placeholder='请输入乙方' />
        </Form.Item>
     
        <Form.Item
          label="合同开始日期"
          name="beginTime"
          rules={[{ required: true, message: '请选择合同开始日期' }]}
        >
          <QuickDatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="合同结束日期"
          name="endTime"
          rules={[{ required: true, message: '请选择合同结束日期' }]}
        >
          <QuickDatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="安装人员"
          name="installer"
          rules={[{ required: false, message: '请输入安装人员' }]}
        >
          <Input placeholder='请输入安装人员' />
        </Form.Item>
        <Form.Item
          label="设备安装时间"
          name="installTime"
        >
          <QuickDatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label="拆除人员"
          name="removalUser"
          rules={[{ required: false, message: '请输入拆除人员' }]}
        >
          <Input placeholder='请输入拆除人员' />
        </Form.Item>
        <Form.Item
          label="设备拆除时间"
          name="uninstallTime"
        >
          <QuickDatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="合同状态" name="state">
          <Select
            defaultValue={'正常'}
            placeholder="请选择合同状态"
            options={stateData}
            allowClear
            showArrow
            showSearch
            optionFilterProp="label" 
          />
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
        >
          <Input placeholder='请输入备注' />
        </Form.Item>
        <Form.Item label="上传文件" name="attachments">
          <UploadFile
            accept= ".pdf"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            defaultUploadFiles={type === 'add' ? [] : data.attachments == null ? [] : data.attachments.map((item: any) => ({
              fileName: item.fileName,
              contentType: item.contentType,
              key: new URL(item.url).pathname.slice(1),
              preview: item.url
            }))}
            canUploadVideo
            limitSize={500}
            multiple
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateModal;
