// 用户有多个分组的形式时过滤显示分组树
export const filterMultipleCateData = (data: any, cateIds: any) => {
  const newData: any = [];
  data.forEach((item: any) => {
    let obj: any = {};
    if (cateIds.includes(item.id)) {
      newData.push(item);
    } else if (Array.isArray(item.children) && item.children.length > 0) {
      const tempData = filterMultipleCateData(item.children, cateIds);
      obj = {
        ...item,
        children: tempData,
      };
      // 当children不为空时才保留该数据
      if (obj.children.length > 0) {
        newData.push(obj);
      }
    }
  });
  return newData;
};