/**
 * 车队选择框(根据分组接口过滤子节点)
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { omit } from 'lodash';
import { groupCategoryRequest } from '@/service/groupManage';
import { useSelector } from 'react-redux';

interface FleetSelectProps extends SelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField: string; // 作为选择器车辆value的字段
}
const FleetSelect = (props: FleetSelectProps) => {
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail } = userStore;
  const { value, onChange, valueField } = props;
  const [fleetList, setFleetList] = useState<any>([]); // 车队列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>();

  useEffect(() => {
    getCategoryData();
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 获取所有子节点数据
  const getLastLevelData = (data: any) => {
    console.log('车队选择' , data)
    if (Array.isArray(data) && data.length > 0) {
      const newDataObj: any = {};
      const newData: any = [];
      const deep = (innerData: any) => {
        innerData.forEach((item: any) => {
          if (Array.isArray(item.children) && item.children.length > 0) {
            deep(item.children);
          } else {
            if(userDetail.multiCate !== '' && userDetail.multiCate !=null ){
              if (JSON.parse(userDetail.multiCate).includes(item.id)) {
              if (newDataObj[item.title]) {
                newDataObj[item.title].push(item.id);
              } else {
                newDataObj[item.title] = [item.id];
              }
            }
            }else{
              if (newDataObj[item.title]) {
                newDataObj[item.title].push(item.id);
              } else {
                newDataObj[item.title] = [item.id];
              }
            }
          }
        });
      };
      deep(data);
      Object.keys(newDataObj).forEach((item: any) => {
        newData.push({
          label: item,
          value: newDataObj[item]
        })
      });
      return newData;
    } else {
      return [];
    }
  }

  // 获取分组树形数据
  const getCategoryData = async () => {
    const res = await groupCategoryRequest();
    const data = res.data?.data || [];
    setFleetList(getLastLevelData(data));
    console.log(fleetList)
  }
  const selectOwnProps = omit(props, 'valueField')
  return (
    <Select
      {...selectOwnProps}
      fieldNames={{
        label: 'label',
        value: valueField || 'value'
      }}
      placeholder='可根据车队名称进行搜索'
      showSearch
      options={fleetList}
      onChange={onChange}
      value={selectValue}
      allowClear
    />
  )
}

export default FleetSelect;
