/**
 * 行驶轨迹查询相关请求
 */
import request from '@/utils/request';

export interface trackByPlateNumberRequestParams {
  plateNumber: string;
  beginTime: string;
  endTime: string;
}

// 获取驾驶行为分析数据
export const trackByPlateNumberRequest = (
  params: trackByPlateNumberRequestParams
) => {
  return request.get('/api/v2/car/trackByPlateNumber', {
    params
  });
};
