/**
 * 车队风险评估报告
 */
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button, Descriptions, InputNumber, Select, Input } from 'antd';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  ToolboxComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import { fleetRiskReportRequest, motorcadeCarList, fleetRiskReportExportRequestPath, cateAllCompanyRequest } from '@/service/fleetRisk';
import { limitDayRange } from '@/utils/operateSearchParams';
import styles from './index.module.scss';
import {companyListRequest} from "@/service/companyManage";

const { TextArea } = Input;

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  BarChart,
  CanvasRenderer,
  ToolboxComponent,
  UniversalTransition
]);
const { RangePicker } = DatePicker;
const FleetRisk = () => {
  const [data, setData] = useState<any>({}); // 报告相关数据
  const [carOption, setcarOption] = useState<any>([]); // 车辆选择
  const [carEvaluate, setCarEvaluate] = useState<any>([]); // 车队评价依据
  const [carScore, setCarScore] = useState<any>([]); // 车辆评分结果
  const [carDayOperation, setDayOperation] = useState<any>([]); // 车辆日运行说明
  const [carMaintains, setCarMaintains] = useState<any>([]); // 运维记录
  const [remarkContent, setRemarkContent] = useState<any>(); // 备注
  const [loading, setLoading] = useState(false); // 是否在获取报告中
  const [allCompany, setAllCompany] = useState<any>([]); // 车队选择
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(1, 'month').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
  }); // 搜索相关的参数

  useEffect(() => {
    getCateAllCompanyData()
  }, []);

  const getCateAllCompanyData = async () => {
    // const res = await companyListRequest({page: 1, limit: 20000, name: ''});
    const res = await cateAllCompanyRequest();
    const data = res.data?.data || [];
    //循环遍历data
    const aa: any = []
    data.forEach((item: any) => {
      // aa.push({label: item.name, value: item.name})
      aa.push({label: item.title, value: item.title})
    })
    setAllCompany(aa)
  }

  // 获取车队风险报告数据
  const getFleetRiskReport = async (data: any) => {
    try {
      setLoading(true);
      const res = await fleetRiskReportRequest(data);
      const reportData = res.data?.data || {};
      setData(reportData);
      // 车队评价依据
      const carEvaluates = [
        {
          one: 'LDW(车道偏离预警)',
          two: reportData?.orgScore?.ldw,
          three: reportData?.orgScore?.ldwW,
          four: reportData?.orgScore?.ldwScore,
        },
        {
          one: 'HMW(跟车过近预警)',
          two: reportData?.orgScore?.hmw,
          three: reportData?.orgScore?.hmwW,
          four: reportData?.orgScore?.hmwScore,
        },
        {
          one: 'FCW(趋近碰撞预警)',
          two: reportData?.orgScore?.fcw,
          three: reportData?.orgScore?.fcwW,
          four: reportData?.orgScore?.fcwScore,
        },
        {
          one: 'AEB(紧急制动数量)',
          two: reportData?.orgScore?.aeb,
          three: reportData?.orgScore?.aebW,
          four: reportData?.orgScore?.aebScore,
        },
        {
          one: '疲劳驾驶预警',
          two: reportData?.orgScore?.tir,
          three: reportData?.orgScore?.tirW,
          four: reportData?.orgScore?.tirScore,
        },
        {
          one: '总分',
          two: reportData?.orgScore?.total,
        }
      ]
      setCarEvaluate(carEvaluates)
      // 车辆评分结果
      setCarScore([])
      let scores = reportData.carScores;
      scores.forEach((item: any) => {
        if (item.ldw < 0) {
          item.ldw = "-"
          item.hmw = "-"
          item.fcw = "-"
          item.aeb = "-"
          item.tir = "-"
          item.total = "-"
          item.rank = "-"
        }
      })
      setCarScore(scores)
      // 车辆日运行说明
      setDayOperation([])
      setDayOperation(reportData.riskInfo)
      // 运维记录
      setCarMaintains([])
      setCarMaintains(reportData.maintains)
      // 渲染图表
      renderChart(reportData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 渲染图表
  const renderChart = (data: any) => {
    const oneFirstData = [data.riskCompare.ldw.first, data.riskCompare.hmw.first, data.riskCompare.fcw.first, data.riskCompare.aeb.first, data.riskCompare.tir.first]
    const oneSecondData = [data.riskCompare.ldw.second, data.riskCompare.hmw.second, data.riskCompare.fcw.second, data.riskCompare.aeb.second, data.riskCompare.tir.second]
    const OneOption = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      color: ['#009DFF', '#22E4FF'],
      title: {
        left: 'center',
        text: '车队风险预警周期对比分析',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          show: true,
          type: 'cross',
          lineStyle: {
            type: 'dashed',
            width: 1
          }
        },
      },
      legend: {
        left: 'right'
      },
      xAxis: { type: 'category', data: ['LDW', 'HMW', 'FCW', 'AEB', '疲劳驾驶'] },
      yAxis: { type: 'value', name: '预警次数' },
      series: [
        {
          data: oneSecondData,
          type: 'bar',
          name: '上周期',
        },
        {
          name: '本周期',
          type: 'bar',
          data: oneFirstData,
        }
      ]
    };
    const OneChart = echarts.init(document.getElementById('OneChart') as HTMLElement)
    OneChart.setOption(OneOption, true);

    const twoTimeData: any[] = []
    const twoStandardData: any[] = []
    const twoActualData: any[] = []
    data.hwm.map((item: any) => {
      twoTimeData.push(item.date)
      twoStandardData.push(item.standard)
      twoActualData.push(item.actual)
    })
    const TwoOption = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: 'HMW风险变化趋势'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: twoTimeData
      },
      yAxis: { type: 'value', name: '报警次数' },
      series: [
        {
          name: '标准值',
          type: 'line',
          data: twoStandardData
        },
        {
          name: 'HMW日出车报警次数',
          type: 'bar',
          data: twoActualData
        }
      ]
    };
    const TwoChart = echarts.init(document.getElementById('TwoChart') as HTMLElement)
    TwoChart.setOption(TwoOption, true);

    const threeTimeData: any[] = []
    const threeStandardData: any[] = []
    const threeActualData: any[] = []
    data.ldw.map((item: any) => {
      threeTimeData.push(item.date)
      threeStandardData.push(item.standard)
      threeActualData.push(item.actual)
    })
    const ThreeOption = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: 'LDW风险变化趋势'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: threeTimeData
      },
      yAxis: { type: 'value', name: '报警次数' },
      series: [
        {
          name: '标准值',
          type: 'line',
          data: threeStandardData
        },
        {
          name: 'LDW日出车报警次数',
          type: 'bar',
          data: threeActualData
        }
      ]
    };
    const ThreeChart = echarts.init(document.getElementById('ThreeChart') as HTMLElement)
    ThreeChart.setOption(ThreeOption, true);

    const fourTimeData: any[] = []
    const fourStandardData: any[] = []
    const fourActualData: any[] = []
    data.fcw.map((item: any) => {
      fourTimeData.push(item.date)
      fourStandardData.push(item.standard)
      fourActualData.push(item.actual)
    })
    const FourOption = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: 'FCW风险变化趋势'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: fourTimeData
      },
      yAxis: { type: 'value', name: '报警次数' },
      series: [
        {
          name: '标准值',
          type: 'line',
          data: fourStandardData
        },
        {
          name: 'FCW日出车报警次数',
          type: 'bar',
          data: fourActualData
        }
      ]
    };
    const FourChart = echarts.init(document.getElementById('FourChart') as HTMLElement)
    FourChart.setOption(FourOption, true);

    const fiveTimeData: any[] = []
    const fiveStandardData: any[] = []
    const fiveActualData: any[] = []
    data.aeb.map((item: any) => {
      fiveTimeData.push(item.date)
      fiveStandardData.push(item.standard)
      fiveActualData.push(item.actual)
    })
    const FiveOption = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: 'AEB风险变化趋势'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: fiveTimeData
      },
      yAxis: { type: 'value', name: '报警次数' },
      series: [
        {
          name: '标准值',
          type: 'line',
          data: fiveStandardData
        },
        {
          name: 'AEB日出车报警次数',
          type: 'bar',
          data: fiveActualData
        }
      ]
    };
    const FiveChart = echarts.init(document.getElementById('FiveChart') as HTMLElement)
    FiveChart.setOption(FiveOption, true);

    const sixTimeData: any[] = []
    const sixStandardData: any[] = []
    const sixActualData: any[] = []
    data.tir.map((item: any) => {
      sixTimeData.push(item.date)
      sixStandardData.push(item.standard)
      sixActualData.push(item.actual)
    })
    const SixOption = {
      toolbox: {
        show: true,
        top: 20,
        feature: {
          dataView: {
            show: true,
            readOnly: false,
            title: '数据视图',
            lang: ['', '关闭', '刷新']
          },
        }
      },
      title: {
        left: 'center',
        text: '疲劳驾驶风险变化趋势'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: { left: 'right' },
      xAxis: {
        type: 'category',
        data: sixTimeData
      },
      yAxis: { type: 'value', name: '报警次数' },
      series: [
        {
          name: '标准值',
          type: 'line',
          data: sixStandardData
        },
        {
          name: '疲劳驾驶风险变化趋势',
          type: 'bar',
          data: sixActualData
        }
      ]
    };
    const SixChart = echarts.init(document.getElementById('SixChart') as HTMLElement)
    SixChart.setOption(SixOption, true);
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = limitDayRange(values, 31);
    if (newSearchParams) {
      getFleetRiskReport(newSearchParams);
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    values.remark = remarkContent
    const newSearchParams = limitDayRange(values, 30, false, true);
    return newSearchParams;
  }

  // 表格列显示
  const columnsOne: any = [
    {
      title: '评价指标',
      dataIndex: 'one',
      width: 110,
    },
    {
      title: '评分',
      dataIndex: 'two',
      width: 110,
      render: (text: any) => <span>{text}</span>,
      onCell: (aaa: any, index: any) => ({
        colSpan: index === 5 ? 3 : 1,
      }),
    },
    {
      title: '权重值 %',
      dataIndex: 'three',
      width: 110,
      onCell: (aaa: any, index: any) => {
        if (index == 5) {
          return { colSpan: 0 }
        }
      }
    },
    {
      title: '单项评分',
      dataIndex: 'four',
      width: 110,
      onCell: (aaa: any, index: any) => {
        if (index == 5) {
          return { colSpan: 0 };
        }
      }
    },
  ];

  const columnsTwo: any = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 110,
    },
    {
      title: 'LDW',
      dataIndex: 'ldw',
      width: 110,
    },
    {
      title: 'HMW',
      dataIndex: 'hmw',
      width: 110,
    },
    {
      title: 'FCW',
      dataIndex: 'fcw',
      width: 110,
    },
    {
      title: 'AEB',
      dataIndex: 'aeb',
      width: 110,
    },
    {
      title: '疲劳驾驶',
      dataIndex: 'tir',
      width: 110,
    },
    {
      title: '总分',
      dataIndex: 'total',
      width: 110,
    },
    {
      title: '安全排名',
      dataIndex: 'rank',
      width: 110,
    },
    {
      title: '备注',
      dataIndex: 'description',
      width: 110,
    },
  ];

  const columnsThree: any = [
    {
      title: '车牌号',
      dataIndex: 'plateNumber',
      width: 60,
    },
    {
      title: '日期',
      dataIndex: 'date',
      width: 60,
    },
    {
      title: '风险说明',
      dataIndex: 'content',
      width: 200,
    },
  ];

  const columns: any = [
    {
      title: '时间',
      dataIndex: 'time',
      width: 110,
      fixed: 'left',
      render: (value: string) => <RenderEllipsisColumn text={dayjs(value).format('YYYY-MM-DD HH:mm:ss')} />
    },
    {
      title: '车辆',
      dataIndex: 'plateNumber',
      width: 110,
      fixed: 'left',
    },
    {
      title: '运维类型',
      dataIndex: 'maintainType',
      width: 110,
      fixed: 'left',
    },
    {
      title: '干预类型',
      dataIndex: 'type',
      width: 110,
      fixed: 'left',
    },
    {
      title: '故障项',
      dataIndex: 'faults',
      width: 110,
      fixed: 'left',
    },
    {
      title: '运维明细',
      dataIndex: 'content',
      width: 140,
    },
  ];

  // 不同风险等级显示不同的颜色
  const riskGradeObj: any = {
    '高': 'dangerText',
    '中': 'warningText',
    '低': 'successText',
  }

  // 获取车队
  // const getCateAllCompanyData = async () => {
  //   const res = await cateAllCompanyRequest();
  //   console.log('获取车队res', res);
  //   const data = res.data?.data || [];
  //   //循环遍历data
  //   const aa:any  = []
  //   data.forEach((item: any) => {
  //     aa.push({ label:item.title,value:item.title})
  //     })
  //   setAllCompany(aa)
  // }


  // 车队选择
  const motorcadeSelect = async (value: any) => {
    const data = { cateName: value }
    const res = await motorcadeCarList(data);
    // const res = await cateAllCompanyRequest();
    const carOptions: { value: any; label: any; }[] = []
    res.data.data.map((item: any) => {
      const aaa = { value: item.id, label: item.plate_number }
      carOptions.push(aaa)
    })
    setcarOption(carOptions)
  }

  const remarkInput = (aaa: any) => {
    const word = aaa.target.value
    setRemarkContent(word)
  }

  return (
    <Card bordered={false} className={styles.fleetRisk}>
      <Form onFinish={onSearch} form={form} autoComplete="off" layout='vertical'>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="报告周期"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>

          </Col>
          <Col span={6}>
            <Form.Item
              label="车队选择"
              name="cateName"
              rules={[{ required: true, message: '请选择车队' }]}
            >
              <Select
                showSearch
                placeholder="请选择"
                style={{ width: '100%' }}
                options={allCompany}
                onChange={motorcadeSelect}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="车辆选择"
              name="carIds"
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="请选择"
                style={{ width: '100%' }}
                options={carOption}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="低风险阈值"
              name="lowThreshold"
              rules={[{ required: true, message: '请指定低风险阈值' }]}
              initialValue={80}
            >
              <InputNumber precision={0} min={1} max={100} placeholder='请指定低风险阈值' style={{ width: '100%' }} addonAfter="分" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item
                  label="中风险阈值"
                  name="middleThreshold"
                  rules={[{ required: true, message: '请指定中风险阈值' }]}
                  initialValue={60}
                >
                  <InputNumber
                    precision={0}
                    min={1}
                    max={100}
                    placeholder='请指定中风险阈值'
                    style={{ width: '100%' }}
                    addonAfter="分"
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item shouldUpdate>
              {() => (
                <div className={styles.searchBox}>
                  <Button type="primary" htmlType="submit">搜索</Button>
                  <ExportButton
                    url={fleetRiskReportExportRequestPath}
                    fileName={`${form.getFieldValue('cateName')}车队报告`}
                    getParamsFunc={getExportParams}
                    requestMethod="POST"
                    fileSuffix="docx"
                    permissionKey='/car-admin/fleet-risk/export'
                  />
                </div>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <div style={{ visibility: isEmpty(data) ? 'hidden' : 'visible' }}>
          <h2>车队综合评价</h2>
          <Descriptions bordered column={2}>
            <Descriptions.Item span={2} label="车队名称">{data?.cateName}</Descriptions.Item>
            <Descriptions.Item span={2} label="车队综合风险等级">
              <span className={riskGradeObj[data?.riskGrade]}>
                {`风险等级 ${data?.riskGrade}`}
              </span>
            </Descriptions.Item>
            <Descriptions.Item span={2} label="车队风险趋势">{data?.riskTrend}</Descriptions.Item>
            <Descriptions.Item label="车辆总数">{`${data?.carTotal}（辆）`}</Descriptions.Item>
            <Descriptions.Item label="风险车辆">{`${data?.carRiskTotal}（辆）`}</Descriptions.Item>
            <Descriptions.Item label="最长上线车辆">{data?.longestOnlineCar}</Descriptions.Item>
            <Descriptions.Item label="最短上线车辆">{data?.shortestOnlineCar}</Descriptions.Item>
            <Descriptions.Item label="运输最远车辆">{data?.longestDistanceCar}</Descriptions.Item>
            <Descriptions.Item label="运输最短车辆">{data?.shortestDistanceCar}</Descriptions.Item>
            <Descriptions.Item label="安全评分最高车辆">{data?.highestScoreCar}</Descriptions.Item>
            <Descriptions.Item label="安全评分最低车辆">{data?.lowestScoreCar}</Descriptions.Item>
            <Descriptions.Item label="疲劳驾驶最多车辆">{data?.highestTiredCar}</Descriptions.Item>
            <Descriptions.Item label="车队最经济车辆">{data?.highestEfficientCar}</Descriptions.Item>
          </Descriptions>
          <h2>车队评价详情</h2>
          <h4>车队评价依据</h4>
          <CommonTable
            columnFilter={false}
            rowKey="one"
            columns={columnsOne}
            dataSource={carEvaluate}
            pagination={false}
            bordered
            loading={loading}
            scroll={{ y: 400 }}
          />
          <h2>车队风险预警周期对比分析</h2>
          <div id="OneChart" style={{ height: '400px', marginTop: '12px' }} />
          <h2>本周期车队日风险变化趋势</h2>
          <div id="TwoChart" style={{ height: '400px', marginTop: '12px' }} />
          <div id="ThreeChart" style={{ height: '400px', marginTop: '12px' }} />
          <div id="FourChart" style={{ height: '400px', marginTop: '12px' }} />
          <div id="FiveChart" style={{ height: '400px', marginTop: '12px' }} />
          <div id="SixChart" style={{ height: '400px', marginTop: '12px' }} />
          <h2>车辆评价详情</h2>
          <h4>车辆评分结果</h4>
          <CommonTable
            columnFilter={false}
            rowKey="rank"
            columns={columnsTwo}
            dataSource={carScore}
            pagination={false}
            bordered
            loading={loading}
            scroll={{ y: 400 }}
          />
          <h4>车辆日运行说明</h4>
          <CommonTable
            columnFilter={false}
            rowKey="content"
            columns={columnsThree}
            dataSource={carDayOperation}
            pagination={false}
            bordered
            loading={loading}
            scroll={{ y: 400 }}
          />
          <h2> </h2>
          <h4>运维汇总</h4>
          <Descriptions bordered column={2}>
            <Descriptions.Item span={2} label="运维总次数">{data?.maintainsT}</Descriptions.Item>
            <Descriptions.Item label="线上干预次数">{data?.maintainsO}</Descriptions.Item>
            <Descriptions.Item label="线下干预次数">{data?.maintainsA}</Descriptions.Item>
          </Descriptions>
          <h4>运维记录</h4>
          <CommonTable
            columnFilter={false}
            rowKey="plateNumber"
            columns={columns}
            dataSource={carMaintains}
            pagination={false}
            bordered
            loading={loading}
            scroll={{ y: 400 }}
          />
        </div>
        <h2>备注</h2>
        <TextArea rows={4} onChange={remarkInput} />


      </LoadingWrapper>
    </Card>
  )
}

export default FleetRisk;