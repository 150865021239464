/**
 * 记录仪视频预览弹窗
 */
import React, { Fragment, useState } from 'react';
import { Modal, Button, message } from 'antd';
import { downloadFile } from '@/utils/download';
import { changeUrlProtocol } from '@/utils/common';
import { implantSubtitlesRequest } from '@/service/driveAnalysis';
import CommentVideoModal from '@/pages/driveVideo/components/commentVideoModal';
import CommentVideoDetail from '@/pages/driveVideo/components/commentVideoDetail';
import styles from './index.module.scss';

interface RecorderVideoPreviewModalProps {
  url: string; // 播放资源url
  fileName: string; // 名称
  closeModal?: () => void; // 关闭弹窗的方法
  showDownload?: boolean; // 是否显示下载按钮
  showTransferButton?: boolean; // 是否显示转码按钮
  resourceKey?: string; // 添加字幕时使用的key
  transferCallback?: Function; // 转码之后的回调方法
  showTag?: boolean; // 是否显示视频标签
  showComment?: boolean; // 是否展示评价按钮
  title?: string; // 视频标题
  errorMessage?: string; // 视频播放失败的信息
  commentCallback?: Function; // 评论之后的回调方法
  needTrans: boolean; // 是否需要转码
  rawUrl: string; // 转码前的原始视频
  showModal?: boolean; // 是否显示弹窗
}
const RecorderVideoPreviewModal = (props: RecorderVideoPreviewModalProps) => {
  const {
    url,
    closeModal,
    fileName = '视频',
    showDownload = true,
    showTransferButton,
    resourceKey,
    transferCallback,
    showTag = false,
    showComment = false,
    title,
    errorMessage,
    commentCallback,
    needTrans,
    rawUrl,
    showModal = true
  } = props;
  const [downloadLoading, setDownloadLoading] = useState(false); // 下载按钮的loading
  const [downloadOriginLoading, setDownloadOriginLoading] = useState(false); // 下载原始视频按钮的loading
  const [transferLoading, setTransferLoading] = useState(false); // 添加字幕按钮的loading
  const [videoUrl, setVideoUrl] = useState(changeUrlProtocol(url)); // 播放的url
  const [showTransfer, setShowTransfer] = useState(showTransferButton); // 是否展示添加字幕按钮
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的信息存储
  const [refreshComment, setRefreshComment] = useState<number>(1); // 重新获取评论的标识
  const [showDownloadOrigin, setShowDownloadOrigin] = useState(!needTrans); // 是否显示下载原始视频按钮

  // 视频添加字幕
  const transferVideo = async () => {
    try {
      setTransferLoading(true);
      const res = await implantSubtitlesRequest(resourceKey as string);
      if (res.data?.code === 0) {
        const resData = changeUrlProtocol(res.data.data);
        // 转码成功之后更新url，显示下载按钮，不显示添加字幕按钮
        setVideoUrl(resData);
        setShowTransfer(false);
        setShowDownloadOrigin(true);
        message.success('添加字幕成功');
        transferCallback && transferCallback(resData);
      }
      setTransferLoading(false);
    } catch (error) {
      setTransferLoading(false);
    }
  };

  // 下载视频
  const downloadVideo = async (url: string, name: string, type?: string) => {
    const matchFunc =
      type === 'origin' ? setDownloadOriginLoading : setDownloadLoading;
    try {
      matchFunc(true);
      await downloadFile(url, name);
      matchFunc(false);
    } catch (error) {
      matchFunc(false);
    }
  };

  // 视频播放区域内容
  const renderVideoContent = () => (
    <Fragment>
      {/* 视频播放失败原因 */}
      {Boolean(errorMessage) && (
        <div className={styles.transferErrorTitle}>{errorMessage}</div>
      )}
      {Boolean(title) && <div className={styles.transferTitle}>{title}</div>}
      <div className={styles.transferTitle}>
        {/* 视频需要转码时的提示 */}
        {showTransfer ? (
          <div>建议您先添加字幕后再进行视频的播放和下载</div>
        ) : (
          <div />
        )}
        {/* 视频可以评价时的按钮 */}
        {showComment && (
          <Button
            type="primary"
            onClick={() => setOperateInfo({ type: 'comment' })}
          >
            评价
          </Button>
        )}
      </div>
      <video width="100%" height="240" controls src={videoUrl}>
        您的浏览器不支持 video 标签。
      </video>
      {/* 显示添加字幕按钮 */}
      {showTransfer && (
        <Button
          loading={transferLoading}
          onClick={transferVideo}
          style={{ marginRight: '8px' }}
        >
          添加字幕
        </Button>
      )}
      {showDownload && (
        <Button
          type="primary"
          onClick={() => downloadVideo(videoUrl, `${fileName}.mp4`)}
          loading={downloadLoading}
          style={{ marginRight: '8px' }}
        >
          下载
        </Button>
      )}
      {showDownloadOrigin && (
        <Button
          onClick={() =>
            downloadVideo(
              changeUrlProtocol(rawUrl),
              `${fileName}.mp4`,
              'origin'
            )
          }
          loading={downloadOriginLoading}
        >
          下载原始视频
        </Button>
      )}
      {/* 标签评论相关内容 */}
      {showTag && (
        <CommentVideoDetail
          resourceKey={resourceKey as string}
          refreshFlag={refreshComment}
        />
      )}
      {/* 视频评价的弹窗 */}
      {operateInfo.type === 'comment' && (
        <CommentVideoModal
          closeModal={() => setOperateInfo({})}
          resourceKey={resourceKey as string}
          reloadData={() => {
            setRefreshComment(refreshComment + 1);
            commentCallback && commentCallback();
          }}
        />
      )}
    </Fragment>
  );

  if (showModal) {
    return (
      <Modal
        title="视频播放"
        open
        footer={null}
        onCancel={closeModal}
        centered
        maskClosable={false}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      >
        {renderVideoContent()}
      </Modal>
    );
  } else {
    return renderVideoContent();
  }
};

export default RecorderVideoPreviewModal;
