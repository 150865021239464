/**
 * 记录仪流控配置弹窗
 */
import React, { useEffect } from 'react';
import { Modal, Form, InputNumber, Switch, message, Select } from 'antd';
import { recorderSensitivity } from '@/constants/parameterConfiguration';
import { saveRecorderFlowConfigurationRequest } from '@/service/recorderFlowConfiguration';

interface OperateModalProps {
  data: any; // 数据
  editIndex?: any; // 编辑规则的index
  type: string; // 添加还是编辑
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 操作成功后重新获取规则列表的方法
}

const OperateModal = (props: OperateModalProps) => {
  const { data, editIndex, type, closeModal, reloadData } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (type === 'edit') {
      setOperateValues();
    }
  }, []);

  // 编辑时赋值
  const setOperateValues = async () => {
    const formValues = { ...data[editIndex] };
    formValues.threshold = data[editIndex].threshold / 1024 / 1024;
    form.setFieldsValue(formValues);
  };

  // 配置记录仪流控规则
  const saveRecorderFlowConfiguration = async (values: any) => {
    const params = { ...values };
    params.fcwEnable = Boolean(values.fcwEnable);
    params.hmwEnable = Boolean(values.hmwEnable);
    params.ldwEnable = Boolean(values.ldwEnable);
    params.aebEnable = Boolean(values.aebEnable);
    params.threshold = values.threshold * 1024 * 1024;
    const saveValues = [...data];
    if (type === 'edit') {
      params.id = data[editIndex].id;
      saveValues.splice(editIndex, 1, params);
    } else {
      saveValues.push(params);
    }
    const res = await saveRecorderFlowConfigurationRequest(saveValues);
    if (res.data?.code === 0) {
      message.success('配置成功');
      closeModal();
      reloadData();
    }
  };

  return (
    <Modal
      title="记录仪流控配置"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelWrap
        onFinish={saveRecorderFlowConfiguration}
      >
        <Form.Item
          label="当月消耗流量阈值"
          name="threshold"
          rules={[{ required: true, message: '当月消耗流量阈值' }]}
        >
          <InputNumber
            min={1}
            precision={0}
            style={{ width: '100%' }}
            addonAfter="MB"
          />
        </Form.Item>
        <Form.Item
          label="灵敏性"
          name="sensitivity"
          rules={[{ required: true, message: '请选择灵敏性' }]}
        >
          <Select options={recorderSensitivity} />
        </Form.Item>
        <Form.Item
          label="拍摄时长"
          name="duration"
          rules={[{ required: true, message: '请输入拍摄时长' }]}
        >
          <InputNumber
            placeholder="1 ~ 255"
            min={1}
            max={255}
            style={{ width: '100%' }}
            addonAfter="秒"
          />
        </Form.Item>
        <Form.Item label="FCW触发开关" name="fcwEnable" valuePropName="checked">
          <Switch checkedChildren="开" unCheckedChildren="关" />
        </Form.Item>
        <Form.Item label="HMW触发开关" name="hmwEnable" valuePropName="checked">
          <Switch checkedChildren="开" unCheckedChildren="关" />
        </Form.Item>
        <Form.Item label="LDW触发开关" name="ldwEnable" valuePropName="checked">
          <Switch checkedChildren="开" unCheckedChildren="关" />
        </Form.Item>
        <Form.Item label="AEB触发开关" name="aebEnable" valuePropName="checked">
          <Switch checkedChildren="开" unCheckedChildren="关" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
