/**
 * 传感器方案通用表格配置
 */
import React from 'react';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import { formatTime } from '@/utils/formatTime';

// 表格列配置
export const sensorSchemeCommonColumns = [
  {
    title: '创建人',
    dataIndex: 'createrName',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={formatTime(value)} />
  },
  {
    title: '更新人',
    dataIndex: 'updaterName',
    width: 110,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={value} />
  },
  {
    title: '更新时间',
    dataIndex: 'updatedAt',
    width: 120,
    ellipsis: { showTitle: false },
    render: (value: string) => <RenderEllipsisColumn text={formatTime(value)} />
  }
];
