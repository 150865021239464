import {useEffect, useState} from "react";
import {tagListRequest} from "@/service/tagManage";

const useFuelType = () => {
  const [fuelType, setFuelType] = useState<any>([]); // 燃料类型
  //获取燃料类型
  const getFuelType = async () => {
    const res = await tagListRequest({tagSorts: [54]});
    const resData = res.data?.data || [];
    return resData.map((item: any) => {
      return {label: item.title, value: item.title}
    })
  }

  useEffect(() => {
    //获取燃料类型
    getFuelType()
      .then(res => setFuelType(res))
  }, []);

  return{
    fuelType
  }
}


export default useFuelType;