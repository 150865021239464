/**
 * @description: 生成树形结构
 * @param child
 */
export const getChildTypeByParentCode = (child: any) => {
  return {
    label: child.name,
    value: child.code
  }
}


/**
 * @description: 过滤根节点
 * @param resData
 */
export const fullTypeGen =(resData:any[])=>{
  return (item: any) => {
    return {
      label: item.name,
      value: item.code,
      children: resData
        .filter((child: any) => child.parentCode === item.code)
        .map(getChildTypeByParentCode)
    }
  }
}

/**
 * @description: 过滤根节点
 * @param item
 */
export const rootNodeFilter = (item: any) => !item.parentCode