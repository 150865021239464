import { alarmTypeData } from './alarm';
import { jtDeviceAlarmTypeData } from './jtDeviceAlarm';

// 驾驶行为的数据类型
export const driveBehaviorData = [
  { label: 'Gps信息', value: '1' },
  { label: '车身信息', value: '2' },
  // { label: '记录仪', value: '4' },
  ...alarmTypeData.map((item: any) => ({
    label: item.label,
    value: `3_${item.value}`
  }))
]

// 驾驶行为的数据类型所有value
export const driveBehaviorValue = driveBehaviorData.map(
  (item: any) => item.value
);

// 驾驶视频播放失败的原因
export const driveVideoErrorMessage: any = {
  255: '未知失败',
  254: '网络原因失败',
  253: '资源找不到(指定时间范围数据没有)',
  252: 'oss服务响应失败'
}

// 驾驶行为的报警数据类型所有value
export const driveBehaviorAlarmValue = alarmTypeData.map(
  (item: any) => `3_${item.value}`
);

// 驾驶行为的数据类型(包含部标机设备数据)
export const driveBehaviorDataWithJtDevice = [
  {
    label: 'GPS信息',
    value: 'gps',
    children: [
      { label: 'AEB设备GPS', value: '1' },
      { label: '视频机设备GPS', value: '6' }
    ]
  },
  {
    label: 'AEB设备',
    value: 'aeb',
    children: [
      { label: '车身信息', value: '2' },
      { label: '记录仪', value: '4' },
      ...alarmTypeData.map((item: any) => ({
        label: item.label,
        value: `3_${item.value}`
      }))
    ]
  },
  ...jtDeviceAlarmTypeData.map((item: any) => ({
    ...item,
    children: item.children.map((item1: any) => ({
      ...item1,
      value: `5_${item1.value}`
    }))
  }))
];

// 驾驶行为的数据类型所有value(包含部标机设备数据)
export const driveBehaviorValueWithJtDevice = driveBehaviorDataWithJtDevice
  .map((item: any) =>
    item.children.map((item1: any) => [item.value, item1.value])
  )
  .flat(1);
