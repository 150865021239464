/**
 * 车身类型弹窗
 */
import React from 'react';
import ParameterConfigurationModal from '@/components/parameterConfigurationModal';
import { carTypeFormConfig } from '@/constants/parameterConfiguration';
import {
  getCarTypeRequest,
  setCarTypeRequest
} from '@/service/parameterConfiguration';
import {
  getJtKlsCarTypeRequest,
  setJtKlsCarTypeRequest
} from '@/service/jtKlsParameterConfiguration';

interface CarTypeModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const CarTypeModal = (props: CarTypeModalProps) => {
  const {
    data: { isKlsV3, sn, deviceType },
    closeModal
  } = props;

  return (
    <ParameterConfigurationModal<number>
      operateParameterValue={sn}
      operateParameterField="sn"
      getParameterRequest={isKlsV3 ? getJtKlsCarTypeRequest : getCarTypeRequest}
      setParameterRequest={isKlsV3 ? setJtKlsCarTypeRequest : setCarTypeRequest}
      formConfig={carTypeFormConfig}
      closeModal={closeModal}
      modalTitle="车身类型"
      modalWidth={520}
      configKey="/car-admin/parameter-configuration/car-type-config"
      approveKey="/car-admin/parameter-configuration/car-type-config-approve"
      approveType="vehicleTypeConfig"
      labelColSpan={6}
      wrapperColSpan={18}
      operateSetFormValueFunc={(info: number) => ({ type: info })}
      deviceType={deviceType}
    />
  );
};

export default CarTypeModal;
