/**
 * 路口类型添加/编辑的表单
 */
import React, { Fragment } from 'react';
import { Form, InputNumber, Select } from 'antd';
import {
  angleLimit,
  intersectionType,
  speedLimit
} from '@/constants/electronFence';

const IntersectionOperateForm = () => (
  <Fragment>
    <Form.Item
      label="路口类型"
      name="type"
      rules={[{ required: true, message: '请选择路口类型' }]}
    >
      <Select options={intersectionType} placeholder="请选择路口类型" />
    </Form.Item>
    <Form.Item
      label="直行速度限制"
      name="straight"
      rules={[{ required: true, message: '请选择直行速度限制' }]}
    >
      <Select options={speedLimit} placeholder="请选择直行速度限制" />
    </Form.Item>
    <Form.Item
      label="左转速度限制"
      name="leftTurn"
      rules={[{ required: true, message: '请选择左转速度限制' }]}
    >
      <Select options={speedLimit} placeholder="请选择左转速度限制" />
    </Form.Item>
    <Form.Item
      label="右转速度限制"
      name="rightTurn"
      rules={[{ required: true, message: '请选择右转速度限制' }]}
    >
      <Select options={speedLimit} placeholder="请选择右转速度限制" />
    </Form.Item>
    <Form.Item
      label="转向角限制（方向盘）"
      name="angle"
      rules={[{ required: true, message: '请选择转向角限制（方向盘）' }]}
    >
      <Select options={angleLimit} placeholder="请选择转向角限制（方向盘）" />
    </Form.Item>
    <Form.Item
      label="斑马线上障碍物（行人）的距离限制"
      name="distance"
      rules={[
        {
          required: true,
          message: '请输入斑马线上障碍物（行人）的距离限制'
        },
        {
          type: 'number',
          min: 0,
          max: 51.1,
          message: `请输入指定区间的值(0 ~ 51.1)`
        }
      ]}
    >
      <InputNumber
        placeholder="请输入斑马线上障碍物（行人）的距离限制"
        style={{ width: '100%' }}
        addonAfter="米"
      />
    </Form.Item>
  </Fragment>
);

export default IntersectionOperateForm;
