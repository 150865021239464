/**
 * 组织添加/编辑
 */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Upload, Button } from 'antd';
import UploadImageVideo from '@/components/uploadImageVideo';
import { upsertOrganizationRequest } from '@/service/organizationManage';
import localStorageUtil, {
  LocalStorageKeyEnum
} from '@/utils/localStorageUtil';

interface OperateOrganizationModalProps {
  type: string; // 操作类型
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateOrganizationModal = (props: OperateOrganizationModalProps) => {
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [uploadSuccessList, setUploadSuccessList] = useState<any>([]); // 上传成功的图片信息

  // 编辑时赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 保存组织
  const saveOrganization = async(values: any) => {
    const params = {...values};
    if (type === 'edit') {
      params.id = data.id;
      params.parentId = data.parentId;
    } else {
      params.parentId = data?.id;
    }
    if (uploadSuccessList?.length) {
      params.iconUrl = uploadSuccessList[0].key;
    } else {
      message.error('请上传logo图标');
      return;
    }
    const res = await upsertOrganizationRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加组织' : '编辑组织'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={saveOrganization}
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="组织名称"
          name="groupName"
          rules={[{ required: true, message: '请输入组织名称' }]}
        >
          <Input placeholder="请输入组织名称" />
        </Form.Item>
        <Form.Item
          label="组织唯一key"
          name="groupKey"
          rules={[{ required: true, message: '请输入组织唯一key' }]}
        >
          <Input placeholder="请输入组织唯一key" />
        </Form.Item>
        <Form.Item
          label="页面Title"
          name="pageTitle"
        >
          <Input placeholder="请输入页面Title" />
        </Form.Item>
        <Form.Item
          label="数据大屏url"
          name="datavUrl"
        >
          <Input placeholder="请输入数据大屏url" />
        </Form.Item>
        <Form.Item label="logo图标" required>
          <UploadImageVideo
            businessName="logo"
            uploadFilesChange={(successList: any) => setUploadSuccessList(successList)}
            maxCount={1}
            defaultUploadFiles={type === 'add' ? [] : [{ contentType: "image/jpeg", key: new URL(data.iconUrl).pathname.slice(1), preview: data.iconUrl }]}
          />
        </Form.Item>
        <Form.Item label="车队风险评估报告模板">
          <Upload
            accept=".doc,.docx"
            action="/api/v2/resource/uploadSafeReport"
            headers={{
              access_token: localStorageUtil.getItem(
                LocalStorageKeyEnum.access_token
              )
            }}
          >
            <Button>上传模板文件</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default OperateOrganizationModal;
     