/**
 * 详细信息弹窗(驾驶行为分析)
 */
import React from 'react';
import { Modal } from 'antd';
import CommonTable from '@/components/commonTable';

interface DetailModalProps {
  data: any;
  closeModal: () => void;
}
const DetailModal = (props: DetailModalProps) => {
  const { data, closeModal } = props;
  let columns: any = []; // 表格列显示
  const tableData: any = [];
  columns = [
    {
      title: '名称',
      dataIndex: 'title'
    },
    {
      title: '',
      dataIndex: 'value'
    }
  ];
  const {
    aebs_on,
    device_status,
    signal_break_on,
    signal_left_on,
    signal_right_on,
    brake,
    throttle,
    gear_status,
    steering_wheel
  } = data?.info || {};
  tableData.push({
    title: 'AEB状态',
    value: Number(aebs_on) === 0 ? '关' : '开'
  });
  tableData.push({
    title: '设备状态',
    value: Number(device_status) === 0 ? '正常' : device_status
  });
  tableData.push({
    title: '刹车灯',
    value: Number(signal_break_on) === 0 ? '关' : '开'
  });
  tableData.push({
    title: '左转向灯',
    value: Number(signal_left_on) === 0 ? '关' : '开'
  });
  tableData.push({
    title: '右转向灯',
    value: Number(signal_right_on) === 0 ? '关' : '开'
  });
  tableData.push({ title: '刹车开度', value: brake });
  tableData.push({ title: '油门开度', value: throttle });
  tableData.push({
    title: '档位',
    value: Number(gear_status) === 0 ? '无效' : gear_status
  });
  tableData.push({ title: '方向盘角度', value: steering_wheel });

  return (
    <Modal
      title="车身详细信息"
      open
      footer={null}
      onCancel={closeModal}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      centered
    >
      <CommonTable
        columnFilter={false}
        size="small"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        bordered
        scroll={{}}
      />
    </Modal>
  );
};

export default DetailModal;
