/**
 * 用户选择器
 */
import React, { useEffect, useState } from 'react';
import { Select, SelectProps } from 'antd';
import { omit } from 'lodash';
import { userListRequest } from '@/service/userManage';

interface UserSelectProps extends SelectProps {
  value?: string; // 选择的用户
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField?: string; // 作为选择器value的字段
}
const UserSelect = (props: UserSelectProps) => {
  const { value, onChange, valueField } = props;
  const [sensorProjectList, setSensorProjectList] = useState<any>([]); // 用户列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>(); // 选择的用户

  // 获取用户数据
  const getUserList = async () => {
    const res = await userListRequest({
      page: 1,
      limit: 999
    });
    setSensorProjectList(res.data?.data || []);
  };

  useEffect(() => {
    getUserList();
  }, []);

  // 监听选择的用户进行赋值
  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const selectOwnProps = omit(props, 'valueField');
  return (
    <Select
      {...selectOwnProps}
      fieldNames={{
        label: 'nickname',
        value: valueField || 'id'
      }}
      optionFilterProp="nickname"
      allowClear
      showSearch
      options={sensorProjectList}
      onChange={onChange}
      value={selectValue}
      placeholder="请选择"
    />
  );
};

export default UserSelect;
