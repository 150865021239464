// extracted by mini-css-extract-plugin
export default {"primaryColor":"#1890ff","backColor":"#f0f2f5","buttonSpacing":"8px","textButtonSpacing":"4px","formTableBox":"formTable_formTableBox__VrWHW","titleBox":"formTable_titleBox__x2evF","itemTitleBox":"formTable_itemTitleBox__PtkRJ","itemContentBox":"formTable_itemContentBox__RupJc"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"primaryColor\":\"#1890ff\",\"backColor\":\"#f0f2f5\",\"buttonSpacing\":\"8px\",\"textButtonSpacing\":\"4px\",\"formTableBox\":\"formTable_formTableBox__VrWHW\",\"titleBox\":\"formTable_titleBox__x2evF\",\"itemTitleBox\":\"formTable_itemTitleBox__PtkRJ\",\"itemContentBox\":\"formTable_itemContentBox__RupJc\"}";
        // 1726639070502
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  