/**
 * 车辆报警情况
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import { getInnerPermissionEnable } from '@/utils/permission';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  ToolboxComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { carAlarmRequest, CarAlarmParams, carAlarmRequestPath } from '@/service/carAlarm';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import CommonTable from '@/components/commonTable';
import CarStatusListModal from '@/components/carStatusListModal';
import OperationGroupSelect from '@/components/operationGroupSelect';
import { limitDayRange } from '@/utils/operateSearchParams';
import { useUserCateIdArr } from '@/utils/ownHooks';
import styles from '@/styles/pageContent.module.scss';
echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  ToolboxComponent,
  UniversalTransition
]);
const { RangePicker } = DatePicker;
const CarAlarm = () => {
  const cateIdArr = useUserCateIdArr();
  const [data, setData] = useState([]); // 数据
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(1, 'month').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    cateId: cateIdArr,
  }); // 搜索相关的参数
  const [loading, setLoading] = useState(false);
  const [carModalData, setCarModalData] = useState<any>(null); // 在线弹窗的相关数据
  const [form] = Form.useForm();

  // 获取数据
  const getData = async (params: CarAlarmParams) => {
    try {
      setLoading(true);
      const res = await carAlarmRequest(params);
      const alarmData = res.data?.data || [];
      const xData = [];
      const onlineCounts = [];
      const totalCounts = [];
      const fcwCounts = [];
      const hmwCounts = [];
      const ldwCounts = [];
      const aebCounts = [];
      const stsCounts = [];
      const binocular = [];
      const millimeter = [];
      const ultrasonic = [];
      for (const item of alarmData) {
        xData.push(item.day)
        onlineCounts.push(item.onlineCount)
        totalCounts.push(item.totalCount)
        fcwCounts.push(item.fcwCount)
        hmwCounts.push(item.hmwCount)
        ldwCounts.push(item.ldwCount)
        aebCounts.push(item.aebCount)
        stsCounts.push(item.stsCount)
        binocular.push(item.binocularCount)
        millimeter.push(item.millimeterCount)
        ultrasonic.push(item.ultrasonicCount)
      }
      const chartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          },
        },
        legend: {
          formatter: (name: string) => {
            if (name === '总报警数') {
              return name +'(当日所有类型的百公里报警数量)';
            }
            if (name === 'FCW报警数量') {
              return name +'(当日FCW类型的百公里报警数量)';
            }
            if (name === 'HMW报警数量') {
              return name +'(当日HMW类型的百公里报警数量)';
            }
            if (name === 'LDW报警数量') {
              return name +'(当日LDW类型的百公里报警数量)';
            }
            if (name === '起步阻止报警数量') {
              return name +'(当日起步阻止报警类型的百公里报警数量)';
            }
            if (name === 'AEB双目制动') {
              return name +'(当日AEB双目制动报警类型的百公里报警数量)';
            }
            if (name === 'AEB毫米波制动') {
              return name +'(当日AEB毫米波制动报警类型的百公里报警数量)';
            }
            if (name === 'AEB超声波制动') {
              return name +'(当日AEB超声波制动报警类型的百公里报警数量)';
            }
            return name;
          }
        },
        grid: { top: '30%' },
        calculable: true,
        xAxis: { type: 'category', data: xData },
        yAxis: { type: 'value' },
        series: [
          {
            data: totalCounts,
            type: 'line',
            name: '总报警数',
            label: { show: true, position: 'top' },
          },
          {
            data: fcwCounts,
            type: 'bar',
            name: 'FCW报警数量',
            stack: 'alarm',
          },
          {
            data: hmwCounts,
            type: 'bar',
            name: 'HMW报警数量',
            stack: 'alarm',
          },
          {
            data: ldwCounts,
            type: 'bar',
            name: 'LDW报警数量',
            stack: 'alarm',
          },
          {
            data: stsCounts,
            type: 'bar',
            name: '起步阻止报警数量',
            stack: 'alarm',
          },
          {
            data: binocular,
            type: 'bar',
            name: 'AEB双目制动',
            stack: 'alarm',
          },
          {
            data: millimeter,
            type: 'bar',
            name: 'AEB毫米波制动',
            stack: 'alarm',
          },
          {
            data: ultrasonic,
            type: 'bar',
            name: 'AEB超声波制动',
            stack: 'alarm',
          },
        ]
      };
      setData(alarmData);
      const carAlarmChart = echarts.init(document.getElementById('carAlarmChart') as HTMLElement)
      carAlarmChart.setOption(chartOption, true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = limitDayRange(values, 31);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      getData({
        page: 1,
        limit: 40,
        ...newSearchParams, 
      });
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return limitDayRange(values, 31, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  // 渲染在线车辆列
  const renderOnlineColumn = (value: any, record: any) => {
    const { carIds, day } = record;
    return (
      value ? (
        getInnerPermissionEnable("/car-admin/car-alarm/show-detail")?
        <Button 
        type='link'
        onClick={() => setCarModalData({
          carIds,
          exportFileName: '在线车辆',
          day,
        })}
      >
        {value}
      </Button>:   <div > {value}</div>
      )
     : 
      <Button type='text'>0</Button>
    )
  }

  // 表格列显示
  const columns = [
    {
      title: '日期',
      dataIndex: 'day',
      width: 110,
    },
    {
      title: '在线数量',
      dataIndex: 'onlineCount',
      width: 110,
      render: (value: any, record: any) => renderOnlineColumn(value, record),
    },
    {
      title: '总报警数量',
      dataIndex: 'totalCount',
      width: 120,
    },
    {
      title: 'FCW数量',
      dataIndex: 'fcwCount',
      width: 110,
    },
    {
      title: 'AEB数量',
      dataIndex: 'aebCount',
      width: 110,
    },
    {
      title: 'AEB双目制动',
      dataIndex: 'binocularCount',
      width: 140,
    },
    {
      title: 'AEB毫米波制动',
      dataIndex: 'millimeterCount',
      width: 140,
    },
    {
      title: 'AEB超声波制动',
      dataIndex: 'ultrasonicCount',
      width: 140,
    },
    {
      title: '起步阻止数量',
      dataIndex: 'stsCount',
      width: 140,
    },
    {
      title: 'LDW数量',
      dataIndex: 'ldwCount',
      width: 110,
    },
    {
      title: 'HMW数量',
      dataIndex: 'hmwCount',
      width: 110,
    },
    {
      title: '修正后HMW数量',
      dataIndex: 'hmwHumanCount',
      width: 140,
    },
  ];

  return (
    <Card bordered={false} className={styles.pageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={24} wrap>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/car-alarm/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={carAlarmRequestPath}
                  fileName='车辆报警分布情况'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/car-alarm/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <div id="carAlarmChart" style={(data.length || loading) ? { height: '400px'} : {}} />
      </LoadingWrapper>
      <CommonTable
        rowKey="day"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        scroll={{ x: '100%', y: 500 }}
      />
      {Boolean(carModalData) &&
        <CarStatusListModal
          carIds={carModalData.carIds}
          day={carModalData.day}
          closeModal={() => setCarModalData(null)}
          exportFileName={carModalData.exportFileName}
          beginDate={searchParams.beginDate}
          endDate={searchParams.endDate}
          exportPermissionKey="/car-admin/car-alarm/online-car-export"
          errTypeLength = {0}
        />
      }
    </Card>
  )
}

export default CarAlarm;
  