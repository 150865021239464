/**
 * 用户观看记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import CommonTable from '@/components/commonTable';
import DatePicker from '@/components/datePicker';
import { useTable } from '@/utils/useTable';
import { videoRequest } from '@/service/monitoringRecord'
import styles from '@/styles/pageContent.module.scss';

const { RangePicker } = DatePicker;
const monitoringClientRecord = () => {
    const { userDetail } = useSelector((state: any) => state.userStore);

    const [searchParams, setSearchParams] = useState({
        beginDate: `${dayjs().subtract(6, 'day').format('YYYY-MM-DD')}`,
        endDate: `${dayjs().format('YYYY-MM-DD')}`,
        userId: userDetail.id
    }); // 搜索相关的参数

    const [form] = Form.useForm();
    const { page, limit, loading, data, totalCount, onPageChange, getData } = useTable({
        requestFunc: videoRequest,
        initRequest: true,
        initPageSize: 40,
        searchParams,
    });

    // 编辑的时候赋值
    useEffect(() => {
      
    }, []);

    // 搜索  
    const onSearch = async (values: any) => {
        const newSearchParams = { ...values };
        newSearchParams.beginDate = dayjs(values.day[0]).format('YYYY-MM-DD');
        newSearchParams.endDate = dayjs(values.day[1]).format('YYYY-MM-DD');
        newSearchParams.userId = userDetail.id;
        delete newSearchParams.day;
        setSearchParams(newSearchParams);
        getData(newSearchParams)
    };



    // 表格列显示
    const columns = [
        {
            title: '车辆',
            dataIndex: 'plateNumber',
            width: 110,
        },
        {
            title: '开始时间',
            dataIndex: 'beginTime',
            width: 110,
            render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            width: 110,
            render: (value: string) => value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : value,

        },
        {
            title: '观看时长(s)',
            dataIndex: 'duration',
            width: 110,
        }
    ]
    return (
        <Card bordered={false} className={styles.fixPageContentBox}>
            <Form onFinish={onSearch} form={form} layout="vertical" autoComplete="off">
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="日期"
                            name="day"
                            initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}>
                            <RangePicker allowClear={false} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="&nbsp;">
                            <div className={styles.searchBox}>
                                <Button type="primary" htmlType="submit">搜索</Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: totalCount,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                }}
                bordered
                loading={loading}
            />

        </Card>
    )
}

export default monitoringClientRecord;
