/**
 * 调查问卷汇总相关请求
 */
import request from "@/utils/request";

// 总分
export const questionnaireTotalRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/total', {params})
}

export const questionnaireCompanyPath = '/api/v2/questionnaire/statistic/company';
// 车队打分汇总排名
export const questionnaireCompanyRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/company', {params})
}

export const questionnairePlateNumberPath = '/api/v2/questionnaire/statistic/plateNumber';
// 车辆评分汇总排名
export const questionnairePlateNumberRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/plateNumber', {params})
}

export const questionnaireMaintainerPath = '/api/v2/questionnaire/statistic/maintainer';
// 安装人员评分汇总排名
export const questionnaireMaintainerRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/maintainer', {params})
}

export const questionnaireQuestionItemPath = '/api/v2/questionnaire/statistic/questionItem';
// 调查问题项分布情况
export const questionnaireQuestionItemRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/questionItem', {params})
}

export const questionnaireSuggestionPath = '/api/v2/questionnaire/statistic/suggestion';
// 意见汇总
export const questionnaireSuggestionRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/suggestion', {params})
}

// 车队得分饼图
export const chartModalCompanyRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/company/score', {params})
}

// 车辆得分饼图
export const chartModalPlateNumberRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/plateNumber/score', {params})
}

// 安装人员得分饼图
export const chartModalMaintainerRequest = (params?: any) => {
  return request.get('/api/v2/questionnaire/statistic/maintainer/score', {params})
}
