/**
 * 合同管理相关请求
 */
import request from "@/utils/request";
export interface ContractListRequestParams {
  page: number;
  limit: number;
  model?: string;
  no?: any;
  state?: string;
  name?: string;
  plateNumber?: string;
  frameNumber?: string;
  caseFlag?: number;
  beginTime?: string;
  endTime?: string;
}

export interface CarProceedListParams {
  no?:any;
  frameNumber?:any;
  caseNo?:any;
  deviceContractNo?:any;
}


export const contractListRequestPath = "/api/v2/contract/list";

// 获取合同管理
export const contractListRequest = (params: ContractListRequestParams) => {
  return request.get(contractListRequestPath, {
    params,
  });
};

// 添加/编辑合同管理
export const upsertContract = (data: any) => {
  return request.post("/api/v2/contract/upsert", data);
};

// 删除合同管理
export const DeleteContract = (id: number) => {
  return request.delete(`/api/v2/contract/${id}`)
}


// 获取查询诉讼记录 
export const carProceedListRequestPath = "/api/v2/carProceed/list";
export const carProceedListRequest = (params:CarProceedListParams ) => {
  return request.get(carProceedListRequestPath, {
    params
  })
}

//保存诉讼记录
export const upsertCarProceedRequest = (data: any) => {
  return request.post('/api/v2/carProceed/upsert', data)
}


// 批量导入
export const importContractRequestPath = '/api/v2/contract/import';

// 拷贝合同管理
export const contractDetailRequestPath = "/api/v2/contract/detail";
export const contractDetailRequest = (params: ContractListRequestParams) => {
  return request.get(contractDetailRequestPath, {
    params,
  });
};
