import ClipboardJS from 'clipboard';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import localStorageUtil from './localStorageUtil';
import request from "@/utils/request";

// 返回一个promise函数的debounce
export const promiseDebounce = (fun: Function, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    return new Promise((resolve) => {
      timer = setTimeout(() => {
        clearTimeout(timer);
        resolve(fun.apply(null, args));
      }, delay);
    });
  };
}

// 获取消息列表
export const getInformationListRequest = () => {
  return request.get('/api/v2/message/list')
}

// 已读消息
export const upsertInformationListRequest = (data: any) => {
  return request.post('/api/v2/message/upsert', data)
}

// 切换客服在线情况
export const upsertserviceOnLineRequest = (data: any) => {
  return request.post('api/v2/customer/upsert', data)
}

// 获取报警信息实时状态推送的WebSocket的path
export const getWebSocketPath = (path: string, hasToken?: boolean) => {
  const token = localStorageUtil.getItem('access_token');
  // 区分开发环境
  if (process.env.NODE_ENV === 'development') {
    let socketPath = `ws://dev.anchezhixing.com${path}`;
    if (hasToken) {
      socketPath = `${socketPath}?access_token=${token}`;
    }
    return socketPath;
  }
  // 生产环境根据域名来确定地址
  let { protocol, hostname } = window.location;
  // hostname = 'iov.anchezhixing.com'
  let socketPath = `${
    protocol.startsWith('https') ? 'wss' : 'ws'
  }://${hostname}${path}`;
  if (hasToken) {
    socketPath = `${socketPath}?access_token=${token}`;
  }
  return socketPath;
};

// 判断是否为生产环境
export const isProd = process.env.NODE_ENV === 'production';

// 将url的协议修改为当前平台的协议
export const changeUrlProtocol = (url: string) => url.replace(/^https?:/g, window.location.protocol);

// 获取页面Title
export const getPageTitle = (userDetail: any) => {
  // 从用户信息中读取页面Title，防止获取用户信息过程页面Title更换闪烁
  if (isEmpty(userDetail)) {
    return '';
  } else {
    return userDetail?.pageTitle || '风险减量运维管理系统'
  }
}

export  const addCheck = (data: any) => {
  if (data?.children?.length) {
    data?.children?.forEach((item: any) => {
      item.isChecked = true
      if (item?.children?.length) {
        addCheck(item)
      }
    })
  }
  return data
}

// 根据搜索内容过滤tree形式数据
export const filterTreeData = (
  data: any,
  judgeValue: any,
  judgeKey: string,
  callback?: (item: any) => void
) => {
  const newData: any = [];
  const valueList = judgeValue.replace('，', ',').split(',')
  valueList.forEach((judgeValueInner: any) => {
    judgeValueInner && data.forEach((item: any) => {
      let obj: any = {};
      if (item[judgeKey]?.includes(judgeValueInner)) {
        obj = {
          ...item,
          isChecked: true
        };
        newData.push(addCheck(obj));
        
      } else if (Array.isArray(item.children) && item.children.length > 0) {
        const tempData = filterTreeData(
          item.children,
          judgeValueInner,
          judgeKey,
          callback
        );
        obj = {
          ...item,
          children: tempData
        };
        // 当children不为空时才保留该数据
        if (obj.children.length > 0) {
          newData.push(obj);
        }
      }
    });
  })
  callback && callback(newData);
  return newData;
};

// 注册复制粘贴事件(防止DOM暂时找不到的问题)
export const registerClipboard = (id: string) => {
  const copyBtn = document.getElementById(id);
  if (copyBtn) {
    const clipboard = new ClipboardJS(copyBtn as HTMLElement);
    clipboard.on('success', () => {
      message.success('复制成功');
    });
    clipboard.on('error', () => {
      message.error('复制失败');
    });
  } else {
    setTimeout(() => {
      registerClipboard(id);
    }, 500);
  }
};

// 渲染和之前数据不同的单元格的背景色(当前值与之前值不同时单元格标黄)
export const renderDifferentTableCell = (
  rowData: any,
  rowIndex: number,
  field: string,
  allData: any,
  formatValueFunc?: Function
) => {
  const nextRowData = allData[rowIndex + 1];
  if (nextRowData) {
    const nextRowValue = formatValueFunc
      ? formatValueFunc(nextRowData[field])
      : nextRowData[field];
    const rowValue = formatValueFunc
      ? formatValueFunc(rowData[field])
      : rowData[field];
    if (nextRowValue !== rowValue) {
      return { className: 'warningTableCell' };
    }
  }
  return {};
};

// 获取tree形式数据中所有节点数据
export const getTreeAllNode = (treeData: any, labelList?: any) => {
  if (Array.isArray(treeData) && treeData.length > 0) {
    const allData: any = [];
    const deep = (innerData: any) => {
      innerData.forEach((item: any) => {
        if (labelList) {
          // push新的键值对数据
          const obj: any = {};
          labelList.forEach((labelPair: any) => {
            obj[labelPair[0]] = item[labelPair[1]];
          });
          allData.push(obj);
        } else {
          // push当前项
          allData.push(item);
        }
        if (Array.isArray(item.children) && item.children.length > 0) {
          deep(item.children);
        }
      });
    };
    deep(treeData);
    return allData;
  } else {
    return [];
  }
};

