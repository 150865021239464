/**
 * 记录仪流控配置页面相关请求
 */
import request from '@/utils/request';

// 获取记录仪流控配置
export const getRecorderFlowConfigurationRequest = () => {
  return request.get('/api/control/modem/throttle/rules');
};

// 保存记录仪流控配置
export const saveRecorderFlowConfigurationRequest = (data: any) => {
  return request.post('/api/control/modem/throttle/saveRules', data);
};
