/**
 * 传感器方案-供应商管理相关请求
 */
import request from '@/utils/request';

export interface SensorSupplierListParams {
  page: number;
  limit: number;
  name?: string;
  componentTypeId?: number;
  userId?: number;
  beginTime?: string;
  endTime?: string;
}
// 获取供应商管理列表
export const sensorSupplierListRequestPath = '/api/v2/supplier/list';
export const sensorSupplierListRequest = (params: SensorSupplierListParams) => {
  return request.get(sensorSupplierListRequestPath, {
    params
  });
};

export interface UpsertSensorSupplierParams {
  name: string;
  componentTypeId: number;
  ps?: string;
  id?: number;
}
// 添加、编辑供应商
export const upsertSensorSupplierRequest = (
  data: UpsertSensorSupplierParams
) => {
  return request.post('/api/v2/supplier/upsert', data);
};

// 删除供应商
export const deleteSensorSupplierRequest = (id: number) => {
  return request.delete(`/api/v2/supplier/${id}`);
};
