/**
 * Tab形式的页面Tab数据的存储
 */
interface ActionType {
  type: string;
  payload?: any
}

interface InitStateType {
  tabPages: any;
  activeKey: string;
  tabOrder: any;
  tabParams: any;
}
const initState: InitStateType = {
  tabPages: [], // 要展示的所有tab
  activeKey: '', // 当前激活的tab
  tabOrder: [], // tab访问顺序(最后访问的在后)
  tabParams: null, // tab存储的参数(用于页面间跳转的参数携带)
}

export const tabPagesStore = (state = initState, action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    // 初始化tab
    case 'initTab':
      return {
        tabPages: [payload],
        activeKey: payload.key,
        tabOrder: [payload.key],
        tabParams: null,
      }
    // 添加tab
    case 'addTab':
      if (state.tabPages.find((item: any) => item.key === payload.key)) {
        const filterTabOrder = state.tabOrder.filter(((item: any) => item !== payload.key));
        filterTabOrder.push(payload.key);
        return {
          ...state,
          activeKey: payload.key,
          tabOrder: filterTabOrder,
          tabParams: payload.params || null,
        }
      } else {
        let afterAddTabOrder = [...state.tabOrder];
        let afterAddTabPages = [...state.tabPages];
        if (state.tabPages.length === 6) {
          // 达到tab数上限，过滤掉最早访问的tab
          afterAddTabOrder = state.tabOrder.filter(((item: any) => item !== state.tabOrder[0]));
          afterAddTabPages = state.tabPages.filter(((item: any) => item.key !== state.tabOrder[0]));
        }
        afterAddTabOrder.push(payload.key);
        afterAddTabPages.push(payload);
        return {
          tabPages: afterAddTabPages,
          activeKey: payload.key,
          tabOrder: afterAddTabOrder,
          tabParams: payload.params || null,
        }
      }
    // 删除tab
    case 'deleteTab':
      const newTabPages: any = []; // 删除后的tab数据
      let deleteIndex: any;
      state.tabPages.forEach((item: any, index: number) => {
        if (item.key === payload.key) {
          deleteIndex = index;
        } else {
          newTabPages.push(item);
        }
      });
      // 如果删除的tab是激活的key则激活前一个key否则不变
      const newActiveKey = payload.key === state.activeKey ? state.tabPages[deleteIndex - 1]?.key : state.activeKey;
      const afterDeleteTabOrder = state.tabOrder.filter(((item: any) => item !== payload.key));
      return {
        tabPages: newTabPages,
        activeKey: newActiveKey,
        tabOrder: afterDeleteTabOrder,
        tabParams: null,
      }
    // 修改激活的key
    case 'changeActive':
      const afterChangeTabOrder = state.tabOrder.filter(((item: any) => item !== payload.key));
      afterChangeTabOrder.push(payload.key);
      return {
        ...state,
        activeKey: payload.key,
        tabOrder: afterChangeTabOrder,
        tabParams: payload.params || null,
      }
    // 充值tab数据
    case 'resetTab':
      return {
        tabPages: [],
        activeKey: '',
        tabOrder: [],
        tabParams: null,
      }
    default:
      return state;
  }
}