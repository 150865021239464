/**
 * 操作AT指令说明
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, Row, Col } from 'antd';
import { upsertAtRequest } from '@/service/atCommand';

interface OperateModalProps {
  type: string; // 操作类型
  data?: any; // AT指令详情
  onClose: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 操作成功后重新请求AT指令列表的方法
}

const OperateModal = (props: OperateModalProps) => {
  const { type, data, onClose, reloadData } = props;
  const [loading, setLoading] = useState<boolean>(false); // 接口是否请求中
  const [form] = Form.useForm();

  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 提交
  const submit = async (values: any) => {
    try {
      setLoading(true);
      const params = { ...values };
      if (type === 'edit') {
        params.id = data.id;
      }
      const res = await upsertAtRequest(params);
      setLoading(false);
      if (res.data?.data) {
        message.success(type === 'add' ? '添加成功' : '编辑成功');
        onClose();
        reloadData();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      title={type === 'add' ? '添加AT指令说明' : '编辑AT指令说明'}
      open
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      onCancel={onClose}
      onOk={() => form.submit()}
      okButtonProps={{ loading }}
      maskClosable={false}
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        scrollToFirstError
        onFinish={submit}
      >
        <Form.Item
          label="指令名"
          name="name"
          rules={[{ required: true, message: '请填写指令名' }]}
        >
          <Input placeholder="请填写指令名" />
        </Form.Item>
        <Form.Item label="默认参数">
          <Row>
            <Col span={8}>
              <Form.Item
                name="type"
                noStyle
                rules={[{ required: true, message: '请选择数据类型' }]}
              >
                <Select
                  placeholder="请选择数据类型"
                  options={[
                    { label: 'string', value: true },
                    { label: 'number', value: false }
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                name="param"
                noStyle
                rules={[{ required: true, message: '请填写默认参数' }]}
              >
                <Input placeholder="请填写默认参数" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="适用版本"
          name="version"
          rules={[{ required: true, message: '请填写适用版本' }]}
        >
          <Input placeholder="请填写适用版本" />
        </Form.Item>
        <Form.Item label="说明" name="ps">
          <Input.TextArea placeholder="请填写说明" allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default OperateModal;
