export const carFilterTypeData = [
  { label: '今日运营车辆', value: 1 },
  { label: '当前在线车辆', value: 2 },
  { label: '今日新增车辆', value: 3 },
  { label: '今日维保车辆', value: 4 },
  { label: '今日升级车辆', value: 5 },
]

export const insuranceInforData = [
  { label: '已填写', value: true },
  { label: '未填写', value: false },
]