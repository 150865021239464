// extracted by mini-css-extract-plugin
export default {"rechargeBox":"rechargeModal_rechargeBox__zeoE7","rechargeBoxRow":"rechargeModal_rechargeBoxRow__ShOLI","rechargeBoxCol":"rechargeModal_rechargeBoxCol__sUzFW","rechargeAmount":"rechargeModal_rechargeAmount__SrFxz","rechargeAmountButton":"rechargeModal_rechargeAmountButton__b08Ea","rechargeAmountSpan":"rechargeModal_rechargeAmountSpan__yDkCQ"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"rechargeBox\":\"rechargeModal_rechargeBox__zeoE7\",\"rechargeBoxRow\":\"rechargeModal_rechargeBoxRow__ShOLI\",\"rechargeBoxCol\":\"rechargeModal_rechargeBoxCol__sUzFW\",\"rechargeAmount\":\"rechargeModal_rechargeAmount__SrFxz\",\"rechargeAmountButton\":\"rechargeModal_rechargeAmountButton__b08Ea\",\"rechargeAmountSpan\":\"rechargeModal_rechargeAmountSpan__yDkCQ\"}";
        // 1726639069076
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  