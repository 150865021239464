/**
 * 数据管理问题车离线车展示弹窗
 */
import React from 'react';
import { Modal } from 'antd';

interface operationQueryProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const operationQuery = (props: operationQueryProps) => {
 const { data, closeModal } = props;
 const item = data
  return (
    <Modal
      title="详细信息"
      visible
      footer={null}
      onCancel={closeModal}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}>
      <div>
       <div style={{ cursor:'pointer',marginTop:'10px'}}>在线状态：{item.onlineState }</div>
       <div style={{ cursor:'pointer',marginTop:'10px'}}>是否为离线车：{item.offLineFlag== true ? '是': '否'}</div>
       <div style={{ cursor:'pointer',marginTop:'10px'}}>是否为问题车：{item.problemFlag== true ? '是': '否'}</div>
      </div>
    </Modal>
  )
}

export default operationQuery;
    