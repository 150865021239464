/**
 * 报警次数筛选
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import { alarmCountRequest, alarmCountRequestPath } from '@/service/alarmCount';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import OperationGroupSelect from '@/components/operationGroupSelect';
import { operateDayRange } from '@/utils/operateSearchParams';
import { useTable } from '@/utils/useTable';
import { useUserCateIdArr } from '@/utils/ownHooks';
import { correctDataStatisticsAlarmTypeData, dataStatisticsAlarmTypeValue } from '@/constants/alarm';
import { snColumnConfig, cateGroupColumnConfig } from '@/constants/tableColumn';
import styles from '@/styles/pageContent.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;
const AlarmCount = () => {
  const cateIdArr = useUserCateIdArr();
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(8, 'day').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')}`,
    min: 80,
    alarmTypes: dataStatisticsAlarmTypeValue,
    duration: 1,
    cate_id: cateIdArr,
  }); // 搜索相关的参数
  const [form] = Form.useForm();
  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: alarmCountRequest,
    initRequest: false,
    initPageSize: 40,
    searchParams,
  });

  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = operateDayRange(values);
    setSearchParams(newSearchParams);
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return operateDayRange(values, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cate_id: options.cateId,
      })
    }
  }

  // 表格列显示
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 70,
      fixed: 'left' as 'left',
    },
    {
      title: searchParams.duration === 1 ? '日期' : '小时',
      dataIndex: 'time',
      width: 160,
      fixed: 'left' as 'left',
    },
    {
      title: '车牌号',
      dataIndex: 'plate_number',
      width: 110,
      fixed: 'left' as 'left',
    },
    snColumnConfig,
    cateGroupColumnConfig,
    {
      title: '报警总数',
      dataIndex: 'cc',
      width: 90,
    },
    {
      title: 'FCW数量',
      dataIndex: 'fcw_count',
      width: 100,
    },
    {
      title: 'AEB数量',
      dataIndex: 'aeb_count',
      width: 90,
    },
    {
      title: '起步阻止数量',
      dataIndex: 'sts_count',
      width: 120,
    },
    {
      title: 'LDW数量',
      dataIndex: 'ldw_count',
      width: 100,
    },
    {
      title: 'HMW数量',
      dataIndex: 'hmw_count',
      width: 100,
    },
    {
      title: '修正后HMW数量',
      dataIndex: 'hmwHumanCount',
      width: 140,
    },
    {
      title: 'HMW最大值',
      dataIndex: 'hmwMax',
      width: 120,
    },
    {
      title: 'HMW最小值',
      dataIndex: 'hmwMin',
      width: 120,
    },
    {
      title: 'HMW平均值',
      dataIndex: 'hmwAvg',
      width: 120,
    },
    {
      title: '双目制动数量',
      dataIndex: 'binocular_count',
      width: 130,
    },
    {
      title: '双目制动时HMW最大值',
      dataIndex: 'binocularHmwMax',
      width: 190,
    },
    {
      title: '双目制动时HMW最小值',
      dataIndex: 'binocularHmwMin',
      width: 190,
    },
    {
      title: '双目制动时HMW平均值',
      dataIndex: 'binocularHmwAvg',
      width: 190,
    },
  ];

  return (
    <Card bordered={false} className={styles.fixPageContentBox}>
      <Form onFinish={onSearch} layout='vertical' form={form}>
        <Row gutter={24} wrap>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/alarm-count/show-operation-group'
            span={4}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cate_id" initialValue={searchParams.cate_id}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              label="报警类型"
              name="alarmTypes"
              initialValue={searchParams.alarmTypes}
              rules={[{ required: true, message: "请指定报警类型" }]}
            >
              <Select mode="multiple" options={correctDataStatisticsAlarmTypeData} maxTagCount="responsive" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={6}>  
            <Form.Item label="时间段" name="duration" initialValue={searchParams.duration}>
              <Select>
                <Option value={1}>一天内</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="次数大于" name="min" initialValue={searchParams.min}>
              <InputNumber placeholder='报警次数' style={{ width: '100%' }} addonAfter="次" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="次数小于" name="max">
              <InputNumber placeholder='报警次数' style={{ width: '100%' }} addonAfter="次" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <div className={styles.searchBox}>
            <Button type="primary" htmlType="submit">搜索</Button>
            <ExportButton 
              url={alarmCountRequestPath}
              fileName='报警次数筛选'
              getParamsFunc={getExportParams}
              requestMethod="POST"
              permissionKey='/car-admin/alarm-count/export'
            />
          </div>
        </Form.Item>
      </Form>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
        }}
        bordered
        loading={loading}
      />
    </Card>
  )
}

export default AlarmCount;
  