/**
 * 部标机报警视频上传规则配置
 */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { message } from 'antd';
import {
  jtDeviceUploadRuleListRequest,
  unbindUploadRuleRequest
} from '@/service/jtDeviceUploadRule';
import PermissionButton from '@/components/permissionButton';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import GroupList from '@/pages/groupManage/components/groupList';
import BindRuleModal from './components/bindRuleModal';
import styles from '@/styles/pageContent.module.scss';

const JtDeviceUploadConfig = () => {
  const groupListRef: any = useRef(); // 分组列表的ref
  const [operateParams, setOperateParams] = useState<any>({}); // 表格操作时对应的数据
  const [ruleData, setRuleData] = useState<any>([]); // 规则数据

  // 重新获取分组数据
  const reloadGroupData = () => {
    groupListRef?.current?.getGroupData?.();
  };

  // 获取规则数据
  const getRuleData = async () => {
    const res = await jtDeviceUploadRuleListRequest({
      limit: 999,
      page: 1
    });
    setRuleData(res.data?.data || []);
  };

  useEffect(() => {
    getRuleData();
  }, []);

  // 解绑规则
  const unbind = async (data: any) => {
    const { id, title, parentId, ruleId } = data;
    const params = {
      id,
      parentId,
      title,
      ruleId: ruleId
    };
    const res = await unbindUploadRuleRequest(params);
    if (res.data?.data) {
      message.success('解绑成功');
      reloadGroupData();
    } else {
      message.error('解绑失败');
    }
  };

  // 表格列显示
  const columns = [
    {
      title: '绑定规则',
      dataIndex: 'ruleId',
      width: 220,
      ellipsis: { showTitle: false },
      render: (value: string) => (
        <RenderEllipsisColumn
          text={ruleData.find((item: any) => item.id === value)?.name || ''}
        />
      )
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 200,
      fixed: 'right' as const,
      render: (value: any, item: any) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/dvr-upload-config/bind"
              type="link"
              onClick={() => setOperateParams({ data: item, type: 'bind' })}
            >
              绑定上传规则
            </PermissionButton>
            {item.ruleId && (
              <PermissionButton
                operateType="/car-admin/dvr-upload-config/unbind"
                type="link"
                onClick={() => unbind(item)}
              >
                解绑上传规则
              </PermissionButton>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <Fragment>
      <GroupList columns={columns} ref={groupListRef} />
      {/* 绑定上传规则的弹窗 */}
      {operateParams.type === 'bind' && (
        <BindRuleModal
          data={operateParams.data}
          closeModal={() => setOperateParams({})}
          reloadData={reloadGroupData}
        />
      )}
    </Fragment>
  );
};

export default JtDeviceUploadConfig;
