/**
 * 图标(iconfont)
 */
import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';

const Icon = (props: any) => {
  const IconFont = createFromIconfontCN({
    scriptUrl: require('../../assets/iconfont/iconfont'),
  });
  return (
    <IconFont style={{ fontSize: '14px' }} {...props} />
  )
}

export default Icon;