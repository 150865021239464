// extracted by mini-css-extract-plugin
export default {"trackPlaybackBox":"playbackMonitoring_trackPlaybackBox__Iu0qx","carTree":"playbackMonitoring_carTree__WILg9","monitoring":"playbackMonitoring_monitoring__CoN87","monitoringTitle":"playbackMonitoring_monitoringTitle__SxV4W","monitoringButton":"playbackMonitoring_monitoringButton__sDqE4","rows":"playbackMonitoring_rows__W+L1t","monitoringContent":"playbackMonitoring_monitoringContent__MH03b","text":"playbackMonitoring_text__d5sJX"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"trackPlaybackBox\":\"playbackMonitoring_trackPlaybackBox__Iu0qx\",\"carTree\":\"playbackMonitoring_carTree__WILg9\",\"monitoring\":\"playbackMonitoring_monitoring__CoN87\",\"monitoringTitle\":\"playbackMonitoring_monitoringTitle__SxV4W\",\"monitoringButton\":\"playbackMonitoring_monitoringButton__sDqE4\",\"rows\":\"playbackMonitoring_rows__W+L1t\",\"monitoringContent\":\"playbackMonitoring_monitoringContent__MH03b\",\"text\":\"playbackMonitoring_text__d5sJX\"}";
        // 1726639067649
        var cssReload = require("../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  