/**
 * PDF文件预览弹窗
 */
import React from 'react';
import { Modal } from 'antd';

interface PdfPreviewModalProps {
  title: string, // 弹窗标题
  pdfUrl: string; // PDF文件地址
  closeModal: () => void; // 关闭弹窗的方法
}
const PdfPreviewModal = (props: PdfPreviewModalProps) => {
  const { title, pdfUrl, closeModal } = props;

  return (
    <Modal
      title={title}
      visible
      footer={null}
      onCancel={closeModal}
      centered
      bodyStyle={{ height: '70vh' }}
      width='70%'
    >
      <iframe
        src={`/pdfjs/web/viewer.html?file=${encodeURIComponent(pdfUrl)}`}
        title="pdf"
        width="100%"
        height="100%"
      />
    </Modal>
  )
}

export default PdfPreviewModal;
    