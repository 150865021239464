/**
 * 部标机设备资源列表上传时选择时间区间
 */
import React, {useState} from 'react';
import {Modal, Form, message} from 'antd';
import {Rule} from 'antd/lib/form';
import dayjs, {Dayjs} from 'dayjs';
import DatePicker from '@/components/datePicker';
import {formatTime} from '@/utils/formatTime';
import {jt808Command9206Request} from '@/service/jtDevicePlayback';

const {RangePicker} = DatePicker;

interface UploadTimeRangeModalProps {
  selectJtDeviceInfo: any; // 选择设备的信息
  data: any; // 设备资源文件数据
  closeModal: () => void; // 关闭弹窗的方法
  uploadSuccessCallback?: () => void; // 上传成功后调用的方法
  limitSeconds?: number; // 时间选择器限制的秒数
}

const UploadTimeRangeModal = (props: UploadTimeRangeModalProps) => {
  const {
    selectJtDeviceInfo,
    data,
    closeModal,
    uploadSuccessCallback,
    limitSeconds = 60 * 5
  } = props;
  const {start, end} = data;
  //判断起止时间是否小于五分钟
  const isLessThanFiveMinutes = dayjs(end).diff(dayjs(start), 'second') < limitSeconds;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // 上传的loading

  // 上传
  // const onUpload = async (values: any) => {
  //   try {
  //     setLoading(true);
  //     const { phone, jt808DeviceId } = selectJtDeviceInfo;
  //     const { channelNo, type, codeStream, storageType } = data;
  //     const params = {
  //       phone,
  //       jt808DeviceId,
  //       channelNo,
  //       codeStream,
  //       start: formatTime(values.time[0]),
  //       end: formatTime(values.time[1]),
  //       type,
  //       storageType,
  //       alarmFlag: 0,
  //       alarmFlag1: 0,
  //       execTiming: 7
  //     };
  //     const res = await jt808Command9206Request(params);
  //     if (res.data?.data) {
  //       uploadSuccessCallback && uploadSuccessCallback();
  //       message.success('上传成功');
  //       closeModal();
  //     } else {
  //       message.error('上传失败');
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const onUpload = (values: any) => {
    async function syncUpload() {
      try {
        setLoading(true);
        const {phone, jt808DeviceId} = selectJtDeviceInfo;
        const {channelNo, type, codeStream, storageType} = data;
        const params = {
          phone,
          jt808DeviceId,
          channelNo,
          codeStream,
          start: formatTime(values.time[0]),
          end: formatTime(values.time[1]),
          type,
          storageType,
          alarmFlag: 0,
          alarmFlag1: 0,
          execTiming: 7
        };
        const res = await jt808Command9206Request(params);
        if (res.data?.data) {
          uploadSuccessCallback && uploadSuccessCallback();
          // message.success('上传成功');
          message.success('文件上传任务已提交，请稍后在下载列表查看');
          closeModal();
        } else {
          message.error('上传失败');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    syncUpload();
  };

  // 校验选择时间范围小于限制的秒数
  const validatorTimeRange = (rule: Rule, value: Array<Dayjs>) => {
    if (value && value.every((item: Dayjs) => Boolean(item))) {
      const startValue = value[0];
      const endValue = value[1];
      if (startValue < dayjs(start)) {
        return Promise.reject(new Error('起始时间要晚于视频开始时间'));
      } else if (endValue > dayjs(end)) {
        return Promise.reject(new Error('终止时间要早于视频结束时间'));
      } else if (endValue.diff(startValue) > limitSeconds * 1000) {
        return Promise.reject(new Error(`视频上传时间最长为${limitSeconds / 60}分钟`));
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  };

  return (
    <Modal
      title="请选择上传时间区间"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      okText="上传"
      okButtonProps={{
        loading
      }}
    >
      <Form form={form} autoComplete="off" onFinish={onUpload}>
        <Form.Item
          label="上传时间区间"
          name="time"
          rules={[
            {required: true, message: '请选择上传时间区间'},
            {validator: validatorTimeRange}
          ]}
          initialValue={[
            dayjs(start),
            dayjs(start)
              .add(limitSeconds, 'second')
              .diff(dayjs(end), 'second') > 0
              ? dayjs(end)
              : dayjs(start).add(limitSeconds, 'second')
          ]}
          extra={`视频上传时间最长为${limitSeconds / 60}分钟`}
        >
          <RangePicker style={{width: '100%'}} showTime allowClear={false} disabled={isLessThanFiveMinutes}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadTimeRangeModal;
