/**
 * 车辆分布
 */
import React, { useEffect, useRef, useState } from 'react';
import { importLocationMap } from '@/utils/map';
import styleJson from '../../custom_map_config (1).json';
import { carLocationRequest } from '@/service/carLocation';
import { drawCar } from '@/utils/location';
import LoadingWarpper from '@/components/loadingWrapper';

const PagesNine = () => {
  const [loading, setLoading] = useState(false); // 地图数据请求中
  let mapRef: any = useRef(null); // 存储地图实例

  useEffect(() => {
    importLocationMap(carDistribution);
  }, [])

  const params = {cateId:[1674]}

  // 车辆分布
  const carDistribution = async () => {
    setLoading(true);
    mapRef.current = new window.window.BMap.Map("trackMap");
    mapRef.current.centerAndZoom(new window.BMap.Point(105, 34), 5);
    mapRef.current.enableScrollWheelZoom(); // 开启鼠标滚轮缩放
    mapRef.current.setMapStyleV2({
      styleJson: styleJson
    })
    const cateId = params.cateId
    const res = await carLocationRequest({ cate_id: cateId });
    setLoading(false);
    const data = res.data?.data || [];
    drawCar(data, mapRef.current, mapRef.current, 'pagesNine');
  }
 
  return (
    <div>
      <LoadingWarpper spinning={loading} autoHeight>
        <div id="trackMap" style={{height:'800px', width:'100%'}}/>
      </LoadingWarpper>
      
    </div>
  )
}

export default PagesNine;
