import { ownRound } from './math';

// 筛掉不在中国境内的经纬度点
export const filterNotInChina = (item: any) => {
  if (item.lat < 73 || item.lat >134 || item.lon > 53.5 || item.lon < 18.15) {
    return false;
  } else {
    return true;
  }
}

// 经纬度过滤方法，相邻数组项之间经度或纬度差距>10则弃掉当前项
export const filterAdjoinPoint = (item: any, index: number, arr: any) => {
  // if (index) {
  //   if ((Math.abs(item.lat - arr[0].lat) < 10) && (Math.abs(item.lon - arr[0].lon) < 10)) {
  //     return item;
  //   }
  // } else {
  //   return item;
  // }
  return item;
}

// 格式化显示GPS速度
export const formatGpsSpeed = (gpsSpeed: any) => {
  if (gpsSpeed === 999 || gpsSpeed === '999') {
    return '未定位';
  } else {
    return ownRound(gpsSpeed, 1);
  }
};
