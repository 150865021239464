// 审批状态
export enum ApproveStatusEnum {
  reject = 'REJECT',
  approve = 'APPROVE',
  pending = 'PENDING'
}

// 审批状态数据
export const approveStatusData: Array<{ label: string; value: string }> = [
  { label: '待审批', value: ApproveStatusEnum.pending },
  { label: '已通过', value: ApproveStatusEnum.approve },
  { label: '已拒绝', value: ApproveStatusEnum.reject }
];

// 审批类型
export enum ApproveTypeEnum {
  aebConfig = 'aebConfig',
  vehicleTypeConfig = 'vehicleTypeConfig',
  gpsFrequencyConfig = 'gpsFrequencyConfig',
  recorderConfig = 'recorderConfig',
  sensorConfig = 'sensorConfig',
  cameraConfig = 'cameraConfig',
  cableMotorConfig = 'cableMotorConfig'
}

// 审批类型数据
export const approveTypeData: Array<{ label: string; value: string }> = [
  { label: 'AEB参数配置', value: ApproveTypeEnum.aebConfig },
  { label: '车身类型配置', value: ApproveTypeEnum.vehicleTypeConfig },
  { label: 'GPS上报频率配置', value: ApproveTypeEnum.gpsFrequencyConfig },
  { label: '记录仪参数配置', value: ApproveTypeEnum.recorderConfig },
  { label: '传感器参数配置', value: ApproveTypeEnum.sensorConfig },
  { label: '相机参数配置', value: ApproveTypeEnum.cameraConfig },
  { label: '拉线电机参数配置', value: ApproveTypeEnum.cableMotorConfig }
];
