/**
 * 单条承保记录
 */
import { useState } from 'react';
import { Button, Descriptions } from "antd";
import { downloadFile } from '@/utils/download';
import { DownloadOutlined, FileZipOutlined, EyeOutlined } from '@ant-design/icons';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import RenderEllipsisColumn from '@/components/renderEllipsisColumn';
import dayjs from 'dayjs';


interface MaintenanceItemProps {
  column: {[key: string]: number};
  data: any;
  type: string;
}
const UnderWritingItem = (props: MaintenanceItemProps) => {
  const { column, data } = props;
  const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
    // PDF合同预览
  const onPdfPreview = (url: string, fileName: string) => {
    setPdfPreviewInfo({
      url,
      fileName,
    });
  }

  return (
    <Descriptions bordered size="small" column={column}>
      <Descriptions.Item label="承保单号">{data.commercialNumber}</Descriptions.Item>
      <Descriptions.Item label="合同号">{data.contractSn}</Descriptions.Item>
      <Descriptions.Item label="合同状态">{data.contractState}</Descriptions.Item>
      <Descriptions.Item label="车架号">{data.frameNumber}</Descriptions.Item>
      <Descriptions.Item label="车辆所有人">{data.carOwner}</Descriptions.Item>
      <Descriptions.Item label="投保人">{data.applicant}</Descriptions.Item>
      <Descriptions.Item label="承保模式">{data.mode}</Descriptions.Item>
      <Descriptions.Item label="服务状态">{data.serviceState}</Descriptions.Item>
      <Descriptions.Item label="承保保司">{data.commercialCompany}</Descriptions.Item>
      <Descriptions.Item label="市场归属">{data.department}</Descriptions.Item>
      <Descriptions.Item label="起保时间(商业)">{data.commercialBeginDate ?<RenderEllipsisColumn text={dayjs(data.commercialBeginDate ).format('YYYY-MM-DD')} /> : ''}</Descriptions.Item>
      <Descriptions.Item label="到期时间(商业)">{data.commercialEndDate ?<RenderEllipsisColumn text={dayjs(data.commercialEndDate ).format('YYYY-MM-DD')} /> : ''}</Descriptions.Item>
      { data.attachments !== null  && <Descriptions.Item label="附件" span={2}>{
             data?.attachments.map((item: any, index: any) => (
              <div key={index} style={{ width: '240px', display: 'flex', border: '1px solid rgb(217,217,217)', padding: '5px' }}>
                <div style={{ width: '170px', paddingLeft: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FileZipOutlined style={{ paddingRight: '5px' }} />{item.fileName}
                </div>
                <div style={{ width: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    type="text"
                    onClick={() => onPdfPreview(item.url, item.fileName)}
                  >
                    <EyeOutlined />
                  </Button>
                  <Button
                    type="link"
                    onClick={() => downloadFile(item.url, item.fileName)}
                    style={{ paddingLeft: 0 }}
                  >
                    <DownloadOutlined />
                  </Button>
                </div>
              </div>
            ))}
              {pdfPreviewInfo && <PdfPreviewModal
              title={pdfPreviewInfo?.fileName}
              pdfUrl={pdfPreviewInfo?.url}
              closeModal={() => setPdfPreviewInfo(null)}
  />}
      </Descriptions.Item>}
    </Descriptions>
    
  )

}

export default UnderWritingItem;