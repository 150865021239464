// 表格列显示
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import Thumbnail from "@/components/thumbnail";
import {Button, Popconfirm} from "antd";
import {QuotationRecordModalType} from "@/pages/offerRecord/components/quotationRecordModal";
import React from "react";
import {marginRight} from "html2canvas/dist/types/css/property-descriptors/margin";

interface ItemHandler {
  exportPdfFn: (...args: any[]) => any,
  copyFn: (...args: any[]) => any,
  delFn: (...args: any[]) => any,
  openAttachmentFn: (...args: any[]) => any,
  generateQuotationFn: (...args: any[]) => any,
}

const useColumns = (handler: ItemHandler) => {
  const {
    exportPdfFn,
    copyFn,
    delFn,
    openAttachmentFn,
    generateQuotationFn
  } = handler
  return [
    {
      title: '申请报价公司',
      dataIndex: 'applyCompany',
      width: 110,
    },
    {
      title: '申请报价人',
      dataIndex: 'applyUser',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '报价人',
      dataIndex: 'operator',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '车牌',
      dataIndex: 'licenseNo',
      width: 110,
      render: (value: string, item: any) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '车架号',
      dataIndex: 'carVin',
      width: 150,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '发动机号',
      dataIndex: 'engineNo',
      width: 150,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '车型库车辆类型',
      dataIndex: 'vehicleFlag',
      width: 110,
      render: (value: number) => {
        if (value == 1) {
          return "机动车"
        }
        if (value == 2) {
          return "特种车"
        }
        if (value == 3) {
          return "新能源车"
        }
        return "未知"
      },
    },
    {
      title: '行驶证车辆类型',
      dataIndex: 'vehicleType',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '载重量(kg)',
      dataIndex: 'tonCount',
      width: 110,
    },
    {
      title: '牵引质量(kg)',
      dataIndex: 'tractionTonCount',
      width: 110,
    },
    {
      title: '载客量',
      dataIndex: 'seatCount',
      width: 110,
    },
    {
      title: '品牌型号',
      dataIndex: 'moldName',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '是否分期',
      dataIndex: 'instalment',
      width: 110,
      render: (value: number) => <RenderEllipsisColumn text={value === 1 ? '分期' : '不分期'}/>,
    },
    {
      title: '报价日期',
      dataIndex: 'createdAt',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '初登日期',
      dataIndex: 'registerDate',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '省份',
      dataIndex: 'area',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '城市',
      dataIndex: 'city',
      width: 150,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },

    {
      title: '市场归属',
      dataIndex: 'carOwnerDepartment',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '车辆所有人',
      dataIndex: 'carOwnerName',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '投保人证件号',
      dataIndex: 'holderIdCard',
      width: 110,
    },
    {
      title: '燃油类型',
      dataIndex: 'fuelType',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '设备类型',
      dataIndex: 'deviceType',
      width: 110,
    },
    {
      title: '评分',
      dataIndex: 'bizCpicScore',
      width: 110,
    },
    {
      title: '评级',
      dataIndex: 'grade',
      width: 110,
    },
    {
      title: 'NCD折扣系数',
      dataIndex: 'rateFactor1',
      width: 110,
    },
    {
      title: '三者保额',
      dataIndex: 'sanZhe',
      width: 110,
    },
    {
      title: '三者保费',
      dataIndex: 'sanZheBaoFeiPlate',
      width: 110,
    },
    {
      title: '司机保额',
      dataIndex: 'siJi',
      width: 110,
    },
    {
      title: '司机保费',
      dataIndex: 'siJiBaoFeiPlate',
      width: 110,
    },
    {
      title: '乘客保额',
      dataIndex: 'chengKe',
      width: 110,
    },
    {
      title: '乘客保费',
      dataIndex: 'chengKeBaoFeiPlate',
      width: 110,
    },
    {
      title: '车损保额',
      dataIndex: 'negotiatePrice',
      width: 110,
    },
    {
      title: '车损保费',
      dataIndex: 'cheSunBaoFeiPlate',
      width: 110,
    },
    {
      title: '总费用',
      dataIndex: 'bizTotalPlate',
      width: 110,
    },
    {
      title: '折扣系数',
      dataIndex: 'platformFactor',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '参考保司',
      dataIndex: 'insure',
      width: 110,
    },
    {
      title: '保司报价',
      dataIndex: 'bizTotal',
      width: 110,
      render: (value: string) => <RenderEllipsisColumn text={value}/>,
    },
    {
      title: '联系电话',
      dataIndex: 'mobile',
      width: 150,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      width: 110,
    },
    {
      title: '附件',
      dataIndex: 'attachment',
      width: 110,
      render: (_: string, item: any) => {
        return (
          // 有没有附件其实打开的是一个弹窗，但是如果只是更改显示文字的话太不显眼了，所以这里还修改了button的type
          <Button type={item.attacheList?.length ? 'default' : 'link'} onClick={() => openAttachmentFn(item)}>
            {`${(item.attacheList?.length) ? '查看' : '上传'}附件`}
          </Button>
        )
      }

    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 220,
      fixed: 'right' as 'right',
      render: (_value: string, item: any) => {
        return (
          <div>
            {/*<Button type={"link"}*/}
            {/*        onClick={() => openQuotationRecordModal(QuotationRecordModalType.Edit, item)}>编辑</Button>*/}
            <Button type={"link"} onClick={() => exportPdfFn(item)}>导出报价单</Button>

            <Button type={"link"}
                    onClick={() => copyFn(item)}>复制</Button>
            <Button type={"link"} onClick={() => generateQuotationFn(item)}>生成承保单</Button>
            <Popconfirm title="此操作不可恢复，是否确认删除"
                        onConfirm={() => {
                          delFn(item.id)
                        }}
                        okText="确认删除" cancelText="取消">
              <a href="#">删除</a>
            </Popconfirm>
          </div>
        )
      }
    },
  ]
}

export default useColumns;
