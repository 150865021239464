/**
 * 页面内部权限配置列表
 */
import React, { useState, useEffect } from "react";
import { Drawer, message, Popconfirm } from 'antd';
import CommonTable from '@/components/commonTable';
import RenderEllipsisColumn from "@/components/renderEllipsisColumn";
import PermissionButton from "@/components/permissionButton";
import { upsertMenuRequest, menuDetailRequest } from '@/service/menuManage';
import ButtonOperateModal from "../buttonOperateModal";
import styles from '@/styles/pageContent.module.scss';

interface ButtonPermissionModalProps {
  data: any; // 页面内部权限配置相关信息
  closeModal: () => void; // 关闭面板的方法
}
const ButtonPermissionModal = (props: ButtonPermissionModalProps) => {
  const { data, closeModal } = props;
  const [loading, setLoading] = useState(false); // 是否在获取菜单详情中
  const [permissionData, setPermissionData] = useState<any>({}); // 页面内部权限的数据
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息

  // 获取当前菜单详情
  const getMenuDetail = async() => {
    try {
      setLoading(true);
      const res = await menuDetailRequest(data.id);
      setPermissionData(res.data?.data || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMenuDetail();
  }, []);
  
  // 删除页面内部权限配置
  const deleteButtonConfig = async(deleteIndex: number) => {
    const params: any = {...permissionData};
    params.permissions.splice(deleteIndex, 1);
    const res = await upsertMenuRequest(params);
    if (res.data?.code === 0) {
      message.success('删除成功');
      getMenuDetail();
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '名称',
      dataIndex: 'displayName',
      width: 160,
    },
    {
      title: '权限标识key',
      dataIndex: 'permissionKey',
      width: 200,
      ellipsis: { showTitle: false },
      render: (value: string) => <RenderEllipsisColumn text={value} />,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 200,
      fixed: 'right' as 'right',
      render: (value: any, item: any, index: number) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/menu-manage/button-edit"
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', data: item, pageData: permissionData, editIndex: index })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此权限配置吗?"
              onConfirm={() => deleteButtonConfig(index)}
            >
              <PermissionButton operateType="/car-admin/menu-manage/button-delete" type="link">删除</PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  return (
    <Drawer
      title={`${permissionData.title}页面内部权限配置`}
      visible
      onClose={closeModal}
      size="large"
      className={styles.drawerTableBox}
    >
      <div className={styles.operateBox}>
        <PermissionButton
          operateType="/car-admin/menu-manage/button-add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add', pageData: permissionData })}
          style={{ marginBottom: '12px' }}
        >
          添加页面内部权限配置
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={permissionData?.permissions || []}
        pagination={false}
        columnFilter={false}
        bordered
        size="small"
        loading={loading}
      />
      {/* 页面内部权限的添加和编辑 */}
      {['add', 'edit'].includes(operateInfo.type) && <ButtonOperateModal
        type={operateInfo.type}
        pageData={operateInfo.pageData}
        data={operateInfo.data}
        editIndex={operateInfo.editIndex}
        closeModal={() => setOperateInfo({})}
        reloadData={getMenuDetail}
      />}
    </Drawer>
  )
}

export default ButtonPermissionModal;