/**
 * 围栏区域配置路口类型
 */
import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import CommonTable from '@/components/commonTable';
import PermissionButton from '@/components/permissionButton';
import { fenceAreaDetailRequest } from '@/service/electronFence';
import {
  intersectionTypeObj,
  speedLimit,
  angleLimit
} from '@/constants/electronFence';
import IntersectionOperateModal from '../intersectionOperateModal';
import commonStyles from '@/styles/pageContent.module.scss';

interface OperateModalProps {
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
}
const IntersectionListModal = (props: OperateModalProps) => {
  const { data, closeModal } = props;
  const [loading, setLoading] = useState(false); // 获取路口类型数据loading
  const [intersectionList, setIntersectionListModal] = useState([]); // 路口列表
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的相关信息

  // 获取围栏区域路口类型数据
  const getFenceAreaDetail = async () => {
    try {
      setLoading(true);
      const res = await fenceAreaDetailRequest(data.id);
      setIntersectionListModal(res.data?.data?.configs || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFenceAreaDetail();
  }, []);

  // 渲染路口类型列
  const renderTypeColumn = (value: number) => intersectionTypeObj[value];

  // 渲染速度限制列
  const renderSpeedLimitColumn = (value: number) =>
    speedLimit.find((item: any) => item.value === value)?.label;

  // 渲染转向角列
  const renderAngleColumn = (value: number) =>
    angleLimit.find((item: any) => item.value === value)?.label;

  const columns = [
    {
      title: '路口类型',
      dataIndex: 'type',
      width: 70,
      render: renderTypeColumn
    },
    {
      title: '直行速度限制',
      dataIndex: 'straight',
      width: 90,
      render: renderSpeedLimitColumn
    },
    {
      title: '左转速度限制',
      dataIndex: 'leftTurn',
      width: 90,
      render: renderSpeedLimitColumn
    },
    {
      title: '右转速度限制',
      dataIndex: 'rightTurn',
      width: 90,
      render: renderSpeedLimitColumn
    },
    {
      title: '转向角限制(方向盘)',
      dataIndex: 'angle',
      width: 80,
      render: renderAngleColumn
    },
    {
      title: '斑马线上障碍物(行人)的距离限制',
      dataIndex: 'distance',
      width: 110,
      render: (value: number) => `${value}米`
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 60,
      render: (value: any, item: any) => (
        <div className={commonStyles.tableOperateColumn}>
          <PermissionButton
            type="link"
            size="small"
            operateType="/car-admin/electron-fence/edit-intersection"
            onClick={() => setOperateInfo({ type: 'edit', data: item })}
          >
            编辑
          </PermissionButton>
        </div>
      )
    }
  ];

  return (
    <Drawer
      title={`${data.name}路口类型`}
      open
      onClose={closeModal}
      size="large"
      className={commonStyles.drawerTableBox}
    >
      <div className={commonStyles.operateBox}>
        <PermissionButton
          type="primary"
          size="small"
          operateType="/car-admin/electron-fence/add-intersection"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加路口类型
        </PermissionButton>
      </div>
      <CommonTable
        rowKey="id"
        columns={columns}
        dataSource={intersectionList}
        pagination={false}
        columnFilter={false}
        bordered
        size="small"
        loading={loading}
      />
      {/* 路口类型添加/编辑弹窗 */}
      {['add', 'edit'].includes(operateInfo.type) && (
        <IntersectionOperateModal
          type={operateInfo.type}
          data={operateInfo.data}
          fenceAreaId={data.id}
          closeModal={() => setOperateInfo({})}
          reloadData={getFenceAreaDetail}
        />
      )}
    </Drawer>
  );
};

export default IntersectionListModal;
