/**
 * 昆仑山版本信息
 */
import React, { Fragment } from 'react';
import { Descriptions, Divider } from 'antd';
import { klsVersionInfoFileds, KlsVersionInfoFiledsInterface, KlsVersionFiledsInterface } from '@/constants/klsVersionInfo';

interface KlsVersionInfoProps {
  data: { [key: string]: string | number };
}
const KlsVersionInfo = (props: KlsVersionInfoProps) => {
  const { data } = props;
  return (
    <div>
      {klsVersionInfoFileds.map((item: KlsVersionInfoFiledsInterface) => (
        <Fragment key={item.title}>
          <Divider orientation="left">{item.title}</Divider>
          <Descriptions>
            {item.children.map((item1: KlsVersionFiledsInterface) => (
              <Descriptions.Item
                label={item1.title}
                key={item1.field}
              >
                {data?.[item1.field]}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Fragment>
      ))}
    </div>
  )
}

export default KlsVersionInfo;