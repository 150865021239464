/**
 * 车辆选择框
 */
import React, { useEffect, useState, useMemo } from 'react';
import { Select, SelectProps } from 'antd';
import { debounce, omit } from 'lodash';
import { carListRequest } from '@/service/carManage';
import {useUserCateIdArr} from "@/utils/ownHooks";

interface CarSelectProps extends SelectProps {
  value?: string; // 选择的数据
  onChange?: (value: string, options: any) => void; // 选择时调用的方法
  valueField: string; // 作为选择器车辆value的字段
}
const CarSelect = (props: CarSelectProps) => {
  const { value, onChange, valueField } = props;
  const cateIdArr = useUserCateIdArr();
  const [carList, setCarList] = useState<any>([]); // 车辆列表数据
  const [selectValue, setSelectValue] = useState<string | undefined>();

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  // 车辆的搜索
  const onCarSearch = async (value: string) => {
    if (value) {
      const res = await carListRequest({
        page: 1,
        limit: 100,
        plate_number: value,
        cate_id: cateIdArr
      });
      const carData = (res.data?.data || []).map((item: any) => ({
        label: item.plate_number,
        value: item[valueField],
        ...item,
      }));
      setCarList(carData);
    } else {
      setCarList([]);
    }
  }

  // 防抖的车辆的搜索
  const debounceOnCarSearch = useMemo(() => {
    return debounce(onCarSearch, 600);
  }, []);

  const selectOwnProps = omit(props, 'valueField')
  return (
    <Select
      {...selectOwnProps}
      placeholder='可根据车牌进行搜索'
      showSearch
      filterOption={false}
      onSearch={debounceOnCarSearch}
      options={carList}
      onChange={onChange}
      value={selectValue}
      notFoundContent={null}
    />
  )
}

export default CarSelect;
