/**
 * 传感器配置添加/编辑
 */
import React, { useEffect, useState } from 'react';
import { Form, Input, message, Modal, Switch, Select } from 'antd';
import { uniq } from 'lodash';
import LoadingWrapper from '@/components/loadingWrapper';
import SensorUltrasonicLocation from '@/components/sensorUltrasonicLocation';
import FormTable from '@/components/formTable';
import SensorProjectSelect from '@/components/sensorProjectSelect';
import { sensorConfigInitialValue } from '@/constants/sensorScheme';
import { sensorUltrasonicInstallListRequest } from '@/service/sensorUltrasonicInstall';
import { sensorUltrasonicHardwareDetailRequest } from '@/service/sensorUltrasonicHardware';
import { sensorDeviceListRequest } from '@/service/sensorDeviceManage';
import {
  upsertSensorConfigRequest,
  sensorConfigDetailRequest
} from '@/service/sensorConfigManage';
import { actuatorName } from '@/constants/sensorScheme';
import styles from './index.module.scss';

interface OperateModalProps {
  type: string; // 操作类型(添加、编辑、另存)
  data: any; // 数据
  closeModal: () => void; // 关闭弹窗的方法
  reloadData: () => void; // 保存成功后重新获取数据的方法
}
const OperateModal = (props: OperateModalProps) => {
  const locationKey = [
    { label: '前方雷达', value: 'radarFront' },
    { label: '后方雷达', value: 'radarBehind' },
    { label: '左方雷达', value: 'radarLeft' },
    { label: '右方雷达', value: 'radarRight' }
  ];
  const ultrasonicIndex = 13; // 超声波雷达对应的index值，用于特殊判断
  const actuatorIndex = 3; // 执行机构对应的index值，用于特殊判断
  const { type, data, closeModal, reloadData } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false); // 编辑、另存时是否在请求接口中
  const [installList, setInstallList] = useState<any>([]); // 超声波安装方案列表数据
  const [sensorDeviceList, setSensorDeviceList] = useState<any>([]); // 设备列表数据
  const [initSelectedLocationData, setInitSelectedLocationData] = useState<any>(
    []
  ); // 超声波安装示意图默认选择的位置关系数据

  // 获取传感器设备列表数据
  const getSensorDeviceList = async () => {
    const res = await sensorDeviceListRequest({
      page: 1,
      limit: 999
    });
    const data = (res.data?.data || []).map((item: any) => {
      const {
        name,
        model,
        version,
        supplierName,
        id,
        componentTypeId,
        componentTypeName
      } = item;
      return {
        label: `${name}-${model}-${version}(${supplierName})`,
        value: id,
        deviceTypeId: componentTypeId,
        componentTypeName
      };
    });
    setSensorDeviceList(data);
  };

  // 获取超声波安装方案列表数据
  const getSensorUltrasonicInstall = async () => {
    const res = await sensorUltrasonicInstallListRequest({
      page: 1,
      limit: 999
    });
    const installData = res.data?.data || [];
    setInstallList(installData);
    if (['edit', 'saveAs'].includes(type)) {
      try {
        setLoading(true);
        const detailRes = await sensorConfigDetailRequest(data.id);
        if (detailRes.data?.code === 0) {
          setFormValues(installData, detailRes.data?.data || {});
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  // 编辑、另存时处理赋值操作
  const setFormValues = (installData: any, detail: any) => {
    const { name, projectId, ps } = detail;
    const config: any = [];
    sensorConfigInitialValue.forEach((item: any, index: number) => {
      const { field } = item;
      // 此传感器设备有值表示安装此传感器设备
      const matchValue = data[field];
      if (matchValue) {
        const newItem = { ...item, isInstall: true };
        // 超声波设备赋值安装方案字段、其它设备赋值设备字段
        if (index === ultrasonicIndex) {
          newItem.location = matchValue;
          operateInitSelectedLocationData(matchValue, index, installData);
        } else {
          newItem.device = matchValue;
        }
        config.push(newItem);
      } else {
        config.push({ ...item });
      }
    });
    form.setFieldsValue({
      name,
      projectId,
      ps,
      config
    });
  };

  useEffect(() => {
    getSensorDeviceList();
    getSensorUltrasonicInstall();
  }, []);

  // 根据选择的超声波安装方案处理超声波安装示意图相关数据以及方案对应的设备数据
  const operateInitSelectedLocationData = async (
    selectInstall: number,
    changeIndex: number,
    installData = installList
  ) => {
    if (selectInstall) {
      const matchInstall: any = installData.find(
        (item: any) => item.id === selectInstall
      ); // 选择的超声波安装方案
      const newInitSelectedLocationData: any = []; // 选择的安装方案中选择的位置关系数据
      const ultrasonicHardwareIds: Array<number> = []; // 选择的安装方案对应的超声波硬件方案
      locationKey.forEach((item: any) => {
        const matchValue = matchInstall[`${item.value}Desc`];
        if (matchValue) {
          newInitSelectedLocationData.push(matchValue.nameArr);
          ultrasonicHardwareIds.push(matchValue.ultraProbeId);
        } else {
          newInitSelectedLocationData.push([]);
        }
      });
      setInitSelectedLocationData(newInitSelectedLocationData);
      // 根据选择的超声波方案对应的超声波硬件方案获取对应的超声波设备数据
      const allDetailRequest = uniq(ultrasonicHardwareIds).map((item: number) =>
        sensorUltrasonicHardwareDetailRequest(item)
      );
      const detailRes = await Promise.all(allDetailRequest);
      const ultrasonicHardwareDeviceIds: Array<number> = [];
      detailRes.forEach((item: any) => {
        if (item?.data?.code === 0) {
          ultrasonicHardwareDeviceIds.push(item?.data?.data?.componentId);
        }
      });
      // 选择超声波雷达方案时对应的设备选择器进行赋值
      form.setFields([
        {
          name: ['config', changeIndex, 'device'],
          errors: [],
          value: ultrasonicHardwareDeviceIds
        }
      ]);
    }
  };

  // 表单操作时的处理
  const onFormChange = (changedValues: any) => {
    if (changedValues?.config) {
      const changeIndex = changedValues?.config.length - 1; // 当前改变的索引
      const { isInstall, location } = changedValues.config[changeIndex] || {};
      if (typeof isInstall === 'boolean') {
        if (isInstall) {
          // 选择安装时去除整个安装字段的校验
          form.setFields([
            { name: 'config', errors: [], value: form.getFieldValue('config') }
          ]);
          // 选择安装超声波雷达时修改雷达示意图的数据
          if (changeIndex === ultrasonicIndex) {
            operateInitSelectedLocationData(
              form.getFieldValue(['config', changeIndex, 'location']),
              changeIndex
            );
          }
        } else {
          // 选择不安装时不校验对应的设备选择框和超声波雷达的方案选择框
          form.setFields([
            {
              name: ['config', changeIndex, 'device'],
              errors: [],
              value: undefined
            },
            {
              name: ['config', changeIndex, 'location'],
              errors: [],
              value:
                changeIndex === ultrasonicIndex
                  ? undefined
                  : form.getFieldValue(['config', changeIndex, 'location'])
            }
          ]);
          // 选择不安装超声波雷达时修改雷达示意图的数据
          if (changeIndex === ultrasonicIndex) {
            setInitSelectedLocationData([]);
          }
        }
      }
      // 超声波雷达安装方案改变且选择安装时修改雷达示意图的数据
      if (
        location &&
        form.getFieldValue(['config', changeIndex, 'isInstall'])
      ) {
        operateInitSelectedLocationData(location, changeIndex);
      }
    }
  };

  // 传感器配置方案校验至少安装一种设备
  const validatorConfig = (value: any) => {
    if (value.some((item: any) => item.isInstall)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error('请至少安装一种设备'));
    }
  };

  // 保存传感器配置方案
  const upsertSensorConfig = async (values: any) => {
    const { name, projectId, ps, config } = values;
    const sensorDeviceConfig: { [key: string]: number } = {};
    config.forEach((item: any, index: number) => {
      const { isInstall, field, device, location } = item;
      if (isInstall) {
        // 超声波雷达使用选择的方案id其它使用选择设备id
        sensorDeviceConfig[field] =
          index === ultrasonicIndex ? location : device;
      }
    });
    const params: any = {
      name,
      projectId,
      ps,
      ...sensorDeviceConfig
    };
    if (type === 'edit') {
      params.id = data.id;
    }
    const res = await upsertSensorConfigRequest(params);
    if (res.data?.data) {
      message.success(type === 'edit' ? '编辑成功' : '添加成功');
      closeModal();
      reloadData();
    }
  };

  // 渲染安装位置表单项
  const renderLocationItem = (formValues: any, index: number) => {
    if ([9, 10, 11, 12].includes(index)) {
      return <div>{formValues?.config?.[index]?.location}</div>;
    } else if (index === ultrasonicIndex) {
      return (
        <Select
          placeholder="安装方案"
          options={installList}
          fieldNames={{ label: 'name', value: 'id' }}
          disabled={formDisabled}
        />
      );
    } else {
      return <div />;
    }
  };

  // 渲染安装设备表单项
  const renderDeviceItem = (formValues: any, index: number) => {
    const currentValue = formValues?.config?.[index]?.device;
    const deviceTypeId = sensorConfigInitialValue[index]?.deviceTypeId;
    let currentSensorDeviceList: any = [];
    // 执行机构使用名称进行过滤
    if (index === actuatorIndex) {
      currentSensorDeviceList = sensorDeviceList.filter(
        (item: any) => item.componentTypeName === actuatorName
      );
      console.log(currentSensorDeviceList);
    } else {
      currentSensorDeviceList = sensorDeviceList.filter(
        (item: any) => item.deviceTypeId === deviceTypeId
      );
    }
    if (Array.isArray(currentValue)) {
      // 兼容一套超声波方案使用多家供应商的超声波设备情况
      return (
        <div>
          {currentValue.map((item: number) => (
            <Select
              options={currentSensorDeviceList}
              placeholder="请选择"
              disabled
              value={item}
              key={item}
            />
          ))}
        </div>
      );
    } else {
      return (
        <Select
          options={currentSensorDeviceList}
          placeholder="请选择"
          optionFilterProp="label"
          showSearch
          disabled={formDisabled}
        />
      );
    }
  };

  // 编辑时只能修改备注
  const formDisabled = type === 'edit';
  return (
    <Modal
      title={type === 'edit' ? '编辑传感器配置方案' : '新增传感器配置方案'}
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
      maskClosable={false}
      width="auto"
    >
      <LoadingWrapper spinning={loading}>
        <div className={styles.operateSensorConfig}>
          <Form
            form={form}
            autoComplete="off"
            onFinish={upsertSensorConfig}
            layout="vertical"
            className={styles.formBox}
            onValuesChange={onFormChange}
          >
            <Form.Item
              label="方案名称"
              name="name"
              rules={[{ required: true, message: '请输入方案名称' }]}
            >
              <Input placeholder="请输入方案名称" disabled={formDisabled} />
            </Form.Item>
            <Form.Item
              label="项目名称"
              name="projectId"
              rules={[{ required: true, message: '请选择项目名称' }]}
            >
              <SensorProjectSelect disabled={formDisabled} />
            </Form.Item>
            <FormTable
              form={form}
              field="config"
              showAdd={false}
              showDelete={false}
              initialValue={sensorConfigInitialValue}
              tableFormItems={[
                {
                  title: '设备类型',
                  width: 100,
                  formItem: (name: number, formValues: any, restField: any) => (
                    <Form.Item {...restField} name={[name, 'deviceType']}>
                      <div>{formValues?.config?.[name]?.deviceType}</div>
                    </Form.Item>
                  )
                },
                {
                  title: '安装位置',
                  width: 160,
                  formItem: (name: number, formValues: any, restField: any) => (
                    <Form.Item
                      {...restField}
                      name={[name, 'location']}
                      rules={
                        name === ultrasonicIndex
                          ? [
                              {
                                required: Boolean(
                                  formValues?.config?.[name]?.isInstall
                                ),
                                message: '请选择安装方案'
                              }
                            ]
                          : []
                      }
                    >
                      {renderLocationItem(formValues, name)}
                    </Form.Item>
                  )
                },
                {
                  title: '是否安装',
                  width: 80,
                  formItem: (name: number, formValues: any, restField: any) => (
                    <Form.Item
                      {...restField}
                      valuePropName="checked"
                      name={[name, 'isInstall']}
                    >
                      <Switch disabled={formDisabled} />
                    </Form.Item>
                  )
                },
                {
                  title: '设备',
                  width: 180,
                  formItem: (name: number, formValues: any, restField: any) => (
                    <Form.Item
                      {...restField}
                      name={[name, 'device']}
                      rules={[
                        {
                          required: Boolean(
                            formValues?.config?.[name]?.isInstall
                          ),
                          message: '请选择设备'
                        }
                      ]}
                    >
                      {renderDeviceItem(formValues, name)}
                    </Form.Item>
                  )
                }
              ]}
              validatorFunc={(value: any) => validatorConfig(value)}
            />
            <Form.Item label="备注" name="ps">
              <Input.TextArea placeholder="请输入方案备注" />
            </Form.Item>
          </Form>
          <div className={styles.imageBox}>
            <SensorUltrasonicLocation
              isView
              initSelectedLocationData={initSelectedLocationData}
            />
          </div>
        </div>
      </LoadingWrapper>
    </Modal>
  );
};

export default OperateModal;
