/**
 * 数据管理相关请求
 */
import request from "@/utils/request";

export interface dataDepartmentListRequestParams {
    page: number; 
    limit: number;
}

export const dataDepartmentListRequestPath = '/api/v2/operation/list';

// 获取数据
export const dataDepartmentListRequest = (params: dataDepartmentListRequestParams) => {
    return request.get(dataDepartmentListRequestPath, {
        params,
    })
}

// 添加数据
export const upsertDataDepartment = (data: any) => {
    return request.post('/api/v2/operation/upsert', data)
}

// 更新记录关联派发的任务
export const upsertRelevanceDistribute = (data: any) => {
    return request.post('/api/v2/operation/assignTask', data)
}

// 运维分析记录锁定
export const upsertLock = (data: any) => {
    return request.post('/api/v2/operation/lock', data)
}

// 删除
export const DeleteDataDepartment = (id: number) => {
    return request.delete(`/api/v2/operation/delete/${id}`)
}

// 批量导入
export const importDataDepartmentRequestPath = '/api/v2/operation/import';

// 获取渠道
export const getChannelListRequest = () => {
    return request.get('/api/v2/channel/list')
}

// 获取问题详情
export const getOperationQueryByTelRequest = (params:any) => {
    return request.get(`/api/v2/operation/queryByTel/`,{
        params,
      })
}

// 数据管理关联派发工单
export const upsertOperationAssignOrder = (data: any) => {
    return request.post('/api/v2/operation/assignOrder', data)
}