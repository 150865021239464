/**
 * 车辆诊断部标机设备故障信息(昆仑山设备)
 */
import React, { useState, useEffect, Fragment } from 'react';
import CommonTable from '@/components/commonTable';
import ExportButton from '@/components/exportButton';
import {
  carDiagnosisJtDeviceErrorRequest,
  carDiagnosisJtDeviceErrorRequestPath
} from '@/service/carDiagnosis';
import { useTable } from '@/utils/useTable';
import { getJtDeviceErrorColumns } from '@/columns/jtDeviceError';

interface JtDeviceErrorTableProps {
  diagnosisSearchParams: any;
  getExportParams: () => any;
}
const JtDeviceErrorTable = (props: JtDeviceErrorTableProps) => {
  const { diagnosisSearchParams, getExportParams } = props;
  const [searchParams, setSearchParams] = useState(diagnosisSearchParams);
  // 监听搜索参数的变化重新获取数据
  useEffect(() => {
    setSearchParams(diagnosisSearchParams);
  }, [diagnosisSearchParams]);

  const { page, limit, loading, data, totalCount, onPageChange } = useTable({
    requestFunc: carDiagnosisJtDeviceErrorRequest,
    initRequest: Boolean(diagnosisSearchParams),
    initPageSize: 40,
    searchParams
  });

  return (
    <Fragment>
      {/* <div style={{ margin: '12px 0', textAlign: 'right' }}>
        <ExportButton
          type="primary"
          disabled={!diagnosisSearchParams}
          url={carDiagnosisJtDeviceErrorRequestPath}
          fileName={`${diagnosisSearchParams?.plateNumber}部标机故障信息`}
          getParamsFunc={getExportParams}
          requestMethod="GET"
          permissionKey="/car-admin/car-diagnosis/jtDevice-error-export"
        />
      </div> */}
      <CommonTable
        rowKey="id"
        columns={getJtDeviceErrorColumns(data)}
        dataSource={data}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange
        }}
        bordered
        loading={loading}
        scroll={{ x: '100%', y: 400 }}
      />
    </Fragment>
  );
};

export default JtDeviceErrorTable;
