/**
 * 菜单权限添加和编辑
 */
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message, Select, InputNumber } from 'antd';
import { menuTypeData, MenuPurposeEnum, menuPurposeData, MenuTypeEnum } from '@/constants/menuManage';
import { upsertMenuRequest, menuTreeRequest } from '@/service/menuManage';

interface MenuOperateModalProps {
  data?: any; // 添加时是所属的父节点数据、编辑时是当前节点的数据
  type?: string; // 操作类型（添加、编辑）
  menuType: string; // 操作的菜单类型（菜单组、页面、浮层弹窗）
  closeModal: () => void;
  reloadData: () => void;
}
const MenuOperateModal = (props: MenuOperateModalProps) => {
  const [form] = Form.useForm();
  const { data, type, closeModal, reloadData, menuType } = props;
  const [menuGroupData, setMenuGroupData] = useState([]); // 菜单组数据
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      getMenuTree();
      form.setFieldsValue(data);
    }
  }, []);

  // 获取所有菜单组数据
  const getMenuGroupData = (innerData: any) => {
    const newData: any = [];
    innerData.forEach((item: any) => {
      if (!item.leaf) {
        newData.push({
          value: item.id,
          label: item.title,
        });
      }
    });
    return newData;
  }

  // 获取菜单权限数据
  const getMenuTree = async () => {
    const res = await menuTreeRequest({ 
      menuPurpose: [MenuPurposeEnum.admin]
    });
    setMenuGroupData(getMenuGroupData(res.data?.data));
  }

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params: any = {...values};
      params.label = values.title;
      // 添加时处理parentId值
      if (type === 'add') {
        params.parentId = data?.id;
      }
      // 编辑时处理id值
      if (type === 'edit') {
        params.id = data.id;
      }
      params.leaf = ['page', 'overlay'].includes(menuType);
      const res = await upsertMenuRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'edit' ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加菜单权限配置' : '编辑菜单权限配置'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="菜单权限名称"
          name="title"
          rules={[{ required: true, message: '请输入菜单权限名称' }]}
        >
          <Input placeholder='请输入菜单权限名称' />
        </Form.Item>
        <Form.Item
          label="菜单权限用途"
          name="menuPurpose"
          rules={[{ required: true, message: '请选择菜单权限用途' }]}
        >
          <Select
            options={menuPurposeData}
            placeholder='请选择菜单权限用途'
          />
        </Form.Item>
        <Form.Item
          label="菜单权限类型"
          name="type"
          rules={[{ required: true, message: '请选择菜单权限类型' }]}
          initialValue={menuType === 'overlay' ? MenuTypeEnum.overlay : undefined}
        >
          <Select
            options={menuTypeData}
            placeholder='请选择菜单权限类型'
            disabled={menuType === 'overlay'}
          />
        </Form.Item>
        {/* 编辑页面权限配置时才显示归属父节点选项 */}
        {menuType === 'page' && type === 'edit' &&
          <Form.Item
            label="菜单权限归属父节点"
            name="parentId"
            rules={[{ required: true, message: '请选择菜单权限归属父节点' }]}
          >
            <Select
              placeholder='请选择菜单权限归属父节点'
              options={menuGroupData}
              showSearch
              optionFilterProp="label"
            />
          </Form.Item>
        }
        <Form.Item
          label="菜单权限唯一key"
          name="key"
          rules={[{ required: true, message: '请输入菜单权限唯一key' }]}
        >
          <Input placeholder='请输入菜单权限唯一key' />
        </Form.Item>
        <Form.Item
          label="排序编号"
          name="order"
          rules={[{ required: true, message: '请输入排序编号' }]}
        >
          <InputNumber min={0} precision={0} placeholder='请输入排序编号' style={{ width: '100%' }} />
        </Form.Item>
        {/* 操作菜单组时显示图标项配置 */}
        {menuType === 'menuGroup' &&
          <Form.Item
            label="菜单组图标"
            name="icon"
            rules={[{ required: true, message: '请填写菜单组图标' }]}
          >
            <Input placeholder='请填写菜单组图标' />
          </Form.Item>
        }
      </Form>
    </Modal>
  )
}

export default MenuOperateModal;
    