/**
 * 角色管理相关请求
 */
import request from "@/utils/request";

// 获取角色列表
export const roleListRequest = (params: any) => {
  return request.get('/api/uaa/role/list', {params})
}

// 添加/编辑角色
export const upsertRoleRequest = (data: any) => {
  return request.post('/api/uaa/role/upsert', data)
}

// 删除角色
export const deleteRoleRequest = (id: number) => {
  return request.delete(`/api/uaa/role/${id}`)
}

// 角色关联菜单权限
export const roleRelateMenuRequest = (data: any) => {
  return request.post('/api/uaa/role/relateMenuPermissions', data)
}

// 获取指定角色关联的菜单权限
export const getRoleRelateMenuRequest = (id: number) => {
  return request.get(`/api/uaa/role/menuPermissions/${id}`)
}
