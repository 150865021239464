/**
 * 电子围栏配置弹窗
 */
import React, { useEffect, useState } from 'react';
import { Form, Modal, Radio, Select, message, InputNumber } from 'antd';
import IntersectionOperateForm from '../intersectionOperateForm';
import { intersectionTypeObj } from '@/constants/electronFence';
import {
  fencePointDetailRequest,
  fenceAreaDetailRequest,
  upsertFencePointRequest
} from '@/service/electronFence';
import { getMinMaxAngle } from '@/utils/math';

interface FenceConfigModalProps {
  fenceAreaId: number; // 电子围栏区域ID
  fenceId: number; // 电子围栏ID
  angle: number; // 航向角角度
  angleRange: number; // 航向角浮动区间
  closeModal: () => void; // 关闭弹窗的方法
  configSuccessCallback: (angleRange: number, fenceId: number) => void; // 改变航向角区间的回调方法
}
const FenceConfigModal = (props: FenceConfigModalProps) => {
  const {
    fenceAreaId,
    fenceId,
    angle,
    angleRange,
    closeModal,
    configSuccessCallback
  } = props;
  const [form] = Form.useForm();
  const [intersectionList, setIntersectionList] = useState([]); // 路口类型列表

  // 获取围栏区域路口类型数据
  const getFenceAreaDetail = async () => {
    const res = await fenceAreaDetailRequest(fenceAreaId);
    const intersectionData: any = [];
    (res.data?.data?.configs || []).forEach((item: any) => {
      intersectionData.push({
        label: intersectionTypeObj[item.type],
        value: item.id
      });
    });
    setIntersectionList(intersectionData);
  };

  // 获取电子围栏点标记详情
  const getFencePointDetail = async () => {
    const res = await fencePointDetailRequest(fenceId);
    const resData = res.data?.data;
    if (resData) {
      if (!resData.electronicFenceConfigId && resData.type) {
        // 自定义路口数据时修改configType表单项值
        resData.configType = 2;
      } else {
        resData.configType = 1;
      }
      resData.range = angleRange;
      form.setFieldsValue(resData);
    }
  };

  useEffect(() => {
    getFenceAreaDetail();
    getFencePointDetail();
  }, []);

  // 配置电子围栏
  const onConfig = async (values: any) => {
    const params = { ...values };
    delete params.configType; // 删除该表单项值(接口不需要)
    delete params.range; // 删除浮动区间表单项值
    const { headingAngleMin, headingAngleMax } = getMinMaxAngle(
      angle,
      values.range
    );
    const res = await upsertFencePointRequest({
      ...params,
      headingAngleMin,
      headingAngleMax,
      id: fenceId,
      electronicFenceId: fenceAreaId
    });
    if (res.data?.data) {
      message.success('配置成功');
      configSuccessCallback(values.range, fenceId);
      closeModal();
    }
  };

  return (
    <Modal
      title="配置电子围栏"
      open
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
      bodyStyle={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={onConfig}
        layout="vertical"
      >
        <Form.Item
          label="航向角浮动区间"
          name="range"
          rules={[
            { required: true, message: '请输入航向角浮动区间' },
            {
              type: 'number',
              min: 0,
              max: 180,
              message: `请输入指定区间的值(0 ~ 180)`
            }
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="请输入航向角浮动区间"
          />
        </Form.Item>
        <Form.Item
          name="configType"
          rules={[{ required: true, message: '请选择路口类型' }]}
          initialValue={1}
        >
          <Radio.Group
            options={[
              { label: '选择已有路口类型', value: 1 },
              { label: '自定义路口数据', value: 2 }
            ]}
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const configType = form.getFieldValue('configType');
            if (configType === 1) {
              return (
                <Form.Item
                  label="路口类型"
                  name="electronicFenceConfigId"
                  rules={[{ required: true, message: '请选择路口类型' }]}
                >
                  <Select options={intersectionList} />
                </Form.Item>
              );
            } else {
              return <IntersectionOperateForm />;
            }
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FenceConfigModal;
