/**
 * 设备全检相关请求
 */
import request from '@/utils/request';

// 获取设备全检任务列表
export const fullInspectionListRequestPath = '/api/v2/fullInspection/list';
export const fullInspectionListRequest = (params: any) => {
  return request.get(fullInspectionListRequestPath, {
    params
  });
};

// 添加/编辑全检任务
export const upsertFullInspectionRequest = (data: any) => {
  return request.post('/api/v2/fullInspection/upsert', data);
};

// 全检任务的详情
export const fullInspectionDetailRequest = (id: number) => {
  return request.get(`/api/v2/fullInspection/detail/${id}`);
};

// 导出全部检测数据的接口
export const fullInspectionExportRequestPath = '/api/v2/fullInspection/export';

// 车辆基本信息-黄河设备
export const vehicleBaseInfoRequestPath = '/api/dataAnalyze/vehicleBaseInfo';
export const vehicleBaseInfoRequest = (params: any) => {
  return request.get(vehicleBaseInfoRequestPath, {
    params
  });
};
