/**
 * 诉讼记录
 */
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import { Button, Drawer, Timeline, Divider } from 'antd';
import PermissionButton from '@/components/permissionButton';
import LoadingWrapper from '@/components/loadingWrapper';
import CarProceedItem from '../carProceedItem';
import styles from './index.module.scss';
import { carProceedListRequest } from "@/service/contractManage"; //诉讼记录请求
import OperateCarProceed from '@/pages/contractManage/components/operateCarProceed';

interface CarProceedRecoedProps {
  carInfo: any; // 车辆信息
  onClose: () => void; // 关闭诉讼记录弹层的方法
}
const CarProceedRecoed = (props: CarProceedRecoedProps) => {
  const { carInfo, carInfo: { plate_number, frame_number}, onClose } = props;
  const [page, setPage] = useState(1); // 页码
  const [totalCount, setTotalCount] = useState(0); // 总数
  const [recordData, setRecordData] = useState<any>([]); // 诉讼记录数据
  const [loading, setLoading] = useState<boolean>(false) // 诉讼记录数据是否在请求中
  const [operateVisible, setOperateVisible] = useState<any>({}) // 添加诉讼记录的弹窗
  
  // 获取诉讼记录
  const getMaintenancesRecord = async(newPage: number) => {
    try {
      setLoading(true);
      const res = await carProceedListRequest({frameNumber: frame_number});
      const data = res.data?.data || [];
      if (newPage === 1) {
        setRecordData(data);
      } else {
        setRecordData([...recordData, ...data]);
      }
      setPage(newPage);
      setLoading(false);
      setTotalCount(res.data?.count || 0);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMaintenancesRecord(1);
  }, []);

  // 加载更多
  const loadMore = () => {
    getMaintenancesRecord(page + 1);
  }
  
  return (
    <Drawer
      title={`${plate_number}诉讼记录`}
      visible
      onClose={onClose}
      className={styles.recordDrawer}
      size="large"
    >
      <PermissionButton
        type="primary"
        operateType='/car-admin/car-diagnose/carProceed-add'
        style={{ marginBottom: '16px' }}
        onClick={() => setOperateVisible({show: true, type: 'add'})}
      >
        新增诉讼记录
      </PermissionButton>
      <LoadingWrapper spinning={loading}>
        <Timeline>
          {recordData.map((item: any, index: any) => {
            return (
              <Timeline.Item key={index}>
                <div>
                  {item.createdAt ? dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : item.createdAt}
                </div>
                <CarProceedItem column={{ xs: 1, sm: 3 }} data={item} type="maintenance" />
              </Timeline.Item>
              )
          })}
        </Timeline>
        {recordData.length ?
          <Divider plain>
            {totalCount > recordData.length ?
              <Button type="primary" onClick={loadMore}>加载更多</Button> :
              "已加载全部数据"
            }
          </Divider> :
          <Divider plain>暂无诉讼记录</Divider>
        }
      </LoadingWrapper>
      {/* 添加诉讼记录的弹窗 */}
      {operateVisible.show && 
        <OperateCarProceed
          type={operateVisible.type}
          closeModal={() => setOperateVisible({show: false, type: 'add'})}
          reloadData={() => getMaintenancesRecord(1)}
          item={carInfo}
        />
      }

    </Drawer>
  )
}

export default CarProceedRecoed;