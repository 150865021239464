/**
 * 页面内部权限的添加和编辑
 */ 
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { cloneDeep } from 'lodash';
import { upsertMenuRequest } from '@/service/menuManage';

interface ButtonOperateModalProps {
  type: string; // 添加或编辑类型
  pageData: any; // 页面配置的数据
  data?: any; // 编辑的页面内部权限配置的数据
  editIndex?: number; // 编辑的页面内部权限配置的索引
  closeModal: () => void;
  reloadData: any;
}
const ButtonOperateModal = (props: ButtonOperateModalProps) => {
  const [form] = Form.useForm();
  const { type, pageData, data, editIndex, closeModal, reloadData } = props;
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求

  // 编辑的时候赋值
  useEffect(() => {
    if (type === 'edit') {
      form.setFieldsValue(data);
    }
  }, []);

  // 提交
  const submit = async(values: any) => {
    if (submitNoRepetition) {
      setSubmitNoRepetition(false)
      const params: any = cloneDeep(pageData);
      if (type === 'add') {
        // 添加时新增权限配置
        params.permissions.push(values);
      } else {
        // 编辑时修改权限配置
        params.permissions[editIndex as number] = {
          ...values,
          id: data.id,
        };
      }
      const res = await upsertMenuRequest(params);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      if (res.data?.code === 0) {
        message.success(type === 'edit' ? '编辑成功' : '添加成功');
        closeModal();
        reloadData();
      }
    }
  }

  return (
    <Modal
      title={type === 'add' ? '添加页面内部权限配置' : '编辑页面内部权限配置'}
      visible
      onCancel={closeModal}
      onOk={() => form.submit()}
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={submit}
        autoComplete="off"
        labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="页面内部权限名称"
          name="displayName"
          rules={[{ required: true, message: '请输入页面内部权限名称' }]}
        >
          <Input placeholder='请输入页面内部权限名称' />
        </Form.Item>
        <Form.Item
          label="页面内部权限唯一key"
          name="permissionKey"
          rules={[{ required: true, message: '请输入页面内部权限唯一key' }]}
        >
          <Input placeholder='请输入页面内部权限唯一key' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ButtonOperateModal;
    