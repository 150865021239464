/**
 * 部标机设备报警视频上传规则相关请求
 */
import request from '@/utils/request';

export interface JtDeviceUploadRuleListRequestParams {
  page: number;
  limit: number;
}

export const jtDeviceUploadRuleListRequestPath = '/api/v2/jt808ReportRule/list';
// 获取部标机设备上传规则列表数据
export const jtDeviceUploadRuleListRequest = (
  params: JtDeviceUploadRuleListRequestParams
) => {
  return request.get(jtDeviceUploadRuleListRequestPath, {
    params
  });
};

// 添加、编辑部标机设备上传规则
export const upsertJtDeviceUploadRuleRequest = (data: any) => {
  return request.post('/api/v2/jt808ReportRule/upsert', data);
};

// 获取部标机设备上传规则详情
export const jtDeviceUploadRuleDetailRequest = (id: number) => {
  return request.get(`/api/v2/jt808ReportRule/detail/${id}`);
};

// 删除部标机设备上传规则
export const deleteJtDeviceUploadRuleRequest = (id: number) => {
  return request.delete(`/api/v2/jt808ReportRule/${id}`);
};

// 分组绑定报警上报规则
export const bindUploadRuleRequest = (data: any) => {
  return request.post('/api/v2/cate/bindRule', data);
};

// 分组解绑报警上报规则
export const unbindUploadRuleRequest = (data: any) => {
  return request.post('/api/v2/cate/unbindRule', data);
};
