/**
 * 保单支付记录
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button, Input } from 'antd';
import dayjs from 'dayjs';
import CommonTable from '@/components/commonTable';
import { useTable } from '@/utils/useTable';
import { insurePayRecordListRequest } from "@/service/insurePayRecord";
import styles from '@/styles/pageContent.module.scss';
import PdfPreviewModal from '@/components/pdfPreviewModal';
import DatePicker from '@/components/datePicker';

const InsurePayRecord = () => {
    const [searchParams, setSearchParams] = useState({
        startDay: `${dayjs().subtract(7, 'day').format('YYYY-MM-DD')}`,
        endDay: `${dayjs().format('YYYY-MM-DD')}`,
    }); // 搜索相关的参数
    const [pdfPreviewInfo, setPdfPreviewInfo] = useState<any>(null); // PDF合同预览的信息
    const [form] = Form.useForm();
    const { page, limit, loading, data, totalCount, onPageChange } = useTable({
        requestFunc: insurePayRecordListRequest,
        initRequest: true,
        initPageSize: 40,
        searchParams,
    });
    const { RangePicker } = DatePicker;

    // 处理搜索参数
    const operateSearchParams = (params: any, isExport?: boolean) => {
        const newParams = { ...params };
        newParams.startDay = dayjs(params.day[0]).format('YYYY-MM-DD');
        newParams.endDay = dayjs(params.day[1]).format('YYYY-MM-DD');
        delete newParams.day;
        setSearchParams(newParams)
        if (isExport) {
            newParams.export = true;
        }
        return newParams;
    }

    // 搜索
    const onSearch = async (values: any) => {
        const newSearchParams = operateSearchParams(values);
        setSearchParams(newSearchParams);
    };

    // 表格列显示
    const columns = [
        {
            title: '车牌号',
            dataIndex: 'plateNumber',
            width: 110,
        },
        {
            title: '车架号',
            dataIndex: 'frameNumber',
            width: 110,
        },
        {
            title: '保单号',
            dataIndex: 'insuranceCode',
            width: 110,
        },
        {
            title: '实支付金额',
            dataIndex: 'paidFee',
            width: 110,
        },
        {
            title: '应支付金额',
            dataIndex: 'payableFee',
            width: 110,
        }
    ]

    return (
        <Card bordered={false} className={styles.fixPageContentBox}>
            <Form onFinish={onSearch} layout="vertical" autoComplete="off">
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item label="保单号" name="insuranceCode">
                            <Input placeholder='请输入保单号' allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="车牌号"
                            name="plateNumber"
                        >
                            <Input placeholder='请输入车牌号' />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="车架号" name="frameNumber">
                            <Input placeholder='请输入车架号' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="缴费时间范围"
                            name="day"
                            initialValue={[dayjs(searchParams.startDay), dayjs(searchParams.endDay)]}>
                            <RangePicker allowClear={false} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="&nbsp;">
                            <div className={styles.searchBox}>
                                <Button type="primary" htmlType="submit">搜索</Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <CommonTable
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    total: totalCount,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                }}
                bordered
                loading={loading}
            />
            {pdfPreviewInfo && <PdfPreviewModal
                title={pdfPreviewInfo?.fileName}
                pdfUrl={pdfPreviewInfo?.url}
                closeModal={() => setPdfPreviewInfo(null)}
            />}
        </Card>
    )
}

export default InsurePayRecord;
