/**
 * 车辆在线情况
 */
import React, { useState } from 'react';
import { Card, Form, Row, Col, Button } from 'antd';
import dayjs from 'dayjs';
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart, LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { onlineDayRequest, OnlineDayParams, onlineDayRequestPath } from '@/service/onlineDay';
import CategoryTreeSelect from '@/components/categoryTreeSelect';
import ExportButton from '@/components/exportButton';
import DatePicker from '@/components/datePicker';
import LoadingWrapper from '@/components/loadingWrapper';
import CommonTable from '@/components/commonTable';
import OperationGroupSelect from '@/components/operationGroupSelect';
import { limitDayRange } from '@/utils/operateSearchParams';
import { useUserCateIdArr } from '@/utils/ownHooks';
import styles from '@/styles/pageContent.module.scss';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
const { RangePicker } = DatePicker;
const OnlineDay = () => {
  const cateIdArr = useUserCateIdArr();
  const [data, setData] = useState([]); // 数据
  const [searchParams, setSearchParams] = useState({
    beginDate: `${dayjs().subtract(1, 'month').format('YYYY-MM-DD')}`,
    endDate: `${dayjs().format('YYYY-MM-DD')}`,
    cateId: cateIdArr,
  }); // 搜索相关的参数
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // 获取数据
  const getData = async (params: OnlineDayParams) => {
    try {
      setLoading(true);
      const res = await onlineDayRequest(params);
      const onlineData = res.data?.data || [];
      const xData = [];
      const onlineCounts = [];
      const addons = [];
      const  deviceCounts = [];
      for (const item of onlineData) {
        xData.push(item.day)
        onlineCounts.push(item.onlineCount)
        addons.push(item.addon)
        deviceCounts.push(item.count)
      }
      const chartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          },
        },
        legend: {
          formatter: (name: string) => {
            if (name === '总设备数量') {
              return name +'(当日设备总数)';
            }
            if (name === '在线设备数量') {
              return name +'(当日上报过gps的设备总数)';
            }
            return name;
          }
        },
        grid: { top: '20%' },
        calculable: true,
        xAxis: { type: 'category', data: xData },
        yAxis: { type: 'value' },
        series: [
          {
            data: deviceCounts,
            type: 'line',
            name: '总设备数量',
            // label: { show: true, position: 'top' },
          },
          {
            data: onlineCounts,
            type: 'bar',
            name: '在线设备数量',
            // label: { show: true, position: 'top' },
          },
        ]
      };
      setData(onlineData);
      const onlineDayChart = echarts.init(document.getElementById('onlineDayChart') as HTMLElement)
      onlineDayChart.setOption(chartOption, true)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }


  // 搜索
  const onSearch = async (values: any) => {
    const newSearchParams = limitDayRange(values, 31);
    if (newSearchParams) {
      setSearchParams(newSearchParams);
      getData({
        page: 1,
        limit: 40,
        ...newSearchParams, 
      });
    }
  };

  // 获取导出的参数
  const getExportParams = () => {
    const values = form.getFieldsValue();
    return limitDayRange(values, 31, true);
  }

  // 运维组选择
  const groupChange = (value: string, options: any) => {
    if (value) {
      form.setFieldsValue({
        cateId: options.cateId,
      })
    }
  }

  // 表格列显示
  const columns = [
    {
      title: '日期',
      dataIndex: 'day',
      width: 110,
    },
    {
      title: '设备数量',
      dataIndex: 'count',
      width: 110,
    },
    {
      title: '新增数量(当日新增设备数量)',
      dataIndex: 'addon',
      width: 220,
    },
    {
      title: '在线数量',
      dataIndex: 'onlineCount',
      width: 110,
    },
    {
      title: '在线率(在线数量÷设备数量)',
      dataIndex: 'ratioOnline',
      width: 220,
    },
  ];

  return (
    <Card bordered={false} className={styles.pageContentBox}>
      <Form onFinish={onSearch} form={form}>
        <Row gutter={12} wrap>
          <Col span={6}>
            <Form.Item
              label="日期范围"
              name="time"
              initialValue={[dayjs(searchParams.beginDate), dayjs(searchParams.endDate)]}
            >
              <RangePicker allowClear={false} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <OperationGroupSelect
            onChange={groupChange}
            operationGroupPermissionKey='/car-admin/online-day/show-operation-group'
            span={6}
          />
          <Col span={6}>
            <Form.Item label="分类选择" name="cateId" initialValue={searchParams.cateId}>
              <CategoryTreeSelect treeCheckable />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item>
              <div className={styles.searchBox}>
                <Button type="primary" htmlType="submit">搜索</Button>
                <ExportButton 
                  url={onlineDayRequestPath}
                  fileName='车辆在线情况'
                  getParamsFunc={getExportParams}
                  requestMethod="POST"
                  permissionKey='/car-admin/online-day/export'
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LoadingWrapper spinning={loading}>
        <div id="onlineDayChart" style={(data.length || loading) ? { height: '400px'} : {}} />
      </LoadingWrapper>
      <CommonTable
        rowKey="day"
        columns={columns}
        dataSource={data}
        pagination={false}
        bordered
        scroll={{ x: '100%', y: 500 }}
        loading={loading}
      />
    </Card>
  )
}

export default OnlineDay;
  