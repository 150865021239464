// 动态引入script
let srcSuccess: any = []; // 加载成功的js

function stdOnEnd(script: any, cb: any) {
  script.onload = function () {
    this.onerror = this.onload = null
    cb(null, script)
  }
  script.onerror = function () {
    // this.onload = null here is necessary
    // because even IE9 works not like others
    this.onerror = this.onload = null
    cb(new Error('Failed to load ' + this.src), script)
  }
}

function ieOnEnd(script: any, cb: any) {
  script.onreadystatechange = function () {
    if (this.readyState !== 'complete' && this.readyState !== 'loaded') return
    this.onreadystatechange = null
    cb(null, script) // there is no way to catch loading errors in IE8
  }
}

function load(src: any, opts: any, cb: any) {
  var head = document.head || document.getElementsByTagName('head')[0]
  var script = document.createElement('script')

  if (typeof opts === 'function') {
    cb = opts
    opts = {}
  }

  opts = opts || {}
  cb = cb || function () {}

  script.type = opts.type || 'text/javascript'
  // script.charset = opts.charset || 'utf8'
  script.async = 'async' in opts ? !!opts.async : true
  script.src = src

  if (opts.attrs) {
    for (var attr in opts.attrs) {
      script.setAttribute(attr, opts.attrs[attr])
    }
  }

  if (opts.text) {
    script.text = '' + opts.text
  }

  const callback = (error: any, script: any) => {
    cb(error, script)
    error && head.removeChild(script)
  }

  var onend = 'onload' in script ? stdOnEnd : ieOnEnd
  onend(script, callback)

  // some good legacy browsers (firefox) fail the 'in' detection above
  // so as a fallback we always set onload
  // old IE will ignore this and new IE will set onload
  if (!script.onload) {
    stdOnEnd(script, callback)
  }

  head.appendChild(script)
}


const importScript = (src: string, opts?: any, hasSuccessCallback?: () => void) => {
  if (srcSuccess.indexOf(src) !== -1) {
    // 已经引入成功的js如果有回调函数调用回调函数
    hasSuccessCallback && hasSuccessCallback();
    return Promise.resolve(true);
  }
  return new Promise((resolve) => {
    load(src, opts, (error: any, script: any) => {
      if (error) {
        resolve(false);
      } else {
        srcSuccess.push(src);
        resolve(true);
      }
    })
  })
}

// 删除指定加载成功的js
export const deleteSrcSuccess = (srcArr: Array<string>) => {
  srcSuccess = srcSuccess.filter((item: string) => !srcArr.includes(item))
}

export default importScript;