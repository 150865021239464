/**
 * 严重故障定义列表
 */
import React, { useEffect, useState } from "react";
import { message, Popconfirm } from 'antd';
import { useSelector } from 'react-redux';
import CommonTable from '@/components/commonTable';
import PermissionButton from "@/components/permissionButton";
import { DeviceTypeEnum } from "@/constants/deviceManage";
import { fatalDefineListRequest, upsertFatalDefineRequest } from '@/service/fatalDefine';
import OperateFatalDefine from "../operateFatalDefine";
import styles from '@/styles/pageContent.module.scss';

interface FatalDefineListProps {
  deviceType: DeviceTypeEnum, // 设备类型
}
const FatalDefineList = (props: FatalDefineListProps) => {
  const { deviceType } = props;
  const userStore = useSelector((state: any) => state.userStore);
  const { userDetail: { groupId } } = userStore;
  const [loading, setLoading] = useState(false); // 是否在获取数据中
  const [fatalDefineList, setFatalDefineList] = useState([]); // 故障定义列表数据
  const [fatalDefineId, setFatalDefineId] = useState(); // 故障定义id
  const [operateInfo, setOperateInfo] = useState<any>({}); // 表格操作的信息

  // 获取严重故障定义列表
  const getFatalDefineList = async() => {
    try {
      setLoading(true);
      const res = await fatalDefineListRequest({
        deviceType,
        groupId,
      });
      setFatalDefineList(res.data?.data?.content || []);
      setFatalDefineId(res.data?.data?.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getFatalDefineList();
  }, []);

  // 删除严重故障定义
  const deleteFatalDefine = async(deleteIndex: number) => {
    const newFatalDefine = fatalDefineList.filter((item: any, index: number) => index !== deleteIndex);
    const res = await upsertFatalDefineRequest({
      id: fatalDefineId,
      groupId,
      deviceType,
      content: JSON.stringify(newFatalDefine),
    })
    if (res.data?.data) {
      message.success('删除成功');
      getFatalDefineList();
    }
  }

  const columns = [
    {
      title: '故障项',
      dataIndex: 'ps',
      width: 200,
    },
    {
      title: '阈值',
      dataIndex: 'value',
      width: 110,
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 150,
      render: (value: any, item: any, dataIndex: number) => {
        return (
          <div className={styles.tableOperateColumn}>
            <PermissionButton
              operateType="/car-admin/fatal-define/edit"
              type="link"
              onClick={() => setOperateInfo({ type: 'edit', editIndex: dataIndex })}
            >
              编辑
            </PermissionButton>
            <Popconfirm
              title="您确定要删除此定义吗?"
              onConfirm={() => deleteFatalDefine(dataIndex)}
            >
              <PermissionButton
                operateType="/car-admin/fatal-define/delete"
                type="link"
              >
                删除
              </PermissionButton>
            </Popconfirm>
          </div>
        )
      },
    }
  ];

  return (
    <div className={styles.commonTableWrapper}>
      <div style={{ textAlign: 'right', margin: '12px 0' }}>
        <PermissionButton
          operateType="/car-admin/fatal-define/add"
          type="primary"
          onClick={() => setOperateInfo({ type: 'add' })}
        >
          添加定义
        </PermissionButton>
      </div>
      <CommonTable
        loading={loading}
        dataSource={fatalDefineList}
        pagination={false}
        columns={columns}
        columnFilter={false}
        rowKey="name"
        bordered
      />
      {['add', 'edit'].includes(operateInfo.type) &&
        <OperateFatalDefine
          reloadData={getFatalDefineList}
          closeModal={() => setOperateInfo({})}
          type={operateInfo.type}
          editIndex={operateInfo.editIndex}
          fatalDefineList={fatalDefineList}
          deviceType={deviceType}
          groupId={groupId}
          fatalDefineId={fatalDefineId}
        />
      }
    </div>
  )
}

export default FatalDefineList;