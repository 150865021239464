/**
 * 记录仪视频获取
 */
import React, { useState } from 'react';
import { message, Form, InputNumber, Button, Drawer, Divider } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import PermissionButton from '@/components/permissionButton';
import DatePicker from '@/components/datePicker';
import CommonTable from '@/components/commonTable';
import VideoPreviewModal from '@/components/videoPreviewModal';
import { useTable } from '@/utils/useTable';
import { formatTime } from '@/utils/formatTime';
import { changeUrlProtocol } from '@/utils/common';
import { renderDvrVideoStatus } from '@/utils/driveAnalysis';
import {
  getShootByParamsRequest,
  getShootHistoryRequest
} from '@/service/parameterConfiguration';
import styles from '@/styles/pageContent.module.scss';

interface ShootHistoryProps {
  data: any; // 数据
  onClose: () => void; // 关闭弹窗的方法
}
const ShootHistory = (props: ShootHistoryProps) => {
  const { data, onClose } = props;
  const [form] = Form.useForm();
  const [searchParams] = useState({}); // 搜索参数
  const [operateInfo, setOperateInfo] = useState<any>({}); // 操作的信息存储
  const {
    page,
    limit,
    loading,
    data: tableData,
    totalCount,
    onPageChange,
    getData
  } = useTable({
    requestFunc: getShootHistoryRequest,
    initRequest: true,
    initPageSize: 40,
    searchParams,
    extraParams: { sn: data.sn, needUser: true }
  });

  // 根据参数获取记录仪视频
  const getShootByParams = async (values: any) => {
    const params = { ...values };
    params.time = dayjs(values.time).format('YYYY-MM-DD HH:mm:ss');
    params.type = 1;
    params.interval = 0;
    const res = await getShootByParamsRequest(data.sn, params);
    if (res.data?.code === 0) {
      message.success('获取指令下发成功，稍后可刷新视频列表查看');
      setTimeout(() => {
        getData();
      }, 1000);
    }
  };

  // 表格列配置
  const columns = [
    {
      title: '视频时间',
      dataIndex: 'targetTime',
      render: (value: string) => formatTime(value)
    },
    {
      title: '视频状态',
      dataIndex: 'status',
      render: (value: number) => renderDvrVideoStatus(value)
    },
    {
      title: '视频时长',
      dataIndex: 'targetDuration',
      render: (value: number) => (value ? `${value}秒` : value),
      width: 100
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 90,
      render: (value: any, item: any) => {
        if (item.status === 0) {
          return (
            <div className={styles.tableOperateColumn}>
              <Button
                type="link"
                onClick={() => setOperateInfo({ type: 'video', data: item })}
              >
                播放
              </Button>
            </div>
          );
        } else {
          return null;
        }
      }
    }
  ];

  return (
    <Drawer
      title="视频获取"
      open
      onClose={onClose}
      size="large"
      className={styles.drawerTableBox}
    >
      <Form
        form={form}
        autoComplete="off"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={getShootByParams}
      >
        <Form.Item
          label="拍摄时长"
          name="duration"
          rules={[{ required: true, message: '请输入拍摄时长' }]}
        >
          <InputNumber
            min={0}
            precision={0}
            style={{ width: '100%' }}
            addonAfter="秒"
          />
        </Form.Item>
        <Form.Item
          label="指定时间"
          name="time"
          rules={[{ required: true, message: '请选择指定时间' }]}
        >
          <DatePicker showTime style={{ width: '100%' }} />
        </Form.Item>
        <div style={{ textAlign: 'center' }}>
          <PermissionButton
            operateType="/car-admin/parameter-configuration/shoot-get"
            type="primary"
            onClick={() => form.submit()}
          >
            获取视频
          </PermissionButton>
        </div>
      </Form>
      <Divider>已获取视频</Divider>
      <div style={{ textAlign: 'right', marginBottom: '12px' }}>
        <Button icon={<RedoOutlined />} onClick={() => getData()}>
          刷新视频列表
        </Button>
      </div>
      <CommonTable
        rowKey="id"
        columnFilter={false}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: totalCount,
          current: page,
          pageSize: limit,
          onChange: onPageChange,
          showQuickJumper: false
        }}
        bordered
        size="small"
        loading={loading}
      />
      {/* 视频播放的弹窗 */}
      {operateInfo.type === 'video' && (
        <VideoPreviewModal
          url={changeUrlProtocol(operateInfo.data?.resourceUrl)}
          fileName={formatTime(operateInfo.data?.targetTime)}
          closeModal={() => setOperateInfo({})}
        />
      )}
    </Drawer>
  );
};

export default ShootHistory;
