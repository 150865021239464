/**
 * 车辆列表数据存储(Tree形式)
 */
interface ActionType {
  type: string;
  payload?: any;
}
const initState = {
  carTreeData: [], // 车辆树数据
  carTreeLoading: false, // 车辆树数据加载loading
  carTreeCount: 0 // 使用车辆树数据的组件个数
};

export const carTreeStore = (state = initState, action: ActionType) => {
  const { type, payload } = action;
  switch (type) {
    // 初始车辆树数据以及增加使用车辆树数据的组件个数
    case 'initCarTree':
      return {
        carTreeData: payload.data || [],
        carTreeLoading: false,
        carTreeCount: payload.addCount
          ? state.carTreeCount + 1
          : state.carTreeCount
      };
    // 增加使用车辆树数据的组件个数
    case 'addCarTree':
      return {
        ...state,
        carTreeCount: state.carTreeCount + 1
      };
    // 减少使用车辆树数据的组件个数
    case 'removeCarTree':
      // 没有使用时清除定时获取车辆树数据的定时器以及重置车辆树数据
      if (state.carTreeCount === 1) {
        clearTimeout(payload);
      }
      return {
        ...state,
        carTreeData: state.carTreeCount === 1 ? [] : state.carTreeData,
        carTreeCount: state.carTreeCount >= 1 ? state.carTreeCount - 1 : 0
      };
    // 修改车辆树数据加载loading
    case 'changeCarTreeLoading':
      return {
        ...state,
        carTreeLoading: payload
      };
    default:
      return state;
  }
};
