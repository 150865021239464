/**
 * 支持搜索的表格hooks
 */
import { useState, useEffect, useRef } from "react";
import { chunk } from 'lodash';

export interface UseTableParams {
  requestFunc: Function, // 请求数据的方法
  initRequest?: Boolean, // 是否要初始请求
  initPageSize?: number, // 每页展示的初始数据
  searchParams?: any, // 搜索相关的请求参数
  extraParams?: any, // 额外的请求参数
  scrollLoadMore?: boolean, // 是否滚动加载更多(特殊处理data数据)
  frontEndPageChange?: boolean; // 是否是前端处理翻页逻辑
  noPageChange?: boolean; // 没有翻页逻辑
}

export const useTable = ({
  requestFunc,
  initRequest = true,
  initPageSize = 40,
  searchParams,
  extraParams,
  scrollLoadMore = false,
  frontEndPageChange = false,
  noPageChange
}: UseTableParams) => {
  const hasFirstRender = useRef(false); // 是否已经完成第一次render
  const [page, setPage] = useState(1); // 页码
  const [limit, setLimit] = useState(initPageSize); // 每页展示的数量
  const [loading, setLoading] = useState(false); // 数据是否请求中
  const [data, setData] = useState<any>([]); // 表格数据
  const [allData, setAllData] = useState<any>([]); // 全量表格数据(纯前端翻页时使用)
  const [totalCount, setTotalCount] = useState(0); // 数据总数
  const [submitNoRepetition, setSubmitNoRepetition] = useState(true); // 不能重复请求
  // 获取表格数据
  const getData = async(newParams?: any) => {
    setSubmitNoRepetition(false)
    try {
      const requestParams = {
        page,
        limit,
        ...searchParams,
        ...extraParams,
        ...newParams,
      };
      if (noPageChange) {
        delete requestParams.page;
        delete requestParams.limit;
      }
      setLoading(true);
      const res = await requestFunc(requestParams);
      if (res.status == 200) {
        setSubmitNoRepetition(true)
      }
      setPage(requestParams.page);
      setLimit(requestParams.limit);
      const resData = res.data?.data || [];
      setAllData(resData);
      let showData = res.data?.data || [];
      if (frontEndPageChange) {
        showData = showData.slice(0, limit)
      }
      if (scrollLoadMore && page !== 1) {
        setData([...data, ...showData])
      } else {
        setData(showData);
      }
      setTotalCount(res.data?.count || 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    initRequest && getData();
  }, []);

  // 监听搜索参数的变化重新请求数据
  useEffect(() => {
    if (hasFirstRender.current) {
      getData({ page: 1 });
    } else {
      hasFirstRender.current = true;
    }
  }, [searchParams]);

  // 翻页器信息改变重新获取数据
  const onPageChange = (newPage: number, newLimit: number) => {
    const newPageNum = newLimit !== limit ? 1 : newPage;
    if (frontEndPageChange) {
      // 前端处理翻页逻辑
      setPage(newPageNum);
      setLimit(newLimit);
      setData(chunk(allData, newLimit)[newPageNum - 1]);
    } else {
      getData({
        page: newPageNum,
        limit: newLimit
      });
    }
  }

  return {
    page, // 页码
    limit, // 每页显示的数量
    loading, // 接口是否请求中
    data, // 数据
    totalCount, // 数据总数
    onPageChange, // 翻页器相关修改的方法
    getData, // 获取数据
    submitNoRepetition
  }
}
